/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */

export const initializeZendesk = (lang, name, email) => {
  let role = localStorage.getItem("role");
  let key = "";
  if (role === "agency") {
    key = "8c9993ae-b2ca-4687-a0f7-f50c35008be3";
  } else if (role === "partner") {
    key = "c291ce52-a455-4aab-974f-33943dca71e7";
  }

  const domain = window.location.protocol + "//" + window.location.host;
  window.zESettings = {
    webWidget: {
      answerBot: {
        title: {
          "*": "Ask Rafiki",
        },
        avatar: {
          url: `${domain}/answer-bot-face.png`,
          name: {
            "*": "Rafiki",
          },
        },
      },
    },
  };

  /*<![CDATA[*/
  window.zE ||
    (function (e, t, s) {
      let n =
          (window.zE =
          window.zEmbed =
            function () {
              n._.push(arguments);
            }),
        a = (n.s = e.createElement(t)),
        r = e.getElementsByTagName(t)[0];
      (n.set = function (e) {
        n.set._.push(e);
      }),
        (n._ = []),
        (n.set._ = []),
        (a.async = true),
        a.setAttribute("charset", "utf-8"),
        (a.src = "https://static.zdassets.com/ekr/asset_composer.js?key=" + s),
        (n.t = +new Date()),
        (a.type = "text/javascript"),
        r.parentNode.insertBefore(a, r);
    })(document, "script", key); /*]]>*/

  zE("webWidget", "setLocale", lang);
  zE("webWidget", "identify", { name, email });
  zE("webWidget", "prefill", {
    name: {
      value: name,
      readOnly: false,
    },
    email: {
      value: email,
      readOnly: false,
    },
  });
  zE("webWidget:on", "close", function () {
    zE("webWidget", "hide");
    const answerBot = document.getElementById("answer-bot-container");
    if (answerBot) {
      answerBot.classList.remove("hidden");
    }
  });
  zE("webWidget", "hide");
};

import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "@local/components/modal";
import Alert from "@local/components/alert";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import PushPinIcon from "@mui/icons-material/PushPin";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HeaderNavigation from "@local/components/header-navigation";
import MainContent from "@local/components/main-content-wrapper";
import { PROJECT_TYPES, PROJECT_STATUSES, ROLES } from "@local/legacy-utils/constants";
import Button from "@local/components/button";
import { adaptJustifications } from "@local/utils/adapters";
import { getProjectTypeColor, getProjectTypeLabel, getProjectStatusColor } from "../../utils/adapters";
import { getProjectStatuses } from "@local/utils/constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CompleteCfeiFrom from "./components/complete-cfei-form";
import FinalizeDirectSelectionProjectForm from "./components/finalize-direct-selection-project-form";
import ConvertToDirectSelectionForm from "./components/convert-to-direct-selection-form";
import { sendProjectRequest, publishProjectRequest, updateProjectDetails, postUCN, convertCnToDirectSelection } from "@local/actions";
import { getApplicationStatusColor } from "@local/pages/application-details/utils/adapters";
import ConfirmModal from "@local/components/confirm-modal";
import { downloadFile } from "@local/utils/network";
import { useParams } from "react-router-dom";
import style from "./style.module.scss";
import { formatDateToBeSent } from "@local/utils";

const getTitle = (project, type) => {
  const title = type === PROJECT_TYPES.UNSOLICITED ? project?.proposal_of_eoi_details?.title : project?.title;
  const documentClientWidth = document.documentElement.clientWidth > 1200;
  const isLongTitle = documentClientWidth ? title?.length > 50 : title?.length > 25;
  const numOfTitleChars = documentClientWidth ? 50 : 25;
  return (
    <div className={style.titleWrapper}>
      <span className={style.title}>{isLongTitle ? title.substring(0, numOfTitleChars).concat("...") : title}</span>
      {isLongTitle ? (
        <Tooltip title={title}>
          <InfoIcon fontSize="small" />
        </Tooltip>
      ) : undefined}
      <Chip size="small" className={style.typeWrapper} style={{ backgroundColor: getProjectTypeColor(type), color: "#FFFFFF" }} label={getProjectTypeLabel(type)} />
    </div>
  );
};

const getHeaderButtons = (
  applicationStatus,
  status,
  type,
  role,
  isProjectPublished,
  isCompleted,
  isCreator,
  isFocalPoint,
  isAdvEd,
  isAdvEdPlus,
  isAdvEdSecretariat,
  isPAM,
  isFinalizeAllowed,
  hasSendPermission,
  hasPublishPermission,
  isDeadlinePassed,
  isClarificationDeadlinePassed,
  onClickOpenSendProjectModal,
  onClickOpenPublishProjectModal,
  onClickOpenCompleteProjectModal,
  onClickOpenFinalizeDirectSelectionProjectModal,
  hasDsrConvertPermission,
  hasSubmitPermission,
  cfeiConverted,
  partnerVerified,
  isMFT,
  isPartnerVerified,
  isPartnerProfileComplete,
  isPinned,
  onClickTogglePin,
  onClickOpenSubmitUCNModal,
  onClickOpenConvertToDirectSelectionModal,
  hasPinPermission,
) => {
  switch (type) {
    case PROJECT_TYPES.OPEN:
      if (role === ROLES.AGENCY)
        return [
          {
            id: "project_details_header_button_complete_project",
            render: () => (
              <Button
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.text",
                })}
                onClick={onClickOpenCompleteProjectModal}
              />
            ),
            isVisible: !isCompleted && isProjectPublished && isFinalizeAllowed,
          },
          {
            id: "project_details_header_button_send_project",
            render: () => (
              <Tooltip
                title={formatMessage({
                  id: "pages.project.details.cmp.container.idx.tooltip",
                })}
              >
                <span>
                  <Button
                    text={formatMessage({
                      id: "pages.project.details.cmp.container.idx.textOne",
                    })}
                    onClick={onClickOpenSendProjectModal}
                  />
                </span>
              </Tooltip>
            ),
            isVisible: !isCompleted && status === PROJECT_STATUSES.DRA && isCreator && hasSendPermission && !isPAM,
          },
          {
            id: "project_details_header_button_publish_project",
            render: () => {
              if (isDeadlinePassed) {
                return (
                  <Tooltip
                    title={formatMessage({
                      id: "pages.project.details.cmp.container.idx.updateDeadlineDateTooltip",
                    })}
                  >
                    <span>
                      <Button
                        text={formatMessage({
                          id: "pages.project.details.cmp.container.idx.textTwo",
                        })}
                        isDisabled
                      />
                    </span>
                  </Tooltip>
                );
              } else if (isClarificationDeadlinePassed) {
                return (
                  <Tooltip
                    title={formatMessage({
                      id: "pages.project.details.cmp.container.idx.updateClarificationDateTooltip",
                    })}
                  >
                    <span>
                      <Button
                        text={formatMessage({
                          id: "pages.project.details.cmp.container.idx.textTwo",
                        })}
                        isDisabled
                      />
                    </span>
                  </Tooltip>
                );
              } else {
                return (
                  <Button
                    text={formatMessage({
                      id: "pages.project.details.cmp.container.idx.textTwo",
                    })}
                    onClick={onClickOpenPublishProjectModal}
                  />
                );
              }
            },
            isVisible: !isCompleted && !isProjectPublished && hasPublishPermission && (((isFocalPoint || isCreator) && (isAdvEd || isMFT || isAdvEdPlus || isAdvEdSecretariat)) || (isCreator && isPAM)),
          },
        ];
      else if (role === ROLES.PARTNER && hasPinPermission)
        return [
          {
            id: "project_details_header_button_pin_project",
            render: () => (
              <div>
                <Button
                  text={
                    isPinned
                      ? formatMessage({
                          id: "pages.project.details.cmp.container.idx.unpin",
                        })
                      : formatMessage({
                          id: "pages.project.details.cmp.container.idx.pin",
                        })
                  }
                  variant={isPinned ? "contained" : "outlined"}
                  onClick={onClickTogglePin}
                  startIcon={<PushPinIcon />}
                />
              </div>
            ),
            isVisible: true,
          },
        ];
      break;
    case PROJECT_TYPES.DIRECT:
      if (role === ROLES.AGENCY)
        return [
          {
            id: "project_details_header_button_complete_direct_project",
            render: () => (
              <Button
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.text",
                })}
                onClick={onClickOpenFinalizeDirectSelectionProjectModal}
              />
            ),
            isVisible: !isCompleted && isProjectPublished && isFinalizeAllowed,
          },
          {
            id: "project_details_header_button_send_direct_project",
            render: () => (
              <Tooltip
                title={formatMessage({
                  id: "pages.project.details.cmp.container.idx.tooltipOne",
                })}
              >
                <span>
                  <Button
                    text={formatMessage({
                      id: "pages.project.details.cmp.container.idx.textThree",
                    })}
                    onClick={onClickOpenSendProjectModal}
                  />
                </span>
              </Tooltip>
            ),
            isVisible: !isCompleted && status === "Dra" && isCreator && hasSendPermission,
          },
          {
            id: "project_details_header_button_publish_direct_project",
            render: () =>
              !isPartnerProfileComplete || !isPartnerVerified ? (
                <Tooltip
                  title={
                    !isPartnerVerified
                      ? formatMessage({
                          id: "pages.project.details.cmp.container.idx.tooltipInfo",
                        })
                      : formatMessage({
                          id: "pages.project.details.cmp.container.idx.tooltipInfoProfile",
                        })
                  }
                >
                  <span>
                    <Button
                      text={formatMessage({
                        id: "pages.project.details.cmp.container.idx.textFour",
                      })}
                      isDisabled
                    />
                  </span>
                </Tooltip>
              ) : (
                <Button
                  text={formatMessage({
                    id: "pages.project.details.cmp.container.idx.textFour",
                  })}
                  onClick={onClickOpenPublishProjectModal}
                />
              ),
            isVisible: !isCompleted && !isProjectPublished && hasPublishPermission && (isFocalPoint || isCreator) && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat || isMFT),
          },
        ];
      else if (role === ROLES.PARTNER) return [];
      break;
    case PROJECT_TYPES.UNSOLICITED:
      if (role === ROLES.AGENCY && hasDsrConvertPermission)
        return [
          {
            id: "project_details_header_button_convert_to_direct",
            render: () =>
              !partnerVerified ? (
                <Tooltip
                  title={
                    !partnerVerified &&
                    formatMessage({
                      id: "pages.project.details.cmp.container.idx.verified",
                    })
                  }
                >
                  <span>
                    <Button
                      text={formatMessage({
                        id: "pages.project.details.cmp.container.idx.label",
                      })}
                      isDisabled
                    />
                  </span>
                </Tooltip>
              ) : (
                <Button
                  text={formatMessage({
                    id: "pages.project.details.cmp.container.idx.label",
                  })}
                  onClick={onClickOpenConvertToDirectSelectionModal}
                />
              ),
            isVisible: !cfeiConverted,
          },
        ];
      else if (role === ROLES.PARTNER)
        return [
          {
            id: "project_details_header_button_submit_uc",
            render: () => (
              <Button
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.textFive",
                })}
                onClick={onClickOpenSubmitUCNModal}
              />
            ),
            isVisible: applicationStatus === PROJECT_STATUSES.DRA && hasSubmitPermission,
          },
        ];
      break;
    default:
      return [];
  }
};

const getHeaderMenuItems = (
  applicationStatus,
  status,
  onClickDownloadPdf,
  isProjectPublished,
  isCreator,
  isFocalPoint,
  isDeadlinePassed,
  isSend,
  hasManageDraftPermission,
  hasInviteSentPermission,
  hasEditSentPermission,
  hasInvitePublishPermission,
  hasCancelPublishPermission,
  hasManageReviewersPermission,
  hasEditPublishedDatesPermission,
  isPublishPermissionAllowed,
  hasRecommendedPartner,
  hasEditDraftPermission,
  hasDeleteDraftPermission,
  hasEditPublishedPermission,
  isEditPublishedPermission,
  hasCancelPermission,
  hasDeletePermission,
  hasEditPermission,
  hasPinPermission,
  type,
  role,
  isAdvEd,
  isAdvEdPlus,
  isAdvEdSecretariat,
  isCompleted,
  isMFT,
  onClickEdit,
  onClickDeleteOpenProject,
  onClickDeleteDirectSelectionProject,
  onClickDeleteUnsolicitedConceptNote,
  onClickCancel,
  onClickInvitePartners,
  onClickManageReviewers,
) => {
  switch (type) {
    case PROJECT_TYPES.OPEN:
      if (role === ROLES.AGENCY)
        return [
          {
            id: "agency_open_project_details_header_download",
            icon: <PictureAsPdfIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSix",
            }),
            onClick: onClickDownloadPdf,
            isVisible: true,
          },
          {
            id: "agency_open_project_details_header_edit",
            icon: <EditIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSeven",
            }),
            onClick: onClickEdit,
            isVisible: ((hasManageDraftPermission && isCreator && status === PROJECT_STATUSES.DRA) || (hasEditSentPermission && isFocalPoint)) && !isProjectPublished,
          },
          {
            id: "agency_open_project_details_header_edit_date",
            icon: <EditIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSeven",
            }),
            onClick: onClickEdit,
            isVisible: isPublishPermissionAllowed(hasEditPublishedDatesPermission) && isProjectPublished,
          },
          {
            id: "agency_open_project_details_header_invite",
            icon: <PersonAddIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textEight",
            }),
            onClick: onClickInvitePartners,
            isVisible:
              ((hasManageDraftPermission && isCreator && status === PROJECT_STATUSES.DRA) || (hasInviteSentPermission && isFocalPoint && status === PROJECT_STATUSES.SEN) || (isProjectPublished && isPublishPermissionAllowed(hasInvitePublishPermission))) &&
              !isDeadlinePassed,
          },
          {
            id: "agency_open_project_details_header_manage_reviewers",
            icon: <PeopleAltIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textNine",
            }),
            onClick: onClickManageReviewers,
            isVisible: !hasRecommendedPartner && !isSend && isProjectPublished && isPublishPermissionAllowed(hasManageReviewersPermission),
          },
          {
            id: "agency_open_project_details_header_delete",
            icon: <DeleteIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textTen",
            }),
            onClick: onClickDeleteOpenProject,
            isVisible: !isProjectPublished && ((hasManageDraftPermission && isCreator && status === PROJECT_STATUSES.DRA) || (hasEditSentPermission && isFocalPoint)),
          },
          {
            id: "agency_open_project_details_header_cancel",
            icon: <CancelIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textEleven",
            }),
            onClick: onClickCancel,
            isVisible: !isProjectPublished && isPublishPermissionAllowed(hasCancelPublishPermission),
          },
        ];
      else if (role === ROLES.PARTNER)
        return [
          {
            id: "partner_open_project_details_header_download",
            icon: <PictureAsPdfIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSix",
            }),
            onClick: onClickDownloadPdf,
            isVisible: true,
          },
        ];
      break;
    case PROJECT_TYPES.DIRECT:
      if (role === ROLES.AGENCY)
        return [
          {
            id: "agency_direct_project_details_header_download",
            icon: <PictureAsPdfIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSix",
            }),
            onClick: onClickDownloadPdf,
            isVisible: true,
          },
          {
            id: "agency_direct_project_details_header_edit",
            icon: <EditIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSeven",
            }),
            onClick: onClickEdit,
            isVisible:
              ((status === "Dra" || (status === "Sen" && isAdvEd)) &&
                ((hasEditDraftPermission && isCreator) || (!isProjectPublished && status === "Sen" && hasEditSentPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && isFocalPoint) || (hasDeleteDraftPermission && isFocalPoint))) ||
              (!isCompleted && isProjectPublished && isEditPublishedPermission) ||
              (!isCompleted && hasEditPublishedPermission && isMFT && isFocalPoint),
          },
          {
            id: "agency_direct_project_details_header_delete",
            icon: <DeleteIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textTen",
            }),
            onClick: onClickDeleteDirectSelectionProject,
            isVisible: (status === "Dra" || (status === "Sen" && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat))) && ((hasDeleteDraftPermission && isCreator) || (hasDeleteDraftPermission && isFocalPoint)),
          },
        ];
      else if (role === ROLES.PARTNER)
        return [
          {
            id: "partner_direct_project_details_header_download",
            icon: <PictureAsPdfIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSix",
            }),
            onClick: onClickDownloadPdf,
            isVisible: true,
          },
        ];
      break;
    case PROJECT_TYPES.UNSOLICITED:
      if (role === ROLES.AGENCY) return [];
      else if (role === ROLES.PARTNER)
        return [
          {
            id: "partner_unsolicited_project_details_header_delete",
            icon: <DeleteIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textTen",
            }),
            onClick: onClickDeleteUnsolicitedConceptNote,
            isVisible: applicationStatus === PROJECT_STATUSES.DRA && hasDeletePermission,
          },
          {
            id: "partner_unsolicited_project_details_header_edit",
            icon: <EditIcon />,
            label: formatMessage({
              id: "pages.project.details.cmp.container.idx.textSeven",
            }),
            onClick: onClickEdit,
            isVisible: applicationStatus === PROJECT_STATUSES.DRA && hasEditPermission,
          },
        ];
      break;
    default:
      return [];
  }
};

const getHeder = (project, type, role, headerButtons, anchorElement, onOpenMenu, onCloseMenu, menuItems) => {
  const onClickMenuItem = (item) => {
    onCloseMenu();
    item.onClick();
  };

  return (
    <div className={style.headerWrapper}>
      {type !== PROJECT_TYPES.UNSOLICITED ? (
        <div>
          <div className={style.statusTitleWrapper}>
            <CircleIcon
              style={{
                fill: getProjectStatusColor(project?.status),
                fontSize: ".95rem",
              }}
            />
            <span>{getProjectStatuses()[project?.status]}</span>
          </div>
          {project?.completed_reason_display ? (
            <div className={style.statusLabelWrapper}>
              <span>{project?.completed_reason_display}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {role === ROLES.PARTNER && type === PROJECT_TYPES.UNSOLICITED ? (
        <div>
          <div className={style.statusTitleWrapper}>
            <CircleIcon
              style={{
                fill: getApplicationStatusColor(project?.application_status),
                fontSize: ".95rem",
              }}
            />
            <span>{project?.application_status_display}</span>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={style.buttonsWrapper}>{headerButtons?.map((item) => (item.isVisible ? item.render() : undefined))}</div>
      <div>
        {menuItems?.filter((item) => item?.isVisible)?.length > 0 ? (
          <IconButton edge="end" onClick={onOpenMenu} color="inherit">
            <MoreVertIcon style={{ fill: "#757575" }} />
          </IconButton>
        ) : undefined}
        <Menu id="basic-menu" anchorEl={anchorElement} open={(anchorElement ?? undefined) !== undefined} onClose={onCloseMenu} MenuListProps={{ "aria-labelledby": "basic-button" }}>
          {menuItems?.map((item) =>
            item.isVisible ? (
              <MenuItem key={item.id} onClick={() => onClickMenuItem(item)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.label}</ListItemText>
              </MenuItem>
            ) : undefined,
          )}
        </Menu>
      </div>
    </div>
  );
};

const Container = ({
  selectedTab,
  children,
  tabs,
  onChangeTab,
  project,
  type,
  isProjectPublished,
  isCompleted,
  isCreator,
  isFocalPoint,
  isAdvEd,
  isAdvEdPlus,
  isAdvEdSecretariat,
  isPAM,
  isMFT,
  isFinalizeAllowed,
  hasSendPermission,
  hasPublishPermission,
  isDeadlinePassed,
  isClarificationDeadlinePassed,
  projectStatus,
  hasWinners,
  isSend,
  hasManageDraftPermission,
  hasInviteSentPermission,
  hasEditSentPermission,
  hasInvitePublishPermission,
  hasCancelPublishPermission,
  hasManageReviewersPermission,
  hasEditPublishedDatesPermission,
  isPublishPermissionAllowed,
  hasRecommendedPartner,
  hasEditDraftPermission,
  hasDeleteDraftPermission,
  hasEditPublishedPermission,
  isEditPublishedPermission,
  hasCancelPermission,
  hasDeletePermission,
  hasEditPermission,
  hasDsrConvertPermission,
  hasSubmitPermission,
  hasPinPermission,
  role,
  cfeiConverted,
  partnerVerified,
  isPartnerVerified,
  isPartnerProfileComplete,
  sendProjectRequest,
  publishProjectRequest,
  updateProjectDetails,
  completeCfeiFormValues,
  finalizeDirectSelectionProjectForm,
  convertCnToDirectSelectionFormValues,
  onClickEdit,
  onAfterSubmitPublish,
  onClickDeleteOpenProject,
  onClickDeleteDirectSelectionProject,
  onClickDeleteUnsolicitedConceptNote,
  onClickCancel,
  onClickInvitePartners,
  onClickManageReviewers,
  isPinned,
  onClickTogglePin,
  refreshProjectFunction,
  submitUCN,
  directJustificationsOptions,
  officeId,
  convertCnToDirectSelection,
}) => {
  const [isSendProjectVisible, setIsSendProjectVisible] = useState(false);
  const [isPublishProjectVisible, setIsPublishProjectVisible] = useState(false);
  const [isCompleteProjectVisible, setIsCompleteProjectVisible] = useState(false);
  const [isFinalizeDirectSelectionProjectVisible, setIsFinalizeDirectSelectionProjectVisible] = useState(false);
  const [isSubmitUCNVisible, setIsSubmitUCNVisible] = useState(false);
  const [isConvertToDirectSelectionVisible, setIsConvertToDirectSelectionVisible] = useState(false);
  const [anchorElement, setAnchorElement] = useState(undefined);
  const { id } = useParams();

  const onClickOpenSendProjectModal = () => setIsSendProjectVisible(true);

  const onClickCloseSendProjectModal = () => setIsSendProjectVisible(false);

  const onClickOpenPublishProjectModal = () => setIsPublishProjectVisible(true);

  const onClickClosePublishProjectModal = () => setIsPublishProjectVisible(false);

  const onClickOpenCompleteProjectModal = () => setIsCompleteProjectVisible(true);

  const onClickCloseCompleteProjectModal = () => setIsCompleteProjectVisible(false);

  const onClickOpenFinalizeDirectSelectionProjectModal = () => setIsFinalizeDirectSelectionProjectVisible(true);

  const onClickCloseFinalizeDirectSelectionProjectModal = () => setIsFinalizeDirectSelectionProjectVisible(false);

  const onClickOpenSubmitUCNModal = () => setIsSubmitUCNVisible(true);

  const onClickCloseSubmitUCNModal = () => setIsSubmitUCNVisible(false);

  const onClickOpenConvertToDirectSelectionModal = () => setIsConvertToDirectSelectionVisible(true);

  const onClickCloseConvertToDirectSelectionModal = () => setIsConvertToDirectSelectionVisible(false);

  const onOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorElement(undefined);
  };

  const onClickDownloadPdf = () => {
    setAnchorElement(undefined);
    downloadFile(`/projects/${id}/?export=pdf`, "pdf");
  };

  const onClickSendProjectRequest = async () => {
    await sendProjectRequest();
    onClickCloseSendProjectModal();
    refreshProjectFunction();
  };

  const onClickPublishProjectRequest = async () => {
    await publishProjectRequest();
    onAfterSubmitPublish();
    setIsPublishProjectVisible(false);
    refreshProjectFunction();
  };

  const onClickCompleteProjectRequest = async () => {
    await updateProjectDetails(completeCfeiFormValues);
    onClickCloseCompleteProjectModal();
    refreshProjectFunction();
  };

  const onClickFinalizeDirectSelectionProjectRequest = async () => {
    await updateProjectDetails({
      justification: finalizeDirectSelectionProjectForm?.justification,
      completed_reason: finalizeDirectSelectionProjectForm?.completed_reason,
      completed_retention: finalizeDirectSelectionProjectForm?.completed_reason?.startsWith("accepted_retention") ? finalizeDirectSelectionProjectForm?.completed_retention : undefined,
      cancellation_explanation: finalizeDirectSelectionProjectForm?.completed_reason?.startsWith("cancelled") ? finalizeDirectSelectionProjectForm?.cancellation_explanation : undefined,
    });
    onClickCloseFinalizeDirectSelectionProjectModal();
    refreshProjectFunction();
  };

  const onClickSubmitUNC = async () => {
    await submitUCN();
    onClickCloseSubmitUCNModal();
    refreshProjectFunction();
  };

  const handleConvertCnToDirectSelection = async () => {
    await convertCnToDirectSelection(
      {
        ...convertCnToDirectSelectionFormValues,
        start_date: formatDateToBeSent(convertCnToDirectSelectionFormValues?.start_date),
        end_date: formatDateToBeSent(convertCnToDirectSelectionFormValues?.end_date),
      },
      id,
    );
    onClickCloseConvertToDirectSelectionModal();
    refreshProjectFunction();
  };

  const headerButtons = getHeaderButtons(
    project?.application_status,
    project?.status,
    type,
    role,
    isProjectPublished,
    isCompleted,
    isCreator,
    isFocalPoint,
    isAdvEd,
    isAdvEdPlus,
    isAdvEdSecretariat,
    isPAM,
    isFinalizeAllowed,
    hasSendPermission,
    hasPublishPermission,
    isDeadlinePassed,
    isClarificationDeadlinePassed,
    onClickOpenSendProjectModal,
    onClickOpenPublishProjectModal,
    onClickOpenCompleteProjectModal,
    onClickOpenFinalizeDirectSelectionProjectModal,
    hasDsrConvertPermission,
    hasSubmitPermission,
    cfeiConverted,
    partnerVerified,
    isMFT,
    isPartnerVerified,
    isPartnerProfileComplete,
    isPinned,
    onClickTogglePin,
    onClickOpenSubmitUCNModal,
    onClickOpenConvertToDirectSelectionModal,
    hasPinPermission,
  );

  const menuItems = getHeaderMenuItems(
    project?.application_status,
    project?.status,
    onClickDownloadPdf,
    isProjectPublished,
    isCreator,
    isFocalPoint,
    isDeadlinePassed,
    isSend,
    hasManageDraftPermission,
    hasInviteSentPermission,
    hasEditSentPermission,
    hasInvitePublishPermission,
    hasCancelPublishPermission,
    hasManageReviewersPermission,
    hasEditPublishedDatesPermission,
    isPublishPermissionAllowed,
    hasRecommendedPartner,
    hasEditDraftPermission,
    hasDeleteDraftPermission,
    hasEditPublishedPermission,
    isEditPublishedPermission,
    hasCancelPermission,
    hasDeletePermission,
    hasEditPermission,
    hasPinPermission,
    type,
    role,
    isAdvEd,
    isAdvEdPlus,
    isAdvEdSecretariat,
    isCompleted,
    isMFT,
    onClickEdit,
    onClickDeleteOpenProject,
    onClickDeleteDirectSelectionProject,
    onClickDeleteUnsolicitedConceptNote,
    onClickCancel,
    onClickInvitePartners,
    onClickManageReviewers,
  );

  return (
    <>
      <HeaderNavigation
        handleChange={onChangeTab}
        index={selectedTab}
        backButtonPath={`/cfei/${project?.display_type ? (project?.display_type === "Dir" ? "direct" : "open") : "unsolicited"}`}
        title={getTitle(project, type)}
        header={getHeder(project, type, role, headerButtons, anchorElement, onOpenMenu, onCloseMenu, menuItems)}
        tabs={tabs}
      >
        <MainContent>{children}</MainContent>
      </HeaderNavigation>
      <Modal
        isOpen={isCompleteProjectVisible}
        onClose={onClickCloseCompleteProjectModal}
        title={formatMessage({
          id: "pages.project.details.cmp.container.idx.title",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseCompleteProjectModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickCompleteProjectRequest}
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.save",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <Alert
            type="info"
            content={formatMessage({
              id: "pages.project.details.cmp.container.idx.header",
            })}
            withBottomMargin
          />
          <CompleteCfeiFrom status={projectStatus} type={type} hasWinners={hasWinners} />
        </>
      </Modal>
      {/* Send Project Modal */}
      <Modal
        isOpen={isSendProjectVisible}
        onClose={onClickCloseSendProjectModal}
        title={formatMessage({
          id: "pages.project.details.cmp.container.idx.titleOne",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseSendProjectModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickSendProjectRequest}
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.send",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <Alert
            type="info"
            content={formatMessage({
              id: "pages.project.details.cmp.container.idx.info",
            })}
            withBottomMargin
          />
        </>
      </Modal>
      {/* Publish Project Modal */}
      <Modal
        isOpen={isPublishProjectVisible}
        onClose={onClickClosePublishProjectModal}
        title={formatMessage({
          id: "pages.project.details.cmp.container.idx.titleTwo",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickClosePublishProjectModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickPublishProjectRequest}
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.publish",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.container.idx.infoOne",
            })}
          </span>
        </>
      </Modal>
      <Modal
        isOpen={isFinalizeDirectSelectionProjectVisible}
        onClose={onClickCloseFinalizeDirectSelectionProjectModal}
        title={formatMessage({
          id: "pages.project.details.cmp.container.idx.titleThree",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseFinalizeDirectSelectionProjectModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickFinalizeDirectSelectionProjectRequest}
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.saveOne",
                })}
              />
            </div>
          </>
        }
      >
        <FinalizeDirectSelectionProjectForm status={projectStatus} type={type} formValues={finalizeDirectSelectionProjectForm} />
      </Modal>
      <ConfirmModal
        isOpen={isSubmitUCNVisible}
        onSubmit={onClickSubmitUNC}
        onClose={onClickCloseSubmitUCNModal}
        message={formatMessage({
          id: "pages.project.details.cmp.container.idx.infoTwo",
        })}
        type="warning"
      />
      <Modal
        isOpen={isConvertToDirectSelectionVisible}
        onClose={onClickCloseConvertToDirectSelectionModal}
        title={formatMessage({
          id: "pages.project.details.cmp.container.idx.titleFour",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseConvertToDirectSelectionModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={handleConvertCnToDirectSelection}
                text={formatMessage({
                  id: "pages.project.details.cmp.container.idx.saveTwo",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <Alert
            type="info"
            content={`${formatMessage({
              id: "pages.project.details.cmp.container.idx.headerOne",
            })} ${project?.partner?.legal_name}`}
            withBottomMargin
          />
          <ConvertToDirectSelectionForm directJustificationsOptions={directJustificationsOptions} officeId={officeId} />
        </>
      </Modal>
    </>
  );
};

Container.propTypes = {};

const mapStateToProps = (state) => ({
  completeCfeiFormValues: state?.form?.completeCfeiForm?.values,
  finalizeDirectSelectionProjectForm: state?.form?.finalizeDirectSelectionProjectForm?.values,
  convertCnToDirectSelectionFormValues: state?.form?.convertToDirectSelectionForm?.values,
  officeId: state?.session?.officeId,
  directJustificationsOptions: adaptJustifications(state?.partnerProfileConfig["direct-justifications"]),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  sendProjectRequest: () => dispatch(sendProjectRequest(undefined, { id: ownProps?.project?.id })),
  publishProjectRequest: () => dispatch(publishProjectRequest(undefined, { id: ownProps?.project?.id })),
  updateProjectDetails: (body) => dispatch(updateProjectDetails(body, { id: ownProps?.project?.id })),
  submitUCN: () => dispatch(postUCN(undefined, { id: ownProps?.project?.id })),
  convertCnToDirectSelection: (body, applicationId) => dispatch(convertCnToDirectSelection(body, { applicationId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import MainContent from "@local/components/main-content-wrapper";
import { PROJECT_TYPES, ROLES } from "@local/legacy-utils/constants";
import Button from "@local/components/button";

const getHederButton = (id, type, role, onClickHeaderButton, hasOpenProjectPermission, hasDirectProjectPermission) => {
  if (!id && role === ROLES.AGENCY && ((hasOpenProjectPermission && type === PROJECT_TYPES.OPEN) || (hasDirectProjectPermission && type === PROJECT_TYPES.DIRECT))) {
    switch (type) {
      case PROJECT_TYPES.OPEN:
        return (
          <Button
            text={formatMessage({
              id: "pages.projects.list.cmp.container.idx.open",
            })}
            onClick={onClickHeaderButton}
          />
        );
      case PROJECT_TYPES.DIRECT:
        return (
          <Button
            text={formatMessage({
              id: "pages.projects.list.cmp.container.idx.direct",
            })}
            onClick={onClickHeaderButton}
          />
        );
      default:
        return undefined;
    }
  }
  return undefined;
};

const Container = ({ isRestrictedPath, tabs, hasPermission, children, role, type, id, onClickHeaderButton, hasOpenProjectPermission, hasDirectProjectPermission }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isRestrictedPath || !hasPermission) history.push("/");
  }, []);

  const handleChange = (event, index) => {
    history.push({
      pathname: `/cfei/${tabs[index].path}`,
      query: location.query,
    });
  };

  const index = tabs.findIndex((tab) => tab.path === type);
  if (index === -1) history.push("/");

  if (hasPermission) {
    return (
      <HeaderNavigation
        index={index}
        title={formatMessage({
          id: "pages.projects.list.cmp.container.idx.title",
        })}
        tabs={tabs}
        header={getHederButton(id, type, role, onClickHeaderButton, hasOpenProjectPermission, hasDirectProjectPermission)}
        handleChange={handleChange}
      >
        <MainContent>{index !== -1 ? children : undefined}</MainContent>
      </HeaderNavigation>
    );
  }

  return <></>;
};

Container.propTypes = {};

export default Container;

/* eslint-disable no-case-declarations */

import { projectTypeColor, projectStatusColor } from "@local/styles/muiTheme";
import { PROJECT_TYPES, PROJECT_STATUSES } from "@local/legacy-utils/constants";
import { projectsTypeLabel } from "./constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getKeyName, getLocationObject } from "@local/utils";

const getBooleanString = (value) => {
  if (value === true) return "true";
  return "false";
};

export const adaptTabs = (tabs) =>
  tabs.map((tab, key) => ({
    id: key,
    path: tab.path,
    label: tab.path,
    ...tab,
  }));

export const getProjectTypeColor = (type) => projectTypeColor[type];

export const getProjectTypeLabel = (type) => projectsTypeLabel[type];

export const getProjectStatusColor = (type) => projectStatusColor[type];

export const adaptValues = (values) => Object.keys(values).map((key) => ({ value: key, label: values[key] }));

export const adaptDirectSelectionCompletedReasons = (values, ownProps, hasWinners) =>
  Object.keys(values)
    .map((key) => ({ value: key, label: values[key] }))
    .map((item) => ({
      ...item,
      disabled: (item.value.startsWith("accepted") && !hasWinners) || (ownProps.status === PROJECT_STATUSES.OPE && ownProps.type !== PROJECT_TYPES.DIRECT),
    }));

export const adaptOpenProjectCompletedReasons = (reasons, status, type, hasWinners) =>
  adaptValues(reasons)
    .map((item) => {
      const reviewStarted = status === PROJECT_STATUSES.OPE && type !== PROJECT_TYPES.DIRECT;
      let extraLabel;
      switch (item.value) {
        case "cancelled":
          extraLabel = formatMessage({
            id: "pages.project.details.utils.adapters.cancelled",
          });
          break;
        case "no_candidate":
          extraLabel = formatMessage({
            id: "pages.project.details.utils.adapters.noCandidate",
          });
          break;
        case "partners":
          extraLabel = formatMessage({
            id: "pages.project.details.utils.adapters.partners",
          });
          break;
        default:
          break;
      }
      if (item.value === "no_candidate" && reviewStarted) {
        return { disabled: true, ...item, extraLabel };
      } else if (item.value === "partners" && !hasWinners) {
        return { disabled: true, ...item, extraLabel };
      }
      return { ...item, extraLabel };
    })
    .sort((a, b) => (a.value > b.value ? 1 : -1));

export const adaptTimePeriodsValues = (timePeriods) => adaptValues(timePeriods);

export const adaptEditProjectInitialFormValues = (project, type, sectors, allCountries) => {
  if (!project) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }
  let attachmentsFormValues = {};
  let attachments = project?.attachments ?? [];
  if (attachments?.length === 0) attachments = [{ uniqueId: 0 }];
  attachments = attachments.map((item, index) => ({
    uniqueId: item?.id ?? index,
    originalId: item?.id ?? undefined,
    file: item.file,
    description: item.description,
  }));
  attachments.forEach((item) => {
    attachmentsFormValues[getKeyName("attachment", item.uniqueId)] = item.originalId
      ? {
          file_field: item.file.file_field,
          id: item.file.id,
        }
      : undefined;
    attachmentsFormValues[getKeyName("attachment_description", item.uniqueId)] = item.description;
  });
  let selectedLocations = [];
  let countries = new Set();
  if (type === PROJECT_TYPES.UNSOLICITED) {
    project?.locations_proposal_of_eoi?.forEach(async (item) => {
      countries.add(item.admin_level_1.country_code);
      const toAdd = await getLocationObject({ latitude: parseFloat(item.lat), longitude: parseFloat(item.lon) }, allCountries, [item?.country_code]);
      selectedLocations.push(toAdd);
    });
  } else {
    project?.locations?.forEach(async (item) => {
      countries.add(item.admin_level_1.country_code);
      const toAdd = await getLocationObject({ latitude: parseFloat(item.lat), longitude: parseFloat(item.lon) }, allCountries, [item?.admin_level_1?.country_code]);
      selectedLocations.push(toAdd);
    });
  }

  let formValues = {
    countries: Array.from(countries),
    focal_points: project?.focal_points ?? [],
    population: project?.population ?? undefined,
    description: project?.description ?? "",
    goal: project?.goal ?? "",
    indicative_budget: project?.indicative_budget,
    indicative_budget_currency: project?.indicative_budget_currency,
    other_information: project?.other_information,
    start_date: new Date(project?.start_date),
    end_date: new Date(project?.end_date),
    ...attachmentsFormValues,
  };

  let stateValues = {
    attachments,
    selectedLocations,
  };

  switch (type) {
    case PROJECT_TYPES.OPEN:
      let criteriaFormValues = {};
      let criteria = project?.assessments_criteria ?? [];
      if (criteria.length === 0) criteria = [{ uniqueId: 0 }];
      criteria = criteria.map((item, index) => ({
        uniqueId: item?.id ?? index,
        originalId: item?.selection_criteria ?? undefined,
        selection_criteria: item?.selection_criteria ?? undefined,
        description: item?.description ?? undefined,
        weight: item?.weight ?? undefined,
      }));
      criteria.forEach((item) => {
        criteriaFormValues[getKeyName("selection_criteria", item.uniqueId)] = item.selection_criteria;
        criteriaFormValues[getKeyName("selection_criteria_description", item.uniqueId)] = item.description;
        criteriaFormValues[getKeyName("selection_criteria_weight", item.uniqueId)] = item.weight;
      });

      formValues = {
        ...formValues,
        title: project?.title ?? "",
        clarification_request_deadline_date: new Date(project?.clarification_request_deadline_date),
        deadline_date: new Date(project?.deadline_date),
        notif_results_date: new Date(project?.notif_results_date),
        has_weighting: getBooleanString(project?.has_weighting),
        is_cn_substitute: project?.is_cn_substitute,
        cn_template: { id: undefined, file_field: project?.cn_template },
        sectors: sectors.filter((sector) => project?.specializations.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.specializations?.map((item) => item.id),
        ...criteriaFormValues,
      };

      stateValues = {
        ...stateValues,
        criteria,
      };
      break;
    case PROJECT_TYPES.DIRECT:
      formValues = {
        ...formValues,
        title: project?.title ?? "",
        ds_attachment: project?.direct_selected_partners?.at(0)?.ds_attachment,
        ds_justification_select: project?.direct_selected_partners?.at(0)?.ds_justification_select,
        is_cn_required: project?.direct_selected_partners?.at(0)?.is_cn_required,
        justification_reason: project?.direct_selected_partners?.at(0)?.justification_reason,
        partner: {
          id: project?.direct_selected_partners?.at(0)?.partner_id,
          legal_name: project?.direct_selected_partners?.at(0)?.partner_name,
        },
        sectors: sectors.filter((sector) => project?.specializations.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.specializations?.map((item) => item.id),
      };
      break;
    case PROJECT_TYPES.UNSOLICITED:
      formValues = {
        ...formValues,
        agency: project?.agency?.id,
        cn: project?.cn,
        title: project?.proposal_of_eoi_details?.title ?? "",
        ds_attachment: project?.direct_selected_partners?.at(0)?.ds_attachment,
        ds_justification_select: project?.direct_selected_partners?.at(0)?.ds_justification_select,
        is_cn_required: project?.is_cn_required,
        justification_reason: project?.direct_selected_partners?.at(0)?.justification_reason,
        partner: {
          id: project?.direct_selected_partners?.at(0)?.partner_id,
          legal_name: project?.direct_selected_partners?.at(0)?.partner_name,
        },
        sectors: sectors.filter((sector) => project?.proposal_of_eoi_details?.specializations.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.proposal_of_eoi_details?.specializations?.map((item) => item.id),
      };
      break;
    default:
      break;
  }
  return {
    formValues,
    stateValues,
  };
};

export const getFileName = (fileField) => fileField?.split("/").pop();

export const adaptFocalPointsOptions = (focalPoints) =>
  focalPoints.map((focalPoint) => ({
    ...focalPoint,
    label: `${focalPoint.name} (${focalPoint.email})`,
  }));

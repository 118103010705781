import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import { getStatusColor } from "./";
import { adaptProjectCountries } from "./adapters";
import { ROLES } from "@local/utils/constants";
import i18n from "@local/i18next/i18n";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// POST_REFACTORING_TODO: These functions are too heavy, check if they can be memoized

export const getDraftProjectColumns = (onClickOpenDraftModal, onClickDeleteDraft) => {
  let result = [];
  result.push({
    id: "id",
    title: "Id",
    width: "10%",
    type: "text",
  });

  result.push({
    id: "title",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.projectTitleFirst",
    }),
    width: "100%",
    type: "custom",
    render: (row) => {
      return <div style={{ display: "flex" }}>{row.eoi_data.title}</div>;
    },
  });
  result.push({
    id: "action",
    title: i18n.t("Actions"),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: ".5rem" }}>
            <IconButton size="small" onClick={() => onClickOpenDraftModal({ id: row.id, ...row.eoi_data })}>
              <EditIcon />
            </IconButton>
          </div>
          <div style={{ marginRight: ".5rem" }}>
            <IconButton size="small" onClick={() => onClickDeleteDraft(row.id)}>
              <DeleteIcon style={{ color: "#FF0800" }} />
            </IconButton>
          </div>
        </div>
      );
    },
  });
  return result;
};

export const getOpenProjectColumns = (role, sectors, countries) => {
  let result = [];
  result.push({
    id: "title",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.projectTitleFirst",
    }),
    type: "link",
    to: (row) => `/cfei/open/${row.id}/overview`,
  });
  if (role === ROLES.PARTNER) {
    result.push({
      id: "country",
      title: formatMessage({ id: "pages.projects.list.utils.columns.country" }),
      type: "custom",
      render: (row) => <span>{adaptProjectCountries(row.country_code, countries)}</span>,
    });
  } else {
    result.push({
      id: "country_location",
      title: formatMessage({
        id: "pages.projects.list.utils.columns.countryAndLocation",
      }),
      type: "custom",
      render: (row) => (
        <Tooltip title={row.location_points.map((item) => item?.admin_level_1?.name ?? "--").join(", ")} arrow>
          <span>{adaptProjectCountries(row.country_code, countries)}</span>
        </Tooltip>
      ),
    });
  }
  result.push({
    id: "specializations",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.sectorAndAreaOfSpecializationFirst",
    }),
    type: "custom",
    render: (row) => {
      const rowSpecializationsIds = row.specializations.map((specialization) => specialization.id);
      const getSectorSpecializationsIds = (sector) => sector.specializations.map((specialization) => specialization.id);
      const rowSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => rowSpecializationsIds.includes(sectorSpecializationId)));
      return (
        <Tooltip title={row.specializations.map((item) => item.name).join(", ")} arrow>
          <span>{rowSectors.map((item) => item.name).join(", ")}</span>
        </Tooltip>
      );
    },
  });
  if (role === ROLES.PARTNER) {
    result.push({
      id: "agency_name",
      title: formatMessage({
        id: "pages.projects.list.utils.columns.agencyName",
      }),
      type: "custom",
      render: (row) => <span>{row?.agency?.name}</span>,
    });
  }
  if (role === ROLES.AGENCY) {
    result.push({
      id: "code_cfei_focal",
      title: formatMessage({
        id: "pages.projects.list.utils.columns.codeCfeiFocal",
      }),
      type: "custom",
      render: (row) => {
        return (
          <Tooltip title={row.focal_points.map((item) => item.name).join(", ")} arrow>
            <span>{row.displayID}</span>
          </Tooltip>
        );
      },
    });
    result.push({
      id: "created",
      title: formatMessage({ id: "pages.projects.list.utils.columns.created" }),
      type: "date",
    });
  }
  result.push({
    id: "deadline_date",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.applicationDeadline",
    }),
    type: "date",
  });
  if (role === ROLES.AGENCY) {
    result.push({
      id: "status",
      title: formatMessage({ id: "pages.projects.list.utils.columns.status" }),
      type: "custom",
      render: (row) => {
        return <Chip icon={<CircleIcon style={{ fill: getStatusColor(row.status) }} fontSize="small" />} label={formatMessage({ id: `utils.constants.${row.status}` })} />;
      },
    });
  }
  if (role === ROLES.PARTNER) {
    result.push({
      id: "start_date",
      title: formatMessage({
        id: "pages.projects.list.utils.columns.startDate",
      }),
      type: "date",
    });
  }
  return result;
};

export const getDirectProjectColumns = (sectors, countries) => [
  {
    id: "title",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.projectTitleSecond",
    }),
    type: "link",
    to: (row) => `/cfei/direct/${row.id}/overview`,
  },
  {
    id: "displayID_dsr_focal_points",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.displayIdDsrFocalPoint",
    }),
    type: "custom",
    render: (row) => {
      return (
        <Tooltip title={row.dsr_focal_points} arrow>
          <span>{row.displayID}</span>
        </Tooltip>
      );
    },
  },
  {
    id: "selected_partners",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.selectedPartners",
    }),
    type: "custom",
    render: (row) => {
      const text = row.partner_offer_status.map((item) => item.legal_name).join(", ");
      return (
        <Tooltip title={text} arrow>
          <span>{text}</span>
        </Tooltip>
      );
    },
  },
  {
    id: "country_location",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.countryAndLocation",
    }),
    type: "custom",
    render: (row) => (
      <Tooltip title={row.location_points.map((item) => item?.admin_level_1?.name ?? "--").join(", ")} arrow>
        <span>{adaptProjectCountries(row.country_code, countries)}</span>
      </Tooltip>
    ),
  },
  {
    id: "specializations",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.sectorAndAreaOfSpecializationSecond",
    }),
    type: "custom",
    render: (row) => {
      const rowSpecializationsIds = row.specializations.map((specialization) => specialization.id);
      const getSectorSpecializationsIds = (sector) => sector.specializations.map((specialization) => specialization.id);
      const rowSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => rowSpecializationsIds.includes(sectorSpecializationId)));
      return (
        <Tooltip title={row.specializations.map((item) => item.name).join(", ")} arrow>
          <span>{rowSectors.map((item) => item.name).join(", ")}</span>
        </Tooltip>
      );
    },
  },
  {
    id: "selected_source",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.selectedSource",
    }),
  },
  {
    id: "created",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.datePosted",
    }),
    type: "date",
  },
  {
    id: "status",
    title: formatMessage({ id: "pages.projects.list.utils.columns.status" }),
    type: "custom",
    render: (row) => {
      return <Chip icon={<CircleIcon style={{ fill: getStatusColor(row.status) }} fontSize="small" />} label={formatMessage({ id: `utils.constants.${row.status}` })} />;
    },
  },
];

export const getUnsolicitedProjectColumns = (sectors, countries) => [
  {
    id: "project_title",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.projectTitle",
    }),
    type: "link",
    to: (row) => `/cfei/unsolicited/${row.id}/overview`,
  },
  {
    id: "agency_name",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.agencyName",
    }),
  },
  {
    id: "partner_name",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.partnerName",
    }),
  },
  {
    id: "country",
    title: formatMessage({ id: "pages.projects.list.utils.columns.country" }),
    type: "custom",
    render: (row) => <span>{adaptProjectCountries(row.country, countries)}</span>,
  },
  {
    id: "specializations",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.sectorAndAreaOfSpecializationThird",
    }),
    type: "custom",
    render: (row) => {
      const rowSpecializationsIds = row.specializations.map((specialization) => specialization.id);
      const getSectorSpecializationsIds = (sector) => sector.specializations.map((specialization) => specialization.id);
      const rowSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => rowSpecializationsIds.includes(sectorSpecializationId)));
      return (
        <Tooltip title={row.specializations.map((item) => item.name).join(", ")} arrow>
          <span>{rowSectors.map((item) => item.name).join(", ")}</span>
        </Tooltip>
      );
    },
  },
  {
    id: "submission_date",
    title: formatMessage({
      id: "pages.projects.list.utils.columns.submissionDate",
    }),
    type: "date",
  },
  {
    id: "is_direct",
    title: formatMessage({ id: "pages.projects.list.utils.columns.isDirect" }),
    type: "custom",
    render: (row) =>
      row.is_direct ? (
        <span>
          {formatMessage({
            id: "pages.projects.list.utils.columns.isCnRequiredYes",
          })}
        </span>
      ) : (
        <span>
          {formatMessage({
            id: "pages.projects.list.utils.columns.isCnRequiredNo",
          })}
        </span>
      ),
  },
];

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { initSession } from "@local/legacy-reducers/session";
import getTheme from "@local/styles/muiTheme";

class NonAuth extends Component {
  componentWillMount() {
    const { sessionInit } = this.props;
    let role = window.localStorage.role;
    if (!role) {
      role = "partner";
      window.localStorage.setItem("role", role);
    }
    sessionInit({ role });
  }

  render() {
    const { children } = this.props;
    return <ThemeProvider theme={getTheme()}>{children}</ThemeProvider>;
  }
}

NonAuth.propTypes = {
  sessionInit: PropTypes.func,
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  status: state.session.state,
});

const mapDispatchToProps = (dispatch) => ({
  sessionInit: (session) => dispatch(initSession(session)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NonAuth);

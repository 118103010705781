import React, { useState } from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import registrationSteps from "@local/assets/images/registrationsteps.png";
import style from "./style.module.scss";

const RegistrationModal = ({ isOpen, onClose, onSubmit }) => {
  const [checked, setChecked] = useState(false);
  const onClickCheckConfirmation = () => setChecked(!checked);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.login.cmp.registration.modal.idx.title",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.login.cmp.registration.modal.idx.register",
              })}
              isDisabled={!checked}
            />
          </div>
        </>
      }
      small
    >
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <p>
            {formatMessage({
              id: "pages.login.cmp.registration.modal.idx.description",
            })}
          </p>
          <p>
            {formatMessage({
              id: "pages.login.cmp.registration.modal.idx.descriptionSteps",
            })}
          </p>
          <Checkbox className={style.confirmCheckbox} id="confirmCheckbox" value="checked" onChange={onClickCheckConfirmation} />
          <label htmlFor="confirmCheckbox" className={style.confirmLabel}>
            {formatMessage({
              id: "pages.login.cmp.registration.modal.idx.confirm",
            })}
          </label>
        </Grid>
        <Grid item xs={8}>
          <div className={style.identityImage}>
            <img alt="" src={registrationSteps} />
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

RegistrationModal.propTypes = {};

export default RegistrationModal;

import React, { useState } from "react";
import HeaderNavigation from "@local/components/header-navigation";
import MainContent from "@local/components/main-content-wrapper";
import style from "./style.module.scss";
import VerificationIcon from "@local/components/verification-icon";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { FLAG_TYPES, ROLES } from "@local/utils/constants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import { checkPermission, AGENCY_PERMISSIONS, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GppGoodIcon from "@mui/icons-material/GppGood";
import AlertModal from "@local/components/alert-modal";
import VerifyModal from "./components/verify-modal";
import ObservationModal from "./components/observation-modal";
import { createPartnerVerification, getPartnerProfileVerificationsList, getFlagsList, createPartnerFlag } from "@local/actions";
import { toast } from "react-toastify";
import { isValid, reset } from "redux-form";
import ConfirmModal from "@local/components/confirm-modal";
import { adaptCategoryRisks, adaptFlagTypes } from "@local/utils/adapters";
import { downloadFile } from "@local/utils/network";
import Chip from "@mui/material/Chip";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import SanctionStatus from "@local/components/sanction-status";

// POST_REFACTORING_TODO: Permission notification to be replaced with something different

const getTitle = (partner, isSpecialPartner, role) => {
  const text = partner.legal_name;
  const profileStatus = partner?.partner_additional?.profile_status;

  return (
    <div className={style.titleWrapper}>
      <span>{text}</span>
      <div className={style.iconsWrapper}></div>
      {partner?.is_hq ? (
        <div className={style.hqLabelWrapper}>
          <Chip size="small" color="primary" label="HQ" />
        </div>
      ) : undefined}
      {!isSpecialPartner && role !== "partner" ? (
        <div className={style.headerActionsWrapper}>
          <div className={style.verificationIconWrapper}>
            <VerificationIcon profileStatus={profileStatus} />
          </div>
          <SanctionStatus sanction={partner?.partner_additional?.sanctions_status} />
        </div>
      ) : undefined}
      {partner?.is_locked ? (
        <div>
          <Chip
            size="small"
            style={{ backgroundColor: "#e84033", color: "#FFFFFF" }}
            label={formatMessage({
              id: "pages.partner.details.cmp.container.idx.deleted",
            })}
          />
        </div>
      ) : undefined}
    </div>
  );
};

const isEditButtonVisible = (role, displayType, hasEditHqProfilePermission, hasEditProfilePermission, hq) =>
  role === ROLES.PARTNER && displayType && ((!hq && hasEditHqProfilePermission) || (hq && hasEditProfilePermission) || (displayType !== "Int" && hasEditProfilePermission));

const getHeaderMenuItems = (role, partner, hasVerifyHqPermission, hasVerifyAllCSOPermission, hasVerifyAssignedCSOPermission, agencyCountryCode, onClickDownloadPdf, onClickVerifyProfile, onClickAddObservation) => {
  if (role === ROLES.AGENCY) {
    return [
      {
        id: "partner_details_header_download",
        icon: <PictureAsPdfIcon />,
        label: formatMessage({
          id: "pages.partner.details.cmp.container.idx.download",
        }),
        onClick: onClickDownloadPdf,
        isVisible: true,
      },
      {
        id: "partner_details_header_verification",
        icon: <GppGoodIcon />,
        label: formatMessage({
          id: "pages.partner.details.cmp.container.idx.text",
        }),
        onClick: onClickVerifyProfile,
        isVisible: !partner.is_locked && (hasVerifyHqPermission || hasVerifyAllCSOPermission || (hasVerifyAssignedCSOPermission && agencyCountryCode === partner.country_code)),
      },
      {
        id: "partner_details_header_observation",
        icon: <AddCircleOutlineIcon />,
        label: formatMessage({
          id: "pages.partner.details.cmp.container.idx.textOne",
        }),
        onClick: onClickAddObservation,
        isVisible: false,
      },
    ];
  } else {
    return [
      {
        id: "partner_details_header_download",
        icon: <PictureAsPdfIcon />,
        label: formatMessage({
          id: "pages.partner.details.cmp.container.idx.download",
        }),
        onClick: onClickDownloadPdf,
        isVisible: true,
      },
    ];
  }
};

const getBackButtonPath = (role) => (role === ROLES.AGENCY ? "/partner" : -1);

const Container = ({
  selectedTab,
  children,
  tabs,
  onChangeTab,
  partner,
  partnerProfile,
  hasEditProfilePermission,
  hasEditHqProfilePermission,
  onClickHeaderButton,
  role,
  hasVerifyHqPermission,
  hasVerifyAllCSOPermission,
  hasVerifyAssignedCSOPermission,
  agencyCountryCode,
  getPartnerVerifications,
  getPartnerFlags,
  flags,
  verificationFormValues,
  resetVerificationForm,
  isValidVerificationForm,
  createPartnerVerification,
  categoryRisks,
  isValidObservationForm,
  observationFormValues,
  createPartnerFlag,
  resetObservationForm,
  observationInitialFormValues,
  isSpecialPartner,
  onClickDeleteSpecialPartner,
  onClickEditSpecialPartner,
  hasDeleteSpecialPartnerPermission,
  setShouldRefreshObservations,
  setShouldRefreshVerifications,
  flagTypes,
  hq,
  getPartnerProfileData,
}) => {
  const [anchorElement, setAnchorElement] = useState(undefined);
  const [isVerifyModalVisible, setIsVerifyModalVisible] = useState(false);
  const [isConfirmVerifyModalVisible, setIsConfirmVerifyModalVisible] = useState(false);
  const [isObservationModalVisible, setIsObservationModalVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState(undefined);
  const [initObservationValues, setInitObservationValues] = useState(undefined);
  const onOpenMenu = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorElement(undefined);
  };

  const onClickDownloadPdf = () => {
    setAnchorElement(undefined);
    downloadFile(`/partners/${partner?.id}/?export=pdf`, "pdf");
  };

  const onClickVerify = () => {
    setAnchorElement(undefined);
    const isHqVerified = partner.hq ? partner?.hq?.partner_additional?.is_verified ?? false : true;
    const hasPotentialSanctionMatch = partner?.partner_additional?.has_potential_sanction_match ?? false;
    const isProfileComplete = partner?.partner_additional?.has_finished ?? false;
    const canBeVerified = !hasPotentialSanctionMatch && isProfileComplete && isHqVerified && partner?.partner_additional?.can_be_verified;
    if (canBeVerified) {
      getPartnerFlags(partner?.partner_additional?.id);
      setIsVerifyModalVisible(true);
    } else {
      if (!isHqVerified) {
        setWarningMessage(
          formatMessage({
            id: "pages.partner.details.cmp.container.idx.verifyHq",
          }),
        );
      } else if (hasPotentialSanctionMatch) {
        setWarningMessage(
          formatMessage({
            id: "pages.partner.details.cmp.container.idx.hasSanctionMatch",
          }),
        );
      } else if (!isProfileComplete) {
        setWarningMessage(
          formatMessage({
            id: "pages.partner.details.cmp.container.idx.hasFinished",
          }),
        );
      }
    }
  };

  const onClickAddObservation = () => {
    setAnchorElement(undefined);
    setInitObservationValues(observationInitialFormValues);
    setIsObservationModalVisible(true);
  };

  const onClickCloseAlertModal = () => {
    setWarningMessage(undefined);
  };

  const onClickCloseVerifyModal = () => {
    setIsVerifyModalVisible(false);
    resetVerificationForm();
  };

  const onClickSubmitVerifyModal = async () => {
    setIsVerifyModalVisible(false);
    setIsConfirmVerifyModalVisible(true);
  };

  const onClickSubmitConfirmVerifyModal = async () => {
    try {
      await createPartnerVerification(partner?.partner_additional?.id, {
        is_cert_uploaded: verificationFormValues?.is_cert_uploaded?.toString() === "true",
        cert_uploaded_comment: verificationFormValues?.cert_uploaded_comment,
        is_mm_consistent: verificationFormValues?.is_mm_consistent?.toString() === "true",
        mm_consistent_comment: verificationFormValues?.mm_consistent_comment,
        is_indicate_results: verificationFormValues?.is_indicate_results?.toString() === "true",
        indicate_results_comment: verificationFormValues?.indicate_results_comment,
        is_yellow_flag: verificationFormValues?.is_yellow_flag?.toString() === "true" ? true : verificationFormValues?.is_yellow_flag?.toString() === "false" ? false : null,
        yellow_flag_comment: verificationFormValues?.yellow_flag_comment,
        is_rep_risk: verificationFormValues?.is_rep_risk?.toString() === "true",
        rep_risk_comment: verificationFormValues?.rep_risk_comment,
        confirm_verification: verificationFormValues?.confirm_verification?.toString() === "true",
      });
      setShouldRefreshVerifications(true);
      setIsConfirmVerifyModalVisible(false);
      getPartnerVerifications(partner?.partner_additional?.id);
      resetVerificationForm();
      getPartnerProfileData();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.container.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickSubmitObservationModal = async () => {
    try {
      await createPartnerFlag(partner?.partner_additional?.id, {
        attachment: observationFormValues?.attachment?.id,
        category: observationFormValues?.category,
        comment: observationFormValues?.comment,
        contact_email: observationFormValues?.contact_email,
        contact_person: observationFormValues?.contact_person,
        contact_phone: observationFormValues?.contact_phone,
        flag_type: observationFormValues?.flag_type,
      });
      setShouldRefreshObservations(true);
      setIsObservationModalVisible(false);
      getPartnerFlags(partner?.partner_additional?.id);
      resetObservationForm();
      setInitObservationValues(undefined);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.container.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickCancelConfirmVerifyModal = async () => {
    setIsConfirmVerifyModalVisible(false);
    setIsVerifyModalVisible(true);
  };

  const onClickCloseObservationModal = () => {
    setIsObservationModalVisible(false);
    resetObservationForm();
    setInitObservationValues(undefined);
  };

  const menuItems = getHeaderMenuItems(role, partner, hasVerifyHqPermission, hasVerifyAllCSOPermission, hasVerifyAssignedCSOPermission, agencyCountryCode, onClickDownloadPdf, onClickVerify, onClickAddObservation);

  return (
    <>
      <HeaderNavigation
        handleChange={onChangeTab}
        index={selectedTab}
        backButtonPath={getBackButtonPath(role)}
        title={getTitle(partnerProfile ?? partner, isSpecialPartner, role)}
        header={
          <div className={style.headerActionsWrapper}>
            {role === ROLES.PARTNER && partner?.last_profile_update ? (
              <div className={style.lastUpdateWrapper}>
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.container.idx.updated",
                  })}
                </span>
                <span>{formatDateForPrint(partner?.last_profile_update)}</span>
              </div>
            ) : undefined}
            {isEditButtonVisible(role, partnerProfile?.display_type, hasEditHqProfilePermission, hasEditProfilePermission, partnerProfile?.hq) ? (
              <div>
                <Button
                  text={formatMessage({
                    id: "pages.partner.details.cmp.container.idx.editButton",
                  })}
                  onClick={onClickHeaderButton}
                />
              </div>
            ) : undefined}
            {isSpecialPartner ? (
              <>
                {hasDeleteSpecialPartnerPermission ? (
                  <div>
                    <Button
                      variant="text"
                      text={formatMessage({
                        id: "pages.partner.details.cmp.container.idx.deleteButton",
                      })}
                      onClick={onClickDeleteSpecialPartner}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <Button
                    text={formatMessage({
                      id: "pages.partner.details.cmp.container.idx.editButtonOne",
                    })}
                    onClick={onClickEditSpecialPartner}
                  />
                </div>
              </>
            ) : (
              <div>
                <IconButton edge="end" onClick={onOpenMenu} color="inherit">
                  <MoreVertIcon style={{ fill: "#757575" }} />
                </IconButton>
                <Menu id="basic-menu" anchorEl={anchorElement} open={(anchorElement ?? undefined) !== undefined} onClose={onCloseMenu} MenuListProps={{ "aria-labelledby": "basic-button" }}>
                  {menuItems.map((item) =>
                    item.isVisible ? (
                      <MenuItem key={item.id} onClick={item.onClick}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText>{item.label}</ListItemText>
                      </MenuItem>
                    ) : undefined,
                  )}
                </Menu>
              </div>
            )}
          </div>
        }
        tabs={tabs}
      >
        <MainContent>{children}</MainContent>
      </HeaderNavigation>
      <AlertModal isOpen={warningMessage !== undefined} onClose={onClickCloseAlertModal} message={warningMessage} />
      <ConfirmModal
        isOpen={isConfirmVerifyModalVisible}
        onSubmit={onClickSubmitConfirmVerifyModal}
        onClose={onClickCancelConfirmVerifyModal}
        message={formatMessage({
          id: "pages.partner.details.cmp.container.idx.confirm",
        })}
      />
      <VerifyModal isOpen={isVerifyModalVisible} onClose={onClickCloseVerifyModal} partner={partner} onSubmit={onClickSubmitVerifyModal} flags={flags} isValid={isValidVerificationForm} />
      <ObservationModal
        isOpen={isObservationModalVisible}
        onClose={onClickCloseObservationModal}
        categoryRisks={categoryRisks}
        flagTypeOptions={flagTypes}
        onSubmit={onClickSubmitObservationModal}
        isValid={isValidObservationForm}
        isRiskFlagSelected={observationFormValues?.flag_type === FLAG_TYPES.YELLOW || observationFormValues?.flag_type === FLAG_TYPES.RED}
        initialValues={initObservationValues}
      />
    </>
  );
};

Container.propTypes = {};

const mapStateToProps = (state) => ({
  hasVerifyHqPermission: checkPermission(AGENCY_PERMISSIONS.VERIFY_INGO_HQ, state),
  hasVerifyAllCSOPermission: checkPermission(AGENCY_PERMISSIONS.VERIFY_CSOS_GLOBALLY, state),
  hasVerifyAssignedCSOPermission: checkPermission(AGENCY_PERMISSIONS.VERIFY_CSOS_FOR_OWN_COUNTRY, state),
  hasEditProfilePermission: checkPermission(PARTNER_PERMISSIONS.EDIT_PROFILE, state),
  hasEditHqProfilePermission: checkPermission(PARTNER_PERMISSIONS.EDIT_HQ_PROFILE, state),
  hasDeleteSpecialPartnerPermission: checkPermission(AGENCY_PERMISSIONS.SPECIAL_PARTNER_DELETION, state),
  agencyCountryCode: state.session.officeCountryCode, // POST_REFACTORING_TODO: Refactor this and check if it is needed
  role: state.session.role,
  flags: state.flagsList.data?.data?.results ?? [],
  verificationFormValues: state.form?.verificationModalForm?.values ?? {},
  isValidVerificationForm: isValid("verificationModalForm")(state),
  observationFormValues: state.form?.observationModalForm?.values ?? {},
  isValidObservationForm: isValid("observationModalForm")(state),
  flagTypes: adaptFlagTypes(state.partnerProfileConfig["flag-type-choices"]),
  categoryRisks: adaptCategoryRisks(state.partnerProfileConfig["flag-category-choices"]),
  observationInitialFormValues: {
    contact_email: state.session.email,
    contact_person: state.session.name,
    contact_phone: state.session.telephone,
  },
});

const mapDispatchToProps = (dispatch) => ({
  createPartnerVerification: (id, data) => dispatch(createPartnerVerification(data, { id })),
  createPartnerFlag: (id, data) => dispatch(createPartnerFlag(data, { id })),
  getPartnerVerifications: (id) => dispatch(getPartnerProfileVerificationsList({ page_size: 999 }, { id })),
  getPartnerFlags: (id) => dispatch(getFlagsList({ page_size: 999, partner: id })),
  resetVerificationForm: () => dispatch(reset("verificationModalForm")),
  resetObservationForm: () => dispatch(reset("observationModalForm")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);

import React, { useMemo } from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import { reduxForm, Field } from "redux-form";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import { ROLES } from "@local/legacy-utils/constants";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FieldDatePicker from "@local/components/field-date-picker";
import FieldText from "@local/components/field-text";
import Button from "@local/components/button";
import DownloadIcon from "@local/components/download-icon";
import WarningIcon from "@mui/icons-material/Warning";
import { isRequired, isBetweenDate, isValidDate } from "@local/utils/validators";
import { subtractYears } from "../../../../../../utils/adapters";

const Form = ({ key, formValues, ratingOptions, role, onClickUploadInitialAssessmentDocument, documents, documentTypes, onClickDeleteDocument, isLocked, hasEditPseaInitialAssessmentPermission, readOnly }) => {
  const isDateBetween = useMemo(() => isBetweenDate(subtractYears(new Date(), 5), new Date()), []);
  const isNotBeneficiariesVisible = formValues?.partner_beneficiaries === "false";
  const isUploadPseaVisible = formValues?.partner_is_assessed === "true";
  return (
    <div key={key}>
      <span className={style.sectionTitle}>
        {role === ROLES.AGENCY
          ? formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerResponse",
            })
          : undefined}
      </span>
      <div className={style.sectionWrapper}>
        <div className={style.questionWrapper}>
          <div>
            <span className={style.questionTitle}>
              {role === ROLES.PARTNER
                ? formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerBeneficiariesQuestion",
                  })
                : formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.agencyBeneficiariesQuestion",
                  })}
            </span>
            {role === ROLES.PARTNER ? (
              <span className={style.questionDescription}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerBeneficiariesQuestionFistDescription",
                })}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Field
              component={FieldRadioGroup}
              id="partner_beneficiaries"
              name="partner_beneficiaries"
              readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
              options={[
                {
                  value: true,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.yes",
                  }),
                },
                {
                  value: false,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.no",
                  }),
                },
              ]}
            />
          </div>
        </div>
        {isNotBeneficiariesVisible ? (
          <div className={style.secondQuestionWrapper}>
            <div className={style.subSectionWrapper}>
              <div className={style.questionWrapper}>
                <div>
                  <span className={style.questionTitle}>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerSelfAssessmentQuestion",
                    })}
                  </span>
                </div>
                <div>
                  <Field
                    component={FieldRadioGroup}
                    id="proceed_with_self_assessment"
                    name="proceed_with_self_assessment"
                    readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                    options={[
                      {
                        value: true,
                        label: formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.yes",
                        }),
                      },
                      {
                        value: false,
                        label: formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.no",
                        }),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={style.questionWrapper}>
          <div>
            <span className={style.questionTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerAssessedQuestion",
              })}
            </span>
            {role === ROLES.PARTNER ? (
              <>
                <span className={style.questionDescription}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerAssessedQuestionDescription",
                  })}
                </span>
                <span className={style.questionDescription}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerAssessedQuestionDescriptionDetails",
                  })}
                </span>
              </>
            ) : (
              <></>
            )}
            {isUploadPseaVisible ? (
              <div className={style.secondQuestionWrapper}>
                <div className={style.firstQuestionUploadFormWrapper}>
                  <div className={style.firstQuestionFilesListWrapper}>
                    {documents?.length ? (
                      <>
                        {documents.map((item) => (
                          <div key={`psea_initial_document_item_${item.id}`}>
                            <DownloadIcon
                              onClickDelete={!isLocked && (role === ROLES.PARTNER || hasEditPseaInitialAssessmentPermission) && role === ROLES.PARTNER ? () => onClickDeleteDocument(item) : undefined}
                              fileUrl={item.file.file_field}
                              fileName={item.note || documentTypes?.find((type) => type.value === item.doc_type)?.label}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  {role === ROLES.PARTNER ? (
                    <div className={style.firstQuestionUploadWrapper}>
                      <Button
                        isDisabled={isLocked || !hasEditPseaInitialAssessmentPermission || documents.length === 5}
                        text={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.uploadDocumentButton",
                        })}
                        onClick={onClickUploadInitialAssessmentDocument}
                      />
                      {!documents?.find((item) => item?.doc_type === "assessment_results") ? (
                        <div className={style.warningIcon}>
                          <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
                        </div>
                      ) : undefined}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={style.firstQuestionFormWrapper}>
                    <div>
                      <Field
                        readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                        component={FieldText}
                        validate={[isRequired]}
                        id="partner_un_agency_submitter"
                        name="partner_un_agency_submitter"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.agencySubmitterLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.agencySubmitterLabel",
                        })}
                        small
                      />
                    </div>
                    <div>
                      <Field
                        readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                        component={FieldDatePicker}
                        validate={[isRequired, isDateBetween, isValidDate]}
                        id="partner_submission_date"
                        name="partner_submission_date"
                        maximumDate={new Date()}
                        minimumDate={subtractYears(new Date(), 5)}
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.agencySubmissionDateLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.agencySubmissionDateLabel",
                        })}
                        small
                      />
                    </div>
                    <div>
                      <Field
                        readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                        component={FieldSelect}
                        validate={[isRequired]}
                        id="partner_rating"
                        name="partner_rating"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.agencyRatingFiledLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.agencyRatingFiledLabel",
                        })}
                        valueField="value"
                        labelField="extendedLabel"
                        options={ratingOptions}
                        small
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Field
              component={FieldRadioGroup}
              id="partner_is_assessed"
              name="partner_is_assessed"
              readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
              options={[
                {
                  value: true,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.yes",
                  }),
                },
                {
                  value: false,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.no",
                  }),
                },
              ]}
            />
          </div>
        </div>
        <div className={style.linksWrapper}>
          {role === ROLES.PARTNER ? (
            <div className={style.linkItemWrapper}>
              <a
                target="_blank"
                alt={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerGuidanceLink",
                })}
                href="https://www.unfpa.org/sites/default/files/admin-resource/PSEA_working_with_un.pdf"
                rel="noreferrer"
              >
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.partnerGuidanceLink",
                })}
              </a>
              <OpenInNewIcon style={{ fontSize: ".85rem", color: "#777777" }} />
            </div>
          ) : (
            <></>
          )}
          {role === ROLES.PARTNER ? (
            <div className={style.linkItemWrapper}>
              <a
                target="_blank"
                alt={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.conversionLink",
                })}
                href="https://supportcso.unpartnerportal.org/hc/en-us/articles/14883257692695-PSEA-Module-User-Guides"
                rel="noreferrer"
              >
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.answers.idx.conversionLink",
                })}
              </a>
              <OpenInNewIcon style={{ fontSize: ".85rem", color: "#777777" }} />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export const PartnerAnswers = reduxForm({
  form: "pseaInitialSectionPartnerAnswersForm",
  enableReinitialize: true,
})(Form);

PartnerAnswers.propTypes = {};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Grid } from "@mui/material";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Chip from "@mui/material/Chip";
import { getFileName } from "@local/utils/adapters";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import FieldText from "@local/components/field-text/index";
import { getApplicationFeedbackList } from "@local/actions";
import style from "./style.module.scss";

const FeedbackItem = ({ allowedToAddFeedback, feedback, isCompleted, cn, extraTitle, formValues, onClickSendFeedback, applicationId, getApplicationFeedbackList }) => {
  const refresh = () => {
    if (applicationId && isCompleted) getApplicationFeedbackList(applicationId);
  };

  useEffect(() => {
    refresh();
  }, [applicationId, isCompleted]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card
          title={
            extraTitle
              ? `${formatMessage({
                  id: "cmp.feedback.item.idx.title",
                })} ${formatMessage({
                  id: "cmp.feedback.item.idx.for",
                })} ${extraTitle}`
              : formatMessage({
                  id: "cmp.feedback.item.idx.title",
                })
          }
        >
          {!feedback?.length && !allowedToAddFeedback ? (
            <span>{isCompleted && !feedback?.length ? formatMessage({ id: "cmp.feedback.item.idx.noInfo" }) : formatMessage({ id: "cmp.feedback.item.idx.notCompleted" })}</span>
          ) : (
            <div>
              {allowedToAddFeedback && !isCompleted ? <span>{formatMessage({ id: "cmp.feedback.item.idx.sendInfo" })}</span> : <></>}
              {allowedToAddFeedback && (
                <div className={style.formWrapper}>
                  <FieldGroup>
                    <Field
                      component={FieldText}
                      id="feedback"
                      name="feedback"
                      label={formatMessage({
                        id: "cmp.feedback.item.idx.placeholder",
                      })}
                    />
                    <div className={style.buttonWrapper}>
                      <Button
                        text={formatMessage({
                          id: "cmp.feedback.item.idx.button",
                        })}
                        onClick={onClickSendFeedback}
                        isDisabled={!formValues?.feedback}
                      />
                    </div>
                  </FieldGroup>
                </div>
              )}
              {feedback?.map((feedbackItem) => (
                <div key={feedbackItem?.id} className={style.sectionWrapper}>
                  <div className={style.infoWrapper}>
                    <span className={style.info}>{`${feedbackItem?.provider?.name} ${formatMessage({
                      id: "cmp.feedback.item.idx.sendInfo",
                    })} ${feedbackItem?.provider?.agency_name}`}</span>
                    <span className={style.secondaryInfo}>{formatDateForPrint(feedbackItem?.created)}</span>
                  </div>
                  <p className={style.text}>{feedbackItem?.feedback}</p>
                </div>
              ))}
            </div>
          )}
        </Card>
      </Grid>
      {cn ? (
        <Grid item xs={12}>
          <Card
            title={formatMessage({
              id: "cmp.feedback.item.idx.conceptNoteSubmittedByThePartner",
            })}
          >
            <div>
              <p>{formatMessage({ id: "cmp.feedback.item.idx.subTitle" })}</p>
              <Chip
                className={style?.attachmentName}
                label={getFileName(cn)}
                onDelete={() => undefined}
                deleteIcon={
                  <a target="_blank" href={cn} alt={formatMessage({ id: "cmp.feedback.item.idx.file" })} rel="noreferrer">
                    <FileDownloadIcon fontSize="small" style={{ fill: "#606060" }} />
                  </a>
                }
              />
            </div>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

FeedbackItem.propTypes = {};

const mapStateToProps = (state) => ({
  feedback: state?.applicationFeedbackList?.data?.data?.results ?? [],
  formValues: state?.form?.feedbackForm?.values,
});

const mapDispatchToProps = (dispatch) => ({
  getApplicationFeedbackList: (applicationId) => dispatch(getApplicationFeedbackList(undefined, { applicationId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "feedbackForm", enableReinitialize: true })(FeedbackItem));

import React from "react";
import Main from "./components/main/index";
import { loginUser } from "@local/legacy-reducers/session";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getBaseUrl } from "@local/utils/network";

const DirectLogin = ({ login, formValues, history }) => {
  const onClickLogin = (e) => {
    e.preventDefault();
    login(formValues)
      .then(() => {
        if (window.location.href.indexOf("response_type=token") > 0) {
          history.push(`${getBaseUrl()}/api/doc/`);
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        toast.error(formatMessage({ id: "pages.direct.login.idx.loginFailed" }));
      });
  };

  return <Main onClickLogin={onClickLogin} />;
};

const mapStateToProps = (state) => ({
  formValues: state.form?.LoginForm?.values ?? {},
});

const mapDispatchToProps = (dispatch) => ({
  login: (values) => dispatch(loginUser(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DirectLogin));

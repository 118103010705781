/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { ROLES, PROJECT_TYPES } from "@local/legacy-utils/constants";
import { reset } from "redux-form";
import FeedbackItem from "@local/components/feedback-item";
import DSFeedback from "./components/direct-project-feedback";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getApplicationFeedbackList, postApplicationFeedback, getOpenProjectApplicationsAllList } from "@local/actions";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";

const ProjectFeedback = ({
  allowedToAddCNFeedback,
  allowedToAddDirectFeedback,
  createApplicationFeedback,
  feedbackFormValues,
  getOpenProjectAllApplications,
  isCompleted,
  getApplicationFeedbackList,
  applications,
  feedback,
  isCreator,
  isFocalPoint,
  resetFeedbackForm,
  role,
}) => {
  const { id } = useParams();
  const { type } = useParams();

  const refresh = () => {
    if (role === ROLES.AGENCY) getOpenProjectAllApplications(id);
    if (id && isCompleted) getApplicationFeedbackList(id);
  };

  useEffect(() => {
    refresh();
  }, [id, isCompleted]);

  const onClickSendFeedback = async () => {
    try {
      await createApplicationFeedback(id, feedbackFormValues);
      refresh();
      resetFeedbackForm();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.project.details.cmp.feedback.idx.error" }));
    }
  };

  if (type === PROJECT_TYPES.UNSOLICITED) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FeedbackItem applicationId={id} isCompleted={isCompleted} allowedToAddFeedback={allowedToAddCNFeedback} onClickSendFeedback={onClickSendFeedback} feedback={feedback} isCreator={isCreator} isFocalPoint={isFocalPoint} />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DSFeedback applicationId={id} applications={applications} isDirect={type === PROJECT_TYPES.DIRECT} allowedToAddDirectFeedback={allowedToAddDirectFeedback} feedback={feedback} isCompleted={isCompleted} />
      </Grid>
    </Grid>
  );
};

ProjectFeedback.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
  allowedToAddCNFeedback: state?.session?.role === ROLES.AGENCY,
  feedbackFormValues: state?.form?.feedbackForm?.values,
  applications: state?.openProjectApplicationsAllList?.data?.data?.results ?? [],
  feedback: state?.applicationFeedbackList?.data?.data?.results ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  getApplicationFeedbackList: (applicationId) => dispatch(getApplicationFeedbackList(undefined, { applicationId })),
  createApplicationFeedback: (applicationId, body) => dispatch(postApplicationFeedback(body, { applicationId })),
  getOpenProjectAllApplications: (projectId) => dispatch(getOpenProjectApplicationsAllList(undefined, { projectId })),
  resetFeedbackForm: () => dispatch(reset("feedbackForm")),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFeedback);

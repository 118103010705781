/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import Card from "@local/components/card";
import Modal from "@local/components/modal";
import FieldText from "@local/components/field-text";
import Button from "@local/components/button";
import StaticTable from "@local/components/static-table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import { ROLES, FEATURES_ENABLED } from "@local/legacy-utils/constants";
import { reduxForm, formValueSelector, Field, submit, reset } from "redux-form";
import { connect } from "react-redux";
import Loader from "@local/pages/common/loader";
import PartnerUnDataOverview from "./legacy-components/partnerUnDataOverview";
import { checkPermission, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import {
  partnerVendorNumberRequestDetails,
  deletePartnerVendorNumber,
  createPartnerVendorNumber,
  createPartnerVendorNumberRequest,
  deletePartnerVendorNumberRequest,
  unDocumentsList,
  getPartnerUnDocumentItem,
  getPartnerUnDocumentItemReset,
  updatePartnerUnDocument,
  createPartnerUnDocument,
  destroyPartnerUnDocument,
  getPartnerSummaryDetails,
  getUNDataList,
} from "@local/actions";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import ManageUnDocumentForm from "./components/ManageUnDocumentForm";
import { getUnDocumentColumns } from "../../utils/columns";
import { adaptFormValuesToBeSent, adaptFormInitialValues } from "../../utils/adapters";
import Alert from "@local/components/alert";
import ConfirmModal from "@local/components/confirm-modal";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "@local/legacy-utils/restrictions";

// POST_REFACTORING_TODO: The table should be separated by document type

const UnData = ({
  partnerId,
  partner,
  isEnterVendorNumberSubmitDisabled,
  session,
  agencyId,
  partnerVendorNumberRequest,
  hasPermissionEnterVendorNumber,
  agencyName,
  vendorNumberFormValue,
  getPartnerSummary,
  createPartnerVendorNumber,
  deletePartnerVendorNumber,
  createPartnerVendorNumberRequest,
  deletePartnerVendorNumberRequest,
  getPartnerVendorNumberRequest,
  getUnDocumentsList,
  getPartnerUnDocumentItem,
  getPartnerUnDocumentItemReset,
  role,
  updatePartnerUnDocument,
  partnerUnDocumentItem,
  createPartnerUnDocument,
  manageUnDocumentFormValues,
  destroyPartnerUnDocument,
  resetForm,
  isLoadingVendorNumber,
  getUNDataList,
}) => {
  const [isEnterVendorNumberModalVisible, setIsEnterVendorNumberModalVisible] = useState(false);
  const [isDeleteVendorNumberModalVisible, setIsDeleteVendorNumberModalVisible] = useState(false);
  const [isConfirmVendorNumberModalVisible, setIsConfirmVendorNumberModalVisible] = useState(false);
  const [isRequestVendorNumberModalVisible, setIsRequestVendorNumberModalVisible] = useState(false);
  const [isCancelRequestVendorNumberModalVisible, setIsCancelRequestVendorNumberModalVisible] = useState(false);
  const [vendorNumberRequestError, setVendorNumberRequestError] = useState(false);
  const [isManageUnDocumentModalVisible, setIsManageUnDocumentModalVisible] = useState(false);
  const [isDeleteUnDocumentModalVisible, setIsDeleteUnDocumentModalVisible] = useState(false);
  const [currentActiveUnDocumentId, setCurrentActiveUnDocumentId] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (role === ROLES.AGENCY) {
        try {
          setLoading(true);
          await getPartnerSummary();
          await getPartnerVendorNumberRequest(partnerId);
          await getUnDocumentsList();
          await getUNDataList(partnerId);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [role]);

  useEffect(() => {
    if (currentActiveUnDocumentId) {
      getPartnerUnDocumentItem(currentActiveUnDocumentId);
    }
  }, [currentActiveUnDocumentId, setCurrentActiveUnDocumentId]);

  const onClickEnterVendorNumber = () => {
    setIsEnterVendorNumberModalVisible(true);
  };

  const onClickDeleteVendorNumber = () => {
    setIsDeleteVendorNumberModalVisible(true);
  };

  const onClickSubmitEnterVendorNumber = () => {
    setIsEnterVendorNumberModalVisible(false);
    setIsConfirmVendorNumberModalVisible(true);
  };

  const onClickRequestVendorNumber = () => {
    setIsRequestVendorNumberModalVisible(true);
  };

  const onClickCloseRequestVendorNumber = () => {
    setIsRequestVendorNumberModalVisible(false);
  };

  const onClickOpenCancelRequestVendorNumber = () => {
    setIsCancelRequestVendorNumberModalVisible(true);
  };

  const onClickCloseCancelRequestVendorNumber = () => {
    setIsCancelRequestVendorNumberModalVisible(false);
  };

  const onClickCancelRequestVendorNumber = async () => {
    try {
      await deletePartnerVendorNumberRequest(partnerVendorNumberRequest?.id);
      await getPartnerVendorNumberRequest(partnerId);
      onClickCloseCancelRequestVendorNumber();
    } catch (error) {
      setVendorNumberRequestError(true);
    }
  };

  const onClickSubmitDeleteVendorNumber = async () => {
    try {
      const vendorNumber = partner?.vendor_numbers?.find((item) => item.agency_id === agencyId) ?? {};
      await deletePartnerVendorNumber(vendorNumber.id);
      await getPartnerVendorNumberRequest(partnerId);
      await getPartnerSummary();
      await getUNDataList(partnerId);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.unableToSaveData",
        }),
      );
    }
    setIsDeleteVendorNumberModalVisible(false);
  };

  const onClickSubmitConfirmVendorNumber = async () => {
    try {
      await createPartnerVendorNumber(vendorNumberFormValue);
      await getPartnerSummary();
      await getUNDataList(partnerId);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.unableToSaveData",
        }),
      );
    }
    setIsConfirmVendorNumberModalVisible(false);
  };

  const onClickConfirmRequestVendorNumber = async () => {
    try {
      await createPartnerVendorNumberRequest({ partner_id: partnerId });
      await getPartnerVendorNumberRequest(partnerId);
      onClickCloseRequestVendorNumber();
    } catch (error) {
      setVendorNumberRequestError(true);
    }
  };

  const onClickCloseEnterVendorNumber = () => {
    setIsEnterVendorNumberModalVisible(false);
  };

  const onClickCloseDeleteVendorNumber = () => {
    setIsDeleteVendorNumberModalVisible(false);
  };

  const onClickCloseConfirmVendorNumber = () => {
    setIsConfirmVendorNumberModalVisible(false);
  };

  const onClickAddUnDocument = async () => {
    resetForm();
    await getPartnerUnDocumentItemReset();
    setCurrentActiveUnDocumentId(undefined);
    setIsManageUnDocumentModalVisible(true);
  };

  const onClickEditUnDocument = async (document) => {
    await getPartnerUnDocumentItemReset();
    setIsManageUnDocumentModalVisible(true);
    setCurrentActiveUnDocumentId(document?.id);
  };

  const onClickCloseManageUnDocument = () => {
    setIsManageUnDocumentModalVisible(false);
    setCurrentActiveUnDocumentId(undefined);
  };

  const onClickDeleteUnDocument = (document) => {
    setIsDeleteUnDocumentModalVisible(true);
    setCurrentActiveUnDocumentId(document?.id);
  };

  const onClickCloseDeleteUnDocument = () => {
    setIsDeleteUnDocumentModalVisible(false);
    setCurrentActiveUnDocumentId(undefined);
  };

  const onFormSubmitManageUnDocument = async () => {
    try {
      const adaptedValues = adaptFormValuesToBeSent(manageUnDocumentFormValues);
      if (currentActiveUnDocumentId) await updatePartnerUnDocument(currentActiveUnDocumentId, adaptedValues);
      else await createPartnerUnDocument(partnerId, adaptedValues);
      getUnDocumentsList();
      setShouldRefresh(true);
      setCurrentActiveUnDocumentId(undefined);
      setIsManageUnDocumentModalVisible(false);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.partner.details.cmp.un.data.idx.error" }));
    }
  };

  const onClickSubmitDeleteUnDocument = async () => {
    try {
      await destroyPartnerUnDocument(currentActiveUnDocumentId);
      setShouldRefresh(true);
      onClickCloseDeleteUnDocument();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.partner.details.cmp.un.data.idx.errorOne" }));
    }
  };

  const afterRefresh = () => {
    setShouldRefresh(false);
  };

  const vendorNumber = partner?.vendor_numbers?.find((item) => item.agency_id === agencyId) ?? {};

  const vendorNumberLink = "https://www.myunfpa.org/Apps/IPIMS/app/viewIP.cfm?ip_atlas_code=";

  const adaptedUnDocumentValues = adaptFormInitialValues(partnerUnDocumentItem);

  return (
    <>
      {role === ROLES.AGENCY && (
        <Card
          title={formatMessage({
            id: "pages.partner.details.cmp.un.data.idx.vendorID",
          })}
        >
          {vendorNumber.number ? (
            <span className={style.vendorNumber}>{vendorNumber.number}</span>
          ) : (
            <>
              {partnerVendorNumberRequest?.status ? (
                <span className={style.requestedVendorNumber}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.un.data.idx.vendorNumberRequestedOn",
                  })}{" "}
                  {new Date(partnerVendorNumberRequest?.created).toLocaleDateString()}
                </span>
              ) : (
                <span className={style.missingVendorNumber}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.un.data.idx.noVendorID",
                  })}
                </span>
              )}
            </>
          )}
          {hasPermissionEnterVendorNumber ? (
            <>
              <div className={style.vendorNumberActionsWrapper}>
                {!(vendorNumber.number || (partnerVendorNumberRequest?.status ?? false)) || isSectionVisible(AGENCY_RESTRICTIONS.partners.unData["partnerCodeManually"], session) ? (
                  <>
                    <span className={style.vendorNumberActionsTitle}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.un.data.idx.requestVendorIDTitle",
                      })}
                    </span>
                    <span className={style.vendorNumberActionsSubTitle}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.un.data.idx.requestVendorIDSubtitle",
                      })}
                    </span>
                  </>
                ) : null}
                <div className={style.vendorNumberActionsColumnsWrapper}>
                  {FEATURES_ENABLED.request_partner_code_button.includes(agencyName) && !(vendorNumber.number || (partnerVendorNumberRequest?.status ?? false)) ? (
                    <div>
                      <Button
                        onClick={onClickRequestVendorNumber}
                        isDisabled={isLoadingVendorNumber}
                        text={formatMessage({
                          id: "pages.partner.details.cmp.un.data.idx.requestVendorID",
                        })}
                      />
                    </div>
                  ) : undefined}
                  {!(vendorNumber.number || partnerVendorNumberRequest?.status) && isSectionVisible(AGENCY_RESTRICTIONS.partners.unData["partnerCodeManually"], session) ? (
                    <div>
                      <Button
                        onClick={onClickEnterVendorNumber}
                        isDisabled={isLoadingVendorNumber}
                        text={formatMessage({
                          id: "pages.partner.details.cmp.un.data.idx.enterVendorID",
                        })}
                      />
                    </div>
                  ) : undefined}
                  {FEATURES_ENABLED.request_partner_code_button.includes(agencyName) && partnerVendorNumberRequest?.id && (partnerVendorNumberRequest?.status !== "received" ?? false) ? (
                    <div>
                      <Button
                        onClick={onClickOpenCancelRequestVendorNumber}
                        isDisabled={isLoadingVendorNumber}
                        text={formatMessage({
                          id: "pages.partner.details.cmp.un.data.idx.cancelRequestVendorID",
                        })}
                        variant="text"
                      />
                    </div>
                  ) : undefined}
                  {vendorNumber.number && isSectionVisible(AGENCY_RESTRICTIONS.partners.unData["partnerCodeDelete"], session) ? (
                    <div>
                      <Button
                        onClick={onClickDeleteVendorNumber}
                        isDisabled={!vendorNumber.number || isLoadingVendorNumber}
                        text={formatMessage({
                          id: "pages.partner.details.cmp.un.data.idx.deleteVendorID",
                        })}
                        variant="text"
                      />
                    </div>
                  ) : undefined}
                  {vendorNumberRequestError ? (
                    <span className={style.vendorNumberRequestError}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.un.data.idx.vendorNumberRequestError",
                      })}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ) : undefined}
        </Card>
      )}
      {role === ROLES.AGENCY && FEATURES_ENABLED.special_partner.includes(agencyName) && (
        <div className={style.partnerUnDocumentsWrapper}>
          <Card
            title={
              <div className={style.partnerUnDocumentsHeader}>
                {formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.title",
                })}
              </div>
            }
          >
            <div className={style.partnerVendorNumberLinkFormWrapper}>
              <span className={style.label}>
                {formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.fieldLabel",
                })}
              </span>
              {vendorNumber.number ? (
                <a
                  target="_blank"
                  href={`${vendorNumberLink}${vendorNumber.number}`}
                  className={style.partnerVendorNumberLinkValue}
                  alt={formatMessage({
                    id: "pages.partner.details.cmp.un.data.idx.fieldLabel",
                  })}
                  rel="noreferrer"
                >
                  {`${vendorNumberLink}${vendorNumber.number}`}
                </a>
              ) : (
                <span className={style.error}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.un.data.idx.noLinkProvided",
                  })}
                </span>
              )}
            </div>
          </Card>
          <div className={style.partnerUnDocumentsWrapper}>
            <StaticTable
              key="un_documents_list"
              id="un_documents_list"
              title={formatMessage({
                id: "pages.partner.details.cmp.un.data.idx.TableTitle",
              })}
              columns={getUnDocumentColumns(onClickEditUnDocument, onClickDeleteUnDocument, session)}
              fetchFunction={getUnDocumentsList}
              keyColumn="id"
              actions={
                <Button
                  onClick={onClickAddUnDocument}
                  text={formatMessage({
                    id: "pages.partner.details.cmp.un.data.idx.add",
                  })}
                />
              }
              shouldRefresh={shouldRefresh}
              afterRefresh={afterRefresh}
            />
          </div>
        </div>
      )}
      <div className={style.externalDataWrapper}>
        {loading ? (
          <div className={style.externalDataLoaderWrapper}>
            <Loader loading={loading} replaceContent />
          </div>
        ) : (
          <PartnerUnDataOverview partner={partner} loading={loading} />
        )}
      </div>
      {/* Enter Vendor Number Modal */}
      <Modal
        isOpen={isEnterVendorNumberModalVisible}
        onClose={onClickCloseEnterVendorNumber}
        title={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.titleOne",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseEnterVendorNumber}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickSubmitEnterVendorNumber}
                isDisabled={isEnterVendorNumberSubmitDisabled}
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.submit",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <Alert
            type="info"
            content={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.header",
            })}
            withBottomMargin
          />
          <Field
            component={FieldText}
            id="number"
            name="number"
            label={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.vendorNumber",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.vendorNumber",
            })}
          />
        </>
      </Modal>
      {/* Confirm Vendor Number Modal */}
      <Modal
        isOpen={isConfirmVendorNumberModalVisible}
        onClose={onClickCloseConfirmVendorNumber}
        title={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.confirmTitle",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseConfirmVendorNumber}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickSubmitConfirmVendorNumber}
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.display",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <Alert
            type="info"
            content={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.confirmInfo",
            })}
            withBottomMargin
          />
          <span className={style.displayLabel}>
            {formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.partnerName",
            })}
          </span>
          <span>{partner?.legal_name}</span>
          <span className={style.displayLabel}>
            {formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.vendorIDOne",
            })}
          </span>
          <span>{vendorNumberFormValue}</span>
        </>
      </Modal>
      {/* Delete Vendor Number Modal */}
      <Modal
        isOpen={isDeleteVendorNumberModalVisible}
        onClose={onClickCloseDeleteVendorNumber}
        title={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.titleTwo",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseDeleteVendorNumber}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickSubmitDeleteVendorNumber}
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.delete",
                })}
              />
            </div>
          </>
        }
        small
      >
        <>
          <Alert
            type="warning"
            content={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.confirmInfoOne",
            })}
            withBottomMargin
          />
          <span className={style.displayLabel}>
            {formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.partnerName",
            })}
          </span>
          <span className={style.displayValue}>{partner?.legal_name}</span>
          <span className={style.displayLabel}>
            {formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.vendorIDOne",
            })}
          </span>
          <span className={style.displayValue}>{vendorNumber.number}</span>
        </>
      </Modal>
      {/* Request Vendor Number Modal */}
      <Modal
        isOpen={isRequestVendorNumberModalVisible}
        onClose={onClickCloseRequestVendorNumber}
        title={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.confirmTitleOne",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseDeleteVendorNumber}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickConfirmRequestVendorNumber}
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.confirm",
                })}
              />
            </div>
          </>
        }
      >
        <>
          <Alert
            type="info"
            content={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.confirmInfoTwo",
            })}
            withBottomMargin
          />
          <ol className={style.listWrapper}>
            <li className={style.listItemWrapper}>
              {formatMessage({
                id: "pages.partner.details.cmp.un.data.idx.first",
              })}
            </li>
            <li className={style.listItemWrapper}>
              {formatMessage({
                id: "pages.partner.details.cmp.un.data.idx.second",
              })}
            </li>
            <li className={style.listItemWrapper}>
              {formatMessage({
                id: "pages.partner.details.cmp.un.data.idx.third",
              })}
            </li>
          </ol>
        </>
      </Modal>
      {/* Cancel Request Vendor Number */}
      <ConfirmModal
        isOpen={isCancelRequestVendorNumberModalVisible}
        onClose={onClickCloseCancelRequestVendorNumber}
        onSubmit={onClickCancelRequestVendorNumber}
        title={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.cancelTitle",
        })}
        message={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.cancelInfo",
        })}
        type="warning"
      />
      {/* Add UN Document Modal */}
      <Modal
        isOpen={isManageUnDocumentModalVisible}
        onClose={onClickCloseManageUnDocument}
        title={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.confirmTitleTwo",
        })}
      >
        <>
          <Alert
            type="info"
            content={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.confirmInfoThree",
            })}
            withBottomMargin
          />
          <ManageUnDocumentForm onClickCloseManageUnDocument={onClickCloseManageUnDocument} manageUnDocument={onFormSubmitManageUnDocument} initialValues={currentActiveUnDocumentId ? adaptedUnDocumentValues : undefined} />
        </>
      </Modal>
      {/* Delete UN Document Modal */}
      <Modal
        isOpen={isDeleteUnDocumentModalVisible}
        onClose={onClickCloseDeleteUnDocument}
        title={formatMessage({
          id: "pages.partner.details.cmp.un.data.idx.confirmTitleThree",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseDeleteUnDocument}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickSubmitDeleteUnDocument}
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.idx.submitOne",
                })}
              />
            </div>
          </>
        }
        small
      >
        <>
          <Alert
            type="warning"
            content={formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.confirmInfoFour",
            })}
            withBottomMargin
          />
          <span>
            {formatMessage({
              id: "pages.partner.details.cmp.un.data.idx.message",
            })}
          </span>
        </>
      </Modal>
    </>
  );
};

const selector = formValueSelector("unDataForm");

const mapStateToProps = (state) => ({
  partner: state.partnerSummaryDetails.data?.data ?? {},
  isEnterVendorNumberSubmitDisabled: !selector(state, "number"),
  partnerVendorNumberRequest: state?.partnerVendorNumberRequestDetails.data?.data ?? {},
  hasPermissionEnterVendorNumber: checkPermission(AGENCY_PERMISSIONS.ERP_ENTER_VENDOR_NUMBER, state),
  vendorNumberFormValue: state.form?.unDataForm?.values?.number ?? undefined,
  session: state?.session,
  agencyId: state?.session?.agencyId,
  agencyName: state?.session?.agencyName,
  role: state?.session?.role,
  unDocuments: state?.unDocumentsList?.data,
  partnerUnDocumentItem: state?.getPartnerUnDocumentItem?.data?.data,
  manageUnDocumentFormValues: state?.form?.manageUnDocumentForm?.values,
  isLoadingVendorNumber:
    state?.partnerVendorNumberRequestCreate?.loading ||
    state?.partnerVendorNumberRequestDetails?.loading ||
    state?.deletePartnerVendorNumber?.loading ||
    state?.createPartnerVendorNumber?.loading ||
    !state?.partnerVendorNumberRequestDetails.data?.data ||
    !state?.partnerSummaryDetails.data?.data,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPartnerSummary: () => dispatch(getPartnerSummaryDetails(undefined, { id: ownProps.match.params.id })),
  createPartnerVendorNumber: (number) => dispatch(createPartnerVendorNumber({ number, partner: ownProps.match.params.id })),
  getPartnerVendorNumberRequest: (partnerId) => dispatch(partnerVendorNumberRequestDetails(undefined, { partnerId })),
  deletePartnerVendorNumber: (vendorId) => dispatch(deletePartnerVendorNumber({ vendorId })),
  createPartnerVendorNumberRequest: (body) => dispatch(createPartnerVendorNumberRequest(body)),
  deletePartnerVendorNumberRequest: (id) => dispatch(deletePartnerVendorNumberRequest({ id })),
  getUnDocumentsList: () => dispatch(unDocumentsList(undefined, { partnerId: ownProps.partnerId })),
  submitDocument: () => dispatch(submit("manageUnDocumentForm")),
  resetForm: () => dispatch(reset("manageUnDocumentForm")),
  createPartnerUnDocument: (partnerId, body) => dispatch(createPartnerUnDocument(body, { partnerId })),
  updatePartnerUnDocument: (id, body) => dispatch(updatePartnerUnDocument(body, { id })),
  destroyPartnerUnDocument: (id) => dispatch(destroyPartnerUnDocument({ id })),
  getPartnerUnDocumentItem: (id) => dispatch(getPartnerUnDocumentItem(undefined, { id: id })),
  getPartnerUnDocumentItemReset: () => dispatch(getPartnerUnDocumentItemReset()),
  getUNDataList: (partnerId) => dispatch(getUNDataList(undefined, { id: partnerId })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "unDataForm" })(UnData)));

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldFile from "@local/components/field-file";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@local/components/box";
import FieldCheckbox from "@local/components/field-checkbox";
import { WrapperComponent } from "../../utils";
import { isRequired, isValidDate } from "@local/utils/validators";
import DownloadIcon from "@local/components/download-icon";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldDatePicker from "@local/components/field-date-picker";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { formatDate } from "@local/utils";

// POST_REFACTORING_TODO: Checkbox instead of radio
// POST_REFACTORING_TODO: Update sidebar logo when saving

const getOtherDocumentLastIndex = (formValues) => {
  let result = 0;
  if (formValues?.other_doc_1?.id) result = 0;
  if (formValues?.other_doc_2?.id) result = 1;
  if (formValues?.other_doc_3?.id) result = 2;
  if (formValues?.other_doc_4?.id) result = 3;
  if (formValues?.other_doc_5?.id) result = 4;
  if (formValues?.other_doc_6?.id) result = 5;
  if (formValues?.other_doc_7?.id) result = 6;
  if (formValues?.other_doc_8?.id) result = 7;
  if (formValues?.other_doc_9?.id) result = 8;
  return result;
};

const OtherInformation = ({ onSubmitAndExit, isLoading, formValues, inEditMode, partner, stateValues }) => {
  const [otherDocumentLastIndex, setOtherDocumentLastIndex] = useState(undefined);
  const [accreditation, setAccreditation] = useState(undefined);

  useEffect(() => {
    if (stateValues?.accreditation && !accreditation) setAccreditation(stateValues.accreditation);
  }, [stateValues?.accreditation]);

  useEffect(() => {
    if ((formValues?.info_to_share ?? undefined) !== undefined && !otherDocumentLastIndex) setOtherDocumentLastIndex(getOtherDocumentLastIndex(formValues));
  }, [formValues?.info_to_share]);

  const onClickAddOtherDocument = () => {
    const updatedOtherDocumentLastIndex = otherDocumentLastIndex + 1;
    setOtherDocumentLastIndex(updatedOtherDocumentLastIndex);
  };

  const onChangeHaveAccreditation = (value) => {
    if (value?.toString() === "true") {
      setAccreditation([{ id: 0, originalId: undefined }]);
    } else {
      setAccreditation([]);
    }
  };

  const onClickAddAccreditation = () => {
    const updatedAccreditation = [
      ...accreditation,
      {
        id: accreditation.length ? accreditation.sort((a, b) => a.id > b.id)[accreditation.length - 1].id + 1 : 1,
        originalId: undefined,
      },
    ];
    setAccreditation(updatedAccreditation);
  };

  const onClickRemoveAccreditation = (toRemove) => {
    const updatedAccreditation = accreditation.filter((item) => item.id !== toRemove.id);
    setAccreditation(updatedAccreditation);
  };

  const onClickSaveAndExit = () => {
    onSubmitAndExit({ accreditation });
  };

  if (isLoading || !accreditation) return <CircularProgress size={50} />;

  const haveAccreditation = formValues?.any_accreditation?.toString() === "true";

  const compareVersions = (versionA, versionB) =>
    versionA.localeCompare(versionB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  const sortedDeclarations = partner?.registration_declaration?.sort((a, b) => (compareVersions(a.version, b.version) > 0 ? 1 : -1));

  return (
    <WrapperComponent
      inEditMode={inEditMode}
      title={formatMessage({
        id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.otherInformation",
      })}
      footer={
        <div className={style.cardActionsWrapper}>
          <Button
            variant="contained"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.saveAndExit",
            })}
            onClick={onClickSaveAndExit}
          />
        </div>
      }
    >
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.otherInformation",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldText}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="info_to_share"
                name="info_to_share"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.otherInfo",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.otherInfo",
                })}
                type="text"
                maxLength={5000}
                withCharactersCount
                multiline
              />
              <Box
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.docs",
                })}
                content={
                  <FieldGroup>
                    {otherDocumentLastIndex >= 0 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_1"
                        name="other_doc_1"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 1 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_2"
                        name="other_doc_2"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 2 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_3"
                        name="other_doc_3"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 3 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_4"
                        name="other_doc_4"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 4 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_5"
                        name="other_doc_5"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 5 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_6"
                        name="other_doc_6"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 6 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_7"
                        name="other_doc_7"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 7 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_8"
                        name="other_doc_8"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                    {otherDocumentLastIndex >= 8 ? (
                      <Field
                        component={FieldFile}
                        highlightType="advanced"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="other_doc_9"
                        name="other_doc_9"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.buttonLabel",
                        })}
                      />
                    ) : undefined}
                  </FieldGroup>
                }
                actions={
                  otherDocumentLastIndex !== 8 && inEditMode ? (
                    <>
                      <Button
                        text={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.addNew",
                        })}
                        startIcon={<AddIcon />}
                        onClick={onClickAddOtherDocument}
                        variant="text"
                      />
                    </>
                  ) : undefined
                }
              />
              <Field
                component={FieldFile}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="org_logo"
                name="org_logo"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.orgLogo",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.orgLogo",
                })}
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.accreditation",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldRadioGroup}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="any_accreditation"
                name="any_accreditation"
                onChangeExtra={onChangeHaveAccreditation}
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.info",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.info",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.no",
                    }),
                  },
                ]}
              />
              {haveAccreditation && accreditation ? (
                <Box
                  sections={accreditation.map((item, index) => (
                    <React.Fragment key={`accreditation_wrapper_${item.id}`}>
                      <div className={style.itemWrapper}>
                        <div>
                          <FieldGroup>
                            <Field
                              component={FieldText}
                              validate={[isRequired]}
                              highlightType="advanced"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`accreditation_organization_name_${item.id}`}
                              name={`accreditation_organization_name_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.referring",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.referring",
                              })}
                              type="text"
                              maxLength={5000}
                              withCharactersCount
                              multiline
                            />
                            <Field
                              component={FieldDatePicker}
                              validate={[isRequired, isValidDate]}
                              highlightType="advanced"
                              maximumDate={new Date()}
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`accreditation_date_received_${item.id}`}
                              name={`accreditation_date_received_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.date",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.date",
                              })}
                            />
                            <Field
                              component={FieldFile}
                              validate={[isRequired]}
                              highlightType="advanced"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`accreditation_evidence_file_${item.id}`}
                              name={`accreditation_evidence_file_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.reference",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.reference",
                              })}
                            />
                          </FieldGroup>
                        </div>
                        {index > 0 && inEditMode ? (
                          <div>
                            <IconButton onClick={() => onClickRemoveAccreditation(item)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ) : undefined}
                      </div>
                    </React.Fragment>
                  ))}
                  actions={
                    inEditMode ? (
                      <>
                        <Button
                          text={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.addNew",
                          })}
                          startIcon={<AddIcon />}
                          onClick={onClickAddAccreditation}
                          variant="text"
                        />
                      </>
                    ) : undefined
                  }
                />
              ) : undefined}
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.otherInformation",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <div className={style.declarationWrapper}>
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.declaration",
                  })}
                </span>
                {!partner?.registration_declaration?.length ? (
                  <>--</>
                ) : (
                  <>
                    {sortedDeclarations?.map((item) => (
                      <div key={`partner_declaration_${item?.partner?.id}_${item?.version}`} className={style.declarationItemWrapper}>
                        <DownloadIcon
                          fileName={`${item.partner?.legal_name} ${formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.declaration",
                          })} (v. ${item?.version})`}
                          fileUrl={item?.file?.file_field}
                        />
                      </div>
                    ))}
                    <div className={style.declarationDateWrapper}>
                      <span>
                        {formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.lastDeclarationDate",
                        })}
                      </span>
                      <span>{formatDate(sortedDeclarations[sortedDeclarations?.length - 1]?.created)}</span>
                    </div>
                  </>
                )}
              </div>
              <Field
                component={FieldCheckbox}
                validate={[isRequired]}
                highlightType="basic"
                id="confirm_data_updated"
                name="confirm_data_updated"
                className={formValues && formValues.confirm_data_updated && formValues.confirm_data_updated === "false" ? "required" : "normalCheckbox"}
                readOnly={!inEditMode}
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.other.information.idx.confirm",
                })}
                small={!inEditMode}
              />
            </FieldGroup>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

OtherInformation.propTypes = {};

export default reduxForm({
  form: "editPartnerProfileOtherInformationForm",
  enableReinitialize: true,
})(OtherInformation);

import React from "react";
import MUIAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./style.module.scss";

const Accordion = ({ id, items }) => {
  return (
    <>
      {items.map((item, index) => (
        <MUIAccordion key={`accordion_item_${id}_${index}_${item.id}`}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={style.titleWrapper}>
              <span className={style.title}>{item.label}</span>
              <div className={style.icon}>{item.extraIcon}</div>
            </div>
          </AccordionSummary>
          <AccordionDetails>{item.content}</AccordionDetails>
        </MUIAccordion>
      ))}
    </>
  );
};

Accordion.propTypes = {};

export default Accordion;

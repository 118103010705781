import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import style from "./style.module.scss";
import Box from "@local/components/box";
import WarningIcon from "@mui/icons-material/Warning";
import CircleIcon from "@mui/icons-material/Circle";

const Container = ({ tabs, children, onChangeTab, selectedTab }) => {
  return (
    <>
      <Box
        withBackground
        withPadding={false}
        content={
          <>
            <div className={style.tabsWrapper}>
              <Tabs value={selectedTab} onChange={onChangeTab} variant="scrollable" scrollButtons="auto">
                {tabs?.map((item) => (
                  <Tab
                    sx={{ fontSize: ".75rem" }}
                    label={
                      item?.isOptional ? (
                        <div className={style.titleWrapper}>
                          <div className={style.circleIcon}>
                            <CircleIcon style={{ fontSize: ".75rem", color: "#0000CC" }} />
                          </div>
                          <span>{item.label}</span>
                        </div>
                      ) : item.isCompleted ? (
                        <span>{item.label}</span>
                      ) : (
                        <div className={style.titleWrapper}>
                          <div className={style.warningIcon}>
                            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
                          </div>
                          <span>{item.label}</span>
                        </div>
                      )
                    }
                    key={`psea_csip_cs_${item.id}`}
                  />
                ))}
              </Tabs>
            </div>
            <div>{children}</div>
          </>
        }
      />
    </>
  );
};

Container.propTypes = {};

export default Container;

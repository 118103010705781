import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { getMainColor } from "@local/styles/muiTheme";
import { Link as RouterLink } from "react-router-dom";
import style from "./style.module.scss";

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const Breadcrumb = ({ items }) => {
  return (
    <div className={style.contentWrapper}>
      <Breadcrumbs separator="|" aria-label="breadcrumb">
        {items.slice(0, items.length - 1).map((item) => (
          <LinkRouter underline="none" color={getMainColor()} to={item.url} key={item.id} className={style.toCapitalize}>
            {item.label}
          </LinkRouter>
        ))}
        <Typography className={style.toCapitalize} color="text.primary" key={items[items.length - 1].id}>
          {items[items.length - 1].label}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

Breadcrumb.propTypes = {};

export default Breadcrumb;

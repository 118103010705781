import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import Alert from "@local/components/alert";
import FieldAutosuggest from "@local/components/field-autosuggest";

const InvitePartnersModal = ({ isOpen, onClose, formValues, onSubmit, fetchPartnersFunction, adaptPartnersFunction }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.projects.list.cmp.manage.project.modal.cmp.invite.partners.modal.idx.title",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onClose}
              variant="text"
              text={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.invite.partners.modal.idx.skip",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={formValues?.invited_partners?.length === 0 ?? true}
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.invite.partners.modal.idx.send",
              })}
            />
          </div>
        </>
      }
    >
      <Alert
        type="info"
        content={formatMessage({
          id: "pages.projects.list.cmp.manage.project.modal.cmp.invite.partners.modal.idx.body",
        })}
        withBottomMargin
      />
      <FieldGroup>
        <Field
          component={FieldAutosuggest}
          id="invited_partners"
          name="invited_partners"
          label={formatMessage({
            id: "pages.projects.list.cmp.manage.project.modal.cmp.invite.partners.modal.idx.label",
          })}
          fetchFunction={fetchPartnersFunction}
          adapterFunction={adaptPartnersFunction}
          valueField="id"
          labelField="legal_name"
          multiple
        />
      </FieldGroup>
    </Modal>
  );
};

InvitePartnersModal.propTypes = {};

export default reduxForm({ form: "invitePartnersModalForm" })(InvitePartnersModal);

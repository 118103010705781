/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from "react";
import style from "./style.module.scss";
import ErrorIcon from "@mui/icons-material/Error";

const Banner = ({ messages }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(undefined);
  const interval = useRef(undefined);

  useEffect(() => {
    if (messages.length === 0) {
      setCurrentMessageIndex(0);
    } else if (messages.length > 1) {
      setCurrentMessageIndex(0);
      startInterval();
    }
    return () => {
      stopInterval();
    };
  }, []);

  const startInterval = () => {
    if (!interval.current) {
      const id = setInterval(() => {
        setCurrentMessageIndex((value) => {
          const updatedValue = value + 1;
          if (updatedValue > messages.length - 1) {
            return 0;
          } else {
            return updatedValue;
          }
        });
      }, 4000);
      interval.current = id;
    }
  };

  const stopInterval = () => {
    if (interval.current) clearInterval(interval.current);
    interval.current = undefined;
  };

  const onMouseEnter = () => {
    stopInterval();
  };

  const onMouseLeave = () => {
    startInterval();
  };

  if (!messages.length || currentMessageIndex === undefined) return <></>;

  return (
    <div className={style.banner} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className={style.bannerIconWrapper}>
        <ErrorIcon style={{ fontSize: "1.5rem" }} />
      </div>
      <div className={style.bannerTextWrapperCurrent}>
        <span className={style.title}>{messages[currentMessageIndex].title}</span>
        <span>{messages[currentMessageIndex].content}</span>
      </div>
    </div>
  );
};

Banner.propTypes = {};

export default Banner;

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { checkPermission, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "@local/legacy-utils/restrictions";
import { DETAILS_ITEMS } from "@local/legacy-utils/constants";
import i18n from "@local/i18next/i18n";

export const getPartnerStatusTypes = () => [
  {
    value: "active",
    label: i18n.t("Active"),
  },
  {
    value: "deleted",
    label: i18n.t("Deleted"),
  },
];

export const getPartnerTypes = () =>
  [
    {
      label: formatMessage({ id: "utils.constants.CBO" }),
      value: "CBO",
    },
    {
      label: formatMessage({ id: "utils.constants.NGO" }),
      value: "NGO",
    },
    {
      label: formatMessage({ id: "utils.constants.Int" }),
      value: "Int",
    },
    {
      label: formatMessage({ id: "utils.constants.Aca" }),
      value: "Aca",
    },
    {
      label: formatMessage({ id: "utils.constants.RCC" }),
      value: "RCC",
    },
    {
      label: formatMessage({ id: "utils.constants.GOV" }),
      value: "GOV",
    },
    {
      label: formatMessage({ id: "utils.constants.UN" }),
      value: "UN",
    },
    {
      label: formatMessage({ id: "utils.constants.GAI" }),
      value: "GAI",
    },
  ].sort((a, b) => a.value.localeCompare(b.value));

export const getPartnerTypesWithoutSpecialTypes = () =>
  [
    {
      label: formatMessage({ id: "utils.constants.CBO" }),
      value: "CBO",
    },
    {
      label: formatMessage({ id: "utils.constants.NGO" }),
      value: "NGO",
    },
    {
      label: formatMessage({ id: "utils.constants.Int" }),
      value: "Int",
    },
    {
      label: formatMessage({ id: "utils.constants.Aca" }),
      value: "Aca",
    },
    {
      label: formatMessage({ id: "utils.constants.RCC" }),
      value: "RCC",
    },
  ].sort((a, b) => a.value.localeCompare(b.value));

export const getSpecialPartnerTypes = () =>
  [
    {
      label: formatMessage({ id: "utils.constants.GOV" }),
      value: "GOV",
    },
    {
      label: formatMessage({ id: "utils.constants.UN" }),
      value: "UN",
    },
    {
      label: formatMessage({ id: "utils.constants.GAI" }),
      value: "GAI",
    },
  ].sort((a, b) => a.value.localeCompare(b.value));

export const getConcernTypes = () => [
  {
    value: "Asy",
    label: formatMessage({ id: "utils.constants.Asy" }),
  },
  {
    value: "Hos",
    label: formatMessage({ id: "utils.constants.Hos" }),
  },
  {
    value: "IDP",
    label: formatMessage({ id: "utils.constants.IDP" }),
  },
  {
    value: "Ref",
    label: formatMessage({ id: "utils.constants.Ref" }),
  },
  {
    value: "Ret",
    label: formatMessage({ id: "utils.constants.Ret" }),
  },
  {
    value: "Sta",
    label: formatMessage({ id: "utils.constants.Sta" }),
  },
];

export const getYearsOfExperience = () => [
  {
    value: "YE1",
    label: formatMessage({ id: "utils.constants.YE1" }),
  },
  {
    value: "YE2",
    label: formatMessage({ id: "utils.constants.YE2" }),
  },
  {
    value: "YE3",
    label: formatMessage({ id: "utils.constants.YE3" }),
  },
  {
    value: "YE4",
    label: formatMessage({ id: "utils.constants.YE4" }),
  },
];

export const getNumberOfStaff = () => [
  {
    value: "SG1",
    label: formatMessage({ id: "utils.constants.SG1" }),
  },
  {
    value: "SG2",
    label: formatMessage({ id: "utils.constants.SG2" }),
  },
  {
    value: "SG3",
    label: formatMessage({ id: "utils.constants.SG3" }),
  },
  {
    value: "SG4",
    label: formatMessage({ id: "utils.constants.SG4" }),
  },
  {
    value: "SG5",
    label: formatMessage({ id: "utils.constants.SG5" }),
  },
  {
    value: "SG6",
    label: formatMessage({ id: "utils.constants.SG6" }),
  },
  {
    value: "SG7",
    label: formatMessage({ id: "utils.constants.SG7" }),
  },
];

export const getMailingAddressTypes = () => [
  {
    value: "POB",
    label: formatMessage({ id: "utils.constants.poBox" }),
  },
  {
    value: "Str",
    label: formatMessage({ id: "utils.constants.streetAddress" }),
  },
];

export const projectModalNav = () => [
  {
    id: 0,
    path: "project_dates",
    label: i18n.t("Project Dates"),
    name: "project_dates",
    isVisible: true,
  },
  {
    id: 1,
    path: "project_reviewers",
    label: i18n.t("Project Reviewers"),
    name: "project_reviewers",
    isVisible: true,
  },
  {
    id: 2,
    path: "project_status",
    label: i18n.t("Project Status"),
    name: "project_status",
    isVisible: true,
  },
  {
    id: 2,
    path: "project_focal_points",
    label: i18n.t("Project Focal Points"),
    name: "project_focal_points",
    isVisible: true,
  },
];

export const partnerModalNav = () => [
  {
    id: 0,
    path: "partner_details",
    label: i18n.t("Partner Details"),
    name: "partner_details",
    isVisible: true,
  },
  {
    id: 1,
    path: "partner_users",
    label: i18n.t("Partner Users"),
    name: "partner_users",
    isVisible: false,
  },
];

export const reportsNav = (state) => [
  {
    id: 0,
    path: "information",
    label: formatMessage({ id: "utils.constants.information" }),
    name: "reportInformation",
    isVisible: true,
  },
  {
    id: 1,
    path: "management",
    label: formatMessage({ id: "utils.constants.management" }),
    name: "reportManagement",
    isVisible: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_CFEI_MANAGEMENT, state),
  },
  {
    id: 2,
    path: "verification",
    label: formatMessage({ id: "utils.constants.verification" }),
    name: "reportVerification",
    isVisible: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_VERIFICATION_AND_FLAGGING, state),
  },
  {
    id: 3,
    path: "psea",
    label: i18n.t("PSEA"),
    name: "reportPsea",
    isVisible: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_PSEA_REPORT, state), // TODO: here needs to chnage with psea
  },
];

export const adminNav = (state) => [
  {
    id: 0,
    path: "projects",
    label: i18n.t("Projects"),
    name: "projects",
    isVisible: true,
  },
  {
    id: 1,
    path: "partners",
    label: i18n.t("Partners"),
    name: "partners",
    isVisible: true,
  },
  {
    id: 2,
    path: "partner-duplicates",
    label: i18n.t("Partner Duplicates"),
    name: "partner-duplicates",
    isVisible: true,
  },
];

export const usersNav = (state) => [
  {
    id: 0,
    path: "users",
    label: formatMessage({ id: "utils.constants.users" }),
    name: "users",
    isVisible: true,
  },
  {
    id: 1,
    path: "regional-offices",
    label: formatMessage({ id: "utils.constants.regionalOffices" }),
    name: "regionalOffices",
    isVisible: state.session.role === ROLES.AGENCY && isSectionVisible(AGENCY_RESTRICTIONS.userManagement["regionalOffices"], state?.session),
  },
];

export const getSelectionCriteria = () =>
  [
    {
      value: "SEE",
      label: "Sector expertise and experience",
    },
    {
      value: "Pro",
      label: "Project management",
    },
    {
      value: "LEP",
      label: "Local experience and presence",
    },
    {
      value: "Con",
      label: "Contribution of resource",
    },
    {
      value: "Cos",
      label: "Cost effectiveness",
    },
    {
      value: "Exp",
      label: "Experience working with UN",
    },
    {
      value: "Rel",
      label: "Relevance of proposal to achieving expected results",
    },
    {
      value: "Cla",
      label: "Clarity of activities and expected results",
    },
    {
      value: "Inn",
      label: "Innovative approach",
    },
    {
      value: "Sus",
      label: "Sustainability of intervention",
    },
    {
      value: "Rea",
      label: "Realistic timelines and plans",
    },
    {
      value: "ASC",
      label: "Access/security considerations",
    },
    {
      value: "Oth",
      label: "Other",
    },
    {
      value: "Acr",
      label: "Accountability to communities and community relations",
    },
  ].sort((a, b) => a.value.localeCompare(b.value));

export const allowedAgencies = ["UN_SECRETARIAT", "UN_WOMEN", "FAO", "UNFPA", "UNHCR", "UNICEF", "WFP", "WHO", "WIPO", "IOM", "UNOPS", "OTHER"];

export const getUnAgencies = () => [
  {
    value: "UN_SECRETARIAT",
    label: "UN Secretariat",
  },
  {
    value: "UN_WOMEN",
    label: "UN Women",
  },
  {
    value: "FAO",
    label: "FAO",
  },
  {
    value: "UNFPA",
    label: "UNFPA",
  },
  {
    value: "UNHCR",
    label: "UNHCR",
  },
  {
    value: "UNICEF",
    label: "UNICEF",
  },
  {
    value: "WFP",
    label: "WFP",
  },
  {
    value: "WHO",
    label: "WHO",
  },
  {
    value: "IOM",
    label: "IOM",
  },
  {
    value: "WIPO",
    label: "WIPO",
  },
  {
    value: "UNOPS",
    label: "UNOPS",
  },
  {
    value: "OTHER",
    label: "Other",
  },
];

export const mapAgenciesIds = (agencies) =>
  getUnAgencies().map((item) => ({
    id: agencies?.find((element) => element.name === item.value)?.id,
    name: item.label,
  }));

export const mapAgenciesLabels = (agencies) =>
  getUnAgencies().map((item) => ({
    id: agencies?.find((element) => element.name === item.value)?.id,
    name: item.value,
    label: item.label,
  }));

export const PROJECT_TYPES = {
  OPEN: "open",
  PINNED: "pinned",
  DIRECT: "direct",
  UNSOLICITED: "unsolicited",
};

export const PROJECT_TYPES_BACKEND = {
  OPEN: "Ope",
  DIRECT: "Dir",
};

export const ROLES = {
  AGENCY: "agency",
  PARTNER: "partner",
};

export const APPLICATION_STATUSES = {
  PRE: "Pre",
  REJ: "Rej",
  PEN: "Pen",
  REC: "Rec",
  REV: "Rev",
};

export const SPECIAL_PARTNER_TYPES = ["GOV", "UN", "GAI"];

export const projectDetailsNav = (state, type, role, hasPermissionToViewApplications, hasPermissionToViewPreApplications) => {
  const hasReviewPermission = checkPermission(AGENCY_PERMISSIONS.CFEI_REVIEW_APPLICATIONS, state);
  const hasViewApplicationsPermission = checkPermission(AGENCY_PERMISSIONS.CFEI_VIEW_APPLICATIONS, state);
  const hasViewAllPermission = checkPermission(AGENCY_PERMISSIONS.CFEI_FINALIZED_VIEW_ALL_INFO, state);
  const hasViewWinnerPermission = checkPermission(AGENCY_PERMISSIONS.CFEI_FINALIZED_VIEW_WINNER_AND_CN, state);
  const hasRequestsViewPermission = checkPermission(AGENCY_PERMISSIONS.CFEI_PUBLISHED_VIEW_AND_ANSWER_CLARIFICATION_QUESTIONS, state);
  const agencyId = state?.session?.agencyId;
  const agency = state?.projectDetails?.data?.data?.agency;
  const isCompleted = state?.projectDetails?.data?.data?.completed_reason;

  return [
    {
      id: 1,
      path: DETAILS_ITEMS.OVERVIEW,
      label: formatMessage({ id: "utils.constants.Overview" }),
      roles: [ROLES.AGENCY, ROLES.PARTNER],
      types: [PROJECT_TYPES.OPEN, PROJECT_TYPES.DIRECT, PROJECT_TYPES.UNSOLICITED],
      isVisible: true,
    },
    {
      id: 2,
      path: DETAILS_ITEMS.FEEDBACK,
      label: formatMessage({ id: "utils.constants.Feedback" }),
      roles: [ROLES.AGENCY],
      types: [PROJECT_TYPES.DIRECT, PROJECT_TYPES.UNSOLICITED],
      isVisible: !(role === ROLES.PARTNER && type === PROJECT_TYPES.UNSOLICITED),
    },
    {
      id: 3,
      path: DETAILS_ITEMS.FEEDBACK,
      label: formatMessage({ id: "utils.constants.Feedback" }),
      roles: [ROLES.PARTNER],
      types: [PROJECT_TYPES.UNSOLICITED],
      isVisible: !(role === ROLES.PARTNER && type === PROJECT_TYPES.UNSOLICITED),
    },
    {
      id: 4,
      path: DETAILS_ITEMS.SUBMISSION,
      label: formatMessage({ id: "utils.constants.conceptNoteSubmission" }),
      roles: [ROLES.PARTNER],
      types: [PROJECT_TYPES.OPEN],
      isVisible: true,
    },
    {
      id: 5,
      path: DETAILS_ITEMS.REQUESTS,
      label: formatMessage({ id: "utils.constants.additionalInformation" }),
      roles: [ROLES.AGENCY],
      types: [PROJECT_TYPES.OPEN],
      isVisible: !(role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && !hasPermissionToViewApplications(hasRequestsViewPermission)),
    },
    {
      id: 6,
      path: DETAILS_ITEMS.APPLICATIONS,
      label: formatMessage({ id: "utils.constants.applications" }),
      roles: [ROLES.AGENCY],
      types: [PROJECT_TYPES.OPEN],
      isVisible: !(
        (role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && !isCompleted && !hasPermissionToViewApplications(hasViewApplicationsPermission)) ||
        (role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && isCompleted && !hasPermissionToViewApplications(hasViewApplicationsPermission))
      ),
    },
    {
      id: 7,
      path: DETAILS_ITEMS.PRESELECTED,
      label: formatMessage({ id: "utils.constants.shortlist" }),
      roles: [ROLES.AGENCY],
      types: [PROJECT_TYPES.OPEN],
      isVisible: !(
        (role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && !isCompleted && !hasPermissionToViewPreApplications(hasReviewPermission)) ||
        (role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && isCompleted && !hasPermissionToViewPreApplications(hasReviewPermission))
      ),
    },
    {
      id: 8,
      path: DETAILS_ITEMS.RESULTS,
      label: formatMessage({ id: "utils.constants.results" }),
      roles: [ROLES.PARTNER, ROLES.AGENCY],
      types: [PROJECT_TYPES.OPEN],
      isVisible: !((role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && isCompleted && (!hasViewAllPermission || !hasViewWinnerPermission)) || (role === ROLES.AGENCY && type === PROJECT_TYPES.OPEN && isCompleted && agencyId !== agency)),
    },
    {
      id: 9,
      path: DETAILS_ITEMS.RESPONSE,
      label: formatMessage({ id: "utils.constants.response" }),
      roles: [ROLES.PARTNER],
      types: [PROJECT_TYPES.DIRECT],
      isVisible: true,
    },
  ];
};

export const getProjectStatuses = () => ({
  Sen: formatMessage({ id: "utils.constants.Sen" }),
  Dra: formatMessage({ id: "utils.constants.Dra" }),
  Ope: formatMessage({ id: "utils.constants.Ope" }),
  Clo: formatMessage({ id: "utils.constants.Clo" }),
  Com: formatMessage({ id: "utils.constants.Com" }),
});

export const getApplicationStatuses = () => ({
  Dra: formatMessage({ id: "utils.constants.DraOne" }),
  Rev: formatMessage({ id: "utils.constants.Rev" }),
  Uns: formatMessage({ id: "utils.constants.Uns" }),
  Suc: formatMessage({ id: "utils.constants.Suc" }),
  Acc: formatMessage({ id: "utils.constants.Acc" }),
  Dec: formatMessage({ id: "utils.constants.Dec" }),
  Ret: formatMessage({ id: "utils.constants.RetOne" }),
  Can: formatMessage({ id: "utils.constants.Can" }),
});

export const getPartnerNamesDisplay = () => ({
  CBO: formatMessage({ id: "utils.constants.CBO" }),
  NGO: formatMessage({ id: "utils.constants.NGO" }),
  Int: formatMessage({ id: "utils.constants.Int" }),
  Aca: formatMessage({ id: "utils.constants.Aca" }),
  RCC: formatMessage({ id: "utils.constants.RCC" }),
  GOV: formatMessage({ id: "utils.constants.GOV" }),
  UN: formatMessage({ id: "utils.constants.UN" }),
  GAI: formatMessage({ id: "utils.constants.GAI" }),
});

export const SELECTION_CRITERIA = () => ({
  SEE: "Sector expertise and experience",
  Pro: "Project management",
  LEP: "Local experience and presence",
  Con: "Contribution of resource",
  Cos: "Cost effectiveness",
  Exp: "Experience working with UN",
  Rel: "Relevance of proposal to achieving expected results",
  Cla: "Clarity of activities and expected results",
  Inn: "Innovative approach",
  Sus: "Sustainability of intervention",
  Rea: "Realistic timelines and plans",
  ASC: "Access/security considerations",
  Oth: "Other",
  Acr: "Accountability to communities and community relations",
});

export const PROJECT_STATUSES = {
  SEN: "Sen",
  DRA: "Dra",
  OPE: "Ope",
  CLO: "Clo",
  COM: "Com",
};

export const FLAG_TYPES = {
  OBSERVATION: "FL1_Obs",
  YELLOW: "FL2_Yel",
  ESCALATED: "FL3_Esc",
  RED: "FL4_Red",
  SANCTION: "C90_sanctions_match",
};

export const getProjectStatusFilterValues = () => [
  {
    value: "Dra,Sen",
    label: formatMessage({ id: "utils.constants.Unpublished" }),
    informationText: formatMessage({ id: "utils.constants.UnpublishedText" }),
  },
  {
    value: "Ope,Clo",
    label: formatMessage({ id: "utils.constants.Active" }),
    informationText: formatMessage({ id: "utils.constants.ActiveText" }),
  },
  {
    value: "Com",
    label: formatMessage({ id: "utils.constants.Finalized" }),
  },
];

export const getDuplicateFilterStatuses = () => [
  { label: "Stand by", value: "Sta" },
  { label: "Confirmed", value: "Con" },
  { label: "False match", value: "Fal" },
  { label: "Cascade deleted", value: "Cas" },
];

export const getDuplicateResultsFilterStatuses = () => [
  { label: "Document similarity", value: "document_similarity" },
  { label: "Name similarity", value: "name_similarity" },
  { label: "User match", value: "user_match" },
  { label: "Weighted similarity", value: "weighted_similarity" },
];

export const DUPLICATE_STATUSES = {
  Sta: "Stand by",
  Con: "Confirmed",
  Fal: "False match",
  Cas: "Cascade deleted",
};

export const CALCULATION_STATUSES = {
  document_similarity: "Document similarity",
  name_similarity: "Name similarity",
  weighted_similarity: "Weighted similarity",
  user_match: "User match",
  no_match: "No match",
};

export const getSubscriptionChannels = () => [
  {
    value: 1,
    label: "CFEI",
    description: "Receive news when a new CFEI is published",
  },
];

export const getUserStatuses = () => [
  {
    value: "deactivated",
    label: formatMessage({ id: "utils.constants.deactivated" }),
  },
  {
    value: "invited",
    label: formatMessage({ id: "utils.constants.invited" }),
  },
  {
    value: "active",
    label: formatMessage({ id: "utils.constants.active" }),
  },
];

export const getProjectTypes = () => [
  {
    value: "open",
    label: formatMessage({ id: "utils.constants.open" }),
  },
  {
    value: "direct",
    label: formatMessage({ id: "utils.constants.direct" }),
  },
];

export const getFlagTypesForFiltering = () => [
  {
    value: "FL1_Obs",
    label: formatMessage({ id: "utils.constants.flagObs" }),
  },
  {
    value: "FL2_Yel",
    label: formatMessage({ id: "utils.constants.flagYel" }),
  },
  {
    value: "FL3_Esc",
    label: formatMessage({ id: "utils.constants.flagEsc" }),
  },
  {
    value: "FL4_Red",
    label: formatMessage({ id: "utils.constants.flagRed" }),
  },
];

export const getRiskCategories = () => [
  {
    value: "C01_reputational_ethical",
    label: formatMessage({ id: "utils.constants.c01ReputationalEthical" }),
  },
  {
    value: "C02_financial",
    label: formatMessage({ id: "utils.constants.c02Financial" }),
  },
  {
    value: "C03_operational",
    label: formatMessage({ id: "utils.constants.c03Operational" }),
  },
  {
    value: "C04_compliance",
    label: formatMessage({ id: "utils.constants.c04Compliance" }),
  },
  {
    value: "C05_sex_abuse",
    label: formatMessage({ id: "utils.constants.c05SexAbuse" }),
  },
  {
    value: "C06_safeguarding_violation",
    label: formatMessage({ id: "utils.constants.c06SafeguardingViolation" }),
  },
  {
    value: "FL3_Esc",
    label: formatMessage({ id: "utils.constants.c90SanctionsMatch" }),
  },
  {
    value: "C99_other",
    label: formatMessage({ id: "utils.constants.c99Other" }),
  },
];

export const getPseaRatingOptions = (notValid = true) => {
  const result = [
    {
      label: formatMessage({ id: "utils.constants.low" }),
      extendedLabel: formatMessage({ id: "utils.constants.lowExtended" }),
      value: "low",
    },
    {
      label: formatMessage({ id: "utils.constants.medium" }),
      extendedLabel: formatMessage({ id: "utils.constants.mediumExtended" }),
      value: "medium",
    },
    {
      label: formatMessage({ id: "utils.constants.full" }),
      extendedLabel: formatMessage({ id: "utils.constants.fullExtended" }),
      value: "full",
    },
    {
      label: formatMessage({ id: "utils.constants.notEligible" }),
      extendedLabel: formatMessage({
        id: "utils.constants.notEligibleExtended",
      }),
      value: "not_eligible",
    },
    {
      label: formatMessage({ id: "utils.constants.lowRisk" }),
      extendedLabel: formatMessage({ id: "utils.constants.lowRiskExtended" }),
      value: "low_risk",
    },
  ];
  if (notValid) {
    result.push({
      label: formatMessage({ id: "utils.constants.notValid" }),
      extendedLabel: formatMessage({ id: "utils.constants.notValidExtended" }),
      value: "not_valid",
    });
  }
  return result;
};

export const getCsipStatuses = () => {
  return [
    {
      label: "CSIP not started yet",
      value: "not_started",
    },
    {
      label: "CSIP available",
      value: "available",
    },
    {
      label: "CSIP overdue",
      value: "overdue",
    },
    {
      label: "CSIP not required",
      value: "csip_not_required",
    },
  ].sort((a, b) => a.value.localeCompare(b.value));
};

export const getSelfAssessmentStatuses = () => {
  return [
    {
      label: "Not started",
      value: "not_started",
    },
    {
      label: "In progress",
      value: "in_progress",
    },
    {
      label: "Submitted",
      value: "submitted",
    },
    {
      label: "N/A",
      value: "na",
    },
  ].sort((a, b) => a.value.localeCompare(b.value));
};

export const getPseaInitialAssessmentDocumentTypes = () => [
  {
    label: formatMessage({ id: "utils.constants.verifiedAssessmentResults" }),
    value: "assessment_results",
  },
  {
    label: formatMessage({
      id: "utils.constants.capacityStrengthImplementationPlan",
    }),
    value: "capacity_strengthening_plan",
  },
  {
    label: formatMessage({ id: "utils.constants.other" }),
    value: "others",
  },
];

export const PSEA_INITIAL_ASSESSMENT_DOCUMENT_TYPES = {
  ASSESSMENT_RESULTS: "assessment_results",
  CAPACITY_STRENGTHENING_PLAN: "capacity_strengthening_plan",
  OTHERS: "others",
};

export const getPseaCoreStandards = () => [
  {
    label: formatMessage({ id: "utils.constants.cs1" }),
    value: "CS1",
    informationText: (
      <>
        <span>
          {formatMessage({
            id: "utils.constants.questionstablerowonecellinfo1",
          })}
        </span>{" "}
        <a
          style={{ color: "inherit" }}
          target="_blank"
          alt={formatMessage({
            id: "utils.constants.questionstablerowonecellinfo2",
          })}
          href="https://undocs.org/ST/SGB/2003/13"
          rel="noreferrer"
        >
          {formatMessage({
            id: "utils.constants.questionstablerowonecellinfo2",
          })}
        </a>
        <span>{"."}</span>
      </>
    ),
  },
  {
    label: formatMessage({ id: "utils.constants.cs2" }),
    value: "CS2",
    informationText: formatMessage({
      id: "utils.constants.questionsTableRowTwoCellInfo",
    }),
  },
  {
    label: formatMessage({ id: "utils.constants.cs3" }),
    value: "CS3",
    informationText: formatMessage({
      id: "utils.constants.questionsTableRowThreeCellInfo",
    }),
  },
  {
    label: formatMessage({ id: "utils.constants.cs4" }),
    value: "CS4",
    informationText: formatMessage({
      id: "utils.constants.questionsTableRowFourCellInfo",
    }),
  },
  {
    label: formatMessage({ id: "utils.constants.cs5" }),
    value: "CS5",
    informationText: formatMessage({
      id: "utils.constants.questionsTableRowFiveCellInfo",
    }),
  },
  {
    label: formatMessage({ id: "utils.constants.cs6" }),
    value: "CS6",
    informationText: formatMessage({
      id: "utils.constants.questionsTableRowSixCellInfo",
    }),
  },
  {
    label: formatMessage({ id: "utils.constants.cs7" }),
    value: "CS7",
    informationText: formatMessage({
      id: "utils.constants.questionsTableRowSevenCellInfo",
    }),
  },
  {
    label: formatMessage({ id: "utils.constants.cs8" }),
    value: "CS8",
    informationText: formatMessage({
      id: "utils.constants.questionsTableRowEightSevenCellInfo",
    }),
  },
];

export const getPseaCoreStandardMonitoringStatuses = () => [
  {
    label: formatMessage({ id: "utils.constants.inProgress" }),
    value: "in_progress",
  },
  {
    label: formatMessage({ id: "utils.constants.notStarted" }),
    value: "not_started",
  },
  {
    label: formatMessage({ id: "utils.constants.completed" }),
    value: "completed",
  },
  {
    label: formatMessage({ id: "utils.constants.inactivated" }),
    value: "inactivated",
  },
];

export const getPseaRevisionTypes = {
  initial_assessment: formatMessage({
    id: "utils.constants.revisionTypesInitialAssessment",
  }),
  self_assessment: formatMessage({
    id: "utils.constants.revisionTypesSelfAssessment",
  }),
  six_months: formatMessage({ id: "utils.constants.revisionTypesSixMonths" }),
  nine_months: formatMessage({ id: "utils.constants.revisionTypesNineMonths" }),
  final_determination: formatMessage({
    id: "utils.constants.revisionTypesFinalDetermination",
  }),
};

export const getJobTitleTypes = () => [
  {
    value: "head_of_organization",
    label: formatMessage({ id: "utils.constants.headOfOrganization" }),
  },
  {
    value: "board_of_directors",
    label: formatMessage({ id: "utils.constants.boardOfDirectors" }),
  },
  {
    value: "senior_managers",
    label: formatMessage({ id: "utils.constants.seniorManagers" }),
  },
  {
    value: "authorized_officer",
    label: formatMessage({ id: "utils.constants.authorizedOfficer" }),
  },
  {
    value: "other",
    label: formatMessage({ id: "utils.constants.otherOne" }),
  },
];

export const getOrganizationDescriptions = () => [
  {
    value: "faith_based_organization",
    label: formatMessage({ id: "utils.constants.faithBasedOrganization" }),
    info: formatMessage({
      id: "utils.constants.faithBasedOrganizationInfoText",
    }),
  },
  {
    value: "indigenous_led_organizations",
    label: formatMessage({ id: "utils.constants.indigenousLedOrganization" }),
    info: formatMessage({
      id: "utils.constants.indigenousLedOrganizationInfoText",
    }),
  },
  {
    value: "minority_community_organization",
    label: formatMessage({
      id: "utils.constants.minorityCommunityOrganization",
    }),
    info: formatMessage({
      id: "utils.constants.minorityCommunityOrganizationInfoText",
    }),
  },
  {
    value: "organizations_of_persons_with_disabilities",
    label: formatMessage({
      id: "utils.constants.organizationsOfPersonsWithDisabilities",
    }),
    info: formatMessage({
      id: "utils.constants.organizationsOfPersonsWithDisabilitiesInfoText",
    }),
  },
  {
    value: "refugee_led_organizations",
    label: formatMessage({ id: "utils.constants.refugeeLedOrganizations" }),
    info: formatMessage({
      id: "utils.constants.refugeeLedOrganizationsInfoText",
    }),
  },
  {
    value: "women_led_organization",
    label: formatMessage({ id: "utils.constants.womenLeadOrganization" }),
    info: formatMessage({
      id: "utils.constants.womenLeadOrganizationInfoText",
    }),
  },
  {
    value: "youth_led_organization",
    label: formatMessage({ id: "utils.constants.youthLeadOrganization" }),
    info: formatMessage({
      id: "utils.constants.youthLeadOrganizationInfoText",
    }),
  },
  {
    value: "other",
    label: formatMessage({ id: "utils.constants.otherTwo" }),
  },
];

export const ratingMap = {
  not_eligible: "Not eligible",
  low: "Low Capacity",
  medium: "Medium Capacity",
  low_risk: "Low Risk",
  full: "Full Capacity",
  not_valid: "Not Valid",
};

export const selfAssessmentMap = {
  not_started: "Not Started",
  in_progress: "In progress",
  submitted: "Submitted",
  NA: "N/A",
};

export const csipMap = {
  not_started: "CSIP not started yet",
  available: "CSIP available",
  overdue: "CSIP overdue",
  csip_not_required: "CSIP Not Required",
  not_available: "CSIP Not Available",
};

/* This array is used by getErrors function in utils to find specific error messages that we want to show to the user
   Add more specific errors here when needed
*/
export const errorSpecificMessages = ["Only one partnership statement per agency is allowed", "Dates for the project cannot be set in the past."];

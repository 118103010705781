import React from "react";
import { FLAGS } from "@local/legacy-utils/constants";
import EscalatedIcon from "@mui/icons-material/Warning";
import ObservationIcon from "@mui/icons-material/Comment";
import MUIFlagIcon from "@mui/icons-material/Flag";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";

const FlagIcon = ({ flagType, isValid, isEscalated, category, flagStyle }) => {
  if (flagType === FLAGS.OBSERVATION) {
    return (
      <div className={style.iconWrapper}>
        <ObservationIcon style={{ fontSize: "1.25rem", color: "#cccccc" }} />
        <span>{formatMessage({ id: "cmp.flag.icon.idx.flagObs" })}</span>
      </div>
    );
  } else if (flagType === FLAGS.YELLOW && isEscalated === false && isValid === null) {
    return (
      <div className={style.iconWrapper}>
        <MUIFlagIcon style={{ fontSize: "1.25rem", color: "#ffcc00" }} />
        <span>{formatMessage({ id: "cmp.flag.icon.idx.flagYel" })}</span>
        <span className={style.message}>{formatMessage({ id: "cmp.flag.icon.idx.deffered" })}</span>
      </div>
    );
  } else if (flagType === FLAGS.YELLOW && isValid === false && category !== FLAGS.SANCTION) {
    return (
      <div className={style.iconWrapper}>
        <MUIFlagIcon style={{ fontSize: "1.25rem", color: "#ffcc00" }} />
        <span>{formatMessage({ id: "cmp.flag.icon.idx.flagYel" })}</span>
        <span className={style.message}>{formatMessage({ id: "cmp.flag.icon.idx.flagObs" })}</span>
      </div>
    );
  } else if (flagType === FLAGS.YELLOW && isValid === false && category === FLAGS.SANCTION) {
    return (
      <div className={style.iconWrapper}>
        <MUIFlagIcon style={{ fontSize: "1.25rem", color: "#ffcc00" }} />
        <span>{formatMessage({ id: "cmp.flag.icon.idx.flagYel" })}</span>
        <span className={style.message}>{formatMessage({ id: "cmp.flag.icon.idx.notMatch" })}</span>
      </div>
    );
  } else if (flagType === FLAGS.YELLOW) {
    return (
      <div className={style.iconWrapper}>
        <MUIFlagIcon style={{ fontSize: "1.25rem", color: "#ffcc00" }} />
        <span>{formatMessage({ id: "cmp.flag.icon.idx.flagYel" })}</span>
      </div>
    );
  } else if (flagType === FLAGS.RED) {
    return (
      <div className={style.iconWrapper}>
        <MUIFlagIcon style={{ fontSize: "1.25rem", color: "#cc0000" }} />
        <span>{formatMessage({ id: "cmp.flag.icon.idx.flagRed" })}</span>
      </div>
    );
  } else if (flagType === FLAGS.ESCALATED) {
    return (
      <div className={style.iconWrapper}>
        <EscalatedIcon style={{ fontSize: "1.25rem", color: "ffa000" }} />
        <span>{formatMessage({ id: "cmp.flag.icon.idx.flagEsc" })}</span>
      </div>
    );
  }
  return <MUIFlagIcon style={flagStyle} />;
};

FlagIcon.propTypes = {};

export default FlagIcon;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldSelect from "@local/components/field-select";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group/index";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import { isRequired, isValidEmail } from "@local/utils/validators";
import Alert from "@local/components/alert";
import Box from "@local/components/box";
import style from "./style.module.scss";
import FieldText from "@local/components/field-text";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { adaptCountriesToOffices, adaptOfficesOfRegion } from "../../utils/adapters";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "@local/legacy-utils/restrictions";
import { ROLES } from "@local/legacy-utils/constants";

const UserManageModal = ({ isOpen, onSubmit, onClose, isValidForm, roles, regionalOffices, offices, stateValues, inEditMode, formValues, getRegionalOffice, countries, changeOfficeField, session, userRole }) => {
  const [officeRoles, setOfficeRoles] = useState(undefined);
  const [officesForRegion, setOfficesForRegion] = useState([]);
  const [countriesForRegion, setCountriesForRegion] = useState([]);

  const sortedOffices = [...offices].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    setOfficeRoles(undefined);
    if (stateValues?.officeRoles && !officeRoles) setOfficeRoles(stateValues.officeRoles);
  }, [stateValues?.officeRoles]);

  const getCountriesForRegion = async () => {
    try {
      if (formValues?.office_role_office_main) {
        const response = await getRegionalOffice(formValues?.office_role_office_main);
        const regionalOffice = response?.data;
        setCountriesForRegion(adaptCountriesToOffices(offices, countries, regionalOffice?.countries));
        return regionalOffice;
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    setOfficesForRegion([]);
    if (stateValues?.officesForRegion) setOfficesForRegion(stateValues.officesForRegion);
  }, [stateValues?.officesForRegion]);

  useEffect(() => {
    getCountriesForRegion();
  }, [formValues?.office_role_office_main]);

  useEffect(() => {
    if (officesForRegion?.length) {
      officesForRegion.forEach((item) => {
        changeOfficeField(`regional_office_role_office_${item.id}`, item?.office);
        changeOfficeField(`regional_office_role_role_${item.id}`, item?.role);
      });
    }
  }, [officesForRegion?.length]);

  useEffect(() => {
    if (formValues?.is_regional_office && formValues?.office_role_office_main && formValues?.office_role_role_main) {
      onSubmitGetRegionalOffice();
    }
  }, [formValues?.office_role_office_main, formValues?.office_role_role_main]);

  const onSubmitGetRegionalOffice = async () => {
    try {
      setOfficesForRegion([]);
      const regionalOffice = await getCountriesForRegion();
      const updatedOfficesForRegion = regionalOffice?.countries?.map((item, index) => ({
        id: index,
        originalId: parseInt(formValues?.office_role_office_main),
        role: formValues?.office_role_role_main,
        office: adaptOfficesOfRegion(offices, countries, item),
      }));
      setOfficesForRegion(updatedOfficesForRegion);
    } catch (error) {
      toast.error(error);
    }
  };

  const onClickAddOfficeRule = () => {
    const updatedOfficeRoles = [
      ...officeRoles,
      {
        id: officeRoles.length ? officeRoles.sort((a, b) => a.id < b.id)[officeRoles.length - 1].id + 1 : 1,
      },
    ];
    setOfficeRoles(updatedOfficeRoles);
  };

  const onClickRemoveOfficeRule = (toRemove) => {
    const updatedOfficeRoles = officeRoles.filter((item) => item.id !== toRemove.id);
    setOfficeRoles(updatedOfficeRoles);
  };

  const onClickSubmit = () => {
    const regionalOffice = formValues?.office_role_office_main ?? undefined;
    const adaptDataToBeSent = formValues?.is_regional_office.toString() === "true" ? officesForRegion : officeRoles;
    onSubmit({ officeRoles: adaptDataToBeSent }, regionalOffice);
    setOfficeRoles(undefined);
  };

  const onClickClose = () => {
    setOfficesForRegion([]);
    onClose();
  };

  if (!officeRoles) return <></>;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClickClose}
      title={formatMessage({
        id: "pages.users.management.cmp.user.manage.modal.idx.title",
      })}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClickClose}
            text={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.cancel",
            })}
          />
          <Button
            isDisabled={!isValidForm || (formValues?.is_regional_office?.toString() === "true" && officesForRegion.length === 0)}
            onClick={onClickSubmit}
            text={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.save",
            })}
          />
        </>
      }
    >
      <Alert
        type="info"
        content={formatMessage({
          id: "pages.users.management.cmp.user.manage.modal.idx.header",
        })}
        withBottomMargin
      />
      <FieldGroup>
        <Field
          component={FieldText}
          validate={[isRequired]}
          readOnly={inEditMode}
          id="fullname"
          name="fullname"
          label={formatMessage({
            id: "pages.users.management.cmp.user.manage.modal.idx.firstName",
          })}
          placeholder={formatMessage({
            id: "pages.users.management.cmp.user.manage.modal.idx.firstName",
          })}
          type="text"
        />
        <Field
          component={FieldText}
          validate={[isRequired, isValidEmail]}
          readOnly={inEditMode}
          id="email"
          name="email"
          label={formatMessage({
            id: "pages.users.management.cmp.user.manage.modal.idx.email",
          })}
          placeholder={formatMessage({
            id: "pages.users.management.cmp.user.manage.modal.idx.email",
          })}
          type="email"
        />
        {isSectionVisible(AGENCY_RESTRICTIONS.userManagement["regionalOffices"], session) && userRole === ROLES.AGENCY && (
          <Field
            component={FieldRadioGroup}
            validate={[isRequired]}
            id="is_regional_office"
            name="is_regional_office"
            label={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.isRegionalOffice",
            })}
            placeholder={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.isRegionalOffice",
            })}
            options={[
              {
                value: true,
                label: formatMessage({
                  id: "pages.users.management.cmp.user.manage.modal.idx.yes",
                }),
              },
              {
                value: false,
                label: formatMessage({
                  id: "pages.users.management.cmp.user.manage.modal.idx.no",
                }),
              },
            ]}
          />
        )}
        {formValues?.is_regional_office?.toString() === "true" ? (
          <>
            <Box
              title={formatMessage({
                id: "pages.users.management.cmp.user.manage.modal.idx.regionalOffice",
              })}
              content={
                <div className={style.itemWrapper}>
                  <div>
                    <FieldGroup>
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id={"office_role_office_main"}
                        name={"office_role_office_main"}
                        label={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.office",
                        })}
                        placeholder={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.office",
                        })}
                        valueField="id"
                        labelField="name"
                        options={regionalOffices}
                      />
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id={"office_role_role_main"}
                        name={"office_role_role_main"}
                        label={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.role",
                        })}
                        placeholder={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.role",
                        })}
                        valueField="value"
                        labelField="label"
                        options={roles}
                      />
                    </FieldGroup>
                  </div>
                </div>
              }
            />
            {formValues?.office_role_office_main && formValues?.office_role_role_main && officesForRegion.length ? (
              <Box
                title={formatMessage({
                  id: "pages.users.management.cmp.user.manage.modal.idx.roleOne",
                })}
                sections={officesForRegion?.map((item) => (
                  <React.Fragment key={`regional_office_role_wrapper_${item.id}`}>
                    <div className={style.itemWrapper}>
                      <div>
                        <FieldGroup>
                          <Field
                            component={FieldSelect}
                            validate={[isRequired]}
                            id={`regional_office_role_office_${item.id}`}
                            name={`regional_office_role_office_${item.id}`}
                            label={formatMessage({
                              id: "pages.users.management.cmp.user.manage.modal.idx.office",
                            })}
                            placeholder={formatMessage({
                              id: "pages.users.management.cmp.user.manage.modal.idx.office",
                            })}
                            valueField="id"
                            labelField="name"
                            options={countriesForRegion}
                            readOnly={inEditMode}
                          />
                          <Field
                            component={FieldSelect}
                            validate={[isRequired]}
                            id={`regional_office_role_role_${item.id}`}
                            name={`regional_office_role_role_${item.id}`}
                            label={formatMessage({
                              id: "pages.users.management.cmp.user.manage.modal.idx.role",
                            })}
                            placeholder={formatMessage({
                              id: "pages.users.management.cmp.user.manage.modal.idx.role",
                            })}
                            valueField="value"
                            labelField="label"
                            options={roles}
                          />
                        </FieldGroup>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              />
            ) : undefined}
          </>
        ) : officeRoles ? (
          <Box
            title={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.roleOne",
            })}
            sections={officeRoles.map((item, index) => (
              <React.Fragment key={`office_role_wrapper_${item.id}`}>
                <div className={style.itemWrapper}>
                  <div>
                    <FieldGroup>
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id={`office_role_office_${item.id}`}
                        name={`office_role_office_${item.id}`}
                        label={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.office",
                        })}
                        placeholder={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.office",
                        })}
                        valueField="id"
                        labelField="name"
                        options={sortedOffices}
                      />
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id={`office_role_role_${item.id}`}
                        name={`office_role_role_${item.id}`}
                        label={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.role",
                        })}
                        placeholder={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.role",
                        })}
                        valueField="value"
                        labelField="label"
                        options={roles}
                      />
                    </FieldGroup>
                  </div>
                  {index > 0 ? (
                    <div>
                      <IconButton onClick={() => onClickRemoveOfficeRule(item)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ) : undefined}
                </div>
              </React.Fragment>
            ))}
            actions={
              officeRoles.length < offices.length ? (
                <>
                  <Button
                    text={formatMessage({
                      id: "pages.users.management.cmp.user.manage.modal.idx.addNew",
                    })}
                    startIcon={<AddIcon />}
                    onClick={onClickAddOfficeRule}
                    variant="text"
                  />
                </>
              ) : undefined
            }
          />
        ) : undefined}
      </FieldGroup>
    </Modal>
  );
};

UserManageModal.propTypes = {};

export default reduxForm({ form: "userModalForm", enableReinitialize: true })(UserManageModal);

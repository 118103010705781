import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import { isRequired } from "@local/utils/validators";

const SendNotificationModal = ({ isOpen, onClose, onSubmit, isValid }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.shell.profiles.list.cmp.send.notification.modal.idx.newShellProfileModalButton",
      })}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.shell.profiles.list.cmp.send.notification.modal.idx.cancel",
            })}
          />
          <Button
            isDisabled={!isValid}
            onClick={onSubmit}
            text={formatMessage({
              id: "pages.shell.profiles.list.cmp.send.notification.modal.idx.save",
            })}
          />
        </>
      }
    >
      <FieldGroup>
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="message"
          name="message"
          label={formatMessage({
            id: "pages.shell.profiles.list.cmp.send.notification.modal.idx.notificationText",
          })}
          placeholder={formatMessage({
            id: "pages.shell.profiles.list.cmp.send.notification.modal.idx.notificationText",
          })}
          maxLength={5000}
          withCharactersCount
          multiline
        />
      </FieldGroup>
    </Modal>
  );
};

SendNotificationModal.propTypes = {};

export default reduxForm({ form: "sendNotificationModalForm" })(SendNotificationModal);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getPartnerProfileDataDetails, getAgenciesList } from "@local/actions";
import Loader from "@local/pages/common/loader";
import PartnerUnAgencyData from "./partnerUnAgencyData";
import Accordion from "@local/components/accordion";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getVendorNumbersListFromState } from "@local/pages/partner-details/components/risk/utils/adapters";
import { getErrors } from "@local/utils";
import { mapAgenciesLabels } from "@local/utils/constants";

const PartnerUnDataOverview = ({ data, role, agencies, loadPartnerProfileDetails, loadAgenciesDetails, vendorNumbers, loading }) => {
  const { id } = useParams();
  const [agencyData, setAgencyData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getUnAgenciesDataList = async () => {
    setIsLoadingData(true);
    const updatedAgencyData = [];
    let unData = undefined;
    let financialData = undefined;
    for (const agency of agencies) {
      try {
        unData = data?.find((item) => item?.agency === agency?.name)?.data["un-data"];
        financialData = data?.find((item) => item?.agency === agency?.name)?.data["financial-data"];
        updatedAgencyData.push({
          id: agency?.id,
          name: agency?.name,
          unDataResult: unData,
          financialResult: financialData,
          error: undefined,
          loading: loading,
        });
      } catch (error) {
        const errorMessages = getErrors(error ?? {}).join(" ");
        toast.error(
          errorMessages ||
            formatMessage({
              id: "pages.partner.details.cmp.un.data.legacy.cmp.partnerUnDataOverview.unableToSaveData",
            }),
        );
      }
    }
    setAgencyData(updatedAgencyData);
    setIsLoadingData(false);
  };

  useEffect(() => {
    loadAgenciesDetails();
  }, []);

  useEffect(() => {
    if (agencies?.length) {
      getUnAgenciesDataList();
    }
  }, [agencies?.length]);

  useEffect(() => {
    if (role === "partner") {
      loadPartnerProfileDetails(id);
    }
  }, [role]);

  return (
    <Accordion
      id="profile_un_data_external"
      items={agencies?.map((agency) => {
        const agencyDataItem = agencyData?.find((item) => item?.id === agency?.id);
        return {
          id: agency?.name,
          label: agency?.label,
          content: <div>{agencyDataItem ? <PartnerUnAgencyData agencyData={agencyDataItem} agency={agency} vendorNumbers={vendorNumbers} /> : <Loader loading={isLoadingData} replaceContent />}</div>,
        };
      })}
    />
  );
};

PartnerUnDataOverview.propTypes = {};

const mapStateToProps = (state) => ({
  role: state?.session?.role,
  agencies:
    mapAgenciesLabels(state?.agenciesList?.data?.data?.results)
      ?.slice()
      ?.sort((a, b) => a.name?.localeCompare(b.name)) ?? [],
  error: state?.partnerUnDataList?.errorData,
  loading: state?.partnerUnDataList?.loading,
  vendorNumbers: getVendorNumbersListFromState(state),
  data: state?.unDataList?.data?.data?.results,
});

const mapDispatchToProps = (dispatch) => ({
  loadPartnerProfileDetails: (id) => dispatch(getPartnerProfileDataDetails(undefined, { id })),
  loadAgenciesDetails: () => dispatch(getAgenciesList()) || {},
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerUnDataOverview);

import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import Grid from "@mui/material/Grid";
import HeaderNavigation from "@local/components/header-navigation";
import { selectNormalizedNotificationsFrequencies } from "../../store";
import { formatMessage } from "../../legacy-utils/i18nHelper";
import { updateUserProfile } from "@local/actions";
import Card from "@local/components/card";
import { toast } from "react-toastify";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import style from "./style.module.scss";

const UserProfile = ({ formValues, notifications, updateUserProfile }) => {
  const onClickSubmit = async () => {
    try {
      const notification_frequency = formValues.notifications_frequency === "0_disabled" || !formValues.notifications_frequency ? "" : formValues.notifications_frequency;
      await updateUserProfile({ notification_frequency });
      toast.info("User profile updated");
    } catch (error) {
      toast.error("Unable to save data");
    }
  };

  return (
    <HeaderNavigation title={formatMessage({ id: "pages.user.profile.idx.header" })} header={<Button text={formatMessage({ id: "pages.user.profile.idx.save" })} onClick={onClickSubmit} />}>
      <div className={style.mainWrapper}>
        <Card title={formatMessage({ id: "pages.user.profile.idx.header" })}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                component={FieldSelect}
                id="notifications_frequency"
                name="notifications_frequency"
                label={formatMessage({
                  id: "pages.user.profile.idx.notifications",
                })}
                placeholder={formatMessage({
                  id: "pages.user.profile.idx.notifications",
                })}
                valueField="value"
                labelField="label"
                options={notifications}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FieldText}
                id="name"
                name="name"
                label={formatMessage({ id: "pages.user.profile.idx.name" })}
                placeholder={formatMessage({
                  id: "pages.user.profile.idx.name",
                })}
                readOnly
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={FieldText}
                id="email"
                name="email"
                label={formatMessage({ id: "pages.user.profile.idx.email" })}
                placeholder={formatMessage({
                  id: "pages.user.profile.idx.email",
                })}
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={FieldText}
                id="officeName"
                name="officeName"
                label={formatMessage({
                  id: "pages.user.profile.idx.officeName",
                })}
                placeholder={formatMessage({
                  id: "pages.user.profile.idx.officeName",
                })}
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={FieldText}
                id="role"
                name="role"
                label={formatMessage({
                  id: "pages.user.profile.idx.officeRole",
                })}
                placeholder={formatMessage({
                  id: "pages.user.profile.idx.officeRole",
                })}
                readOnly
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={FieldText}
                id="telephone"
                name="telephone"
                label={formatMessage({
                  id: "pages.user.profile.idx.telephone",
                })}
                placeholder={formatMessage({
                  id: "pages.user.profile.idx.telephone",
                })}
                readOnly
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </HeaderNavigation>
  );
};

UserProfile.propTypes = {};

const mapStateToProps = (state) => ({
  notifications: selectNormalizedNotificationsFrequencies(state),
  formValues: state.form?.userProfileForm?.values ?? {},
  initialValues: {
    name: state.session.name,
    email: state.session.email,
    role: state.session.position || state.session.officeRole,
    officeName: state.session.officeName || state.session.partnerName,
    telephone: state.session.telephone || "",
    permissions: state.session.permissions.join(", \n"),
    notifications_frequency: R.isEmpty(state.session.notificationFrequency) ? "0_disabled" : state.session.notificationFrequency,
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (data) => dispatch(updateUserProfile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "userProfileForm", enableReinitialize: true })(UserProfile));

import { ROLES } from "@local/legacy-utils/constants";
import { getPseaRatingOptions } from "@local/utils/constants";
import { getRatingScoreLabel } from "@local/pages/partner-details/utils/adapters";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { parseISO } from "date-fns";

export const getRevisionsByTpe = (revisions, type) => {
  const sortedRevisions = [...revisions];
  sortedRevisions.sort((a, b) => a.id - b.id);
  return sortedRevisions.filter((revision) => revision.revision_type === type);
};

export const getLastRevisionByTpe = (revisions, type) => {
  const sortedRevisions = [...revisions];
  sortedRevisions.sort((a, b) => b.id - a.id);
  for (const revision of sortedRevisions) {
    if (revision.revision_type === type) return revision;
  }
  return undefined;
};

export const getInitialAssessmentRevision = (revisions) => getLastRevisionByTpe(revisions, "initial_assessment");

export const getSelfAssessmentRevision = (revisions) => getLastRevisionByTpe(revisions, "self_assessment");

export const getSixMonthsRevision = (revisions) => getLastRevisionByTpe(revisions, "six_months");

export const getNineMonthsRevision = (revisions) => getLastRevisionByTpe(revisions, "nine_months");

export const getInitialAssessmentRevisions = (revisions) => getRevisionsByTpe(revisions, "initial_assessment");

export const getSelfAssessmentRevisions = (revisions) => getRevisionsByTpe(revisions, "self_assessment");

export const getSixMonthsRevisions = (revisions) => getRevisionsByTpe(revisions, "six_months");

export const getNineMonthsRevisions = (revisions) => getRevisionsByTpe(revisions, "nine_months");

export const getInitialAssessmentJustification = (revisions) => getLastRevisionByTpe(revisions, "initial_assessment");

export const getSelfAssessmentJustification = (revisions) => getLastRevisionByTpe(revisions, "self_assessment");

export const getSixMonthsJustification = (revisions) => getLastRevisionByTpe(revisions, "six_months");

export const getNineMonthsJustification = (revisions) => getLastRevisionByTpe(revisions, "nine_months");

export const getFinalDeterminationRevision = (revisions) => {
  const definitiveRatings = ["full", "low_risk", "not_eligible"];
  const sectionsForDefinitiveRatings = ["final_determination", "self_assessment", "initial_assessment"];
  const sortedRevisions = [...revisions];
  sortedRevisions.sort((a, b) => b.id - a.id);
  for (const revision of sortedRevisions) {
    if (revision?.revision_type === "nine_months") {
      return revision;
    }
    if (revision?.revision_type === "self_assessment" && !definitiveRatings.includes(revision?.revision_rating)) {
      return undefined;
    }
    if (revision?.revision_type === "six_months") {
      return revision;
    }
    if (sectionsForDefinitiveRatings.includes(revision?.revision_type) && definitiveRatings.includes(revision?.revision_rating)) {
      return revision;
    }
  }
  return undefined;
};

export const getPreliminaryDetermination = (initialAssessment, selfAssessment) => {
  if (selfAssessment?.revision_rating) {
    return selfAssessment;
  }
  if (initialAssessment?.revision_rating === "low_risk" && selfAssessment?.revision_rating !== "not_eligible") {
    return initialAssessment;
  }
  if (initialAssessment) {
    return initialAssessment;
  }
  return undefined;
};

export const isPartnerLocked = (selfAssessment, hasEditPseaSelfAssessmentPermission, initialAssessment) => {
  if (
    selfAssessment?.is_finalized_by_partner ||
    selfAssessment?.is_finalized_by_agency ||
    !hasEditPseaSelfAssessmentPermission ||
    selfAssessment?.force_self_assessment === false ||
    (initialAssessment?.agency_rating && initialAssessment?.agency_rating !== "not_valid" && ((selfAssessment?.is_finalized_by_partner ?? undefined) === undefined || selfAssessment?.is_finalized_by_partner === true)) ||
    (initialAssessment?.partner_beneficiaries === true && initialAssessment?.partner_is_assessed === true && (initialAssessment?.agency_beneficiaries ?? undefined) === undefined)
  ) {
    return true;
  }
  return false;
};

export const isAgencyInitialSectionComplete = (role, initialAssessment) => {
  if (role === ROLES.AGENCY && initialAssessment?.confirmed_by !== null) return true;
  return false;
};

export const isAgencyLocked = (role, selfAssessment, hasEditPseaSelfAssessmentPermission, canAgencySkipPartner, hasUnlockPseaSelfAssessmentAgencySectionPermission, initialAssessment) => {
  if (role === ROLES.AGENCY && !hasEditPseaSelfAssessmentPermission) return true;
  if (!isAgencyInitialSectionComplete(role, initialAssessment)) return true;
  if (role === ROLES.AGENCY && !hasUnlockPseaSelfAssessmentAgencySectionPermission) return false;
  let isPartnerNotAnswered = !selfAssessment?.questions?.find((item) => item.partner_answer !== null);
  if (role === ROLES.PARTNER || selfAssessment?.is_finalized_by_agency || !hasEditPseaSelfAssessmentPermission) return true;
  if (selfAssessment?.is_finalized_by_partner) {
    if (selfAssessment?.is_finalized_by_agency) return true;
  } else {
    if (isPartnerNotAnswered && canAgencySkipPartner) return false;
    if (!isPartnerNotAnswered && !selfAssessment?.is_finalized_by_agency && canAgencySkipPartner) return true;
  }
  return false;
};

export const getRatingColor = (coreStandard) => {
  if (coreStandard?.rating === "yes") return "#00cc00";
  else if (coreStandard?.is_optional) return "#0000CC";
  else return "#cc0000";
};

export const getLabelFromRevision = (rating, score, uploadedDocumentRating) => {
  let result = "";
  const ratingOptions = getPseaRatingOptions();
  if (!ratingOptions.find((item) => item.value === rating)?.extendedLabel) {
    return formatMessage({
      id: "pages.partner.details.cmp.psea.utils.idx.sectionNotAvailable",
    });
  }
  result = ratingOptions.find((item) => item.value === rating)?.extendedLabel;
  if (rating === "low_risk" && (score || uploadedDocumentRating)) {
    result += ` (${getRatingScoreLabel(score, ratingOptions, uploadedDocumentRating)}${score ? `, ${score}` : ""})`;
  }
  return result;
};

export const formatDate = (date) => parseISO(date).toLocaleDateString("en-GB");

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptSpecializations } from "@local/utils/adapters";

export const getOpenProjectApplicationFilters = (countries, getLocations, locations, setLocations, sectors, concernTypes, partnerTypes) => [
  {
    id: "legal_name",
    name: "legal_name",
    label: formatMessage({ id: "pages.project.details.utils.filters.name" }),
    placeholder: formatMessage({
      id: "pages.project.details.utils.filters.name",
    }),
    type: "text",
  },
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.project.details.utils.filters.country" }),
    placeholder: formatMessage({
      id: "pages.project.details.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: countries,
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
  },
  {
    id: "locations",
    name: "locations",
    label: formatMessage({
      id: "pages.project.details.utils.filters.location",
    }),
    placeholder: formatMessage({
      id: "pages.project.details.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
  },
  {
    id: "specializations",
    name: "specializations",
    label: formatMessage({
      id: "pages.project.details.utils.filters.sectorArea",
    }),
    placeholder: formatMessage({
      id: "pages.project.details.utils.filters.sectorArea",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "concern",
    name: "concern",
    label: formatMessage({
      id: "pages.project.details.utils.filters.populations",
    }),
    placeholder: formatMessage({
      id: "pages.project.details.utils.filters.populations",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: concernTypes,
  },
  {
    id: "type_org",
    name: "type_org",
    label: formatMessage({
      id: "pages.project.details.utils.filters.typeOfOrganization",
    }),
    placeholder: formatMessage({
      id: "pages.project.details.utils.filters.typeOfOrganization",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerTypes,
  },
];

import React from "react";
import Table from "@local/components/table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getOpenProjectRequestsColumns } from "../../utils/columns";
import Button from "@local/components/button";
import SaveIcon from "@mui/icons-material/Save";
import { downloadFile } from "@local/utils/network";

const OpenProjectRequests = ({ fetchFunction, projectId }) => {
  return (
    <Table
      key="open_project_requests"
      id="open_project_requests"
      title={formatMessage({
        id: "pages.project.details.cmp.open.project.requests.idx.additionalInformation",
      })}
      columns={getOpenProjectRequestsColumns()}
      fetchFunction={fetchFunction}
      actions={
        <Button
          type="text"
          text={formatMessage({
            id: "pages.project.details.cmp.open.project.requests.idx.download",
          })}
          onClick={() => downloadFile(`/projects/${projectId}/questions/?export=pdf`, "pdf")}
          startIcon={<SaveIcon />}
        />
      }
    />
  );
};

OpenProjectRequests.propTypes = {};

export default OpenProjectRequests;

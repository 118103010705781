/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import style from "./style.module.scss";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "@local/components/button";
import DataTable from "@local/components/data-table";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import DownloadIcon from "@local/components/download-icon";
import { getUrlFromFileField } from "@local/utils";
import { ROLES } from "@local/legacy-utils/constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import ShowHide from "@local/components/show-hide";
import { IconButton } from "@mui/material";
import AddCircle from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import EditTargetDateModal from "./components/edit-target-date-modal";
import EditPersonResponsibleModal from "./components/edit-person-responsible-modal";
import EditCommentModal from "./components/edit-comment-modal";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@local/components/alert";
import { getRatingColor, formatDate } from "../../../../../../utils";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

const getCoreStandardLabel = (coreStandard) => {
  if (coreStandard?.rating === "yes") {
    return formatMessage({
      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.yes",
    });
  }
  if (coreStandard?.is_optional) {
    return formatMessage({
      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.noOptional",
    });
  }
  return coreStandard?.rating;
};

const CoreStandard = ({
  onClickUploadDocument,
  onClickAddActivity,
  coreStandard,
  coreStandards,
  activityMonitoringStatuses,
  onClickDeleteDocument,
  onSubmitEditTargetDate,
  onSubmitEditPersonResponsible,
  onSubmitEditComment,
  role,
  hasEditPseaCsipActivitiesPermission,
  hasViewPseaCsipActivitiesMonitoringStatusPermission,
  hasEditPseaCsipActivitiesMonitoringStatusPermission,
  hasViewPseaCsipDocumentsPermission,
  hasEditPseaCsipDocumentsPermission,
  pseaCsipActivities,
  isEditTargetDateFormValid,
  isEditPersonResponsibleFormValid,
  isEditCommentFormValid,
  onChangeStatus,
  isStatusEnabled,
  resetCommentForm,
}) => {
  const [isTargetDateEditableModalVisible, setIsTargetDateEditableModalVisible] = useState(false);
  const [isPersonResponsibleEditableModalVisible, setIsPersonResponsibleEditableModalVisible] = useState(false);
  const [isCommentEditableModalVisible, setIsCommentEditableModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const { t } = useTranslation();

  const onChangeStatusField = (activity, status) => {
    onChangeStatus(activity, status);
  };

  const onClickOpenTargetDateModal = (row) => {
    setIsTargetDateEditableModalVisible(true);
    setSelectedRow(row);
  };

  const onClickOpenPersonResponsibleModal = (row) => {
    setIsPersonResponsibleEditableModalVisible(true);
    setSelectedRow(row);
  };

  const onClickOpenCommentModal = () => {
    setIsCommentEditableModalVisible(true);
  };

  const onClickCancelEditTargetDate = () => setIsTargetDateEditableModalVisible(false);

  const onClickCancelEditPersonResponsible = () => setIsPersonResponsibleEditableModalVisible(false);

  const onClickCancelEditComment = () => {
    setIsCommentEditableModalVisible(false);
    resetCommentForm();
  };

  let activities = [];
  if (role === ROLES.PARTNER) {
    activities = coreStandard?.activities?.filter((item) => item.status !== "inactivated");
  } else {
    activities = coreStandard?.activities;
  }

  return (
    <>
      <div className={style.mainWrapper}>
        <div className={style.csHeaderWrapper}>
          <div className={style.csHeaderMessageWrapper}>
            <span className={style.coreStandardInformationText}>{coreStandards.find((item) => coreStandard.core_standard === item?.value)?.informationText}</span>
            <div className={style.statusWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.ratingLabel",
                })}
              </span>
              <Chip
                size="small"
                icon={
                  <CircleIcon
                    style={{
                      fill: getRatingColor(coreStandard),
                      fontSize: ".75rem",
                    }}
                  />
                }
                label={<span className={style.coreStandardLabel}>{getCoreStandardLabel(coreStandard)}</span>}
              />
            </div>
          </div>
          {role === ROLES.AGENCY ? (
            <div className={style.csHeaderActionWrapper}>
              <Button
                onClick={onClickAddActivity}
                text={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.addActivityButton",
                })}
                variant="text"
                isDisabled={!hasEditPseaCsipActivitiesPermission}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {activities.length || role === ROLES.AGENCY ? (
          <>
            <div className={style.csContentWrapper}>
              <div className={style.csDisclaimerWrapper}>
                {coreStandard?.rating !== "yes" ? (
                  <Alert
                    type="info"
                    content={
                      role === ROLES.PARTNER
                        ? formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.csDisclaimerPartner",
                          })
                        : formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.csDisclaimer",
                          })
                    }
                  />
                ) : null}
              </div>
              <DataTable
                key="cs_activity_table"
                id="cs_activity_table"
                title={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.capacityStrengthImplementationPlan",
                })}
                isBordered
                columns={[
                  {
                    id: "type",
                    title: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnType",
                    }),
                    type: "custom",
                    render: (row) => {
                      if (pseaCsipActivities?.find((item) => item.activity === row.activity)?.activity === "other") {
                        if (row?.is_required) {
                          return <b>{row?.title} *</b>;
                        } else {
                          return <span>{row?.title}</span>;
                        }
                      } else {
                        if (row?.is_required) {
                          return <b>{t(pseaCsipActivities?.find((item) => item.activity === row.activity)?.description)} *</b>;
                        } else {
                          return <span>{t(pseaCsipActivities?.find((item) => item.activity === row.activity)?.description)}</span>;
                        }
                      }
                    },
                  },
                  {
                    id: "person_responsible",
                    title: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnPersonResponsible",
                    }),
                    type: "custom",
                    render: (row) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{row?.person_responsible}</span>
                        {role !== ROLES.PARTNER && hasEditPseaCsipActivitiesPermission ? (
                          <div>
                            <Tooltip
                              title={formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesEditPersonResponsible",
                              })}
                            >
                              <IconButton size="small" onClick={() => onClickOpenPersonResponsibleModal(row)} style={{ marginLeft: ".75rem" }} disabled={!hasEditPseaCsipActivitiesPermission}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : undefined}
                      </div>
                    ),
                  },
                  {
                    id: "target_date",
                    title: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnTargetDate",
                    }),
                    type: "custom",
                    render: (row) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{formatDate(row?.target_date)}</span>
                        {role !== ROLES.PARTNER && hasEditPseaCsipActivitiesPermission ? (
                          <div>
                            <Tooltip
                              title={formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesEditTargetDate",
                              })}
                            >
                              <IconButton size="small" onClick={() => onClickOpenTargetDateModal(row)} style={{ marginLeft: ".75rem" }} disabled={!hasEditPseaCsipActivitiesPermission}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        ) : undefined}
                      </div>
                    ),
                  },
                  {
                    id: "status",
                    title: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnStatus",
                    }),
                    type: "custom",
                    isVisible: hasViewPseaCsipActivitiesMonitoringStatusPermission,
                    render: (row) => (
                      <FieldSelect
                        id={`csip_cs_activity_${coreStandard?.id}`}
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnStatus",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnStatus",
                        })}
                        small
                        valueField="value"
                        labelField="label"
                        options={activityMonitoringStatuses}
                        readOnly={!isStatusEnabled || role === ROLES.PARTNER || !hasEditPseaCsipActivitiesMonitoringStatusPermission}
                        showEmptyElement={false}
                        input={{
                          value: activities?.find((item) => item?.id === row?.id)?.status,
                          onChange: (event) => onChangeStatusField(row, event.target.value),
                        }}
                      />
                    ),
                  },
                ]}
                data={activities}
                keyColumn="id"
                shouldRefresh={undefined}
                afterRefresh={undefined}
                secondaryColumns={[
                  {
                    id: "plan",
                    title: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnPlan",
                    }),
                    span: 3,
                  },
                  {
                    id: "monitoring",
                    title: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesTableColumnMonitoring",
                    }),
                    span: 1,
                  },
                ]}
                emptyLabel={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.noActivity",
                })}
              />
            </div>
          </>
        ) : (
          <>
            <span className={style.emptyLabel}>
              {coreStandard?.rating === "yes"
                ? formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.notAction",
                  })
                : formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.notAvailable",
                  })}
            </span>
          </>
        )}
        <div className={style.csFooterWrapper}>
          <ShowHide isVisible={hasViewPseaCsipDocumentsPermission}>
            <span className={style.filesListTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesDocuments",
              })}
            </span>
            <div className={style.filesListWrapper}>
              {
                <>
                  {coreStandard?.documents?.length ? (
                    <>
                      {coreStandard?.documents?.map((item) => (
                        <div key={item.file_url}>
                          <DownloadIcon onClickDelete={role === ROLES.AGENCY || hasEditPseaCsipDocumentsPermission ? () => onClickDeleteDocument(item) : undefined} fileUrl={getUrlFromFileField(item.file.file_field)} fileName={item.file?.name} />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {role === ROLES.PARTNER ? (
                        <span>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesDocumentsNotAvailable",
                          })}
                        </span>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              }
              {role === ROLES.AGENCY ? (
                <div>
                  <Button
                    text={formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesAddDocument",
                    })}
                    onClick={onClickUploadDocument}
                    variant="text"
                    isDisabled={!hasEditPseaCsipDocumentsPermission}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </ShowHide>
          {
            <div className={style.commentWrapper}>
              <div>
                <FieldText
                  id="comment"
                  name="comment"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesCommentField",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.activitiesCommentField",
                  })}
                  helperText={
                    role === ROLES.AGENCY
                      ? formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.idx.helpText",
                        })
                      : undefined
                  }
                  multiline
                  readOnly
                  input={{
                    value: coreStandard?.comment,
                    onChange: () => undefined,
                  }}
                />
              </div>
              {role === ROLES.AGENCY ? (
                <div>
                  <IconButton onClick={onClickOpenCommentModal} disabled={!hasEditPseaCsipActivitiesMonitoringStatusPermission}>
                    <AddCircle />
                  </IconButton>
                </div>
              ) : (
                <></>
              )}
            </div>
          }
        </div>
      </div>
      <EditTargetDateModal
        onCancel={onClickCancelEditTargetDate}
        onSubmit={onSubmitEditTargetDate}
        isOpen={isTargetDateEditableModalVisible}
        isFormValid={isEditTargetDateFormValid}
        selectedActivity={selectedRow}
        initialValues={{
          target_date: selectedRow?.target_date ? parseISO(selectedRow?.target_date) : undefined,
        }}
      />
      <EditPersonResponsibleModal
        onCancel={onClickCancelEditPersonResponsible}
        onSubmit={onSubmitEditPersonResponsible}
        isOpen={isPersonResponsibleEditableModalVisible}
        isFormValid={isEditPersonResponsibleFormValid}
        selectedActivity={selectedRow}
        initialValues={{
          person_responsible: selectedRow?.person_responsible ?? undefined,
        }}
      />
      <EditCommentModal onCancel={onClickCancelEditComment} onSubmit={onSubmitEditComment} isOpen={isCommentEditableModalVisible} isFormValid={isEditCommentFormValid} selectedActivity={selectedRow} />
    </>
  );
};

CoreStandard.propTypes = {};

export default CoreStandard;

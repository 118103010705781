/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useMemo } from "react";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import Box from "@local/components/box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import FieldFile from "@local/components/field-file";
import { getKeyName, getLocationObject } from "@local/utils";
import { WrapperComponent } from "../../utils";
import { isRequired, isRequiredIfValue } from "@local/utils/validators";
import Map from "@local/components/map";
import FieldSelection from "@local/components/field-selection";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

// POST_REFACTORING_TODO: The logic behind array of fields (so, initial state, add and remove, etc)
// should be handled by a custom hook, same for other pages and tabs too,
// like partner edit or manage project modal forms

const MandateMission = ({ isLoading, stateValues, formValues, partner, onSubmitAndContinue, onSubmitAndExit, countries, sectors, yearsOfExperience, populationOfConcern, numberOfStaff, inEditMode, organizationDescriptions }) => {
  const [experiences, setExperiences] = useState(undefined);
  const [locations, setLocations] = useState(undefined);
  const isEthicSafeguardCommentRequired = useMemo(() => isRequiredIfValue("ethic_safeguard", "false"), []);

  useEffect(() => {
    if (stateValues?.experiences && !experiences) setExperiences(stateValues.experiences);
  }, [stateValues?.experiences]);

  useEffect(() => {
    if (stateValues?.locations && !locations) setLocations(stateValues.locations);
  }, [stateValues?.locations]);

  const onClickAddExperience = () => {
    const updatedExperiences = [
      ...experiences,
      {
        id: experiences.length ? experiences.sort((a, b) => a.id > b.id)[experiences.length - 1].id + 1 : 1,
      },
    ];
    setExperiences(updatedExperiences);
  };

  const onClickRemoveExperience = (toRemove) => {
    const updatedExperiences = experiences.filter((item) => item.id !== toRemove.id);
    setExperiences(updatedExperiences);
  };

  const onClickSaveAndContinue = () => {
    onSubmitAndContinue({ experiences, locations });
  };

  const onClickSaveAndExit = () => {
    onSubmitAndExit({ experiences, locations });
  };

  const onClickMap = async (event) => {
    try {
      const updatedSelectedLocations = [...locations, await getLocationObject(event.location, countries, [formValues?.country_code])];
      setLocations(updatedSelectedLocations);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onClickDeleteLocation = (location) => {
    const updatedSelectedLocations = locations.filter((item) => item.id !== location.id);
    setLocations(updatedSelectedLocations);
  };

  if (isLoading || !experiences) return <CircularProgress size={50} />;

  const isCountryProfile = (partner?.is_hq ?? false) === false;
  const haveEthicSafeguard = formValues?.ethic_safeguard?.toString() === "true";
  const haveEthicFraud = formValues?.ethic_fraud?.toString() === "true";
  const havePopulationOfConcern = formValues?.population_of_concern?.toString() === "true";
  const orgDescription = formValues?.org_description;

  return (
    <WrapperComponent
      inEditMode={inEditMode}
      title={formatMessage({
        id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.mandateMission",
      })}
      footer={
        <div className={style.cardActionsWrapper}>
          <Button
            variant="text"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.saveAndExit",
            })}
            onClick={onClickSaveAndExit}
          />
          <Button
            variant="contained"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.saveAndContinue",
            })}
            onClick={onClickSaveAndContinue}
          />
        </div>
      }
    >
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.background",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldText}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="mandate_and_mission"
                name="mandate_and_mission"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.mandate",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.mandate",
                })}
                maxLength={5000}
                withCharactersCount
                multiline
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.governance",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldText}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="governance_structure"
                name="governance_structure"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.structure",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.structure",
                })}
                maxLength={5000}
                withCharactersCount
                multiline
              />
              {!isCountryProfile ? (
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="governance_hq"
                  name="governance_hq"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.headquarters",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.headquarters",
                  })}
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
              <Field
                component={FieldFile}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="governance_organigram"
                name="governance_organigram"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.organigram",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.organigram",
                })}
              />
              <Grid container spacing={2}>
                <Grid item xs={orgDescription?.includes("other") ? 9 : 12}>
                  <Field
                    component={FieldSelect}
                    highlightType="advanced"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="org_description"
                    name="org_description"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.orgDescription",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.orgDescription",
                    })}
                    valueField="value"
                    labelField="label"
                    options={organizationDescriptions}
                    helperText={organizationDescriptions?.find((item) => orgDescription?.includes(item?.value))?.info}
                    multiple
                  />
                </Grid>
                {orgDescription?.includes("other") ? (
                  <Grid item xs={3}>
                    <Field
                      component={FieldText}
                      highlightType="advanced"
                      readOnly={!inEditMode}
                      small={!inEditMode}
                      id="org_description_other"
                      name="org_description_other"
                      label={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.other",
                      })}
                      placeholder={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.other",
                      })}
                    />
                  </Grid>
                ) : undefined}
              </Grid>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_board_directors"
                name="have_board_directors"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.boardOfDirectors",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.boardOfDirectors",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.no",
                    }),
                  },
                ]}
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.ethics",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldText}
                validate={[isRequired, isEthicSafeguardCommentRequired]} // TODO: maybe isRequired is wrong since we have isEthicSafeguardCommentRequired
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="ethic_safeguard_comment"
                name="ethic_safeguard_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.violationSafeguard",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.violationSafeguard",
                })}
                maxLength={5000}
                withCharactersCount
                multiline
              />
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="ethic_safeguard"
                name="ethic_safeguard"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.violation",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.violation",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.no",
                    }),
                  },
                ]}
              />
              {haveEthicSafeguard ? (
                <Field
                  component={FieldFile}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="ethic_safeguard_policy"
                  name="ethic_safeguard_policy"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.policy",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.policy",
                  })}
                />
              ) : (
                <></>
              )}
              <Field
                component={FieldText}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="ethic_fraud_comment"
                name="ethic_fraud_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.fraudSafeguard",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.fraudSafeguard",
                })}
                maxLength={5000}
                withCharactersCount
                multiline
              />
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="ethic_fraud"
                name="ethic_fraud"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.fraud",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.fraud",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.no",
                    }),
                  },
                ]}
              />
              {haveEthicFraud ? (
                <Field
                  component={FieldFile}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="ethic_fraud_policy"
                  name="ethic_fraud_policy"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.policy",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.policy",
                  })}
                />
              ) : (
                <></>
              )}
              <Field
                component={FieldText}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="allegations_link"
                name="allegations_link"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.allegationsLink",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.allegationsLink",
                })}
                maxLength={5000}
                withCharactersCount
              />
              <Field
                component={FieldRadioGroup}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="social_and_environmental_standards"
                name="social_and_environmental_standards"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.environmental",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.environmental",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.no",
                    }),
                  },
                ]}
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.experience",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Box
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.experience",
                })}
                sections={experiences.map((item, index) => (
                  <React.Fragment key={`experiences_wrapper_${item.id}`}>
                    <div className={style.itemWrapper}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Field
                              component={FieldSelect}
                              highlightType="basic"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`experiences_sector_${item.id}`}
                              name={`experiences_sector_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.sector",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.sector",
                              })}
                              valueField="id"
                              labelField="name"
                              options={sectors}
                            />
                          </Grid>
                          {/*  */}
                          {formValues && formValues[getKeyName("experiences_sector", item.id)] ? (
                            <>
                              <Grid item xs={6}>
                                <Field
                                  component={FieldSelect}
                                  validate={[isRequired]}
                                  highlightType="basic"
                                  readOnly={!inEditMode}
                                  small={!inEditMode}
                                  id={`experiences_years_${item.id}`}
                                  name={`experiences_years_${item.id}`}
                                  label={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.years",
                                  })}
                                  placeholder={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.years",
                                  })}
                                  valueField="value"
                                  labelField="label"
                                  options={yearsOfExperience}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  component={FieldSelect}
                                  validate={[isRequired]}
                                  highlightType="basic"
                                  readOnly={!inEditMode}
                                  small={!inEditMode}
                                  id={`experiences_areas_${item.id}`}
                                  name={`experiences_areas_${item.id}`}
                                  label={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.areaOfSpecialization",
                                  })}
                                  placeholder={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.areaOfSpecialization",
                                  })}
                                  valueField="id"
                                  labelField="name"
                                  options={sectors.find((element) => element.id?.toString() === formValues[getKeyName("experiences_sector", item.id)]?.toString())?.specializations ?? []}
                                  multiple
                                />
                              </Grid>
                            </>
                          ) : undefined}
                        </Grid>
                      </div>
                      {index > 0 && inEditMode ? (
                        <div>
                          <IconButton onClick={() => onClickRemoveExperience(item)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ) : undefined}
                    </div>
                  </React.Fragment>
                ))}
                actions={
                  inEditMode ? (
                    <>
                      <Button
                        text={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.addNew",
                        })}
                        startIcon={<AddIcon />}
                        onClick={onClickAddExperience}
                        variant="text"
                      />
                    </>
                  ) : undefined
                }
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.populationOfConcern",
              })}
            </span>
            <div className={style.tooltipWrapper}>
              <Tooltip
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.populationOfConcernOne",
                })}
              >
                <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
              </Tooltip>
            </div>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="population_of_concern"
                name="population_of_concern"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.populationOfConcernTwo",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.populationOfConcernTwo",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.no",
                    }),
                  },
                ]}
              />
              {havePopulationOfConcern ? (
                <Field
                  component={FieldSelect}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="concern_groups"
                  name="concern_groups"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.concernGroups",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.concernGroups",
                  })}
                  valueField="value"
                  labelField="label"
                  options={populationOfConcern}
                  multiple
                />
              ) : undefined}
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.countryPresence",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Box
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.country",
                })}
                content={
                  <FieldGroup>
                    {!isCountryProfile ? (
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        highlightType="basic"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="country_presence"
                        name="country_presence"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.operate",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.operate",
                        })}
                        valueField="value"
                        labelField="label"
                        options={countries}
                        multiple
                      />
                    ) : undefined}
                    {isCountryProfile ? (
                      <Box
                        title={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.projectLocation",
                        })}
                        content={
                          <>
                            <div className={style.locationWrapper}>
                              <div className={style.newLocationSearchWrapper}>
                                <Field
                                  component={FieldSelection}
                                  validate={[isRequired]}
                                  highlightType="basic"
                                  readOnly
                                  id="country_code"
                                  name="country_code"
                                  label={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.country",
                                  })}
                                  placeholder={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.country",
                                  })}
                                  valueField="value"
                                  labelField="label"
                                  options={countries}
                                  multiple={false}
                                  height="400px"
                                  width="300px"
                                />
                              </div>
                              {formValues?.country_code ? (
                                <div className={style.newLocationMapWrapper}>
                                  <Map
                                    readOnly={!inEditMode}
                                    height="400px"
                                    onClickMap={onClickMap}
                                    markers={locations}
                                    onClickDeleteMarker={onClickDeleteLocation}
                                    countryCodes={formValues?.country_code ? [formValues?.country_code] : []}
                                    isError={locations?.length === 0}
                                  />
                                </div>
                              ) : (
                                <span className={style.newLocationMissingCountry}>
                                  {formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.noCountrySelected",
                                  })}
                                </span>
                              )}
                            </div>
                          </>
                        }
                      />
                    ) : undefined}
                    {!isCountryProfile ? (
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        highlightType="basic"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="staff_globally"
                        name="staff_globally"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.staffGlobaly",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.staffGlobaly",
                        })}
                        valueField="value"
                        labelField="label"
                        options={numberOfStaff}
                      />
                    ) : undefined}
                    {isCountryProfile ? (
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        highlightType="basic"
                        readOnly={!inEditMode}
                        small={!inEditMode}
                        id="staff_in_country"
                        name="staff_in_country"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.staffCountry",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.staffCountry",
                        })}
                        valueField="value"
                        labelField="label"
                        options={numberOfStaff}
                      />
                    ) : undefined}
                  </FieldGroup>
                }
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.security",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldText}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="security_desc"
                name="security_desc"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.description",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.mandate.mission.idx.description",
                })}
                maxLength={5000}
                withCharactersCount
                multiline
              />
            </FieldGroup>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

MandateMission.propTypes = {};

export default reduxForm({
  form: "editPartnerProfileMandateMissionForm",
  enableReinitialize: true,
})(MandateMission);

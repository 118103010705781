import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Alert from "@local/components/alert";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldText from "@local/components/field-text";
import style from "./style.module.scss";
import { reduxForm, Field } from "redux-form";
import { formatDate } from "@local/utils/index";
import FieldCheckbox from "@local/components/field-checkbox";
import { isRequired, isRequiredTrue } from "@local/utils/validators";
import { getBaseUrl } from "@local/utils/network";
import i18n from "@local/i18next/i18n";

const Question = ({ text, nameField, commentField, additionalContent, extraOptions }) => {
  return (
    <div className={style.questionWrapper}>
      {additionalContent ? <div className={style.additionalContentWrapper}>{additionalContent}</div> : undefined}
      <div className={style.questionOptionWrapper}>
        <Field
          component={FieldRadioGroup}
          validate={[isRequired]}
          fullWidthLabel
          id={nameField}
          name={nameField}
          label={text}
          placeholder={text}
          options={[
            {
              value: true,
              label: formatMessage({
                id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.yes",
              }),
            },
            {
              value: false,
              label: formatMessage({
                id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.no",
              }),
            },
          ].concat(extraOptions || [])}
        />
      </div>
      <div className={style.commentWrapper}>
        <Field
          component={FieldText}
          id={commentField}
          name={commentField}
          label={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.comment",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.comment",
          })}
          withCharactersCount
          maxLength={300}
          multiline
        />
      </div>
    </div>
  );
};

const VerifyModal = ({ isOpen, onClose, partner, onSubmit, flags, isValid }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.title",
      })}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.cancel",
            })}
          />
          <Button
            isDisabled={!isValid}
            onClick={onSubmit}
            text={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.save",
            })}
          />
        </>
      }
    >
      <Alert
        type="info"
        content={`${formatMessage({
          id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.header",
        })} ${partner?.legal_name}`}
        withBottomMargin
      />
      <>
        <Question
          text={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.certUpload",
          })}
          nameField="is_cert_uploaded"
          commentField="cert_uploaded_comment"
        />
        <Question
          text={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.mmConsistent",
          })}
          nameField="is_mm_consistent"
          commentField="mm_consistent_comment"
        />
        <Question
          text={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.indicateResults",
          })}
          nameField="is_indicate_results"
          commentField="indicate_results_comment"
        />
        <Question
          text={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.observationsPose",
          })}
          extraOptions={[
            {
              value: "test",
              label: i18n.t("No observations"),
            },
          ]}
          nameField="is_yellow_flag"
          commentField="yellow_flag_comment"
          additionalContent={
            <div>
              <span className={style.flagsTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.titleOne",
                })}
              </span>
              {flags?.length > 0 ? (
                <>
                  {flags.map((item, index) => (
                    <div key={`flag_item_${index}`} className={style.observationWrapper}>
                      <div className={style.observationCommentWrapper}>
                        <div>
                          <span className={style.label}>
                            {formatMessage({
                              id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.commentOne",
                            })}
                          </span>
                          <span className={style.value}>{item?.comment?.message}</span>
                        </div>
                        <div>
                          <span className={style.label}>
                            {formatMessage({
                              id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.created",
                            })}
                          </span>
                          <span className={style.value}>{formatDate(item?.created)}</span>
                        </div>
                        <div>
                          <span className={style.label}>
                            {formatMessage({
                              id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.contact",
                            })}
                          </span>
                          <span className={style.value}>
                            {item?.created_by?.name} ({item?.created_by?.email})
                          </span>
                        </div>
                        <div>
                          <span className={style.label}>
                            {formatMessage({
                              id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.attachment",
                            })}
                          </span>
                          {item?.attachments?.length > 0 ? (
                            <Button
                              type="link"
                              text={formatMessage({
                                id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.button",
                              })}
                              variant="text"
                              onClick={() => window.open(`${getBaseUrl()}/api/risk-profile/export-file-zip/?file_ids=${item?.attachments?.map((attachment) => attachment?.id).join(", ")}`)}
                            />
                          ) : (
                            <span className={style.value}>--</span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <span className={style.noObservations}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.noObservations",
                  })}
                </span>
              )}
            </div>
          }
        />
        <Question
          text={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.riskRelated",
          })}
          nameField="is_rep_risk"
          commentField="rep_risk_comment"
        />
        <div className={style.confirmWrapper}>
          <Field
            component={FieldCheckbox}
            validate={[isRequiredTrue]}
            id="confirm_verification"
            name="confirm_verification"
            label={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.verify.modal.idx.confirmVerification",
            })}
          />
        </div>
      </>
    </Modal>
  );
};

VerifyModal.propTypes = {};

export default reduxForm({ form: "verificationModalForm" })(VerifyModal);

export const adaptReportsCountries = (countryKey, countries) =>
  countries
    .filter((country) => countryKey.includes(country.value))
    .map((item) => item.label)
    .join(", ");

export const YEARS_VAL = () => {
  const currentYear = new Date().getFullYear();
  const FROM = 2018;
  const years = [];

  for (let year = FROM; year < currentYear + 2; year += 1) {
    years.push({
      value: String(year),
      label: String(year),
    });
  }
  return years;
};

export const checkVerificationStatus = (agencyName, verificationMenu) => {
  return verificationMenu.filter((item) => {
    if (agencyName !== "UN_SECRETARIAT" && item.value === "canBeVerified") return false;
    else return true;
  });
};

export const adaptValues = (values) => Object.keys(values).map((key) => ({ value: key, label: values[key] }));

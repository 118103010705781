import Button from "@local/components/button";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { Box, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";

import { isRequired, isValidEmail } from "@local/utils/validators";
import style from "./style.module.scss";
import { Field, reduxForm, change as changeFieldValue, reset, isValid } from "redux-form";
import BoxLocal from "@local/components/box";
import i18n from "@local/i18next/i18n";
import FieldText from "@local/components/field-text";
import FieldGroup from "@local/components/field-group";
import Alert from "@local/components/alert";
import FieldSelect from "@local/components/field-select";
import EmailForm from "./EmailForm";
import { connect } from "react-redux";
import { compose } from "redux";
import { getUsersList } from "@local/actions";
import ConfirmModal from "@local/components/confirm-modal";
const steps = [
  { id: 1, label: "Email" },
  { id: 2, label: "Check Email" },
  { id: 3, label: "Assign/Create User" },
];
const NewUserStepper = ({ isOpen, onSubmit, onUpdateSubmit, onClose, roles, emailFormValues, dispatch, isValidEmailCheckForm, isValidNewUserModalForm }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [user, setUser] = useState(null);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const onClickBackButton = () => {
    if (activeStep === 1) {
      setUser(null);
      dispatch(reset("newUserModalForm"));
      handleBack();
    } else {
      handleBack();
    }
  };

  const onClickConfirmClose = () => {
    setIsConfirmModalVisible(false);
  };

  const onClickFinalNextButton = () => {
    setIsConfirmModalVisible(true);
  };

  const onClickConfirmButton = () => {
    if (user) {
      onUpdateSubmit(user);
    } else {
      onSubmit();
    }

    setIsConfirmModalVisible(false);
    onClose();
    setActiveStep(0);
    dispatch(reset("newUserModalForm"));
    dispatch(reset("emailCheckForm"));
  };

  const onClickNextButton = async () => {
    if (activeStep === steps.length - 1) {
      onClickFinalNextButton();
    } else {
      if (activeStep === 0) {
        setUser(null);
        try {
          const res = await dispatch(getUsersList({ email: emailFormValues?.emailForCheck }));
          if (res.data.results.length > 0) {
            setUser(res.data.results[0]);
            dispatch(changeFieldValue("newUserModalForm", "email", res.data.results[0].email));
            dispatch(changeFieldValue("newUserModalForm", "fullname", res.data.results[0].fullname));
          } else {
            dispatch(changeFieldValue("newUserModalForm", "email", emailFormValues.emailForCheck));
          }
        } catch (err) {
          console.log(err);
        }
        handleNext();
      } else {
        handleNext();
      }
    }
  };
  const onCloseModal = () => {
    onClose();
    dispatch(changeFieldValue("emailCheckForm", "emailForCheck", undefined));
    setActiveStep(0);
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {i18n.t("Please enter the email address to check if it is already in use.")}
            </Grid>
            <Grid item xs={6}>
              <EmailForm />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {user ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ fontWeight: "bold" }}>{user?.email}</div>

                  <div>{i18n.t("The email address you entered already exists. Press 'Next' button to assign a role and create a user for the current partner.")}</div>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ fontWeight: "bold" }}>{emailFormValues.emailForCheck}</div>
                  <div>{i18n.t("No user exists with this email address. Press 'Next' button to create a new user for the current partner.")}</div>
                </div>
              )}
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <>
            <Alert
              type="info"
              content={formatMessage({
                id: "pages.users.management.cmp.user.manage.modal.idx.header",
              })}
              withBottomMargin
            />
            <FieldGroup>
              <Field
                readOnly={user}
                component={FieldText}
                validate={[isRequired]}
                id="fullname"
                name="fullname"
                label={formatMessage({
                  id: "pages.users.management.cmp.user.manage.modal.idx.firstName",
                })}
                placeholder={formatMessage({
                  id: "pages.users.management.cmp.user.manage.modal.idx.firstName",
                })}
                type="text"
              />
              <Field
                readOnly
                component={FieldText}
                validate={[isRequired, isValidEmail]}
                id="email"
                name="email"
                label={formatMessage({
                  id: "pages.users.management.cmp.user.manage.modal.idx.email",
                })}
                placeholder={formatMessage({
                  id: "pages.users.management.cmp.user.manage.modal.idx.email",
                })}
                type="email"
              />
              <>
                <BoxLocal
                  title={i18n.t("Role")}
                  content={
                    <div className={style.itemWrapper}>
                      <div>
                        <FieldGroup>
                          <Field
                            component={FieldSelect}
                            validate={[isRequired]}
                            id={"role"}
                            name={"role"}
                            label={formatMessage({
                              id: "pages.users.management.cmp.user.manage.modal.idx.role",
                            })}
                            placeholder={formatMessage({
                              id: "pages.users.management.cmp.user.manage.modal.idx.role",
                            })}
                            valueField="value"
                            labelField="label"
                            options={roles}
                          />
                        </FieldGroup>
                      </div>
                    </div>
                  }
                />
              </>
            </FieldGroup>
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <Modal message="" title="Add Partner User" isOpen={isOpen} onClose={onCloseModal}>
        <Box>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={step.id} {...stepProps}>
                  <StepLabel {...labelProps}>{step.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <></>
          ) : (
            <>
              <div className={style.contentWrapper}>{getStepContent(activeStep)}</div>
              <div className={style.buttonsWrapper}>
                <Button
                  text={formatMessage({
                    id: "pages.login.cmp.login.identity.modal.idx.back",
                  })}
                  color="inherit"
                  isDisabled={activeStep === 0}
                  onClick={onClickBackButton}
                />
                <Button
                  text={
                    activeStep === steps.length - 1
                      ? formatMessage({
                          id: "pages.login.cmp.login.identity.modal.idx.confirm",
                        })
                      : formatMessage({
                          id: "pages.login.cmp.login.identity.modal.idx.next",
                        })
                  }
                  isDisabled={(activeStep === 0 && !isValidEmailCheckForm) || (activeStep === 2 && !isValidNewUserModalForm)}
                  onClick={() => onClickNextButton()}
                />
              </div>
            </>
          )}
        </Box>
      </Modal>
      <ConfirmModal onClose={onClickConfirmClose} isOpen={isConfirmModalVisible} onSubmit={onClickConfirmButton} title={!user ? i18n.t("Do you confirm creating a new user for the current partner?") : i18n.t("Do you confirm assigning the user to this partner?")} />
    </>
  );
};
const mapStateToProps = (state) => {
  const emailFormValues = state.form?.emailCheckForm?.values ?? {};
  return {
    initialValues: {
      // Assuming the field in newUserModalForm is also named 'email'
      email: emailFormValues.emailForCheck,
    },
    isValidEmailCheckForm: isValid("emailCheckForm")(state),
    isValidNewUserModalForm: isValid("newUserModalForm")(state),
    emailFormValues: state.form?.emailCheckForm?.values ?? {},
    newUserModalFormValues: state.form?.newUserModalForm?.values ?? {},
  };
};

// Use compose to apply both HOCs
const enhance = compose(
  // First argument for compose: reduxForm HOC
  reduxForm({ form: "newUserModalForm", enableReinitialize: true }),
  // Second argument: connect HOC with mapStateToProps
  connect(mapStateToProps),
);

// Apply the combined HOCs to your component
export default enhance(NewUserStepper);

import Box from "@local/components/box";
import Button from "@local/components/button";
import FieldText from "@local/components/field-text";
import i18n from "@local/i18next/i18n";
import { isRequired } from "@local/utils/validators";
import { Grid } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import style from "./style.module.scss";
import { updatePartnerLegalName } from "@local/actions";
import ConfirmModal from "@local/components/confirm-modal";
import FieldCheckbox from "@local/components/field-checkbox";

const PartnerForm = ({ formValues, updatePartner, setShouldRefresh, onClose }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const updatePartnerLegalName = async (formValues) => {
    try {
      await updatePartner(formValues);
      toast.success(i18n.t("Partner legal name updated successfully"));
      onClose();
      setShouldRefresh(true);
    } catch (error) {
      toast.error(i18n.t("Partner legal name failed to update"));
    }
  };

  return (
    <div className={style.modalContent}>
      <Box
        title={i18n.t("Partner Details")}
        content={
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field validate={[isRequired]} component={FieldText} id="legal_name" name="legal_name" label={"Ogranization's Legal Name"} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />
      <div>
        <Button className={style.updateButton} text={"Update"} type="submit" onClick={() => setIsConfirmModalOpen(true)} isDisabled={!formValues?.legal_name} />
      </div>
      <ConfirmModal
        message={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field component={FieldText} id="comment" name="comment" label={"Comment"} />
            </Grid>
            <Grid item xs={12}>
              <Field validate={[isRequired]} component={FieldCheckbox} id="accept" name="accept" label={"I confirm the partner has shared sufficient documentation verifying their legal name change."} />
            </Grid>
          </Grid>
        }
        title={""}
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onSubmit={async () => updatePartnerLegalName(formValues)}
        isConfirmButtonDisabled={formValues?.accept !== true}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: state?.form?.partner?.values,
});

const mapDispatchToProps = (dispatch) => ({
  updatePartner: async (body) => dispatch(updatePartnerLegalName({ legal_name: body?.legal_name, comment: body.comment, accept: body.accept }, { partnerId: body?.id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "partner", enableReinitialize: true })(PartnerForm));

import React, { useState } from "react";
import style from "./style.module.scss";
import { getMainColor } from "@local/styles/muiTheme";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";

// POST_REFACTORING_TODO: Translations, eventually remove this component if not used

const Steps = ({ options, onChangeOption, onSubmit, isSubmitDisabled }) => {
  const [currentOption, setCurrentOption] = useState(0);

  const onClickPrevious = () => {
    const updatedCurrentOption = currentOption - 1;
    setCurrentOption(updatedCurrentOption);
    onChangeOption(options[updatedCurrentOption]);
  };

  const onClickNext = () => {
    const updatedCurrentOption = currentOption + 1;
    setCurrentOption(updatedCurrentOption);
    onChangeOption(options[updatedCurrentOption]);
  };

  const onCLickOption = (index) => {
    setCurrentOption(index);
    onChangeOption(options[index]);
  };

  return (
    <div className={style.mainWrapper} style={{ backgroundColor: undefined }}>
      <div className={style.stepsWrapper}>
        <div>
          <IconButton style={currentOption === 0 ? { color: "#22222244" } : { color: "#222222cc" }} onClick={onClickPrevious} disabled={currentOption === 0}>
            <KeyboardArrowLeft />
          </IconButton>
        </div>
        {options.map((item, index) => (
          <div key={`steps_tab_${item.id}_${index}`} className={style.step} onClick={() => onCLickOption(index)}>
            <div className={style.bulletWrapper}>
              {index > 0 ? <div className={style.row} style={index <= currentOption ? { backgroundColor: getMainColor() } : undefined} /> : <div className={style.row} style={{ visibility: "hidden" }} />}
              <div className={style.bullet} style={index <= currentOption ? { backgroundColor: getMainColor() } : undefined} />
              {index < options.length - 1 ? <div className={style.row} style={index < currentOption ? { backgroundColor: getMainColor() } : undefined} /> : <div className={style.row} style={{ visibility: "hidden" }} />}
            </div>
            <span className={style.stepLabel} style={index === currentOption ? { fontWeight: "600", color: "#333333" } : undefined}>
              {item.label}
            </span>
          </div>
        ))}
        <div>
          <IconButton style={currentOption === options.length - 1 ? { color: "#22222244" } : { color: "#222222cc" }} onClick={onClickNext} disabled={currentOption === options.length - 1}>
            <KeyboardArrowRight />
          </IconButton>
        </div>
        <div className={style.submitButtonWrapper}>
          <Button type="button" variant="contained" onClick={onSubmit} disabled={isSubmitDisabled}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

Steps.propTypes = {};

Steps.defaultProps = {};

export default Steps;

import { formatMessage } from "@local/legacy-utils/i18nHelper";

export const getShellProfilesFilters = (countries, partnerTypes) => [
  {
    id: "legal_name",
    name: "legal_name",
    label: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.name",
    }),
    placeholder: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.name",
    }),
    type: "text",
  },
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.country",
    }),
    placeholder: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: countries,
  },
  {
    id: "display_type",
    name: "display_type",
    label: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.typeOfOrganization",
    }),
    placeholder: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.typeOfOrganization",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerTypes,
  },
  {
    id: "email",
    name: "email",
    label: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.email",
    }),
    placeholder: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.email",
    }),
    type: "text",
  },
  {
    id: "vendor_number",
    name: "vendor_number",
    label: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.vendorNum",
    }),
    placeholder: formatMessage({
      id: "pages.shell.profiles.list.utils.filters.vendorNum",
    }),
    type: "text",
  },
];

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { getProjectStatusFilterValues, mapAgenciesIds, ROLES } from "@local/utils/constants";
import { useParams } from "react-router";
import { getOpenProjectsList, getDirectProjectsList, getUnsolicitedProjectsList, getCountriesList, getLocationsList, getFocalPointsList, getAgenciesList, getSectorsList, getPinnedProjectsList, getOpenDraftProjectsList, deleteOpenDraftProject } from "@local/actions";
import { getDirectProjectColumns, getDraftProjectColumns, getOpenProjectColumns, getUnsolicitedProjectColumns } from "./utils/columns";
import { getDirectProjectFilters, getDraftProjectFilters, getOpenProjectFilters, getUnsolicitedProjectFilters } from "./utils/filters";
import OpenProjectsList from "./components/open-projects-list";
import PinnedProjectsList from "./components/pinned-projects-list";
import DirectProjectsList from "./components/direct-projects-list";
import UnsolicitedProjectsList from "./components/unsolicited-projects-list";
import ManageProjectModal from "./components/manage-project-modal";
import Container from "./components/container";
import { checkPermission, COMMON_PERMISSIONS, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import { checkRestrictedPath } from "@local/legacy-utils/restrictions";
import { adaptCountries, adaptFocalPoints } from "@local/utils/adapters";
import { PARTNER, AGENCY } from "@local/legacy-reducers/nav";
import { PROJECT_TYPES } from "@local/legacy-utils/constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getSortedList } from "@local/utils";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import DraftProjectsList from "./components/draft-projects-list";
import { adaptEditDraftProjectInitialFormValues, adaptEditProjectInitialFormValues } from "./components/manage-project-modal/utils/adapters";
import ConfirmModal from "@local/components/confirm-modal";
import { toast } from "react-toastify";

// POST_REFACTORING_TODO: Agency filter should be filled by the logged in UN Agency

const getTabs = (role) =>
  [
    {
      id: 0,
      path: PROJECT_TYPES.OPEN,
      label: formatMessage({ id: "pages.projects.list.idx.PARTNER" }),
      roles: [PARTNER],
    },
    {
      id: 1,
      path: PROJECT_TYPES.PINNED,
      label: formatMessage({ id: "pages.projects.list.idx.pinned" }),
      roles: [PARTNER],
    },
    {
      id: 0,
      path: PROJECT_TYPES.OPEN,
      label: formatMessage({ id: "pages.projects.list.idx.AGENCY" }),
      roles: [AGENCY],
    },
    {
      id: 1,
      path: PROJECT_TYPES.DIRECT,
      label: formatMessage({
        id: "pages.projects.list.idx.directSelectionRetention",
      }),
      roles: [AGENCY],
    },
    {
      id: 2,
      path: PROJECT_TYPES.UNSOLICITED,
      label: formatMessage({
        id: "pages.projects.list.idx.unsolicitedConceptNotes",
      }),
      roles: [AGENCY],
    },
  ].filter((item) => item.roles.includes(role));

const ProjectsList = ({
  sectors,
  agencies,
  statuses,
  countries,
  getOpenProjects,
  getDirectProjects,
  getUnsolicitedProjects,
  getCountries,
  getLocations,
  getFocalPoints,
  getAgencies,
  getSectors,
  officeId,
  directSelectionSources,
  isRestrictedPath,
  tabs,
  hasPermission,
  role,
  focalPoints,
  getPinnedProjects,
  hasOpenProjectPermission,
  hasDirectProjectPermission,
  agencyId,
  getOpenDraftProjects,
  deleteOpenDraftProject,
}) => {
  const { type, id } = useParams();
  const [locations, setLocations] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [innerTabIndex, setInnerTabIndex] = useState(0);
  const [initialValues, setInitialValues] = useState({});
  const [isConfirmDeleteDraftModalVisible, setIsConfirmDeleteDraftModalVisible] = useState(false);
  const [draftIdtoBeDeleted, setDraftIdtoBeDeleted] = useState(undefined);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [initialStateValues, setInitialStateValues] = useState({
    selectedLocations: [],
    attachments: [
      {
        uniqueId: 0,
        originalId: undefined,
      },
    ],
    criteria: [
      {
        uniqueId: 0,
        originalId: undefined,
      },
    ],
  });

  useEffect(() => {
    getAgencies();
    getSectors();
    getCountries();
    getFocalPoints(officeId);
  }, []);

  const onClickOpenModal = () => setIsModalVisible(true);

  const onClickOpenDraftModal = useCallback(
    (values) => {
      const { formValues: draftValues, stateValues } = adaptEditDraftProjectInitialFormValues(values, type, sectors, countries);
      setInitialValues(draftValues);
      setInitialStateValues(stateValues);
      setIsModalVisible(true);
    },
    [initialStateValues, initialValues, innerTabIndex],
  );

  const onClickDeleteDraft = (id) => {
    setDraftIdtoBeDeleted(id);
    setIsConfirmDeleteDraftModalVisible(true);
  };

  const deleteDraft = async () => {
    try {
      await deleteOpenDraftProject(draftIdtoBeDeleted);
      setIsConfirmDeleteDraftModalVisible(false);
      setShouldRefresh(true);
      toast.success("Draft deleted successfully");
    } catch (error) {
      toast.error("Something went wrong while deleting draft");
    }
  };

  const onCloseDeleteDraftModal = () => {
    setIsConfirmDeleteDraftModalVisible(false);
  };

  const onClickCloseModal = () => {
    setIsModalVisible(false);
    setInitialValues({});
    if (innerTabIndex === 1) {
      setShouldRefresh(true);
    }
    setInitialStateValues({
      selectedLocations: [],
      attachments: [
        {
          uniqueId: 0,
          originalId: undefined,
        },
      ],
      criteria: [
        {
          uniqueId: 0,
          originalId: undefined,
        },
      ],
    });
  };

  let content = undefined;

  const cfeiInnerTabsContent = [
    {
      id: 0,
      label: "Open",
      content: (
        <OpenProjectsList
          columns={getOpenProjectColumns(role, sectors, countries)}
          fetchFunction={getOpenProjects}
          filters={getOpenProjectFilters(role, countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses)}
          initialFilters={role === ROLES.AGENCY ? { agency: agencyId } : undefined}
        />
      ),
    },
    {
      id: 1,
      label: "Draft",
      content: (
        <DraftProjectsList
          shouldRefresh={shouldRefresh}
          setShouldRefresh={setShouldRefresh}
          columns={getDraftProjectColumns(onClickOpenDraftModal, onClickDeleteDraft)}
          fetchFunction={getOpenDraftProjects}
          filters={getDraftProjectFilters()}
          initialFilters={role === ROLES.AGENCY ? { agency: agencyId } : undefined}
        />
      ),
    },
  ];

  const handleInnerTabChange = (event, newValue) => {
    setInnerTabIndex(newValue); // Update the inner tab index when user clicks on a tab

    // Get the current URL search params
    const searchParams = new URLSearchParams();

    // Update the URL without reloading the page
    const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
    window.history.pushState(null, "", newRelativePathQuery);
  };
  switch (type) {
    case "open":
      content =
        role === ROLES.AGENCY && hasOpenProjectPermission ? (
          <TabContext value={innerTabIndex}>
            <Tabs
              value={innerTabIndex}
              onChange={handleInnerTabChange} // Use the tab change handler
              variant="scrollable"
              scrollButtons={false}
              allowScrollButtonsMobile
            >
              {cfeiInnerTabsContent.map((tab, index) => (
                <Tab key={`cfei_navigation_tab_${index}`} label={tab.label} value={tab.id} />
              ))}
            </Tabs>
            {cfeiInnerTabsContent.map((tab, index) => (
              <TabPanel key={index} value={tab.id}>
                {tab.content}
              </TabPanel>
            ))}
          </TabContext>
        ) : (
          <OpenProjectsList
            columns={getOpenProjectColumns(role, sectors, countries)}
            fetchFunction={getOpenProjects}
            filters={getOpenProjectFilters(role, countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses)}
            initialFilters={role === ROLES.AGENCY ? { agency: agencyId } : undefined}
          />
        );
      break;
    case "pinned":
      content = <PinnedProjectsList columns={getOpenProjectColumns(role, sectors, countries)} fetchFunction={getPinnedProjects} filters={getOpenProjectFilters(role, countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses)} />;
      break;
    case "direct":
      content = (
        <DirectProjectsList
          columns={getDirectProjectColumns(sectors, countries)}
          fetchFunction={getDirectProjects}
          filters={getDirectProjectFilters(countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses, directSelectionSources)}
          initialFilters={role === ROLES.AGENCY ? { agency: agencyId } : undefined}
        />
      );
      break;
    case "unsolicited":
      content = <UnsolicitedProjectsList columns={getUnsolicitedProjectColumns(sectors, countries)} fetchFunction={getUnsolicitedProjects} filters={getUnsolicitedProjectFilters(countries, getLocations, locations, setLocations, sectors, statuses)} />;
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Container
        type={type}
        id={id}
        role={role}
        tabs={tabs}
        hasPermission={hasPermission}
        isRestrictedPath={isRestrictedPath}
        onClickHeaderButton={onClickOpenModal}
        hasOpenProjectPermission={hasOpenProjectPermission}
        hasDirectProjectPermission={hasDirectProjectPermission}
      >
        {content}
      </Container>
      {isModalVisible && <ManageProjectModal type={type} isOpen={isModalVisible} onClose={onClickCloseModal} initialValues={initialValues || { is_cn_substitute: "false" }} stateValues={initialStateValues} />}
      <ConfirmModal message={"Are you sure you want to delete this draft project?"} isOpen={isConfirmDeleteDraftModalVisible} onClose={onCloseDeleteDraftModal} onSubmit={async () => await deleteDraft()} />
    </>
  );
};

ProjectsList.propTypes = {};

const mapStateToProps = (state) => ({
  sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
  agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  statuses: getProjectStatusFilterValues(),
  officeId: state.session.officeId,
  directSelectionSources: state.partnerProfileConfig["direct-selection-source"],
  role: state.session.role,
  tabs: getTabs(state.session.role),
  hasPermission: checkPermission(COMMON_PERMISSIONS.CFEI_VIEW, state),
  hasOpenProjectPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DRAFT_CREATE, state),
  hasDirectProjectPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_DIRECT_CREATE_DRAFT_MANAGE_FOCAL_POINTS, state),
  isRestrictedPath: checkRestrictedPath(state),
  focalPoints: adaptFocalPoints(state.focalPointsList?.data?.data?.results ?? []),
  agencyId: state.session.agencyId,
});

const mapDispatchToProps = (dispatch) => ({
  deleteOpenDraftProject: (id) => dispatch(deleteOpenDraftProject({ id })),
  getOpenDraftProjects: (params) => dispatch(getOpenDraftProjectsList(params)),
  getOpenProjects: (params) => dispatch(getOpenProjectsList(params)),
  getPinnedProjects: (params) => dispatch(getPinnedProjectsList(params)),
  getDirectProjects: (params) => dispatch(getDirectProjectsList(params)),
  getUnsolicitedProjects: (params) => dispatch(getUnsolicitedProjectsList(params)),
  getCountries: () => dispatch(getCountriesList()),
  getLocations: (params) => dispatch(getLocationsList(params)),
  getFocalPoints: (officeId) => dispatch(getFocalPointsList({ focal: "true" }, { officeId })),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
  getSectors: (params) => dispatch(getSectorsList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);

import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldAutosuggest from "@local/components/field-autosuggest";
import style from "./style.module.scss";

const ManageModal = ({ isOpen, onSubmit, onClose, isValidForm, channels, fetchTagsFunction, selectedChannel }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.subscriptions.list.cmp.manage.modal.idx.headingTitle",
      })}
      footer={
        <Button
          isDisabled={!isValidForm}
          onClick={onSubmit}
          text={formatMessage({
            id: "pages.subscriptions.list.cmp.manage.modal.idx.subscribe",
          })}
        />
      }
      small
    >
      <div>
        <div className={style.headerWrapper}>
          <div className={style.headerContent}>
            <p>
              {formatMessage({
                id: "pages.subscriptions.list.cmp.manage.modal.idx.subtitle",
              })}
            </p>
          </div>
        </div>
        <FieldGroup>
          <Field
            component={FieldAutosuggest}
            id="tags"
            name="tags"
            placeholder={formatMessage({
              id: "pages.subscriptions.list.cmp.manage.modal.idx.tags",
            })}
            fetchFunction={fetchTagsFunction}
            valueField="id"
            labelField="name"
            multiple
          />
        </FieldGroup>
      </div>
    </Modal>
  );
};

ManageModal.propTypes = {};

export default reduxForm({
  form: "subscriptionModalForm",
  enableReinitialize: true,
})(ManageModal);

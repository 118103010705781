import React, { useState } from "react";
import AppBar from "@local/components/app-bar";
import SideBar from "@local/components/side-bar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import { getPartnerDeclarationQuestions } from "@local/utils/adapters";
import PartnerDeclarationQuestion from "@local/components/partner-declaration-question";
import { reduxForm } from "redux-form";
import style from "./style.module.scss";
import { connect } from "react-redux";
import { createPartnerDeclaration } from "@local/actions";
import { toast } from "react-toastify";
import { loadUserData, logoutUser } from "@local/legacy-reducers/session";
import { ROLES } from "@local/legacy-utils/constants";
import LanguageTrigger from "@local/components/language-trigger";

const isQuestionListCompleted = (formValues) =>
  formValues?.ad_1 === "true" &&
  formValues?.ad_2 === "true" &&
  formValues?.ad_3 === "true" &&
  formValues?.ad_4 === "true" &&
  formValues?.ad_5 === "true" &&
  formValues?.ad_6 === "true" &&
  formValues?.ad_7 === "true" &&
  formValues?.ad_8 === "true" &&
  formValues?.ad_9 === "true" &&
  formValues?.ad_10 === "true" &&
  formValues?.ad_11 === "true" &&
  formValues?.ad_12 === "true";

// TODO: endpoint to check if partner needs a new declaration

const MainLayout = ({ children, declarationFormValues, role, partnerDeclarationStatus, createPartnerDeclaration, loadUserInfo, logout }) => {
  const [partnerDeclarationStep, setPartnerDeclarationStep] = useState("welcome");

  const onSubmitPartnerDeclaration = async () => {
    const data = {
      declaration: getPartnerDeclarationQuestions().map((item) => ({
        answer: "Yes",
        question: item.text,
      })),
    };
    try {
      await createPartnerDeclaration(data);
      await loadUserInfo();
    } catch (error) {
      toast.error(formatMessage({ id: "cmp.mn.layout.idx.unableToSaveData" }));
    }
  };

  const onClickNextStep = () => setPartnerDeclarationStep("declaration");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <Toolbar />
        <div style={{ marginBottom: "5rem" }}>{children}</div>
      </Box>
      <Modal
        small
        isOpen={role === ROLES.PARTNER && partnerDeclarationStatus?.status === "not_done"}
        title={formatMessage({ id: "cmp.mn.layout.idx.declaration" })}
        hasLeftFooterElements
        footer={
          <>
            <LanguageTrigger />
            <div className={style.stackWrapper}>
              <Button onClick={logout} text={formatMessage({ id: "cmp.mn.layout.idx.logoutButton" })} variant="outlined" />
              {partnerDeclarationStep === "welcome" ? (
                <Button onClick={onClickNextStep} text={formatMessage({ id: "cmp.mn.layout.idx.next" })} />
              ) : (
                <Button isDisabled={!isQuestionListCompleted(declarationFormValues)} onClick={onSubmitPartnerDeclaration} text={formatMessage({ id: "cmp.mn.layout.idx.button" })} />
              )}
            </div>
          </>
        }
      >
        {partnerDeclarationStep === "welcome" ? (
          <span>
            {formatMessage({
              id: "cmp.mn.layout.idx.partnerDeclarationChangeMessage",
            })}
          </span>
        ) : (
          <>
            <span className={style.stepDescription}>
              {formatMessage({
                id: "cmp.mn.layout.idx.partnerDeclarationLabel",
              })}
            </span>
            <div className={style.questionsWrapper}>
              {getPartnerDeclarationQuestions(declarationFormValues).map((item) =>
                item?.isVisible ? <PartnerDeclarationQuestion id={item?.id} text={item?.content} nameField={item?.nameField} isPartnerDeclarationRequired={item?.errorMessage} formValues={declarationFormValues} /> : undefined,
              )}
              {isQuestionListCompleted(declarationFormValues) ? (
                <>
                  <div className={style.extraInfoWrapper}>
                    <span className={style.stepDescription}>{formatMessage({ id: "cmp.mn.layout.idx.part1" })}</span>
                    <span className={style.stepDescription}>{formatMessage({ id: "cmp.mn.layout.idx.part2" })}</span>
                    <span className={style.stepDescription}>{formatMessage({ id: "cmp.mn.layout.idx.part3" })}</span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </Modal>
      <Modal
        small
        isOpen={role === ROLES.PARTNER && ["admin_warning", "hq_warning"].includes(partnerDeclarationStatus?.status)}
        title={formatMessage({ id: "cmp.mn.layout.idx.declaration" })}
        hasLeftFooterElements
        footer={
          <>
            <LanguageTrigger />
            <Button onClick={logout} text={formatMessage({ id: "cmp.mn.layout.idx.logoutButton" })} variant="outlined" />
          </>
        }
      >
        {partnerDeclarationStatus?.status === "admin_warning" ? (
          <>
            {formatMessage({
              id: "cmp.mn.layout.idx.partnerDeclarationWarningMessage",
            })}
          </>
        ) : (
          <></>
        )}
        {partnerDeclarationStatus?.status === "hq_warning" ? (
          <>
            {formatMessage({
              id: "cmp.mn.layout.idx.partnerDeclarationHqWarningMessage",
            })}
          </>
        ) : (
          <></>
        )}
        {partnerDeclarationStatus?.contacts?.length ? (
          <div className={style.warningContactsWrapper}>
            <span>
              {formatMessage({
                id: "cmp.mn.layout.idx.partnerDeclarationWarningMessageContact",
              })}
            </span>
            {partnerDeclarationStatus?.contacts?.map(({ fullname, email }) => (
              <div className={style.warningContactItemWrapper} key={`warning_contact_${email}`}>
                <span>
                  {fullname} ({email})
                </span>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </Box>
  );
};

MainLayout.propTypes = {};

const mapStateToProps = (state) => ({
  declarationFormValues: state.form?.mainLayoutPartnerDeclarationForm?.values ?? {},
  role: state?.session?.role,
  partnerDeclarationStatus: state?.session?.partnerDeclarationStatus ?? true,
});

const mapDispatchToProps = (dispatch) => ({
  createPartnerDeclaration: (data) => dispatch(createPartnerDeclaration(data)),
  loadUserInfo: () => dispatch(loadUserData()),
  logout: () => dispatch(logoutUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: "mainLayoutPartnerDeclarationForm",
    enableReinitialize: true,
  })(MainLayout),
);

import React from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import { getPartnerDecisionsList } from "@local/actions";
import Chip from "@mui/material/Chip";

// POST_REFACTORING_TODO: Check if this is working correctly

const columns = [
  {
    id: "partner.legal_name",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.partnerDecisions.title",
    }),
    width: 250,
  },
  {
    id: "id_title",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.partnerDecisions.idTitle",
    }),
    type: "custom",
    render: (row) => `${row.id} | ${row.eoi.title}`,
  },
  {
    id: "did_accept",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.partnerDecisions.result",
    }),
    type: "custom",
    render: (row) =>
      row.did_accept ? (
        <Chip
          size="small"
          label={formatMessage({
            id: "pages.dashboard.cmp.agency.partnerDecisions.accepted",
          })}
          color="success"
        />
      ) : (
        <Chip
          size="small"
          label={formatMessage({
            id: "pages.dashboard.cmp.agency.partnerDecisions.declined",
          })}
          color="error"
        />
      ),
  },
  {
    id: "decision_date",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.partnerDecisions.decisionDate",
    }),
    type: "date",
  },
];

const PartnerDecisions = ({ getPartnerDecisions }) => {
  return (
    <Table
      id="list_of_partner_decisions"
      title={formatMessage({
        id: "pages.dashboard.cmp.agency.partnerDecisions.titleOne",
      })}
      columns={columns}
      fetchFunction={getPartnerDecisions}
    />
  );
};

PartnerDecisions.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  getPartnerDecisions: (params) => dispatch(getPartnerDecisionsList(params)),
});

export default connect(undefined, mapDispatchToProps)(PartnerDecisions);

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VerificationIcon from "@local/components/verification-icon";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Button from "@local/components/button";
import { applicationStatusColor } from "@local/styles/muiTheme";
import { APPLICATION_STATUSES, PROJECT_STATUSES } from "@local/legacy-utils/constants";
import { getPartnerNamesDisplay } from "@local/utils/constants";
import CircleIcon from "@mui/icons-material/Circle";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import SanctionStatus from "@local/components/sanction-status";
import style from "./style.module.scss";
import { formatNumberDecimals } from "@local/utils/adapters";

export const getOpenProjectApplicationsColumns = (project, updateProjectApplication, isCompleted, isFocalPoint, isCreator) => [
  {
    id: "legal_name",
    title: formatMessage({ id: "pages.project.details.utils.columns.name" }),
    type: "link",
    to: (row) => `/cfei/open/${project?.id}/applications/${row?.id}/`,
  },
  {
    id: "icons",
    title: "",
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: ".25rem" }}>
            <VerificationIcon profileStatus={row?.partner_additional?.profile_status} />
          </div>
          <SanctionStatus sanction={row?.partner_additional?.sanctions_status} />
        </div>
      );
    },
  },
  {
    id: "type_org",
    title: formatMessage({ id: "pages.project.details.utils.columns.orgType" }),
    type: "custom",
    render: (row) => getPartnerNamesDisplay()[row?.type_org],
  },
  {
    id: "id",
    title: formatMessage({ id: "pages.project.details.utils.columns.id" }),
    type: "link",
    to: (row) => `/cfei/open/${project?.id}/applications/${row?.id}/`,
  },
  {
    id: "status",
    title: formatMessage({ id: "pages.project.details.utils.columns.status" }),
    type: "custom",
    render: (row) => (
      <Chip
        icon={<CircleIcon style={{ fill: applicationStatusColor[row?.status] }} fontSize="small" />}
        label={formatMessage({
          id: `backend.common.consts.APPLICATION_STATUSES.${row?.status}`,
        })}
      />
    ),
  },
  {
    id: "download",
    title: "",
    type: "custom",
    render: (row) => (
      <div>
        <Tooltip
          title={formatMessage({
            id: "pages.project.details.utils.columns.text",
          })}
        >
          <a target="_blank" href={row?.cn?.file_field} rel="noreferrer">
            <FileDownloadIcon fontSize="small" style={{ fill: "#0000008a" }} />
          </a>
        </Tooltip>
      </div>
    ),
  },
  {
    id: "shortlist",
    title: "",
    type: "custom",
    render: (row) => (
      <div>
        <Tooltip
          title={formatMessage({
            id: "pages.project.details.utils.columns.textOne",
          })}
        >
          <span>
            <IconButton
              disabled={row?.status === APPLICATION_STATUSES.PRE || row?.status === APPLICATION_STATUSES.REC || project?.status === PROJECT_STATUSES.OPE || !(!isCompleted && (isFocalPoint || isCreator))}
              onClick={() => updateProjectApplication(row?.id, APPLICATION_STATUSES.PRE)}
            >
              <ThumbUpIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    id: "not-shortlist",
    title: "",
    type: "custom",
    render: (row) => (
      <div>
        <Tooltip
          title={formatMessage({
            id: "pages.project.details.utils.columns.textTwo",
          })}
        >
          <span>
            <IconButton
              disabled={row?.status === APPLICATION_STATUSES.REJ || !row?.review_progress?.startsWith("0") || project?.status === PROJECT_STATUSES.OPE || !(!isCompleted && (isFocalPoint || isCreator))}
              onClick={() => updateProjectApplication(row?.id, APPLICATION_STATUSES.REJ)}
            >
              <ThumbDownIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    ),
  },
];

export const getOpenProjectRequestsColumns = () => [
  {
    id: "partner",
    title: formatMessage({ id: "pages.project.details.utils.columns.partner" }),
    type: "custom",
    render: (row) => <span>{row?.partner?.legal_name}</span>,
  },
  {
    id: "created_by",
    title: formatMessage({
      id: "pages.project.details.utils.columns.createdBy",
    }),
    type: "custom",
    render: (row) => <span>{row?.created_by?.name}</span>,
  },
  {
    id: "created",
    title: formatMessage({ id: "pages.project.details.utils.columns.created" }),
    type: "date",
  },
  {
    id: "question",
    title: formatMessage({
      id: "pages.project.details.utils.columns.question",
    }),
  },
];

export const getProjectClarificationRequestsColumns = () => [
  {
    id: "created_by",
    title: formatMessage({
      id: "pages.project.details.utils.columns.requestBy",
    }),
    type: "custom",
    render: (row) => <span>{row?.created_by?.name}</span>,
  },
  {
    id: "question",
    title: formatMessage({ id: "pages.project.details.utils.columns.comment" }),
  },
  {
    id: "partner",
    title: formatMessage({
      id: "pages.project.details.utils.columns.organizationName",
    }),
    type: "custom",
    render: (row) => <span>{row?.partner?.legal_name}</span>,
  },
  {
    id: "created",
    title: formatMessage({ id: "pages.project.details.utils.columns.date" }),
    type: "date",
  },
];

export const getOpenProjectShortlistColumns = (projectId, isCreator, isSend, updateProjectApplication, selectionCriteria, onClickRecommendApplication, isFocalPoint, isApplicationDeadlinePassed, isReviewer) => [
  {
    id: "legal_name",
    title: formatMessage({
      id: "pages.project.details.utils.columns.legalName",
    }),
    type: "link",
    to: (row) => `/cfei/open/${projectId}/applications/${row?.id}/`,
  },
  {
    id: "icon",
    title: "",
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: ".25rem" }}>
            <VerificationIcon profileStatus={row?.partner_additional?.profile_status} />
          </div>
          <SanctionStatus sanction={row?.partner_additional?.sanctions_status} />
        </div>
      );
    },
  },
  {
    id: "type_org",
    title: formatMessage({ id: "pages.project.details.utils.columns.typeOrg" }),
    type: "custom",
    render: (row) => getPartnerNamesDisplay()[row?.type_org],
  },
  {
    id: "id",
    title: formatMessage({ id: "pages.project.details.utils.columns.idOne" }),
    type: "link",
    to: (row) => `/cfei/open/${projectId}/applications/${row?.id}/`,
  },
  {
    id: "your_score",
    title: formatMessage({
      id: "pages.project.details.utils.columns.yourScore",
    }),
    type: "custom",
    isVisible: isReviewer,
    render: (row) => (
      <div>
        <Tooltip
          title={
            <div>
              {row?.your_score_breakdown &&
                Object.keys(row?.your_score_breakdown).map((key) => (
                  <div className={style.tooltipItem}>
                    <span className={style.tooltipInfo}>{selectionCriteria[key.substring(0, 3)]}</span>
                    <span>{formatNumberDecimals(row?.your_score_breakdown[key]?.score)}</span>
                  </div>
                ))}
            </div>
          }
        >
          <span>{formatNumberDecimals(row?.your_score)}</span>
        </Tooltip>
      </div>
    ),
  },
  {
    id: "average_total_score",
    title: formatMessage({
      id: "pages.project.details.utils.columns.averageTotalScore",
    }),
    type: "custom",
    isVisible: isCreator || isFocalPoint,

    render: (row) => (
      <div>
        <Tooltip
          title={
            <div>
              {row?.assessments?.map((review, index) => (
                <div className={style.tooltipItem} key={`shortlist_review_key_${index}`}>
                  <span className={style.tooltipInfo}>{review?.reviewer_fullname}</span>
                  <span>{formatNumberDecimals(review?.total_score)}</span>
                </div>
              ))}
            </div>
          }
        >
          <span>{formatNumberDecimals(row?.average_total_score)}</span>
        </Tooltip>
      </div>
    ),
  },
  {
    id: "recommended_partner",
    title: formatMessage({
      id: "pages.project.details.utils.columns.recommendedPartner",
    }),
    type: "custom",
    isVisible: (isCreator || isFocalPoint) && isApplicationDeadlinePassed,
    render: (row) => {
      const isReviewsFinished = row.assessments_completed && row.completed_assessments_count > 0;
      if (!isReviewsFinished || (isCreator && isSend) || row?.did_win || row?.did_withdraw) {
        return (
          <div>
            <Tooltip
              title={
                (!isReviewsFinished &&
                  formatMessage({
                    id: "pages.project.details.utils.columns.finishReviews",
                  })) ||
                (isCreator &&
                  isSend &&
                  formatMessage({
                    id: "pages.project.details.utils.columns.send",
                  })) ||
                (row?.did_accept &&
                  formatMessage({
                    id: "pages.project.details.utils.columns.accepted",
                  })) ||
                (row?.did_accept &&
                  formatMessage({
                    id: "pages.project.details.utils.columns.declined",
                  })) ||
                (row?.did_win &&
                  !row?.did_withdraw &&
                  formatMessage({
                    id: "pages.project.details.utils.columns.selected",
                  })) ||
                (row?.did_withdraw &&
                  formatMessage({
                    id: "pages.project.details.utils.columns.retracted",
                  }))
              }
            >
              <span>
                <Button
                  isDisabled
                  text={
                    row?.status === APPLICATION_STATUSES.REC ? (
                      <span>
                        {formatMessage({
                          id: "pages.project.details.utils.columns.recommended",
                        })}
                      </span>
                    ) : (
                      <span>
                        {formatMessage({
                          id: "pages.project.details.utils.columns.recommend",
                        })}
                      </span>
                    )
                  }
                  startIcon={row?.status === APPLICATION_STATUSES.REC ? <CheckIcon /> : undefined}
                />
              </span>
            </Tooltip>
          </div>
        );
      }
      return (
        <Button
          text={
            row?.status === APPLICATION_STATUSES.REC ? (
              <span>
                {formatMessage({
                  id: "pages.project.details.utils.columns.recommended",
                })}
              </span>
            ) : (
              <span>
                {formatMessage({
                  id: "pages.project.details.utils.columns.recommend",
                })}
              </span>
            )
          }
          startIcon={row?.status === APPLICATION_STATUSES.REC ? <CheckIcon /> : undefined}
          color={row?.status === APPLICATION_STATUSES.REC ? "success" : undefined}
          onClick={() => onClickRecommendApplication(row?.id, row?.status === APPLICATION_STATUSES.REC ? APPLICATION_STATUSES.PRE : APPLICATION_STATUSES.REC)}
        />
      );
    },
  },
  {
    id: "download",
    title: "",
    type: "custom",
    render: (row) => (
      <div>
        <Tooltip
          title={formatMessage({
            id: "pages.project.details.utils.columns.text",
          })}
        >
          <span>
            <a target="_blank" href={row?.cn?.file_field} rel="noreferrer">
              <FileDownloadIcon fontSize="small" style={{ fill: "#0000008a" }} />
            </a>
          </span>
        </Tooltip>
      </div>
    ),
  },
  {
    id: "delete",
    title: "",
    type: "custom",
    isVisible: isCreator || isFocalPoint,
    render: (row) => (
      <div>
        <Tooltip
          title={formatMessage({
            id: "pages.project.details.utils.columns.textThree",
          })}
        >
          <span>
            <IconButton onClick={() => updateProjectApplication(row?.id, APPLICATION_STATUSES.PEN)}>
              <DeleteIcon fontSize="small" style={{ fill: "#0000008a" }} />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    ),
  },
];

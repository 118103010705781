/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import Box from "@local/components/box";
import { Link } from "react-router-dom";
import VerificationIcon from "@local/components/verification-icon";
import { getVerificationContent } from "@local/utils/adapters";
import style from "./style.module.scss";
import { RISK_FLAGS_REASONS } from "@local/legacy-utils/constants";
import { getPseaRatingOptions } from "@local/utils/constants";

const getRatingLabel = (rating, isExpired) => {
  const ratingOptions = getPseaRatingOptions();
  if (!rating) {
    return <span />;
  }
  if (rating === "full") {
    return (
      <>
        <span style={isExpired ? { textDecoration: "line-through", filter: "grayscale(1)" } : undefined} className={style.fullRating}>
          {ratingOptions.find((item) => item.value === rating)?.extendedLabel}
        </span>
        {isExpired ? (
          <>
            <span className={style.expiredLabel}>
              {formatMessage({
                id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.finalDeterminationExpiredLabel",
              })}
            </span>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  if (rating === "not_eligible") {
    return (
      <>
        <span style={isExpired ? { textDecoration: "line-through", filter: "grayscale(1)" } : undefined} className={style.notEligibleRating}>
          {ratingOptions.find((item) => item.value === rating)?.extendedLabel}
        </span>
        {isExpired ? (
          <>
            <span className={style.expiredLabel}>
              {formatMessage({
                id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.finalDeterminationExpiredLabel",
              })}
            </span>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  if (rating === "low_risk") {
    return (
      <>
        <span style={isExpired ? { textDecoration: "line-through", filter: "grayscale(1)" } : undefined} className={style.lowRiskRating}>
          {ratingOptions.find((item) => item.value === rating)?.extendedLabel}
        </span>
        {isExpired ? (
          <>
            <span className={style.expiredLabel}>
              {formatMessage({
                id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.finalDeterminationExpiredLabel",
              })}
            </span>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  return (
    <>
      <span style={isExpired ? { textDecoration: "line-through", filter: "grayscale(1)" } : undefined} className={style.nonFullRating}>
        {ratingOptions.find((item) => item.value === rating)?.extendedLabel}
      </span>
      {isExpired ? (
        <>
          <span className={style.expiredLabel}>
            {formatMessage({
              id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.finalDeterminationExpiredLabel",
            })}
          </span>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const CompareApplicationsModal = ({ isOpen, onClose, onSubmit, applications, type, id, budgetChoices }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.title",
      })}
      footer={
        <div>
          <Button
            onClick={onSubmit}
            text={formatMessage({
              id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.print",
            })}
          />
        </div>
      }
    >
      <Box
        content={
          <div className={style.table}>
            <div className={style.headerRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.partnerName",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.value} style={{ width: `${100 / applications.length + 1}%` }}>
                  {application?.partner_name}
                </div>
              ))}
            </div>
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.ConceptNoteId",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.value} style={{ width: `${100 / applications.length + 1}%` }}>
                  <Link alt={application?.partner_id} to={`/cfei/${type}/${id}/applications/${application?.partner_id}`}>
                    {application?.partner_id}
                  </Link>
                </div>
              ))}
            </div>
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.AverageScore",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.value} style={{ width: `${100 / applications.length + 1}%` }}>
                  {application?.total_assessment_score}
                </div>
              ))}
            </div>
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.ProfileStatus",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.flexValue} style={{ width: `${100 / applications.length + 1}%` }}>
                  <VerificationIcon profileStatus={application?.partner_additional?.profile_status} />
                  <span>{getVerificationContent(application?.partner_additional?.profile_status)?.stepText}</span>
                </div>
              ))}
            </div>
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.pseaCapacityRating",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.flexValue} style={{ width: `${100 / applications.length + 1}%` }}>
                  {getRatingLabel(application?.psea_capacity_rating, application?.psea_is_expired)}
                </div>
              ))}
            </div>
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.FlaggingStatus",
                })}
              </div>
            </div>
            {RISK_FLAGS_REASONS?.map((item) => (
              <div className={style.subBodyRow}>
                <div className={style.subLabel}>{item?.label}</div>
                {applications.map((application) => (
                  <div className={style.value} style={{ width: `${100 / applications.length + 1}%` }}>
                    {application?.partner_additional?.risk_flag_count[item?.value]}
                  </div>
                ))}
              </div>
            ))}
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.YearEstablishment",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.value} style={{ width: `${100 / applications.length + 1}%` }}>
                  {application?.year_establishment}
                </div>
              ))}
            </div>
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.UNExperience",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.value} style={{ width: `${100 / applications.length + 1}%` }}>
                  {application?.un_exp}
                </div>
              ))}
            </div>
            <div className={style.bodyRow}>
              <div className={style.label}>
                {formatMessage({
                  id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.AnnualBudget",
                })}
              </div>
              {applications.map((application) => (
                <div className={style.value} style={{ width: `${100 / applications.length + 1}%` }}>
                  {budgetChoices[application?.annual_budget]}
                </div>
              ))}
            </div>
          </div>
        }
      />
      <span>
        {formatMessage({
          id: "pages.project.details.cmp.open.project.preselected.cmp.compare.applications.modal.idx.pseaCapacityRatingDisclaimer",
        })}
      </span>
    </Modal>
  );
};

CompareApplicationsModal.propTypes = {};

export default CompareApplicationsModal;

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";

const AgencyUnsolicitedProjectsList = ({ fetchFunction, columns, filters, adapterFunction, expandSectionItems, filtersNumberOfColumns, initialFilters }) => {
  return (
    <Table
      key="projects_list_unsolicited"
      id="projects_list_unsolicited"
      title={formatMessage({
        id: "pages.partners.list.cmp.list.idx.listTitle",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      filters={filters}
      adapterFunction={adapterFunction}
      expandSectionItems={expandSectionItems}
      filtersNumberOfColumns={filtersNumberOfColumns}
      initialFilters={initialFilters}
      withUrlSynchronization
    />
  );
};

AgencyUnsolicitedProjectsList.propTypes = {};

export default AgencyUnsolicitedProjectsList;

import * as R from "ramda";

export const SAVE_ITEMS = "SAVE_ITEMS";
export const SELECT_ALL = "SELECT_ALL";

export const saveSelectedItems = (items) => ({ type: SAVE_ITEMS, items });
export const selectAllItems = (selectAll) => ({
  type: SELECT_ALL,
  items: [],
  selectAll,
});

const saveItems = (state, action) => {
  return R.assoc("items", action.items, state);
};

const selectAll = (state, action) => {
  return R.merge(state, { selectAll: action.selectAll, items: [] });
};

const initialState = {
  items: [],
  selectAll: false,
};

export const saveSelections = (selections) => (dispatch) => {
  dispatch(saveSelectedItems(selections));
};

export const saveAllItems = (selectAll) => (dispatch) => {
  dispatch(selectAllItems(selectAll));
};

export default function selectableListReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ITEMS: {
      return saveItems(state, action);
    }
    case SELECT_ALL: {
      return selectAll(state, action);
    }
    default:
      return state;
  }
}

import React, { useMemo } from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import { isRequired, isValidDate, isFutureDate } from "@local/utils/validators";
import FieldDatePicker from "@local/components/field-date-picker";
import FieldSelect from "@local/components/field-select";
import FieldCheckbox from "@local/components/field-checkbox";
import Alert from "@local/components/alert";

const ActivityModal = ({ onCancel, onSubmit, isOpen, isFormValid, pseaCsipActivities, formValues, coreStandard }) => {
  const isFutureDateValidator = useMemo(() => isFutureDate(), []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={formatMessage({
        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.addActivityTitle",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onCancel}
              variant="text"
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={!isFormValid}
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.button",
              })}
            />
          </div>
        </>
      }
    >
      <FieldGroup>
        {coreStandard?.core_standard === "CS8" ? (
          <Alert
            type="warning"
            content={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.csDisclaimerCs8",
            })}
          />
        ) : (
          <Alert
            type="info"
            content={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.csDisclaimer",
            })}
          />
        )}
        <Field
          component={FieldSelect}
          validate={[isRequired]}
          id="activity"
          name="activity"
          label={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityType",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityType",
          })}
          valueField="activity"
          labelField="description"
          options={pseaCsipActivities}
        />
        {formValues?.activity === "other" ? (
          <Field
            component={FieldText}
            validate={[isRequired]}
            id="title"
            name="title"
            label={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityTitle",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityTitle",
            })}
            type="text"
          />
        ) : undefined}
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="person_responsible"
          name="person_responsible"
          label={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityPersonResponsible",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityPersonResponsible",
          })}
          type="text"
        />
        <Field
          component={FieldDatePicker}
          validate={[isRequired, isValidDate, isFutureDateValidator]}
          id="target_date"
          name="target_date"
          minimumDate={new Date()}
          label={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityTargetDate",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityTargetDate",
          })}
        />
        {formValues?.activity === "other" ? (
          <Field
            component={FieldCheckbox}
            id="is_required"
            name="is_required"
            label={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.activity.modal.idx.pseaCsipActivityIsRequired",
            })}
          />
        ) : undefined}
      </FieldGroup>
    </Modal>
  );
};

ActivityModal.propTypes = {};

export default reduxForm({
  form: "pseaCsipCoreStandardActivityForm",
  enableReinitialize: true,
})(ActivityModal);

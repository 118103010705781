import React from "react";
import FormControl from "@mui/material/FormControl";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import style from "./style.module.scss";
import FormHelperText from "@mui/material/FormHelperText";
import { getColorByHighlightType } from "@local/utils";

const FieldDatePicker = ({ id, label, small, readOnly, inputFullWidth, helperText, input: { value, onChange }, meta, minimumDate, maximumDate, onChangeExtra, highlightType, inputFormat = "dd/MM/yyyy" }) => {
  const onChangeDate = (event) => {
    onChange(event);
    if (onChangeExtra) onChangeExtra(event);
  };

  const color = getColorByHighlightType(highlightType);

  return (
    <div>
      <FormControl fullWidth variant="outlined" className={style.datePicker}>
        <DatePicker
          inputFormat={inputFormat}
          minDate={minimumDate}
          maxDate={maximumDate}
          id={id}
          label={label}
          value={value || null}
          onChange={onChangeDate}
          disabled={readOnly}
          disableOpenPicker={readOnly}
          renderInput={(params) => <TextField fullWidth={inputFullWidth} size={small ? "small" : "normal"} {...params} error={(meta?.error ?? "") !== ""} sx={{ backgroundColor: `${color}29` }} />}
        />
        <div className={style.footerWrapper}>
          <div>
            {helperText && !meta?.error ? <FormHelperText id={`field-text-helper-text-${id}`}>{helperText}</FormHelperText> : <></>}
            {meta?.error ? (
              <FormHelperText error id={`field-text-helper-text-${id}`}>
                {meta?.error}
              </FormHelperText>
            ) : (
              <></>
            )}
          </div>
        </div>
      </FormControl>
    </div>
  );
};

FieldDatePicker.propTypes = {};

FieldDatePicker.defaultProps = {};

export default FieldDatePicker;

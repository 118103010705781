import React from "react";
import { reduxForm, Field, change } from "redux-form";
import Grid from "@mui/material/Grid";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getAgencyFocalPointsList } from "@local/actions";
import FieldGroup from "@local/components/field-group";
import FieldSelect from "@local/components/field-select";
import FieldText from "@local/components/field-text";
import FieldAutosuggest from "@local/components/field-autosuggest";
import FieldDatePicker from "@local/components/field-date-picker";
import { isRequired, isValidDate } from "@local/utils/validators";
import { connect } from "react-redux";
import { adaptFocalPointsOptions } from "@local/pages/project-details/utils/adapters";

const ConvertToDirectSelectionForm = ({ directJustificationsOptions, getAgencyFocalPoints, officeId, clearEndDate, formValues }) => {
  const onChangeExtraClearStartDate = () => {
    clearEndDate();
  };

  return (
    <FieldGroup>
      <Field
        component={FieldSelect}
        validate={[isRequired]}
        id="ds_justification_select"
        name="ds_justification_select"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.justificationFor",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.justificationFor",
        })}
        valueField="value"
        labelField="label"
        options={directJustificationsOptions}
        multiple
      />
      <Field
        component={FieldText}
        validate={[isRequired]}
        id="justification"
        name="justification"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.pleaseState",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.explain",
        })}
        withCharactersCount
        maxLength={5000}
        multiline
      />
      <Field
        component={FieldAutosuggest}
        validate={[isRequired]}
        id="focal_points"
        name="focal_points"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.focal",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.focal",
        })}
        fetchFunction={getAgencyFocalPoints}
        valueField="id"
        labelField="label"
        adapterFunction={adaptFocalPointsOptions}
        multiple
      />
      <Field
        component={FieldText}
        validate={[isRequired]}
        id="description"
        name="description"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.briefBackground",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.briefBackground",
        })}
        withCharactersCount
        maxLength={5000}
        multiline
      />
      <Field
        component={FieldText}
        id="other_information"
        name="other_information"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.other",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.other",
        })}
        withCharactersCount
        maxLength={5000}
        multiline
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Field
            component={FieldDatePicker}
            validate={[isRequired, isValidDate]}
            onChangeExtra={onChangeExtraClearStartDate}
            id="start_date"
            name="start_date"
            label={formatMessage({
              id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.estimatedStartDate",
            })}
            placeholder={formatMessage({
              id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.pickADateFirst",
            })}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={FieldDatePicker}
            validate={[isRequired, isValidDate]}
            minimumDate={formValues["start_date"]}
            id="end_date"
            name="end_date"
            label={formatMessage({
              id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.estimatedEndDate",
            })}
            placeholder={formatMessage({
              id: "pages.project.details.cmp.container.cmp.convert.to.direct.selection.form.idx.pickADateSecond",
            })}
          />
        </Grid>
      </Grid>
    </FieldGroup>
  );
};

const mapStateToProps = (state) => ({
  formValues: state.form?.convertToDirectSelectionForm?.values ?? {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAgencyFocalPoints: (name_or_email) => dispatch(getAgencyFocalPointsList({ name_or_email, focal: true, page_size: 6 }, { id: ownProps?.officeId })),
  clearEndDate: () => dispatch(change("convertToDirectSelectionForm", "end_date", null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "convertToDirectSelectionForm", enableReinitialize: true })(ConvertToDirectSelectionForm));

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Typography, ListItem, Link } from "@mui/material";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getRiskProfileDetails } from "@local/actions";
import Card from "@local/components/card";
import Check from "@local/components/check";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import style from "./style.module.scss";

const Dashboard = ({ partnerId, getRiskProfile, riskProfile }) => {
  useEffect(() => {
    getRiskProfile();
  }, []);

  const history = useHistory();

  const lastUpdateLabel = formatMessage({
    id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.lastUpdate",
  });

  const handleClickAssessments = () => history.push(`/partner/${partnerId}/risk/assessment`);

  const handleClickAudits = () => history.push(`/partner/${partnerId}/risk/audit`);

  // const handleClickProgressReport = () => history.push(`/partner/${partnerId}/risk/progress-report`);

  const handleClickFinancial = () => history.push(`/partner/${partnerId}/risk/financial`);

  const handleClickPsea = () => {
    history.push(`/partner/${partnerId}/risk/psea`);
  };

  const handleClickFlags = () => history.push(`/partner/${partnerId}/risk/flag`);

  const handleClickObservations = () => history.push(`/partner/${partnerId}/risk/observations`);

  const items = [
    {
      id: "PSEA",
      label: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.PSEA",
      }),
      lastUpdate: null,
      status: null,
      linkLabel: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.PSEADetails",
      }),
      render: (item) => (
        <Grid item xs={12}>
          <div key={`risk_item_${item.id}`} className={style.itemContainer}>
            <ListItem container className={style.item} onClick={handleClickPsea}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{item.label}</Typography>
                <Typography variant="caption">
                  {lastUpdateLabel} {formatDateForPrint(item.lastUpdate) || "- N/A"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">
                  <Link variant="body2">{item.linkLabel}</Link>
                </Typography>
              </Grid>
            </ListItem>
          </div>
        </Grid>
      ),
    },
    {
      id: "assessment",
      label: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.assessments",
      }),
      lastUpdate: riskProfile?.is_assessment_done?.last_update,
      status: riskProfile?.is_assessment_done?.status,
      render: (item) => (
        <Grid item xs={12}>
          <div key={`risk_item_${item.id}`} className={style.itemContainer}>
            <ListItem container className={style.item} onClick={handleClickAssessments}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{item.label}</Typography>
                <Typography variant="caption">
                  {lastUpdateLabel} {formatDateForPrint(item.lastUpdate) || "- N/A"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Check isChecked={item.status} />
              </Grid>
            </ListItem>
          </div>
        </Grid>
      ),
    },
    {
      id: "audit",
      label: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.audits",
      }),
      lastUpdate: riskProfile?.is_audit_done?.last_updated,
      status: riskProfile?.is_audit_done?.status,
      render: (item) => (
        <Grid item xs={12}>
          <div key={`risk_item_${item.id}`} className={style.itemContainer}>
            <ListItem container className={style.item} onClick={handleClickAudits}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{item.label}</Typography>
                <Typography variant="caption">
                  {lastUpdateLabel} {formatDateForPrint(item.lastUpdate) || "- N/A"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Check isChecked={item.status} />
              </Grid>
            </ListItem>
          </div>
        </Grid>
      ),
    },
    // {
    //   id: "progress-report",
    //   label: formatMessage({ id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.progressReport" }),
    //   lastUpdate: riskProfile?.progress_report_on_improvement_plan?.last_updated,
    //   status: riskProfile?.progress_report_on_improvement_plan?.status,
    //   render: item => (
    //     <Grid item xs={12}>
    //       <div key={`risk_item_${item.id}`} className={style.itemContainer}>
    //         <ListItem container className={style.item} onClick={handleClickProgressReport}>
    //           <Grid item xs={4}>
    //             <Typography variant="subtitle2">
    //               {item.label}
    //             </Typography>
    //             <Typography variant="caption">
    //               {lastUpdateLabel} {formatDateForPrint(item.lastUpdate) || "- N/A"}
    //             </Typography>
    //           </Grid>
    //           <Grid item xs={8}>
    //             <Check isChecked={item.status} />
    //           </Grid>
    //         </ListItem>
    //       </div>
    //     </Grid>
    //   ),
    // },
    {
      id: "financial",
      label: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.financial",
      }),
      lastUpdate: null,
      linkLabel: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.financialDetails",
      }),
      render: (item) => (
        <Grid item xs={12}>
          <div key={`risk_item_${item.id}`} className={style.itemContainer}>
            <ListItem container className={style.item} onClick={handleClickFinancial}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{item.label}</Typography>
                <Typography variant="caption">
                  {lastUpdateLabel} {formatDateForPrint(item.lastUpdate) || "- N/A"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">
                  <Link variant="body2">{item.linkLabel}</Link>
                </Typography>
              </Grid>
            </ListItem>
          </div>
        </Grid>
      ),
    },
    {
      id: "flag",
      label: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.flags",
      }),
      lastUpdate: riskProfile?.flag_status?.last_update,
      status: riskProfile?.flag_status?.status,
      render: (item) => (
        <Grid item xs={12}>
          <div key={`risk_item_${item.id}`} className={style.itemContainer}>
            <ListItem container className={style.item} onClick={handleClickFlags}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{item.label}</Typography>
                <Typography variant="caption">
                  {lastUpdateLabel} {formatDateForPrint(item.lastUpdate) || "- N/A"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Check isChecked={item.status} />
              </Grid>
            </ListItem>
          </div>
        </Grid>
      ),
    },
    {
      id: "observations",
      label: formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.observations",
      }),
      lastUpdate: riskProfile?.observation_status?.last_update,
      status: riskProfile?.observation_status?.status,
      render: (item) => (
        <Grid item xs={12}>
          <div key={`risk_item_${item.id}`} className={style.itemContainer}>
            <ListItem container className={style.item} onClick={handleClickObservations}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">{item.label}</Typography>
                <Typography variant="caption">
                  {lastUpdateLabel} {formatDateForPrint(item.lastUpdate) || "- N/A"}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Check isChecked={item.status} />
              </Grid>
            </ListItem>
          </div>
        </Grid>
      ),
    },
  ];

  return (
    <Card
      title={formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.dashboard.idx.dashboardTitle",
      })}
    >
      <Grid container>{items.map((item) => item.render(item))}</Grid>
    </Card>
  );
};

Dashboard.propTypes = {};

const mapStateToProps = (state) => ({
  riskProfile: state.riskProfileDetails?.data?.data ?? {},
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getRiskProfile: () => dispatch(getRiskProfileDetails(undefined, { id: ownProps.partnerId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

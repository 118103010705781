import React from "react";
import FormControl from "@mui/material/FormControl";
import DatePicker from "@mui/lab/DatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FormHelperText from "@mui/material/FormHelperText";
import style from "./style.module.scss";

// POST_REFACTORING_TODO: check should be done at component mount too

const FieldDateRangePicker = ({ id, label, small, readOnly, input: { value = [null, null], onChange }, meta }) => {
  const onChangeFrom = (date) => {
    if (value[1] && date > value[1]) {
      onChange([date, null]);
    } else {
      onChange([date, value[1]]);
    }
  };

  const onChangeTo = (date) => {
    if (value[0] && date < value[0]) {
      onChange([null, date]);
    } else {
      onChange([value[0], date]);
    }
  };

  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <Grid container spacing={2} className={style.datePicker}>
          <Grid item xs={6}>
            <DatePicker
              id={`${id}_from`}
              label={`${label} (${formatMessage({
                id: "cmp.field.date.range.picker.idx.from",
              })})`}
              value={value[0] || null}
              disabled={readOnly}
              onChange={onChangeFrom}
              renderInput={(params) => <TextField fullWidth size={small ? "small" : "normal"} {...params} error={(meta?.error ?? "") !== ""} />}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              id={`${id}_to`}
              label={`${label} (${formatMessage({
                id: "cmp.field.date.range.picker.idx.to",
              })})`}
              value={value[1] || null}
              disabled={readOnly}
              onChange={onChangeTo}
              renderInput={(params) => <TextField fullWidth size={small ? "small" : "normal"} {...params} error={(meta?.error ?? "") !== ""} />}
            />
          </Grid>
        </Grid>
      </FormControl>
      <div className={style.footerWrapper}>
        <div>
          {meta?.error ? (
            <FormHelperText error id={`field-text-helper-text-${id}`}>
              {meta?.error}
            </FormHelperText>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

FieldDateRangePicker.propTypes = {};

export default FieldDateRangePicker;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router";
import { connect } from "react-redux";
import { loadUserData, loginSuccess } from "../../legacy-reducers/session";

const LoginToken = ({ loadUserInfo, loggedIn }) => {
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    window.localStorage.setItem("token", params.token);
    loggedIn(params.token);
    loadUserInfo()
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        window.localStorage.removeItem("token");
        history.push("/registration");
      });
  }, []);

  return <span>Redirecting...</span>;
};

LoginToken.propTypes = {
  loadUserInfo: PropTypes.func,
  loggedIn: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  loadUserInfo: () => dispatch(loadUserData()),
  loggedIn: (token) => dispatch(loginSuccess({ user: "", token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginToken);

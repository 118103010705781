import React from "react";
import HeaderNavigation from "@local/components/header-navigation";
import MainContent from "@local/components/main-content-wrapper";
import style from "./style.module.scss";

const Container = ({ selectedTab, children, tabs, onChangeTab, title }) => {
  return (
    <HeaderNavigation
      handleChange={onChangeTab}
      index={selectedTab}
      backButtonPath={-1}
      title={title}
      tabs={tabs.map((item) =>
        item.extraIcon
          ? {
              ...item,
              label: (
                <div className={style.titleWrapper}>
                  <span>{item.label}</span>
                  <span>{item.extraIcon}</span>
                </div>
              ),
            }
          : item,
      )}
    >
      <MainContent>{children}</MainContent>
    </HeaderNavigation>
  );
};

Container.propTypes = {};

export default Container;

import React, { useState } from "react";
import style from "./style.module.scss";
import { getPseaRatingOptions, getPseaRevisionTypes } from "@local/utils/constants";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { getFinalDeterminationRevision, getPreliminaryDetermination, getInitialAssessmentJustification, getSelfAssessmentJustification, getSixMonthsJustification, getNineMonthsJustification, formatDate } from "../../../../../../utils";
import { getRatingScoreLabel } from "../../../../../../../../utils/adapters";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { ROLES } from "@local/legacy-utils/constants";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { reduxForm, Field } from "redux-form";
import Modal from "@local/components/modal";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import FieldText from "@local/components/field-text";
import { isRequired } from "@local/utils/validators";

const getRatingLabel = (ratingOptions, revision, prefix = undefined) => {
  let ratingLabel = revision?.revision_score !== null || revision?.uploaded_document_rating !== null ? getRatingScoreLabel(revision?.revision_score, ratingOptions, revision?.uploaded_document_rating) : undefined;
  if (revision?.revision_rating === "not_eligible") {
    return (
      <>
        <span>{prefix ? `${prefix} ` : ""}</span>
        <span style={revision?.is_expired ? { textDecoration: "line-through" } : undefined} className={style.notEligible}>
          {ratingOptions.find((item) => item.value === revision?.revision_rating)?.extendedLabel}
        </span>
        {revision?.is_expired ? (
          <>
            <span className={style.expiredLabel}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredLabel",
              })}
            </span>
            <div className={style.tooltipWrapper}>
              <Tooltip
                title={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredInformationText",
                })}
              >
                <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
              </Tooltip>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  if (revision?.revision_rating === "low_risk") {
    return (
      <>
        <span>{prefix ? `${prefix} ` : ""}</span>
        <b style={revision?.is_expired ? { textDecoration: "line-through" } : undefined}>
          {ratingOptions.find((item) => item.value === revision?.revision_rating)?.extendedLabel} {ratingLabel ? `(${ratingLabel}${revision?.revision_score ? `, ${revision?.revision_score}` : ""})` : ""}
        </b>
        {revision?.is_expired ? (
          <>
            <span className={style.expiredLabel}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredLabel",
              })}
            </span>
            <div className={style.tooltipWrapper}>
              <Tooltip
                title={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredInformationText",
                })}
              >
                <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
              </Tooltip>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  if ((revision.revision_score ?? undefined) === undefined) {
    return (
      <>
        <span>{prefix ? `${prefix} ` : ""}</span>
        <b style={revision?.is_expired ? { textDecoration: "line-through" } : undefined}>{ratingOptions.find((item) => item.value === revision?.revision_rating)?.extendedLabel}</b>
        {revision?.is_expired ? (
          <>
            <span className={style.expiredLabel}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredLabel",
              })}
            </span>
            <div className={style.tooltipWrapper}>
              <Tooltip
                title={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredInformationText",
                })}
              >
                <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
              </Tooltip>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
  return (
    <>
      <span>{prefix ? `${prefix} ` : ""}</span>
      <b style={revision?.is_expired ? { textDecoration: "line-through" } : undefined}>
        {ratingOptions.find((item) => item.value === revision?.revision_rating)?.extendedLabel} {revision.revision_score ? `(${revision.revision_score})` : ""}
      </b>
      {revision?.is_expired ? (
        <>
          <span className={style.expiredLabel}>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredLabel",
            })}
          </span>
          <div className={style.tooltipWrapper}>
            <Tooltip
              title={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationExpiredInformationText",
              })}
            >
              <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
            </Tooltip>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const getFinalDeterminationCompletedLabel = (revision) => {
  let result = "";
  if (revision.agency_name) {
    result = `${formatMessage({
      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.completedByLabel",
    })} ${revision.agency_name} `;
  } else if (revision.revision_type === "six_months") {
    result = `${formatMessage({
      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.sixMothTitle",
    })} ${revision.agency_name} `;
  } else if (revision.revision_type === "nine_months") {
    result = `${formatMessage({
      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.nineMothTitle",
    })} ${revision.agency_name} `;
  }
  result += `${formatMessage({
    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.completedOn",
  })} ${formatDate(revision?.revision_date)}`;
  return result;
};

const Main = ({ psea, role, justificationTypes, onClickCreateComment, onCancelCreateCommentModal, onSubmitCreateCommentModal, isCreateCommentModalOpen, isRatingCommentFormValid, pseaRatingComments }) => {
  const [visibleItems, setVisibleItems] = useState([]);

  const onClickCollapse = (sectionId) => {
    if (visibleItems.find((item) => item === sectionId)) {
      const updatedVisibleItems = visibleItems.filter((item) => item !== sectionId) ?? [];
      setVisibleItems(updatedVisibleItems);
    } else {
      const updatedVisibleItems = [...visibleItems, sectionId];
      setVisibleItems(updatedVisibleItems);
    }
  };

  const ratingOptions = getPseaRatingOptions();
  let isPreliminaryJustificationVisible = false;
  let sortedRevisions = [];
  let initialAssessment = undefined;
  let selfAssessment = undefined;
  let sixMonth = undefined;
  let nineMonth = undefined;
  let preliminaryDetermination = undefined;
  let finalDetermination = undefined;
  let initialAssessmentJustification = undefined;
  let selfAssessmentJustification = undefined;
  let preliminaryJustification = undefined;
  let sixMonthsJustification = undefined;
  let nineMonthsJustification = undefined;
  if (psea?.revisions?.length) {
    sortedRevisions = [...psea.revisions];
    sortedRevisions.sort((a, b) => a.id - b.id);
    initialAssessment = sortedRevisions.find((item) => item.revision_type === "initial_assessment") ?? {};
    selfAssessment = sortedRevisions.filter((item) => item.revision_type === "self_assessment").at(-1) ?? {};
    sixMonth = sortedRevisions.find((item) => item.revision_type === "six_months");
    nineMonth = sortedRevisions.find((item) => item.revision_type === "nine_months");
    finalDetermination = getFinalDeterminationRevision(psea.revisions);
    preliminaryDetermination = getPreliminaryDetermination(initialAssessment, selfAssessment);
    initialAssessmentJustification = getInitialAssessmentJustification(psea.revisions);
    selfAssessmentJustification = getSelfAssessmentJustification(psea.revisions);
    sixMonthsJustification = getSixMonthsJustification(psea.revisions);
    nineMonthsJustification = getNineMonthsJustification(psea.revisions);
    if (selfAssessmentJustification) {
      preliminaryJustification = selfAssessmentJustification;
    } else {
      preliminaryJustification = initialAssessmentJustification;
    }
  }

  if (
    preliminaryDetermination &&
    (!finalDetermination || finalDetermination.revision_type === "initial_assessment" || finalDetermination.revision_type === "self_assessment") &&
    (preliminaryDetermination?.revision_rating === "low" || preliminaryDetermination?.revision_rating === "medium" || preliminaryDetermination?.revision_rating === "not_eligible")
  ) {
    isPreliminaryJustificationVisible = true;
  }

  return (
    <>
      <div className={style.mainWrapper}>
        {
          <div className={style.sectionWrapper}>
            <div className={style.sectionTitleWrapper}>
              <span className={style.sectionTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.selfAssessmentTitle",
                })}
              </span>
              <IconButton onClick={() => onClickCollapse("self_assessment")} color="inherit">
                {visibleItems.find((item) => item === "self_assessment") ? <KeyboardArrowUp style={{ fill: "#757575" }} /> : <KeyboardArrowDown style={{ fill: "#757575" }} />}
              </IconButton>
            </div>
            <Collapse in={visibleItems.find((item) => item === "self_assessment")}>
              <div className={style.sectionBodyWrapper}>
                {preliminaryDetermination ? (
                  <div className={style.sectionItemWrapper}>
                    <span className={style.ratingText}>
                      {getRatingLabel(
                        ratingOptions,
                        preliminaryDetermination,
                        formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.selfAssessmentTitle",
                        }),
                      )}
                    </span>
                    <span className={style.dateText}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.completedByLabel",
                      })}{" "}
                      {preliminaryDetermination.agency_name} on {formatDate(preliminaryDetermination.revision_date)}
                    </span>
                    {isPreliminaryJustificationVisible && role === ROLES.AGENCY ? (
                      <div className={style.justificationWrapper}>
                        {preliminaryJustification?.justification_to_proceed ? (
                          <>
                            <span className={style.justificationToProceedTitle}>
                              {formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.justificationForContinuingPartnership",
                              })}
                            </span>
                            <pre className={style.justificationToProceedText}>{preliminaryJustification?.justification_to_proceed}</pre>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <>
                    <span className={style.ratingText}>
                      <span>
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.selfAssessmentTitle",
                        })}{" "}
                      </span>
                      <span className={style.emptyLabel}>
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.notAvailable",
                        })}
                      </span>
                    </span>
                  </>
                )}
              </div>
            </Collapse>
          </div>
        }
        {sixMonth ? (
          <div className={style.sectionWrapper}>
            <div className={style.sectionTitleWrapper}>
              <span className={style.sectionTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.sixMothTitle",
                })}
              </span>
              <IconButton onClick={() => onClickCollapse("six_months")} color="inherit">
                {visibleItems.find((item) => item === "six_months") ? <KeyboardArrowUp style={{ fill: "#757575" }} /> : <KeyboardArrowDown style={{ fill: "#757575" }} />}
              </IconButton>
            </div>
            <Collapse in={visibleItems.find((item) => item === "six_months")}>
              <div className={style.sectionBodyWrapper}>
                <div className={style.sectionItemWrapper}>
                  <span className={style.ratingText}>
                    {getRatingLabel(
                      ratingOptions,
                      sixMonth,
                      formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.sixMothTitle",
                      }),
                    )}
                  </span>
                  <span className={style.dateText}>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.sixMothTitle",
                    })}{" "}
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.completedOn",
                    })}{" "}
                    {formatDate(sixMonth.revision_date)}
                  </span>
                </div>
                {role === ROLES.AGENCY ? (
                  <div className={style.justificationWrapper}>
                    {sixMonthsJustification?.justification_to_proceed && !nineMonthsJustification ? (
                      <>
                        <span className={style.justificationToProceedTitle}>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.justificationForContinuingPartnership",
                          })}
                        </span>
                        <pre className={style.justificationToProceedText}>{sixMonthsJustification?.justification_to_proceed}</pre>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Collapse>
          </div>
        ) : (
          <></>
        )}
        {nineMonth ? (
          <div className={style.sectionWrapper}>
            <div className={style.sectionTitleWrapper}>
              <span className={style.sectionTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.nineMothTitle",
                })}
              </span>
              <IconButton onClick={() => onClickCollapse("nine_months")} color="inherit">
                {visibleItems.find((item) => item === "nine_months") ? <KeyboardArrowUp style={{ fill: "#757575" }} /> : <KeyboardArrowDown style={{ fill: "#757575" }} />}
              </IconButton>
            </div>
            <Collapse in={visibleItems.find((item) => item === "nine_months")}>
              <div className={style.sectionBodyWrapper}>
                <div className={style.sectionItemWrapper}>
                  <span className={style.ratingText}>
                    {getRatingLabel(
                      ratingOptions,
                      nineMonth,
                      formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.nineMothDescription",
                      }),
                    )}
                  </span>
                  <span className={style.dateText}>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.nineMothTitle",
                    })}{" "}
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.completedOn",
                    })}{" "}
                    {formatDate(nineMonth.revision_date)}
                  </span>
                </div>
                {role === ROLES.AGENCY ? (
                  <div className={style.justificationWrapper}>
                    {nineMonthsJustification?.justification_to_proceed ? (
                      <>
                        <span className={style.justificationToProceedTitle}>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.justificationForContinuingPartnership",
                          })}
                        </span>
                        <pre className={style.justificationToProceedText}>{nineMonthsJustification?.justification_to_proceed}</pre>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Collapse>
          </div>
        ) : (
          <></>
        )}
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <span className={style.sectionTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationTitle",
              })}
            </span>
          </div>
          <div className={style.sectionBodyWrapper}>
            <div className={style.sectionItemWrapper}>
              {finalDetermination ? (
                <>
                  <span className={style.ratingText}>
                    {getRatingLabel(
                      ratingOptions,
                      finalDetermination,
                      formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationTitle",
                      }),
                    )}
                  </span>
                  <span className={style.dateText}>{getFinalDeterminationCompletedLabel(finalDetermination)}</span>
                  <div className={style.ratingCommentsListWrapper}>
                    <div>
                      <span className={style.ratingCommentsTitle}>
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.unRatingCommentsListTitle",
                        })}
                      </span>
                      {pseaRatingComments
                        .filter((item) => item.is_agency)
                        ?.map((item) => (
                          <div key={`rating_comment_${item.id}`} className={style.ratingCommentItemWrapper}>
                            <pre>{item.comment}</pre>
                            <span>
                              {formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentBy",
                              })}{" "}
                              <b>{item.agency_name}</b>{" "}
                              {formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentOn",
                              })}{" "}
                              <b>{formatDate(item.comment_date)}</b>{" "}
                              {formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentRating",
                              })}{" "}
                              <b>{ratingOptions.find((option) => option.value === item?.rating)?.extendedLabel}</b>
                            </span>
                          </div>
                        ))}
                      {!pseaRatingComments.filter((item) => item.is_agency)?.length ? (
                        <span className={style.noRatingComments}>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.noRatingComments",
                          })}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    {role === ROLES.AGENCY ? (
                      <div>
                        <Button
                          onClick={onClickCreateComment}
                          text={formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.addButtonLabel",
                          })}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={style.ratingCommentsListWrapper}>
                    <div>
                      <span className={style.ratingCommentsTitle}>
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.partnerRatingCommentsListTitle",
                        })}
                      </span>
                      {pseaRatingComments
                        .filter((item) => !item.is_agency)
                        ?.map((item) => (
                          <div key={`rating_comment_${item.id}`} className={style.ratingCommentItemWrapper}>
                            <pre>{item.comment}</pre>
                            <span>
                              {formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentBy",
                              })}{" "}
                              <b>{item.agency_name}</b>{" "}
                              {formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentOn",
                              })}{" "}
                              <b>{formatDate(item.comment_date)}</b>{" "}
                              {formatMessage({
                                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentRating",
                              })}{" "}
                              <b>{ratingOptions.find((option) => option.value === item?.rating)?.extendedLabel}</b>
                            </span>
                          </div>
                        ))}
                      {!pseaRatingComments.filter((item) => !item.is_agency)?.length ? (
                        <span className={style.noRatingComments}>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.noRatingComments",
                          })}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                    {role === ROLES.PARTNER ? (
                      <div>
                        <Button
                          onClick={onClickCreateComment}
                          text={formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.addButtonLabel",
                          })}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <span className={style.ratingText}>
                    <span>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.finalDeterminationTitle",
                      })}{" "}
                    </span>
                    <span className={style.emptyLabel}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.notAvailable",
                      })}
                    </span>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {
          <div className={style.sectionWrapper}>
            <div className={style.sectionTitleWrapper}>
              <span className={style.sectionTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.historyTitle",
                })}
              </span>
              <IconButton onClick={() => onClickCollapse("rating_history")} color="inherit">
                {visibleItems.find((item) => item === "rating_history") ? <KeyboardArrowUp style={{ fill: "#757575" }} /> : <KeyboardArrowDown style={{ fill: "#757575" }} />}
              </IconButton>
            </div>
            <Collapse in={visibleItems.find((item) => item === "rating_history")}>
              {sortedRevisions.length ? (
                <table className={style.revisionsWrapper}>
                  <tr className={style.justificationHeader}>
                    <td colSpan="4"></td>
                    <td colSpan="2">
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.justificationForUnlocking",
                      })}
                    </td>
                  </tr>
                  <tr className={style.justificationHeader}>
                    <td colSpan="4"></td>
                    <td>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.justificationForUnlockingSelfAssessment",
                      })}
                    </td>
                    <td>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.justificationForUnlockingRating",
                      })}
                    </td>
                  </tr>
                  {sortedRevisions?.map((revision) => (
                    <tr key={`rating_history_item_${revision.id}`}>
                      <td>{formatDate(revision.revision_date)}</td>
                      <td>{getPseaRevisionTypes[revision.revision_type]}</td>
                      <td>{getRatingLabel(ratingOptions, revision)}</td>
                      <td>
                        {revision.agency_name ? (
                          <span>
                            {formatMessage({
                              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.historyByLabel",
                            })}{" "}
                            {revision.agency_name}
                          </span>
                        ) : (
                          <i>
                            {revision.revision_type === "six_months"
                              ? formatMessage({
                                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.sixMothTitle",
                                })
                              : formatMessage({
                                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.nineMothTitle",
                                })}
                          </i>
                        )}
                      </td>
                      <td>
                        {revision?.unlock_assessment_justification ? (
                          <span className={style.assessmentJustification}>
                            {revision?.unlock_assessment_justification?.endsWith("_other") ? revision?.unlock_assessment_comment : justificationTypes?.find((item) => item.justification === revision?.unlock_assessment_justification)?.description}
                          </span>
                        ) : undefined}
                      </td>
                      <td>
                        {revision?.unlock_rating_justification ? (
                          <span>{revision?.unlock_rating_justification?.endsWith("_other") ? revision?.unlock_rating_comment : justificationTypes?.find((item) => item.justification === revision?.unlock_rating_justification)?.description}</span>
                        ) : undefined}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <>
                  <div className={style.sectionBodyWrapper}>
                    <div className={style.sectionItemWrapper}>
                      <span className={style.ratingText}>
                        <span>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.historyTitle",
                          })}{" "}
                        </span>
                        <span className={style.emptyLabel}>
                          {formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.notAvailable",
                          })}
                        </span>
                      </span>
                    </div>
                  </div>
                </>
              )}
            </Collapse>
          </div>
        }
      </div>
      <Modal
        small
        isOpen={isCreateCommentModalOpen}
        onClose={onCancelCreateCommentModal}
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.addRatingCommentModalTitle",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onCancelCreateCommentModal}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                isDisabled={!isRatingCommentFormValid}
                onClick={onSubmitCreateCommentModal}
                text={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.submit",
                })}
              />
            </div>
          </>
        }
      >
        <FieldGroup>
          <Field
            component={FieldText}
            validate={[isRequired]}
            id="comment"
            name="comment"
            label={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentField",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.cmp.mn.idx.ratingCommentField",
            })}
            maxLength={5000}
            withCharactersCount
            multiline
          />
        </FieldGroup>
      </Modal>
    </>
  );
};

Main.propTypes = {};

export default reduxForm({
  form: "pseaRatingCommentFormValues",
  enableReinitialize: true,
})(Main);

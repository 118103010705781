import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import Button from "@local/components/button";
import ConfirmModal from "@local/components/confirm-modal";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import style from "./style.module.scss";
import Alert from "@local/components/alert";

const PartnerInformation = ({
  fetchFunction,
  columns,
  filters,
  adapterFunction,
  hasCSOProfilePermission,
  hasCSOContactPermission,
  exportPartnerProfileReports,
  exportPartnerContactReports,
  showDownloadInfo,
  downloadInfo,
  isOpen,
  onClose,
  onCloseDownloadInfo,
  onChangeTableFilters,
  isExportContactReport,
}) => {
  return (
    <>
      <Collapse in={showDownloadInfo}>
        <Alert
          type="success"
          content={
            <>
              <span className={style.alertTitle}>
                {formatMessage({
                  id: "pages.reports.list.cmp.partner.information.idx.report",
                })}
              </span>
              <span>{downloadInfo}</span>
            </>
          }
          actionLabel={formatMessage({
            id: "pages.reports.list.cmp.partner.information.idx.ok",
          })}
          onClickAction={onCloseDownloadInfo}
          withBottomMargin
        />
      </Collapse>
      <Table
        key="partner_information_reports_list"
        id="partner_information_reports_list"
        title={formatMessage({
          id: "pages.reports.list.cmp.partner.information.idx.information",
        })}
        columns={columns}
        fetchFunction={fetchFunction}
        filters={filters}
        filterInfo={formatMessage({
          id: "pages.reports.list.cmp.partner.information.idx.select",
        })}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        withUrlSynchronization
        actions={
          (hasCSOProfilePermission || hasCSOContactPermission) && (
            <>
              {hasCSOProfilePermission && (
                <Button
                  variant="text"
                  text={formatMessage({
                    id: "pages.reports.list.cmp.partner.information.idx.partnerProfile",
                  })}
                  onClick={exportPartnerProfileReports}
                />
              )}
              {hasCSOContactPermission && (
                <Button
                  variant="text"
                  text={formatMessage({
                    id: "pages.reports.list.cmp.partner.information.idx.partnerContact",
                  })}
                  onClick={exportPartnerContactReports}
                />
              )}
            </>
          )
        }
      />
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={isExportContactReport ? exportPartnerContactReports : exportPartnerProfileReports}
        title={formatMessage({
          id: "pages.reports.list.cmp.partner.information.idx.reportWarning",
        })}
        type="warning"
        message={
          <div className={style.infoWrapper}>
            <Typography variant="subtitle1" component="div">
              <Alert
                type="info"
                content={formatMessage({
                  id: "pages.reports.list.cmp.partner.information.idx.infoTitle",
                })}
                withBottomMargin
              />
            </Typography>
            <Typography variant="body1">
              <div className={style.infoText}>
                {formatMessage({
                  id: "pages.reports.list.cmp.partner.information.idx.info",
                })}
              </div>
            </Typography>
          </div>
        }
      />
    </>
  );
};

PartnerInformation.propTypes = {};

export default PartnerInformation;

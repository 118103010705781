/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Modal from "@local/components/modal";
import Table from "@local/components/table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getOpenProjectShortlistColumns } from "../../utils/columns";
import Button from "@local/components/button";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import CompareApplicationsModal from "./components/compare-applications-modal";
import { connect } from "react-redux";
import { getApplicationComparison } from "@local/actions";
import { downloadFile } from "@local/utils/network";
import { toast } from "react-toastify";
import style from "./style.module.scss";

const OpenProjectPreselected = ({
  fetchFunction,
  isCompletedAssessment,
  isFinishedReview,
  isReviewer,
  isDeadlinePassed,
  isCreator,
  isSend,
  projectId,
  updateProjectApplication,
  selectionCriteria,
  onClickRecommendApplication,
  isCompletingAssessmentVisible,
  onClickCloseCompletingAssessmentModal,
  onClickOpenCompletingAssessmentModal,
  onClickCompleteAssessment,
  isFocalPoint,
  shouldRefresh,
  afterRefresh,
  isApplicationDeadlinePassed,
  getApplicationComparison,
  type,
  budgetChoices,
}) => {
  const [selectedApplicationIds, setSelectedApplicationIds] = useState([]);
  const [isCompareApplicationsModalVisible, setIsCompareApplicationsModalVisible] = useState(false);
  const [selectedApplications, setSelectedApplications] = useState([]);

  const onClickCompareApplications = async () => {
    try {
      setIsCompareApplicationsModalVisible(true);
      const applicationsIdList = selectedApplicationIds.join(",");
      const applications = await getApplicationComparison(projectId, applicationsIdList);
      setSelectedApplications(applications?.data);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.preselected.idx.error",
        }),
      );
    }
  };

  const onClickCloseCompareApplicationsModal = () => {
    setIsCompareApplicationsModalVisible(false);
  };

  const onClickSubmitCompareApplicationsModal = () => {
    try {
      const applicationsIdList = selectedApplicationIds.join(",");
      downloadFile(`/projects/${projectId}/applications/compare-selected/?application_ids=${applicationsIdList}&export=xlsx`, "xlsx");
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.preselected.idx.error",
        }),
      );
    }
  };

  return (
    <>
      <Table
        key="open_project_shortlist"
        id="open_project_shortlist"
        title={formatMessage({
          id: "pages.project.details.cmp.open.project.preselected.idx.shortlist",
        })}
        columns={getOpenProjectShortlistColumns(projectId, isCreator, isSend, updateProjectApplication, selectionCriteria, onClickRecommendApplication, isFocalPoint, isApplicationDeadlinePassed, isReviewer)}
        fetchFunction={fetchFunction}
        shouldRefresh={shouldRefresh}
        afterRefresh={afterRefresh}
        isSelectable
        selected={selectedApplicationIds}
        setSelected={setSelectedApplicationIds}
        keyColumn="id"
        actions={
          <>
            {isReviewer && isDeadlinePassed && !(isFinishedReview === null) ? (
              !isFinishedReview || isCompletedAssessment ? (
                <Tooltip
                  title={
                    isCompletedAssessment
                      ? formatMessage({
                          id: "pages.project.details.cmp.open.project.preselected.idx.assessmentInfo",
                        })
                      : formatMessage({
                          id: "pages.project.details.cmp.open.project.preselected.idx.completeInfo",
                        })
                  }
                >
                  <span>
                    <Button
                      type="text"
                      text={
                        isCompletedAssessment
                          ? formatMessage({
                              id: "pages.project.details.cmp.open.project.preselected.idx.completeAssessment",
                            })
                          : formatMessage({
                              id: "pages.project.details.cmp.open.project.preselected.idx.assessmentCompleted",
                            })
                      }
                      isDisabled
                    />
                  </span>
                </Tooltip>
              ) : (
                <Button
                  type="text"
                  text={formatMessage({
                    id: "pages.project.details.cmp.open.project.preselected.idx.completeAssessment",
                  })}
                  onClick={onClickOpenCompletingAssessmentModal}
                />
              )
            ) : undefined}
            {selectedApplicationIds.length > 0 ? (
              <div className={style.header}>
                <div>
                  <span>
                    {selectedApplicationIds.length}{" "}
                    {formatMessage({
                      id: "pages.project.details.cmp.open.project.preselected.idx.selected",
                    })}
                  </span>
                </div>
                <div>
                  <IconButton onClick={onClickCompareApplications}>
                    <Tooltip
                      title={formatMessage({
                        id: "pages.project.details.cmp.open.project.preselected.idx.text",
                      })}
                    >
                      <CompareArrowsIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            ) : undefined}
          </>
        }
      />
      <Modal
        isOpen={isCompletingAssessmentVisible}
        onClose={onClickCloseCompletingAssessmentModal}
        title={formatMessage({
          id: "pages.project.details.cmp.open.project.preselected.idx.title",
        })}
        footer={
          <>
            <Button
              onClick={onClickCloseCompletingAssessmentModal}
              variant="text"
              text={formatMessage({
                id: "pages.project.details.cmp.open.project.preselected.idx.cancel",
              })}
            />
            <Button
              onClick={onClickCompleteAssessment}
              text={formatMessage({
                id: "pages.project.details.cmp.open.project.preselected.idx.complete",
              })}
            />
          </>
        }
      >
        <span>
          {formatMessage({
            id: "pages.project.details.cmp.open.project.preselected.idx.header",
          })}
        </span>
      </Modal>
      <CompareApplicationsModal
        isOpen={isCompareApplicationsModalVisible}
        onClose={onClickCloseCompareApplicationsModal}
        onSubmit={onClickSubmitCompareApplicationsModal}
        applications={selectedApplications}
        projectId={projectId}
        type={type}
        id={projectId}
        budgetChoices={budgetChoices}
      />
    </>
  );
};

OpenProjectPreselected.propTypes = {};

const mapStateToProps = (state) => ({
  budgetChoices: state?.partnerProfileConfig["budget-choices"],
});

const mapDispatchToProps = (dispatch) => ({
  getApplicationComparison: (projectId, applications) => dispatch(getApplicationComparison({ application_ids: applications }, { id: projectId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenProjectPreselected);

import React from "react";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldFile from "@local/components/field-file";
import { formatMessage } from "@local/legacy-utils/i18nHelper";

const ManageReviewSummaryForm = () => {
  return (
    <FieldGroup>
      <Field
        component={FieldText}
        id="review_summary_comment"
        name="review_summary_comment"
        label={formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.manage.review.summary.form.idx.comment",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.manage.review.summary.form.idx.commentPlaceholder",
        })}
        type="text"
      />
      <Field
        component={FieldFile}
        id="review_summary_attachment"
        name="review_summary_attachment"
        label={formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.manage.review.summary.form.idx.attachment",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.manage.review.summary.form.idx.attachment",
        })}
      />
    </FieldGroup>
  );
};

ManageReviewSummaryForm.propTypes = {};

export default reduxForm({
  form: "changeSummaryForm",
  enableReinitialize: true,
})(ManageReviewSummaryForm);

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";

const OpenProjectsList = ({ fetchFunction, columns, filters, adapterFunction, initialFilters }) => {
  return (
    <Table
      key="projects_list_open"
      id="projects_list_open"
      title={formatMessage({
        id: "pages.projects.list.cmp.open.projects.list.idx.openTitle",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      filters={filters}
      adapterFunction={adapterFunction}
      withUrlSynchronization
      initialFilters={initialFilters}
    />
  );
};

OpenProjectsList.propTypes = {};

export default OpenProjectsList;

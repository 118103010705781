import React from "react";
import Box from "@mui/material/Box";

const MainContentWrapper = ({ children }) => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {children}
    </Box>
  );
};

MainContentWrapper.propTypes = {};

export default MainContentWrapper;

import { getCountries } from "../legacy-utils/api";
import { sessionError } from "./session";

export const LOAD_COUNTRIES_SUCCESS = "LOAD_COUNTRIES_SUCCESS";

const loadCountriesSuccess = (countries) => ({
  type: LOAD_COUNTRIES_SUCCESS,
  countries,
});

export const loadCountries = () => (dispatch) =>
  getCountries()
    .then((countries) => {
      dispatch(loadCountriesSuccess(countries));
    })
    .catch((error) => {
      dispatch(sessionError(error));
    });

export default function countriesReducer(state = {}, action) {
  switch (action.type) {
    case LOAD_COUNTRIES_SUCCESS:
      return action.countries;
    default:
      return state;
  }
}

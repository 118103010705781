import { getBaseUrl } from "@local/utils/network";
import * as R from "ramda";
import { getUserData, login, logout, postRegistration, passwordResetConfirm } from "../legacy-utils/api";
import { ROLES, SESSION_STATUS } from "../legacy-utils/constants";

export const SESSION_CHANGE = "SESSION_CHANGE";
export const SESSION_READY = "SESSION_READY";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUBMITTING = "LOGIN_SUBMITTIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const SESSION_ERROR = "SESSION_ERROR";
export const NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE";

const home = `${getBaseUrl()}/landing/`;

const initialState = {
  role: null,
  state: SESSION_STATUS.INITIAL,
  authorized: false,
  fontSize: "16px",
  user: null,
  userId: null,
  token: null,
  userStatus: null,
  userLogged: false,
  position: null,
  agencyName: null,
  agencyId: null,
  officeName: null,
  officeId: null,
  partners: null,
  error: null,
  email: null,
  isHq: null,
  displayType: null,
  newlyRegistered: false,
  hqId: null,
  partnerCountry: null,
  partnerId: null,
  partnerName: null,
  logo: null,
  logoThumbnail: null,
  isProfileComplete: null,
  lastUpdate: null,
  notificationFrequency: null,
};

export const initSession = (session) => ({ type: SESSION_CHANGE, session });

export const sessionInitializing = () => ({
  type: SESSION_CHANGE,
  session: { state: SESSION_STATUS.CHANGING },
});

export const sessionChange = (session) => ({
  type: SESSION_CHANGE,
  session: { ...session, state: SESSION_STATUS.READY },
});

export const sessionSwitchAgencyOffice = (session) => {
  localStorage.setItem("agencyRole", JSON.stringify(session));
  return {
    type: SESSION_CHANGE,
    session: { ...session, state: SESSION_STATUS.READY },
  };
};

export const sessionReady = (getState) => ({
  type: SESSION_READY,
  session: { state: SESSION_STATUS.READY },
  role: getState().session.role,
});

export const sessionError = (error) => ({
  type: SESSION_ERROR,
  error,
});

export const updateNotification = (frequency) => ({
  type: NOTIFICATION_UPDATE,
  frequency,
});

export const loginSuccess = (session) => ({ type: LOGIN_SUCCESS, session });

export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });

export const loadUserData = () => (dispatch, getState) => {
  const { session } = getState();
  const token = session.token;
  const partnerId = session.partnerId;

  dispatch(sessionInitializing());
  return getUserData(token)
    .then((response) => {
      if (response.partners || response.agency_name) {
        const role = response.agency_name ? ROLES.AGENCY : ROLES.PARTNER;
        window.localStorage.setItem("role", role);
        let sessionObject = {
          role,
          name: response.name,
          userId: response.id,
          email: response.email,
          position: response.role,
          notificationFrequency: R.path(["profile", "notification_frequency"], response),
          // token was valid so we can authorized user
          authorized: true,
        };
        const addToSession = R.mergeDeepRight(sessionObject);
        // agency specific fields

        if (role === ROLES.AGENCY) {
          if (!response?.office_memberships && !window.location.href.endsWith("/first-access")) window.location.assign("/first-access");
          const responseOffice = response?.office_memberships?.at(0) ?? undefined;
          const localStorageOffice = JSON.parse(localStorage.getItem("agencyRole") ?? "{}");
          const agencyObject = {
            officeId: localStorageOffice?.officeId ?? responseOffice?.office_id,
            officeName: localStorageOffice?.officeName ?? responseOffice?.office?.name,
            telephone: responseOffice?.office?.telephone,
            offices: response?.office_memberships,
            officeCountryCode: localStorageOffice?.officeCountryCode ?? responseOffice?.office?.country,
            officeRole: localStorageOffice?.officeRole ?? responseOffice?.role_display,
            agencyName: responseOffice?.office?.agency?.name,
            agencyId: responseOffice?.office?.agency?.id,
            permissions: localStorageOffice?.permissions ?? responseOffice?.permissions,
            agencyCountryOffices: response?.countries,
          };
          sessionObject = addToSession(agencyObject);
        }
        // partner specific fields
        if (role === ROLES.PARTNER) {
          const mainPartner = R.defaultTo(R.find(R.propEq("is_hq", true), response.partners) || R.head(response.partners), R.find(R.propEq("id", partnerId), response.partners));

          const partnerObject = {
            partners: response.partners,
            hqId: R.propOr(null, "hq_id", mainPartner),
            partnerCountry: R.prop("country_code", mainPartner),
            partnerId: R.prop("id", mainPartner),
            partnerName: R.prop("legal_name", mainPartner),
            isHq: R.prop("is_hq", mainPartner),
            displayType: R.prop("display_type", mainPartner),
            telephone: R.path(["office", "telephone"], mainPartner),
            logo: R.prop("logo", mainPartner),
            permissions: R.prop("permissions", response),
            logoThumbnail: R.prop("org_logo_thumbnail", mainPartner) || R.prop("logo", mainPartner),
            isProfileComplete: R.path(["partner_additional", "has_finished"], mainPartner),
            lastUpdate: R.prop("last_profile_update", mainPartner),
            partnerDeclarationStatus: response.partner_declaration_status,
          };
          sessionObject = addToSession(partnerObject);
        }
        dispatch(initSession({ ...sessionObject, profileType: role }));
        dispatch(sessionReady(getState));

        return sessionObject;
      } else {
        let sessionObject = {
          name: response.name,
          userId: response.id,
          email: response.email,
          // token was valid so we can authorized user
          authorized: true,
        };
        dispatch(
          initSession({
            ...sessionObject,
            profileType: response.is_shell_profile ? "shell-profile" : "",
          }),
        );
        dispatch(sessionReady(getState));
        if (!window.location.href.endsWith("/first-access")) window.location.assign("/first-access");
        return sessionObject;
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          if (!window.location.href.endsWith("/first-access")) window.location.assign("/first-access");
        } else {
          window.localStorage.removeItem("token");
          document.cookie = "lang=en; path=/";
          window.location.href = home;
        }
      } else {
        throw error;
      }
      dispatch(
        initSession({
          authorized: false,
          role: ROLES.PARTNER,
          error,
          profileType: "",
        }),
      );
    });
};

export const loginUser = (creds) => (dispatch) =>
  login(creds).then((response) => {
    window.localStorage.setItem("token", response.key);
    dispatch(loginSuccess({ user: creds.email, token: response.key }));
    dispatch(loadUserData());
    return response;
  });

export const logoutUser = () => (dispatch, getState) => {
  localStorage.removeItem("agencyRole");
  localStorage.removeItem("role");
  let logoutRedirect = getState().partnerProfileConfig["active-directory-logout-url"];
  const userDetail = getState().session;
  if (userDetail.email.indexOf("@un.org") > 0) {
    logoutRedirect = getState().partnerProfileConfig["un-logout-url"];
  }
  return logout()
    .then(() => {
      window.localStorage.removeItem("token");
      dispatch(logoutSuccess());
      if (logoutRedirect === undefined) {
        window.location.href = home;
      } else {
        window.location.href = logoutRedirect;
      }
    })
    .catch(() => {
      window.localStorage.removeItem("token");
      dispatch(logoutSuccess());
      if (logoutRedirect === undefined) {
        window.location.href = home;
      } else {
        window.location.href = logoutRedirect;
      }
    });
};

export const registerUser = (json) => (dispatch) =>
  postRegistration(json).then(() => {
    dispatch(loginSuccess({ role: ROLES.PARTNER }));
    dispatch(sessionChange({ newlyRegistered: true }));
    dispatch(loadUserData());
    window.location.assign("/");
  });

export const changePassword = (payload) => () => passwordResetConfirm(payload);

const setSession = (state, session) => {
  return R.mergeDeepRight(state, session);
};

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_READY: {
      return setSession(state, action.session);
    }
    case SESSION_CHANGE: {
      return setSession(state, action.session);
    }
    case LOGIN_SUCCESS: {
      return setSession(state, { userLogged: true, ...action.session });
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case SESSION_ERROR: {
      return R.assoc("error", action.error, state);
    }
    case NOTIFICATION_UPDATE: {
      return R.assoc("notificationFrequency", action.frequency.notification_frequency, state);
    }
    default:
      return state;
  }
}

import i18n from "@local/i18next/i18n";
import { formatDate } from "@local/pages/partner-details/components/psea/utils";
import { getKeyName } from "@local/utils";
import { Link } from "@mui/material";

export const adaptReportsCountries = (countryKey, countries) =>
  countries
    .filter((country) => countryKey.includes(country.value))
    .map((item) => item.label)
    .join(", ");

export const YEARS_VAL = () => {
  const currentYear = new Date().getFullYear();
  const FROM = 2018;
  const years = [];

  for (let year = FROM; year < currentYear + 2; year += 1) {
    years.push({
      value: String(year),
      label: String(year),
    });
  }
  return years;
};

export const checkVerificationStatus = (agencyName, verificationMenu) => {
  return verificationMenu.filter((item) => {
    if (agencyName !== "UN_SECRETARIAT" && item.value === "canBeVerified") return false;
    else return true;
  });
};

export const adaptValues = (values) => Object.keys(values).map((key) => ({ value: key, label: values[key] }));

export const adaptFormInitialValues = (user, toAddUser, regionalOffice) => {
  if (!user) {
    return {
      id: undefined,
      formValues: toAddUser
        ? {
            email: toAddUser.split(",").at(0),
            fullname: toAddUser.split(",").at(1),
          }
        : { is_regional_office: "false" },
      stateValues: {
        officeRoles: [
          {
            id: 0,
          },
        ],
      },
    };
  }

  let officeRolesFormValues = {};
  let officeRoles = user?.office_memberships ?? [];
  if (officeRoles.length === 0) officeRoles = [{ originalId: undefined }];
  officeRoles = officeRoles.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    role: item?.role ?? undefined,
    office: item?.office?.id ?? undefined,
  }));

  officeRoles.forEach((item, index) => {
    officeRolesFormValues[getKeyName("regional_office_role_office", index)] = item.office;
    officeRolesFormValues[getKeyName("regional_office_role_role", index)] = item.role;
  });
  officeRoles.forEach((item, index) => {
    officeRolesFormValues[getKeyName("office_role_office", index)] = item.office;
    officeRolesFormValues[getKeyName("office_role_role", index)] = item.role;
  });

  return {
    id: user?.id,
    formValues: {
      fullname: user?.fullname,
      email: user?.email,
      is_regional_office: regionalOffice ? "true" : "false",
      office_role_office_main: regionalOffice ? regionalOffice?.id : undefined,
      office_role_role_main: regionalOffice ? officeRolesFormValues?.regional_office_role_role_0 : undefined,
      ...officeRolesFormValues,
    },
    stateValues: {
      officeRoles,
      officesForRegion: regionalOffice ? officeRoles : undefined,
    },
  };
};

export const adaptOfficesOfRegion = (offices, countries, countryCode) =>
  offices
    ?.map((office) => (office?.name === countries[countryCode] ? office?.id : undefined))
    ?.filter((item) => item)
    ?.at(0);

export const adaptCountriesToOffices = (offices, countries, countriesForRegion) => countriesForRegion?.map((item) => offices.find((office) => office?.name === countries[item]));

export const evalPropertyValue = (obj, keyPath, countries) => {
  const getPartnerCountry = (countries, partnerCountry) => {
    return countries?.find((country) => country.value === partnerCountry)?.label;
  };
  // Navigate through the object according to the path parts, considering optional chaining and array access
  const navigatePath = (obj, pathParts) => {
    let result = obj;
    for (let i = 0; i < pathParts.length; i++) {
      const part = pathParts[i];
      if (result === undefined || result === null) {
        return "--"; // Return placeholder if the path leads to undefined/null
      }
      // Check if part matches array access, e.g., "array[0]"
      const arrayMatch = part.match(/^(.+)\[(\d+)\]$/);
      if (arrayMatch) {
        // Access array element
        result = result[arrayMatch[1]]?.[Number(arrayMatch[2])];
      } else {
        // Standard property access, with check for optional chaining
        result = part.endsWith("?") ? result[part.slice(0, -1)] : result[part];
      }
    }
    return result === undefined || result === null ? "--" : result;
  };

  // Split the key path while considering optional chaining and array access
  const pathParts = keyPath.split(".").reduce((acc, part) => {
    // Handle optional chaining by removing '?' and keeping track of it
    if (part.includes("?")) {
      part.split("?").forEach((subPart, index, array) => {
        if (subPart) {
          acc.push(subPart + (index < array.length - 1 ? "?" : ""));
        }
      });
    } else if (part.match(/\[\d+\]/)) {
      // Directly push parts with array access
      acc.push(part);
    } else {
      acc.push(part);
    }
    return acc;
  }, []);

  // Extract the result using the path parts
  let result = navigatePath(obj, pathParts);

  // Check if the last part of the path is 'country' and apply getPartnerCountry if so
  if (pathParts[pathParts.length - 1] === "country") {
    result = getPartnerCountry(countries, result);
  }
  if (pathParts[pathParts.length - 1] === "legal_name") {
    result = (
      <a target="_blank" href={`/partner/${obj.id}/overview`} rel="noreferrer">
        {result}
      </a>
    );
  }
  // Check if the last part of the path is 'country' and apply getPartnerCountry if so
  if (pathParts[pathParts.length - 1] === "profile_completed") {
    result = result === 1 ? i18n.t("Yes") : i18n.t("No");
  }
  if (pathParts[pathParts.length - 1] === "partner_verified") {
    result = result === true ? i18n.t("Yes") : i18n.t("No");
  }
  if (pathParts[pathParts.length - 1] === "vendors") {
    result = result?.length > 0 ? result?.join(", ") : "No partner code provided by any agency";
  }
  // Handle 'issue_date' 'latest_profile_update' and 'latest_partner_member_login' case
  if (pathParts[pathParts.length - 1] === "issue_date" || pathParts[pathParts.length - 1] === "latest_profile_update" || pathParts[pathParts.length - 1] === "latest_partner_member_login") {
    // Ensure result is not '--', undefined, or null before formatting
    result = result !== "--" && result !== undefined && result !== null ? formatDate(result) : "--";
  }

  return result;
};

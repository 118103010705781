import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import style from "./style.module.scss";
import { getColorByHighlightType } from "@local/utils";

const FieldSelect = ({ id, label, options, valueField, labelField, small, helperText, multiple, onChangeExtra, readOnly, informationText, input: { value, onChange }, meta, highlightType, showEmptyElement = true }) => {
  const onChangeValue = (event) => {
    onChange(event);
    if (onChangeExtra) onChangeExtra(event);
  };

  const emptyElement = {
    [valueField]: null,
    [labelField]: <em>{formatMessage({ id: "cmp.field.select.idx.empty" })}</em>,
  };

  let computedOptions = [];
  if (multiple) {
    computedOptions = options.map((item) => ({
      ...item,
      [valueField]: item[valueField]?.toString(),
    }));
  } else {
    if (showEmptyElement) {
      computedOptions = [
        emptyElement,
        ...options.map((item) => ({
          ...item,
          [valueField]: item[valueField]?.toString(),
        })),
      ];
    } else {
      computedOptions = [
        ...options.map((item) => ({
          ...item,
          [valueField]: item[valueField]?.toString(),
        })),
      ];
    }
  }

  let computedValue = "";
  if (multiple) {
    if (!value) {
      computedValue = [];
    } else {
      computedValue = value?.map((item) => item?.toString());
    }
  } else {
    if ((value ?? undefined) === undefined) {
      computedValue = "";
    } else {
      computedValue = value?.toString();
    }
  }
  const color = getColorByHighlightType(highlightType);
  return (
    <div className={style.selectWrapper}>
      <div className={style.controlWrapper}>
        <FormControl error={(meta?.error ?? "") !== ""} fullWidth variant="outlined" disabled={readOnly}>
          <InputLabel size={small ? "small" : "normal"} id={`select_label_${id}`}>
            {label}
          </InputLabel>
          <Select
            sx={{ backgroundColor: `${color}29` }}
            labelId={`select_label_${id}`}
            id={id}
            value={computedValue}
            label={label}
            onChange={onChangeValue}
            size={small ? "small" : "normal"}
            multiple={multiple}
            MenuProps={{ style: { maxHeight: "300px" } }}
            renderValue={(toRender) => {
              if (multiple) {
                const items = computedOptions.filter((option) => !option.isTitle && toRender.includes(option[valueField]));
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {items.map((item) => (
                      <div key={item[labelField]} className={style.selectedOptionWrapper}>
                        <Chip label={item[labelField]} size={small ? "small" : "normal"} />
                      </div>
                    ))}
                  </Box>
                );
              }
              const item = computedOptions.find((option) => option[valueField] === computedValue);
              return item ? (
                <Box>
                  <div key={item[labelField]} className={style.selectedOptionWrapper}>
                    <Chip label={item[labelField]} size={small ? "small" : "normal"} />
                  </div>
                </Box>
              ) : undefined;
            }}
          >
            {computedOptions.map((item, index) =>
              item.isTitle ? (
                <MenuItem
                  style={{
                    margin: ".5rem 0",
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                    opacity: "1",
                  }}
                  disabled
                  key={`select_item_${id}_${index}`}
                  value={item[valueField]}
                >
                  {item[labelField]}
                </MenuItem>
              ) : (
                <MenuItem key={`select_item_${id}_${index}`} value={item[valueField]}>
                  {item[labelField]}
                </MenuItem>
              ),
            )}
          </Select>
          {helperText && !meta?.error ? <FormHelperText id={`select_helper_text_${id}`}>{helperText}</FormHelperText> : <></>}
          {meta?.error ? (
            <FormHelperText error id={`field-text-helper-text-${id}`}>
              {meta?.error}
            </FormHelperText>
          ) : (
            <></>
          )}
        </FormControl>
      </div>
      {informationText ? (
        <div className={style.tooltipWrapper}>
          <Tooltip title={informationText}>
            <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
          </Tooltip>
        </div>
      ) : undefined}
    </div>
  );
};

FieldSelect.propTypes = {};

export default FieldSelect;

import en from "@local/translations/en.json";
import es from "@local/translations/es.json";
import fr from "@local/translations/fr.json";

const languages = { en, es, fr };

export const getSelectedLanguage = () => {
  const localStorageLanguage = localStorage.getItem("language");
  if (localStorageLanguage) return localStorageLanguage;
  let selectedLanguage = "";
  const browserLanguage = (navigator.language.split("-")[0] || "").toLocaleLowerCase();
  selectedLanguage = browserLanguage && ["en", "es", "fr"].includes(browserLanguage) ? browserLanguage : "en";
  setSelectedLanguage(selectedLanguage);
  return selectedLanguage;
};

export const setSelectedLanguage = (language) => localStorage.setItem("language", language);

export const swapKeyValue = (json) => {
  var ret = {};
  for (var key in json) {
    ret[json[key]] = key;
  }
  return ret;
};

export const formatMessage = (object) => {
  const selectedLang = getSelectedLanguage();
  const selectedMessages = languages[selectedLang];
  const { id, text } = object;
  if (selectedMessages[id] !== undefined) {
    return selectedMessages[id];
  } else if (text !== undefined) {
    return text;
  } else {
    return selectedMessages["default.fallback"];
  }
};

export const PARTNER_TYPES = {
  "backend.common.consts.PARTNER_TYPES.CBO": "Community Based Organization (CBO)",
  "backend.common.consts.PARTNER_TYPES.NGO": "National NGO",
  "backend.common.consts.PARTNER_TYPES.Int": "International Civil Society Organization",
  "backend.common.consts.PARTNER_TYPES.Aca": "Academic Institution",
  "backend.common.consts.PARTNER_TYPES.RCC": "Red Cross/Red Crescent National Society",
  "backend.common.consts.PARTNER_TYPES.GOV": "Government",
  "backend.common.consts.PARTNER_TYPES.UN": "UN Agency",
  "backend.common.consts.PARTNER_TYPES.GAI": "Government Academic Institution",
};

export const CONCERN_GROUPS = {
  "backend.common.consts.CONCERN_.Ref": "Refugees",
  "backend.common.consts.CONCERN_.Asy": "Asylum seekers",
  "backend.common.consts.CONCERN_.IDP": "Internally displaced persons",
  "backend.common.consts.CONCERN_.Sta": "Stateless",
  "backend.common.consts.CONCERN_.Ret": "Returning",
  "backend.common.consts.CONCERN_.Hos": "Host Country",
  "backend.common.consts.CONCERN_.You": "Youth and adolescent",
  "backend.common.consts.CONCERN_.Wom": "Women",
  "backend.common.consts.CONCERN_.Chi": "Children",
};

export const SECTORS = {
  "backend.common.fixtures.initial.common.sector.Camp_Coordination_&_Camp_Management": "Camp Coordination & Camp Management",
  "backend.common.fixtures.initial.common.sector.Education": "Education",
  "backend.common.fixtures.initial.common.sector.Food_Security": "Food Security",
  "backend.common.fixtures.initial.common.sector.Health": "Health",
  "backend.common.fixtures.initial.common.sector.Livelihoods": "Livelihoods",
  "backend.common.fixtures.initial.common.sector.Logistics": "Logistics",
  "backend.common.fixtures.initial.common.sector.Nutrition": "Nutrition",
  "backend.common.fixtures.initial.common.sector.Protection": "Protection",
  "backend.common.fixtures.initial.common.sector.Shelter": "Shelter",
  "backend.common.fixtures.initial.common.sector.WASH_and_Environment": "WASH and Environment",
  "backend.common.fixtures.initial.common.sector.Social_Policy_and__Advocacy": "Social Policy and  Advocacy",
  "backend.common.fixtures.initial.common.sector.Durable_Solutions": "Durable Solutions",
  "backend.common.fixtures.initial.common.sector.Cross_Sectoral_Areas": "Cross Sectoral Areas",
};

export const SPECIALIZATIONS = {
  "backend.common.fixtures.initial.common.specialization.Camp_management": "Camp management",
  "backend.common.fixtures.initial.common.specialization.Coordination_and_Partnerships": "Coordination and Partnerships",
  "backend.common.fixtures.initial.common.specialization.Early_childhood_development": "Early childhood development",
  "backend.common.fixtures.initial.common.specialization.Learning_Outcomes": "Learning Outcomes",
  "backend.common.fixtures.initial.common.specialization.Out-of-school_education": "Out-of-school education",
  "backend.common.fixtures.initial.common.specialization.Skills_development_for_adolescents": "Skills development for adolescents",
  "backend.common.fixtures.initial.common.specialization.School_meals": "School meals",
  "backend.common.fixtures.initial.common.specialization.Education_in_emergencies": "Education in emergencies",
  "backend.common.fixtures.initial.common.specialization.Agricultural_inputs": "Agricultural inputs",
  "backend.common.fixtures.initial.common.specialization.Food_assistance": "Food assistance",
  "backend.common.fixtures.initial.common.specialization.Cash_assistance": "Cash assistance",
  "backend.common.fixtures.initial.common.specialization.Food_assistance_for_assets": "Food assistance for assets",
  "backend.common.fixtures.initial.common.specialization.Food_security_and_market_analysis/vulnerability_analysis_and_mapping_(VAM)": "Food security and market analysis/vulnerability analysis and mapping (VAM)",
  "backend.common.fixtures.initial.common.specialization.Maternal_and_newborn_health": "Maternal and newborn health, including emergency obstetric care",
  "backend.common.fixtures.initial.common.specialization.Child_health": "Child health",
  "backend.common.fixtures.initial.common.specialization.Adolescent_health": "Adolescent health, including sexual and reproductive health",
  "backend.common.fixtures.initial.common.specialization.Reproductive_health_services": "Reproductive health services",
  "backend.common.fixtures.initial.common.specialization.Immunization": "Immunization",
  "backend.common.fixtures.initial.common.specialization.HIV/AIDS": "HIV/AIDS",
  "backend.common.fixtures.initial.common.specialization.Health_systems_strengthening": "Health systems strengthening",
  "backend.common.fixtures.initial.common.specialization.Health_in_emergencies": "Health in emergencies",
  "backend.common.fixtures.initial.common.specialization.Health_-_general": "Health - general",
  "backend.common.fixtures.initial.common.specialization.Technical_and_vocational_education_and_training": "Technical and vocational education and training",
  "backend.common.fixtures.initial.common.specialization.Microfinance,_microcredit_and_revolving_loan_funds": "Microfinance, microcredit and revolving loan funds",
  "backend.common.fixtures.initial.common.specialization.Cash_and_voucher_assistance": "Cash and voucher assistance",
  "backend.common.fixtures.initial.common.specialization.Smallholder_agricultural_market_support": "Smallholder agricultural market support",
  "backend.common.fixtures.initial.common.specialization.Co-existence_with_local_communities": "Co-existence with local communities",
  "backend.common.fixtures.initial.common.specialization.Community_mobilization": "Community mobilization",
  "backend.common.fixtures.initial.common.specialization.Self_reliance": "Self reliance",
  "backend.common.fixtures.initial.common.specialization.Procurement_of_goods_and_services": "Procurement of goods and services",
  "backend.common.fixtures.initial.common.specialization.Warehouse_and_inventory_management": "Warehouse and inventory management",
  "backend.common.fixtures.initial.common.specialization.Distribution_of_supplies,_including_basic_and_domestic_items": "Distribution of supplies, including basic and domestic items",
  "backend.common.fixtures.initial.common.specialization.Operations_management,_coordination_and_support": "Operations management, coordination and support",
  "backend.common.fixtures.initial.common.specialization.Vehicle_workshop_in_the_field_(vehicle_maintenance_and_servicing)": "Vehicle workshop in the field (vehicle maintenance and servicing)",
  "backend.common.fixtures.initial.common.specialization.Logistics_in_emergencies": "Logistics in emergencies",
  "backend.common.fixtures.initial.common.specialization.Infant_and_young_child_feeding": "Infant and young child feeding",
  "backend.common.fixtures.initial.common.specialization.Micronutrients": "Micronutrients",
  "backend.common.fixtures.initial.common.specialization.Malnutrition_prevention_and_treatment": "Malnutrition prevention and treatment",
  "backend.common.fixtures.initial.common.specialization.Nutrition_in_emergencies": "Nutrition in emergencies",
  "backend.common.fixtures.initial.common.specialization.Nutrition_-_general": "Nutrition - general",
  "backend.common.fixtures.initial.common.specialization.Legal_assistance_and_access_to_justice": "Legal assistance and access to justice",
  "backend.common.fixtures.initial.common.specialization.Protection_advice_and_support": "Protection advice and support",
  "backend.common.fixtures.initial.common.specialization.Prevention_of_and_response_to_violence,_including_SGBV": "Prevention of and response to crime/violence",
  "backend.common.fixtures.initial.common.specialization.Birth_registration": "Birth registration",
  "backend.common.fixtures.initial.common.specialization.Registration_and_profiling": "Registration and profiling",
  "backend.common.fixtures.initial.common.specialization.Access_to_asylum": "Access to asylum",
  "backend.common.fixtures.initial.common.specialization.Detention_and_freedom_of_movement": "Detention and freedom of movement",
  "backend.common.fixtures.initial.common.specialization.Family_re-unification": "Family re-unification",
  "backend.common.fixtures.initial.common.specialization.Protection_in_emergencies": "Protection in emergencies",
  "backend.common.fixtures.initial.common.specialization.Protection_-_general": "Protection - general",
  "backend.common.fixtures.initial.common.specialization.Shelter-related_non-food-items_(NFIs)": "Shelter-related non-food-items (NFIs)",
  "backend.common.fixtures.initial.common.specialization.Shelter_construction_and_reconstruction": "Shelter construction and reconstruction",
  "backend.common.fixtures.initial.common.specialization.Settlement_support_(e.g._site_planning,_urban_planning)": "Settlement support (e.g. site planning, urban planning)",
  "backend.common.fixtures.initial.common.specialization.Shelter_preparedness_and_risk_reduction": "Shelter preparedness and risk reduction",
  "backend.common.fixtures.initial.common.specialization.Shelter_in_emergencies": "Shelter in emergencies",
  "backend.common.fixtures.initial.common.specialization.Safe_drinking_water": "Safe drinking water",
  "backend.common.fixtures.initial.common.specialization.Basic_sanitation": "Basic sanitation",
  "backend.common.fixtures.initial.common.specialization.Hygiene": "Hygiene",
  "backend.common.fixtures.initial.common.specialization.Environmental_pollution_and_climate_action": "Environmental pollution and climate action",
  "backend.common.fixtures.initial.common.specialization.Energy": "Energy",
  "backend.common.fixtures.initial.common.specialization.WASH_in_emergencies": "WASH in emergencies",
  "backend.common.fixtures.initial.common.specialization.Social_protection_and_multidimensional_poverty": "Social protection and multidimensional poverty",
  "backend.common.fixtures.initial.common.specialization.Public_finance_and_local_governance": "Public finance and local governance",
  "backend.common.fixtures.initial.common.specialization.Policy_advocacy": "Policy advocacy",
  "backend.common.fixtures.initial.common.specialization.South-South_cooperation": "South-South cooperation",
  "backend.common.fixtures.initial.common.specialization.Social_safety_nets": "Social safety nets",
  "backend.common.fixtures.initial.common.specialization.Social_policy_and_advocacy_-_general": "Social policy and advocacy - general",
  "backend.common.fixtures.initial.common.specialization.Integration,_Reintegration": "Integration, Reintegration",
  "backend.common.fixtures.initial.common.specialization.Resettlement": "Resettlement",
  "backend.common.fixtures.initial.common.specialization.Voluntary_return": "Voluntary return",
  "backend.common.fixtures.initial.common.specialization.Gender_equality": "Gender equality and women empowerment",
  "backend.common.fixtures.initial.common.specialization.Training,_capacity_building_and_capacity_development": "Training, capacity building and capacity development",
  "backend.common.fixtures.initial.common.specialization.Communication_for_development": "Communication for development",
  "backend.common.fixtures.initial.common.specialization.Research_and_data_analysis": "Research and data analysis",
  "backend.common.fixtures.initial.common.specialization.Monitoring_and_evaluation": "Monitoring and evaluation",
  "backend.common.fixtures.initial.common.specialization.Resource_mobilization": "Resource mobilization",
  "backend.common.fixtures.initial.common.specialization.Disaster_risk_reduction": "Disaster risk reduction",
  "backend.common.fixtures.initial.common.specialization.Emergency_management": "Emergency management",
  "backend.common.fixtures.initial.common.specialization.Early_recovery": "Early recovery",
  "backend.common.fixtures.initial.common.specialization.Conflict_Sensitivity_and_Humanitarian_Access": "Conflict Sensitivity and Humanitarian Access",
  "backend.common.fixtures.initial.common.specialization.Service_provision_and_platforms_activities": "Service provision and platforms activities",
  "backend.common.fixtures.initial.common.specialization.Comprehensive_sexuality_education": "Comprehensive sexuality education",
  "backend.common.fixtures.initial.common.specialization.Sexual_&_reproductive_health": "Sexual & reproductive health",
  "backend.common.fixtures.initial.common.specialization.Last_mile_delivery": "Last mile delivery",
  "backend.common.fixtures.initial.common.specialization.Gender_based_violence": "Gender based violence",
  "backend.common.fixtures.initial.common.specialization.Engaging_men_and_boys": "Engaging men and boys",
  "backend.common.fixtures.initial.common.specialization.Youth_leadership_and_participation": "Youth leadership and participation",
  "backend.common.fixtures.initial.common.specialization.Life_skills_learning_and_training": "Life skills learning and training",
  "backend.common.fixtures.initial.common.specialization.Mental_health": "Mental health",
  "backend.common.fixtures.initial.common.specialization.Medical_and_associated_services_provider": "Medical and associated services provider",
  "backend.common.fixtures.initial.common.specialization.Drug_use_prevention_and_treatment": "Drug use prevention and treatment",
  "backend.common.fixtures.initial.common.specialization.Mine_Action": "Mine Action",
  "backend.common.fixtures.initial.common.specialization.Youth_empowerment": "Youth empowerment",
};

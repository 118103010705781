import React, { useEffect, useState } from "react";
import UsersList from "./components/users-list";
import OfficesList from "./components/offices-list";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getUserStatuses, usersNav } from "@local/utils/constants";
import { adaptRoles } from "@local/utils/adapters";
import { connect } from "react-redux";
import UserManageModal from "./components/user-manage-modal";
import OfficeManageModal from "./components/office-manage-modal";
import {
  getUsersList,
  createUser,
  updateUser,
  toggleUser,
  getOfficesList,
  getAgencyMemberReports,
  getRegionalOfficesList,
  createRegionalOffice,
  updateRegionalOffice,
  deleteRegionalOffice,
  getRegionalOffice,
  createRegionalOfficeMember,
  getRegionalOfficeMember,
  deleteRegionalOfficeMember,
} from "@local/actions";
import { toast } from "react-toastify";
import { change, isValid, reset } from "redux-form";
import Container from "./components/container";
import { getUserColumns, getOfficesColumns } from "./utils/columns";
import { getUserFilters, getOfficeFilters } from "./utils/filters";
import { adaptFormInitialValues, adaptFormValuesToBeSent, adaptOfficeFormValuesToBeSent, adaptOfficeFormInitialValues } from "./utils/adapters";
import Collapse from "@mui/material/Collapse";
import Alert from "@local/components/alert";
import style from "./style.module.scss";
import { useParams } from "react-router-dom";
import ConfirmModal from "@local/components/confirm-modal";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "@local/legacy-utils/restrictions";
import { ROLES } from "@local/legacy-utils/constants";

// POST_REFACTORING_TODO: We should avoid duplicated office/role pairs, not mandatory, they are handled by backend

// TODO: fix the edit of regional office

const UsersManagement = ({
  createUser,
  createRegionalOffice,
  toggleUser,
  deleteRegionalOffice,
  userFormValues,
  officeFormValues,
  getUsers,
  getRegionalOffices,
  regionalOffices,
  isValidUserForm,
  isValidOfficeForm,
  officesChoices,
  officesFilter,
  getOffices,
  resetUserForm,
  resetOfficeForm,
  rolesFilter,
  rolesChoices,
  statuses,
  updateUser,
  updateRegionalOffice,
  getAgencyMemberReports,
  userId,
  tabs,
  countries,
  session,
  getRegionalOffice,
  changeOfficeField,
  createRegionalOfficeMember,
  getRegionalOfficeMember,
  deleteRegionalOfficeMember,
  officeId,
  userRole,
}) => {
  const [isUserManageModalVisible, setIsUserManageModalVisible] = useState(false);
  const [isOfficeManageModalVisible, setIsOfficeManageModalVisible] = useState(false);
  const [isOfficeDeleteModalVisible, setIsOfficeDeleteModalVisible] = useState(false);
  const [showDownloadInfo, setShowDownloadInfo] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState(null);
  const [filterParams, setFilterParams] = useState(null);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentOffice, setCurrentOffice] = useState(undefined);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { type, toAddUser } = useParams();

  useEffect(() => {
    getOffices();
    getRegionalOffices();
  }, [getOffices, getRegionalOffices]);

  useEffect(() => {
    if (toAddUser) {
      setIsUserManageModalVisible(true);
      setCurrentUser(adaptFormInitialValues(undefined, toAddUser, undefined));
    }
  }, [toAddUser]);

  const onClickAddUser = () => {
    setIsUserManageModalVisible(true);
    setCurrentUser(adaptFormInitialValues(undefined, undefined, undefined));
  };

  const onClickAddRegionalOffice = () => {
    setIsOfficeManageModalVisible(true);
    setCurrentOffice(adaptFormInitialValues(undefined, undefined, undefined));
  };

  const onSubmitManageUserModal = async (officeRoles, regionalOffice) => {
    setIsUserManageModalVisible(false);
    try {
      if (currentUser?.id && userRole === ROLES.AGENCY) {
        const response = await getRegionalOfficeMember(currentUser?.id);
        let regionalOfficeMember = response?.data?.regional_office ?? undefined;
        await updateUser(currentUser.id, adaptFormValuesToBeSent(userFormValues, officeRoles));
        if (regionalOfficeMember && userFormValues?.is_regional_office === "false") await deleteRegionalOfficeMember(currentUser?.id);
      } else {
        const user = await createUser(adaptFormValuesToBeSent(userFormValues, officeRoles));
        if (regionalOffice)
          await createRegionalOfficeMember({
            regional_office: regionalOffice,
            user: user?.data?.id,
          });
      }
      setCurrentUser(undefined);
      setShouldRefresh(true);
    } catch (error) {
      if (error.response.status === 404) {
        if (currentUser?.id) {
          await updateUser(currentUser.id, adaptFormValuesToBeSent(userFormValues, officeRoles));
          if (userFormValues?.is_regional_office === "true")
            await createRegionalOfficeMember({
              regional_office: regionalOffice,
              user: currentUser?.id,
            });
          setCurrentUser(undefined);
          setShouldRefresh(true);
        }
      } else toast.error(formatMessage({ id: "pages.users.management.idx.unableToSaveData" }));
    }
    resetUserForm();
  };

  const onSubmitManageOfficeModal = async () => {
    setIsOfficeManageModalVisible(false);
    try {
      if (currentOffice?.id) {
        await updateRegionalOffice(currentOffice.id, adaptOfficeFormValuesToBeSent(officeFormValues, countries));
      } else {
        await createRegionalOffice(adaptOfficeFormValuesToBeSent(officeFormValues, countries));
      }
      setCurrentOffice(undefined);
      setShouldRefresh(true);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.users.management.idx.unableToSaveData" }));
    }
    resetOfficeForm();
  };

  const onClickEditUser = async (item) => {
    try {
      setIsUserManageModalVisible(true);
      if (userRole === ROLES.AGENCY) {
        const response = await getRegionalOfficeMember(item?.id);
        let regionalOffice = response?.data?.regional_office ?? undefined;
        if (regionalOffice) setCurrentUser(adaptFormInitialValues(item, undefined, regionalOffice));
      } else {
        setCurrentUser(adaptFormInitialValues(item, undefined, undefined));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setCurrentUser(adaptFormInitialValues(item, undefined, undefined));
        }
      } else {
        toast.error(formatMessage({ id: "pages.users.management.idx.unableToSaveData" }));
      }
    }
  };

  const onClickEditRegionalOffice = (item) => {
    setIsOfficeManageModalVisible(true);
    setCurrentOffice(adaptOfficeFormInitialValues(item, undefined, countries));
  };

  const onCloseManageUserModal = () => {
    setIsUserManageModalVisible(false);
    setCurrentUser(undefined);
    resetUserForm();
  };

  const onCloseManageOfficeModal = () => {
    setIsOfficeManageModalVisible(false);
    setCurrentOffice(undefined);
    resetOfficeForm();
  };

  const onClickToggleUser = async (item, status) => {
    try {
      await toggleUser(item.id, status);
      if (status) {
        toast.success(formatMessage({ id: "pages.users.management.idx.activateUser" }));
      } else {
        toast.info(formatMessage({ id: "pages.users.management.idx.deactivateUser" }));
      }
      setShouldRefresh(true);
      setCurrentUser(undefined);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.users.management.idx.unableToSaveData" }));
    }
  };

  const onClickOpenDeleteRegionalOfficeModal = (item) => {
    setIsOfficeDeleteModalVisible(true);
    setCurrentOffice(adaptOfficeFormInitialValues(item, undefined, countries));
  };

  const onClickCloseDeleteRegionalOfficeModal = () => {
    setIsOfficeDeleteModalVisible(false);
    setCurrentOffice(undefined);
  };

  const onClickDeleteRegionalOffice = async () => {
    try {
      await deleteRegionalOffice(currentOffice?.id);
      setShouldRefresh(true);
      setCurrentOffice(undefined);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.users.management.idx.unableToSaveData" }));
    }
    setIsOfficeDeleteModalVisible(false);
  };

  const onClickDownload = async () => {
    const data = await getAgencyMemberReports({
      page: 1,
      page_size: 5,
      ...filterParams,
    });
    setShowDownloadInfo(true);
    data?.data?.length > 0 && setDownloadInfo(data?.data[0]);
    setFilterParams(null);
  };

  const onClickCloseDownloadInfo = () => {
    setShowDownloadInfo(false);
    setFilterParams(null);
  };

  const onChangeTableFilters = (filterValues) => {
    setFilterParams(filterValues);
  };

  const afterRefresh = () => {
    setShouldRefresh(false);
  };

  let content = undefined;

  switch (type) {
    case "users":
      content = (
        <>
          <Collapse in={showDownloadInfo}>
            <Alert
              type="success"
              content={
                <>
                  <span className={style.alertTitle}>
                    {formatMessage({
                      id: "pages.users.management.idx.downloadInfo",
                    })}
                  </span>
                  <span>{downloadInfo}</span>
                </>
              }
              actionLabel={formatMessage({
                id: "pages.users.management.idx.ok",
              })}
              onClickAction={onClickCloseDownloadInfo}
              withBottomMargin
            />
          </Collapse>
          <UsersList
            userId={userId}
            onClickAdd={onClickAddUser}
            onClickDownload={onClickDownload}
            fetchFunction={getUsers}
            columns={getUserColumns(onClickEditUser, onClickToggleUser, statuses, userId)}
            filters={getUserFilters(officesChoices, rolesFilter, statuses)}
            shouldRefresh={shouldRefresh}
            afterRefresh={afterRefresh}
            onChangeTableFilters={onChangeTableFilters}
            officeId={officeId}
            userRole={userRole}
          />
        </>
      );
      break;
    case "regional-offices":
      content = (
        <>
          {isSectionVisible(AGENCY_RESTRICTIONS.userManagement["regionalOffices"], session) && (
            <OfficesList
              onClickAdd={onClickAddRegionalOffice}
              fetchFunction={getRegionalOffices}
              columns={getOfficesColumns(countries, onClickEditRegionalOffice, onClickOpenDeleteRegionalOfficeModal)}
              filters={getOfficeFilters(officesFilter)}
              shouldRefresh={shouldRefresh}
              afterRefresh={afterRefresh}
              onChangeTableFilters={onChangeTableFilters}
            />
          )}
        </>
      );
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Container tabs={tabs} type={type}>
        {content}
      </Container>
      <UserManageModal
        isOpen={isUserManageModalVisible}
        onSubmit={onSubmitManageUserModal}
        onClose={onCloseManageUserModal}
        isValidForm={isValidUserForm}
        offices={officesChoices}
        roles={rolesChoices}
        formValues={userFormValues}
        initialValues={{ ...currentUser?.formValues }}
        stateValues={currentUser?.stateValues}
        inEditMode={currentUser?.id !== undefined}
        regionalOffices={regionalOffices}
        getRegionalOffice={getRegionalOffice}
        countries={countries}
        changeOfficeField={changeOfficeField}
        session={session}
        userRole={userRole}
      />
      <OfficeManageModal
        isOpen={isOfficeManageModalVisible}
        onSubmit={onSubmitManageOfficeModal}
        onClose={onCloseManageOfficeModal}
        isValidForm={isValidOfficeForm}
        offices={officesChoices}
        formValues={officeFormValues}
        initialValues={currentOffice?.formValues}
        stateValues={currentOffice?.stateValues}
        inEditMode={currentOffice?.id !== undefined}
      />
      <ConfirmModal
        isOpen={isOfficeDeleteModalVisible}
        onClose={onClickCloseDeleteRegionalOfficeModal}
        onSubmit={onClickDeleteRegionalOffice}
        title={formatMessage({ id: "pages.users.management.idx.delete" })}
        type="warning"
        message={
          <div>
            <Alert
              type="info"
              content={
                <span>
                  {formatMessage({
                    id: "pages.users.management.idx.deleteInfo",
                  })}
                  {currentOffice?.formValues?.name}
                  {formatMessage({
                    id: "pages.users.management.idx.deleteInfoExtra",
                  })}
                </span>
              }
              withBottomMargin
            />
          </div>
        }
      />
    </>
  );
};

UsersManagement.propTypes = {};

const mapStateToProps = (state) => ({
  userFormValues: state.form?.userModalForm?.values ?? {},
  isValidUserForm: isValid("userModalForm")(state),
  officeFormValues: state.form?.officeModalForm?.values ?? {},
  isValidOfficeForm: isValid("officeModalForm")(state),
  officesChoices: state?.officesList?.data?.data ? state?.officesList?.data?.data["user-offices-choices"] : [],
  officesFilter: state?.officesList?.data?.data ? state?.officesList?.data?.data["user-offices-filter"] : [],
  rolesFilter: adaptRoles(state.partnerProfileConfig["user-role-filter"] ?? {}),
  rolesChoices: adaptRoles(state.partnerProfileConfig["user-role-choices"] ?? {}),
  statuses: getUserStatuses(),
  userId: state?.session?.userId,
  tabs: usersNav(state)?.filter((item) => item.isVisible),
  countries: state.countries,
  regionalOffices: state?.getRegionalOfficesList?.data?.data?.results ?? [],
  session: state?.session,
  officeId: state?.session?.officeId,
  userRole: state?.session?.role,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (params) => dispatch(createUser(params)),
  createRegionalOffice: (params) => dispatch(createRegionalOffice(params)),
  toggleUser: (id, is_active) => dispatch(toggleUser({ is_active }, { id })),
  deleteRegionalOffice: (id) => dispatch(deleteRegionalOffice({ id })),
  getUsers: (params) => dispatch(getUsersList(params)),
  getOffices: (params) => dispatch(getOfficesList(params)),
  getRegionalOffices: (params) => dispatch(getRegionalOfficesList(params)),
  resetUserForm: () => dispatch(reset("userModalForm")),
  resetOfficeForm: () => dispatch(reset("officeModalForm")),
  updateUser: (id, params) => dispatch(updateUser(params, { id })),
  updateRegionalOffice: (id, params) => dispatch(updateRegionalOffice(params, { id })),
  getAgencyMemberReports: (params) => dispatch(getAgencyMemberReports(params)),
  getRegionalOffice: (id) => dispatch(getRegionalOffice(undefined, { id })),
  changeOfficeField: (name, value) => dispatch(change("userModalForm", name, value)),
  createRegionalOfficeMember: (body) => dispatch(createRegionalOfficeMember(body)),
  getRegionalOfficeMember: (id) => dispatch(getRegionalOfficeMember(undefined, { id })),
  deleteRegionalOfficeMember: (id) => dispatch(deleteRegionalOfficeMember({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersManagement);

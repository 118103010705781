import { ROLES } from "@local/legacy-utils/constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { PSEA_INITIAL_ASSESSMENT_DOCUMENT_TYPES } from "@local/utils/constants";
import { subtractYears } from "./adapters";
import { getBooleanValue } from "@local/utils";
import { isValid } from "date-fns";

export const getIsAssessedValue = (agencyFormValues, partnerFormValues, role) => {
  if (role === ROLES.AGENCY) {
    return getBooleanValue(agencyFormValues?.agency_is_assessed);
  } else {
    return getBooleanValue(partnerFormValues?.partner_is_assessed);
  }
};

export const validateAnswersForm = (formValues, documents, role) => {
  let beneficiaries = undefined;
  let rating = undefined;
  if (role === ROLES.AGENCY) {
    rating = formValues?.agency_rating ?? undefined;
    beneficiaries = formValues?.agency_beneficiaries ?? undefined;
  } else {
    rating = formValues?.partner_rating ?? undefined;
    beneficiaries = formValues?.partner_beneficiaries ?? undefined;
  }

  if ((role === ROLES.PARTNER && (formValues?.partner_is_assessed ?? undefined) === undefined) || (role === ROLES.AGENCY && (formValues?.agency_is_assessed ?? undefined) === undefined)) return false;
  if (beneficiaries === undefined) return false;
  if (beneficiaries === "false" && !formValues?.proceed_with_self_assessment && role === ROLES.PARTNER) return false;

  if (role === ROLES.PARTNER && formValues?.partner_is_assessed === "true") {
    if (!documents?.filter((item) => !item.is_agency)?.find((item) => item.doc_type === PSEA_INITIAL_ASSESSMENT_DOCUMENT_TYPES.ASSESSMENT_RESULTS)) return false;
    if (!formValues?.partner_un_agency_submitter || !formValues?.partner_submission_date || !rating) return false;
    if (!isValid(formValues?.partner_submission_date)) return false;
    if (formValues?.partner_submission_date && subtractYears(new Date(), 5) > formValues?.partner_submission_date) return false;
    if (formValues?.partner_submission_date && new Date() < formValues?.partner_submission_date) return false;
  } else if (role === ROLES.AGENCY && formValues?.agency_is_assessed === "true") {
    if (!documents?.filter((item) => item.is_agency)?.find((item) => item.doc_type === PSEA_INITIAL_ASSESSMENT_DOCUMENT_TYPES.ASSESSMENT_RESULTS)) return false;
    if (!formValues?.agency_un_agency_submitter || !formValues?.agency_submission_date || !rating) return false;
    if (!isValid(formValues?.agency_submission_date)) return false;
    if (formValues?.agency_submission_date && subtractYears(new Date(), 5) > formValues?.agency_submission_date) return false;
    if (formValues?.agency_submission_date && new Date() < formValues?.agency_submission_date) return false;
  }

  return true;
};

export const validatePartnerSelfAssessmentQuestions = (formValues, pseaSelfAssessment, isPartnerLocked) => {
  const result = [];
  if (!isPartnerLocked && !pseaSelfAssessment?.is_finalized_by_agency) {
    if (!formValues?.partner_CS1)
      result.push({
        coreStandard: "CS1",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.partner_CS2)
      result.push({
        coreStandard: "CS2",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.partner_CS3)
      result.push({
        coreStandard: "CS3",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.partner_CS4)
      result.push({
        coreStandard: "CS4",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.partner_CS5)
      result.push({
        coreStandard: "CS5",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.partner_CS6)
      result.push({
        coreStandard: "CS6",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.partner_CS7)
      result.push({
        coreStandard: "CS7",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.partner_CS8)
      result.push({
        coreStandard: "CS8",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    return result;
  }
};

export const validateAgencySelfAssessmentQuestions = (formValues, isAgencyLocked) => {
  const result = [];
  if (!isAgencyLocked) {
    if (!formValues?.agency_CS1)
      result.push({
        coreStandard: "CS1",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.agency_CS2)
      result.push({
        coreStandard: "CS2",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.agency_CS3)
      result.push({
        coreStandard: "CS3",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.agency_CS4)
      result.push({
        coreStandard: "CS4",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.agency_CS5)
      result.push({
        coreStandard: "CS5",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.agency_CS6)
      result.push({
        coreStandard: "CS6",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.agency_CS7)
      result.push({
        coreStandard: "CS7",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (!formValues?.agency_CS8)
      result.push({
        coreStandard: "CS8",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.required",
        }),
      });
    if (formValues?.agency_CS1 && (!formValues?.partner_CS1 ? false : formValues?.partner_CS1 !== formValues?.agency_CS1) && !formValues?.agency_CS1_comment)
      result.push({
        coreStandard: "CS1",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    if (formValues?.agency_CS2 && (!formValues?.partner_CS2 ? false : formValues?.partner_CS2 !== formValues?.agency_CS2) && !formValues?.agency_CS2_comment)
      result.push({
        coreStandard: "CS2",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    if (formValues?.agency_CS3 && (!formValues?.partner_CS3 ? false : formValues?.partner_CS3 !== formValues?.agency_CS3) && !formValues?.agency_CS3_comment)
      result.push({
        coreStandard: "CS3",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    if (formValues?.agency_CS4 && (!formValues?.partner_CS4 ? false : formValues?.partner_CS4 !== formValues?.agency_CS4) && !formValues?.agency_CS4_comment)
      result.push({
        coreStandard: "CS4",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    if (formValues?.agency_CS5 && (!formValues?.partner_CS5 ? false : formValues?.partner_CS5 !== formValues?.agency_CS5) && !formValues?.agency_CS5_comment)
      result.push({
        coreStandard: "CS5",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    if (formValues?.agency_CS6 && (!formValues?.partner_CS6 ? false : formValues?.partner_CS6 !== formValues?.agency_CS6) && !formValues?.agency_CS6_comment)
      result.push({
        coreStandard: "CS6",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    if (formValues?.agency_CS7 && (!formValues?.partner_CS7 ? false : formValues?.partner_CS7 !== formValues?.agency_CS7) && !formValues?.agency_CS7_comment)
      result.push({
        coreStandard: "CS7",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    if (formValues?.agency_CS8 && (!formValues?.partner_CS8 ? false : formValues?.partner_CS8 !== formValues?.agency_CS8) && !formValues?.agency_CS8_comment)
      result.push({
        coreStandard: "CS8",
        message: formatMessage({
          id: "pages.partner.details.cmp.psea.utils.validators.missingCommentErrorMessage",
        }),
      });
    return result;
  }
};

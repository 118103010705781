import React from "react";
import { connect } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldFile from "@local/components/field-file";
import { updateProjectApplication } from "@local/actions";
import style from "./style.module.scss";
import { formatDate } from "@local/utils/index";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";

const getForm = (hasPermissionToAnswerApplication, application, isCnRequired, onSubmit, formValues) => {
  if (!hasPermissionToAnswerApplication || !application) {
    return <></>;
  }
  if (application?.did_accept === false && application?.did_decline === true) {
    return (
      <div className={style.resultFormDeclinedMainWrapper}>
        <div className={style.resultFormTitleWrapper}>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.chosen",
            })}
          </span>
        </div>
        <div className={style.resultFormDeclinedWrapper}>
          <RemoveCircleIcon fontSize="small" style={{ color: "#DB5163" }} />
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.declined",
            })}
          </span>
        </div>
      </div>
    );
  }
  if (application?.did_accept === true && application?.did_decline === false) {
    return (
      <div className={style.resultFormConfirmedMainWrapper}>
        <div className={style.resultFormTitleWrapper}>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.chosen",
            })}
          </span>
        </div>
        <div className={style.resultFormConfirmedWrapper}>
          <CheckCircleIcon fontSize="small" style={{ color: "#64BA19" }} />
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.confirmed",
            })}
          </span>
        </div>
      </div>
    );
  }
  if (application?.did_accept === false && application?.did_decline === false) {
    return (
      <div className={style.resultFormInputMainWrapper}>
        <div className={style.resultFormTitleWrapper}>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.chosen",
            })}
          </span>
        </div>
        <div className={style.resultFormInputWrapper}>
          <FieldGroup>
            <Field
              component={FieldRadioGroup}
              id="confirmation"
              name="confirmation"
              label={formatMessage({
                id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.chosenQuestion",
              })}
              placeholder={formatMessage({
                id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.chosenQuestion",
              })}
              fullWidthLabel
              options={[
                {
                  value: true,
                  label: formatMessage({
                    id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.yes",
                  }),
                },
                {
                  value: false,
                  label: formatMessage({
                    id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.no",
                  }),
                },
              ]}
            />
            {isCnRequired && formValues?.confirmation?.toString() === "true" ? (
              <Field
                component={FieldFile}
                id="cn"
                name="cn"
                label={formatMessage({
                  id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.cn",
                })}
                placeholder={formatMessage({
                  id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.cn",
                })}
              />
            ) : (
              <></>
            )}
          </FieldGroup>
          <div className={style.resultFormActionWrapper}>
            <Button
              text={formatMessage({
                id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.button",
              })}
              onClick={onSubmit}
              endIcon={<SendIcon />}
              isDisabled={(isCnRequired && formValues?.confirmation?.toString() === "true" && !formValues?.cn?.id) || (formValues?.confirmation ?? undefined) === undefined}
            />
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

const PartnerResultForm = ({ hasPermissionToAnswerApplication, application, formValues, submitConfirmation, notifyDate, isCnRequired, refreshFunction }) => {
  const onSubmit = async () => {
    try {
      await submitConfirmation({
        did_accept: formValues?.confirmation?.toString() === "true",
        did_decline: formValues?.confirmation === "false",
        cn: isCnRequired && formValues?.confirmation?.toString() === "true" ? formValues?.cn?.id : undefined,
      });
      refreshFunction();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.reminderError",
        }),
      );
    }
  };

  return (
    <>
      <div className={style.notificationDateWrapper}>
        <span>
          {formatMessage({
            id: "pages.project.details.cmp.open.project.results.cmp.partner.result.form.idx.expected",
          })}
        </span>
        <span>{formatDate(notifyDate)}</span>
      </div>
      {getForm(hasPermissionToAnswerApplication, application, isCnRequired, onSubmit, formValues)}
    </>
  );
};

PartnerResultForm.propTypes = {};

const mapStateToProps = (state) => ({
  formValues: state?.form?.partnerResultForm?.values,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitConfirmation: (body) => dispatch(updateProjectApplication(body, { id: ownProps?.application?.id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "partnerResultForm", enableReinitialize: true })(PartnerResultForm));

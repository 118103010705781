import React, { useMemo } from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import { isRequired, isValidDate, isFutureDate } from "@local/utils/validators";
import FieldDatePicker from "@local/components/field-date-picker";

const EditTargetDateModal = ({ onCancel, onSubmit, isOpen, isFormValid, selectedActivity }) => {
  const isFutureDateValidator = useMemo(() => isFutureDate(), []);

  const onClickSubmit = () => {
    onSubmit(selectedActivity);
  };

  return (
    <Modal
      small
      isOpen={isOpen}
      onClose={onCancel}
      title={formatMessage({
        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.target.date.modal.idx.EditActivityTitle",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onCancel}
              variant="text"
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.target.date.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={!isFormValid}
              onClick={onClickSubmit}
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.target.date.modal.idx.button",
              })}
            />
          </div>
        </>
      }
    >
      <FieldGroup>
        <Field
          component={FieldDatePicker}
          validate={[isRequired, isValidDate, isFutureDateValidator]}
          id="target_date"
          name="target_date"
          minimumDate={new Date()}
          label={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.target.date.modal.idx.pseaCsipActivityTargetDate",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.target.date.modal.idx.pseaCsipActivityTargetDate",
          })}
        />
      </FieldGroup>
    </Modal>
  );
};

EditTargetDateModal.propTypes = {};

export default reduxForm({
  form: "editTargetDateForm",
  enableReinitialize: true,
})(EditTargetDateModal);

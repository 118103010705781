/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { useHistory } from "react-router-dom";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Card from "@local/components/card";
import Button from "@local/components/button";
import Modal from "@local/components/modal";
import Alert from "@local/components/alert";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ConceptNoteSubmissionForm from "./components/concept-note-submission-form";
import style from "./style.module.scss";

const ProjectSubmission = ({
  isHq,
  isProfileComplete,
  partnerId,
  isDeadlinePassed,
  hasUploadCnPermission,
  isConceptNoteUploaded,
  uploadConceptNote,
  deleteConceptNote,
  deadlineDate,
  submitDate,
  applicationId,
  conceptNoteSubmissionFormValues,
  partnerProjectApplication,
  resetConceptNoteSubmissionForm,
  updatedDate,
  loadProjectApplication,
  getPartnerProfileData,
  partnerProfile,
  countries,
  conceptNote,
}) => {
  const history = useHistory();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isProfileConfirmationChecked, setIsProfileConfirmationChecked] = useState(false);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  const onClickOpenDeleteConceptNoteModal = () => setIsDeleteModalVisible(true);

  const onClickCloseDeleteConceptNoteModal = () => setIsDeleteModalVisible(false);

  const onClickCheckProfileConfirmation = () => setIsProfileConfirmationChecked(!isProfileConfirmationChecked);

  const onClickOpenProfileModal = () => setIsProfileModalVisible(true);

  const onClickCloseProfileModal = () => setIsProfileModalVisible(false);

  useEffect(() => {
    loadProjectApplication();
    setIsProfileConfirmationChecked(partnerProjectApplication?.cn ? true : false);
  }, []);

  const onClickUploadConceptNote = async () => {
    await uploadConceptNote({ cn: conceptNoteSubmissionFormValues?.cn?.id });
    await loadProjectApplication();
  };

  const onClickDeleteConceptNote = async () => {
    await deleteConceptNote(applicationId);
    onClickCloseDeleteConceptNoteModal();
    resetConceptNoteSubmissionForm();
    setIsProfileConfirmationChecked(false);
    await loadProjectApplication();
  };

  const onClickViewProfile = async () => {
    await getPartnerProfileData(partnerId);
    onClickOpenProfileModal();
  };

  return (
    <Grid container spacing={2}>
      {isHq ? (
        <Grid item xs={12}>
          <Card>
            <span>
              {formatMessage({
                id: "pages.project.details.cmp.project.submission.idx.hq",
              })}
            </span>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
      {!isProfileComplete ? (
        <Grid item xs={12}>
          <Card>
            <div className={style.contentWrapper}>
              <span className={style.info}>
                {formatMessage({
                  id: "pages.project.details.cmp.project.submission.idx.incomplete",
                })}
              </span>
              <div className={style.button}>
                <Button
                  type="text"
                  text={formatMessage({
                    id: "pages.project.details.cmp.project.submission.idx.completeProfile",
                  })}
                  variant="text"
                  onClick={() => history.push(`/profile/${partnerId}/edit/`)}
                />
              </div>
            </div>
          </Card>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card
            title={
              <div className={style.cardHeader}>
                <span className={style.cardTitle}>
                  {formatMessage({
                    id: "pages.project.details.cmp.project.submission.idx.title",
                  })}
                </span>
                {isConceptNoteUploaded && hasUploadCnPermission && !isDeadlinePassed ? (
                  <IconButton onClick={onClickOpenDeleteConceptNoteModal}>
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </div>
            }
          >
            <ConceptNoteSubmissionForm
              hasUploadCnPermission={hasUploadCnPermission}
              deadlineDate={deadlineDate}
              isConceptNoteUploaded={isConceptNoteUploaded}
              submitDate={submitDate}
              onClickUploadConceptNote={onClickUploadConceptNote}
              updatedDate={updatedDate}
              initialValues={partnerProjectApplication}
              history={history}
              partnerId={partnerId}
              onClickViewProfile={onClickViewProfile}
              onClickCheckProfileConfirmation={onClickCheckProfileConfirmation}
              isProfileConfirmationChecked={isProfileConfirmationChecked}
              isProfileModalVisible={isProfileModalVisible}
              onClickCloseProfileModal={onClickCloseProfileModal}
              partnerProfile={partnerProfile}
              countries={countries}
              conceptNote={conceptNote}
            />
          </Card>
        </Grid>
      )}

      <Modal
        isOpen={isDeleteModalVisible}
        onClose={onClickCloseDeleteConceptNoteModal}
        title={formatMessage({
          id: "pages.project.details.cmp.project.submission.idx.confirmQuestion",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseDeleteConceptNoteModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.project.submission.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onClickDeleteConceptNote}
                text={formatMessage({
                  id: "pages.project.details.cmp.project.submission.idx.confirm",
                })}
              />
            </div>
          </>
        }
        small
      >
        <>
          <Alert
            type="warning"
            content={formatMessage({
              id: "pages.project.details.cmp.project.submission.idx.confirmMessage",
            })}
            withBottomMargin
          />
        </>
      </Modal>
    </Grid>
  );
};

ProjectSubmission.propTypes = {};

const selector = formValueSelector("conceptNoteSubmissionForm");

const mapStateToProps = (state) => ({
  conceptNote: selector(state, "cn"),
});

export default connect(mapStateToProps)(ProjectSubmission);

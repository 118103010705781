import { projectStatusColor } from "@local/styles/muiTheme";

const reviewerStatus = {
  marked_reviews_completed: "Reviews Completed",
  finished_reviews: "Finished Scoring",
  reviews_in_progress: "Scoring in Progress",
  reviews_not_started: "Scoring not Started",
};

export const getStatusColor = (status) => projectStatusColor[status];

export const getReviewerTextStatus = (status) => reviewerStatus[status];

import React from "react";
import Card from "@local/components/card";
import Alert from "@local/components/alert";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import { getColorByHighlightType } from "@local/utils";

export const WrapperComponent = ({ inEditMode, title, footer, children }) => {
  const basicColor = getColorByHighlightType("basic");
  const advancedColor = getColorByHighlightType("advanced");

  return inEditMode ? (
    <Card title={title} footer={footer}>
      <>
        <Alert
          type="info"
          content={
            <>
              <span className={style.title}>
                {formatMessage({
                  id: "pages.partner.details.cmp.profile.details.utils.idx.hintInfo",
                })}
              </span>
              <div className={style.itemWrapper}>
                <span
                  className={style.fieldsBullet}
                  style={{
                    backgroundColor: `${basicColor}33`,
                    borderColor: basicColor,
                  }}
                />
                <span className={style.fieldsHintLabel}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.profile.details.utils.idx.hintInfoBasicFields",
                  })}
                </span>
              </div>
              <div className={style.itemWrapper}>
                <span
                  className={style.fieldsBullet}
                  style={{
                    backgroundColor: `${advancedColor}33`,
                    borderColor: advancedColor,
                  }}
                />
                <span className={style.fieldsHintLabel}>
                  {formatMessage({
                    id: "pages.partner.details.cmp.profile.details.utils.idx.hintInfoAdvancedFields",
                  })}
                </span>
              </div>
            </>
          }
          withBottomMargin
        />
        {children}
      </>
    </Card>
  ) : (
    <>
      <Alert
        type="info"
        content={
          <>
            <span className={style.title}>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.utils.idx.hintInfo",
              })}
            </span>
            <div className={style.itemWrapper}>
              <span
                className={style.fieldsBullet}
                style={{
                  backgroundColor: `${basicColor}33`,
                  borderColor: basicColor,
                }}
              />
              <span className={style.fieldsHintLabel}>
                {formatMessage({
                  id: "pages.partner.details.cmp.profile.details.utils.idx.hintInfoBasicFields",
                })}
              </span>
            </div>
            <div className={style.itemWrapper}>
              <span
                className={style.fieldsBullet}
                style={{
                  backgroundColor: `${advancedColor}33`,
                  borderColor: advancedColor,
                }}
              />
              <span className={style.fieldsHintLabel}>
                {formatMessage({
                  id: "pages.partner.details.cmp.profile.details.utils.idx.hintInfoAdvancedFields",
                })}
              </span>
            </div>
          </>
        }
        withBottomMargin
      />
      {children}
    </>
  );
};

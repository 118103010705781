import React from "react";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";

const SelectedPartnerJustification = ({ project }) => (
  <Card
    title={formatMessage({
      id: "pages.project.details.cmp.overview.cmp.selected.partner.justification.idx.justificationSummary",
    })}
  >
    <div>
      <span className={style.info}>{project?.justification}</span>
    </div>
  </Card>
);

SelectedPartnerJustification.propTypes = {};

export default SelectedPartnerJustification;

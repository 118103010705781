import React, { useCallback, useState } from "react";
import Table from "@local/components/table";

import i18n from "@local/i18next/i18n";
import Modal from "@local/components/modal";
import PartnerForm from "./components/PartnerForm";
import style from "./style.module.scss";

import { adminPartnerCreateUser, adminPartnerMemberUpdateUser, getPartnerMembersList, getRegionalOffice, adminUpdateUser, assignPartnerUserAdmin } from "@local/actions";
import { connect } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import { getUserStatuses, partnerModalNav } from "@local/utils/constants";
import { getPartnerUsersColumns } from "../../utils/columns";
import Loader from "@local/components/loader";
import Button from "@local/components/button";
import AdminPanelUserManageModal from "./components/user-manage-modal";
import { isValid, reset } from "redux-form";
import { adaptRoles } from "@local/utils/adapters";
import { toast } from "react-toastify";
import { adaptFormInitialValues } from "../../utils/adapters";
import NewUserStepper from "./components/user-manage-modal/NewUserStepper";

const AdminPartners = ({
  tabs,
  getPartnerMembers,
  fetchFunction,
  columns,
  filters,
  adapterFunction,
  message,
  isOpen,
  onClose,
  onChangeTableFilters,
  selectedPartner,
  setShouldRefresh,
  shouldRefresh,
  adminPartnercreateUser,
  userFormValues,
  isValidUserForm,
  officesChoices,
  resetUserForm,
  rolesChoices,
  countries,
  statuses,
  toggleUser,
  adminPartnerMemberUpdateUser,
  newUserFormValues,
  assignPartnerUserAdmin,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserManageModalVisible, setIsUserManageModalVisible] = useState(false);
  const [isUserManageEditModalVisible, setIsUserManageEditModalVisible] = useState(false);
  const [shouldRefreshUsersList, setShouldRefreshUsersList] = useState(false);

  const [currentUser, setCurrentUser] = useState(undefined);
  // Handle change of tab
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onClickToggleUser = async (row, is_active) => {
    try {
      await toggleUser(row.user.id, is_active);
      toast.success("User deactivated");
      setShouldRefreshUsersList(true);
    } catch (e) {
      toast.error("Unable to deactivate user");
    }
  };

  const onClickAddUser = () => {
    setIsUserManageModalVisible(true);
    setCurrentUser(adaptFormInitialValues(undefined, undefined, undefined));
  };

  const onClickEditUser = (row) => {
    setSelectedUser(row);
    setIsUserManageEditModalVisible(true);
    setCurrentUser(adaptFormInitialValues(row, undefined, undefined));
  };

  const onSubmitEditUserModal = useCallback(async () => {
    try {
      await adminPartnerMemberUpdateUser({ role: userFormValues.role }, selectedUser.id);
      toast.success("User updated successfully");
      setCurrentUser(undefined);
      setSelectedUser(null);
      setIsUserManageEditModalVisible(false);
      setShouldRefreshUsersList(true);
      resetUserForm();
    } catch (error) {
      toast.error("Unable to update user");
    }
  }, [userFormValues, adminPartnerMemberUpdateUser, resetUserForm, setCurrentUser, setSelectedUser, setShouldRefreshUsersList]);

  const onSubmitManageUserModal = useCallback(async () => {
    try {
      await adminPartnercreateUser(newUserFormValues);
      toast.success("User created successfully");
      setCurrentUser(undefined);
      setIsUserManageModalVisible(false);
      setShouldRefreshUsersList(true);
      resetUserForm();
    } catch (error) {
      if (error?.response?.data.details) {
        toast.error(error?.response?.data.details);
      } else {
        toast.error("Unable to create user");
      }
    }
  }, [newUserFormValues, adminPartnercreateUser, resetUserForm, setIsUserManageModalVisible, setCurrentUser, setShouldRefreshUsersList]);

  const onSubmitAssignUserAdmin = useCallback(
    async (user) => {
      try {
        await assignPartnerUserAdmin({ user: user.id, partner: selectedPartner.id, role: newUserFormValues.role });
        toast.success("User assigned successfully to the partner");
        setCurrentUser(undefined);
        setIsUserManageModalVisible(false);
        setShouldRefreshUsersList(true);
        resetUserForm();
      } catch (error) {
        if (error?.response?.data?.non_field_errors?.[0] === "Another record with the same attributes already exists.") {
          toast.error("User with the same email is already assigned to the partner");
        } else {
          toast.error("Unable to create user");
        }
      }
    },
    [newUserFormValues, assignPartnerUserAdmin, resetUserForm, setIsUserManageModalVisible, setCurrentUser, setShouldRefreshUsersList],
  );

  const onCloseManageEditUserModal = () => {
    setIsUserManageEditModalVisible(false);
    setCurrentUser(undefined);
    setSelectedUser(null);
    resetUserForm();
  };

  const onCloseManageUserModal = () => {
    setIsUserManageModalVisible(false);
    setCurrentUser(undefined);
    setSelectedUser(null);
    resetUserForm();
  };

  const afterRefreshList = () => {
    setShouldRefreshUsersList(false);
  };

  let content;

  switch (activeTab) {
    case 0:
      content = <PartnerForm initialValues={selectedPartner} setShouldRefresh={setShouldRefresh} onClose={onClose} />;
      break;
    case 1:
      content = (
        <div className={style.tableWrapper}>
          <Table
            key="user_partner_list"
            id="user_partner_list"
            title={"Users"}
            columns={getPartnerUsersColumns(statuses, onClickToggleUser, onClickEditUser)}
            shouldRefresh={shouldRefreshUsersList}
            afterRefresh={afterRefreshList}
            fetchFunction={getPartnerMembers}
            adapterFunction={adapterFunction}
            onChangeFilters={onChangeTableFilters}
            keyColumn="id"
            actions={<Button onClick={onClickAddUser} text="New User" />}
          />
        </div>
      );
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Table
        key="admin_partner_list"
        id="admin_partner_list"
        title={"Partners"}
        columns={columns}
        shouldRefresh={shouldRefresh}
        afterRefresh={() => setShouldRefresh(false)}
        fetchFunction={fetchFunction}
        filters={filters}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        withUrlSynchronization
        keyColumn="id"
      />
      <Modal
        isOpen={isOpen}
        message={message}
        title={i18n.t("Partner")}
        onClose={() => {
          setActiveTab(0);
          onClose();
        }}
      >
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontWeight: "bold" }}>{i18n.t("Organization Legal Name")}:</span> {selectedPartner?.legal_name}
        </div>
        <Tabs value={activeTab} onChange={handleChangeTab} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile>
          {tabs.map((tab, index) => (
            <Tab key={tab.id} value={index} label={tab.label} disabled={tab.isDisabled} />
          ))}
        </Tabs>
        <div className={style.modal}>
          {selectedPartner ? (
            content
          ) : (
            <div className={style.loaderWrapper}>
              <Loader isVisible />
            </div>
          )}
        </div>
      </Modal>
      <NewUserStepper isOpen={isUserManageModalVisible} onSubmit={onSubmitManageUserModal} onUpdateSubmit={onSubmitAssignUserAdmin} onClose={onCloseManageUserModal} roles={rolesChoices} />
      <AdminPanelUserManageModal
        isOpen={isUserManageEditModalVisible}
        onSubmit={onSubmitEditUserModal}
        onClose={onCloseManageEditUserModal}
        isValidForm={isValidUserForm}
        offices={officesChoices}
        roles={rolesChoices}
        formValues={userFormValues}
        initialValues={selectedUser ? { role: selectedUser?.role, fullname: selectedUser?.user?.fullname, email: selectedUser?.user?.email } : {}}
        stateValues={currentUser?.stateValues}
        inEditMode={selectedUser ? true : false}
        countries={countries}
      />
    </>
  );
};

AdminPartners.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    tabs: partnerModalNav(),
    selectedPartner: state?.partnersList?.data?.data?.results?.find((result) => result?.id === ownProps?.selectedPartner?.id),
    newUserFormValues: state.form?.newUserModalForm?.values ?? {},
    userFormValues: state.form?.userModalForm?.values ?? {},
    isValidUserForm: isValid("userModalForm")(state),
    rolesFilter: adaptRoles(state.partnerProfileConfig["user-role-filter"] ?? {}),
    rolesChoices: adaptRoles(state.partnerProfileConfig["admin-partner-role-choices"] ?? {}),
    statuses: getUserStatuses(),
    userId: state?.session?.userId,
    countries: state.countries,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  adminPartnercreateUser: async (params) => dispatch(adminPartnerCreateUser(params, { partnerId: ownProps?.selectedPartner?.id })),
  toggleUser: (id, is_active) => dispatch(adminUpdateUser({ is_active }, { id })),
  adminPartnerMemberUpdateUser: (params, partnerMemberId) => dispatch(adminPartnerMemberUpdateUser(params, { id: partnerMemberId })),
  resetUserForm: () => dispatch(reset("userModalForm")),
  getRegionalOffice: (id) => dispatch(getRegionalOffice(undefined, { id })),
  getPartnerMembers: (params) => dispatch(getPartnerMembersList(params, { id: ownProps?.selectedPartner?.id })),
  assignPartnerUserAdmin: (params) => dispatch(assignPartnerUserAdmin(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPartners);

import React from "react";
import MUICard from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

const Card = ({ title, children, footer, action }) => {
  return (
    <MUICard>
      {title ? <CardHeader title={title} action={action} /> : undefined}
      <CardContent>{children}</CardContent>
      {footer ? <CardActions>{footer}</CardActions> : undefined}
    </MUICard>
  );
};

Card.propTypes = {};

export default Card;

import * as R from "ramda";
import { SESSION_READY } from "./session";
import { PARTNER, AGENCY, filterItems } from "./nav";
import { PROJECT_TYPES, DETAILS_ITEMS } from "../legacy-utils/constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";

const initialState = [
  {
    id: 1,
    path: DETAILS_ITEMS.OVERVIEW,
    label: formatMessage({ id: "legacy.reducers.cfeiDetailsNav.Overview" }),
    roles: [AGENCY, PARTNER],
    types: [PROJECT_TYPES.OPEN, PROJECT_TYPES.DIRECT, PROJECT_TYPES.UNSOLICITED],
  },
  {
    id: 2,
    path: DETAILS_ITEMS.FEEDBACK,
    label: formatMessage({ id: "legacy.reducers.cfeiDetailsNav.Feedback" }),
    roles: [AGENCY],
    types: [PROJECT_TYPES.DIRECT, PROJECT_TYPES.UNSOLICITED],
  },
  {
    id: 3,
    path: DETAILS_ITEMS.FEEDBACK,
    label: formatMessage({ id: "legacy.reducers.cfeiDetailsNav.Feedback" }),
    roles: [PARTNER],
    types: [PROJECT_TYPES.UNSOLICITED],
  },
  {
    id: 4,
    path: DETAILS_ITEMS.SUBMISSION,
    label: formatMessage({
      id: "legacy.reducers.cfeiDetailsNav.conceptNoteSubmission",
    }),
    roles: [PARTNER],
    types: [PROJECT_TYPES.OPEN],
  },
  {
    id: 5,
    path: DETAILS_ITEMS.REQUESTS,
    label: formatMessage({
      id: "legacy.reducers.cfeiDetailsNav.additionalInformation",
    }),
    roles: [AGENCY],
    types: [PROJECT_TYPES.OPEN],
  },
  {
    id: 6,
    path: DETAILS_ITEMS.APPLICATIONS,
    label: formatMessage({ id: "legacy.reducers.cfeiDetailsNav.applications" }),
    roles: [AGENCY],
    types: [PROJECT_TYPES.OPEN],
  },
  {
    id: 7,
    path: DETAILS_ITEMS.PRESELECTED,
    label: formatMessage({ id: "legacy.reducers.cfeiDetailsNav.shortlist" }),
    roles: [AGENCY],
    types: [PROJECT_TYPES.OPEN],
  },
  {
    id: 8,
    path: DETAILS_ITEMS.RESULTS,
    label: formatMessage({ id: "legacy.reducers.cfeiDetailsNav.results" }),
    roles: [PARTNER, AGENCY],
    types: [PROJECT_TYPES.OPEN],
  },
  {
    id: 9,
    path: DETAILS_ITEMS.RESPONSE,
    label: formatMessage({ id: "legacy.reducers.cfeiDetailsNav.response" }),
    roles: [PARTNER],
    types: [PROJECT_TYPES.DIRECT],
  },
];

export const selectItemsByType = (state, type) => R.filter((item) => R.any(R.equals(type), item.types), state);

export default function cfeiNavReducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_READY: {
      return filterItems(initialState, action.role);
    }
    default:
      return state;
  }
}

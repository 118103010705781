import React from "react";
import Main from "./components/main";
import { connect } from "react-redux";

const Demo = ({ formValues }) => {
  const onClickSubmit = () => console.info(formValues);
  return <Main onClickSubmit={onClickSubmit} />;
};

Demo.propTypes = {};

const mapStateToProps = (state) => ({
  formValues: state.form?.demoForm?.values ?? {},
});

export default connect(mapStateToProps, undefined)(Demo);

import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import style from "./style.module.scss";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { getColorByHighlightType } from "@local/utils";

const FieldText = ({ id, label, placeholder, helperText, type, small, maxLength, withCharactersCount, multiline, maxRows, rows, defaultValue, readOnly, input: { value, onChange }, meta, informationText, highlightType }) => {
  const color = getColorByHighlightType(highlightType);

  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <TextField
          sx={{ backgroundColor: `${color}29` }}
          error={(meta?.error ?? "") !== ""}
          id={id}
          value={value ?? ""}
          onChange={onChange}
          label={label}
          placeholder={placeholder}
          size={small ? "small" : "normal"}
          multiline={multiline}
          maxRows={maxRows}
          rows={rows}
          defaultValue={defaultValue}
          disabled={readOnly}
          inputProps={{
            type,
            maxLength,
          }}
          InputProps={{
            startAdornment: informationText ? (
              <div className={style.tooltipWrapper}>
                <Tooltip title={informationText}>
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
            ) : undefined,
          }}
        />
        <div className={style.footerWrapper}>
          <div>
            {helperText && !meta?.error ? <FormHelperText id={`field-text-helper-text-${id}`}>{helperText}</FormHelperText> : <></>}
            {meta?.error ? (
              <FormHelperText error id={`field-text-helper-text-${id}`}>
                {meta?.error}
              </FormHelperText>
            ) : (
              <></>
            )}
          </div>
          <div>
            {withCharactersCount ? (
              <FormHelperText className={style.count} id={`field-text-helper-text-${id}`}>
                {value?.length ?? 0}/{maxLength ?? 0}
              </FormHelperText>
            ) : (
              <></>
            )}
          </div>
        </div>
      </FormControl>
    </div>
  );
};

FieldText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.string,
  small: PropTypes.bool,
};

FieldText.defaultProps = {
  placeholder: undefined,
  helperText: undefined,
  type: "text",
  small: false,
};

export default FieldText;

import CfeiIcon from "@mui/icons-material/Language";
import ApplicationsIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import PartnersIcon from "@mui/icons-material/Group";
import ShellProfilesIcon from "@mui/icons-material/AccountBox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SubscriptionsIcon from "@mui/icons-material/RssFeed";
import HelpIcon from "@mui/icons-material/Help";
import BookIcon from "@mui/icons-material/Book";
import GradeIcon from "@mui/icons-material/Grade";
import { SESSION_READY } from "./session";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import IpAgreementIcon from "@mui/icons-material/AccountTree";

export const PARTNER = "partner";
export const AGENCY = "agency";
export const MANAGE_SHELL_PROFILES = "MANAGE_SHELL_PROFILES";
export const REFRESH_NAV_ITEMS = "REFRESH_NAV_ITEMS";

const getInitialState = () => [
  {
    path: "/dashboard",
    label: formatMessage({ id: "legacy.reducers.nav.dashboard" }),
    roles: [PARTNER, AGENCY],
    icon: <DashboardIcon fontSize="small" />,
    name: "dashboard",
    external: false,
  },
  {
    path: "/cfei",
    label: formatMessage({ id: "legacy.reducers.nav.cfei" }),
    roles: [PARTNER, AGENCY],
    icon: <CfeiIcon fontSize="small" />,
    name: "partnershipOpportunities",
    external: false,
  },
  {
    path: "/partner",
    label: formatMessage({ id: "legacy.reducers.nav.partner" }),
    roles: [AGENCY],
    icon: <PartnersIcon fontSize="small" />,
    name: "partners",
    external: false,
  },
  {
    path: "/shell-profile",
    label: formatMessage({ id: "legacy.reducers.nav.shellProfile" }),
    roles: [AGENCY],
    icon: <ShellProfilesIcon fontSize="small" />,
    name: "invitedNGOPartners",
    external: false,
  },
  {
    path: "/applications",
    label: formatMessage({ id: "legacy.reducers.nav.applications" }),
    roles: [PARTNER],
    icon: <ApplicationsIcon fontSize="small" />,
    name: "applications",
    external: false,
  },
  {
    path: "/profile",
    label: formatMessage({ id: "legacy.reducers.nav.profile" }),
    roles: [PARTNER],
    icon: <SettingsIcon fontSize="small" />,
    name: "profile",
    external: false,
  },
  {
    path: "/reports",
    label: formatMessage({ id: "legacy.reducers.nav.reports" }),
    roles: [AGENCY],
    icon: <ApplicationsIcon fontSize="small" />,
    name: "reports",
    external: false,
  },
  {
    path: "/notifications-list",
    label: formatMessage({ id: "legacy.reducers.nav.notifications" }),
    roles: [PARTNER, AGENCY],
    icon: <NotificationsIcon fontSize="small" />,
    name: "notificationsList",
    external: false,
  },
  {
    path: "/subscriptions",
    label: formatMessage({ id: "legacy.reducers.nav.subscriptions" }),
    roles: [AGENCY, PARTNER],
    icon: <SubscriptionsIcon fontSize="small" />,
    name: "subscriptions",
    external: false,
  },
  {
    path: "/faq",
    label: formatMessage({ id: "legacy.reducers.nav.faq" }),
    roles: [PARTNER, AGENCY],
    icon: <HelpIcon fontSize="small" />,
    name: "faq",
    external: false,
  },
  {
    path: "/ip-agreement",
    label: formatMessage({ id: "legacy.reducers.nav.ipAgreement" }),
    roles: [AGENCY],
    icon: <IpAgreementIcon fontSize="small" />,
    name: "ipAgreement",
    external: false,
  },
  {
    path: "https://unpartnerportalcso.zendesk.com/hc/en-us",
    label: formatMessage({ id: "legacy.reducers.nav.httpsCsoResource" }),
    roles: [PARTNER],
    icon: <BookIcon fontSize="small" />,
    name: "resourceLibraryCSO",
    external: true,
  },
  {
    path: "https://unpartnerportalhelpcenter.zendesk.com/hc/en-us",
    label: formatMessage({ id: "legacy.reducers.nav.httpsHelpCenter" }),
    roles: [AGENCY],
    icon: <BookIcon fontSize="small" />,
    name: "resourceLibraryUN",
    external: true,
  },
  {
    path: "https://learning.unpartnerportal.org/",
    label: formatMessage({ id: "legacy.reducers.nav.training" }),
    roles: [PARTNER, AGENCY],
    icon: <GradeIcon fontSize="small" />,
    name: "learning",
    external: true,
  },
];

export const filterItems = (state, role) => state.filter((item) => item.roles.includes(role));

export const refreshNavItems = () => (dispatch, getState) => {
  dispatch({
    type: REFRESH_NAV_ITEMS,
    role: getState().session.role,
  });
};

export default function navReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SESSION_READY: {
      return filterItems(getInitialState(), action.role);
    }
    case REFRESH_NAV_ITEMS: {
      return filterItems(getInitialState(), action.role);
    }
    default:
      return state;
  }
}

import { requestDelete } from "@local/utils/network";

const actionStart = (resource) => ({
  type: `${resource}_START`,
});

const actionEnd = (resource, data) => ({
  type: `${resource}_END`,
  data,
});

const actionError = (resource, errorData) => ({
  type: `${resource}_ERROR`,
  errorData,
});

const destroy =
  (resource, url, raiseException = true) =>
  (urlParams = {}) =>
  (dispatch) => {
    let withParamsUrl = url;
    Object.keys(urlParams).forEach((key) => {
      withParamsUrl = withParamsUrl.replaceAll(`:${key}`, urlParams[key]);
    });
    dispatch(actionStart(resource));
    return requestDelete({ url: withParamsUrl })
      .then((response) => {
        dispatch(actionEnd(resource, response));
        return response;
      })
      .catch((error) => {
        dispatch(actionError(resource, error));
        if (raiseException) throw error;
      });
  };

export default destroy;

import React from "react";
import * as R from "ramda";
import { combineReducers } from "redux";
import { getPartnersList } from "../legacy-utils/api";
import { sendRequest } from "../legacy-utils/apiHelper";
import apiMeta, { success } from "./apiMeta";
import FormattedMessage from "../legacy-utils/translator";

const savePartners = (state, action) => {
  const partners = R.assoc("partners", action.results, state);
  return R.assoc("totalCount", action.count, partners);
};

const errorMsg = "Load partners failed.";
const AGENCY_PARTNERS_LIST = "AGENCY_PARTNERS_LIST";
const tag = "agencyPartnersList";

const initialState = {
  columns: [
    {
      name: "name",
      title: <FormattedMessage id="reducers.agencyPartnersList.name" />,
    },
    {
      name: "acronym",
      title: <FormattedMessage id="reducers.agencyPartnersList.acronym" />,
      width: 100,
    },
    {
      name: "display_type",
      title: <FormattedMessage id="reducers.agencyPartnersList.displayType" />,
    },
    {
      name: "country_code",
      title: <FormattedMessage id="reducers.agencyPartnersList.countryCode" />,
    },
    {
      name: "experience_working",
      title: <FormattedMessage id="reducers.agencyPartnersList.experienceWorking" />,
    },
  ],
  partners: [],
  totalCount: 0,
};

export const loadPartnersList = (params) =>
  sendRequest({
    loadFunction: getPartnersList,
    meta: {
      reducerTag: tag,
      actionTag: AGENCY_PARTNERS_LIST,
      isPaginated: true,
    },
    errorHandling: { userMessage: errorMsg },
    apiParams: [params],
  });

function agencyPartnersListReducer(state = initialState, action) {
  switch (action.type) {
    case success`${AGENCY_PARTNERS_LIST}`: {
      return savePartners(state, action);
    }
    default:
      return state;
  }
}

export default combineReducers({
  data: agencyPartnersListReducer,
  status: apiMeta(AGENCY_PARTNERS_LIST),
});

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import MainContent from "@local/components/main-content-wrapper";
import Alert from "@local/components/alert";
import style from "./style.module.scss";

const Container = ({ isRestrictedPath, tabs, hasPermission, children, type }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isRestrictedPath || !hasPermission) history.push("/admin");
  }, []);

  const handleChange = (event, index) => {
    history.push({
      pathname: `/admin/${tabs[index].path}`,
      query: location.query,
    });
  };

  const index = tabs.findIndex((tab) => tab.path === type);
  if (index === -1) history.push("/");

  const filteredTabs = tabs.filter((tab) => tab.isVisible);

  if (hasPermission) {
    return (
      <HeaderNavigation index={index} title={"Admin panel"} tabs={filteredTabs} handleChange={handleChange}>
        <MainContent>{index !== -1 ? children : undefined}</MainContent>
      </HeaderNavigation>
    );
  }

  return <></>;
};

Container.propTypes = {};

export default Container;

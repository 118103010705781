import { createPartnerDuplicates } from "@local/actions";
import Button from "@local/components/button";
import ConfirmModal from "@local/components/confirm-modal";
import FieldText from "@local/components/field-text";
import i18n from "@local/i18next/i18n";
import { isDecimalNumeric, isNumeric, isRequired, isRequiredAndNotEmptyString } from "@local/utils/validators";
import { Grid } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";

const MatchPartnersForm = ({ formValues, partnerMatchingUpdate, setShouldRefresh, onClose }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const isButtonDisabled = (formValues) => {
    if (
      formValues?.partner === undefined ||
      formValues?.duplicate_partner === undefined ||
      formValues?.partner === formValues?.duplicate_partner ||
      formValues?.partner === "" ||
      formValues?.duplicate_partner.trim() === "" ||
      formValues?.partner.trim() === "" ||
      isNumeric(formValues?.duplicate_partner) ||
      isNumeric(formValues?.partner)
    ) {
      return true;
    }
  };

  const showError = (formValues) => {
    if (formValues?.partner !== undefined || formValues?.duplicate_partner !== undefined) {
      if (formValues?.partner === formValues?.duplicate_partner) {
        return i18n.t("Partner 1 and Partner 2 cannot be the same");
      }
    }
  };

  const showConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };
  const hideConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const onSubmit = async () => {
    try {
      const createdMatching = await partnerMatchingUpdate(formValues);
      onClose(createdMatching.data, () => {
        toast.success(i18n.t("Partner matching created successfully"));
        setShouldRefresh(true);
      });
    } catch (e) {
      if (e?.response?.data?.non_field_errors?.[0]) {
        if (e?.response?.data?.non_field_errors?.[0] === "Another record with the same attributes already exists.") {
          toast.error(i18n.t("This match between Partner 1 ID and Partner 2 ID has already been reported."));
        } else {
          toast.error(e?.response?.data?.non_field_errors?.[0]);
        }
      } else {
        toast.error(i18n.t("Failed to create partner matching"));
      }
      hideConfirmModal();
    }
  };
  return (
    <div style={{ padding: "1rem" }}>
      <div style={{ marginBottom: "0.3rem" }}>Please provide the partner id's that you want to report a duplicate</div>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Field label={i18n.t("Partner 1 ID")} validate={[isRequiredAndNotEmptyString, isNumeric, isDecimalNumeric]} component={FieldText} id="partner" name="partner" />
            </Grid>
            <Grid item xs={6}>
              <Field label={i18n.t("Partner 2 ID")} validate={[isRequiredAndNotEmptyString, isNumeric, isDecimalNumeric]} component={FieldText} id="duplicate_partner" name="duplicate_partner" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ color: "#d32f2f", marginTop: "0.5rem" }}>{showError(formValues)}</div>
      <div style={{ float: "inline-end", marginBottom: "0.5rem", marginTop: "1rem" }}>
        <Button isDisabled={isButtonDisabled(formValues)} text="Match" onClick={showConfirmModal} />
      </div>
      <ConfirmModal title={i18n.t("Do you confirm that partners are duplicates?")} isOpen={isConfirmModalOpen} onSubmit={onSubmit} onClose={hideConfirmModal} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: state?.form?.matchingPartnersForm?.values,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  partnerMatchingUpdate: (body) => dispatch(createPartnerDuplicates({ partner: parseInt(body.partner), duplicate_partner: parseInt(body.duplicate_partner) })),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "matchingPartnersForm", enableReinitialize: true })(MatchPartnersForm));

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { getOpenApplicationsList, getDirectApplicationsList, getUnsolicitedApplicationsList, getCountriesList, getLocationsList, getAgenciesList, getSectorsList } from "@local/actions";
import { checkPermission, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import { getDirectApplicationsColumns, getOpenApplicationsColumns, getUnsolicitedApplicationsColumns } from "./utils/columns";
import { getDirectApplicationsFilters, getOpenApplicationsFilters, getUnsolicitedApplicationsFilters } from "./utils/filters";
import ManageProjectModal from "@local/pages/projects-list/components/manage-project-modal";
import OpenApplicationsList from "./components/open-applications-list";
import DirectApplicationsList from "./components/direct-applications-list";
import UnsolicitedApplicationsList from "./components/unsolicited-applications-list";
import Container from "./components/container";
import { adaptCountries, adaptApplicationStatuses } from "@local/utils/adapters";
import { getSortedList } from "@local/utils";

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { mapAgenciesIds } from "@local/utils/constants";

export const getTabs = () => [
  {
    path: "notes",
    label: formatMessage({ id: "pages.applications.list.idx.notes" }),
  },
  {
    path: "unsolicited",
    label: formatMessage({ id: "pages.applications.list.idx.unsolicited" }),
  },
  {
    path: "direct",
    label: formatMessage({ id: "pages.applications.list.idx.direct" }),
  },
];

const ApplicationsList = ({
  sectors,
  agencies,
  statuses,
  countries,
  getOpenApplications,
  getDirectApplications,
  getUnsolicitedApplications,
  getCountries,
  getLocations,
  getAgencies,
  getSectors,
  directSelectionSources,
  tabs,
  hasUcnPermission,
  isHq,
  isProfileComplete,
}) => {
  const { type, id } = useParams();
  const [locations, setLocations] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    getAgencies();
    getSectors();
    getCountries();
  }, []);

  const onClickOpenModal = () => setIsModalVisible(true);

  const onClickCloseModal = () => setIsModalVisible(false);

  let content = undefined;

  switch (type) {
    case "notes":
      content = <OpenApplicationsList columns={getOpenApplicationsColumns(sectors, countries)} fetchFunction={getOpenApplications} filters={getOpenApplicationsFilters(countries, getLocations, locations, setLocations, sectors, agencies, statuses)} />;
      break;
    case "direct":
      content = (
        <DirectApplicationsList
          columns={getDirectApplicationsColumns(sectors, countries)}
          fetchFunction={getDirectApplications}
          filters={getDirectApplicationsFilters(countries, getLocations, locations, setLocations, sectors, agencies, statuses, directSelectionSources)}
        />
      );
      break;
    case "unsolicited":
      content = <UnsolicitedApplicationsList columns={getUnsolicitedApplicationsColumns(sectors, countries)} fetchFunction={getUnsolicitedApplications} filters={getUnsolicitedApplicationsFilters(countries, getLocations, locations, setLocations, sectors, agencies)} />;
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Container type={type} id={id} tabs={tabs} onClickHeaderButton={onClickOpenModal} hasUcnPermission={hasUcnPermission} isHq={isHq} isProfileComplete={isProfileComplete}>
        {content}
      </Container>
      <ManageProjectModal
        type={type}
        isOpen={isModalVisible}
        onClose={onClickCloseModal}
        stateValues={{
          selectedLocations: [],
          attachments: [
            {
              uniqueId: 0,
              originalId: undefined,
            },
          ],
          criteria: [
            {
              uniqueId: 0,
              originalId: undefined,
            },
          ],
        }}
      />
    </>
  );
};

ApplicationsList.propTypes = {};

const mapStateToProps = (state) => ({
  sectors: getSortedList(getSortedList(state?.sectorsList?.data?.data, "name")) ?? [],
  agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  statuses: adaptApplicationStatuses(state.partnerProfileConfig["extended-application-statuses"]),
  directSelectionSources: state.partnerProfileConfig["direct-selection-source"],
  tabs: getTabs(),
  hasUcnPermission: checkPermission(PARTNER_PERMISSIONS.UCN_DRAFT, state),
  isHq: state?.session?.isHq,
  isProfileComplete: state?.session?.isProfileComplete,
});

const mapDispatchToProps = (dispatch) => ({
  getOpenApplications: (params) => dispatch(getOpenApplicationsList(params)),
  getDirectApplications: (params) => dispatch(getDirectApplicationsList(params)),
  getUnsolicitedApplications: (params) => dispatch(getUnsolicitedApplicationsList(params)),
  getCountries: () => dispatch(getCountriesList()),
  getLocations: (params) => dispatch(getLocationsList(params)),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
  getSectors: (params) => dispatch(getSectorsList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsList);

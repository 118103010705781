/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { REPORT_STATUS_FILTER_VALUES, VERIFICATION_STATUSES, ROLES } from "@local/legacy-utils/constants";
import { getCsipStatuses, getPartnerTypes, getPseaRatingOptions, getSelfAssessmentStatuses, mapAgenciesIds, reportsNav } from "@local/utils/constants";
import {
  getCountriesList,
  getLocationsList,
  getAgenciesList,
  getSectorsList,
  getPartnerProfileReports,
  getPartnerContactReports,
  getProjectDetailsReports,
  getVerificationsReports,
  getPseaReports,
  getVerificationsDetailsReports,
  getPseaDetailsReports,
  getPartnerMappingReports,
  getPartnerReports,
  getProjectReports,
} from "@local/actions";
import { getPartnerInformationColumns, getCfeiManagementColumns, getVerificationColumns, getPseaColumns, getPseaUpperColumns } from "./utils/columns";
import { getPartnerInformationFilters, getCfeiManagementFilters, getVerificationFilters, getPseaFilters } from "./utils/filters";
import PartnerInformation from "./components/partner-information";
import CfeiManagement from "./components/cfei-management";
import Verification from "./components/verification";
import Container from "./components/container";
import { checkPermission, COMMON_PERMISSIONS, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import { checkRestrictedPath } from "@local/legacy-utils/restrictions";
import { adaptCountries } from "@local/utils/adapters";
import { getSortedList } from "@local/utils";
import PseaReport from "./components/psea";

// POST_REFACTORING_TODO: check the selection on the tables

const ReportsList = ({
  sectors,
  status,
  countries,
  agencies,
  selfAssessmentStatuses,
  csipStatuses,
  role,
  agencyId,
  getCountries,
  getLocations,
  getAgencies,
  getSectors,
  isRestrictedPath,
  tabs,
  hasPermission,
  getPartnerProfileReports,
  getPartnerContactReports,
  getProjectReports,
  partnerTypes,
  VerificationMenu,
  cfeiTypes,
  applicationStatuses,
  populationConcern,
  getPartnerReports,
  getProjectDetailsReports,
  hasCSOProfilePermission,
  hasCSOContactPermission,
  hasCFEIReportPermission,
  getVerificationsReports,
  getVerificationsDetailsReports,
  hasVerificationReportPermission,
  getPseaReports,
  getPseaDetailsReports,
}) => {
  const { type } = useParams();
  const [locations, setLocations] = useState([]);
  const [showDownloadInfo, setShowDownloadInfo] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isTableFiltered, setIsTableFiltered] = useState(false);
  const [filterParams, setFilterParams] = useState(null);
  const [isExportContactReport, setIsExportContactReport] = useState(false);
  useEffect(() => {
    getAgencies();
    getSectors();
    getCountries();
  }, []);

  const onClickCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const onClickCloseDownloadInfo = () => {
    setShowDownloadInfo(false);
  };

  const onChangeTableFilters = (filterValues) => {
    setIsTableFiltered(Object.keys(filterValues).filter((filter) => filter).length > 0 && Object.values(filterValues).filter((filter) => filter).length > 0); // fix this issue
    setFilterParams(filterValues);
  };

  const exportPartnerProfileReports = async () => {
    if (!isTableFiltered && !isConfirmModalOpen) {
      setIsConfirmModalOpen(true);
    } else {
      const data = await getPartnerProfileReports({
        page: 1,
        page_size: 5,
        ...filterParams,
      });
      setShowDownloadInfo(true);
      data?.data?.length > 0 && setDownloadInfo(data?.data[0]);
      setIsConfirmModalOpen(false);
      setFilterParams(null);
    }
  };

  const exportPartnerContactReports = async () => {
    setIsExportContactReport(true);
    if (!isTableFiltered && !isConfirmModalOpen) {
      setIsConfirmModalOpen(true);
    } else {
      const data = await getPartnerContactReports({
        page: 1,
        page_size: 5,
        ...filterParams,
      });
      setShowDownloadInfo(true);
      data?.data?.length > 0 && setDownloadInfo(data?.data[0]);
      setIsConfirmModalOpen(false);
      setIsExportContactReport(false);
      setFilterParams(null);
    }
  };

  const exportProjectDetailsReports = async () => {
    if (!isTableFiltered && !isConfirmModalOpen) {
      setIsConfirmModalOpen(true);
    } else {
      const data = await getProjectDetailsReports({
        page: 1,
        page_size: 5,
        ...filterParams,
      });
      setShowDownloadInfo(true);
      data?.data?.length > 0 && setDownloadInfo(data?.data[0]);
      setIsConfirmModalOpen(false);
      setFilterParams(null);
    }
  };

  const exportVerificationDetailsReports = async () => {
    if (!isTableFiltered && !isConfirmModalOpen) {
      setIsConfirmModalOpen(true);
    } else {
      const data = await getVerificationsDetailsReports({
        page: 1,
        page_size: 5,
        ...filterParams,
      });
      setShowDownloadInfo(true);
      data?.data?.length > 0 && setDownloadInfo(data?.data[0]);
      setIsConfirmModalOpen(false);
      setFilterParams(null);
    }
  };

  const exportPseaDetailsReports = async () => {
    if (!isTableFiltered && !isConfirmModalOpen) {
      setIsConfirmModalOpen(true);
    } else {
      const data = await getPseaDetailsReports({
        page: 1,
        page_size: 5,
        ...filterParams,
      });
      setShowDownloadInfo(true);
      data?.data?.length > 0 && setDownloadInfo(data?.data[0]);
      setIsConfirmModalOpen(false);
      setFilterParams(null);
    }
  };

  let content = undefined;

  switch (type) {
    case "information":
      content = (
        <PartnerInformation
          columns={getPartnerInformationColumns()}
          fetchFunction={getPartnerReports}
          filters={getPartnerInformationFilters(countries, getLocations, locations, setLocations, sectors, status, partnerTypes)}
          hasCSOProfilePermission={hasCSOProfilePermission}
          hasCSOContactPermission={hasCSOContactPermission}
          exportPartnerProfileReports={exportPartnerProfileReports}
          exportPartnerContactReports={exportPartnerContactReports}
          showDownloadInfo={showDownloadInfo}
          downloadInfo={downloadInfo}
          isOpen={isConfirmModalOpen}
          onClose={onClickCloseConfirmModal}
          onCloseDownloadInfo={onClickCloseDownloadInfo}
          onChangeTableFilters={onChangeTableFilters}
          isExportContactReport={isExportContactReport}
        />
      );
      break;
    case "management":
      content = (
        <CfeiManagement
          columns={getCfeiManagementColumns()}
          fetchFunction={getProjectReports}
          filters={getCfeiManagementFilters(countries, getLocations, locations, setLocations, sectors, cfeiTypes, applicationStatuses, agencies)}
          hasCFEIReportPermission={hasCFEIReportPermission}
          exportProjectDetailsReports={exportProjectDetailsReports}
          showDownloadInfo={showDownloadInfo}
          downloadInfo={downloadInfo}
          isOpen={isConfirmModalOpen}
          onClose={onClickCloseConfirmModal}
          onCloseDownloadInfo={onClickCloseDownloadInfo}
          onChangeTableFilters={onChangeTableFilters}
          initialFilters={role === ROLES.AGENCY ? { agency: agencyId } : undefined}
        />
      );
      break;
    case "verification":
      content = (
        <Verification
          columns={getVerificationColumns()}
          fetchFunction={getVerificationsReports}
          filters={getVerificationFilters(countries, sectors, partnerTypes, VerificationMenu, populationConcern)}
          hasVerificationReportPermission={hasVerificationReportPermission}
          exportVerificationDetailsReports={exportVerificationDetailsReports}
          showDownloadInfo={showDownloadInfo}
          downloadInfo={downloadInfo}
          isOpen={isConfirmModalOpen}
          onClose={onClickCloseConfirmModal}
          onCloseDownloadInfo={onClickCloseDownloadInfo}
          onChangeTableFilters={onChangeTableFilters}
        />
      );
      break;
    case "psea":
      content = (
        <PseaReport
          columns={getPseaColumns()}
          secondaryColumns={getPseaUpperColumns()}
          fetchFunction={getPseaReports}
          filters={getPseaFilters(selfAssessmentStatuses, csipStatuses, partnerTypes, countries, sectors)}
          hasPseaReportPermission={true} //TODO: ask for permissions for psea reports
          exportPseaDetailsReports={exportPseaDetailsReports}
          showDownloadInfo={showDownloadInfo}
          downloadInfo={downloadInfo}
          isOpen={isConfirmModalOpen}
          onClose={onClickCloseConfirmModal}
          onCloseDownloadInfo={onClickCloseDownloadInfo}
          onChangeTableFilters={onChangeTableFilters}
        />
      );
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Container type={type} tabs={tabs} hasPermission={hasPermission} isRestrictedPath={isRestrictedPath}>
        {content}
      </Container>
    </>
  );
};

ReportsList.propTypes = {};

const mapStateToProps = (state) => ({
  sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
  agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  ratings: getPseaRatingOptions(),
  selfAssessmentStatuses: getSelfAssessmentStatuses(),
  csipStatuses: getCsipStatuses(),
  status: REPORT_STATUS_FILTER_VALUES,
  tabs: reportsNav(state),
  role: state.session.role,
  agencyId: state.session.agencyId,
  hasPermission: checkPermission(COMMON_PERMISSIONS.CFEI_VIEW, state),
  isRestrictedPath: checkRestrictedPath(state),
  partnerTypes: getPartnerTypes(),
  agencyName: state?.session?.agencyName,
  VerificationMenu: VERIFICATION_STATUSES,
  cfeiTypes: state?.partnerProfileConfig["cfei-types"],
  applicationStatuses: state?.partnerProfileConfig["cfei-statuses"],
  populationConcern: state.partnerProfileConfig["population-of-concern"],
  hasCSOProfilePermission: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_CSO_PROFILE, state),
  hasCSOContactPermission: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_CSO_CONTACT, state),
  hasCSOMappingPermission: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_CSO_MAPPING, state),
  hasCFEIReportPermission: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_CFEI_MANAGEMENT, state),
  hasVerificationReportPermission: checkPermission(AGENCY_PERMISSIONS.RUN_REPORT_CFEI_MANAGEMENT, state),
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(getCountriesList()),
  getLocations: (params) => dispatch(getLocationsList(params)),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
  getSectors: (params) => dispatch(getSectorsList(params)),
  getPartnerReports: (params) => dispatch(getPartnerReports(params)),
  getProjectReports: (params) => dispatch(getProjectReports(params)),
  getPartnerProfileReports: (params) => dispatch(getPartnerProfileReports(params)),
  getPartnerContactReports: (params) => dispatch(getPartnerContactReports(params)),
  getPartnerMappingReports: (params) => dispatch(getPartnerMappingReports(params)),
  getProjectDetailsReports: (params) => dispatch(getProjectDetailsReports(params)),
  getVerificationsReports: (params) => dispatch(getVerificationsReports(params)),
  getVerificationsDetailsReports: (params) => dispatch(getVerificationsDetailsReports(params)),
  getPseaDetailsReports: (params) => dispatch(getPseaDetailsReports(params)),
  getPseaReports: (params) => dispatch(getPseaReports(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsList);

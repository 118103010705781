import React, { useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import { reduxForm } from "redux-form";
import ResponseForm from "./components/partner-response-form";
import { ROLES } from "@local/legacy-utils/constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Card from "@local/components/card";
import { getPartnerProjectApplication, updateProjectApplication } from "@local/actions";
import ProjectFeedback from "../feedback";

const DirectProjectResponse = ({ partnerProjectApplication, getPartnerProjectApplication, role, hasDSRAnswerPermission, project, partnerId }) => {
  useEffect(() => {
    if (role === ROLES.PARTNER) {
      getPartnerProjectApplication();
    }
  }, [getPartnerProjectApplication, role]);

  if (role === ROLES.AGENCY) return <></>;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {hasDSRAnswerPermission ? (
              <Grid item xs={12} sm={6}>
                <Card
                  title={formatMessage({
                    id: "pages.project.details.cmp.direct.project.response.idx.title",
                  })}
                >
                  <ResponseForm application={partnerProjectApplication} isCnRequired={partnerProjectApplication?.is_cn_required} refreshFunction={getPartnerProjectApplication} project={project} partnerId={partnerId} />
                </Card>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={6}>
              <ProjectFeedback allowedToAddDirectFeedback={false} isCompleted={project?.is_completed} isCreator={false} isFocalPoint={false} applicationId={partnerProjectApplication?.id} role={role} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

DirectProjectResponse.propTypes = {};
const mapStateToProps = (state) => ({
  partnerProjectApplication: state?.partnerProjectApplication?.data?.data,
  partnerId: state?.session?.partnerId,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProjectApplication: (applicationId, body) => dispatch(updateProjectApplication(body, { id: applicationId })),
  getPartnerProjectApplication: () =>
    dispatch(
      getPartnerProjectApplication(undefined, {
        projectId: ownProps?.projectId,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "ResultForm", enableReinitialize: true })(DirectProjectResponse));

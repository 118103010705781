import React, { useMemo } from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import style from "./style.module.scss";
import DownloadIcon from "@local/components/download-icon";
import Button from "@local/components/button";
import WarningIcon from "@mui/icons-material/Warning";
import FieldSelect from "@local/components/field-select";
import FieldDatePicker from "@local/components/field-date-picker";
import FieldText from "@local/components/field-text";
import { isRequired, isBetweenDate, isValidDate } from "@local/utils/validators";
import { subtractYears } from "../../../../../../utils/adapters";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const PseaInitialSectionConfirmation = ({
  ratingOptions,
  isLocked,
  isAgencyLocked,
  hasEditPseaInitialAssessmentPermission,
  formValues,
  documents,
  documentTypes,
  onClickDeleteDocument,
  onClickUploadInitialAssessmentDocument,
  readOnly,
  isCopyPseaEnabled,
  onClickCopyUploadedDocument,
  isDifferentRatingCommentRequired,
}) => {
  const isUploadPseaVisible = formValues?.agency_is_assessed === "true";
  const isDateBetween = useMemo(() => isBetweenDate(subtractYears(new Date(), 5), new Date()), []);
  return (
    <div>
      <span className={style.sectionTitle}>
        {formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencyInitialAssessmentTitle",
        })}
      </span>
      <div className={style.sectionWrapper}>
        <div className={style.questionWrapper}>
          <div>
            <span className={style.questionTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySecondAnswerConfirmationTitle",
              })}
            </span>
            <span className={style.questionDescription}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySecondAnswerConfirmationDescriptionFirstPart",
              })}
            </span>
            <span className={style.questionDescription}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySecondAnswerConfirmationDescriptionSecondPart",
              })}
            </span>
            <span className={style.questionDescription}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySecondAnswerConfirmationDescriptionThirdPart",
              })}
            </span>
          </div>
          <div className={style.agencyBeneficiariesWrapper}>
            <Field
              component={FieldRadioGroup}
              id="agency_beneficiaries"
              name="agency_beneficiaries"
              readOnly={isLocked || isAgencyLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
              options={[
                {
                  value: true,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.yes",
                  }),
                },
                {
                  value: false,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.no",
                  }),
                },
              ]}
            />
          </div>
        </div>
        <div className={style.questionWrapper}>
          <div>
            <span className={style.questionTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencyAssessedQuestion",
              })}
            </span>
            <span className={style.questionDescription}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencyAssessedQuestionDescription",
              })}
            </span>
            <span className={style.questionDescription}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencyAssessedQuestionDescriptionDetails",
              })}
            </span>
            <span className={style.questionDescription}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencyAssessedQuestionDescriptionOtherDetails",
              })}
            </span>
            {isUploadPseaVisible ? (
              <div className={style.secondQuestionWrapper}>
                <div className={style.firstQuestionUploadFormWrapper}>
                  {isCopyPseaEnabled ? (
                    <div className={style.copyUploadedWrapper}>
                      <Button
                        variant="text"
                        text={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.copyFromPartner",
                        })}
                        onClick={onClickCopyUploadedDocument}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className={style.firstQuestionFilesListWrapper}>
                    {documents?.length ? (
                      <>
                        {documents.map((item) => (
                          <div key={`psea_initial_document_item_${item.id}`}>
                            <DownloadIcon
                              onClickDelete={!isLocked && hasEditPseaInitialAssessmentPermission && !readOnly ? () => onClickDeleteDocument(item) : undefined}
                              fileUrl={item.file.file_field}
                              fileName={item.note || documentTypes?.find((type) => type.value === item.doc_type)?.label}
                            />
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={style.firstQuestionUploadWrapper}>
                    <Button
                      isDisabled={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly || documents.length === 5}
                      text={formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.uploadDocumentButton",
                      })}
                      onClick={onClickUploadInitialAssessmentDocument}
                    />
                    {!documents?.find((item) => item?.doc_type === "assessment_results") ? (
                      <div className={style.warningIcon}>
                        <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
                      </div>
                    ) : undefined}
                  </div>
                  <div className={style.firstQuestionFormWrapper}>
                    <div>
                      <Field
                        readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                        component={FieldText}
                        validate={[isRequired]}
                        id="agency_un_agency_submitter"
                        name="agency_un_agency_submitter"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySubmitterLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySubmitterLabel",
                        })}
                        small
                      />
                    </div>
                    <div>
                      <Field
                        readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                        component={FieldDatePicker}
                        validate={[isRequired, isDateBetween, isValidDate]}
                        id="agency_submission_date"
                        name="agency_submission_date"
                        maximumDate={new Date()}
                        minimumDate={subtractYears(new Date(), 5)}
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySubmissionDateLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencySubmissionDateLabel",
                        })}
                        small
                      />
                    </div>
                    <div>
                      <Field
                        readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                        component={FieldSelect}
                        validate={[isRequired]}
                        id="agency_rating"
                        name="agency_rating"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencyRatingFiledLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.agencyRatingFiledLabel",
                        })}
                        valueField="value"
                        labelField="extendedLabel"
                        options={ratingOptions}
                        small
                      />
                    </div>
                  </div>
                  {isDifferentRatingCommentRequired ? (
                    <div className={style.differentRatingCommentWrapper}>
                      <Field
                        readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
                        component={FieldText}
                        validate={[isRequired]}
                        id="different_rating_comment"
                        name="different_rating_comment"
                        label={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.differentRatingCommentLabel",
                        })}
                        placeholder={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.differentRatingCommentLabel",
                        })}
                        small
                        maxLength={5000}
                        withCharactersCount
                        multiline
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <Field
              component={FieldRadioGroup}
              id="agency_is_assessed"
              name="agency_is_assessed"
              readOnly={isLocked || !hasEditPseaInitialAssessmentPermission || readOnly}
              options={[
                {
                  value: true,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.yes",
                  }),
                },
                {
                  value: false,
                  label: formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.no",
                  }),
                },
              ]}
            />
          </div>
        </div>
        <div className={style.linksWrapper}>
          <div className={style.linkItemWrapper}>
            <a
              target="_blank"
              alt={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.conversionLink",
              })}
              href="https://supportagency.unpartnerportal.org/hc/en-us/articles/14881043134743-PSEA-Module-User-Guides-"
              rel="noreferrer"
            >
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.cmp.confirmation.idx.conversionLink",
              })}
            </a>
            <OpenInNewIcon style={{ fontSize: ".85rem", color: "#777777" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

PseaInitialSectionConfirmation.propTypes = {};

export default reduxForm({
  form: "pseaInitialSectionAgencyAnswersForm",
  enableReinitialize: true,
})(PseaInitialSectionConfirmation);

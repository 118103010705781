import React from "react";
import Card from "@local/components/card";
import FileDownloadButton from "@local/components/download-button";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import Loader from "@local/pages/common/loader";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";

const ConceptNote = ({ conceptNote, date, title, loading }) => {
  return (
    <Card title={title}>
      <Loader loading={loading}>
        {!loading ? (
          <div className={style.contentWrapper}>
            <FileDownloadButton fileUrl={conceptNote} />
            {date ? (
              <span className={style.dateInfo}>{`${formatMessage({
                id: "pages.project.details.cmp.overview.cmp.concept.note.idx.caption",
              })}: ${formatDateForPrint(date)}`}</span>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Loader>
    </Card>
  );
};

ConceptNote.propTypes = {};

export default ConceptNote;

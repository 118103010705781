/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory, useLocation, useParams } from "react-router";
import Container from "./components/container";
import Risk from "./components/risk";
import Overview from "./components/overview";
import SpacialPartnerOverview from "./components/spacial-partner-overview";
import {
  getPartnerProfileVerificationsList,
  getPartnerProfileDataDetails,
  getCountriesList,
  getPartnerVerificationsFullList,
  getPartnerFlagsList,
  getPartnerApplicationsList,
  getPartnerSummaryDetails,
  getPartnerMembersList,
  updateSpecialPartner,
  getSpecialPartnerSubNationalsList,
  getAgenciesList,
  getSectorsList,
  deleteSpecialPartner,
  createSpecialPartner,
  updatePartnerFlag,
  updateSanctionMatch,
  getSanctionMatch,
} from "@local/actions";
import { checkPermission, AGENCY_PERMISSIONS, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import ProfileDetails from "./components/profile-details";
import UnData from "./components/un-data";
import Verification from "./components/verification";
import Observations from "./components/observations";
import Applications from "./components/applications";
import Offices from "./components/offices";
import Users from "./components/users";
import { adaptApplications, adaptObservationToBeSent } from "./utils/adapters";
import { ROLES, getSpecialPartnerTypes, getFlagTypesForFiltering, getRiskCategories, FLAG_TYPES, mapAgenciesIds } from "@local/utils/constants";
import { isValid, reset } from "redux-form";
import ManagePartnerModal from "@local/pages/partners-list/components/manage-partner-modal";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptCountries, adaptCategoryRisks, adaptFlagTypes } from "@local/utils/adapters";
import ConfirmModal from "@local/components/confirm-modal";
import Alert from "@local/components/alert";
import { getSortedList } from "@local/utils";
import Psea from "./components/psea";
import { FEATURES_ENABLED } from "@local/legacy-utils/constants";
import ObservationModal from "./components/container/components/observation-modal";

// POST_REFACTORING_TODO: This page is too slow, check the requests done

const getPartnerTabs = () => [
  {
    id: "overview",
    path: "overview",
    label: formatMessage({ id: "pages.partner.details.idx.overview" }),
    isVisible: true,
  },
  {
    id: "psea",
    path: "psea",
    label: formatMessage({ id: "pages.partner.details.idx.PSEA" }),
    isVisible: true,
  },
  {
    id: "undata",
    path: "undata",
    label: formatMessage({ id: "pages.partner.details.idx.undata" }),
    isVisible: false,
  },
];

const getAgencyTabs = (hasVerifySeeCommentsPermission, isApplicationsTabVisible) => [
  {
    id: "overview",
    path: "overview",
    label: formatMessage({ id: "pages.partner.details.idx.overviewOne" }),
    isAvailableForSpecialPartner: true,
    isAvailableForRegularPartner: true,
    isVisible: true,
  },
  {
    id: "details",
    path: "details",
    label: formatMessage({ id: "pages.partner.details.idx.details" }),
    isAvailableForSpecialPartner: false,
    isAvailableForRegularPartner: true,
    isVisible: true,
  },
  {
    id: "psea",
    path: "psea",
    label: formatMessage({ id: "pages.partner.details.idx.PSEA" }),
    isAvailableForSpecialPartner: false,
    isAvailableForRegularPartner: true,
    isVisible: true,
  },
  {
    id: "undata",
    path: "undata",
    label: formatMessage({ id: "pages.partner.details.idx.undataOne" }),
    isAvailableForSpecialPartner: true,
    isAvailableForRegularPartner: true,
    isVisible: true,
  },
  {
    id: "verification",
    path: "verification",
    label: formatMessage({ id: "pages.partner.details.idx.verification" }),
    isAvailableForSpecialPartner: false,
    isAvailableForRegularPartner: true,
    isVisible: hasVerifySeeCommentsPermission,
  },
  {
    id: "risk",
    path: "risk/dashboard",
    label: formatMessage({ id: "pages.partner.details.idx.risk" }),
    isDisabled: false,
    isAvailableForSpecialPartner: false,
    isAvailableForRegularPartner: true,
    isVisible: true,
  },
  {
    id: "observations",
    path: "observations",
    label: formatMessage({ id: "pages.partner.details.idx.observations" }),
    isAvailableForSpecialPartner: false,
    isAvailableForRegularPartner: true,
    isVisible: false,
  },
  {
    id: "applications",
    path: "applications",
    label: formatMessage({ id: "pages.partner.details.idx.applications" }),
    isAvailableForSpecialPartner: false,
    isAvailableForRegularPartner: true,
    isVisible: isApplicationsTabVisible,
  },
  {
    id: "users",
    path: "users",
    label: formatMessage({ id: "pages.partner.details.idx.users" }),
    isAvailableForSpecialPartner: false,
    isAvailableForRegularPartner: true,
    isVisible: true,
  },
  {
    id: "offices",
    path: "offices",
    label: formatMessage({ id: "pages.partner.details.idx.countryOffice" }),
    isAvailableForSpecialPartner: true,
    isAvailableForRegularPartner: false,
    isVisible: true,
  },
];

const getPathPrefix = (role, isSpecialPartner) => {
  if (role === ROLES.AGENCY && isSpecialPartner) {
    return "special-partner";
  } else if (role === ROLES.AGENCY && !isSpecialPartner) {
    return "partner";
  } else {
    return "profile";
  }
};

const getSelectedTabIndex = (tabs, location, id, role, isSpecialPartner) => {
  const selectedTabIndex = tabs.findIndex((tab) => location.pathname.startsWith(`/${getPathPrefix(role, isSpecialPartner)}/${id}/${tab.id}`));
  if (selectedTabIndex === -1) return 0;
  return selectedTabIndex;
};

const PartnerDetails = ({
  tabs,
  partnerSummary,
  getPartnerSummary,
  getPartnerProfileVerifications,
  verifications,
  hasPermissionViewFlagCount,
  getPartnerProfileData,
  partnerProfile,
  getCountries,
  countries,
  agencyId,
  getAllPartnerVerifications,
  getPartnerFlags,
  hasResolveEscalatePermission,
  hasReviewSanctionMatchPermission,
  getPartnerApplications,
  getPartnerMembers,
  role,
  isSpecialPartner,
  updateSpecialPartner,
  getSpecialPartnerSubNationals,
  resetSpecialPartnerForm,
  resetObservationForm,
  isValidSpecialPartnerForm,
  specialPartnerFormValues,
  specialPartnerTypes,
  specialPartnerInitialFormValues,
  agencies,
  getAgencies,
  getSectors,
  sectors,
  deleteSpecialPartner,
  flagTypes,
  riskCategories,
  specialPartnerSubNationals,
  isAddSubnationalOfficeAllowed,
  createSpecialPartner,
  isSwitchToHqButtonVisible,
  sessionHqId,
  sessionPartnerId,
  userId,
  categoryRisks,
  isValidObservationForm,
  observationFormValues,
  updatePartnerFlag,
  flagTypesOptions,
  hasViewPseaObservationsPermission,
  updateSanctionMatch,
  verifyMatchFormValues,
  getSanctionMatch,
  sanctionMatchDetails,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { id, type } = useParams();
  const [isManageSpecialPartnerModalVisible, setIsManageSpecialPartnerModalVisible] = useState(false);
  const [isAddingNewSubnationalOffice, setIsAddingNewSubnationalOffice] = useState(false);
  const [isDeleteSpecialPartnerModalVisible, setIsDeleteSpecialPartnerModalVisible] = useState(false);
  const [shouldRefreshVerifications, setShouldRefreshVerifications] = useState(false);
  const [shouldRefreshObservations, setShouldRefreshObservations] = useState(false);
  const [isObservationModalVisible, setIsObservationModalVisible] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(null);
  const [observationUpdateType, setObservationUpdateType] = useState(null);

  const adaptObservationInitialValues = {
    ...selectedFlag,
    validation_comment: undefined,
  };

  useEffect(() => {
    getPartnerSummary();
    getPartnerProfileData();
    getCountries();
    getAgencies();
    getSectors();
    getSanctionMatch(id);
    if (role === ROLES.AGENCY) {
      getPartnerProfileVerifications();
    }
  }, []);

  useEffect(() => {
    if (type === "offices") getSpecialPartnerSubNationals();
  }, [type]);

  const onChangeTab = (event, index) => history.push(`/${getPathPrefix(role, isSpecialPartner)}/${id}/${tabs[index].path}`);

  const onClickGoToOffice = (officeId) => window.location.assign(`/special-partner/${officeId}/overview`);

  const onClickAddNewSubnationalOffice = () => {
    setIsManageSpecialPartnerModalVisible(true);
    setIsAddingNewSubnationalOffice(true);
  };

  const onClickHeaderButton = () => history.push(`/profile/${id}/edit/identification`);

  const onClickDeleteSpecialPartner = () => {
    setIsDeleteSpecialPartnerModalVisible(true);
  };

  const onClickEditSpecialPartner = () => {
    setIsManageSpecialPartnerModalVisible(true);
    setIsAddingNewSubnationalOffice(false);
  };

  const onClickSubmitDeleteSpecialPartner = async () => {
    await deleteSpecialPartner(id);
    setIsDeleteSpecialPartnerModalVisible(false);
    history.push("/partner");
  };

  const onClickCloseDeleteSpecialPartner = () => {
    setIsDeleteSpecialPartnerModalVisible(false);
  };

  const onCloseManageSpecialPartnerModal = () => {
    setIsManageSpecialPartnerModalVisible(false);
    setIsAddingNewSubnationalOffice(false);
    resetSpecialPartnerForm();
  };

  const onSubmitManageSpecialPartner = async () => {
    try {
      if (isAddingNewSubnationalOffice) {
        await createSpecialPartner({
          hq: id,
          display_type: specialPartnerFormValues?.display_type,
          legal_name: specialPartnerFormValues?.legal_name,
          other_names: specialPartnerFormValues?.other_names,
          country_code: specialPartnerFormValues?.country_code,
          mailing_address: {
            mailing_type: specialPartnerFormValues?.mailing_type,
            street: specialPartnerFormValues?.street,
            po_box: specialPartnerFormValues?.po_box,
            city: specialPartnerFormValues?.city,
            zip_code: specialPartnerFormValues?.zip_code,
            org_email: specialPartnerFormValues?.org_email,
          },
        });
        getSpecialPartnerSubNationals();
      } else {
        await updateSpecialPartner({
          display_type: specialPartnerFormValues?.display_type,
          legal_name: specialPartnerFormValues?.legal_name,
          other_names: specialPartnerFormValues?.other_names,
          country_code: specialPartnerFormValues?.country_code,
          mailing_address: {
            mailing_type: specialPartnerFormValues?.mailing_type,
            street: specialPartnerFormValues?.street,
            po_box: specialPartnerFormValues?.po_box,
            city: specialPartnerFormValues?.city,
            zip_code: specialPartnerFormValues?.zip_code,
            org_email: specialPartnerFormValues?.org_email,
          },
        });
        getPartnerSummary();
      }
      setIsManageSpecialPartnerModalVisible(false);
      setIsAddingNewSubnationalOffice(false);
      resetSpecialPartnerForm();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.partner.details.idx.unableToSaveData" }));
    }
  };

  const onClickOpenUpdateObservationModal = (row) => {
    setSelectedFlag(row);
    setObservationUpdateType("updateObservation");
    setIsObservationModalVisible(true);
  };

  const onClickOpenUpdateEscalatedObservationModal = (row) => {
    setSelectedFlag(row);
    setObservationUpdateType("updateEscalatedObservation");
    setIsObservationModalVisible(true);
  };

  const onClickOpenUpdateSanctionObservationModal = (row) => {
    setSelectedFlag(row);
    setObservationUpdateType("updateSanctionObservation");
    setIsObservationModalVisible(true);
  };

  const onClickCloseObservationModal = () => {
    setIsObservationModalVisible(false);
    setSelectedFlag(undefined);
    resetObservationForm();
  };

  const onClickSubmitUpdateObservationModal = async () => {
    try {
      await updatePartnerFlag(partnerSummary?.partner_additional?.id, selectedFlag?.id, adaptObservationToBeSent(observationUpdateType, observationFormValues));
      setShouldRefreshObservations(true);
      setIsObservationModalVisible(false);
      getPartnerFlags(partnerSummary?.partner_additional?.id);
      resetObservationForm();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.partner.details.idx.unableToSaveData" }));
    }
  };

  const afterRefreshVerifications = () => {
    setShouldRefreshVerifications(false);
  };

  const afterRefreshObservations = () => {
    setShouldRefreshObservations(false);
  };

  const selectedTabIndex = getSelectedTabIndex(tabs, location, id, role, isSpecialPartner);

  const getPartnerApplicationsFetchFunction = (params) => getPartnerApplications(params, agencyId);
  const getPartnerApplicationsAdapterFunction = (data) => adaptApplications(data, countries);

  let content = undefined;
  const selectedTab = tabs[selectedTabIndex];

  switch (selectedTab.id) {
    case "overview":
      if (role === ROLES.AGENCY && isSpecialPartner) {
        content = <SpacialPartnerOverview partner={partnerSummary} />;
      } else if (role === ROLES.AGENCY && !isSpecialPartner) {
        content = (
          <Overview
            partnerSummary={partnerSummary}
            partner={partnerProfile}
            verifications={verifications}
            hasPermissionViewFlagCount={hasPermissionViewFlagCount}
            hasViewPseaObservationsPermission={hasViewPseaObservationsPermission}
            countries={countries}
            updateSanctionMatch={updateSanctionMatch}
            verifyMatchFormValues={verifyMatchFormValues}
            getPartnerProfileData={getPartnerProfileData}
            sanctionMatchDetails={sanctionMatchDetails}
          />
        );
      } else {
        content = <ProfileDetails partner={partnerProfile} countries={countries} isSwitchToHqButtonVisible={isSwitchToHqButtonVisible} sessionHqId={sessionHqId} sessionPartnerId={sessionPartnerId} />;
      }
      break;
    case "details":
      content = <ProfileDetails partner={partnerProfile} countries={countries} />;
      break;
    case "undata":
      content = <UnData partnerId={id} />;
      break;
    case "psea":
      content = <Psea partnerId={id} />;
      break;
    case "verification":
      content = <Verification fetchFunction={getAllPartnerVerifications} shouldRefresh={shouldRefreshVerifications} afterRefresh={afterRefreshVerifications} />;
      break;
    case "risk":
      content = <Risk />;
      break;
    case "observations":
      content = (
        <Observations
          fetchFunction={getPartnerFlags}
          hasResolveEscalatePermission={hasResolveEscalatePermission}
          hasReviewSanctionMatchPermission={hasReviewSanctionMatchPermission}
          flagTypes={flagTypes}
          riskCategories={riskCategories}
          shouldRefresh={shouldRefreshObservations}
          afterRefresh={afterRefreshObservations}
          userId={userId}
          onClickOpenUpdateObservationModal={onClickOpenUpdateObservationModal}
          onClickOpenUpdateEscalatedObservationModal={onClickOpenUpdateEscalatedObservationModal}
          onClickOpenUpdateSanctionObservationModal={onClickOpenUpdateSanctionObservationModal}
        />
      );
      break;
    case "applications":
      content = <Applications fetchFunction={getPartnerApplicationsFetchFunction} adapterFunction={getPartnerApplicationsAdapterFunction} countries={adaptCountries(countries)} sectors={sectors} agencies={agencies} initialFilters={{ agency: agencyId }} />;
      break;
    case "users":
      content = <Users fetchFunction={getPartnerMembers} />;
      break;
    case "offices":
      content = <Offices specialPartnerSubNationals={specialPartnerSubNationals} onClickGoToOffice={onClickGoToOffice} isAddSubnationalOfficeAllowed={isAddSubnationalOfficeAllowed} onClickAddNewSubnationalOffice={onClickAddNewSubnationalOffice} />;
      break;
    default:
      content = undefined;
      break;
  }

  return (
    <>
      <Container
        getPartnerProfileData={getPartnerProfileData}
        partner={partnerSummary}
        partnerProfile={partnerProfile}
        tabs={tabs}
        selectedTab={selectedTabIndex}
        onChangeTab={onChangeTab}
        onClickHeaderButton={onClickHeaderButton}
        isSpecialPartner={isSpecialPartner}
        onClickDeleteSpecialPartner={onClickDeleteSpecialPartner}
        onClickEditSpecialPartner={onClickEditSpecialPartner}
        setShouldRefreshObservations={setShouldRefreshObservations}
        setShouldRefreshVerifications={setShouldRefreshVerifications}
      >
        {content}
      </Container>
      <ManagePartnerModal
        isTypeEditable={!isAddingNewSubnationalOffice}
        initialValues={isAddingNewSubnationalOffice ? { display_type: partnerProfile?.display_type } : specialPartnerInitialFormValues}
        isOpen={isManageSpecialPartnerModalVisible}
        onClose={onCloseManageSpecialPartnerModal}
        onSubmit={onSubmitManageSpecialPartner}
        isValidSpecialPartnerForm={isValidSpecialPartnerForm}
        partnerTypes={specialPartnerTypes}
        formValues={specialPartnerFormValues}
        countries={adaptCountries(countries)}
      />
      <ConfirmModal
        isOpen={isDeleteSpecialPartnerModalVisible}
        onClose={onClickCloseDeleteSpecialPartner}
        onSubmit={onClickSubmitDeleteSpecialPartner}
        title={formatMessage({ id: "pages.partner.details.idx.title" })}
        type="warning"
        message={
          <div>
            <Alert type="info" content={formatMessage({ id: "pages.partner.details.idx.info" })} withBottomMargin />
          </div>
        }
      />
      <ObservationModal
        isOpen={isObservationModalVisible}
        onClose={onClickCloseObservationModal}
        categoryRisks={categoryRisks}
        flagTypeOptions={flagTypesOptions}
        onSubmit={onClickSubmitUpdateObservationModal}
        isValid={isValidObservationForm}
        isRiskFlagSelected={observationFormValues?.flag_type === FLAG_TYPES.YELLOW || observationFormValues?.flag_type === FLAG_TYPES.RED}
        initialValues={adaptObservationInitialValues}
        observationUpdateType={observationUpdateType}
        selectedFlag={selectedFlag}
        isEditable
      />
    </>
  );
};

PartnerDetails.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  const hasVerifySeeCommentsPermission = checkPermission(AGENCY_PERMISSIONS.VERIFY_SEE_COMMENTS, state);
  const role = state.session.role;
  const isApplicationsTabVisible = checkPermission(AGENCY_PERMISSIONS.CFEI_VIEW_APPLICATIONS, state) || (state.session.officeRole === "HQ Editor" && state.session.agencyName === "UNHCR");
  let tabs = [];
  if (role === ROLES.AGENCY && ownProps.isSpecialPartner) {
    tabs = getAgencyTabs(hasVerifySeeCommentsPermission, isApplicationsTabVisible).filter((item) => item.isAvailableForSpecialPartner && item.isVisible);
  } else if (role === ROLES.AGENCY && !ownProps.isSpecialPartner) {
    tabs = getAgencyTabs(hasVerifySeeCommentsPermission, isApplicationsTabVisible).filter((item) => item.isAvailableForRegularPartner && item.isVisible);
  } else {
    tabs = getPartnerTabs().filter((item) => item.isVisible);
  }
  const partnerSummary = state.partnerSummaryDetails.data?.data ?? {};
  return {
    tabs,
    partnerSummary,
    partnerProfile: state.partnerProfileDataDetails.data?.data ?? {},
    verifications: state.partnerProfileVerifications.data?.data?.results ?? [],
    hasPermissionViewFlagCount: checkPermission(AGENCY_PERMISSIONS.VIEW_PROFILE_OBSERVATION_FLAG_COUNT, state),
    countries: state?.countriesList?.data?.data ?? {},
    specialPartnerSubNationals: state?.specialPartnerSubNationals?.data?.data ?? {},
    agencyId: state.session.agencyId,
    hasResolveEscalatePermission: checkPermission(AGENCY_PERMISSIONS.RESOLVE_ESCALATED_FLAG_ALL_CSO_PROFILES, state),
    hasReviewSanctionMatchPermission: checkPermission(AGENCY_PERMISSIONS.REVIEW_AND_MARK_SANCTIONS_MATCHES, state),
    role,
    isValidSpecialPartnerForm: isValid("specialPartnerModalForm")(state),
    specialPartnerFormValues: state.form?.specialPartnerModalForm?.values ?? {},
    verifyMatchFormValues: state?.form?.verifyMatchForm?.values ?? {},
    specialPartnerTypes: getSpecialPartnerTypes(),
    agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
    sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
    specialPartnerInitialFormValues: {
      display_type: partnerSummary?.display_type,
      legal_name: partnerSummary?.legal_name,
      other_names: partnerSummary?.other_names,
      country_code: partnerSummary?.country_code,
      org_email: partnerSummary?.mailing_address?.org_email,
      mailing_type: partnerSummary?.mailing_address?.mailing_type,
      street: partnerSummary?.mailing_address?.street,
      po_box: partnerSummary?.mailing_address?.po_box,
      city: partnerSummary?.mailing_address?.city,
      zip_code: partnerSummary?.mailing_address?.zip_code,
    },
    flagTypes: getFlagTypesForFiltering(),
    riskCategories: getRiskCategories(),
    isAddSubnationalOfficeAllowed: FEATURES_ENABLED.special_partner.includes(state.session.agencyName),
    isSwitchToHqButtonVisible: !state.session.isHq && state.session.hqId,
    sessionHqId: state.session.hqId,
    sessionPartnerId: state.session.partnerId,
    userId: state.session.userId,
    flagTypesOptions: adaptFlagTypes(state.partnerProfileConfig["flag-type-choices"]),
    categoryRisks: adaptCategoryRisks(state.partnerProfileConfig["flag-category-choices"]),
    isValidObservationForm: isValid("observationModalForm")(state),
    observationFormValues: state.form?.observationModalForm?.values ?? {},
    hasViewPseaObservationsPermission: role === ROLES.AGENCY ? checkPermission(PARTNER_PERMISSIONS.VIEW_PSEA_OBSERVATIONS, state) : false,
    sanctionMatchDetails: state?.sanctionMatchDetails?.data?.data,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPartnerSummary: () => dispatch(getPartnerSummaryDetails(undefined, { id: ownProps.match.params.id })),
  getPartnerProfileData: () => dispatch(getPartnerProfileDataDetails(undefined, { id: ownProps.match.params.id })),
  getPartnerProfileVerifications: () =>
    dispatch(
      getPartnerProfileVerificationsList(undefined, {
        id: ownProps.match.params.id,
      }),
    ),
  getCountries: () => dispatch(getCountriesList()),
  getAllPartnerVerifications: () =>
    dispatch(
      getPartnerVerificationsFullList(undefined, {
        id: ownProps.match.params.id,
      }),
    ),
  getPartnerFlags: (params) => dispatch(getPartnerFlagsList(params, { id: ownProps.match.params.id })),
  getPartnerApplications: (params) =>
    dispatch(
      getPartnerApplicationsList({
        ...params,
        partner: ownProps.match.params.id,
      }),
    ),
  getPartnerMembers: (params) => dispatch(getPartnerMembersList(params, { id: ownProps.match.params.id })),
  updateSpecialPartner: (params) => dispatch(updateSpecialPartner(params, { id: ownProps.match.params.id })),
  createSpecialPartner: (params) => dispatch(createSpecialPartner(params)),
  getSpecialPartnerSubNationals: () =>
    dispatch(
      getSpecialPartnerSubNationalsList(undefined, {
        id: ownProps.match.params.id,
      }),
    ),
  resetSpecialPartnerForm: () => dispatch(reset("specialPartnerModalForm")),
  resetObservationForm: () => dispatch(reset("observationModalForm")),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
  getSectors: (params) => dispatch(getSectorsList(params)),
  deleteSpecialPartner: (id) => dispatch(deleteSpecialPartner({ id })),
  updatePartnerFlag: (id, flagId, body) => dispatch(updatePartnerFlag(body, { id, flagId })),
  updateSanctionMatch: (body) => dispatch(updateSanctionMatch(body, { id: ownProps.match.params.id })),
  getSanctionMatch: (id) => dispatch(getSanctionMatch(undefined, { id })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerDetails));

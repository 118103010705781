const destroy =
  (resource) =>
  (state = { data: {}, loading: false, errorData: undefined }, action) => {
    switch (action.type) {
      case `${resource}_START`:
        return { data: {}, loading: true, errorData: undefined };
      case `${resource}_END`:
        return { data: action.data, loading: false, errorData: undefined };
      case `${resource}_ERROR`:
        return { data: {}, loading: false, errorData: action.errorData };
      default:
        return state;
    }
  };

export default destroy;

import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import messages_en from "../../translations/en.json";
import messages_es from "../../translations/es.json";
import messages_fr from "../../translations/fr.json";
import { loadLocale } from "../../legacy-reducers/localeReducer";
import { connect } from "react-redux";
import Routes from "../../routes";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { initializeZendesk } from "./utils";

import "@local/styles/index.css";
import "react-toastify/dist/ReactToastify.css";

require("@formatjs/intl-pluralrules/polyfill");
require("@formatjs/intl-pluralrules/locale-data/en");
require("@formatjs/intl-pluralrules/locale-data/es");
require("@formatjs/intl-pluralrules/locale-data/fr");

// Any change in this variable should be added in ./helpers/i18nHelter.js translatedString()
const messages = {
  en: messages_en,
  es: messages_es,
  fr: messages_fr,
};

class Application extends Component {
  componentWillMount() {
    initializeZendesk(this.props.lang, this.props.name, this.props.email);
    this.props.loadLocale();
  }

  componentDidUpdate() {
    initializeZendesk(this.props.lang, this.props.name, this.props.email);
  }

  render() {
    const { lang } = this.props;
    return (
      <IntlProvider textComponent={React.Fragment} locale={lang} messages={messages[lang]}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Routes />
          <ToastContainer position="bottom-left" autoClose={2000} />
        </LocalizationProvider>
      </IntlProvider>
    );
  }
}

Application.propTypes = {
  lang: PropTypes.string,
  loadLocale: PropTypes.func,
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
  email: state.session.email,
  name: state.session.name,
});

const mapDispatchToProps = (dispatch) => ({
  loadLocale: () => dispatch(loadLocale()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);

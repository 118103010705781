import React from "react";
import { useParams } from "react-router-dom";
import Main from "./components/main";

const Psea = () => {
  const { id } = useParams();
  return <Main partnerId={id} />;
};

Psea.propTypes = {};

export default Psea;

import { formatMessage } from "@local/legacy-utils/i18nHelper";
export const auditDetailsNav = [
  {
    id: 0,
    path: "documents",
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.constants.auditDetailsTabDocuments",
    }),
    name: "documents",
  },
  {
    id: 1,
    path: "key-findings",
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.constants.auditDetailsTabKeyFindings",
    }),
    name: "keyFindings",
  },
  {
    id: 2,
    path: "ineligible-expenses",
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.constants.auditDetailsTabIneligibleExpenses",
    }),
    name: "ineligibleExpenses",
  },
  {
    id: 3,
    path: "compliance",
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.constants.auditDetailsTabCompliance",
    }),
    name: "compliance",
  },
  {
    id: 4,
    path: "recommendations",
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.constants.auditDetailsTabRecommendations",
    }),
    name: "recommendations",
  },
];

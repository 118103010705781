import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";

const Check = ({ isChecked }) => {
  return (
    <>
      {isChecked ? (
        <Typography variant="subtitle2" component="div" className={style.item}>
          <CheckIcon fontSize="small" className={style.checkedIcon} />
          {formatMessage({ id: "cmp.check.idx.yes" })}
        </Typography>
      ) : (
        <Typography variant="subtitle2" component="div" className={style.item}>
          <CloseIcon fontSize="small" className={style.notCheckedIcon} />
          {formatMessage({ id: "cmp.check.idx.no" })}
        </Typography>
      )}
    </>
  );
};

Check.propTypes = {};

export default Check;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Button from "@local/components/button";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { connect } from "react-redux";
import { checkPermission, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { useHistory } from "react-router-dom";
import { getSanctionMatch } from "@local/actions";
import { RISK_FLAGS_REASONS } from "@local/legacy-utils/constants";
import { normalizeDateFormat } from "@local/legacy-utils/dates";

const getSanctionMatchContent = (sanctions_status) => {
  if (sanctions_status === "confirmed_match") {
    return <span className={style.confirmedMatchFoundLabel}>{formatMessage({ id: "cmp.flags.list.idx.confirmedMatchFoundLabel" })}</span>;
  }
  if (sanctions_status === "potential_match") {
    return <span className={style.potentialMatchFoundLabel}>{formatMessage({ id: "cmp.flags.list.idx.potentialMatchFoundLabel" })}</span>;
  }
  return <span className={style.noMatchFoundLabel}>{formatMessage({ id: "cmp.flags.list.idx.noMatchFoundLabel" })}</span>;
};

const getLastScanDateTime = () => {
  let result = new Date();
  result.setDate(result.getDate() - result.getDay() === 1 ? 7 : (result.getDay() + 6) % 7);
  result.setHours(12);
  result.setMinutes(0);
  const today = new Date();
  if (today > result) {
    result = today;
    result.setHours(12);
    result.setMinutes(0);
  }
  return `${result.toLocaleDateString(undefined, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  })}, ${result.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  })}`;
};

const FlagsList = ({ partner, hasPermissionViewFlagCount, partnerId, onClickOpenVerifyMatchModal, sanctionMatchDetails }) => {
  const history = useHistory();

  const onClickViewAllFlags = () => history.push(`/partner/${partnerId}/risk/flag`);

  const onClickViewAllObservations = () => history.push(`/partner/${partnerId}/risk/observations`);

  if (!hasPermissionViewFlagCount) return <React.Fragment></React.Fragment>;

  const flagsCounters = partner.partner_additional
    ? partner?.partner_additional?.risk_flag_count
    : {
        performance_delivery_issues: 0,
        management_administrative_issues: 0,
        fraud_or_corruption: 0,
        safeguarding_violation: 0,
        others: 0,
      };
  const observationCount = partner?.partner_additional?.observation_count ?? 0;
  const sanctions_status = partner?.partner_additional?.sanctions_status ?? undefined;

  return (
    <div className={style.sectionsWrapper}>
      <div className={style.sectionWrapper}>
        <div className={style.sectionWrapperTitle}>
          <div className={style.sectionTitleWrapper}>
            <span>{formatMessage({ id: "cmp.flags.list.idx.flagStatus" })}</span>
          </div>
          <div className={style.flagCountWrapper}>
            <span>{observationCount}</span>
          </div>
        </div>
        <div className={style.flagFooter}>
          <Button text={formatMessage({ id: "cmp.flags.list.idx.button" })} variant="text" onClick={onClickViewAllObservations} />
        </div>
      </div>
      <div className={style.sectionWrapper}>
        <div className={style.sectionTitleWrapper}>
          <span>{formatMessage({ id: "cmp.flags.list.idx.flagsLabel" })}</span>
        </div>
        <div className={style.sectionContentWrapper}>
          {RISK_FLAGS_REASONS?.map((item) => (
            <div className={style.flagRowWrapper}>
              <div className={style.flagTextWrapper}>
                <span>{item?.label}</span>
              </div>
              <div className={style.flagCountWrapper}>
                <span>{flagsCounters[item?.value]}</span>
              </div>
            </div>
          ))}
        </div>
        <div className={style.flagFooter}>
          <Button text={formatMessage({ id: "cmp.flags.list.idx.button" })} variant="text" onClick={onClickViewAllFlags} />
        </div>
      </div>
      <div className={style.sectionWrapper}>
        <div className={style.sectionTitleWrapper}>
          <span>{formatMessage({ id: "cmp.flags.list.idx.sanctionsLabel" })}</span>
          <div className={style.tooltipWrapper}>
            <Tooltip
              title={formatMessage({
                id: "cmp.flags.list.idx.sanctionsLabelInformationText",
              })}
            >
              <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
            </Tooltip>
          </div>
        </div>
        <div className={style.sectionContentWrapper}>
          <div className={style.sanctionWrapper}>
            {getSanctionMatchContent(sanctions_status)}
            {sanctions_status === "potential_match" ? (
              <div>
                <Button
                  text={formatMessage({
                    id: "cmp.flags.list.idx.validateMatch",
                  })}
                  variant="outlined"
                  onClick={onClickOpenVerifyMatchModal}
                />
              </div>
            ) : sanctions_status === "confirmed_match" ? (
              <div>
                <Button text={formatMessage({ id: "cmp.flags.list.idx.viewMatch" })} variant="outlined" onClick={onClickOpenVerifyMatchModal} />
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
      <div className={style.footerWrapper}>
        <div>
          <span className={style.lastScanLabel}>
            {formatMessage({ id: "cmp.flags.list.idx.lastScan" })} {normalizeDateFormat(sanctionMatchDetails?.created)}
          </span>
        </div>
      </div>
    </div>
  );
};

FlagsList.propTypes = {};

const mapStateToProps = (state) => ({
  hasPermissionViewFlagCount: checkPermission(AGENCY_PERMISSIONS.VIEW_PROFILE_OBSERVATION_FLAG_COUNT, state),
  sanctionMatchDetails: state?.sanctionMatchDetails?.data?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getSanctionMatch: (id) => dispatch(getSanctionMatch(undefined, { id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FlagsList);

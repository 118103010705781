import React, { useEffect, useMemo } from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import imageLogo from "@local/assets/images/login-page-logo.png";
import imageBackground from "@local/assets/images/login-page-background.jpg";
import imageUn from "@local/assets/images/login-page-un.png";
import imageFao from "@local/assets/images/login-page-fao.png";
import imageWho from "@local/assets/images/login-page-who.png";
import imageUnfpa from "@local/assets/images/login-page-unfpa.png";
import imageUnhcr from "@local/assets/images/login-page-unhcr.png";
import imageUnicef from "@local/assets/images/login-page-unicef.png";
import imageUnwomen from "@local/assets/images/login-page-unwomen.png";
import imageIom from "@local/assets/images/login-page-iom.png";
import imageUnops from "@local/assets/images/login-page-unops.png";
import imageWfp from "@local/assets/images/login-page-wfp.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Button from "@local/components/button";
import { reduxForm, Field, change } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldAutosuggest from "@local/components/field-autosuggest/";
import FieldText from "@local/components/field-text";
import { isBetween, isRequired, isValidDate, isValidEmail } from "@local/utils/validators";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Divider from "@mui/material/Divider";
import Alert from "@local/components/alert";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldDatePicker from "@local/components/field-date-picker";
import FieldFile from "@local/components/field-file";
import FieldCheckbox from "@local/components/field-checkbox";
import Logout from "@mui/icons-material/Logout";
import LanguageTrigger from "@local/components/language-trigger";
import FieldListInput from "@local/components/field-list-input";
import { adaptPartnersOptions } from "../../utils/adapters";
import PartnerDeclarationQuestion from "@local/components/partner-declaration-question";
import { getPartnerDeclarationQuestions } from "@local/utils/adapters";
import { toast } from "react-toastify";
import i18n from "@local/i18next/i18n";

const getContentByCurrentStep = (
  currentStep,
  onClickContinueAsPartner,
  getPartnersBasicInformation,
  onClickRequestPartnerAccess,
  onClickCreateNewPartnerProfile,
  onClickCreateNewAgencyMember,
  fullName,
  onClickSendNewRequest,
  newPartnerCurrentStep,
  onClickNextStep,
  onClickPreviousStep,
  partnerTypes,
  countries,
  formValues,
  onChangeIsHq,
  isYearBetween,
  agencyCountryOffices,
) => {
  const isPartnerDeclarationDisabled =
    !formValues?.ad_1 ||
    formValues?.ad_1 === "false" ||
    !formValues?.ad_2 ||
    formValues?.ad_2 === "false" ||
    !formValues?.ad_3 ||
    formValues?.ad_3 === "false" ||
    !formValues?.ad_4 ||
    formValues?.ad_4 === "false" ||
    !formValues?.ad_5 ||
    formValues?.ad_5 === "false" ||
    !formValues?.ad_6 ||
    formValues?.ad_6 === "false" ||
    !formValues?.ad_7 ||
    formValues?.ad_7 === "false" ||
    !formValues?.ad_8 ||
    formValues?.ad_8 === "false" ||
    !formValues?.ad_9 ||
    formValues?.ad_9 === "false" ||
    !formValues?.ad_10 ||
    formValues?.ad_10 === "false" ||
    !formValues?.ad_11 ||
    formValues?.ad_11 === "false" ||
    !formValues?.ad_12 ||
    formValues?.ad_12 === "false";

  const stepOneSave = () => {
    localStorage.setItem("displayType", JSON.stringify(formValues?.display_type));
    if (formValues?.is_hq) {
      localStorage.setItem("isHq", formValues?.is_hq);
    }
    toast.success(i18n.t("Your changes have been saved"));
  };
  const stepTwoSave = () => {
    localStorage.setItem("legalName", JSON.stringify(formValues?.legal_name ? formValues?.legal_name : ""));
    localStorage.setItem("otherNames", JSON.stringify(formValues?.other_names ? formValues?.other_names : ""));
    localStorage.setItem("countryCode", JSON.stringify(formValues?.country_code ? formValues?.country_code : ""));
    localStorage.setItem("headOrganizationFullname", JSON.stringify(formValues?.head_organization_fullname ? formValues?.head_organization_fullname : ""));
    localStorage.setItem("headOrganizationEmail", JSON.stringify(formValues?.head_organization_email ? formValues?.head_organization_email : ""));
    toast.success(i18n.t("Your changes have been saved"));
  };

  const stepThreeSave = () => {
    localStorage.setItem("yearEstablishment", JSON.stringify(formValues?.year_establishment ? formValues?.year_establishment : null));
    localStorage.setItem("registeredToOperateInCountry", formValues?.registered_to_operate_in_country !== null ? formValues?.registered_to_operate_in_country : null);
    if (formValues?.registered_to_operate_in_country === "true" || formValues?.registered_to_operate_in_country === true) {
      localStorage.setItem("registrationDocument", JSON.stringify(formValues?.registration_document ? formValues?.registration_document : null));
      localStorage.setItem("issuingAuthority", JSON.stringify(formValues?.issuing_authority ? formValues?.issuing_authority : ""));
      localStorage.setItem("issueDate", JSON.stringify(formValues?.issue_date ? formValues?.issue_date : null));
      localStorage.setItem("registrationNumber", JSON.stringify(formValues?.registration_number ? formValues?.registration_number : ""));
    } else {
      localStorage.setItem("missingRegistrationDocumentComment", JSON.stringify(formValues?.missing_registration_document_comment ? formValues?.missing_registration_document_comment : ""));
    }
    localStorage.setItem("haveGoverningDocument", formValues?.have_governing_document !== null ? formValues?.have_governing_document : null);
    if (formValues?.have_governing_document === "true" || formValues?.have_governing_document === true) {
      localStorage.setItem("governingDocument", JSON.stringify(formValues?.governing_document ? formValues?.governing_document : null));
    } else {
      localStorage.setItem("missingGoverningDocumentComment", JSON.stringify(formValues?.missing_governing_document_comment ? formValues?.missing_governing_document_comment : ""));
    }
    toast.success(i18n.t("Your changes have been saved"));
  };

  const stepFourSave = () => {
    localStorage.setItem("ad1", formValues?.ad_1 !== null ? formValues?.ad_1 : null);
    localStorage.setItem("ad2", formValues?.ad_2 !== null ? formValues?.ad_2 : null);
    localStorage.setItem("ad3", formValues?.ad_3 !== null ? formValues?.ad_3 : null);
    localStorage.setItem("ad4", formValues?.ad_4 !== null ? formValues?.ad_4 : null);
    localStorage.setItem("ad5", formValues?.ad_5 !== null ? formValues?.ad_5 : null);
    localStorage.setItem("ad6", formValues?.ad_6 !== null ? formValues?.ad_6 : null);
    localStorage.setItem("ad7", formValues?.ad_7 !== null ? formValues?.ad_7 : null);
    localStorage.setItem("ad8", formValues?.ad_8 !== null ? formValues?.ad_8 : null);
    localStorage.setItem("ad9", formValues?.ad_9 !== null ? formValues?.ad_9 : null);
    localStorage.setItem("ad10", formValues?.ad_10 !== null ? formValues?.ad_10 : null);
    localStorage.setItem("ad11", formValues?.ad_11 !== null ? formValues?.ad_11 : null);
    localStorage.setItem("ad12", formValues?.ad_12 !== null ? formValues?.ad_12 : null);
    toast.success(i18n.t("Your changes have been saved"));
  };

  switch (currentStep) {
    case "welcome":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.firstDescription}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.firstStepLabel",
              })}
            </span>
            <Button
              text={formatMessage({
                id: "pages.first.access.cmp.mn.idx.firstStepButton",
              })}
              onClick={onClickContinueAsPartner}
            />
          </div>
        </>
      );
    case "shell-profile":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.firstDescription}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.description",
              })}
            </span>
            <Button
              text={formatMessage({
                id: "pages.first.access.cmp.mn.idx.create",
              })}
              onClick={onClickCreateNewPartnerProfile}
            />
          </div>
        </>
      );
    case "agency-access":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.agencyFirstDescription}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.countryDialogInfo",
              })}
            </span>
          </div>
          <FieldGroup>
            <Field
              component={FieldSelect}
              validate={[isRequired]}
              id="agency_country_offices"
              name="agency_country_offices"
              label={formatMessage({
                id: "pages.first.access.cmp.mn.idx.countryOffice",
              })}
              countries
              placeholder={formatMessage({
                id: "pages.first.access.cmp.mn.idx.selectCountryOffice",
              })}
              options={agencyCountryOffices}
              valueField="value"
              labelField="label"
            />
            <div className={style.sendActionsWrapper}>
              <Button
                isDisabled={isRequired(formValues?.agency_country_offices) !== ""}
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.submit",
                })}
                onClick={onClickCreateNewAgencyMember}
              />
            </div>
          </FieldGroup>
        </>
      );
    case "partner-welcome":
      return (
        <>
          <div className={style.sectionWrapper}>
            <div className={style.sectionContent}>
              <span className={style.description}>
                {formatMessage({
                  id: "pages.first.access.cmp.mn.idx.newPartnerProfileLabel",
                })}
              </span>
              <Button
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.newPartnerProfileButton",
                })}
                onClick={onClickCreateNewPartnerProfile}
              />
            </div>
          </div>
          <Divider className={style.sectionWrapper}>OR</Divider>
          <div className={style.sectionWrapper}>
            <span className={style.description}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.sendRequestAdministrator",
              })}
            </span>
            <FieldGroup>
              <Field
                component={FieldAutosuggest}
                validate={[isRequired]}
                id="partner"
                name="partner"
                label={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.legalName",
                })}
                placeholder={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.legalName",
                })}
                fetchFunction={getPartnersBasicInformation}
                valueField="id"
                labelField="label"
                adapterFunction={adaptPartnersOptions}
              />
              <Field
                component={FieldText}
                validate={[isRequired, isValidEmail]}
                id="admin"
                name="admin"
                label={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.administratorEmail",
                })}
                placeholder={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.administratorEmail",
                })}
                type="email"
              />
            </FieldGroup>
            <div className={style.sendActionsWrapper}>
              <Button
                isDisabled={isRequired(formValues?.partner) !== "" || isValidEmail(formValues?.admin) !== ""}
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.sendRequest",
                })}
                onClick={onClickRequestPartnerAccess}
              />
            </div>
          </div>
        </>
      );
    case "partner-steps":
      return (
        <>
          <div className={style.sectionWrapper}>
            <Stepper activeStep={newPartnerCurrentStep} orientation="vertical">
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.selectType",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <div className={style.alertWrapper}>
                      <Alert
                        content={
                          <span>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.informationText",
                            })}{" "}
                            <a
                              target="_blank"
                              href="https://unicef.org/"
                              alt={formatMessage({
                                id: "pages.first.access.cmp.mn.idx.learnMore",
                              })}
                              rel="noreferrer"
                            >
                              {formatMessage({
                                id: "pages.first.access.cmp.mn.idx.learnMore",
                              })}
                            </a>
                          </span>
                        }
                      />
                    </div>
                    <FieldGroup>
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id="display_type"
                        name="display_type"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.projectDetailsType",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.projectDetailsType",
                        })}
                        valueField="value"
                        labelField="label"
                        options={partnerTypes}
                        informationText={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.tooltip",
                        })}
                      />
                      {formValues?.display_type === "Int" ? (
                        <Field
                          component={FieldRadioGroup}
                          validate={[isRequired]}
                          small
                          id="is_hq"
                          name="is_hq"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.office",
                          })}
                          onChangeExtra={onChangeIsHq}
                          options={[
                            {
                              value: true,
                              label: formatMessage({
                                id: "pages.first.access.cmp.mn.idx.headquarters",
                              }),
                            },
                            {
                              value: false,
                              label: formatMessage({
                                id: "pages.first.access.cmp.mn.idx.country",
                              }),
                            },
                          ]}
                        />
                      ) : undefined}
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button
                      isDisabled={isRequired(formValues?.display_type) !== "" || (formValues?.display_type === "Int" && isRequired(formValues?.is_hq) !== "")}
                      text={i18n.t("Continue")}
                      onClick={() => {
                        stepOneSave();
                        onClickNextStep();
                      }}
                    />
                    <Button isDisabled={isRequired(formValues?.display_type) !== "" || (formValues?.display_type === "Int" && isRequired(formValues?.is_hq) !== "")} text={i18n.t("Save")} onClick={stepOneSave} />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.basicInformation",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <FieldGroup>
                      <Field
                        component={FieldText}
                        validate={[isRequired]}
                        id="legal_name"
                        name="legal_name"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.legalNameOne",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.legalNameOne",
                        })}
                        type="text"
                      />
                      <Field
                        component={FieldListInput}
                        id="other_names"
                        name="other_names"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.otherNames",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.otherNames",
                        })}
                        emptyPlaceholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.otherNamesEmpty",
                        })}
                      />
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id="country_code"
                        name="country_code"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.countryOne",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.countryOne",
                        })}
                        valueField="value"
                        labelField="label"
                        options={countries}
                        informationText={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.tooltipOne",
                        })}
                      />
                      <Field
                        component={FieldText}
                        validate={[isRequired]}
                        id="head_organization_fullname"
                        name="head_organization_fullname"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.fullName",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.fullName",
                        })}
                      />
                      <Field
                        component={FieldText}
                        validate={[isRequired, isValidEmail]}
                        id="head_organization_email"
                        name="head_organization_email"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.email",
                        })}
                        placeholder={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.email",
                        })}
                        type="email"
                      />
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button variant="text" text={i18n.t("Back")} onClick={onClickPreviousStep} />
                    <Button
                      isDisabled={
                        isRequired(formValues?.legal_name) !== "" ||
                        isRequired(formValues?.country_code) !== "" ||
                        isRequired(formValues?.head_organization_fullname) !== "" ||
                        isRequired(formValues?.head_organization_email) !== "" ||
                        isValidEmail(formValues?.head_organization_email) !== ""
                      }
                      text={i18n.t("Continue")}
                      onClick={() => {
                        stepTwoSave();
                        onClickNextStep();
                      }}
                    />
                    <Button
                      isDisabled={formValues?.legal_name === "" || formValues?.country_code === "" || formValues?.head_organization_fullname === "" || formValues?.head_organization_email === "" || formValues?.head_organization_email === ""}
                      text={i18n.t("Save")}
                      onClick={stepTwoSave}
                    />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.legalStatus",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <FieldGroup>
                      <Field
                        component={FieldText}
                        validate={[isRequired, isYearBetween]}
                        id="year_establishment"
                        name="year_establishment"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.yearEstablishment",
                        })}
                        type="number"
                      />
                      <Field
                        component={FieldRadioGroup}
                        validate={[isRequired]}
                        small
                        fullWidthLabel
                        id="registered_to_operate_in_country"
                        name="registered_to_operate_in_country"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.registrationCountry",
                        })}
                        options={[
                          {
                            value: true,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.yes",
                            }),
                          },
                          {
                            value: false,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.no",
                            }),
                          },
                        ]}
                      />
                      {formValues?.registered_to_operate_in_country?.toString() === "true" ? (
                        <>
                          <Field
                            component={FieldFile}
                            validate={[isRequired]}
                            isLocalFile
                            id="registration_document"
                            name="registration_document"
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.document",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.document",
                            })}
                          />
                          <Field
                            component={FieldText}
                            validate={[isRequired]}
                            id="issuing_authority"
                            name="issuing_authority"
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.name",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.name",
                            })}
                            type="text"
                          />
                          <Field
                            component={FieldDatePicker}
                            validate={[isRequired, isValidDate]}
                            id="issue_date"
                            name="issue_date"
                            maximumDate={new Date()}
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.date",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.date",
                            })}
                          />
                          <Field
                            component={FieldText}
                            validate={[isRequired]}
                            id="registration_number"
                            name="registration_number"
                            label={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.number",
                            })}
                            placeholder={formatMessage({
                              id: "pages.first.access.cmp.mn.idx.number",
                            })}
                            type="text"
                            maxLength={50}
                          />
                        </>
                      ) : undefined}
                      {formValues?.registered_to_operate_in_country === "false" ? (
                        <Field
                          component={FieldText}
                          validate={[isRequired]}
                          id="missing_registration_document_comment"
                          name="missing_registration_document_comment"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          placeholder={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          type="text"
                          maxLength={50}
                        />
                      ) : undefined}
                      <Field
                        component={FieldRadioGroup}
                        validate={[isRequired]}
                        small
                        fullWidthLabel
                        id="have_governing_document"
                        name="have_governing_document"
                        label={formatMessage({
                          id: "pages.first.access.cmp.mn.idx.haveGovDoc",
                        })}
                        options={[
                          {
                            value: true,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.yes",
                            }),
                          },
                          {
                            value: false,
                            label: formatMessage({
                              id: "pages.first.access.cmp.mn.idx.no",
                            }),
                          },
                        ]}
                      />
                      {formValues?.have_governing_document?.toString() === "true" ? (
                        <Field
                          component={FieldFile}
                          validate={[isRequired]}
                          isLocalFile
                          id="governing_document"
                          name="governing_document"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.button",
                          })}
                          placeholder={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.button",
                          })}
                          informationText={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.governingDocument",
                          })}
                        />
                      ) : undefined}
                      {formValues?.have_governing_document === "false" ? (
                        <Field
                          component={FieldText}
                          validate={[isRequired]}
                          id="missing_governing_document_comment"
                          name="missing_governing_document_comment"
                          label={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          placeholder={formatMessage({
                            id: "pages.first.access.cmp.mn.idx.comment",
                          })}
                          type="text"
                          maxLength={50}
                        />
                      ) : undefined}
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button variant="text" text={i18n.t("Back")} onClick={onClickPreviousStep} />
                    <Button
                      isDisabled={
                        isRequired(formValues?.year_establishment) !== "" ||
                        isBetween(1000, new Date().getFullYear())(formValues?.year_establishment) !== "" ||
                        isRequired(formValues?.have_governing_document) !== "" ||
                        isRequired(formValues?.registered_to_operate_in_country) !== "" ||
                        (formValues?.registered_to_operate_in_country?.toString() === "true" &&
                          (isRequired(formValues?.registration_document) !== "" ||
                            isRequired(formValues?.issuing_authority) !== "" ||
                            isRequired(formValues?.issue_date) !== "" ||
                            isRequired(formValues?.registration_number) !== "" ||
                            isValidDate(formValues?.issue_date) !== "")) ||
                        (formValues?.registered_to_operate_in_country === "false" && isRequired(formValues?.missing_registration_document_comment) !== "") ||
                        (formValues?.have_governing_document?.toString() === "true" && isRequired(formValues?.governing_document) !== "") ||
                        (formValues?.have_governing_document === "false" && isRequired(formValues?.missing_governing_document_comment) !== "")
                      }
                      text={i18n.t("Continue")}
                      onClick={() => {
                        stepThreeSave();
                        onClickNextStep();
                      }}
                    />
                    <Button isDisabled={formValues?.year_establishment === ""} text={i18n.t("Save")} onClick={stepThreeSave} />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.partnerDeclaration",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <span className={style.stepDescription}>
                      {formatMessage({
                        id: "pages.first.access.cmp.mn.idx.partnerDeclarationLabel",
                      })}
                    </span>
                    <>
                      {getPartnerDeclarationQuestions(formValues)?.map((item) =>
                        item?.isVisible ? <PartnerDeclarationQuestion id={item?.id} text={item?.content} nameField={item?.nameField} isPartnerDeclarationRequired={item?.errorMessage} formValues={formValues} /> : undefined,
                      )}
                      {!isPartnerDeclarationDisabled && formValues.ad_12 === "true" ? (
                        <div className={style.extraInfoWrapper}>
                          <span className={style.stepDescription}>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.part1",
                            })}
                          </span>
                          <span className={style.stepDescription}>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.part2",
                            })}
                          </span>
                          <span className={style.stepDescription}>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.part3",
                            })}
                          </span>
                        </div>
                      ) : undefined}
                    </>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button variant="text" text={i18n.t("Back")} onClick={onClickPreviousStep} />
                    <Button
                      isDisabled={isPartnerDeclarationDisabled}
                      text={i18n.t("Continue")}
                      onClick={() => {
                        stepFourSave();
                        onClickNextStep();
                      }}
                    />
                    <Button text={i18n.t("Save")} onClick={stepFourSave} />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>
                  {formatMessage({
                    id: "pages.first.access.cmp.mn.idx.termsTitle",
                  })}
                </StepLabel>
                <StepContent>
                  <div className={style.stepWrapper}>
                    <FieldGroup>
                      <Field
                        component={FieldCheckbox}
                        small
                        fullWidthLabel
                        validate={[isRequired]}
                        id="term_of_use"
                        name="term_of_use"
                        label={
                          <>
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.terms1",
                            })}{" "}
                            <a
                              target="_blank"
                              href="https://unpartnerportalcso.zendesk.com/hc/en-us/article_attachments/360018648633/UN_Partner_Portal_Terms_of_Use.pdf"
                              alt={formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink1",
                              })}
                              rel="noreferrer"
                            >
                              {formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink1",
                              })}
                            </a>{" "}
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.terms2",
                            })}{" "}
                            <a
                              target="_blank"
                              href="https://unpartnerportalcso.zendesk.com/hc/en-us/article_attachments/360017929314/UN_Partner_Portal_Privacy_Policy_.pdf"
                              alt={formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink2",
                              })}
                              rel="noreferrer"
                            >
                              {formatMessage({
                                id: "pages.first.access.cmp.mn.idx.termsLink2",
                              })}
                            </a>{" "}
                            {formatMessage({
                              id: "pages.first.access.cmp.mn.idx.terms3",
                            })}
                          </>
                        }
                      />
                    </FieldGroup>
                  </div>
                  <div className={style.actionsWrapper}>
                    <Button variant="text" text={i18n.t("Back")} onClick={onClickPreviousStep} />
                    <Button isDisabled={formValues?.term_of_use !== true} text={i18n.t("Register")} onClick={onClickNextStep} />
                  </div>
                </StepContent>
              </Step>
            </Stepper>
          </div>
        </>
      );
    case "welcome-confirmation":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.description}>
              {formatMessage({
                id: "pages.first.access.cmp.mn.idx.sentRequestLabel",
              })}{" "}
              <b>{fullName}</b>
            </span>
            <Button
              text={formatMessage({
                id: "pages.first.access.cmp.mn.idx.sendNewRequest",
              })}
              onClick={onClickSendNewRequest}
            />
          </div>
        </>
      );
    case "welcome-agency":
      return (
        <>
          <div className={style.sectionWrapper}>
            <span className={style.firstDescription}>{formatMessage({ id: "pages.first.access.cmp.mn.idx.helpInfo" })}</span>
            <FieldGroup>
              <Field
                component={FieldAutosuggest}
                validate={[isRequired]}
                id=""
                name=""
                label={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.countryOffices",
                })}
                placeholder={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.countryOffices",
                })}
                fetchFunction={[]}
                valueField="value"
                labelField="label"
              />
              <Button
                text={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.registerOne",
                })}
                onClick={() => {}}
              />
            </FieldGroup>
          </div>
        </>
      );
    default:
      return <></>;
  }
};

const Main = ({
  onClickBack,
  currentStep,
  onClickContinueAsPartner,
  getPartnersBasicInformation,
  onClickRequestPartnerAccess,
  onClickCreateNewPartnerProfile,
  onClickCreateNewAgencyMember,
  fullName,
  onClickSendNewRequest,
  newPartnerCurrentStep,
  onClickNextStep,
  onClickPreviousStep,
  partnerTypes,
  countries,
  formValues,
  onChangeIsHq,
  onClickLogout,
  role,
  agencyCountryOffices,
  dispatch,
}) => {
  const isYearBetween = useMemo(() => isBetween(1000, new Date().getFullYear()), []);

  useEffect(() => {
    const displayType = localStorage.getItem("displayType");
    if (displayType) {
      const savedVal = partnerTypes.find((obj) => obj.value === JSON.parse(displayType));
      dispatch(change("firstAccessForm", "display_type", savedVal.value));
    }
    const is_hq = localStorage.getItem("isHq");
    if (is_hq) {
      const savedVal = is_hq === "true" ? true : false;
      dispatch(change("firstAccessForm", "is_hq", savedVal));
    }
    const legal_name = localStorage.getItem("legalName");
    if (legal_name) {
      dispatch(change("firstAccessForm", "legal_name", JSON.parse(legal_name)));
    }
    const other_names = localStorage.getItem("otherNames");
    if (other_names) {
      dispatch(change("firstAccessForm", "other_names", JSON.parse(other_names)));
    }
    const country_code = localStorage.getItem("countryCode");
    if (country_code && countries.length > 0) {
      const savedVal = countries.find((obj) => obj.value === JSON.parse(country_code));
      dispatch(change("firstAccessForm", "country_code", savedVal?.value));
    }
    const full_name = localStorage.getItem("headOrganizationFullname");
    if (full_name) {
      dispatch(change("firstAccessForm", "head_organization_fullname", JSON.parse(full_name)));
    }
    const email = localStorage.getItem("headOrganizationEmail");
    if (email) {
      dispatch(change("firstAccessForm", "head_organization_email", JSON.parse(email)));
    }
    const year_establishment = localStorage.getItem("yearEstablishment");
    if (year_establishment) {
      dispatch(change("firstAccessForm", "year_establishment", JSON.parse(year_establishment)));
    }
    const registered_to_operate_in_country = localStorage.getItem("registeredToOperateInCountry");
    if (registered_to_operate_in_country !== "null") {
      dispatch(change("firstAccessForm", "registered_to_operate_in_country", registered_to_operate_in_country));
    }
    const registration_document = localStorage.getItem("registrationDocument");
    if (registration_document) {
      dispatch(change("firstAccessForm", "registration_document", JSON.parse(registration_document)));
    }
    const issuing_authority = localStorage.getItem("issuingAuthority");
    if (issuing_authority) {
      dispatch(change("firstAccessForm", "issuing_authority", JSON.parse(issuing_authority)));
    }
    const issue_date = localStorage.getItem("issueDate");
    if (issue_date) {
      dispatch(change("firstAccessForm", "issue_date", new Date(JSON.parse(issue_date))));
    }
    const registration_number = localStorage.getItem("registrationNumber");
    if (registration_number) {
      dispatch(change("firstAccessForm", "registration_number", JSON.parse(registration_number)));
    }
    const have_governing_document = localStorage.getItem("haveGoverningDocument");
    if (have_governing_document !== "null") {
      dispatch(change("firstAccessForm", "have_governing_document", have_governing_document));
    }
    const governing_document = localStorage.getItem("governingDocument");
    if (governing_document) {
      dispatch(change("firstAccessForm", "governing_document", JSON.parse(governing_document)));
    }
    const missing_registration_document_comment = localStorage.getItem("missingRegistrationDocumentComment");
    if (missing_registration_document_comment) {
      dispatch(change("firstAccessForm", "missing_registration_document_comment", JSON.parse(missing_registration_document_comment)));
    }
    const missing_governing_document_comment = localStorage.getItem("missingGoverningDocumentComment");
    if (missing_governing_document_comment) {
      dispatch(change("firstAccessForm", "missing_governing_document_comment", JSON.parse(missing_governing_document_comment)));
    }
    const ad_1 = localStorage.getItem("ad1");
    if (ad_1 !== "null") {
      dispatch(change("firstAccessForm", "ad_1", ad_1));
    }
    const ad_2 = localStorage.getItem("ad2");
    if (ad_2 !== "null") {
      dispatch(change("firstAccessForm", "ad_2", ad_2));
    }
    const ad_3 = localStorage.getItem("ad3");
    if (ad_3 !== "null") {
      dispatch(change("firstAccessForm", "ad_3", ad_3));
    }
    const ad_4 = localStorage.getItem("ad4");
    if (ad_4 !== "null") {
      dispatch(change("firstAccessForm", "ad_4", ad_4));
    }
    const ad_5 = localStorage.getItem("ad5");
    if (ad_5 !== "null") {
      dispatch(change("firstAccessForm", "ad_5", ad_5));
    }
    const ad_6 = localStorage.getItem("ad6");
    if (ad_6 !== "null") {
      dispatch(change("firstAccessForm", "ad_6", ad_6));
    }
    const ad_7 = localStorage.getItem("ad7");
    if (ad_7 !== "null") {
      dispatch(change("firstAccessForm", "ad_7", ad_7));
    }
    const ad_8 = localStorage.getItem("ad8");
    if (ad_8 !== "null") {
      dispatch(change("firstAccessForm", "ad_8", ad_8));
    }
    const ad_9 = localStorage.getItem("ad9");
    if (ad_9 !== "null") {
      dispatch(change("firstAccessForm", "ad_9", ad_9));
    }
    const ad_10 = localStorage.getItem("ad10");
    if (ad_10 !== "null") {
      dispatch(change("firstAccessForm", "ad_10", ad_10));
    }
    const ad_11 = localStorage.getItem("ad11");
    if (ad_11 !== "null") {
      dispatch(change("firstAccessForm", "ad_11", ad_11));
    }
    const ad_12 = localStorage.getItem("ad12");
    if (ad_12 !== "null") {
      dispatch(change("firstAccessForm", "ad_12", ad_12));
    }
  }, [currentStep]);
  return (
    <div
      className={style.mainWrapper}
      style={{
        backgroundImage: `linear-gradient(0deg, #2296F3BB, #2296F3BB), url("${imageBackground}")`,
      }}
    >
      <div className={style.stackWrapper}>
        <div>
          <LanguageTrigger />
        </div>
        <div>
          <IconButton size="large" edge="end" onClick={onClickLogout} color="inherit">
            <Logout />
          </IconButton>
        </div>
      </div>
      <div className={style.contentWrapper}>
        <div className={style.layoutWrapper}>
          <div className={style.boxWrapper}>
            <div className={style.headerWrapper}>
              {currentStep !== "welcome" && currentStep !== "agency-access" ? (
                <div>
                  <IconButton onClick={onClickBack}>
                    <ChevronLeftIcon style={{ fontSize: "1.5rem", color: "#222222" }} />
                  </IconButton>
                </div>
              ) : undefined}
              <div>
                <span className={style.title}>{formatMessage({ id: "pages.first.access.cmp.mn.idx.title" })}</span>
                {role === "partner" ? (
                  <span className={style.subtitle}>
                    {formatMessage({
                      id: "pages.first.access.cmp.mn.idx.subtitle",
                    })}
                  </span>
                ) : undefined}
              </div>
            </div>
            <div className={style.formWrapper}>
              {getContentByCurrentStep(
                currentStep,
                onClickContinueAsPartner,
                getPartnersBasicInformation,
                onClickRequestPartnerAccess,
                onClickCreateNewPartnerProfile,
                onClickCreateNewAgencyMember,
                fullName,
                onClickSendNewRequest,
                newPartnerCurrentStep,
                onClickNextStep,
                onClickPreviousStep,
                partnerTypes,
                countries,
                formValues,
                onChangeIsHq,
                isYearBetween,
                agencyCountryOffices,
              )}
            </div>
          </div>
          <div className={style.logosWrapper}>
            <div className={style.unppLogoWrapper}>
              <img
                alt={formatMessage({
                  id: "pages.first.access.cmp.mn.idx.logoAltText",
                })}
                src={imageLogo}
              />
            </div>
            <div>
              <div className={style.agenciesLogosWrapper}>
                <img alt="IOM" src={imageIom} />
                <img alt="UN" src={imageUn} />
                <img alt="UN WOMEN" src={imageUnwomen} />
                <img alt="FAO" src={imageFao} />
                <img alt="UNFPA" src={imageUnfpa} />
                <img alt="UNHCR" src={imageUnhcr} />
                <img alt="UNICEF" src={imageUnicef} />
                <img alt="UNOPS" src={imageUnops} />
                <img alt="WFP" src={imageWfp} />
                <img alt="WHO" src={imageWho} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({ form: "firstAccessForm", enableReinitialize: true })(Main);

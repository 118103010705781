import React, { useState } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const FieldPassword = ({ id, label, placeholder, helperText, input: { value, onChange } }) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const hideShowPassword = () => setShowPassword(false);
  return (
    <div>
      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <OutlinedInput
          onBlur={hideShowPassword}
          id={id}
          value={value}
          onChange={onChange}
          label={label}
          placeholder={placeholder}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={toggleShowPassword} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {helperText ? <FormHelperText id={`field-text-helper-text-${id}`}>{helperText}</FormHelperText> : <></>}
      </FormControl>
    </div>
  );
};

FieldPassword.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

FieldPassword.defaultProps = {
  placeholder: undefined,
  helperText: undefined,
  type: "text",
};

export default FieldPassword;

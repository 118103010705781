/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import Box from "@local/components/box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { WrapperComponent } from "../../utils";
import { isRequired, isValidEmail, isValidPhoneNumber, isValidUrl } from "@local/utils/validators";
import { getKeyName } from "@local/utils";

// POST_REFACTORING_TODO: The logic behind array of fields (so, initial state, add and remove, etc)
// should be handled by a custom hook, same for other pages and tabs too,
// like partner edit or manage project modal forms

const ContactInformation = ({ isLoading, stateValues, formValues, partner, onSubmitAndContinue, onSubmitAndExit, countries, languages, inEditMode, jobTitleTypes }) => {
  const [boardDirectors, setBoardDirectors] = useState(undefined);

  useEffect(() => {
    if (stateValues?.boardDirectors && !boardDirectors) setBoardDirectors(stateValues.boardDirectors);
  }, [stateValues?.boardDirectors]);

  const onClickAddBoardDirector = () => {
    const updatedBoardDirectors = [
      ...boardDirectors,
      {
        id: boardDirectors.length ? boardDirectors.sort((a, b) => a.id > b.id)[boardDirectors.length - 1].id + 1 : 1,
        originalId: undefined,
      },
    ];
    setBoardDirectors(updatedBoardDirectors);
  };

  const onClickRemoveBoardDirector = (toRemove) => {
    const updatedBoardDirectors = boardDirectors.filter((item) => item.id !== toRemove.id);
    setBoardDirectors(updatedBoardDirectors);
  };

  const onClickSaveAndContinue = () => {
    onSubmitAndContinue({ boardDirectors });
  };

  const onClickSaveAndExit = () => {
    onSubmitAndExit({ boardDirectors });
  };

  if (isLoading || !boardDirectors) return <CircularProgress size={50} />;

  const haveJobTitleOther = boardDirectors.map((item, index) => (formValues[getKeyName("board_director_job_title", index)]?.includes("other") ? true : false));

  const haveHqOrganisationHeadsJobTitleOther = formValues?.hq_head_of_organization_job_title?.includes("other") ? true : false;

  const haveHqOrganizationHeads = partner ? partner?.hq_head_of_organization?.length || false : false;

  return (
    <WrapperComponent
      inEditMode={inEditMode}
      title={formatMessage({
        id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.contactInformation",
      })}
      footer={
        <div className={style.cardActionsWrapper}>
          <Button
            variant="text"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.saveAndExit",
            })}
            onClick={onClickSaveAndExit}
          />
          <Button
            variant="contained"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.saveAndContinue",
            })}
            onClick={onClickSaveAndContinue}
          />
        </div>
      }
    >
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.mailingAddress",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="mailing_type"
                name="mailing_type"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.mailingType",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.mailingType",
                })}
                options={[
                  {
                    value: "Str",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.streetAddress",
                    }),
                  },
                  {
                    value: "POB",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.poBox",
                    }),
                  },
                ]}
              />
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Field
                    component={FieldText}
                    highlightType="advanced"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id={formValues?.mailing_type === "POB" ? "po_box" : "street"}
                    name={formValues?.mailing_type === "POB" ? "po_box" : "street"}
                    label={
                      formValues?.mailing_type === "POB"
                        ? formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.poBoxNumber",
                          })
                        : formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.streetAddress",
                          })
                    }
                    type="text"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FieldText}
                    validate={[isRequired]}
                    highlightType="basic"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="city"
                    name="city"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.city",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.city",
                    })}
                    type="text"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FieldSelect}
                    validate={[isRequired]}
                    highlightType="basic"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="country"
                    name="country"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.country",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.country",
                    })}
                    valueField="value"
                    labelField="label"
                    options={countries}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FieldText}
                    validate={[isRequired, isValidPhoneNumber]}
                    highlightType="basic"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="mailing_telephone"
                    name="mailing_telephone"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.telephone",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.telephone",
                    })}
                    type="text"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FieldText}
                    highlightType="advanced"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="zip_code"
                    name="zip_code"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.zipCode",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.zipCode",
                    })}
                    type="text"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FieldText}
                    readOnly={!inEditMode}
                    validate={[isValidUrl]}
                    highlightType="advanced"
                    small={!inEditMode}
                    id="website"
                    name="website"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.website",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.website",
                    })}
                    type="text"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FieldText}
                    readOnly={!inEditMode}
                    validate={[isValidEmail]}
                    highlightType="advanced"
                    small={!inEditMode}
                    id="org_email"
                    name="org_email"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.organizationEmail",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.organizationEmail",
                    })}
                    type="email"
                  />
                </Grid>
              </Grid>
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.keyPersonnel",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              {haveHqOrganizationHeads ? (
                <Box
                  title={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.hqHeads",
                  })}
                  content={
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          component={FieldText}
                          highlightType="basic"
                          readOnly
                          small={!inEditMode}
                          id="hq_head_of_organization_fullname"
                          name="hq_head_of_organization_fullname"
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.fullname",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.fullname",
                          })}
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={haveHqOrganisationHeadsJobTitleOther ? 4 : 6}>
                        <Field
                          component={FieldSelect}
                          highlightType="advanced"
                          readOnly
                          small={!inEditMode}
                          id="hq_head_of_organization_job_title"
                          name="hq_head_of_organization_job_title"
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.jobTitle",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.jobTitle",
                          })}
                          valueField="value"
                          labelField="label"
                          options={jobTitleTypes}
                          multiple
                        />
                      </Grid>
                      {haveHqOrganisationHeadsJobTitleOther ? (
                        <Grid item xs={2}>
                          <Field
                            component={FieldText}
                            highlightType="advanced"
                            readOnly
                            small={!inEditMode}
                            id="hq_head_of_organization_job_title_other"
                            name="hq_head_of_organization_job_title_other"
                            label={formatMessage({
                              id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.other",
                            })}
                            placeholder={formatMessage({
                              id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.other",
                            })}
                          />
                        </Grid>
                      ) : undefined}
                      <Grid item xs={6}>
                        <Field
                          component={FieldText}
                          highlightType="basic"
                          readOnly
                          small={!inEditMode}
                          id="hq_head_of_organization_telephone"
                          name="hq_head_of_organization_telephone"
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.telephoneOne",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.telephoneOne",
                          })}
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={FieldText}
                          readOnly
                          highlightType="basic"
                          small={!inEditMode}
                          id="hq_head_of_organization_email"
                          name="hq_head_of_organization_email"
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.email",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.email",
                          })}
                          type="text"
                        />
                      </Grid>
                    </Grid>
                  }
                />
              ) : undefined}
              <Box
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.directors",
                })}
                sections={boardDirectors.map((item, index) => (
                  <React.Fragment key={`board_director_wrapper_${item.id}`}>
                    <div className={style.itemWrapper}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Field
                              component={FieldText}
                              highlightType="advanced"
                              readOnly={!inEditMode || index === 0}
                              small={!inEditMode}
                              id={`board_director_fullname_${item.id}`}
                              name={`board_director_fullname_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.fullname",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.fullname",
                              })}
                            />
                          </Grid>
                          <Grid item xs={haveJobTitleOther[item.id] ? 4 : 6}>
                            <Field
                              component={FieldSelect}
                              highlightType="advanced"
                              readOnly={!inEditMode || index === 0}
                              small={!inEditMode}
                              id={`board_director_job_title_${item.id}`}
                              name={`board_director_job_title_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.jobTitle",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.jobTitle",
                              })}
                              informationText={
                                index !== 0
                                  ? formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.JobTitleInfoText",
                                    })
                                  : undefined
                              }
                              valueField="value"
                              labelField="label"
                              options={jobTitleTypes}
                              multiple
                            />
                          </Grid>
                          {haveJobTitleOther[item.id] ? (
                            <Grid item xs={2}>
                              <Field
                                component={FieldText}
                                highlightType="advanced"
                                readOnly={!inEditMode || index === 0}
                                small={!inEditMode}
                                id={`job_title_other_${item.id}`}
                                name={`job_title_other_${item.id}`}
                                label={formatMessage({
                                  id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.other",
                                })}
                                placeholder={formatMessage({
                                  id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.other",
                                })}
                              />
                            </Grid>
                          ) : undefined}
                          <Grid item xs={6}>
                            <Field
                              component={FieldText}
                              validate={[isValidPhoneNumber]}
                              highlightType="advanced"
                              readOnly={!inEditMode || index === 0}
                              small={!inEditMode}
                              id={`board_director_telephone_${item.id}`}
                              name={`board_director_telephone_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.telephoneOne",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.telephoneOne",
                              })}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              component={FieldText}
                              validate={[isValidEmail]}
                              highlightType="advanced"
                              readOnly={!inEditMode || index === 0}
                              small={!inEditMode}
                              id={`board_director_email_${item.id}`}
                              name={`board_director_email_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.email",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.email",
                              })}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {index > 0 && inEditMode ? (
                        <div>
                          <IconButton onClick={() => onClickRemoveBoardDirector(item)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ) : undefined}
                    </div>
                  </React.Fragment>
                ))}
                actions={
                  inEditMode ? (
                    <>
                      <Button
                        text={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.addNew",
                        })}
                        startIcon={<AddIcon />}
                        onClick={onClickAddBoardDirector}
                        variant="text"
                      />
                    </>
                  ) : undefined
                }
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.workingLanguages",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={formValues?.working_languages?.includes("Oth") ? 8 : 12}>
                <Field
                  component={FieldSelect}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="working_languages"
                  name="working_languages"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.workingLanguagesOne",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.workingLanguagesOne",
                  })}
                  valueField="value"
                  labelField="label"
                  options={languages}
                  multiple
                />
              </Grid>
              {formValues?.working_languages?.length && formValues?.working_languages?.includes("Oth") ? (
                <Grid item xs={4}>
                  <Field
                    component={FieldText}
                    validate={[isRequired]}
                    highlightType="basic"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="working_languages_other"
                    name="working_languages_other"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.otherOne",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.contact.information.idx.otherOne",
                    })}
                  />
                </Grid>
              ) : undefined}
            </Grid>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

ContactInformation.propTypes = {};

export default reduxForm({
  form: "editPartnerProfileContactInformationForm",
  enableReinitialize: true,
})(ContactInformation);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { initSession, loadUserData } from "../../legacy-reducers/session";
import { loadCountries } from "../../legacy-reducers/countries";
import { SESSION_STATUS } from "../../legacy-utils/constants";
import Loader from "@local/components/loader";
import Page404 from "./page404";
import { pathRestrictions } from "@local/legacy-utils/restrictions";

class Auth extends Component {
  componentWillMount() {
    const { sessionInit, loadUserInfo } = this.props;
    let role = window.localStorage.role;
    if (!role) {
      role = "partner";
      window.localStorage.setItem("role", role);
    }
    const token = window.localStorage.token;
    sessionInit({ role, token });
    loadUserInfo();
  }
  isRestricted() {
    const { agencyName } = this.props;
    const currentPath = window.location.pathname;

    // Check if the agency is restricted from accessing any of the paths
    return pathRestrictions.some((restriction) => restriction.agency === agencyName && restriction.paths.some((path) => currentPath.startsWith(path)));
  }

  render() {
    const { status, children } = this.props;

    // Restrict access if the agency is in the restricted list
    if (this.isRestricted()) {
      return <Page404 />;
    }

    return (
      <React.Fragment>
        <Loader isVisible={status === SESSION_STATUS.CHANGING} isFullScreen size={60} />
        {status === SESSION_STATUS.READY ? children : null}
      </React.Fragment>
    );
  }
}

Auth.propTypes = {
  sessionInit: PropTypes.func,
  loadUserInfo: PropTypes.func,
  children: PropTypes.node,
  status: PropTypes.string,
  agencyName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  status: state.session.state,
  agencyName: state.session.agencyName,
});

const mapDispatchToProps = (dispatch) => ({
  sessionInit: (session) => {
    dispatch(initSession(session));
  },
  loadUserInfo: () => dispatch(loadUserData()),
  loadCountries: () => loadCountries(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";

const AgencyUnsolicitedProjectsList = ({ fetchFunction, columns, filters, adapterFunction, shouldRefresh, afterRefresh, expandSectionItems }) => {
  return (
    <Table
      key="shell_profiles_list"
      id="shell_profiles_list"
      title={formatMessage({
        id: "pages.shell.profiles.list.cmp.list.idx.header",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      filters={filters}
      adapterFunction={adapterFunction}
      shouldRefresh={shouldRefresh}
      afterRefresh={afterRefresh}
      withUrlSynchronization
      expandSectionItems={expandSectionItems}
    />
  );
};

AgencyUnsolicitedProjectsList.propTypes = {};

export default AgencyUnsolicitedProjectsList;

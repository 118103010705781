/* eslint-disable max-len */

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Accordion from "@local/components/accordion";
import { ROLES } from "@local/legacy-utils/constants";

const getTabs = (role) => [
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question009" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question009" }),
    content: (
      <span>
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0091" })}{" "}
        <a target="_blank" href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360004492133-Create-and-Edit-Profile-Local-NGO" alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question00921" })} rel="noreferrer">
          {formatMessage({ id: "pages.faq.cmp.mn.idx.question0092" })}
        </a>{" "}
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0093" })}
      </span>
    ),
    isVisible: true,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question011" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question011" }),
    content: <span>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0111" })}</span>,
    isVisible: true,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question013" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question013" }),
    content: (
      <>
        <span>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0131" })}</span>
        <ol>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0132" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0133" })}</li>
        </ol>
      </>
    ),
    isVisible: true,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question002" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question002" }),
    content: (
      <>
        <span>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0021" })}</span>
        <img alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question0022" })} src="https://icc-unpp-data.s3.amazonaws.com/public/faq-screenshot-2.p_1ng" />
      </>
    ),
    isVisible: true,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question004" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question004" }),
    content: <span>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0041" })}</span>,
    isVisible: true,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question001" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question001" }),
    content: (
      <span>
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0011" })}{" "}
        <a target="_blank" href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360004110374-Registration" alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question00121" })} rel="noreferrer">
          {formatMessage({ id: "pages.faq.cmp.mn.idx.question0012" })}
        </a>{" "}
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0013" })}
      </span>
    ),
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question006" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question006" }),
    content: (
      <span>
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0061" })}{" "}
        <a target="_blank" href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360013423454-User-Management-Assignment-of-users-in-your-organization-" alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question00621" })} rel="noreferrer">
          {formatMessage({ id: "pages.faq.cmp.mn.idx.question0062" })}
        </a>
      </span>
    ),
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question007" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question007" }),
    content: (
      <span>
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0071" })}{" "}
        <a target="_blank" href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360014082374-Login-as-a-Migrated-Partner-from-UNHCR-s-Partner-Portal" alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question00721" })} rel="noreferrer">
          {formatMessage({ id: "pages.faq.cmp.mn.idx.question0072" })}
        </a>{" "}
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0073" })}
      </span>
    ),
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question008" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question008" }),
    content: <span>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0081" })}</span>,
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question010" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question010" }),
    content: (
      <span>
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0101" })}{" "}
        <a target="_blank" href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360013423454-User-Management-Assignment-of-users-in-your-organization-" alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question01021" })} rel="noreferrer">
          {formatMessage({ id: "pages.faq.cmp.mn.idx.question0102" })}
        </a>{" "}
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0103" })}
      </span>
    ),
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question012" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question012" }),
    content: (
      <>
        <span>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0121" })}</span>
        <ol>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0122" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0123" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0124" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0125" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0126" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0127" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0128" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0129" })}</li>
          <li>{formatMessage({ id: "pages.faq.cmp.mn.idx.question012A" })}</li>
        </ol>
        <img alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question012B" })} src="https://icc-unpp-data.s3.amazonaws.com/public/faq-screenshot-1.p_1ng" />
      </>
    ),
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question003" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question003" }),
    content: <span>{formatMessage({ id: "pages.faq.cmp.mn.idx.question0031" })}</span>,
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: formatMessage({ id: "pages.faq.cmp.mn.idx.question005" }),
    label: formatMessage({ id: "pages.faq.cmp.mn.idx.question005" }),
    content: (
      <span>
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0051" })}{" "}
        <a target="_blank" href="https://unpartnerportalcso.zendesk.com/hc/en-us/articles/360004232134-View-and-Apply-for-Calls-for-Expression-of-Interest" alt={formatMessage({ id: "pages.faq.cmp.mn.idx.question00521" })} rel="noreferrer">
          {formatMessage({ id: "pages.faq.cmp.mn.idx.question0052" })}
        </a>{" "}
        {formatMessage({ id: "pages.faq.cmp.mn.idx.question0053" })}
      </span>
    ),
    isVisible: role === ROLES.PARTNER,
  },
];

const Main = ({ role }) => {
  return <Accordion id="faq" items={getTabs(role).filter((item) => item.isVisible)} />;
};

Main.propTypes = {};

export default Main;

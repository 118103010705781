/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createShellProfile, getShellProfilesList, createShellProfileNotification, getCountriesList, getPartnerHqsList, destroyInvitedPartner } from "@local/actions";
import { adaptShellProfiles } from "./utils/adapters";
import { getShellProfilesColumns, getShellProfilesExpandSectionItems } from "./utils/columns";
import { getShellProfilesFilters } from "./utils/filters";
import List from "./components/list";
import ConfirmModal from "@local/components/confirm-modal";
import Alert from "@local/components/alert";
import Container from "./components/container";
import { getPartnerTypesWithoutSpecialTypes } from "@local/utils/constants";
import { adaptCountries } from "@local/utils/adapters";
import NewShellProfileModal from "./components/new-shell-profile-modal";
import SendNotificationModal from "./components/send-notification-modal";
import { isValid, reset } from "redux-form";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getErrors } from "@local/utils";

const ShellProfilesList = ({
  countries,
  partnerTypes,
  getShellProfiles,
  createShellProfile,
  createShellProfileNotification,
  getCountries,
  isValidNewShellProfileForm,
  isValidSendNotificationForm,
  newShellProfileFormValues,
  sendNotificationFormValues,
  resetNewShellProfileForm,
  resetSendNotificationForm,
  getPartnerHqs,
  deleteInvitedPartner,
}) => {
  const [isAddShellProfileModalVisible, setIsAddShellProfileModalVisible] = useState(false);
  const [isSendNotificationModalVisible, setIsSendNotificationModalVisible] = useState(false);
  const [isDeleteInvitationModalVisible, setIsDeleteInvitationModalVisible] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(false);
  const [currentInvitedPartnerId, setCurrentInvitedPartnerId] = useState(undefined);

  useEffect(() => {
    getCountries();
  }, []);

  const onClickAddShellProfile = () => {
    setIsAddShellProfileModalVisible(true);
  };

  const onClickSendNotification = (row) => {
    setCurrentEmail(row.email);
    setIsSendNotificationModalVisible(true);
  };

  const onClickOpenDeleteInvitation = (row) => {
    setCurrentInvitedPartnerId(row?.id);
    setIsDeleteInvitationModalVisible(true);
  };

  const onClickSubmitDeleteInvitation = async () => {
    await deleteInvitedPartner(currentInvitedPartnerId);
    setShouldRefresh(true);
    setIsDeleteInvitationModalVisible(false);
    setCurrentInvitedPartnerId(null);
  };

  const onClickCloseDeleteInvitation = () => {
    setIsDeleteInvitationModalVisible(false);
  };

  const onClickSubmitAddShellProfileModal = async () => {
    try {
      await createShellProfile({
        country_code: newShellProfileFormValues?.country_code,
        display_type: newShellProfileFormValues?.display_type,
        hd: newShellProfileFormValues?.hq?.value,
        email: newShellProfileFormValues?.email,
        legal_name: newShellProfileFormValues?.legal_name,
        vendor_number: newShellProfileFormValues?.vendor_number,
      });
      setIsAddShellProfileModalVisible(false);
      setShouldRefresh(true);
      resetNewShellProfileForm();
    } catch (error) {
      const errorMessages = getErrors(error ?? {}).join(" ");
      toast.error(
        errorMessages ||
          formatMessage({
            id: "pages.shell.profiles.list.idx.unableToSaveData",
          }),
      );
    }
  };

  const onClickCancelAddShellProfileModal = () => {
    setIsAddShellProfileModalVisible(false);
    resetNewShellProfileForm();
  };

  const onClickSubmitSendNotificationModal = async () => {
    try {
      await createShellProfileNotification({
        email: currentEmail,
        message: sendNotificationFormValues?.message,
      });
      setIsSendNotificationModalVisible(false);
      setCurrentEmail(undefined);
      resetSendNotificationForm();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.shell.profiles.list.idx.unableToSaveData" }));
    }
  };

  const onClickCancelSendNotificationModal = () => {
    setIsSendNotificationModalVisible(false);
    setCurrentEmail(undefined);
    resetSendNotificationForm();
  };

  const afterRefresh = () => {
    setShouldRefresh(false);
  };

  return (
    <>
      <Container onClickHeaderButton={onClickAddShellProfile}>
        <List
          columns={getShellProfilesColumns(countries)}
          fetchFunction={getShellProfiles}
          filters={getShellProfilesFilters(countries, partnerTypes)}
          adapterFunction={(shellProfiles) => adaptShellProfiles(shellProfiles, countries, "country_code")}
          shouldRefresh={shouldRefresh}
          afterRefresh={afterRefresh}
          expandSectionItems={getShellProfilesExpandSectionItems(onClickSendNotification, onClickOpenDeleteInvitation)}
        />
      </Container>
      <NewShellProfileModal
        isOpen={isAddShellProfileModalVisible}
        onClose={onClickCancelAddShellProfileModal}
        countries={countries}
        partnerTypes={partnerTypes}
        onSubmit={onClickSubmitAddShellProfileModal}
        isValid={isValidNewShellProfileForm}
        isInternational={newShellProfileFormValues?.display_type === "Int"}
        getPartnerHqs={getPartnerHqs}
      />
      <SendNotificationModal isOpen={isSendNotificationModalVisible} onClose={onClickCancelSendNotificationModal} onSubmit={onClickSubmitSendNotificationModal} isValid={isValidSendNotificationForm} />
      <ConfirmModal
        isOpen={isDeleteInvitationModalVisible}
        onClose={onClickCloseDeleteInvitation}
        onSubmit={onClickSubmitDeleteInvitation}
        title={formatMessage({ id: "pages.shell.profiles.list.idx.title" })}
        type="warning"
        message={
          <div>
            <Alert
              type="info"
              content={formatMessage({
                id: "pages.shell.profiles.list.idx.content",
              })}
              withBottomMargin
            />
          </div>
        }
      />
    </>
  );
};

ShellProfilesList.propTypes = {};

const mapStateToProps = (state) => ({
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  partnerTypes: getPartnerTypesWithoutSpecialTypes(),
  newShellProfileFormValues: state.form?.newShellProfileModalForm?.values ?? {},
  isValidNewShellProfileForm: isValid("newShellProfileModalForm")(state),
  sendNotificationFormValues: state.form?.sendNotificationModalForm?.values ?? {},
  isValidSendNotificationForm: isValid("sendNotificationModalForm")(state),
});

const mapDispatchToProps = (dispatch) => ({
  getShellProfiles: (params) => dispatch(getShellProfilesList(params)),
  createShellProfile: (params) => dispatch(createShellProfile(params)),
  createShellProfileNotification: (params) => dispatch(createShellProfileNotification(params)),
  getCountries: () => dispatch(getCountriesList()),
  getPartnerHqs: (legal_name) => dispatch(getPartnerHqsList({ is_hq: true, legal_name })),
  resetNewShellProfileForm: () => dispatch(reset("newShellProfileModalForm")),
  resetSendNotificationForm: () => dispatch(reset("sendNotificationModalForm")),
  deleteInvitedPartner: (id) => dispatch(destroyInvitedPartner({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShellProfilesList);

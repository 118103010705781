import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Main from "@local/pages/shared/main";
import Authenticated from "@local/pages/shared/auth";
import NonAuth from "@local/pages/shared/nonAuth";
import MainLayout from "@local/components/main-layout";
import Banner from "@local/pages/banner";
import ProjectsList from "@local/pages/projects-list";
import ProjectDetails from "@local/pages/project-details";
import PartnersList from "@local/pages/partners-list";
import PartnerDetails from "@local/pages/partner-details";
import ProfileDetails from "@local/pages/partner-details/components/profile-details";
import ApplicationDetails from "@local/pages/application-details";
import ApplicationsList from "@local/pages/applications-list";
import Dashboard from "@local/pages/dashboard";
import Demo from "@local/pages/demo";
import ShellProfiles from "@local/pages/shell-profiles-list";
import DirectLogin from "@local/pages/direct-login";
import FirstAccess from "@local/pages/first-access";
import LoginRedirect from "@local/pages/login/loginRedirect";
import LoginToken from "@local/pages/login/loginToken";
import Register from "@local/pages/login/register";
import ReportsList from "@local/pages/reports-list";
import UserProfile from "@local/pages/user-profile";
import Page404 from "@local/pages/shared/page404";
import Faq from "@local/pages/faq";
import SubscriptionsList from "@local/pages/subscriptions-list";
import NotificationsList from "@local/pages/notifications-list";
import UsersManagement from "@local/pages/users-management";
import ProfilesList from "@local/pages/profiles-list";
import IpAgreement from "@local/pages/ip-agreement";
import AdminPanel from "@local/pages/admin";
import OrganizationUsers from "@local/pages/organization-users";

// POST_REFACTORING_TODO: Request confirmation to be checked

const PrivateRoutesWrapper = ({ children }) => {
  return (
    <Banner>
      <Authenticated>
        <Main>
          <MainLayout>{children}</MainLayout>
        </Main>
      </Authenticated>
    </Banner>
  );
};

const PublicRoutesWrapper = ({ children }) => {
  return (
    <Banner>
      <NonAuth>{children}</NonAuth>
    </Banner>
  );
};

const RegistrationRoutesWrapper = ({ children }) => {
  return (
    <Banner>
      <Authenticated>
        <Main>{children}</Main>
      </Authenticated>
    </Banner>
  );
};

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path="/login">
        <LoginRedirect />
      </Route>
      <Route exact path="/registration-email">
        <Register />
      </Route>
      <Route exact path="/login/:token">
        <LoginToken />
      </Route>
      <Route exact path="/direct-login">
        <DirectLogin />
      </Route>
    </Switch>
  );
};

const RegistrationRoutes = () => {
  return (
    <Switch>
      <Route exact path="/registration">
        <FirstAccess step="registration" />
      </Route>
      <Route exact path="/first-access">
        <FirstAccess step="first-access" />
      </Route>
      <Route exact path="/confirmation">
        <FirstAccess step="confirmation" />
      </Route>
    </Switch>
  );
};

const PrivateRoutes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Route exact path="/demo">
        <Demo />
      </Route>
      <Route exact path="/registration">
        <FirstAccess step="registration" />
      </Route>
      <Route exact path="/first-access">
        <FirstAccess step="first-access" />
      </Route>
      <Route exact path="/confirmation">
        <FirstAccess step="confirmation" />
      </Route>
      <Route exact path="/dashboard">
        <Dashboard />
      </Route>
      <Route exact path="/user">
        <UserProfile />
      </Route>
      <Route exact path="/notifications-list">
        <NotificationsList />
      </Route>
      <Redirect exact from="/cfei" to="/cfei/open" />
      <Route exact path="/cfei/:type?">
        <ProjectsList />
      </Route>
      <Redirect exact from="/cfei/:type/:id" to="/cfei/:type/:id/overview" />
      <Route exact path="/cfei/:type/:id/overview">
        <ProjectDetails />
      </Route>
      <Route exact path="/cfei/:type/:id/feedback">
        <ProjectDetails />
      </Route>
      <Route exact path="/cfei/:type/:id/submission">
        <ProjectDetails />
      </Route>
      <Route exact path="/cfei/:type/:id/results">
        <ProjectDetails />
      </Route>
      <Route exact path="/cfei/:type/:id/requests">
        <ProjectDetails />
      </Route>
      <Route exact path="/cfei/:type/:id/preselected">
        <ProjectDetails />
      </Route>
      <Route exact path="/cfei/:type/:id/applications">
        <ProjectDetails />
      </Route>
      <Route exact path="/cfei/:type/:id/response">
        <ProjectDetails />
      </Route>
      <Redirect exact from="/cfei/:type/:id/preselected/:applicationId" to="/cfei/:type/:id/applications/:applicationId" />
      <Route exact path="/cfei/:type/:id/applications/:applicationId">
        <ApplicationDetails />
      </Route>
      <Route exact path="/partner">
        <PartnersList />
      </Route>
      <Route exact path="/shell-profile">
        <ShellProfiles />
      </Route>
      <Redirect exact from="/partner/:id" to={"/partner/:id/overview"} />
      <Route exact path="/partner/:id/:type?">
        <PartnerDetails />
      </Route>
      <Redirect exact from="/partner/:id/risk" to="/partner/:id/risk/dashboard" />
      <Route exact path="/partner/:id/risk/:type?">
        <PartnerDetails />
      </Route>
      <Route exact path="/partner/:id/risk/:type?/:flagId?">
        <PartnerDetails />
      </Route>
      <Redirect exact from="/profile/:id" to="/profile/:id/overview" />
      <Route exact path="/profile/:id/:type?">
        <PartnerDetails />
      </Route>
      <Redirect exact from="/special-partner/:id" to="/special-partner/:id/overview" />
      <Route exact path="/special-partner/:id/:type?">
        <PartnerDetails isSpecialPartner />
      </Route>
      <Redirect exact from="/applications" to="/applications/notes" />
      <Route exact path="/applications/:type?">
        <ApplicationsList />
      </Route>
      <Route exact path="/profile">
        <ProfilesList />
      </Route>
      <Redirect exact from="/profile/:id/edit" to="/profile/:id/edit/identification" />
      <Route exact path="/profile/:id/edit/:type?">
        <ProfileDetails inEditMode />
      </Route>
      <Redirect exact from="/reports/" to="/reports/information" />
      <Route exact path="/reports/:type?">
        <ReportsList />
      </Route>
      <Route exact path="/faq">
        <Faq />
      </Route>
      <Route exact path="/subscriptions">
        <SubscriptionsList />
      </Route>
      <Redirect exact from="/idp" to="/idp/users" />
      <Route exact path="/idp/:type?">
        <UsersManagement />
      </Route>
      <Route exact path="/idp/users/add/:toAddUser">
        <UsersManagement />
      </Route>
      <Redirect exact from="/org" to="/org/users" />
      <Route exact path="/org/:type?">
        <OrganizationUsers />
      </Route>
      <Route exact path="/ip-agreement">
        <IpAgreement />
      </Route>
      <Redirect exact from="/admin/" to="/admin/projects" />
      <Route exact path="/admin/:type?">
        <AdminPanel />
      </Route>
    </Switch>
  );
};

const privateRoutesPaths = [
  "/dashboard",
  "/user",
  "/notifications-list",
  "/cfei",
  "/cfei/:type?",
  "/cfei/:type/:id/overview",
  "/cfei/:type/:id/feedback",
  "/cfei/:type/:id/submission",
  "/cfei/:type/:id/results",
  "/cfei/:type/:id/requests",
  "/cfei/:type/:id/preselected",
  "/cfei/:type/:id/applications",
  "/cfei/:type/:id/response",
  "/cfei/:type/:id/applications",
  "/cfei/:type/:id/applications/:applicationId",
  "/shell-profile",
  "/partner",
  "/partner/:id/overview",
  "/partner/:id/details",
  "/partner/:id/undata",
  "/partner/:id/users",
  "/partner/:id/verification",
  "/partner/:id/risk",
  "/partner/:id/risk/:type?",
  "/partner/:id/risk/:type?/:flagId?",
  "/partner/:id/observations",
  "/partner/:id/applications",
  "/special-partner",
  "/special-partner/:id/overview",
  "/special-partner/:id/undata",
  "/special-partner/:id/offices",
  "/applications",
  "/applications/notes",
  "/applications/unsolicited",
  "/applications/direct",
  "/profile",
  "/profile/:id/edit",
  "/profile/:id/edit/:type",
  "/profile/:id/overview",
  "/profile/:id/undata",
  "/reports",
  "/reports/:type?",
  "/faq",
  "/demo",
  "/subscriptions",
  "/idp",
  "/idp/dashboard",
  "/idp/users",
  "idp/users/add/:toAddUser",
  "/org",
  "/org/users",
  "/ip-agreement",
  "/admin",
];

const publicRoutesPaths = ["/login", "/registration-email", "/login/:token", "/direct-login"];

const registrationRoutesPaths = ["/first-access", "/registration", "/confirmation"];

const Routes = () => (
  <Switch>
    <Redirect exact from="/" to={"/dashboard"} />
    <Route path={privateRoutesPaths}>
      <PrivateRoutesWrapper>
        <PrivateRoutes />
      </PrivateRoutesWrapper>
    </Route>
    <Route path={publicRoutesPaths}>
      <PublicRoutesWrapper>
        <PublicRoutes />
      </PublicRoutesWrapper>
    </Route>
    <Route path={registrationRoutesPaths}>
      <RegistrationRoutesWrapper>
        <RegistrationRoutes />
      </RegistrationRoutesWrapper>
    </Route>
    <Route path="*">
      <Page404 />
    </Route>
  </Switch>
);

export default Routes;

import { SESSION_READY } from "./session";
import { PARTNER, AGENCY, filterItems } from "./nav";
import { PROJECT_TYPES } from "../legacy-utils/constants";
import { formatMessage } from "../legacy-utils/i18nHelper";

const initialState = [
  {
    id: 0,
    path: PROJECT_TYPES.OPEN,
    label: formatMessage({ id: "legacy.reducers.cfeiNav.PARTNER" }),
    roles: [PARTNER],
  },
  {
    id: 1,
    path: PROJECT_TYPES.PINNED,
    label: formatMessage({ id: "legacy.reducers.cfeiNav.pinned" }),
    roles: [PARTNER],
  },
  {
    id: 0,
    path: PROJECT_TYPES.OPEN,
    label: formatMessage({ id: "legacy.reducers.cfeiNav.AGENCY" }),
    roles: [AGENCY],
  },
  {
    id: 1,
    path: PROJECT_TYPES.DIRECT,
    label: formatMessage({
      id: "legacy.reducers.cfeiNav.directSelectionRetention",
    }),
    roles: [AGENCY],
  },
  {
    id: 2,
    path: PROJECT_TYPES.UNSOLICITED,
    label: formatMessage({
      id: "legacy.reducers.cfeiNav.unsolicitedConceptNotes",
    }),
    roles: [AGENCY],
  },
];

export default function cfeiNavReducer(state = initialState, action) {
  switch (action.type) {
    case SESSION_READY: {
      return filterItems(initialState, action.role);
    }
    default:
      return state;
  }
}

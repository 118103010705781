import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { formatDateForPrint } from "../../../../legacy-utils/dates";
import { formatMessage } from "../../../../legacy-utils/i18nHelper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import style from "./lastProfileUpdate.module.scss";
import Loader from "@local/components/loader";

const LastProfileUpdate = ({ isLoading, date }) => {
  return (
    <Card>
      <CardContent>
        <div className={style.titleWrapper}>
          <div>
            <Typography variant="subtitle2" component="div">
              {formatMessage({
                id: "pages.dashboard.cmp.partner.lastProfileUpdate.title",
              })}
            </Typography>
          </div>
          {isLoading ? <Loader isVisible /> : <div>{formatDateForPrint(date) || ""}</div>}
        </div>
      </CardContent>
      <CardActions>
        <Button component={Link} to="/profile/" color="primary">
          {formatMessage({
            id: "pages.dashboard.cmp.partner.lastProfileUpdate.button",
          })}
        </Button>
      </CardActions>
    </Card>
  );
};

LastProfileUpdate.propTypes = {};

export default LastProfileUpdate;

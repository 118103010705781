/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useParams } from "react-router";
import { reduxForm, Field } from "redux-form";
import FieldText from "@local/components/field-text";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddIcon from "@mui/icons-material/Add";
import Button from "@local/components/button";
import Card from "@local/components/card";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Modal from "@local/components/modal";
import Alert from "@local/components/alert";
import Table from "@local/components/table";
import Loader from "@local/pages/common/loader";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import { getProjectClarificationRequestsColumns } from "../../../../utils/columns";
import { isRequired } from "@local/utils/validators";
import style from "./style.module.scss";

const PartnerClarificationRequests = ({
  isPreviewModalVisible,
  onClickOpenPreviewModal,
  onClickClosePreviewModal,
  getClarificationRequests,
  isAddClarificationRequestModalVisible,
  onClickOpenAddClarificationRequestModal,
  onClickCloseAddClarificationRequestModal,
  isClarificationDeadlinePassed,
  requestsLoading,
  requestCount,
  hasPermissionToAddRequest,
  handleClickOpenAddRequestMenu,
  handleCloseAddRequestMenu,
  openAddRequestMenu,
  anchorEl,
  onClickAddClarificationRequest,
  pristine,
  invalid,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getClarificationRequests(id);
  }, []);

  return (
    <div>
      <Loader fullscreen loading={requestsLoading} />
      <Card
        title={
          <span className={style.title}>
            {formatMessage({
              id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.title",
            })}
          </span>
        }
        action={
          hasPermissionToAddRequest && !isClarificationDeadlinePassed ? (
            <div>
              <IconButton onClick={handleClickOpenAddRequestMenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu id="request_menu" anchorEl={anchorEl} open={openAddRequestMenu} onClose={handleCloseAddRequestMenu}>
                <MenuItem
                  className={style.item}
                  key="add_request"
                  onClick={() => {
                    onClickOpenAddClarificationRequestModal();
                    handleCloseAddRequestMenu();
                  }}
                >
                  <ListItemIcon>
                    <AddIcon fontSize="small" />
                  </ListItemIcon>
                  {formatMessage({
                    id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.text",
                  })}
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <></>
          )
        }
      >
        <div className={style.buttonWrapper}>
          <span>
            {`${requestCount || 0} ${formatMessage({
              id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.requests",
            })}`}
          </span>
          <Button
            text={formatMessage({
              id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.viewDetails",
            })}
            variant="text"
            onClick={onClickOpenPreviewModal}
          />
        </div>
      </Card>
      <Modal
        isOpen={isPreviewModalVisible}
        onClose={onClickClosePreviewModal}
        title={formatMessage({
          id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.titleOne",
        })}
        footer={
          <div className={style.modalActionsWrapper}>
            <div>
              <Button
                text={formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.ok",
                })}
                onClick={onClickClosePreviewModal}
              />
            </div>
          </div>
        }
      >
        <>
          <Alert
            type="info"
            content={
              <span>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.info",
                })}
                {requestCount}
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.completedInfo",
                })}
              </span>
            }
            withBottomMargin
          />
          <div>
            {requestCount === 0 ? (
              <span className={style.caption}>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.noInfo",
                })}
              </span>
            ) : (
              <Table key="project_clarification_requests" id="project_clarification_requests" columns={getProjectClarificationRequestsColumns()} fetchFunction={getClarificationRequests} isBordered />
            )}
          </div>
        </>
      </Modal>
      <Modal
        isOpen={isAddClarificationRequestModalVisible}
        onClose={onClickCloseAddClarificationRequestModal}
        title={formatMessage({
          id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.titleTwo",
        })}
        footer={
          <div className={style.modalActionsWrapper}>
            <div>
              <Button
                onClick={onClickCloseAddClarificationRequestModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                text={formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.save",
                })}
                isDisabled={pristine || invalid}
                onClick={onClickAddClarificationRequest}
              />
            </div>
          </div>
        }
        small
      >
        <>
          <Alert type="info" content={formatDateForPrint(new Date())} withBottomMargin />
          <Field
            component={FieldText}
            id="question"
            name="question"
            type="text"
            maxLength={5000}
            withCharactersCount
            label={formatMessage({
              id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.comment",
            })}
            placeholder={formatMessage({
              id: "pages.project.details.cmp.overview.cmp.partner.clarification.requests.idx.commentPlaceholder",
            })}
            validate={[isRequired]}
          />
        </>
      </Modal>
    </div>
  );
};

PartnerClarificationRequests.propTypes = {};

export default reduxForm({ form: "addClarificationForm" })(PartnerClarificationRequests);

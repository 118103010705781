import { adaptSpecializations } from "@local/utils/adapters";
import i18n from "@local/i18next/i18n";

export const getPartnerDuplicateFilters = (duplicateStatus, duplicateResults) => [
  {
    id: "legal_name",
    name: "legal_name",
    label: i18n.t("Partner Name"),
    placeholder: i18n.t("Partner Name"),
    type: "text",
  },
  {
    id: "status",
    name: "status",
    label: i18n.t("Status"),
    placeholder: i18n.t("Status"),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: duplicateStatus,
  },
  {
    id: "duplicate_result",
    name: "duplicate_result",
    label: i18n.t("Duplicate Result"),
    placeholder: i18n.t("Duplicate Result"),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: duplicateResults,
  },
];

export const getPendingAssessmentReviewFilters = (countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses) => [
  {
    id: "title",
    name: "title",
    label: i18n.t("Title"),
    placeholder: i18n.t("Title"),
    type: "text",
  },
  {
    id: "country_code",
    name: "country_code",
    label: i18n.t("Country"),
    placeholder: i18n.t("Country"),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  },
  {
    id: "locations",
    name: "locations",
    label: i18n.t("Location"),
    placeholder: i18n.t("Location"),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
    multiple: true,
  },
  {
    id: "specializations",
    name: "specializations",
    label: i18n.t("Sector & Area of Specialization"),
    placeholder: i18n.t("Sector & Area of Specialization"),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "displayID",
    name: "displayID",
    label: i18n.t("CFEI ID"),
    placeholder: i18n.t("CFEI ID"),
    type: "text",
  },
  {
    id: "posted_date",
    fromName: "posted_from_date",
    toName: "posted_to_date",
    label: i18n.t("Date Posted"),
    placeholder: i18n.t("Date Posted"),
    type: "dateRange",
  },
  {
    id: "focal_points",
    name: "focal_points",
    label: i18n.t("CFEI Focal Point"),
    placeholder: i18n.t("CFEI Focal Point"),
    type: "select",
    valueField: "id",
    labelField: "label",
    multiple: true,
    options: focalPoints,
  },
  {
    id: "status",
    name: "status",
    type: "radio",
    options: statuses,
  },
];

export const getPartnersFilters = (countries, sectors, partnerTypes) => [
  {
    id: "legal_name",
    name: "legal_name",
    label: i18n.t("Partner Name"),
    placeholder: i18n.t("Partner Name"),
    type: "text",
  },
  {
    id: "vendor_num",
    name: "vendor_num",
    label: i18n.t("Partner Code"),
    placeholder: i18n.t("Partner Code"),
    type: "text",
  },
  {
    id: "country_code",
    name: "country_code",
    label: i18n.t("Country"),
    placeholder: i18n.t("Country"),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: countries,
  },
  {
    id: "display_type",
    name: "display_type",
    label: i18n.t("Type of Organization"),
    placeholder: i18n.t("Type of Organization"),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerTypes,
  },
  {
    id: "specializations",
    name: "specializations",
    label: i18n.t("Sector & Area of Specialization"),
    placeholder: i18n.t("Sector & Area of Specialization"),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
];

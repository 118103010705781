import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import style from "./style.module.scss";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";

// POST_REFACTORING_TODO: Translations

const FieldSelection = ({ id, width, height, options, valueField, labelField, readOnly, input: { value, onChange }, meta }) => {
  const [filter, setFilter] = useState("");

  const onChangeFilter = (event) => setFilter(event.target.value);

  const selectedOptions = options.filter((item) => value.includes(item[valueField]));
  const nonSelectedOptions = options.filter((item) => !value.includes(item[valueField]));

  return (
    <div className={style.mainWrapper} style={{ width, height }}>
      <Paper style={{ width: "100%" }} elevation={0}>
        <div className={style.filterWrapper}>
          <FormControl fullWidth variant="outlined">
            <TextField id={id} value={filter ?? ""} onChange={onChangeFilter} label="Search" placeholder="Enter a filter" type="text" size="small" disabled={readOnly} />
          </FormControl>
        </div>
        <div className={style.listWrapper} style={(meta?.error ?? "") !== "" ? { borderColor: "#d32f2f" } : undefined}>
          <List dense>
            {selectedOptions.map((item, index) => (
              <ListItem key={`selection_${id}_list_item_${item.id}_${index}`}>
                <ListItemButton disabled={readOnly} selected={true} onClick={() => onChange(value.filter((element) => element !== item[valueField]))}>
                  {item[labelField]}
                </ListItemButton>
              </ListItem>
            ))}
            {selectedOptions.length ? <Divider /> : undefined}
            {nonSelectedOptions
              .filter((item) => item[labelField].toLowerCase().includes(filter.toLowerCase()))
              .map((item, index) => (
                <ListItem key={`selection_${id}_list_item_${item.id}_${index}`}>
                  <ListItemButton disabled={readOnly} selected={false} onClick={() => onChange([...value, item[valueField]])}>
                    {item[labelField]}
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </div>
      </Paper>
    </div>
  );
};

FieldSelection.propTypes = {};

export default FieldSelection;

import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import FieldFile from "@local/components/field-file";
import Button from "@local/components/button";
import { Grid } from "@mui/material";
import { RISK_FLAGS_REASONS } from "@local/legacy-utils/constants";
import { isRequired } from "@local/utils/validators";
import style from "./style.module.scss";

const ManageFlagModal = ({ isOpen, onClose, pristine, invalid, onSubmit, isEditable, onEditFlag }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.raiseFlagModalTitle",
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            component={FieldSelect}
            id="reason"
            name="reason"
            label={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.label",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.placeholder",
            })}
            valueField="value"
            labelField="label"
            options={RISK_FLAGS_REASONS}
            validate={[isRequired]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={FieldText}
            id="comment"
            name="comment"
            type="text"
            multiline
            rows={4}
            maxRows={6}
            maxLength={5000}
            withCharactersCount
            label={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.labelOne",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.placeholderOne",
            })}
            validate={[isRequired]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={FieldFile}
            id="attachments"
            name="attachments"
            label={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.button",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.button",
            })}
            multiple
          />
        </Grid>
      </Grid>
      <div className={style.submitButtonWrapper}>
        <Button
          variant="contained"
          onClick={isEditable ? onEditFlag : onSubmit}
          isDisabled={pristine || invalid}
          text={
            isEditable
              ? formatMessage({
                  id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.save",
                })
              : formatMessage({
                  id: "pages.partner.details.cmp.risk.cmp.flag.cmp.manage.flag.modal.idx.buttonOne",
                })
          }
        />
      </div>
    </Modal>
  );
};

ManageFlagModal.propTypes = {};

export default reduxForm({ form: "manageFlagForm", enableReinitialize: true })(ManageFlagModal);

import React from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { withRouter } from "react-router";
import Breadcrumb from "@local/components/breadcrumb";
import Table from "@local/components/table";
import { getFinancialDetailsColumns, getFinancialSecondaryColumns } from "../../utils/columns";
import { getUNDataList, getAgenciesList } from "@local/actions";
import { adapterFinancialDetails } from "../../utils/adapters";

const FinancialDetails = ({ partnerId, getUNDataList }) => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Breadcrumb
        items={[
          {
            id: 1,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.financial.details.idx.dashboardTitle",
            }),
            url: `/partner/${partnerId}/risk/dashboard`,
          },
          {
            id: 2,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.financial.details.idx.dashboardTitleOne",
            }),
            url: `/partner/${partnerId}/risk/financial`,
          },
        ]}
      />
      <Table
        key="risk_financial_details_list"
        id="risk_financial_details_list"
        title={formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.financial.details.idx.dashboardTitleOne",
        })}
        columns={getFinancialDetailsColumns(currentYear)}
        fetchFunction={getUNDataList}
        adapterFunction={adapterFinancialDetails}
        secondaryColumns={getFinancialSecondaryColumns()}
        isPaginated={false}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUNDataList: () => dispatch(getUNDataList(undefined, { id: ownProps.partnerId })),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
});

export default withRouter(connect(null, mapDispatchToProps)(FinancialDetails));

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory, useLocation } from "react-router";
import {
  getPartnerProfileDataDetails,
  getCountriesList,
  updatePartnerIdentification,
  updatePartnerMailing,
  updatePartnerMandateMission,
  updatePartnerFund,
  updatePartnerCollaboration,
  updatePartnerProjectImplementation,
  updatePartnerOtherInformation,
  getSectorsList,
} from "@local/actions";
import { useParams } from "react-router-dom";
import Container from "./components/container";
import Identification from "./components/identification";
import ContactInformation from "./components/contact-information";
import MandateMission from "./components/mandate-mission";
import Funding from "./components/funding";
import Collaboration from "./components/collaboration";
import ProjectImplementation from "./components/project-implementation";
import OtherInformation from "./components/other-information";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { loadUserData, sessionInitializing, sessionChange } from "@local/legacy-reducers/session";
import { adaptCountries, adaptBudgetTypes, adaptDonorTypes, adaptFinancialControlSystems, adaptAccountingMethods, adaptAreasOfResponsibility, adaptAreasOfPolicy, adaptAuditTypes, adaptCapacityAssessments } from "@local/utils/adapters";
import { getPartnerTypes, getConcernTypes, getYearsOfExperience, getNumberOfStaff, getUnAgencies, getJobTitleTypes, getOrganizationDescriptions } from "@local/utils/constants";
import {
  adaptWorkingLanguages,
  adaptIdentificationInitialFormValues,
  adaptContactInformationInitialFormValues,
  adaptMandateMissionInitialFormValues,
  adaptFundingInitialFormValues,
  adaptCollaborationInitialFormValues,
  adaptProjectImplementationInitialFormValues,
  adaptOtherInformationInitialFormValues,
  adaptIdentificationFormValuesToBeSent,
  adaptContactInformationFormValuesToBeSent,
  adaptMandateMissionFormValuesToBeSent,
  adaptFundingFormValuesToBeSent,
  adaptCollaborationFormValuesToBeSent,
  adaptProjectImplementationFormValuesToBeSent,
  adaptOtherInformationFormValuesToBeSent,
  adaptUNAgencies,
} from "./utils/adapters";
import Accordion from "@local/components/accordion";
import WarningIcon from "@mui/icons-material/Warning";
import Loader from "@local/components/loader";
import style from "./style.module.scss";
import { toast } from "react-toastify";
import { getErrors, getSortedList } from "@local/utils";
import Button from "@local/components/button";

const getSelectedTabIndex = (tabs, location, partnerId) => {
  const selectedTabIndex = tabs.findIndex((tab) => location.pathname.startsWith(`/profile/${partnerId}/edit/${tab.path}`));
  if (selectedTabIndex === -1) return 0;
  return selectedTabIndex;
};

const ProfileDetails = ({
  partner,
  getPartnerProfileData,
  forms,
  getCountries,
  countries,
  partnerTypes,
  identificationInitialFormValues,
  contactInformationInitialFormValues,
  mandateMissionInitialFormValues,
  fundingInitialFormValuesInitialFormValues,
  collaborationInitialFormValues,
  projectImplementationInitialFormValues,
  otherInformationInitialFormValues,
  isLoadingPartner,
  updatePartnerIdentification,
  updatePartnerMailing,
  updatePartnerMandateMission,
  updatePartnerFund,
  updatePartnerCollaboration,
  updatePartnerProjectImplementation,
  updatePartnerOtherInformation,
  sectors,
  getSectors,
  yearsOfExperience,
  populationOfConcern,
  numberOfStaff,
  agencies,
  languages,
  budgetTypes,
  donorTypes,
  financialControlSystems,
  accountingMethods,
  areasOfResponsibility,
  areasOfPolicy,
  auditTypes,
  capacityAssessments,
  inEditMode,
  isSwitchToHqButtonVisible,
  sessionHqId,
  sessionPartnerId,
  partnerId,
  saveCurrentProfile,
  loadUserInfo,
  startRefresh,
  stopRefresh,
  jobTitleTypes,
  organizationDescriptions,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    getPartnerProfileData();
    getCountries();
    getSectors();
  }, []);

  const onChangeTab = (event, index) => history.push(`/profile/${partnerId || id}/edit/${tabs[index].path}`);

  const onSubmit = async (exit, additionalParams) => {
    const refresh = async () => {
      const result = await getPartnerProfileData();
      startRefresh();
      loadUserInfo().then(() => {
        stopRefresh();
      });
      saveCurrentProfile({
        isProfileComplete: result?.data?.partner_additional.has_finished,
      });
    };
    try {
      const selectedTabIndex = getSelectedTabIndex(tabs, location, partnerId || id);
      const selectedTab = tabs[selectedTabIndex];
      let adaptedValues = undefined;
      switch (selectedTab.path) {
        case "identification":
          adaptedValues = adaptIdentificationFormValuesToBeSent(forms.editPartnerProfileIdentificationForm?.values, additionalParams);
          await updatePartnerIdentification(adaptedValues);
          refresh();
          break;
        case "contact":
          adaptedValues = adaptContactInformationFormValuesToBeSent(forms.editPartnerProfileContactInformationForm?.values, additionalParams);
          await updatePartnerMailing(adaptedValues);
          refresh();
          break;
        case "mandate":
          adaptedValues = adaptMandateMissionFormValuesToBeSent(forms.editPartnerProfileMandateMissionForm?.values, additionalParams);
          await updatePartnerMandateMission(adaptedValues);
          refresh();
          break;
        case "funding":
          adaptedValues = adaptFundingFormValuesToBeSent(forms.editPartnerProfileFundingForm?.values, partner);
          await updatePartnerFund(adaptedValues);
          refresh();
          break;
        case "collaboration":
          adaptedValues = adaptCollaborationFormValuesToBeSent(forms.editPartnerProfileCollaborationForm?.values, additionalParams);
          await updatePartnerCollaboration(adaptedValues);
          refresh();
          break;
        case "project":
          adaptedValues = adaptProjectImplementationFormValuesToBeSent(forms.editPartnerProfileProjectImplementationForm?.values, additionalParams, areasOfResponsibility, areasOfPolicy, partner);
          await updatePartnerProjectImplementation(adaptedValues);
          refresh();
          break;
        case "other":
          adaptedValues = adaptOtherInformationFormValuesToBeSent(forms.editPartnerProfileOtherInformationForm?.values, partner, additionalParams);
          await updatePartnerOtherInformation(adaptedValues);
          refresh();
          break;
        default:
          content = <></>;
          break;
      }
      if (exit) {
        history.push(`/profile/${partnerId || id}/overview`);
      } else {
        history.push(tabs[selectedTabIndex + 1]?.path ?? `/profile/${partnerId || id}/overview`);
      }
    } catch (error) {
      const errorMessages = getErrors(error ?? {}).join(" ");
      toast.error(
        errorMessages ||
          formatMessage({
            id: "pages.partner.details.cmp.profile.details.idx.error",
          }),
      );
    }
  };

  const onSubmitAndContinue = (additionalParams) => {
    onSubmit(false, additionalParams);
  };

  const onSubmitAndExit = (additionalParams) => {
    onSubmit(true, additionalParams);
  };

  const onClickSwitchButton = () => {
    const toNavigateId = parseInt(partnerId || id) === sessionPartnerId ? sessionHqId : sessionPartnerId;
    window.location.assign(`/profile/${toNavigateId}/overview`);
  };

  const isBudgetRequired = () => {
    if (forms?.editPartnerProfileFundingForm?.values) {
      return Object.keys(forms.editPartnerProfileFundingForm.values).filter((key) => key.startsWith("budgets_budget") && forms.editPartnerProfileFundingForm.values[key]).length < 3;
    }
  };

  const tabs = [
    {
      id: "partner_profile_details_identification",
      path: "identification",
      extraIcon:
        partner.identification_is_complete === false ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : undefined,
      label: formatMessage({
        id: "pages.partner.details.cmp.profile.details.idx.identification",
      }),
      content: (
        <Identification
          isLoading={isLoadingPartner}
          inEditMode={inEditMode}
          initialValues={identificationInitialFormValues.formValues}
          stateValues={identificationInitialFormValues.stateValues}
          formValues={forms.editPartnerProfileIdentificationForm?.values ?? {}}
          partner={partner}
          onSubmitAndContinue={onSubmitAndContinue}
          onSubmitAndExit={onSubmitAndExit}
          countries={countries}
          partnerTypes={partnerTypes}
        />
      ),
    },
    {
      id: "partner_profile_details_contact",
      path: "contact",
      extraIcon:
        partner.contact_is_complete === false ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : undefined,
      label: formatMessage({
        id: "pages.partner.details.cmp.profile.details.idx.contactInformation",
      }),
      content: (
        <ContactInformation
          isLoading={isLoadingPartner}
          inEditMode={inEditMode}
          initialValues={contactInformationInitialFormValues.formValues}
          stateValues={contactInformationInitialFormValues.stateValues}
          formValues={forms.editPartnerProfileContactInformationForm?.values ?? {}}
          partner={partner}
          onSubmitAndContinue={onSubmitAndContinue}
          onSubmitAndExit={onSubmitAndExit}
          countries={countries}
          languages={languages}
          jobTitleTypes={jobTitleTypes}
        />
      ),
    },
    {
      id: "partner_profile_details_mandate",
      path: "mandate",
      extraIcon:
        partner.mandatemission_complete === false ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : undefined,
      label: formatMessage({
        id: "pages.partner.details.cmp.profile.details.idx.mandateMission",
      }),
      content: (
        <MandateMission
          isLoading={isLoadingPartner}
          inEditMode={inEditMode}
          initialValues={mandateMissionInitialFormValues.formValues}
          stateValues={mandateMissionInitialFormValues.stateValues}
          formValues={forms.editPartnerProfileMandateMissionForm?.values ?? {}}
          partner={partner}
          onSubmitAndContinue={onSubmitAndContinue}
          onSubmitAndExit={onSubmitAndExit}
          countries={countries}
          sectors={sectors}
          yearsOfExperience={yearsOfExperience}
          populationOfConcern={populationOfConcern}
          numberOfStaff={numberOfStaff}
          organizationDescriptions={organizationDescriptions}
        />
      ),
    },
    {
      id: "partner_profile_details_funding",
      path: "funding",
      extraIcon:
        partner.funding_complete === false ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : undefined,
      label: formatMessage({
        id: "pages.partner.details.cmp.profile.details.idx.funding",
      }),
      content: (
        <Funding
          isLoading={isLoadingPartner}
          inEditMode={inEditMode}
          initialValues={fundingInitialFormValuesInitialFormValues.formValues}
          partner={partner}
          onSubmitAndContinue={onSubmitAndContinue}
          onSubmitAndExit={onSubmitAndExit}
          budgetTypes={budgetTypes}
          donorTypes={donorTypes}
          isBudgetRequired={isBudgetRequired()}
        />
      ),
    },
    {
      id: "partner_profile_details_collaboration",
      path: "collaboration",
      extraIcon:
        partner.collaboration_complete === false ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : undefined,
      label: formatMessage({
        id: "pages.partner.details.cmp.profile.details.idx.collaboration",
      }),
      content: (
        <Collaboration
          isLoading={isLoadingPartner}
          inEditMode={inEditMode}
          initialValues={collaborationInitialFormValues.formValues}
          stateValues={collaborationInitialFormValues.stateValues}
          formValues={forms.editPartnerProfileCollaborationForm?.values ?? {}}
          onSubmitAndContinue={onSubmitAndContinue}
          onSubmitAndExit={onSubmitAndExit}
          agencies={agencies}
        />
      ),
    },
    {
      id: "partner_profile_details_project",
      path: "project",
      extraIcon:
        partner.proj_impl_is_complete === false ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : undefined,
      label: formatMessage({
        id: "pages.partner.details.cmp.profile.details.idx.projectImplementation",
      }),
      content: (
        <ProjectImplementation
          isLoading={isLoadingPartner}
          inEditMode={inEditMode}
          initialValues={projectImplementationInitialFormValues.formValues}
          stateValues={projectImplementationInitialFormValues.stateValues}
          formValues={forms.editPartnerProfileProjectImplementationForm?.values ?? {}}
          onSubmitAndContinue={onSubmitAndContinue}
          onSubmitAndExit={onSubmitAndExit}
          financialControlSystems={financialControlSystems}
          accountingMethods={accountingMethods}
          areasOfResponsibility={areasOfResponsibility}
          areasOfPolicy={areasOfPolicy}
          auditTypes={auditTypes}
          capacityAssessments={capacityAssessments}
        />
      ),
    },
    {
      id: "partner_profile_details_other",
      path: "other",
      extraIcon:
        partner.other_info_is_complete === false ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : undefined,
      label: formatMessage({
        id: "pages.partner.details.cmp.profile.details.idx.otherInformation",
      }),
      content: (
        <OtherInformation
          isLoading={isLoadingPartner}
          inEditMode={inEditMode}
          onSubmitAndExit={onSubmitAndExit}
          initialValues={otherInformationInitialFormValues.formValues}
          stateValues={otherInformationInitialFormValues.stateValues}
          formValues={forms.editPartnerProfileOtherInformationForm?.values ?? {}}
          partner={partner}
        />
      ),
    },
  ];

  const selectedTabIndex = getSelectedTabIndex(tabs, location, partnerId || id);
  let content = tabs[selectedTabIndex]?.content;

  if (inEditMode) {
    return (
      <Container title={partner.legal_name} tabs={tabs} selectedTab={selectedTabIndex} onChangeTab={onChangeTab} inEditMode={inEditMode}>
        {content}
      </Container>
    );
  } else if (isLoadingPartner) {
    return <Loader isVisible isFullScreen />;
  } else {
    return (
      <>
        {isSwitchToHqButtonVisible ? (
          <div className={style.switchToHqButtonWrapper}>
            <Button
              text={
                parseInt(partnerId || id) === sessionPartnerId
                  ? formatMessage({
                      id: "pages.partner.details.cmp.profile.details.idx.toHq",
                    })
                  : formatMessage({
                      id: "pages.partner.details.cmp.profile.details.idx.toCountry",
                    })
              }
              variant="text"
              onClick={onClickSwitchButton}
            />
          </div>
        ) : (
          <></>
        )}
        <Accordion id="profile_details" items={tabs} />
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  partner: state.partnerProfileDataDetails.data?.data ?? {},
  isLoadingPartner: state.partnerProfileDataDetails.loading ?? false,
  identificationInitialFormValues: adaptIdentificationInitialFormValues(state.partnerProfileDataDetails.data?.data),
  contactInformationInitialFormValues: adaptContactInformationInitialFormValues(state.partnerProfileDataDetails.data?.data),
  mandateMissionInitialFormValues: adaptMandateMissionInitialFormValues(state.partnerProfileDataDetails.data?.data),
  fundingInitialFormValuesInitialFormValues: adaptFundingInitialFormValues(state.partnerProfileDataDetails.data?.data),
  collaborationInitialFormValues: adaptCollaborationInitialFormValues(state.partnerProfileDataDetails.data?.data),
  projectImplementationInitialFormValues: adaptProjectImplementationInitialFormValues(state.partnerProfileDataDetails.data?.data),
  otherInformationInitialFormValues: adaptOtherInformationInitialFormValues(state.partnerProfileDataDetails.data?.data),
  forms: state?.form ?? {},
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  partnerTypes: getPartnerTypes(),
  sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
  yearsOfExperience: getYearsOfExperience(),
  populationOfConcern: getConcernTypes(),
  numberOfStaff: getNumberOfStaff(),
  agencies: adaptUNAgencies(getUnAgencies()),
  jobTitleTypes: getJobTitleTypes(),
  organizationDescriptions: getOrganizationDescriptions(),
  languages: adaptWorkingLanguages(state.partnerProfileConfig["working-languages"]),
  budgetTypes: adaptBudgetTypes(state.partnerProfileConfig["budget-choices"]),
  donorTypes: adaptDonorTypes(state.partnerProfileConfig["partner-donors"]),
  financialControlSystems: adaptFinancialControlSystems(state.partnerProfileConfig["financial-control-system-choices"]),
  accountingMethods: adaptAccountingMethods(state.partnerProfileConfig["method-acc-adopted-choices"]),
  areasOfResponsibility: adaptAreasOfResponsibility(state.partnerProfileConfig["functional-responsibility-choices"]),
  areasOfPolicy: adaptAreasOfPolicy(state.partnerProfileConfig["policy-area-choices"]),
  auditTypes: adaptAuditTypes(state.partnerProfileConfig["audit-types"]),
  capacityAssessments: adaptCapacityAssessments(state.partnerProfileConfig["formal-capacity-assessment"]),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPartnerProfileData: () =>
    dispatch(
      getPartnerProfileDataDetails(undefined, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  getCountries: () => dispatch(getCountriesList()),
  updatePartnerIdentification: (params) =>
    dispatch(
      updatePartnerIdentification(params, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  updatePartnerMailing: (params) =>
    dispatch(
      updatePartnerMailing(params, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  updatePartnerMandateMission: (params) =>
    dispatch(
      updatePartnerMandateMission(params, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  updatePartnerFund: (params) =>
    dispatch(
      updatePartnerFund(params, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  updatePartnerCollaboration: (params) =>
    dispatch(
      updatePartnerCollaboration(params, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  updatePartnerProjectImplementation: (params) =>
    dispatch(
      updatePartnerProjectImplementation(params, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  updatePartnerOtherInformation: (params) =>
    dispatch(
      updatePartnerOtherInformation(params, {
        id: ownProps.partnerId || ownProps.match.params.id,
      }),
    ),
  getSectors: (params) => dispatch(getSectorsList(params)),
  saveCurrentProfile: (session) => dispatch(sessionChange(session)),
  loadUserInfo: () => dispatch(loadUserData()),
  startRefresh: () => dispatch(sessionInitializing()),
  stopRefresh: () => dispatch(sessionChange()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileDetails));

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { PROJECT_TYPES } from "@local/utils/constants";
import { createOpenProject, createDirectProject, updateOpenProject, updateDirectProject, createUnsolicitedApplication, updateUnsolicitedApplication } from "@local/actions";

export const getModalTitle = (type) => {
  switch (type) {
    case PROJECT_TYPES.OPEN:
      return formatMessage({
        id: "pages.projects.list.cmp.manage.project.modal.utils.idx.open",
      });
    case PROJECT_TYPES.DIRECT:
      return formatMessage({
        id: "pages.projects.list.cmp.manage.project.modal.utils.idx.direct",
      });
    case PROJECT_TYPES.UNSOLICITED:
      return formatMessage({
        id: "pages.projects.list.cmp.manage.project.modal.utils.idx.unsolicited",
      });
    default:
      return "";
  }
};

export const getModalInformationText = (type) => {
  switch (type) {
    case PROJECT_TYPES.OPEN:
      return formatMessage({
        id: "pages.projects.list.cmp.manage.project.modal.utils.idx.thisCfeiIsForOpenSelections",
      });
    case PROJECT_TYPES.DIRECT:
      return "";
    case PROJECT_TYPES.UNSOLICITED:
      return "";
    default:
      return formatMessage({
        id: "pages.projects.list.cmp.manage.project.modal.utils.idx.thisCfeiIsForOpenSelections",
      });
  }
};

export const getModalSaveFunction = (type, onEditId) => {
  if (onEditId) {
    switch (type) {
      case PROJECT_TYPES.OPEN:
        return updateOpenProject;
      case PROJECT_TYPES.DIRECT:
        return updateDirectProject;
      case PROJECT_TYPES.UNSOLICITED:
        return updateUnsolicitedApplication;
      default:
        return undefined;
    }
  } else {
    switch (type) {
      case PROJECT_TYPES.OPEN:
        return createOpenProject;
      case PROJECT_TYPES.DIRECT:
        return createDirectProject;
      case PROJECT_TYPES.UNSOLICITED:
        return createUnsolicitedApplication;
      default:
        return undefined;
    }
  }
};

import moment from "moment";

export const printFormat = "DD MMM YYYY";
const DATE_FORMAT = "DD-MM-YYYY";
const CHART_FORMAT = "MM/DD";

export const normalizeDateFormat = (date) => {
  if (!date) return null;
  return moment(date).format(DATE_FORMAT).toString();
};

export const formatDateForPrint = (date) => {
  if (!date || date === "-") return date;
  return moment(date).format(printFormat).toString();
};

export const formatDateForChart = (date) => {
  if (!date) return null;
  return moment(date).format(CHART_FORMAT).toString();
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import SelfAssessment from "./components/self-assessment";
import Csip from "./components/csip";
import FinalDetermination from "./components/final-determination";
import InitialAssessment from "./components/initial-assessment";
import { getPseaInitialAssessmentDetails, getPseaSelfAssessmentDetails, updatePseaSelfAssessment, getPseaDetails, getPseaSelfAssessmentJustificationsList, getPseaSelfAssessmentDocumentsList } from "@local/actions";
import { connect } from "react-redux";
import { checkPermission, AGENCY_PERMISSIONS, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import { ROLES } from "@local/legacy-utils/constants";
import SectionContainer from "./components/section-container";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import { getFinalDeterminationRevision, getInitialAssessmentJustification, getSelfAssessmentJustification, getSixMonthsJustification, getNineMonthsJustification, getSelfAssessmentRevisions } from "../../utils";

const isInitialAssessmentCompleted = (pseaInitialAssessment, hasViewPseaSelfAssessmentPermission) => {
  if (!pseaInitialAssessment?.id) {
    return false;
  }
  if (!hasViewPseaSelfAssessmentPermission) {
    return false;
  }
  if (pseaInitialAssessment?.partner_beneficiaries === true && pseaInitialAssessment?.partner_is_assessed === false) {
    return true;
  }
  if (pseaInitialAssessment?.partner_beneficiaries === false && pseaInitialAssessment?.proceed_with_self_assessment === true) {
    return true;
  }
  return true;
};

const getSections = (partnerId, pseaInitialAssessment, pseaSelfAssessment, hasViewPseaSelfAssessmentPermission, hasViewPseaCsipPermission, revisions, csipStartDate) => {
  const selfAssessmentRevisions = getSelfAssessmentRevisions(revisions);
  const initialAssessmentJustification = getInitialAssessmentJustification(revisions);
  const selfAssessmentJustification = getSelfAssessmentJustification(revisions);
  const sixMonthsJustification = getSixMonthsJustification(revisions);
  const nineMonthsJustification = getNineMonthsJustification(revisions);
  const finalDeterminationRevision = getFinalDeterminationRevision(revisions);
  const isJustificationNeeded = finalDeterminationRevision?.revision_rating !== "full" && finalDeterminationRevision?.revision_rating !== "low_risk";
  const result = [];
  result.push(
    <InitialAssessment
      partnerId={partnerId}
      revisionToJustify={
        isJustificationNeeded && !selfAssessmentJustification && !sixMonthsJustification && !nineMonthsJustification ? initialAssessmentJustification : initialAssessmentJustification?.justification_to_proceed ? initialAssessmentJustification : undefined
      }
      isAddJustificationAvailable={isJustificationNeeded && !selfAssessmentJustification && !sixMonthsJustification && !nineMonthsJustification}
    />,
  );
  if (isInitialAssessmentCompleted(pseaInitialAssessment, hasViewPseaSelfAssessmentPermission)) {
    result.push(
      <SelfAssessment
        partnerId={partnerId}
        pseaInitialAssessment={pseaInitialAssessment}
        selfAssessmentRevisions={selfAssessmentRevisions}
        revisionToJustify={isJustificationNeeded && !sixMonthsJustification && !nineMonthsJustification ? selfAssessmentJustification : selfAssessmentJustification?.justification_to_proceed ? selfAssessmentJustification : undefined}
        isAddJustificationAvailable={isJustificationNeeded && !sixMonthsJustification && !nineMonthsJustification}
      />,
    );
  } else {
    result.push(
      <SectionContainer
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.idx.preliminaryAssessment",
        })}
        emptyLabel={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.idx.preliminaryAssessmentNotAvailable",
        })}
        isEmpty
      />,
    );
  }
  if ((csipStartDate || pseaSelfAssessment?.is_finalized_by_agency) && hasViewPseaCsipPermission) {
    result.push(
      <Csip
        partnerId={partnerId}
        revisionToJustifySixMonths={isJustificationNeeded ? sixMonthsJustification : sixMonthsJustification?.justification_to_proceed ? sixMonthsJustification : undefined}
        revisionToJustifyNineMonths={isJustificationNeeded ? nineMonthsJustification : nineMonthsJustification?.justification_to_proceed ? nineMonthsJustification : undefined}
      />,
    );
  } else {
    result.push(
      <SectionContainer
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.idx.capacityStrength",
        })}
        isEmpty
      />,
    );
  }
  result.push(<FinalDetermination partnerId={partnerId} />);
  return result;
};

const Main = ({
  partnerId,
  pseaInitialAssessment,
  getPseaInitialAssessmentDetails,
  pseaSelfAssessment,
  getPseaSelfAssessmentDetails,
  hasViewPseaSelfAssessmentPermission,
  hasViewPseaCsipPermission,
  getPseaSelfAssessmentDocumentsList,
  getPseaDetails,
  getPseaSelfAssessmentJustifications,
  psea,
}) => {
  useEffect(() => {
    getPseaInitialAssessmentDetails();
    getPseaSelfAssessmentDetails();
    getPseaSelfAssessmentDocumentsList();
    getPseaDetails();
    getPseaSelfAssessmentJustifications();
  }, []);

  const sections = getSections(partnerId, pseaInitialAssessment, pseaSelfAssessment, hasViewPseaSelfAssessmentPermission, hasViewPseaCsipPermission, psea?.revisions ?? [], psea?.csip_start_date);

  if (!sections.length) return <></>;

  return (
    <div className={style.sectionsWrapper}>
      {sections.map((item, index) => (
        <div key={`psea_section_${index}`}>{item}</div>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  const role = state?.session?.role;
  return {
    role: role,
    pseaInitialAssessment: state?.pseaInitialAssessmentDetails?.data?.data ?? undefined,
    pseaSelfAssessment: state?.pseaSelfAssessmentDetails?.data?.data ?? undefined,
    psea: state.pseaDetails.data?.data ?? {},
    hasViewPseaSelfAssessmentPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.VIEW_PSEA_SELF_ASSESSMENT, state) : checkPermission(PARTNER_PERMISSIONS.VIEW_PSEA_SELF_ASSESSMENT, state),
    hasViewPseaCsipPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.VIEW_PSEA_CSIP, state) : checkPermission(PARTNER_PERMISSIONS.VIEW_PSEA_CSIP, state),
    hasUnlockPseaSelfAssessmentAgencySectionPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.UNLOCK_PSEA_SELF_ASSESSMENT_AGENCY_SECTION, state) : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPseaInitialAssessmentDetails: () =>
    dispatch(
      getPseaInitialAssessmentDetails(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
  getPseaSelfAssessmentDetails: () =>
    dispatch(
      getPseaSelfAssessmentDetails(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
  updatePseaSelfAssessment: (data) => dispatch(updatePseaSelfAssessment(data, { partnerId: ownProps.partnerId })),
  getPseaDetails: () => dispatch(getPseaDetails(undefined, { partnerId: ownProps.partnerId })),
  getPseaSelfAssessmentJustifications: () => dispatch(getPseaSelfAssessmentJustificationsList()),
  getPseaSelfAssessmentDocumentsList: () =>
    dispatch(
      getPseaSelfAssessmentDocumentsList(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);

/* eslint-disable no-case-declarations */
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import countriesCodes from "@local/assets/json/countries-codes.json";
import countriesBoxes from "@local/assets/json/countries-boxes.json";
import { getReverseGeocode } from "@local/utils/network";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { errorSpecificMessages } from "./constants";

export const getBooleanValue = (text) => {
  if (text === true || text === "true" || text === 1 || text === "1") return true;
  return false;
};

export const formatNumber = (number) =>
  number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const getKeyName = (prefix, index) => `${prefix}_${index}`;

export const formatDateForFiltering = (date) => (isValid(date) ? format(date, "yyyy-MM-dd") : undefined);

export const formatDateToBeSent = (date) => (isValid(date) ? format(date, "yyyy-MM-dd") : undefined);

export const formatDate = (date) => new Date(date).toLocaleDateString();

export const formatDateWithTime = (date) => `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString()}`;

export const getCountryBoundingBox = (country) => {
  const countryCode = countriesCodes.find((item) => item["alpha-2"] === country);
  const countryBox = countryCode ? countriesBoxes[countryCode["alpha-3"]] : undefined;
  return countryBox
    ? [
        [countryBox.sw.lat, countryBox.sw.lon],
        [countryBox.ne.lat, countryBox.ne.lon],
      ]
    : undefined;
};

export const getSortedList = (list, key) => list?.sort((a, b) => a[key] > b[key]) ?? [];

export const getRandomColorByIndex = (index, opacityPercentage) => {
  const colors = [
    "#6a5d4d",
    "#cc7d15",
    "#800080",
    "#781f19",
    "#096b8b",
    "#d81153",
    "#ff69b4",
    "#8b0000",
    "#1717d3",
    "#1cb31c",
    "#288b82",
    "#6a5acd",
    "#ee0384",
    "#868630",
    "#17928e",
    "#149655",
    "#8d3f8d",
    "#8b008b",
    "#00008b",
    "#b8860b",
    "#135a5a",
    "#2e8b57",
    "#ffff00",
    "#3f2929",
    "#534429",
    "#7cfc00",
    "#4169e1",
    "#2b8b8b",
    "#632525",
    "#574423",
    "#adff2f",
    "#87cefa",
    "#483d8b",
    "#800000",
    "#ffd700",
    "#778899",
    "#5f9ea0",
    "#3cb371",
    "#7e2222",
    "#9acd32",
    "#ff4500",
    "#42301d",
    "#4b0082",
    "#a0522d",
    "#747424",
    "#461119",
    "#ffa07a",
    "#142929",
    "#c71585",
    "#ba55d3",
  ];
  const computedIndex = index % colors.length;
  return `${colors[computedIndex]}${opacityPercentage ? Math.round((255 / 100) * opacityPercentage).toString(16) : "ff"}`;
};

export const getLocationObject = async (location, countries, selectedCountries) => {
  try {
    const params = {
      latitude: location.latitude,
      longitude: location.longitude,
      localityLanguage: "en",
    };
    const latitude = location.latitude.toFixed(5);
    const longitude = location.longitude.toFixed(5);
    const domain = window.location.hostname;
    let mapData;
    switch (domain) {
      case "dev.unpartnerportal.org":
        mapData = {
          id: `marker_${new Date()}`,
          label: "Region, Country (IT)",
          adminLevelOne: {
            name: "Region",
            countryCode: "IT",
          },
          latitude,
          longitude,
        };
        break;
      case "localhost":
        mapData = {
          id: `marker_${new Date()}`,
          label: "Region, Country (IT)",
          adminLevelOne: {
            name: "Region",
            countryCode: "IT",
          },
          latitude,
          longitude,
        };
        break;
      default:
        const result = await getReverseGeocode(params);
        const mapRegion = (result?.data?.principalSubdivision || result?.data?.countryName) ?? undefined;
        let mapCountry = countries.find((item) => item.value === result?.data?.countryCode) ?? undefined;
        if (
          mapRegion &&
          selectedCountries?.length &&
          ((mapCountry?.value && selectedCountries?.includes(mapCountry?.value)) ||
            (selectedCountries?.includes("XK") && mapCountry === undefined) ||
            (selectedCountries?.includes("PS") && mapCountry?.value === "IL") ||
            (selectedCountries?.includes("IL") && mapCountry?.value === "PS"))
        ) {
          if (selectedCountries?.includes("XK") && mapCountry === undefined) mapCountry = { value: "XK", label: "Kosovo - UNSCR1244" };
          mapData = {
            id: `marker_${latitude}_${longitude}`,
            label: `${mapRegion}, ${mapCountry?.label} (${mapCountry?.value})`,
            adminLevelOne: {
              name: mapRegion,
              countryCode: mapCountry?.value,
            },
            latitude,
            longitude,
          };
        } else {
          throw new Error("Please indicate locations within the geographic boundaries of the selected Country"); // POST_REFACTORING_TODO: Translate this
        }
    }
    return mapData;
  } catch (error) {
    throw new Error(error?.message || formatMessage({ id: "utils.idx.error" }));
  }
};

export const getErrors = (error) => {
  let errors = [];

  const extract = (obj) => {
    if (Object.prototype.hasOwnProperty.call(obj, "message")) {
      errors.push(obj.message);
    } else if (Object.prototype.hasOwnProperty.call(obj, "detail")) {
      errors.push(obj.detail);
    } else {
      for (const key in obj) {
        const value = obj[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (typeof item === "string") {
              errors.push(item);
            } else if (typeof item === "object") {
              extract(item);
            }
          });
        } else if (typeof value === "object") {
          extract(value);
        } else if (typeof value === "string") {
          extract(value);
        }
      }
    }
  };

  if (typeof error === "object") {
    extract(error?.response?.data);
  }

  /* Filter to only include the specific error message if it is in the errorSpecificMessaged array
     otherwise return all errors
  */
  const filteredErrors = errors.filter((err) => errorSpecificMessages.includes(err));
  return filteredErrors.length > 0 ? filteredErrors : errors;
};

export const getColorByHighlightType = (highlightType) => {
  switch (highlightType) {
    case "basic":
      return "#ffbd47";
    case "advanced":
      return "#019587";
    default:
      return undefined;
  }
};

export const getUrlFromFileField = (fileField) => (window.location.hostname === "localhost" ? `http://localhost:8000${fileField}` : fileField);

import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage as FM } from "react-intl";

const FormattedMessage = (props, context) => {
  const { id, defaultMessage, values } = props;
  const { intl } = context;

  if (!intl) {
    return false;
  } else if (intl.messages[id] !== undefined && defaultMessage !== undefined && values !== undefined) {
    return <FM id={id} defaultMessage={defaultMessage} values={values} />;
  } else if (intl.messages[id] !== undefined && values !== undefined) {
    return <FM id={id} values={values} />;
  } else if (intl.messages[id] !== undefined) {
    return <FM id={id} />;
  } else {
    return <FM id={"default.fallback"} />;
  }
};

FormattedMessage.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default FormattedMessage;

import { Grid } from "@mui/material";

import { Field, change, reduxForm } from "redux-form";
import FieldDatePicker from "@local/components/field-date-picker";
import { isRequired, isValidDateEditMode } from "@local/utils/validators";
import { connect } from "react-redux";
import Button from "@local/components/button";
import style from "./style.module.scss";
import Box from "@local/components/box";
import { updateProjectDeadline } from "@local/actions";
import { formatDateToBeSent } from "@local/utils";
import ConfirmModal from "@local/components/confirm-modal";
import { useState } from "react";
import { toast } from "react-toastify";
import i18n from "@local/i18next/i18n";

const ProjectForm = ({ updateProjectDeadline, formValues, clearDeadlineDate, clearNotificationResultsDate, clearStartDate, clearEndDate }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const onChangeExtraClarificationRequestDeadlineDate = () => {
    clearDeadlineDate();
    clearNotificationResultsDate();
    clearStartDate();
    clearEndDate();
  };

  const onChangeExtraClearDeadlineDate = () => {
    clearNotificationResultsDate();
    clearStartDate();
    clearEndDate();
  };

  const onChangeExtraClearNotificationResultsDate = () => {
    clearStartDate();
    clearEndDate();
  };

  const onChangeExtraClearStartDate = () => {
    clearEndDate();
  };
  const isValidForm = (formValues) => {
    if (formValues?.["clarification_request_deadline_date"] && formValues?.["deadline_date"] && formValues?.["notif_results_date"] && formValues?.["start_date"] && formValues?.["end_date"]) {
      return true;
    }
    return false;
  };
  return (
    <>
      <div className={style.modalContent}>
        <Box
          title={i18n.t("Deadlines")}
          content={
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Field
                        component={FieldDatePicker}
                        onChangeExtra={onChangeExtraClarificationRequestDeadlineDate}
                        minimumDate={new Date()}
                        validate={[isRequired, isValidDateEditMode]}
                        id="clarification_request_deadline_date"
                        name="clarification_request_deadline_date"
                        label={i18n.t("Request for clarification deadline")}
                        placeholder={i18n.t("Pick a date")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={FieldDatePicker}
                        validate={[isRequired, isValidDateEditMode]}
                        minimumDate={new Date(formValues?.["clarification_request_deadline_date"])}
                        onChangeExtra={onChangeExtraClearDeadlineDate}
                        id="deadline_date"
                        name="deadline_date"
                        label={i18n.t("Application Deadline")}
                        placeholder={i18n.t("Pick a date")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={FieldDatePicker}
                        validate={[isRequired, isValidDateEditMode]}
                        minimumDate={new Date(formValues?.["deadline_date"])}
                        onChangeExtra={onChangeExtraClearNotificationResultsDate}
                        id="notif_results_date"
                        name="notif_results_date"
                        label={i18n.t("Notification of Results")}
                        placeholder={i18n.t("Pick a date")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={FieldDatePicker}
                        validate={[isRequired, isValidDateEditMode]}
                        minimumDate={new Date(formValues?.["notif_results_date"])}
                        onChangeExtra={onChangeExtraClearStartDate}
                        id="start_date"
                        name="start_date"
                        label={i18n.t("Estimated Start Date")}
                        placeholder={i18n.t("Pick a date")}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field component={FieldDatePicker} validate={[isRequired, isValidDateEditMode]} minimumDate={new Date(formValues?.["start_date"])} id="end_date" name="end_date" label={i18n.t("Estimated End Date")} placeholder={i18n.t("Pick a date")} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
        />
        <div>
          <Button isDisabled={!isValidForm(formValues)} className={style.updateButton} text={i18n.t("Update")} onClick={() => setIsConfirmModalOpen(true)} />
        </div>
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onSubmit={() => {
          updateProjectDeadline(formValues);
          setIsConfirmModalOpen(false);
        }}
        onClose={() => setIsConfirmModalOpen(false)}
        message={"Are you sure you want to update Project's deadline date?"}
        type="info"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  formValues: state?.form?.projectAdminModalForm?.values,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearDeadlineDate: () => dispatch(change("projectAdminModalForm", "deadline_date", null)),
  clearNotificationResultsDate: () => dispatch(change("projectAdminModalForm", "notif_results_date", null)),
  clearStartDate: () => dispatch(change("projectAdminModalForm", "start_date", null)),
  clearEndDate: () => dispatch(change("projectAdminModalForm", "end_date", null)),
  updateProjectDeadline: async (body) => {
    try {
      await dispatch(
        updateProjectDeadline(
          {
            clarification_request_deadline_date: formatDateToBeSent(body?.clarification_request_deadline_date),
            deadline_date: formatDateToBeSent(body?.deadline_date),
            notif_results_date: formatDateToBeSent(body?.notif_results_date),
            start_date: formatDateToBeSent(new Date(body?.start_date)),
            end_date: formatDateToBeSent(body?.end_date),
          },
          { id: body?.id },
        ),
      );
      toast.success(i18n.t("Update success"));
      ownProps.setShouldRefresh(true);
    } catch (error) {
      toast.error(i18n.t("Update failed"));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "projectAdminModalForm", enableReinitialize: true })(ProjectForm));

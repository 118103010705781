import React, { useState } from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import Alert from "@local/components/alert";
import FieldAutosuggest from "@local/components/field-autosuggest";
import style from "./style.module.scss";

const getToBeRemovedReviewersList = (initialReviewers, reviewers) => initialReviewers.filter((initialReviewer) => !reviewers.find((reviewer) => initialReviewer.id === reviewer.id));

const ManageReviewersModal = ({ isOpen, onClose, formValues, onSubmit, fetchReviewersFunction, adaptReviewersFunction, initialReviewers }) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [toBeRemovedReviewersList, setToBeRemovedReviewersList] = useState([]);

  const onClickSubmit = () => {
    const toRemoveList = getToBeRemovedReviewersList(initialReviewers, formValues?.reviewers);
    if (toRemoveList.length) {
      setIsConfirmationModalOpen(true);
      setToBeRemovedReviewersList(toRemoveList);
    } else {
      setIsConfirmationModalOpen(false);
      setToBeRemovedReviewersList([]);
      onSubmit();
    }
  };

  if (isConfirmationModalOpen) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={formatMessage({
          id: "pages.project.details.cmp.manage.reviewers.modal.idx.confirmationTitle",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClose}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.manage.reviewers.modal.idx.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={onSubmit}
                text={formatMessage({
                  id: "pages.project.details.cmp.manage.reviewers.modal.idx.send",
                })}
              />
            </div>
          </>
        }
      >
        <div>
          <p>
            {formatMessage({
              id: "pages.project.details.cmp.manage.reviewers.modal.idx.confirmationMessage",
            })}
          </p>
          <div>
            {toBeRemovedReviewersList.map((item) => (
              <span key={`to_remove_reviewer_${item.id}`} className={style.reviewerToDeleteItem}>
                {item.name}
              </span>
            ))}
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.project.details.cmp.manage.reviewers.modal.idx.title",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onClose}
              variant="text"
              text={formatMessage({
                id: "pages.project.details.cmp.manage.reviewers.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={formValues?.reviewers?.length === 0 ?? true}
              onClick={onClickSubmit}
              text={formatMessage({
                id: "pages.project.details.cmp.manage.reviewers.modal.idx.send",
              })}
            />
          </div>
        </>
      }
    >
      <Alert
        type="info"
        content={formatMessage({
          id: "pages.project.details.cmp.manage.reviewers.modal.idx.header",
        })}
        withBottomMargin
      />
      <FieldGroup>
        <Field
          component={FieldAutosuggest}
          id="reviewers"
          name="reviewers"
          label={formatMessage({
            id: "pages.project.details.cmp.manage.reviewers.modal.idx.selectReviewers",
          })}
          fetchFunction={fetchReviewersFunction}
          adapterFunction={adaptReviewersFunction}
          valueField="id"
          labelField="legal_name"
          multiple
        />
      </FieldGroup>
    </Modal>
  );
};

ManageReviewersModal.propTypes = {};

export default reduxForm({ form: "manageReviewersModalForm" })(ManageReviewersModal);

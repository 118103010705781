import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group/index";
import style from "./style.module.scss";
import FieldText from "@local/components/field-text/index";
import FieldPassword from "@local/components/field-password/index";
import { reduxForm, Field } from "redux-form";
import Button from "@local/components/button/index";
import { isValidEmail, isRequired } from "@local/utils/validators";

const Main = ({ onClickLogin, invalid, pristine }) => {
  return (
    <div className={style.mainWrapper}>
      <div className={style.contentWrapper}>
        <form onSubmit={onClickLogin}>
          <Card>
            <CardHeader
              style={{
                paddingTop: "75px",
                color: "#ffffff",
                backgroundColor: getMainColor(),
              }}
              title={formatMessage({
                id: "pages.direct.login.cmp.mn.idx.title",
              })}
            />
            <CardContent>
              <FieldGroup>
                <Field component={FieldText} type="email" name="email" label="Email" id="email" placeholder="Please use your email address to login" validate={[isValidEmail]} />
                <Field component={FieldPassword} type="password" name="password" label="Password" id="password" placeholder="Enter your password" validate={[isRequired]} />
              </FieldGroup>
            </CardContent>
            <CardActions>
              <div className={style.actionsWrapper}>
                <Button
                  text={formatMessage({
                    id: "pages.direct.login.cmp.mn.idx.login",
                  })}
                  onClick={onClickLogin}
                  isDisabled={pristine || invalid}
                  type="submit"
                />
              </div>
            </CardActions>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({ form: "LoginForm" })(Main);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router";
import { getPartnerProfilesList, createCountryProfile, getCountriesList } from "@local/actions";
import CircularProgress from "@mui/material/CircularProgress";
import Main from "./components/main";
import { checkPermission, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import { adaptCountries } from "@local/utils/adapters";
import { toast } from "react-toastify";

const ProfilesList = ({ getPartnerProfiles, partnerId, partnerProfiles, isLoading, shouldRedirectToProfile, hasAddCountryOfficePermission, countries, createNewCountryProfiles, getCountries }) => {
  const history = useHistory();

  const [isCountryModalVisible, setIsCountryModalVisible] = useState(false);

  const onClickOpenAddCountryOfficeModal = () => {
    setIsCountryModalVisible(true);
  };

  const onClickCloseAddCountryOfficeModal = () => {
    setIsCountryModalVisible(false);
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getPartnerProfiles(partnerId);
  }, [getPartnerProfiles, partnerId]);

  useEffect(() => {
    if (shouldRedirectToProfile) history.push(`/profile/${partnerId}/overview`);
  }, [shouldRedirectToProfile, partnerId, history]);

  const onClickProfile = (id) => {
    history.push(`/profile/${id}/overview`);
  };

  if (isLoading) return <CircularProgress size={50} />;

  if (shouldRedirectToProfile) return <></>;

  const onSubmitAddNewCountry = async (countriesProfiles) => {
    try {
      const countryProfilesCodes = partnerProfiles?.country_profiles?.map((item) => item?.country_code);
      const filteredOutCreatedProfiles = countriesProfiles.filter((item) => !countryProfilesCodes?.includes(item));
      await createNewCountryProfiles(partnerId, {
        chosen_country_to_create: filteredOutCreatedProfiles,
      });
      getPartnerProfiles(partnerId);
      onClickCloseAddCountryOfficeModal();
    } catch (error) {
      if (error?.response?.data?.non_field_errors) {
        error?.response?.data?.non_field_errors.forEach((i) => toast.error(i));
      } else {
        toast.error(error?.response?.data);
      }
    }
  };

  return (
    <Main
      partnerProfiles={partnerProfiles}
      onClickProfile={onClickProfile}
      hasAddCountryOfficePermission={hasAddCountryOfficePermission}
      onSubmitAddNewCountry={onSubmitAddNewCountry}
      countries={countries}
      onClickOpenAddCountryOfficeModal={onClickOpenAddCountryOfficeModal}
      onClickCloseAddCountryOfficeModal={onClickCloseAddCountryOfficeModal}
      isCountryModalVisible={isCountryModalVisible}
    />
  );
};

ProfilesList.propTypes = {};

const mapStateToProps = (state) => ({
  partnerId: state.session.partnerId,
  partnerProfiles: state?.partnerProfiles?.data?.data ?? {},
  isLoading: state?.partnerProfiles?.loading ?? false,
  shouldRedirectToProfile: !state.session.isHq && state.session.authorized,
  hasAddCountryOfficePermission: checkPermission(PARTNER_PERMISSIONS.CREATE_COUNTRY_OFFICE, state),
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerProfiles: (partnerId) => dispatch(getPartnerProfilesList(undefined, { partnerId })),
  createNewCountryProfiles: (partnerId, body) => dispatch(createCountryProfile(body, { partnerId })),
  getCountries: () => dispatch(getCountriesList()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilesList));

import React from "react";
import { connect } from "react-redux";
import NumberOfNewCfeis from "./numberOfNewCfeis";
import NumberOfConceptNotes from "./numberOfConceptNotes";
import ListOfConceptNotes from "./listOfConceptNotes";
import ListOfOpenCfeis from "./listOfOpenCfeis";
import NewPartners from "./newPartners";
import NumberOfPartners from "./numberOfPartners";
import PartnerDecisions from "./partnerDecisions";
import { checkPermission, AGENCY_PERMISSIONS } from "../../../../legacy-utils/permissions";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "../../../../legacy-utils/restrictions";
import Grid from "@mui/material/Grid";

const AgencyDashboard = ({
  isLoading,
  hasReviewPermission,
  session,
  dashboard: { new_partners_last_15_count: newPartnersCount, new_partners_last_15_by_day_count: newPartnersByDayCount, new_cfei_last_15_by_day_count: newCfeiCount, num_cn_to_score: numCNToScore, partner_breakdown: partnerBreakdown },
}) => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NewPartners isLoading={isLoading} number={newPartnersCount} dayBreakdown={newPartnersByDayCount} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isSectionVisible(AGENCY_RESTRICTIONS.dashboard["numberOfNewCfeis"], session) && <NumberOfNewCfeis isLoading={isLoading} number={newCfeiCount} />}
            </Grid>
            <Grid item xs={12}>
              {isSectionVisible(AGENCY_RESTRICTIONS.dashboard["numberOfCNToScore"], session) && <NumberOfConceptNotes isLoading={isLoading} number={numCNToScore} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6}>
      <NumberOfPartners isLoading={isLoading} partnerBreakdown={partnerBreakdown} />
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {hasReviewPermission && isSectionVisible(AGENCY_RESTRICTIONS.dashboard["conceptNotesContainer"], session) && <ListOfConceptNotes />}
        </Grid>
        <Grid item xs={12}>
          {isSectionVisible(AGENCY_RESTRICTIONS.dashboard["openCfeiContainer"], session) && <ListOfOpenCfeis />}
        </Grid>
        <Grid item xs={12}>
          {isSectionVisible(AGENCY_RESTRICTIONS.dashboard["partnerDecisions"], session) && <PartnerDecisions />}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

AgencyDashboard.propTypes = {};

const mapStateToProps = (state) => ({
  hasReviewPermission: checkPermission(AGENCY_PERMISSIONS.CFEI_REVIEW_APPLICATIONS, state),
  session: state.session,
});

export default connect(mapStateToProps, undefined)(AgencyDashboard);

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FEATURES_ENABLED, VERIFICATION_STATUSES } from "@local/legacy-utils/constants";
import { getPartnersList, getCountriesList, getSectorsList, createSpecialPartner } from "@local/actions";
import { adaptPartners } from "./utils/adapters";
import { getPartnersColumns, getPartnersExpandSectionItems } from "./utils/columns";
import { getPartnersFilters } from "./utils/filters";
import List from "./components/list";
import Container from "./components/container";
import ManagePartnerModal from "./components/manage-partner-modal";
import { checkPermission, AGENCY_PERMISSIONS } from "@local/legacy-utils/permissions";
import { getConcernTypes, getPartnerTypes, getSpecialPartnerTypes, getOrganizationDescriptions, getPartnerStatusTypes } from "@local/utils/constants";
import { adaptCountries } from "@local/utils/adapters";
import { isValid, reset } from "redux-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getSortedList } from "@local/utils";

// POST_REFACTORING_TODO: Change way to pass properties to filters, button to add

const PartnersList = ({
  sectors,
  countries,
  getPartners,
  getCountries,
  getSectors,
  partnerTypes,
  isNewPartnerButtonVisible,
  verificationStatuses,
  concernTypes,
  specialPartnerFormValues,
  isValidSpecialPartnerForm,
  createSpecialPartner,
  resetSpecialPartnerForm,
  specialPartnerTypes,
  organizationDescriptions,
  partnerStatusTypes,
}) => {
  const [isManageSpecialPartnerModalVisible, setIsManageSpecialPartnerModalVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getSectors();
    getCountries();
  }, []);

  const onClickHeaderButton = () => {
    setIsManageSpecialPartnerModalVisible(true);
  };

  const onCloseManageSpecialPartnerModal = () => {
    setIsManageSpecialPartnerModalVisible(false);
    resetSpecialPartnerForm();
  };

  const onSubmitManageSpecialPartner = async () => {
    try {
      const result = await createSpecialPartner({
        display_type: specialPartnerFormValues?.display_type,
        legal_name: specialPartnerFormValues?.legal_name,
        other_names: specialPartnerFormValues?.other_names,
        country_code: specialPartnerFormValues?.country_code,
        mailing_address: {
          mailing_type: specialPartnerFormValues?.mailing_type,
          street: specialPartnerFormValues?.street,
          po_box: specialPartnerFormValues?.po_box,
          city: specialPartnerFormValues?.city,
          zip_code: specialPartnerFormValues?.zip_code,
          org_email: specialPartnerFormValues?.org_email,
        },
      });
      setIsManageSpecialPartnerModalVisible(false);
      resetSpecialPartnerForm();
      history.push(`/special-partner/${result?.data?.id}/overview`);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.partners.list.idx.unableToSaveData" }));
    }
  };

  return (
    <>
      <Container isNewPartnerButtonVisible={isNewPartnerButtonVisible} onClickHeaderButton={onClickHeaderButton}>
        <List
          columns={getPartnersColumns(specialPartnerTypes, countries, partnerTypes)}
          fetchFunction={getPartners}
          filters={getPartnersFilters(countries, partnerTypes, sectors, verificationStatuses, concernTypes, organizationDescriptions, partnerStatusTypes)}
          adapterFunction={(partners) => adaptPartners(partners, countries, "country_code")}
          expandSectionItems={getPartnersExpandSectionItems()}
          filtersNumberOfColumns={4}
          initialFilters={{ partner_status: "active" }}
        />
        <ManagePartnerModal
          isOpen={isManageSpecialPartnerModalVisible}
          onClose={onCloseManageSpecialPartnerModal}
          onSubmit={onSubmitManageSpecialPartner}
          isValidSpecialPartnerForm={isValidSpecialPartnerForm}
          partnerTypes={specialPartnerTypes}
          formValues={specialPartnerFormValues}
          countries={countries}
        />
      </Container>
    </>
  );
};

PartnersList.propTypes = {};

const mapStateToProps = (state) => ({
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
  hasPermissionViewFlagCount: checkPermission(AGENCY_PERMISSIONS.VIEW_PROFILE_OBSERVATION_FLAG_COUNT, state),
  partnerTypes: getPartnerTypes(),
  partnerStatusTypes: getPartnerStatusTypes(),
  specialPartnerTypes: getSpecialPartnerTypes(),
  isNewPartnerButtonVisible: FEATURES_ENABLED.special_partner.includes(state.session.agencyName) && state.session.officeRole !== "Reader",
  verificationStatuses: VERIFICATION_STATUSES,
  concernTypes: getConcernTypes(),
  specialPartnerFormValues: state.form?.specialPartnerModalForm?.values ?? {},
  isValidSpecialPartnerForm: isValid("specialPartnerModalForm")(state),
  organizationDescriptions: getOrganizationDescriptions(),
});

const mapDispatchToProps = (dispatch) => ({
  getPartners: (params) => dispatch(getPartnersList(params)),
  getCountries: () => dispatch(getCountriesList()),
  getSectors: (params) => dispatch(getSectorsList(params)),
  createSpecialPartner: (params) => dispatch(createSpecialPartner(params)),
  resetSpecialPartnerForm: () => dispatch(reset("specialPartnerModalForm")),
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersList);

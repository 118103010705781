import React from "react";
import ReactDOM from "react-dom";
import Application from "@local/pages/application";
import store, { history } from "@local/store";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import "@local/i18next/i18n";

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Application />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root"),
);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import StaticTable from "@local/components/static-table";
import Table from "@local/components/table";
import { getFinancialDetailsColumns, getFinancialSecondaryColumns } from "../../risk/utils/columns";
import { adapterFinancialDetailsForAgency } from "../../risk/utils/adapters";
import style from "./style.module.scss";
import { getUNDataList } from "@local/actions";

const PartnerUnAgencyData = ({ agencyData, agency, getUNDataList, vendorNumbers }) => {
  const [indexValue, setIndexValue] = useState(0);

  const UNData = agencyData?.unDataResult;
  const financialData = agencyData?.financialResult;

  const handleChange = (event, index) => setIndexValue(index);

  const adaptedTables = UNData?.map((item) => ({
    columns: item?.header?.map((column) => ({
      id: column.toLocaleLowerCase(),
      title: column,
    })),
    rows: item?.rows,
  }));

  const tableOfAdaptedRows = [];
  adaptedTables?.forEach((item) => {
    const adaptedRows = [];
    item?.rows?.forEach((row) => {
      const adaptedRow = {};
      item?.columns?.forEach((column, index) => {
        adaptedRow[`${column?.id}`] = row[index];
      });
      adaptedRows.push(adaptedRow);
    });
    tableOfAdaptedRows.push(adaptedRows);
  });

  const currentYear = new Date().getFullYear();

  return (
    <>
      {UNData?.length > 0 ? (
        <div>
          <Tabs value={indexValue} onChange={handleChange} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile>
            {UNData?.map((tab, index) => (
              <Tab key={`external_data_tab_${index}`} label={tab?.title} />
            ))}
          </Tabs>
          {UNData?.map((table, index) => (
            <div hidden={indexValue !== index} className={style.tableWrapper} key={`external_data_tab_${index}`}>
              <Paper>
                <StaticTable
                  key={`external_data_tab_${table?.title}`}
                  id={`external_data_tab_${table?.title}`}
                  title={formatMessage({
                    id: "pages.partner.details.cmp.un.data.legacy.cmp.partnerUnAgencyData.",
                  })}
                  columns={adaptedTables?.at(index)?.columns}
                  fetchFunction={() => ({
                    data: tableOfAdaptedRows?.at(index),
                  })}
                />
              </Paper>
            </div>
          ))}
        </div>
      ) : Object.keys(financialData || {})?.length > 0 ? (
        <Table
          key="financial_details"
          id="financial_details"
          title={formatMessage({
            id: "pages.partner.details.cmp.un.data.legacy.cmp.partnerUnAgencyData.dashboardTitle",
          })}
          columns={getFinancialDetailsColumns(currentYear, true)}
          fetchFunction={getUNDataList}
          adapterFunction={(data) => adapterFinancialDetailsForAgency(data, agency)}
          secondaryColumns={getFinancialSecondaryColumns(true)}
          isPaginated={false}
          isBordered
        />
      ) : (
        <div className={style.mainWrapper}>
          {vendorNumbers.find((vendorNumber) => vendorNumber?.agency_id === agency?.id) ? (
            <Typography className={style.infoWrapper} type="body2">
              {formatMessage({
                id: "pages.partner.details.cmp.un.data.legacy.cmp.partnerUnAgencyData.empty",
              })}
            </Typography>
          ) : (
            <Typography className={style.infoWrapper} type="body2">
              {formatMessage({
                id: "pages.partner.details.cmp.un.data.legacy.cmp.partnerUnAgencyData.info",
              })}
            </Typography>
          )}
        </div>
      )}
    </>
  );
};

PartnerUnAgencyData.propTypes = {};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUNDataList: () => dispatch(getUNDataList(undefined, { id: ownProps.match.params.id })),
});

export default withRouter(connect(null, mapDispatchToProps)(PartnerUnAgencyData));

import React from "react";
import Table from "@local/components/table";
import i18n from "@local/i18next/i18n";
import Modal from "@local/components/modal";
import { connect } from "react-redux";
import { projectModalNav } from "@local/utils/constants";
import PartnerDuplicateArea from "./components/PartnerDuplicateArea";
import Button from "@local/components/button";
import MatchPartnersForm from "./components/MatchPartnersForm";

const PartnerDuplicates = ({ initialFilters, fetchFunction, columns, filters, adapterFunction, isOpen, onClose, onChangeTableFilters, shouldRefresh, setShouldRefresh, selectedPartnerDuplicate, setPartnerDuplicate, setIsPartnerDuplicateModalOpen }) => {
  const [isCreateNewDuplicateModalOpen, setIsCreateNewDuplicateModalOpen] = React.useState(false);
  const onCloseCreatePartnerDuplicateModal = (createdDuplicatedMatch, callback) => {
    // if createdDuplicatedMatch exists it means that the matching was created
    // if not we just close the create modal form
    if (createdDuplicatedMatch) {
      setIsCreateNewDuplicateModalOpen(false);
      callback();
      setPartnerDuplicate(createdDuplicatedMatch);
      setIsPartnerDuplicateModalOpen(true);
    } else {
      setIsCreateNewDuplicateModalOpen(false);
    }
  };
  return (
    <>
      <Table
        key="partners_duplicates_list"
        id="partners_duplicates_list"
        title={i18n.t("Partners Duplicates")}
        columns={columns}
        fetchFunction={fetchFunction}
        filters={filters}
        shouldRefresh={shouldRefresh}
        afterRefresh={() => setShouldRefresh(false)}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        withUrlSynchronization
        keyColumn="id"
        initialFilters={initialFilters}
        actions={[<Button variant="text" text="Add partner duplicate" onClick={() => setIsCreateNewDuplicateModalOpen(true)} />]}
      />
      <Modal isOpen={isOpen} onClose={onClose} title={i18n.t("Partner duplicate information")}>
        <div style={{ paddingBottom: 8, fontWeight: 400 }}>
          <span>
            {i18n.t("Created by")}: {selectedPartnerDuplicate?.created_by ? `${selectedPartnerDuplicate?.created_by?.fullname}(${selectedPartnerDuplicate?.created_by?.email})  of ${selectedPartnerDuplicate?.created_by?.agency}` : i18n.t("System")}
          </span>
        </div>
        <PartnerDuplicateArea selectedPartnerDuplicate={selectedPartnerDuplicate} setShouldRefresh={setShouldRefresh} onClose={onClose} />
      </Modal>
      <Modal isOpen={isCreateNewDuplicateModalOpen} onClose={() => setIsCreateNewDuplicateModalOpen(false)} title={i18n.t("Partner duplicate information")}>
        <MatchPartnersForm setShouldRefresh={setShouldRefresh} onClose={onCloseCreatePartnerDuplicateModal} />
      </Modal>
    </>
  );
};

PartnerDuplicates.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    tabs: projectModalNav(),
    selectedProject: state?.getAdminProjectsList?.data?.data?.results?.find((result) => result?.id === ownProps?.selectedProject?.id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDuplicates);

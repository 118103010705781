import React from "react";
import Modal from "@local/components/modal";
import { reduxForm, Field } from "redux-form";
import { Grid, Typography, Chip } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import CircleIcon from "@mui/icons-material/Circle";
import FieldText from "@local/components/field-text";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import CheckboxesList from "@local/components/checkboxes-list";
import { AGENCY_RESTRICTIONS, checkFlagRestrictions } from "@local/legacy-utils/restrictions";
import { formatDateForPrint, normalizeDateFormat } from "@local/legacy-utils/dates";
import { adaptFlagReasons } from "@local/pages/partner-details/components/risk/utils/adapters";
import { getFlagStatusColor } from "@local/pages/partner-details/components/risk/utils";
import style from "./style.module.scss";

const FlagDetailsModal = ({ flag, isOpen, onClose, session, flagStatus, onCreateComment, flagComments, onClickViewAllComments, commentsNumber }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.flagDetailsModalTitle",
      })}
    >
      <div>
        <div className={style.headerWrapper}>
          <div className={style.headerContent}>
            <Typography variant="h6">
              {formatMessage({
                id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.id",
              })}
              : {flag?.id}
            </Typography>
          </div>
          <div className={style.buttonWrapper}>
            <Chip className={style.toCapitalize} icon={<CircleIcon style={{ fill: getFlagStatusColor(flagStatus) }} fontSize="small" />} label={flagStatus} />
          </div>
        </div>
        <div className={style.contentWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.raisedBy",
                  })}
                </Typography>
                <Typography variant="body2">{flag?.agency?.name}</Typography>
                <Typography variant="body2">
                  {flag?.created_by?.name} ({flag?.created_by?.email})
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.date",
                  })}
                </Typography>
                <Typography variant="body2">{formatDateForPrint(flag?.created)}</Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div>
                <Typography variant="subtitle2">
                  {formatMessage({
                    id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.reason",
                  })}
                </Typography>
                <Typography variant="body2">{adaptFlagReasons(flag?.reason)?.label}</Typography>
              </div>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <div className={style.commentWrapper}>
              <div className={style.commentContentWrapper}>
                <div>
                  <div className={style.commentAuthorIcon}>
                    {flag?.comment?.created_by?.name
                      ?.split(" ")
                      .map((word) => word[0])
                      .join("")}
                  </div>
                </div>
                <div className={style.commentTextWrapper}>
                  <div className={style.commentText}>{flag?.comment?.message}</div>
                  <div className={style.commentAuthorWrapper}>
                    <div className={style.commentAuthorName}>
                      <span className={style.commentAuthorCaption}>{flag?.comment?.created_by?.name}</span>
                      <span className={style.commentAuthorCaption}>{flag?.comment?.created_by?.email}</span>
                    </div>
                    <span className={style.commentAuthorCaption}>{normalizeDateFormat(flag?.created)}</span>
                  </div>
                  {commentsNumber / -1 < flagComments?.comment_replies.length && (
                    <Button
                      variant="text"
                      color="primary"
                      text={formatMessage({
                        id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.view",
                      })}
                      onClick={onClickViewAllComments}
                    />
                  )}
                  {flagComments?.comment_replies?.slice(commentsNumber)?.map((reply) => (
                    <div className={style.replyWrapper}>
                      <div>
                        <div className={style.userIcon}>
                          {reply?.created_by.name
                            ?.split(" ")
                            .map((word) => word[0])
                            .join("")}
                        </div>
                      </div>
                      <div>
                        <div className={style.replyText}>{reply?.message}</div>
                        <div className={style.commentAuthorWrapper}>
                          <div className={style.commentAuthorName}>
                            <span className={style.commentAuthorCaption}>{reply?.created_by?.name}</span>
                            <span className={style.commentAuthorCaption}>{reply?.created_by?.email}</span>
                          </div>
                          <span className={style.commentAuthorCaption}>{normalizeDateFormat(reply?.created)}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                  {checkFlagRestrictions(AGENCY_RESTRICTIONS.partners.risk.flags["addCommentToFlag"], session, flag?.agency?.id, flag?.created_by?.id) ? (
                    <div className={style.commentFormWrapper}>
                      <div>
                        <div className={style.userIcon}>
                          {session?.name
                            ?.split(" ")
                            .map((word) => word[0])
                            .join("")}
                        </div>
                      </div>
                      <div className={style.commentInput}>
                        <Field
                          component={FieldText}
                          id="message"
                          name="message"
                          type="text"
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.placeholder",
                          })}
                          small
                        />
                      </div>
                      <div>
                        <Button
                          text={formatMessage({
                            id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.send",
                          })}
                          variant="contained"
                          endIcon={<SendIcon />}
                          onClick={onCreateComment}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          {flag?.attachments?.length > 0 && (
            <Grid item xs={12} lg={6}>
              <Typography variant="subtitle2">
                {formatMessage({
                  id: "pages.partner.details.cmp.risk.cmp.flag.cmp.flag.details.modal.idx.downloadDocument",
                })}
              </Typography>
              <div>
                <CheckboxesList items={flag?.attachments} />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </Modal>
  );
};

FlagDetailsModal.propTypes = {};

export default reduxForm({ form: "addCommentForm" })(FlagDetailsModal);

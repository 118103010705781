import React, { useState } from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Alert from "@local/components/alert";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox } from "@mui/material";

const ManageCountryOfficeModal = ({ isOpen, onSubmit, onClose, countryPresence, countries, partnerCountryProfiles }) => {
  const [checked, setChecked] = useState(partnerCountryProfiles?.map((item) => item?.country_code));

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.profiles.list.cmp.manage.country.office.modal.idx.countryDialogTitle",
      })}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.profiles.list.cmp.manage.country.office.modal.idx.cancel",
            })}
          />
          <Button
            onClick={() => onSubmit(checked)}
            text={formatMessage({
              id: "pages.profiles.list.cmp.manage.country.office.modal.idx.create",
            })}
          />
        </>
      }
    >
      <Alert
        type="info"
        content={formatMessage({
          id: "pages.profiles.list.cmp.manage.country.office.modal.idx.countryDialogInfo",
        })}
        withBottomMargin
      />
      <div>
        <span>
          {formatMessage({
            id: "pages.profiles.list.cmp.manage.country.office.modal.idx.choose",
          })}
        </span>
      </div>
      <List>
        {countryPresence.map((country, index) => {
          const labelId = `checkbox-list-label-${index}`;
          return (
            <ListItem key={index} disablePadding>
              <ListItemButton role={undefined} onClick={handleToggle(country)} dense>
                <ListItemIcon>
                  <Checkbox
                    checked={partnerCountryProfiles?.filter((item) => (item.country_code === country ? country : undefined))?.length ? true : undefined}
                    disabled={partnerCountryProfiles?.filter((item) => (item.country_code === country ? country : undefined))?.length ? true : false}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={countries.find((item) => item.value === country)?.label} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Modal>
  );
};

ManageCountryOfficeModal.propTypes = {};

export default ManageCountryOfficeModal;

import React from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Button from "@local/components/button";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";

const Main = ({ havePreviousPage, haveNextPage, notifications, onClickNext, onClickPrevious, onClickMarkAllAsRead }) => {
  return (
    <>
      <div className={style.notificationsHeaderWrapper}>
        <Paper>
          <div className={style.notificationsHeaderContentWrapper}>
            <div className={style.notificationsFiltersWrapper}>
              <Button
                variant="text"
                onClick={onClickMarkAllAsRead}
                text={formatMessage({
                  id: "pages.notifications.list.cmp.mn.idx.markAllAsRead",
                })}
              />
            </div>
            <div className={style.notificationsPaginationWrapper}>
              <div>
                {havePreviousPage ? (
                  <IconButton onClick={onClickPrevious}>
                    <ChevronLeft />
                  </IconButton>
                ) : (
                  <IconButton disabled>
                    <ChevronLeft />
                  </IconButton>
                )}
              </div>
              <div>
                {haveNextPage ? (
                  <IconButton onClick={onClickNext}>
                    <ChevronRight />
                  </IconButton>
                ) : (
                  <IconButton disabled>
                    <ChevronRight />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        </Paper>
      </div>
      <Paper>
        <div>
          {notifications.map((item) => (
            <div
              className={style.notificationRowWrapper}
              key={`row_${item.id}`}
              style={{
                opacity: item.read ? 0.7 : 1,
                borderLeftColor: item.read ? "#dddddd" : "#2196f3",
              }}
            >
              <div>
                <span className={style.notificationRowDate}>{item.date}</span>
              </div>
              <div>
                <b>{item.title}</b>
              </div>
              <div>
                <span className={style.notificationRowDescription} dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
              {item.project ? (
                <div className={style.notificationRowProjectWrapper}>
                  <span>{item.project.displayID}</span>
                </div>
              ) : (
                <></>
              )}
              <div>
                {item.link ? (
                  <a
                    alt={formatMessage({
                      id: "pages.notifications.list.cmp.mn.idx.goToDetails",
                    })}
                    href={item.link}
                  >
                    <span>
                      {formatMessage({
                        id: "pages.notifications.list.cmp.mn.idx.goToDetails",
                      })}
                    </span>
                    <ChevronRight />
                  </a>
                ) : (
                  "--"
                )}
              </div>
            </div>
          ))}
        </div>
      </Paper>
    </>
  );
};

export default Main;

import { PROJECT_TYPES } from "@local/utils/constants";
import { isValidDate } from "@local/utils/validators";

const isValidAttachment = (attachment, description) => {
  if (!attachment?.id && !description) return true;
  if (attachment?.id && !description) return false;
  if (description && !attachment?.id) return false;
  return true;
};

const isValidSelectionCriteria = (criteria, weighting, hasWeighting, description) => {
  if (!criteria) return false;
  if (hasWeighting?.toString() === "true" && !weighting) return false;
  if (weighting && !hasWeighting === "false") return false;
  if (criteria === "Oth" && !description) return false;
  return true;
};

export const isValidWeightSum = (criteria, formValues) => {
  if (formValues?.has_weighting?.toString() === "true") {
    if (
      criteria.reduce((total, item) => {
        const value = parseFloat(formValues[`selection_criteria_weight_${item.uniqueId}`]);
        return total + value;
      }, 0) !== 100
    )
      return false;
  }
  return true;
};

export const isValidForm = (type, formValues, selectedLocations, attachments, criteria, isInRestrictedMode, isDeadlineDatePassed) => {
  if (!isInRestrictedMode) {
    if (!formValues?.title) return false;
    if (!formValues?.countries?.length) return false;
    if (!formValues?.sectors?.length) return false;
    if (!formValues?.areas?.length) return false;
    if (!selectedLocations.length) return false; // POST_REFACTORING_TODO also check that each country has at least a location
    if (type === PROJECT_TYPES.OPEN || type === PROJECT_TYPES.DIRECT) {
      if (!formValues?.description) return false;
      if (!formValues?.goal) return false;
      if (formValues?.indicative_budget && isNaN(formValues.indicative_budget)) return false;
      if (isValidDate(formValues?.start_date) !== "") return false;
      if (isValidDate(formValues?.end_date) !== "") return false;
      if (attachments.filter((item) => !isValidAttachment(formValues[`attachment_${item.uniqueId}`], formValues[`attachment_description_${item.uniqueId}`])).length > 0) return false;
    }
    switch (type) {
      case PROJECT_TYPES.OPEN:
        if (isValidDate(formValues?.clarification_request_deadline_date) !== "") return false;
        if (isValidDate(formValues?.deadline_date) !== "") return false;
        if (isValidDate(formValues?.notif_results_date) !== "") return false;
        if (formValues?.has_weighting === undefined) return false; // POST_REFACTORING_TODO: Not sure about this
        if (formValues?.is_cn_substitute === undefined) return false; // POST_REFACTORING_TODO: Not sure about this
        if (formValues?.is_cn_substitute?.toString() === "true" && (formValues?.cn_template?.file_field ?? undefined) === undefined) return false; // POST_REFACTORING_TODO: Not sure about this
        if (
          criteria.filter((item) => !isValidSelectionCriteria(formValues[`selection_criteria_${item.uniqueId}`], formValues[`selection_criteria_weight_${item.uniqueId}`], formValues?.has_weighting, formValues[`selection_criteria_description_${item.uniqueId}`])).length >
          0
        )
          return false;
        if (!isValidWeightSum(criteria, formValues)) return false;
        if (!formValues?.focal_points?.length) return false;
        break;
      case PROJECT_TYPES.DIRECT:
        if (!formValues?.partner?.id) return false;
        if (!formValues?.ds_justification_select?.length) return false;
        if ((formValues?.is_cn_required ?? undefined) === undefined) return false;
        if (!formValues?.justification_reason) return false;
        break;
      case PROJECT_TYPES.UNSOLICITED:
        if (!formValues?.cn?.file_field) return false;
        break;
      default:
        break;
    }
  } else {
    if (type === PROJECT_TYPES.OPEN || type === PROJECT_TYPES.DIRECT) {
      if (!isDeadlineDatePassed) {
        if (isValidDate(formValues?.start_date) !== "") return false;
        if (isValidDate(formValues?.end_date) !== "") return false;
      }
      if (attachments.filter((item) => !isValidAttachment(formValues[`attachment_${item.uniqueId}`], formValues[`attachment_description_${item.uniqueId}`])).length > 0) return false;
    }
    switch (type) {
      case PROJECT_TYPES.OPEN:
        if (!isDeadlineDatePassed) {
          if (isValidDate(formValues?.clarification_request_deadline_date) !== "") return false;
          if (isValidDate(formValues?.deadline_date) !== "") return false;
          if (isValidDate(formValues?.notif_results_date) !== "") return false;
        }
        break;
      default:
        break;
    }
  }
  return true;
};

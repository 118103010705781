import React from "react";
import Chip from "@mui/material/Chip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CircleIcon from "@mui/icons-material/Circle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { AGENCY_RESTRICTIONS, checkFlagRestrictions, isSectionVisible } from "@local/legacy-utils/restrictions";
import { adaptPartnerCountries, adaptFlagReasons } from "./adapters";
import { getStatusColor, getFlagStatusColor } from "./";
import style from "./style.module.scss";

export const getAssessmentReportsColumns = (countries, onClickOpenModal) => [
  {
    id: "id",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.id",
    }),
  },
  {
    id: "report_name",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.reportName",
    }),
    type: "custom",
    render: (row) => (
      <div onClick={() => onClickOpenModal(row)} style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
        <span>{row.report_name}</span>
        <span className={style.caption}>{row.report_type}</span>
      </div>
    ),
  },
  {
    id: "country",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.country",
    }),
    type: "custom",
    render: (row) => <span>{row?.operating_country ? adaptPartnerCountries(row?.operating_country, countries) : null}</span>,
  },
  {
    id: "agency",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.agencyName",
    }),
    type: "custom",
    render: (row) => <span>{row?.partner_vendor?.agency?.name}</span>,
  },
  {
    id: "assessment_type",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.assessmentType",
    }),
  },
  {
    id: "assessment_date",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.assessmentDate",
    }),
    type: "date",
  },
  {
    id: "evaluated_risk",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.evaluatedRisk",
    }),
    type: "custom",
    render: (row) => {
      return <Chip className={style.toCapitalize} icon={<CircleIcon style={{ fill: getStatusColor(row.risk_level) }} fontSize="small" />} label={row.risk_level} />;
    },
  },
  {
    id: "attachments",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.attachments",
    }),
    type: "custom",
    render: (row) => {
      return <Chip icon={<AttachFileIcon style={{ fill: "#808080" }} fontSize="small" />} label={row?.attachments?.length} />;
    },
  },
];

export const getAuditReportsColumns = (countries, onClickOpenModal) => [
  {
    id: "id",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.idOne",
    }),
  },
  {
    id: "report_name",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.reportNameOne",
    }),
    type: "custom",
    render: (row) => (
      <div onClick={() => onClickOpenModal(row)} style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
        <span>{row.report_name}</span>
        <span className={style.caption}>{row.report_type}</span>
      </div>
    ),
  },
  {
    id: "audit_type",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.type",
    }),
    type: "custom",
    render: (row) => (
      <span>
        {row?.is_special
          ? formatMessage({
              id: "pages.partner.details.cmp.risk.utils.columns.typeSpecial",
            })
          : formatMessage({
              id: "pages.partner.details.cmp.risk.utils.columns.typeRegular",
            })}
      </span>
    ),
  },
  {
    id: "country",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.countryOne",
    }),
    type: "custom",
    render: (row) => <span>{row?.operating_country ? adaptPartnerCountries(row?.operating_country, countries) : null}</span>,
  },
  {
    id: "agency",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.agencyNameOne",
    }),
    type: "custom",
    render: (row) => <span>{row?.partner_vendor?.agency?.name}</span>,
  },
  {
    id: "created_by",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.auditedBy",
    }),
    type: "custom",
    render: (row) => <span>{row?.partner_vendor?.partner?.legal_name}</span>,
  },
  {
    id: "audit_date",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.auditDate",
    }),
    type: "date",
  },
  {
    id: "opinion",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.opinion",
    }),
    type: "custom",
    render: (row) => <span className={style.label}>{row?.opinion}</span>,
  },
  {
    id: "audit_period",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.auditPeriod",
    }),
  },
  {
    id: "attachments",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.attachmentsOne",
    }),
    type: "custom",
    render: (row) => {
      return <Chip icon={<AttachFileIcon style={{ fill: "#808080" }} fontSize="small" />} label={row?.attachments?.length} />;
    },
  },
];

export const getProgressReportColumns = () => [
  {
    id: "id",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.idTwo",
    }),
  },
  {
    id: "report_name",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.reportNameTwo",
    }),
  },
  {
    id: "agency",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.agencyNameTwo",
    }),
    type: "custom",
    render: (row) => <span>{row?.partner_vendor?.agency?.name}</span>,
  },
  {
    id: "attachments",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.attachmentFile",
    }),
    type: "custom",
    render: (row) =>
      row?.attachments?.map((attachment) => (
        <Chip
          avatar={
            <Avatar sx={{ bgcolor: "#C5C5C5" }}>
              <AttachFileIcon fontSize="small" style={{ fill: "#404040" }} />
            </Avatar>
          }
          className={style.attachmentName}
          label={attachment?.name}
          onDelete={() => {}}
          deleteIcon={
            <a target="_blank" href={attachment?.file_field} rel="noreferrer">
              <FileDownloadIcon fontSize="small" style={{ fill: "#606060" }} />
            </a>
          }
        />
      )),
  },
  {
    id: "progress_report_date",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.date",
    }),
    type: "date",
  },
];

export const getFlagsColumns = (onClickOpenModal, handleOnClickEditFlag, onClickOpenDeactivateFlagModal, onClickOpenActivateFlagModal, session) => [
  {
    id: "id",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.idThree",
    }),
  },
  {
    id: "reason",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.reason",
    }),
    type: "custom",
    render: (row) => (
      <span onClick={() => onClickOpenModal(row)} style={{ cursor: "pointer" }}>
        {adaptFlagReasons(row?.reason)?.label}
      </span>
    ),
  },
  {
    id: "created_by",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.raisedBy",
    }),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{row?.agency?.name}</span>
          <span>
            {row?.created_by?.name} ({row?.created_by?.email})
          </span>
        </div>
      );
    },
  },
  {
    id: "created",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.dateOne",
    }),
    type: "date",
  },
  {
    id: "attachments",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.attachmentsTwo",
    }),
    type: "custom",
    render: (row) => {
      return <Chip icon={<AttachFileIcon style={{ fill: "#808080" }} fontSize="small" />} label={row?.attachments?.length} />;
    },
  },
  {
    id: "status",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.status",
    }),
    type: "custom",
    render: (row) => {
      return <Chip className={style.toCapitalize} icon={<CircleIcon style={{ fill: getFlagStatusColor(row?.status) }} fontSize="small" />} label={row?.status} />;
    },
  },
  {
    id: "edit",
    title: "",
    type: "custom",
    render: (row) =>
      checkFlagRestrictions(AGENCY_RESTRICTIONS.partners.risk.flags["editFlag"], session, row?.agency?.id, row?.created_by?.id) ? (
        <div>
          <IconButton onClick={() => handleOnClickEditFlag(row)}>
            <EditIcon />
          </IconButton>
        </div>
      ) : (
        <></>
      ),
  },
  {
    id: "activate",
    title: "",
    type: "custom",
    render: (row) => (
      <div>
        {checkFlagRestrictions(AGENCY_RESTRICTIONS.partners.risk.flags["closeFlag"], session, row?.agency?.id, row?.created_by?.id) && row?.status === "open" ? (
          <div>
            <Tooltip
              title={formatMessage({
                id: "pages.partner.details.cmp.risk.utils.columns.closeFlagButton",
              })}
            >
              <IconButton onClick={() => onClickOpenDeactivateFlagModal(row)}>
                <LockIcon style={{ fill: "#DB5163" }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : checkFlagRestrictions(AGENCY_RESTRICTIONS.partners.risk.flags["reOpenFlag"], session, row?.agency?.id, row?.created_by?.id) && row?.status === "close" ? (
          <div>
            <Tooltip
              title={formatMessage({
                id: "pages.partner.details.cmp.risk.utils.columns.openFlagButton",
              })}
            >
              <IconButton onClick={() => onClickOpenActivateFlagModal(row)}>
                <LockOpenIcon style={{ fill: "#64BA19" }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <></>
        )}
      </div>
    ),
  },
];

export const getFinancialDetailsColumns = (currentYear, onlyOneRow = false) => [
  {
    id: "agency",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.agency",
    }),
    isVisible: !onlyOneRow,
  },
  {
    id: "budget",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.budget",
    }),
    isVisible: true,
  },
  {
    id: "cash_at_hand",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.cashAtHand",
    }),
    isVisible: true,
  },
  {
    id: "expenditure_current_year",
    title: `${formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.expenditureCurrentYear",
    })} ${currentYear}`,
    isVisible: true,
  },
  {
    id: "expenditure_previous_year",
    title: `${formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.expenditurePreviousYear",
    })} ${currentYear - 1}`,
    isVisible: true,
  },
  {
    id: "expenditure_previous_two_year",
    title: `${formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.expenditurePreviousTwoYear",
    })} ${currentYear - 2}`,
    isVisible: true,
  },
  {
    id: "expenditure_previous_three_year",
    title: `${formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.expenditurePreviousThreeYear",
    })} ${currentYear - 3}`,
    isVisible: true,
  },
];

export const getFinancialSecondaryColumns = (onlyOneRow = false) => [
  {
    id: "agency",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.agency",
    }),
    span: 1,
    isVisible: true,
  },
  {
    id: "current",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.current",
    }),
    span: 3,
    isVisible: true,
  },
  {
    id: "expenditure_history",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.expenditureHistory",
    }),
    span: 3,
    isVisible: true,
  },
];

export const getObservationsColumns = (handleOnClickEditObservation, onClickOpenDeleteObservationModal, session) => [
  {
    id: "id",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.idFour",
    }),
  },
  {
    id: "comment.message",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.description",
    }),
  },
  {
    id: "created_by",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.raisedByOne",
    }),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>{row?.agency?.name}</span>
          <span>
            {row?.created_by?.name} ({row?.created_by?.email})
          </span>
        </div>
      );
    },
  },
  {
    id: "created",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.dateTwo",
    }),
    type: "date",
  },
  {
    id: "attachments",
    title: formatMessage({
      id: "pages.partner.details.cmp.risk.utils.columns.attachmentsThree",
    }),
    type: "custom",
    render: (row) => {
      return <Chip icon={<AttachFileIcon style={{ fill: "#808080" }} fontSize="small" />} label={row?.attachments?.length} />;
    },
  },
  {
    id: "edit",
    title: "",
    type: "custom",
    render: (row) =>
      isSectionVisible(AGENCY_RESTRICTIONS.partners.risk.observations["editObservation"], session) && row?.created_by?.id === session?.userId ? (
        <div>
          <IconButton onClick={() => handleOnClickEditObservation(row)}>
            <EditIcon />
          </IconButton>
        </div>
      ) : (
        <></>
      ),
  },
  {
    id: "delete",
    title: "",
    type: "custom",
    render: (row) =>
      isSectionVisible(AGENCY_RESTRICTIONS.partners.risk.observations["deleteObservation"], session) && row?.created_by?.id === session?.userId ? (
        <div>
          <Tooltip
            title={formatMessage({
              id: "pages.partner.details.cmp.risk.utils.columns.delete",
            })}
          >
            <span>
              <IconButton
                onClick={() => {
                  onClickOpenDeleteObservationModal(row);
                }}
              >
                <DeleteIcon fontSize="small" style={{ fill: "#0000008a" }} />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      ) : (
        <></>
      ),
  },
];

import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { formatMessage } from "../../../../legacy-utils/i18nHelper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import style from "./numberOfAwards.module.scss";
import Loader from "@local/components/loader";

const NumberOfAwards = ({ isLoading, number }) => {
  return (
    <Card>
      <CardContent>
        <div className={style.titleWrapper}>
          <div>
            <Typography variant="subtitle2" component="div">
              {formatMessage({
                id: "pages.dashboard.cmp.partner.numberOfAwards.title",
              })}
            </Typography>
          </div>
          {isLoading ? (
            <Loader isVisible />
          ) : (
            <div>
              {number !== undefined ? (
                <Typography variant="h6" component="div">
                  {number}
                </Typography>
              ) : undefined}
            </div>
          )}
        </div>
      </CardContent>
      <CardActions>
        <Button component={Link} to="/applications/notes/" color="primary">
          {formatMessage({
            id: "pages.dashboard.cmp.partner.numberOfAwards.button",
          })}
        </Button>
      </CardActions>
    </Card>
  );
};

NumberOfAwards.propTypes = {};

export default NumberOfAwards;

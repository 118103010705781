import React from "react";
import { purple, blue, grey } from "@mui/material/colors";
import store from "../store";
import { createTheme } from "@mui/material/styles";

const getThemeColorByRole = (role) => {
  switch (role) {
    case "agency":
      return blue;
    case "partner":
      return { ...purple, 500: "#6B5CA5" };
    default:
      return grey;
  }
};

const getThemeBaseByRole = (role) => ({
  zIndex: {
    dialog: 999,
  },
  palette: {
    primary: getThemeColorByRole(role),
    secondary: getThemeColorByRole(role),
    common: {
      arrayFormOuter: "#F5F5F5",
      arrayFormInner: "#E0E0E0",
      lightGreyBackground: "#F5F5F5",
      darkGreyBackground: "#EEEEEE",
      statusOk: "#189a58",
      orange: "#F39C38",
      purple: "#A996D8",
      blue: "#87B0EE",
      green: "#72C300",
      gray: "#F6F6F6",
      lightGreen: "#ADEAC0",
      formLabel: "rgba(0, 0, 0, 0.34)",
      grayText: "#757575",
    },
    eoiStatus: {
      completed: "#5B92E5",
      closed: "#233944",
      open: "#72C300",
      draft: "#A1A1A1",
      cancelled: "#FF3300",
    },
    userStatus: {
      invited: "#FF6D00",
      active: "#8BC34A",
      deactivated: "#263238",
    },
    dateColors: {
      dark: "#233944",
      green: "#72C300",
      red: "#EA4022",
      blue: "#0099FF",
      orange: "#fec832",
    },
    flags: {
      observation: "#A1A1A1",
      red: "#D50000",
      yellow: "#FFC400",
      escalated: "#FFA000",
      background: "#E5E5E5",
    },
  },
  typography: {
    headline: {
      fontSize: "1.25rem",
    },
  },
  overrides: {
    typography: {
      fontSize: 20,
    },
    MuiAppBar: {
      root: {
        flexDirection: "row",
        height: "100%",
        padding: 15,
        alignItems: "center",
        zIndex: 0,
      },
    },
    MuiList: {
      root: {
        width: "100%",
      },
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      default: {
        paddingTop: "1.5em",
        paddingBottom: "1.5em",
      },
      gutters: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    MuiTypography: {
      headline: {
        color: "inherit",
      },
      root: {
        wordBreak: "break-word",
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.34)",
        zIndex: 1,
        transform: "scale(0.75)",
        transformOrigin: "left top",
        pointerEvents: "none",
      },
      focused: {
        color: "rgba(0, 0, 0, 0.34)",
      },
    },
    MuiInput: {
      input: {
        "label + $formControl > &": {
          opacity: 0.5,
        },
      },
    },
    MuiTableCell: {
      paddingDefault: {
        "&:not(:first-child)": {
          paddingLeft: "8px",
        },
      },
    },
    MuiDefaultTab: {
      fontWeight: 400,
    },
    MuiTab: {
      rootLabelIcon: {
        height: "48px",
      },
    },
    MuiRadio: {
      checked: {
        color: getThemeColorByRole(role)[500],
      },
    },
    MuiCheckbox: {
      checked: {
        color: getThemeColorByRole(role)[500],
      },
      disabled: {
        color: getThemeColorByRole(role)[200],
      },
    },
  },
});

const themesByRole = {
  default: createTheme(getThemeBaseByRole("default")),
  agency: createTheme(getThemeBaseByRole("agency")),
  partner: createTheme(getThemeBaseByRole("partner")),
};

const getTheme = () => themesByRole[store.getState().session.role || "default"];

export const getMainColor = () => getThemeColorByRole(store.getState().session.role || "default")[500];

export const withStyles = (styleSheet, options) => (component) => {
  const Component = component;
  const theme = getTheme();
  const classes = styleSheet(theme);
  return class extends React.Component {
    render() {
      return <Component classes={classes} {...this.props} />;
    }
  };
};

export const chartColors = ["#668DDB", "#F7C848", "#ADEAC0", "#A996D8", "#EE7635"];

export const projectStatusColor = {
  Com: "#5B92E5",
  Clo: "#233944",
  Ope: "#72C300",
  Dra: "#A1A1A1",
  Can: "#FF3300",
};

export const userStatusColor = {
  deactivated: "#263238",
  invited: "#FF6D00",
  active: "#8BC34A",
};

export const partnerEvaluatedRiskColor = {
  high: "#F44336",
  medium: "#FFC400",
  low: "#5B92E5",
};

export const riskFlagStatusColor = {
  open: "#64BA19",
  close: "#DB5163",
};

export const projectTypeColor = {
  open: "orange",
  direct: "purple",
  unsolicited: "blue",
};

export const selectedPartnerDirectProjectStatusColor = {
  Suc: "purple",
  Acc: "lightGreen",
  Dec: "orange",
};

export const applicationStatusColor = {
  Pen: "green",
  Dra: "#A1A1A1",
  Sen: "#A1A1A1",
  Rej: "#233944",
  Pre: "#5B92E5",
  Uns: "#F44336",
  Suc: "purple",
  Acc: "lightGreen",
  Dec: "orange",
  Ret: "#233944",
  Can: "#FF3300",
  Rev: "#2196f3",
};

export default getTheme;

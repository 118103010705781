import React from "react";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import MainContent from "@local/components/main-content-wrapper";
import Button from "@local/components/button";

const Container = ({ onClickHeaderButton, children }) => {
  return (
    <HeaderNavigation
      title={formatMessage({
        id: "pages.shell.profiles.list.cmp.container.idx.header",
      })}
      header={
        <Button
          text={formatMessage({
            id: "pages.shell.profiles.list.cmp.container.idx.newShellProfileModalButton",
          })}
          onClick={onClickHeaderButton}
        />
      }
    >
      <MainContent>{children}</MainContent>
    </HeaderNavigation>
  );
};

Container.propTypes = {};

export default Container;

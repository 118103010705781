import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";

const DirectApplicationsList = ({ fetchFunction, columns, filters, adapterFunction }) => {
  return (
    <Table
      key="applications_list_direct"
      id="applications_list_direct"
      title={formatMessage({
        id: "pages.applications.list.cmp.direct.applications.list.idx.directTitle",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      filters={filters}
      adapterFunction={adapterFunction}
      withUrlSynchronization
    />
  );
};

DirectApplicationsList.propTypes = {};

export default DirectApplicationsList;

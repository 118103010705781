/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import Box from "@local/components/box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import { WrapperComponent } from "../../utils";
import { isRequired } from "@local/utils/validators";

// POST_REFACTORING_TODO: Errors and alert icon for each section

const Collaboration = ({ onSubmitAndContinue, onSubmitAndExit, agencies, isLoading, formValues, stateValues, inEditMode }) => {
  const [collaborated, setCollaborated] = useState(undefined);

  useEffect(() => {
    if (stateValues?.collaborated && !collaborated) setCollaborated(stateValues.collaborated);
  }, [stateValues?.collaborated]);

  const onChangeHaveCollaborated = (value) => {
    if (value?.toString() === "true") {
      setCollaborated([{ id: 0, originalId: undefined }]);
    } else {
      setCollaborated([]);
    }
  };

  const onClickAddCollaborated = () => {
    const updatedCollaborated = [
      ...collaborated,
      {
        id: collaborated.length ? collaborated.sort((a, b) => a.id > b.id)[collaborated.length - 1].id + 1 : 1,
        originalId: undefined,
      },
    ];
    setCollaborated(updatedCollaborated);
  };

  const onClickRemoveCollaborated = (toRemove) => {
    const updatedCollaborated = collaborated.filter((item) => item.id !== toRemove.id);
    setCollaborated(updatedCollaborated);
  };

  const onClickSaveAndContinue = () => {
    onSubmitAndContinue({ collaborated });
  };

  const onClickSaveAndExit = () => {
    onSubmitAndExit({ collaborated });
  };

  if (isLoading || !collaborated) return <CircularProgress size={50} />;

  const havePartnership = formValues?.partnership_collaborate_institution?.toString() === "true";
  const haveCollaborated = formValues?.any_partnered_with_un?.toString() === "true";

  return (
    <WrapperComponent
      inEditMode={inEditMode}
      title={formatMessage({
        id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.collaboration",
      })}
      footer={
        <div className={style.cardActionsWrapper}>
          <Button
            variant="text"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.saveAndExit",
            })}
            onClick={onClickSaveAndExit}
          />
          <Button
            variant="contained"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.saveAndContinue",
            })}
            onClick={onClickSaveAndContinue}
          />
        </div>
      }
    >
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.historyOfPartnership",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="any_partnered_with_un"
                name="any_partnered_with_un"
                onChangeExtra={onChangeHaveCollaborated}
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.hasCollaborated",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.hasCollaborated",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.no",
                    }),
                  },
                ]}
              />
              {haveCollaborated && collaborated ? (
                <Box
                  sections={collaborated.map((item, index) => (
                    <React.Fragment key={`collaborated_wrapper_${item.id}`}>
                      <div className={style.itemWrapper}>
                        <div>
                          <FieldGroup>
                            <Field
                              component={FieldSelect}
                              validate={[isRequired]}
                              highlightType="basic"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`collaborated_agency_name_${item.id}`}
                              name={`collaborated_agency_name_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.selectAgency",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.selectAgency",
                              })}
                              valueField="label"
                              labelField="label"
                              options={agencies}
                            />
                            {formValues?.[`collaborated_agency_name_${item.id}`] === "Other" && (
                              <Field
                                component={FieldText}
                                validate={formValues?.[`collaborated_agency_name_${item.id}`] === "Other" ? [isRequired] : []}
                                highlightType="advanced"
                                readOnly={!inEditMode}
                                small={!inEditMode}
                                id={`other_agency_name_${item.id}`}
                                name={`other_agency_name_${item.id}`}
                                label={"Agency name"}
                                placeholder={formatMessage({
                                  id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.explainCollaboration",
                                })}
                                type="text"
                                maxLength={5000}
                                withCharactersCount
                                multiline
                              />
                            )}

                            <Field
                              component={FieldText}
                              highlightType="advanced"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`collaborated_description_${item.id}`}
                              name={`collaborated_description_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.explainCollaboration",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.explainCollaboration",
                              })}
                              type="text"
                              maxLength={5000}
                              withCharactersCount
                              multiline
                            />
                            <Field
                              component={FieldText}
                              highlightType="advanced"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`collaborated_partner_number_${item.id}`}
                              name={`collaborated_partner_number_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.provideNumber",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.provideNumber",
                              })}
                              type="text"
                              maxLength={5000}
                              withCharactersCount
                              multiline
                            />
                          </FieldGroup>
                        </div>
                        {index > 0 && inEditMode ? (
                          <div>
                            <IconButton onClick={() => onClickRemoveCollaborated(item)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ) : undefined}
                      </div>
                    </React.Fragment>
                  ))}
                  actions={
                    inEditMode ? (
                      <>
                        <Button
                          text={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.addNew",
                          })}
                          startIcon={<AddIcon />}
                          onClick={onClickAddCollaborated}
                          variant="text"
                        />
                      </>
                    ) : undefined
                  }
                />
              ) : undefined}
              <Field
                component={FieldRadioGroup}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="partnership_collaborate_institution"
                name="partnership_collaborate_institution"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.hasCollaboratedOther",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.hasCollaboratedOther",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.no",
                    }),
                  },
                ]}
              />
              {havePartnership ? (
                <Field
                  component={FieldText}
                  highlightType="advanced"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="partnership_collaborate_institution_desc"
                  name="partnership_collaborate_institution_desc"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.collaborationDesc",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.collaboration.idx.collaborationDesc",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
            </FieldGroup>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

Collaboration.propTypes = {};

export default reduxForm({
  form: "editPartnerProfileCollaborationForm",
  enableReinitialize: true,
})(Collaboration);

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import { formatDate } from "@local/utils/index";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import { formatNumberDecimals } from "@local/utils/adapters";

// POST_REFACTORING_TODO: total score translations

const getAverageTotalScore = (reviews) => {
  const numberOfReviewers = reviews?.length ?? 1;
  let totalScore = 0;
  reviews?.forEach((item) => {
    totalScore += item?.assessment?.at(0)?.scores?.reduce((total, current) => total + current.score, 0) ?? 0;
  });
  return Math.round(totalScore / numberOfReviewers, 2);
};

const Reviews = ({ application, isCreator, isFocalPoint, reviews, user, criteria, selectionCriteria }) => {
  const [expandedItem, setExpandedItem] = useState(undefined);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Card
          title={formatMessage({
            id: "pages.application.details.cmp.reviews.idx.title",
          })}
        >
          {reviews ? (
            <>
              {reviews
                .sort((item) => (isFocalPoint ? item : item.id !== user))
                .map((item) => (
                  <div key={`review_${item.id}`}>
                    <div className={style.reviewItemWrapper}>
                      {item.assessment.length ? (
                        <div className={style.reviewItemExpandWrapper}>
                          <IconButton onClick={() => setExpandedItem((state) => (state === item.id ? undefined : item.id))}>{item.id === expandedItem ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                        </div>
                      ) : undefined}
                      <div className={style.reviewItemNameWrapper}>
                        <div>{item.fullname}</div>
                        {item.id === user ? (
                          <div>
                            <Chip
                              size="small"
                              label={formatMessage({
                                id: "pages.application.details.cmp.reviews.idx.you",
                              })}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className={style.reviewItemStatusWrapper}>
                        {item.assessment.length ? (
                          <div className={style.assessmentLabelWrapper}>
                            <span>
                              {`${formatMessage({
                                id: "pages.application.details.cmp.reviews.idx.date",
                              })}: ${formatDate(item.assessment.at(0).date_reviewed)}`}
                            </span>
                            {item.assessment.at(0).completed_date ? (
                              <span>
                                {`${formatMessage({
                                  id: "pages.application.details.cmp.reviews.idx.date.completed",
                                })}: ${formatDate(item.assessment.at(0).completed_date)}`}
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <Chip
                            icon={<CircleIcon style={{ fill: "#cc0000" }} fontSize="small" />}
                            label={formatMessage({
                              id: "pages.application.details.cmp.reviews.idx.notDone",
                            })}
                          />
                        )}
                      </div>
                    </div>
                    {item.id === expandedItem ? (
                      <div className={style.expandedSectionWrapper}>
                        <div className={style.caption}>
                          <span>
                            {formatMessage({
                              id: "pages.application.details.cmp.reviews.idx.criteria",
                            })}
                          </span>
                          <span>
                            {formatMessage({
                              id: "pages.application.details.cmp.reviews.idx.score",
                            })}
                          </span>
                        </div>
                        <div className={style.criteriaWrapper}>
                          {item.assessment.at(0)?.scores?.map((itemScore) => {
                            return (
                              <div key={`criteria_review_score_item_${item.selection_criteria}`} className={style.criteriaItemWrapper}>
                                <div>
                                  <span>
                                    {selectionCriteria.find((element) => element.value === itemScore.selection_criteria.substring(0, 3))?.label} - {itemScore.description}
                                  </span>
                                </div>
                                <div>
                                  <div>{formatNumberDecimals(itemScore.score)}</div>
                                  {criteria.weight ? (
                                    <div className={style.criteriaItemWeightWrapper}>
                                      <span>/ {item.weight}</span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className={style.additionalInformationWrapper}>
                          {item.assessment.at(0).note ? (
                            <div className={style.additionalInformationItemWrapper}>
                              <div>
                                <span className={style.additionalInformationLabel}>
                                  {formatMessage({
                                    id: "pages.application.details.cmp.reviews.idx.notes",
                                  })}
                                </span>
                              </div>
                              <div>
                                <span className={style.additionalInformationText}>{item.assessment.at(0).note}</span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {item.assessment.at(0).is_a_committee_score ? (
                            <div className={style.additionalInformationItemWrapper}>
                              <CheckIcon fontSize="small" style={{ color: "#64BA19" }} />
                              <span className={style.additionalInformationText}>
                                {formatMessage({
                                  id: "pages.application.details.cmp.reviews.idx.committee",
                                })}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </>
          ) : (
            <></>
          )}
        </Card>
      </Grid>
      {isFocalPoint || isCreator ? (
        <Grid item xs={12} md={4}>
          <Card
            title={formatMessage({
              id: "pages.application.details.cmp.reviews.idx.totalScore",
            })}
          >
            <div className={style.totalScoreMainWrapper}>
              <div>
                <span>
                  The average total score is <b>{getAverageTotalScore(reviews)}</b>
                </span>
                <small>{`Assessment made by ${reviews?.filter((item) => item.assessment.length).length ?? 0} of ${reviews?.length ?? 0} Agency users`}</small>
              </div>
              {application?.justification_reason ? (
                <div>
                  <span>Justification</span>
                  <b>{application.justification_reason}</b>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Card>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

Reviews.propTypes = {};

export default Reviews;

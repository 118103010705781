import React from "react";
import Table from "@local/components/table";
import i18n from "@local/i18next/i18n";

const UsersList = ({ fetchFunction, columns, shouldRefresh, afterRefresh, filters, onChangeTableFilters, officeId }) => {
  return (
    <Table
      id="users_list"
      title={i18n.t("Users")}
      columns={columns}
      fetchFunction={fetchFunction}
      shouldRefresh={shouldRefresh}
      afterRefresh={afterRefresh}
      filters={filters}
      initialFilters={{ office: officeId }}
      onChangeFilters={onChangeTableFilters}
      withUrlSynchronization
      filtersNumberOfColumns={3}
    />
  );
};

UsersList.propTypes = {};

export default UsersList;

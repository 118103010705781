/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import UploadDocumentModal from "./components/upload-document-modal";
import { connect } from "react-redux";
import { getPseaRatingOptions, getPseaInitialAssessmentDocumentTypes } from "@local/utils/constants";
import { validateAnswersForm, getIsAssessedValue } from "../../../../utils/validators";
import { reset, isValid, change } from "redux-form";
import { getPseaInitialAssessmentDetails, updatePseaInitialAssessment, getPseaInitialAssessmentDocumentsList, createPseaInitialAssessmentDocument, deletePseaInitialAssessmentDocument, getPseaDetails } from "@local/actions";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { checkPermission, AGENCY_PERMISSIONS, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import { ROLES } from "@local/legacy-utils/constants";
import { adaptInitialAssessmentFormValuesToBeSent, adaptAgencyInitialValues, adaptPartnerInitialValues } from "../../../../utils/adapters";
import FieldCheckbox from "@local/components/field-checkbox";
import { formatDate } from "@local/pages/partner-details/components/psea/utils";
import Button from "@local/components/button";
import { PartnerAnswers } from "./components/answers";
import Confirmation from "./components/confirmation";
import style from "./style.module.scss";
import Alert from "@local/components/alert";
import Collapse from "@mui/material/Collapse";
import SectionContainer from "../section-container";
import JustificationSection from "../justification-section";

const PseaInitialAssessment = ({
  initialAssessmentDocumentFormValues,
  ratingOptions,
  ratingOptionsWithoutValid,
  documentTypes,
  role,
  resetInitialAssessmentDocumentForm,
  isInitialAssessmentDocumentFormValid,
  getPseaInitialAssessmentDetails,
  updatePseaInitialAssessment,
  pseaInitialAssessmentDocuments,
  getPseaInitialAssessmentDocumentsList,
  createPseaInitialAssessmentDocument,
  deletePseaInitialAssessmentDocument,
  pseaInitialAssessment,
  hasEditPseaInitialAssessmentPermission,
  hasSpecialPseaPermission,
  getPseaDetails,
  agencyFormValues,
  partnerFormValues,
  agencyInitialFormValues,
  partnerInitialFormValues,
  revisionToJustify,
  isAddJustificationAvailable,
  partnerId,
  changeAgencyUnAgencySubmitter,
  changeAgencySubmissionDate,
  changeAgencyRating,
}) => {
  const [isInitialAssessmentDocumentModalVisible, setIsInitialAssessmentDocumentModalVisible] = useState(false);
  const [isSubmissionConfirmed, setIsSubmissionConfirmed] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [warningMessages, setWarningMessages] = useState([]);

  const isAssessed = getIsAssessedValue(agencyFormValues, partnerFormValues, role);

  const isAssessmentResultsUploaded = pseaInitialAssessmentDocuments?.filter((item) => (item.is_agency && role === ROLES.AGENCY) || (!item.is_agency && role === ROLES.PARTNER))?.find((item) => item?.doc_type === "assessment_results") !== undefined ?? false;

  useEffect(() => {
    getPseaInitialAssessmentDetails();
    getPseaInitialAssessmentDocumentsList();
  }, []);

  const onClickCopyUploadedDocument = async () => {
    const agencyDocuments = pseaInitialAssessmentDocuments?.filter((item) => item.is_agency);
    for (const item of agencyDocuments) {
      try {
        await deletePseaInitialAssessmentDocument(item.id);
      } catch (error) {
        toast.error(
          formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.unableToSaveData",
          }),
        );
      }
    }
    const partnerDocuments = pseaInitialAssessmentDocuments?.filter((item) => !item.is_agency);
    for (const item of partnerDocuments) {
      try {
        await createPseaInitialAssessmentDocument({
          doc_type: item?.doc_type,
          file: item?.file?.id,
          note: item?.note ?? undefined,
        });
      } catch (error) {
        toast.error(
          formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.unableToSaveData",
          }),
        );
      }
    }
    getPseaInitialAssessmentDocumentsList();
    changeAgencyUnAgencySubmitter(partnerInitialFormValues?.partner_un_agency_submitter);
    changeAgencySubmissionDate(partnerInitialFormValues?.partner_submission_date);
    changeAgencyRating(partnerInitialFormValues?.partner_rating);
  };

  useEffect(() => {
    setWarningMessages(
      revisionToJustify && !revisionToJustify.justification_to_proceed && (revisionToJustify?.revision_rating === "low" || revisionToJustify?.revision_rating === "medium" || revisionToJustify?.revision_rating === "not_eligible") && role === ROLES.AGENCY
        ? [
            {
              title: formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.initialAssessmentWarningBannerTitle",
              }),
              tooltip: formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.initialAssessmentWarningBannerTooltip",
              }),
            },
          ]
        : undefined,
    );
  }, [revisionToJustify?.id]);

  const onCloseSuccessMessage = () => setIsSuccessMessageVisible(false);

  const onClickUploadInitialAssessmentDocument = () => {
    setIsInitialAssessmentDocumentModalVisible(true);
  };

  const onClickCancelUploadInitialAssessmentDocument = () => {
    setIsInitialAssessmentDocumentModalVisible(false);
    resetInitialAssessmentDocumentForm();
  };

  const onClickSubmitUploadInitialAssessmentDocument = async () => {
    try {
      await createPseaInitialAssessmentDocument({
        doc_type: initialAssessmentDocumentFormValues?.doc_type,
        file: initialAssessmentDocumentFormValues?.file?.id,
        note: initialAssessmentDocumentFormValues?.note ?? undefined,
      });
      getPseaInitialAssessmentDocumentsList();
      setIsInitialAssessmentDocumentModalVisible(false);
      resetInitialAssessmentDocumentForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickDeleteDocument = async (item) => {
    try {
      await deletePseaInitialAssessmentDocument(item.id);
      getPseaInitialAssessmentDocumentsList();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickSubmit = async () => {
    try {
      let formValues = {};
      if (role === ROLES.PARTNER) formValues = partnerFormValues;
      else if (role === ROLES.AGENCY) formValues = agencyFormValues;
      else formValues = {};
      await updatePseaInitialAssessment(adaptInitialAssessmentFormValuesToBeSent(formValues, role));
      getPseaInitialAssessmentDetails();
      getPseaDetails();
      if (role === ROLES.PARTNER) setIsSuccessMessageVisible(true);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickConfirmSubmission = (value) => setIsSubmissionConfirmed(value);

  const getIsAgencyLockedValue = () => {
    if (role === ROLES.AGENCY && hasSpecialPseaPermission) return undefined;
    else if ((pseaInitialAssessment?.partner_beneficiaries ?? undefined) === undefined) return true;
  };

  const isAgencyLocked = getIsAgencyLockedValue();

  if (!pseaInitialAssessment) return <></>;

  const confirmedBy = pseaInitialAssessment?.confirmed_by ? pseaInitialAssessment.agency_name : undefined;
  const isAgencyRatingLocked = pseaInitialAssessment?.agency_is_assessed === false;
  let isFormValid = false;

  if (role === ROLES.AGENCY) {
    isFormValid = validateAnswersForm(agencyFormValues, pseaInitialAssessmentDocuments, role);
  } else {
    isFormValid = validateAnswersForm(partnerFormValues, pseaInitialAssessmentDocuments, role);
  }

  const isDifferentRatingCommentRequired = agencyFormValues?.agency_rating && partnerFormValues?.partner_rating && agencyFormValues?.agency_rating !== partnerFormValues?.partner_rating;

  return (
    <>
      <SectionContainer
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.mainTitle",
        })}
        isExpanded
        warningMessages={warningMessages}
      >
        <div className={style.sectionsWrapper}>
          <div>
            <PartnerAnswers
              formValues={partnerFormValues}
              initialValues={partnerInitialFormValues}
              ratingOptions={ratingOptionsWithoutValid}
              role={role}
              onClickUploadInitialAssessmentDocument={onClickUploadInitialAssessmentDocument}
              documents={pseaInitialAssessmentDocuments?.filter((item) => !item.is_agency)}
              documentTypes={documentTypes}
              onClickDeleteDocument={onClickDeleteDocument}
              isLocked={pseaInitialAssessment?.is_locked}
              hasEditPseaInitialAssessmentPermission={hasEditPseaInitialAssessmentPermission}
              readOnly={role !== ROLES.PARTNER}
            />
          </div>
          {role === ROLES.AGENCY ? (
            <div>
              <Confirmation
                initialValues={agencyInitialFormValues}
                ratingOptions={ratingOptions}
                isLocked={pseaInitialAssessment?.is_locked}
                isAgencyLocked={isAgencyLocked}
                isAgencyRatingLocked={isAgencyRatingLocked}
                hasEditPseaInitialAssessmentPermission={hasEditPseaInitialAssessmentPermission}
                formValues={agencyFormValues}
                documents={pseaInitialAssessmentDocuments?.filter((item) => item.is_agency)}
                documentTypes={documentTypes}
                onClickDeleteDocument={onClickDeleteDocument}
                onClickUploadInitialAssessmentDocument={onClickUploadInitialAssessmentDocument}
                readOnly={role === ROLES.PARTNER}
                isCopyPseaEnabled={role !== ROLES.PARTNER && partnerInitialFormValues?.partner_is_assessed === "true" && !pseaInitialAssessment?.is_locked}
                onClickCopyUploadedDocument={onClickCopyUploadedDocument}
                isDifferentRatingCommentRequired={isDifferentRatingCommentRequired}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {pseaInitialAssessment?.is_migrated ? (
          <div className={style.migratedMessageWrapper}>
            <Alert
              type="warning"
              content={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.migratedPsea",
              })}
            />
          </div>
        ) : (
          <></>
        )}
        {confirmedBy && pseaInitialAssessment?.confirmed_on && role === ROLES.PARTNER && pseaInitialAssessment?.different_rating_comment ? (
          <div className={style.warningMessageWrapper}>
            <Alert
              type="warning"
              content={
                <>
                  <span>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.differentRatingCommentTitle",
                    })}{" "}
                  </span>
                  <b>{pseaInitialAssessment?.different_rating_comment}</b>
                </>
              }
            />
          </div>
        ) : undefined}
        {confirmedBy && pseaInitialAssessment?.confirmed_on ? (
          <div className={style.confirmMessageWrapper}>
            <Alert
              type="success"
              content={
                <div>
                  <span className={style.questionFooterTitle}>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.onDate",
                    })}
                  </span>
                  <span className={style.questionFooterDescription}>{formatDate(pseaInitialAssessment?.confirmed_on)}</span>
                  <span className={style.questionFooterTitle}>{confirmedBy}</span>
                  <span className={style.questionFooterTitle}>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.confirmedPartner",
                    })}
                  </span>
                  {pseaInitialAssessment?.agency_beneficiaries ? (
                    <span className={style.questionFooterDescription}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.hasContactWithBeneficiaries",
                      })}
                    </span>
                  ) : (
                    <span className={style.questionFooterDescription}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.doesNotHaveContactWithBeneficiaries",
                      })}
                    </span>
                  )}
                  {pseaInitialAssessment?.agency_is_assessed ? (
                    <span className={style.questionFooterTitle}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.hasPreviousAssessment",
                      })}
                    </span>
                  ) : (
                    <span className={style.questionFooterTitle}>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.doesNotHavePreviousAssessment",
                      })}
                      .
                    </span>
                  )}
                  {pseaInitialAssessment?.agency_is_assessed && pseaInitialAssessment?.agency_rating ? (
                    <>
                      <span className={style.questionFooterTitle}>
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.dated",
                        })}
                      </span>
                      <span className={style.questionFooterDescription}>{formatDate(pseaInitialAssessment?.agency_submission_date)}</span>
                      <span className={style.questionFooterTitle}>
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.agencyRating",
                        })}
                      </span>
                      <span className={style.questionFooterDescription}>{ratingOptions?.find((item) => item.value === pseaInitialAssessment?.agency_rating)?.extendedLabel}.</span>
                    </>
                  ) : undefined}
                  <span className={style.questionFooterDescription}>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.confirmedPartnerNotEditable",
                    })}
                    .
                  </span>
                </div>
              }
            />
          </div>
        ) : (
          <></>
        )}
        {role === ROLES.PARTNER && confirmedBy && pseaInitialAssessment?.agency_rating === "not_valid" ? (
          <div className={style.warningMessageWrapper}>
            <Alert
              type="warning"
              content={
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.notValidInfo",
                  })}
                </span>
              }
            />
          </div>
        ) : undefined}
        {revisionToJustify && role === ROLES.AGENCY ? (
          <>
            <span className={style.justificationsToProceedTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.justificationTitle",
              })}
            </span>
            <JustificationSection revisionToJustify={revisionToJustify} partnerId={partnerId} isAddJustificationAvailable={isAddJustificationAvailable} />
          </>
        ) : (
          <></>
        )}
        <div className={style.successMessageWrapper}>
          <Collapse in={isSuccessMessageVisible}>
            <div>
              <Alert
                type="success"
                content={
                  <>
                    <span>
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.initialAssessmentSuccessMessage",
                      })}
                    </span>
                  </>
                }
                actionLabel={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.ok",
                })}
                onClickAction={onCloseSuccessMessage}
              />
            </div>
          </Collapse>
        </div>
        <div className={style.mainActionWrapper}>
          <div>
            {!pseaInitialAssessment?.is_locked && !isAgencyLocked && hasEditPseaInitialAssessmentPermission && role === ROLES.AGENCY ? (
              <FieldCheckbox
                input={{
                  value: isSubmissionConfirmed,
                  onChange: onClickConfirmSubmission,
                }}
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.confirmation",
                })}
              />
            ) : undefined}
          </div>
          <div className={style.actionButtons}>
            <Button
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.text",
              })}
              isDisabled={
                !isFormValid ||
                pseaInitialAssessment?.is_locked ||
                (role === ROLES.AGENCY && isAgencyLocked) ||
                !hasEditPseaInitialAssessmentPermission ||
                (!isSubmissionConfirmed && role === ROLES.AGENCY) ||
                (isDifferentRatingCommentRequired && !agencyFormValues?.different_rating_comment?.trim())
              }
              onClick={onClickSubmit}
            />
            {isAssessed && !isAssessmentResultsUploaded ? (
              <small className={style.actionsWaring}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.initial.assessment.idx.initialSectionUploadDocumentDisclaimer",
                })}
              </small>
            ) : (
              <></>
            )}
          </div>
        </div>
      </SectionContainer>
      <UploadDocumentModal
        onCancel={onClickCancelUploadInitialAssessmentDocument}
        onSubmit={onClickSubmitUploadInitialAssessmentDocument}
        isOpen={isInitialAssessmentDocumentModalVisible}
        documentTypes={documentTypes}
        isFormValid={isInitialAssessmentDocumentFormValid}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const role = state?.session?.role;
  const hasSpecialPseaPermission = role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.SPECIAL_PSEA_PERMISSIONS, state) : checkPermission(PARTNER_PERMISSIONS.SPECIAL_PSEA_PERMISSIONS, state);
  const pseaInitialAssessment = state?.pseaInitialAssessmentDetails?.data?.data ?? undefined;

  const agencyFormValues = state.form["pseaInitialSectionAgencyAnswersForm"]?.values ?? {};
  const partnerFormValues = state.form["pseaInitialSectionPartnerAnswersForm"]?.values ?? {};

  const agencyInitialFormValues = adaptAgencyInitialValues(pseaInitialAssessment);
  const partnerInitialFormValues = adaptPartnerInitialValues(pseaInitialAssessment);

  const initialAssessmentDocumentFormValues = state.form?.pseaInitialAssessmentDocumentForm?.values ?? {};

  return {
    pseaInitialAssessment,
    pseaInitialAssessmentDocuments: state?.pseaInitialAssessmentDocumentsList?.data?.data ?? [],
    initialAssessmentDocumentFormValues,
    ratingOptions: getPseaRatingOptions(),
    ratingOptionsWithoutValid: getPseaRatingOptions(false),
    documentTypes: getPseaInitialAssessmentDocumentTypes(),
    role,
    isInitialAssessmentDocumentFormValid: isValid("pseaInitialAssessmentDocumentForm")(state),
    hasEditPseaInitialAssessmentPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.EDIT_PSEA_INITIAL_ASSESSMENT, state) : checkPermission(PARTNER_PERMISSIONS.EDIT_PSEA_INITIAL_ASSESSMENT, state),
    hasSpecialPseaPermission,
    agencyFormValues,
    partnerFormValues,
    agencyInitialFormValues,
    partnerInitialFormValues,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetInitialAssessmentDocumentForm: () => dispatch(reset("pseaInitialAssessmentDocumentForm")),
  getPseaInitialAssessmentDetails: () =>
    dispatch(
      getPseaInitialAssessmentDetails(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
  updatePseaInitialAssessment: (data) => dispatch(updatePseaInitialAssessment(data, { partnerId: ownProps.partnerId })),
  getPseaInitialAssessmentDocumentsList: () =>
    dispatch(
      getPseaInitialAssessmentDocumentsList(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
  createPseaInitialAssessmentDocument: (data) =>
    dispatch(
      createPseaInitialAssessmentDocument(data, {
        partnerId: ownProps.partnerId,
      }),
    ),
  deletePseaInitialAssessmentDocument: (id) =>
    dispatch(
      deletePseaInitialAssessmentDocument({
        partnerId: ownProps.partnerId,
        id,
      }),
    ),
  getPseaDetails: () => dispatch(getPseaDetails(undefined, { partnerId: ownProps.partnerId })),
  changeAgencyUnAgencySubmitter: (value) => dispatch(change("pseaInitialSectionAgencyAnswersForm", "agency_un_agency_submitter", value)),
  changeAgencySubmissionDate: (value) => dispatch(change("pseaInitialSectionAgencyAnswersForm", "agency_submission_date", value)),
  changeAgencyRating: (value) => dispatch(change("pseaInitialSectionAgencyAnswersForm", "agency_rating", value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PseaInitialAssessment);

import React from "react";
import MUIModal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import style from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import WarningIcon from "@mui/icons-material/Warning";
import HelpIcon from "@mui/icons-material/Help";

const getTitleByType = (title, type) => {
  switch (type) {
    case "warning":
      return (
        <div className={style.titleWrapper}>
          <div style={{ backgroundColor: "#ffaa0033", color: "#ffaa00ff" }} className={style.titleIconWrapper}>
            <WarningIcon style={{ fontSize: ".9rem" }} />
          </div>
          <span>{title}</span>
        </div>
      );
    case "confirmation":
      return (
        <div className={style.titleWrapper}>
          <div style={{ backgroundColor: "#1ba9e933", color: "#1ba9e9ff" }} className={style.titleIconWrapper}>
            <HelpIcon style={{ fontSize: ".9rem" }} />
          </div>
          <span>{title}</span>
        </div>
      );
    default:
      return (
        <div className={style.titleWrapper}>
          <span>{title}</span>
        </div>
      );
  }
};

const Modal = ({ isOpen, onClose, children, title, footer, small, type = "normal", hasLeftFooterElements = false }) => {
  return (
    <MUIModal open={isOpen} className={style.mainWrapper}>
      <Paper elevation={3} className={small ? style.smallBoxWrapper : style.boxWrapper}>
        <div className={style.headerWrapper}>
          <div>{getTitleByType(title, type)}</div>
          {onClose ? (
            <div>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={small ? style.smallContentWrapper : style.contentWrapper}>{children}</div>
        {footer ? <div className={hasLeftFooterElements ? style.customFooterWrapper : style.footerWrapper}>{footer}</div> : undefined}
      </Paper>
    </MUIModal>
  );
};

export default Modal;

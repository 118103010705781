import React from "react";
import HeaderNavigation from "@local/components/header-navigation";
import MainContent from "@local/components/main-content-wrapper";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import Tooltip from "@mui/material/Tooltip";
import Button from "@local/components/button";
import { getApplicationStatusColor } from "../../utils/adapters";
import { getApplicationStatuses } from "@local/utils/constants";
import { APPLICATION_STATUSES, PROJECT_STATUSES } from "@local/legacy-utils/constants";
import style from "./style.module.scss";

const getHeaderButtons = (application, loading, isCompleted, assessment, isDeadlinePassed, onClickManageReview, onClickWithdrawApplication, projectStatus, isRetractAllowed, isAssessActionAllowed, hasAssessPermission) => {
  const isActionButtonVisible = !isCompleted && !loading && application?.status === APPLICATION_STATUSES.PRE;
  const isActionButtonDisabled = projectStatus !== PROJECT_STATUSES.CLO;
  return [
    {
      id: "application_details_header_button_retracted",
      render: () => (
        <Button
          isDisabled
          text={formatMessage({
            id: "pages.application.details.cmp.container.idx.retracted",
          })}
        />
      ),
      isVisible: isActionButtonVisible && assessment?.completed && application?.did_win && application?.did_withdraw,
    },

    {
      id: "application_details_header_button_withdraw",
      render: () => (
        <Tooltip
          title={formatMessage({
            id: "pages.application.details.cmp.container.idx.decision",
          })}
        >
          <span>
            <Button
              text={formatMessage({
                id: "pages.application.details.cmp.container.idx.withdraw",
              })}
              onClick={onClickWithdrawApplication}
            />
          </span>
        </Tooltip>
      ),
      isVisible: isActionButtonVisible && isRetractAllowed && assessment?.completed && application?.did_win,
    },
    {
      id: "application_details_header_button_add_review",
      render: () => (
        <Button
          text={formatMessage({
            id: "pages.application.details.cmp.container.idx.addReview",
          })}
          onClick={onClickManageReview}
        />
      ),
      isVisible: isActionButtonVisible && isAssessActionAllowed && hasAssessPermission && isDeadlinePassed && !assessment,
    },
    {
      id: "application_details_header_button_edit_review",
      render: () => (
        <Button
          text={formatMessage({
            id: "pages.application.details.cmp.container.idx.editReview",
          })}
          onClick={onClickManageReview}
          isDisabled={isActionButtonDisabled}
        />
      ),
      isVisible: isActionButtonVisible && isAssessActionAllowed && hasAssessPermission && isDeadlinePassed && assessment && assessment?.completed !== true,
    },
  ];
};

const Container = ({ application, children, partner, projectStatus, loading, isCompleted, isRetractAllowed, hasAssessPermission, isAssessActionAllowed, isDeadlinePassed, onClickManageReview, onClickWithdrawApplication, assessment }) => {
  const applicationStatus = application?.application_status;

  const headerButtons = getHeaderButtons(application, loading, isCompleted, assessment, isDeadlinePassed, onClickManageReview, onClickWithdrawApplication, projectStatus, isRetractAllowed, isAssessActionAllowed, hasAssessPermission);

  return (
    <>
      <HeaderNavigation
        backButtonPath={-1}
        title={`${formatMessage({
          id: "pages.application.details.cmp.container.idx.header",
        })} ${partner}`}
        header={
          <div className={style.headerActionsWrapper}>
            {applicationStatus ? (
              <Chip
                icon={
                  <CircleIcon
                    style={{
                      fill: getApplicationStatusColor(applicationStatus),
                    }}
                    fontSize="small"
                  />
                }
                label={getApplicationStatuses()[applicationStatus]}
              />
            ) : (
              <></>
            )}
            <div className={style.buttonsWrapper}>{headerButtons?.map((item) => (item.isVisible ? item.render() : undefined))}</div>
          </div>
        }
      >
        <MainContent>{children}</MainContent>
      </HeaderNavigation>
    </>
  );
};

Container.propTypes = {};

export default Container;

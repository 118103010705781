/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldFile from "@local/components/field-file";
import Box from "@local/components/box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import FieldDatePicker from "@local/components/field-date-picker";
import CircularProgress from "@mui/material/CircularProgress";
import { WrapperComponent } from "../../utils";
import { isRequired, isValidDate, isValidUrl } from "@local/utils/validators";

// POST_REFACTORING_TODO: It should not be possible to select the same audit report twice,
// and should not be possible to add more of them if all were selected,
// this have to checked in other tabs to

const validateFileLinkMissing = (fileFieldName, linkFiledName, formValues) => {
  if (!formValues[fileFieldName] && !formValues[linkFiledName]) {
    return formatMessage({
      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.missingFileLinkError",
    });
  }
  return "";
};

const ProjectImplementation = ({ onSubmitAndContinue, onSubmitAndExit, financialControlSystems, accountingMethods, areasOfResponsibility, areasOfPolicy, auditTypes, capacityAssessments, isLoading, formValues, stateValues, inEditMode }) => {
  const [audits, setAudits] = useState(undefined);
  const [assessments, setAssessments] = useState(undefined);

  useEffect(() => {
    if (stateValues?.audits && !audits) setAudits(stateValues.audits);
  }, [stateValues?.audits]);

  useEffect(() => {
    if (stateValues?.assessments && !assessments) setAssessments(stateValues.assessments);
  }, [stateValues?.assessments]);

  const onChangeHaveRegularityAudited = (value) => {
    if (value?.toString() === "true") {
      setAudits([{ id: 0, originalId: undefined }]);
    } else {
      setAudits([]);
    }
  };

  const onChangeHaveCapacityAssessment = (value) => {
    if (value?.toString() === "true") {
      setAssessments([{ id: 0, originalId: undefined }]);
    } else {
      setAssessments([]);
    }
  };

  const onClickAddAudit = () => {
    const updatedAudits = [
      ...audits,
      {
        id: audits.length ? audits.sort((a, b) => a.id > b.id)[audits.length - 1].id + 1 : 1,
        originalId: undefined,
      },
    ];
    setAudits(updatedAudits);
  };

  const onClickAddAssessment = () => {
    const updatedAssessments = [
      ...assessments,
      {
        id: assessments.length ? assessments.sort((a, b) => a.id > b.id)[assessments.length - 1].id + 1 : 1,
        originalId: undefined,
      },
    ];
    setAssessments(updatedAssessments);
  };

  const onClickRemoveAudit = (toRemove) => {
    const updatedAudits = audits.filter((item) => item.id !== toRemove.id);
    setAudits(updatedAudits);
  };

  const onClickRemoveAssessment = (toRemove) => {
    const updatedAssessments = assessments.filter((item) => item.id !== toRemove.id);
    setAssessments(updatedAssessments);
  };

  const onClickSaveAndContinue = () => {
    onSubmitAndContinue({ audits, assessments });
  };
  const onClickSaveAndExit = () => {
    onSubmitAndExit({ audits, assessments });
  };

  if (isLoading || !audits || !assessments) return <CircularProgress size={50} />;

  const haveResultsBasedApproach = formValues?.have_management_approach?.toString() === "true";
  const haveMonitoringSystem = formValues?.have_system_monitoring?.toString() === "true";
  const haveFeedbackSystem = formValues?.have_feedback_mechanism?.toString() === "true";
  const haveSystemTrack = formValues?.have_system_track?.toString() === "true";
  const haveExperiencedStaff = formValues?.experienced_staff?.toString() === "true";
  const haveRegularityAudited = formValues?.regular_audited?.toString() === "true";
  const haveAccountabilityIssues = formValues?.major_accountability_issues_highlighted?.toString() === "true";
  const haveCapacityAssessment = formValues?.regular_capacity_assessments?.toString() === "true";
  const havePublishedResults = formValues?.publish_annual_reports?.toString() === "true";

  const projectImplementationErrorMessage = validateFileLinkMissing("project_impl_report", "last_report", formValues);

  return (
    <WrapperComponent
      inEditMode={inEditMode}
      title={formatMessage({
        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.projectImplementation",
      })}
      footer={
        <div className={style.cardActionsWrapper}>
          <Button
            variant="text"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.saveAndExit",
            })}
            onClick={onClickSaveAndExit}
          />
          <Button
            variant="contained"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.saveAndContinue",
            })}
            onClick={onClickSaveAndContinue}
          />
        </div>
      }
    >
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.programmeManagement",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_management_approach"
                name="have_management_approach"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.resultsBasedApproach",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.resultsBasedApproach",
                })}
                informationText={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.resultsBasedApproachOne",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
              {haveResultsBasedApproach ? (
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="management_approach_desc"
                  name="management_approach_desc"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.resultsDescription",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.resultsDescription",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_system_monitoring"
                name="have_system_monitoring"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.monitoringSystem",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.monitoringSystem",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
              {haveMonitoringSystem ? (
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="system_monitoring_desc"
                  name="system_monitoring_desc"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.meDescription",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.meDescription",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_feedback_mechanism"
                name="have_feedback_mechanism"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.feedbackSystem",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.feedbackSystem",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
              {haveFeedbackSystem ? (
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="feedback_mechanism_desc"
                  name="feedback_mechanism_desc"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.feedbackDescription",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.feedbackDescription",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.financialControls",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldSelect}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="org_acc_system"
                name="org_acc_system"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.accountingSystem",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.accountingSystem",
                })}
                valueField="value"
                labelField="label"
                options={financialControlSystems}
              />
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="method_acc"
                name="method_acc"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.methodAdopted",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.methodAdopted",
                })}
                valueField="value"
                labelField="label"
                options={accountingMethods}
              />
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_system_track"
                name="have_system_track"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.trackSystem",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.trackSystem",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
              {haveSystemTrack ? (
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="financial_control_system_desc"
                  name="financial_control_system_desc"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.systemDescription",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.systemDescription",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.internalControls",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Box
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.policyArea",
                })}
                sections={areasOfResponsibility?.map((item) => (
                  <React.Fragment key={`internal_controls_wrapper_${item.value}`}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FieldSelect
                          highlightType="basic"
                          id={`internal_controls_functional_responsibility_${item.value}`}
                          name={`internal_controls_functional_responsibility_${item.value}`}
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.areaOfResponsibility",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.areaOfResponsibility",
                          })}
                          valueField="value"
                          labelField="label"
                          options={areasOfResponsibility}
                          readOnly
                          input={{ value: item.value }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={FieldRadioGroup}
                          validate={[isRequired]}
                          highlightType="basic"
                          readOnly={!inEditMode}
                          small={!inEditMode}
                          id={`internal_controls_segregation_duties_${item.value}`}
                          name={`internal_controls_segregation_duties_${item.value}`}
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.segregationOf",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.segregationOf",
                          })}
                          options={[
                            {
                              value: true,
                              label: formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                              }),
                            },
                            {
                              value: false,
                              label: formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                              }),
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={FieldText}
                          validate={[isRequired]}
                          highlightType="basic"
                          readOnly={!inEditMode}
                          small={!inEditMode}
                          id={`internal_controls_comment_${item.value}`}
                          name={`internal_controls_comment_${item.value}`}
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.pleaseComment",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.pleaseComment",
                          })}
                          type="text"
                          maxLength={5000}
                          withCharactersCount
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              />
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="experienced_staff"
                name="experienced_staff"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.experiencedStaff",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.experiencedStaff",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
              {haveExperiencedStaff ? (
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="experienced_staff_desc"
                  name="experienced_staff_desc"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.pleaseComment",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.pleaseComment",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
              <Box
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.policyarea2",
                })}
                sections={areasOfPolicy?.map((item) => (
                  <React.Fragment key={`area_policies_wrapper_${item.value}`}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FieldSelect
                          highlightType="basic"
                          id={`area_policies_area_${item.value}`}
                          name={`area_policies_area_${item.value}`}
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.areaOfResponsibility",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.areaOfResponsibility",
                          })}
                          valueField="value"
                          labelField="label"
                          options={areasOfPolicy}
                          readOnly
                          input={{ value: item.value }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={FieldRadioGroup}
                          validate={[isRequired]}
                          highlightType="basic"
                          readOnly={!inEditMode}
                          small={!inEditMode}
                          id={`area_policies_document_policies_${item.value}`}
                          name={`area_policies_document_policies_${item.value}`}
                          label={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.documentedPolicies",
                          })}
                          placeholder={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.documentedPolicies",
                          })}
                          options={[
                            {
                              value: true,
                              label: formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                              }),
                            },
                            {
                              value: false,
                              label: formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                              }),
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.bankingInformation",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_bank_account"
                name="have_bank_account"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.hasBankAccount",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.hasBankAccount",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.audit&Assessments",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="regular_audited"
                name="regular_audited"
                onChangeExtra={onChangeHaveRegularityAudited}
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.isRegularyAudited",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.isRegularyAudited",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
              {haveRegularityAudited && audits ? (
                <Box
                  sections={[
                    ...(audits?.map((item, index) => {
                      const errorMessage = validateFileLinkMissing(`audit_reports_most_recent_audit_report_${item.id}`, `audit_reports_audit_link_report_${item.id}`, formValues);
                      return (
                        <React.Fragment key={`audit_reports_wrapper_${item.id}`}>
                          <div className={style.itemWrapper}>
                            <div>
                              <Grid container spacing={2}>
                                {errorMessage ? (
                                  <Grid item xs={12}>
                                    <span className={style.errorMessage}>{errorMessage}</span>
                                  </Grid>
                                ) : undefined}
                                <Grid item xs={12}>
                                  <Field
                                    component={FieldSelect}
                                    validate={[isRequired]}
                                    highlightType="basic"
                                    readOnly={!inEditMode}
                                    small={!inEditMode}
                                    id={`audit_reports_org_audit_${item.id}`}
                                    name={`audit_reports_org_audit_${item.id}`}
                                    label={formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.organizationUndergoes",
                                    })}
                                    placeholder={formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.organizationUndergoes",
                                    })}
                                    informationText={formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.organizationUndergoesTooltip",
                                    })}
                                    valueField="value"
                                    labelField="label"
                                    options={auditTypes}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    component={FieldFile}
                                    highlightType="basic"
                                    readOnly={!inEditMode}
                                    small={!inEditMode}
                                    id={`audit_reports_most_recent_audit_report_${item.id}`}
                                    name={`audit_reports_most_recent_audit_report_${item.id}`}
                                    label={formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.copyOfRecentAudit",
                                    })}
                                    placeholder={formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.copyOfRecentAudit",
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    component={FieldText}
                                    validate={[isValidUrl]}
                                    highlightType="basic"
                                    readOnly={!inEditMode}
                                    small={!inEditMode}
                                    id={`audit_reports_audit_link_report_${item.id}`}
                                    name={`audit_reports_audit_link_report_${item.id}`}
                                    label={formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.insertLink",
                                    })}
                                    placeholder={formatMessage({
                                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.insertLink",
                                    })}
                                    type="text"
                                    maxLength={5000}
                                    withCharactersCount
                                    multiline
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            {index > 0 && inEditMode ? (
                              <div>
                                <IconButton onClick={() => onClickRemoveAudit(item)}>
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            ) : undefined}
                          </div>
                        </React.Fragment>
                      );
                    }) ?? <React.Fragment key="audit_reports_wrapper_empty" />),
                  ]}
                  actions={
                    inEditMode ? (
                      <>
                        <Button
                          text={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.addNew",
                          })}
                          startIcon={<AddIcon />}
                          onClick={onClickAddAudit}
                          variant="text"
                        />
                      </>
                    ) : undefined
                  }
                />
              ) : (
                <Field
                  component={FieldText}
                  highlightType="advanced"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="regular_audited_comment"
                  name="regular_audited_comment"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.comment",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.comment",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              )}
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="major_accountability_issues_highlighted"
                name="major_accountability_issues_highlighted"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.accountabilityIssues",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.accountabilityIssues",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
              {haveAccountabilityIssues ? (
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="comment"
                  name="comment"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.comment",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.comment",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              ) : undefined}
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="regular_capacity_assessments"
                name="regular_capacity_assessments"
                onChangeExtra={onChangeHaveCapacityAssessment}
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.formalCapacity",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.formalCapacity",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                    }),
                  },
                ]}
              />
            </FieldGroup>
            {haveCapacityAssessment && assessments ? (
              <Box
                sections={assessments?.map((item, index) => (
                  <React.Fragment key={`capacity_assessments_wrapper_${item.id}`}>
                    <div className={style.itemWrapper}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Field
                              component={FieldSelect}
                              validate={[isRequired]}
                              highlightType="basic"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`capacity_assessments_assessment_type_${item.id}`}
                              name={`capacity_assessments_assessment_type_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.indicateAssessments",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.indicateAssessments",
                              })}
                              valueField="value"
                              labelField="label"
                              options={capacityAssessments}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={FieldFile}
                              validate={[isRequired]}
                              highlightType="basic"
                              readOnly={!inEditMode}
                              small={!inEditMode}
                              id={`capacity_assessments_report_file_${item.id}`}
                              name={`capacity_assessments_report_file_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.copyOfAssessment",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.copyOfAssessment",
                              })}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      {index > 0 && inEditMode ? (
                        <div>
                          <IconButton onClick={() => onClickRemoveAssessment(item)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ) : undefined}
                    </div>
                  </React.Fragment>
                ))}
                actions={
                  inEditMode ? (
                    <>
                      <Button
                        text={formatMessage({
                          id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.addNew",
                        })}
                        startIcon={<AddIcon />}
                        onClick={onClickAddAssessment}
                        variant="text"
                      />
                    </>
                  ) : undefined
                }
              />
            ) : undefined}
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.reporting",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  component={FieldText}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="key_result"
                  name="key_result"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.keyResults",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.keyResults",
                  })}
                  type="text"
                  maxLength={5000}
                  withCharactersCount
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={FieldRadioGroup}
                  validate={[isRequired]}
                  highlightType="basic"
                  readOnly={!inEditMode}
                  small={!inEditMode}
                  id="publish_annual_reports"
                  name="publish_annual_reports"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.publishAnnualReports",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.publishAnnualReports",
                  })}
                  options={[
                    {
                      value: true,
                      label: formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.yes",
                      }),
                    },
                    {
                      value: false,
                      label: formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.no",
                      }),
                    },
                  ]}
                />
              </Grid>
              {havePublishedResults ? (
                <>
                  {projectImplementationErrorMessage ? (
                    <Grid item xs={12}>
                      <span className={style.errorMessage}>{projectImplementationErrorMessage}</span>
                    </Grid>
                  ) : undefined}
                  <Grid item xs={12}>
                    <Field
                      component={FieldFile}
                      highlightType="advanced"
                      readOnly={!inEditMode}
                      small={!inEditMode}
                      id="project_impl_report"
                      name="project_impl_report"
                      label={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.mostRecentReport",
                      })}
                      placeholder={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.mostRecentReport",
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={FieldDatePicker}
                      validate={[isRequired, isValidDate]}
                      highlightType="advanced"
                      readOnly={!inEditMode}
                      small={!inEditMode}
                      id="last_report"
                      name="last_report"
                      maximumDate={new Date()}
                      label={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.dateOfReport",
                      })}
                      placeholder={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.dateOfReport",
                      })}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={FieldText}
                      validate={[isValidUrl]}
                      highlightType="advanced"
                      readOnly={!inEditMode}
                      small={!inEditMode}
                      id="link_report"
                      name="link_report"
                      label={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.link",
                      })}
                      placeholder={formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.project.implementation.idx.link",
                      })}
                      type="text"
                      maxLength={5000}
                      withCharactersCount
                      multiline
                    />
                  </Grid>
                </>
              ) : undefined}
            </Grid>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

ProjectImplementation.propTypes = {};

export default reduxForm({
  form: "editPartnerProfileProjectImplementationForm",
  enableReinitialize: true,
})(ProjectImplementation);

/* eslint-disable max-len */

import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

am4core.useTheme(am4themes_kelly);

class NumberOfPartnerCompletedProfile extends Component {
  componentDidMount() {
    var chart = am4core.create("chartdiv", am4charts.PieChart);
    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(40);

    var chartColors = new am4core.ColorSet();
    chartColors.list = ["rgba(135, 86, 146, 1)", "rgba(94, 44, 242, 1)", "rgba(50, 101, 219, 1)", "rgba(44, 211, 242, 1)", "rgba(42, 232, 153, 1)", "rgba(190, 0, 50, 1)"].map(function (color) {
      return new am4core.color(color);
    });

    var chartColors2 = new am4core.ColorSet();
    chartColors2.list = [
      "rgba(135, 86, 146, 1)",
      "rgba(135, 86, 146, 0.5)",
      "rgba(94, 44, 242, 1)",
      "rgba(94, 44, 242, 0.5)",
      "rgba(50, 101, 219, 1)",
      "rgba(50, 101, 219, 0.5)",
      "rgba(44, 211, 242, 1)",
      "rgba(44, 211, 242, 0.5)",
      "rgba(42, 232, 153, 1)",
      "rgba(42, 232, 153, 0.5)",
      "rgba(190, 0, 50, 1)",
      "rgba(190, 0, 50, 0.5)",
    ].map(function (color) {
      return new am4core.color(color);
    });

    // Add data
    chart.data = this.props.chartData;
    chart.innerRadius = 48;
    var label = chart.seriesContainer.createChild(am4core.Label);
    label.text = this.props.total + "/" + this.props.totalCompleted;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 14;

    // Add second series
    var pieSeries2 = chart.series.push(new am4charts.PieSeries());
    pieSeries2.colors = chartColors2;
    pieSeries2.dataFields.value = "partnerProfile";
    pieSeries2.dataFields.category = "partnerCompletedName";
    pieSeries2.slices.template.stroke = am4core.color("#fff");
    pieSeries2.slices.template.strokeWidth = 2;
    pieSeries2.slices.template.strokeOpacity = 1;
    pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    pieSeries2.slices.template.states.getKey("hover").properties.scale = 0.9;
    pieSeries2.ticks.template.disabled = true;
    pieSeries2.labels.template.disabled = true;

    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.colors = chartColors;
    pieSeries.dataFields.value = "partnerType";
    pieSeries.dataFields.category = "name";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // Disabling labels and ticks on inner circle
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    // Disable sliding out of slices
    pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    pieSeries.slices.template.states.getKey("hover").properties.scale = 1.1;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return <div id="chartdiv" style={{ width: "100%", height: "200px", marginBottom: "1.5rem" }}></div>;
  }
}

export default NumberOfPartnerCompletedProfile;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from "react";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldFile from "@local/components/field-file";
import FieldListInput from "@local/components/field-list-input";
import Box from "@local/components/box";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import FieldDatePicker from "@local/components/field-date-picker";
import CircularProgress from "@mui/material/CircularProgress";
import { WrapperComponent } from "../../utils";
import { isRequired, isValidDate, isBetween } from "@local/utils/validators";

const Identification = ({ partner, onSubmitAndContinue, onSubmitAndExit, countries, partnerTypes, isLoading, formValues, stateValues, inEditMode }) => {
  const [governmentDocuments, setGovernmentDocuments] = useState(undefined);
  const [registrationDocuments, setRegistrationDocuments] = useState(undefined);
  const isYearBetween = useMemo(() => isBetween(1000, new Date().getFullYear()), []);

  useEffect(() => {
    if (stateValues?.governmentDocuments && !governmentDocuments) setGovernmentDocuments(stateValues.governmentDocuments);
  }, [stateValues?.governmentDocuments]);

  useEffect(() => {
    if (stateValues?.registrationDocuments && !registrationDocuments) setRegistrationDocuments(stateValues.registrationDocuments);
  }, [stateValues?.registrationDocuments]);

  const onChangeHaveGovernmentDocuments = (value) => {
    if (value?.toString() === "true") {
      setGovernmentDocuments([{ id: 0, originalId: undefined }]);
    } else {
      setGovernmentDocuments([]);
    }
  };

  const onChangeHaveRegistrationDocuments = (value) => {
    if (value?.toString() === "true") {
      setRegistrationDocuments([{ id: 0, originalId: undefined }]);
    } else {
      setRegistrationDocuments([]);
    }
  };

  const onClickAddGovernmentDocument = () => {
    const updatedGovernmentDocuments = [
      ...governmentDocuments,
      {
        id: governmentDocuments.length ? governmentDocuments.sort((a, b) => a.id > b.id)[governmentDocuments.length - 1].id + 1 : 1,
        originalId: undefined,
      },
    ];
    setGovernmentDocuments(updatedGovernmentDocuments);
  };

  const onClickRemoveGovernmentDocument = (toRemove) => {
    const updatedGovernmentDocuments = governmentDocuments.filter((item) => item.id !== toRemove.id);
    setGovernmentDocuments(updatedGovernmentDocuments);
  };

  const onClickAddRegistrationDocument = () => {
    const updatedRegistrationDocuments = [
      ...registrationDocuments,
      {
        id: registrationDocuments.length ? registrationDocuments.sort((a, b) => a.id > b.id)[registrationDocuments.length - 1].id + 1 : 1,
        originalId: undefined,
      },
    ];
    setRegistrationDocuments(updatedRegistrationDocuments);
  };

  const onClickRemoveRegistrationDocument = (toRemove) => {
    const updatedRegistrationDocuments = registrationDocuments.filter((item) => item.id !== toRemove.id);
    setRegistrationDocuments(updatedRegistrationDocuments);
  };

  const onClickSaveAndContinue = () => {
    onSubmitAndContinue({ governmentDocuments, registrationDocuments });
  };

  const onClickSaveAndExit = () => {
    onSubmitAndExit({ governmentDocuments, registrationDocuments });
  };

  if (isLoading || !governmentDocuments || !registrationDocuments) return <CircularProgress size={50} />;

  const isCountryProfile = (partner?.is_hq ?? false) === false;
  const isReadOnly = !(isCountryProfile && partner.display_type === "Int");
  const fieldIsReadOnly = (item) => item?.editable === false;
  const hasGovernmentDocument = formValues?.have_governing_document?.toString() === "true";
  const isRegisteredInCountry = formValues?.registered_to_operate_in_country?.toString() === "true";

  return (
    <WrapperComponent
      inEditMode={inEditMode}
      title={formatMessage({
        id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.identification",
      })}
      footer={
        <div className={style.cardActionsWrapper}>
          <Button
            variant="text"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.saveAndExit",
            })}
            onClick={onClickSaveAndExit}
          />
          <Button
            variant="contained"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.saveAndContinue",
            })}
            onClick={onClickSaveAndContinue}
          />
        </div>
      }
    >
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.basicInformation",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldText}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode || isReadOnly}
                small={!inEditMode || isReadOnly}
                id="legal_name"
                name="legal_name"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.legalName",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.legalName",
                })}
                type="text"
              />
              <Field
                component={FieldListInput}
                highlightType="advanced"
                readOnly={!inEditMode || isReadOnly}
                small={!inEditMode || isReadOnly}
                id="other_names"
                name="other_names"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.otherNames",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.otherNames",
                })}
                emptyPlaceholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.otherNamesEmpty",
                })}
              />
              <Field
                component={FieldSelect}
                validate={[isRequired]}
                highlightType="basic"
                readOnly
                small={!inEditMode}
                id="country_code"
                name="country_code"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.countryOrigin",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.countryOrigin",
                })}
                valueField="value"
                labelField="label"
                options={countries}
              />
              <Field
                component={FieldSelect}
                validate={[isRequired]}
                highlightType="basic"
                readOnly
                small={!inEditMode}
                id="display_type"
                name="display_type"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.projectDetailsType",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.projectDetailsType",
                })}
                valueField="value"
                labelField="label"
                options={partnerTypes}
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.legalStatus",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldText}
                validate={[isRequired, isYearBetween]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="year_establishment"
                name="year_establishment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.yearEstablishment",
                })}
                type="number"
              />
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_governing_document"
                name="have_governing_document"
                onChangeExtra={onChangeHaveGovernmentDocuments}
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.haveGovDoc",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.haveGovDoc",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.no",
                    }),
                  },
                ]}
              />
              {hasGovernmentDocument && governmentDocuments ? (
                <Box
                  title={formatMessage({
                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.governingDoc",
                  })}
                  sections={governmentDocuments.map((item, index) => (
                    <React.Fragment key={`governing_document_wrapper_${item.id}`}>
                      <div className={style.itemWrapper}>
                        <div>
                          <FieldGroup>
                            <Field
                              component={FieldFile}
                              validate={[isRequired]}
                              highlightType="basic"
                              readOnly={!inEditMode || fieldIsReadOnly(item)}
                              small={!inEditMode}
                              id={`governing_document_${item.id}`}
                              name={`governing_document_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.button",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.button",
                              })}
                              informationText={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.authorizedOfficer",
                              })}
                            />
                          </FieldGroup>
                        </div>
                        {index > 0 && inEditMode ? (
                          <div>
                            <IconButton onClick={() => onClickRemoveGovernmentDocument(item)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ) : undefined}
                      </div>
                    </React.Fragment>
                  ))}
                  actions={
                    inEditMode ? (
                      <>
                        <Button
                          text={formatMessage({
                            id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.addNew",
                          })}
                          startIcon={<AddIcon />}
                          onClick={onClickAddGovernmentDocument}
                          variant="text"
                        />
                      </>
                    ) : undefined
                  }
                />
              ) : (
                <div>
                  <Field
                    component={FieldText}
                    validate={[isRequired]}
                    highlightType="basic"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="missing_governing_document_comment"
                    name="missing_governing_document_comment"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.comment",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.comment",
                    })}
                    type="text"
                    maxLength={50}
                  />
                </div>
              )}
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                highlightType="basic"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="registered_to_operate_in_country"
                name="registered_to_operate_in_country"
                onChangeExtra={onChangeHaveRegistrationDocuments}
                label={
                  isCountryProfile
                    ? formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.registrationCountry",
                      })
                    : formatMessage({
                        id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.registrationCountryHq",
                      })
                }
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.no",
                    }),
                  },
                ]}
              />
              {isRegisteredInCountry && registrationDocuments ? (
                <div>
                  <Box
                    title={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.registeredInCountryLabel",
                    })}
                    sections={registrationDocuments.map((item, index) => (
                      <React.Fragment key={`registration_wrapper_document_${item.id}`}>
                        <div className={style.itemWrapper}>
                          <div>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Field
                                  component={FieldDatePicker}
                                  validate={[isRequired, isValidDate]}
                                  highlightType="basic"
                                  readOnly={!inEditMode || fieldIsReadOnly(item)}
                                  small={!inEditMode}
                                  id={`registration_issue_date_${item.id}`}
                                  name={`registration_issue_date_${item.id}`}
                                  label={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.date",
                                  })}
                                  placeholder={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.date",
                                  })}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Field
                                  component={FieldText}
                                  highlightType="basic"
                                  readOnly={!inEditMode || fieldIsReadOnly(item)}
                                  small={!inEditMode}
                                  id={`registration_issuing_authority_${item.id}`}
                                  name={`registration_issuing_authority_${item.id}`}
                                  label={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.registeringAuthority",
                                  })}
                                  placeholder={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.registeringAuthority",
                                  })}
                                  type="text"
                                  maxLength={50}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Field
                                  component={FieldText}
                                  validate={[isRequired]}
                                  highlightType="basic"
                                  readOnly={!inEditMode || fieldIsReadOnly(item)}
                                  small={!inEditMode}
                                  id={`registration_registration_number_${item.id}`}
                                  name={`registration_registration_number_${item.id}`}
                                  label={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.number",
                                  })}
                                  placeholder={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.number",
                                  })}
                                  type="text"
                                  maxLength={50}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Field
                                  component={FieldFile}
                                  validate={[isRequired]}
                                  highlightType="basic"
                                  readOnly={!inEditMode || fieldIsReadOnly(item)}
                                  small={!inEditMode}
                                  id={`registration_document_${item.id}`}
                                  name={`registration_document_${item.id}`}
                                  label={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.document",
                                  })}
                                  placeholder={formatMessage({
                                    id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.document",
                                  })}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {index > 0 && inEditMode ? (
                            <div>
                              <IconButton onClick={() => onClickRemoveRegistrationDocument(item)}>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ) : undefined}
                        </div>
                      </React.Fragment>
                    ))}
                    actions={
                      inEditMode ? (
                        <>
                          <Button
                            text={formatMessage({
                              id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.addNew",
                            })}
                            startIcon={<AddIcon />}
                            onClick={onClickAddRegistrationDocument}
                            variant="text"
                          />
                        </>
                      ) : undefined
                    }
                  />
                </div>
              ) : (
                <div>
                  <Field
                    component={FieldText}
                    validate={[isRequired]}
                    highlightType="basic"
                    readOnly={!inEditMode}
                    small={!inEditMode}
                    id="missing_registration_document_comment"
                    name="missing_registration_document_comment"
                    label={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.comment",
                    })}
                    placeholder={formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.identification.idx.comment",
                    })}
                    type="text"
                    maxLength={50}
                  />
                </div>
              )}
            </FieldGroup>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

Identification.propTypes = {};

export default reduxForm({
  form: "editPartnerProfileIdentificationForm",
  enableReinitialize: true,
})(Identification);

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getAssessmentReportsList, getCountriesList, getAgenciesList } from "@local/actions";
import Table from "@local/components/table";
import Breadcrumb from "@local/components/breadcrumb";
import { getAssessmentReportsColumns } from "../../utils/columns";
import { adaptCountries } from "@local/utils/adapters";
import AssessmentDetailsModal from "./components/modal";

const Assessment = ({ getAssessmentReports, countries, getCountries, getAgencies, partnerId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    getAgencies();
    getCountries();
  }, []);

  const onClickOpenModal = (row) => {
    setSelectedRow(row);
    setIsModalVisible(true);
  };
  const onClickCloseModal = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            id: 1,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.assessment.idx.dashboardTitle",
            }),
            url: `/partner/${partnerId}/risk/dashboard`,
          },
          {
            id: 2,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.assessment.idx.dashboardTitleOne",
            }),
            url: `/partner/${partnerId}/risk/assessment`,
          },
        ]}
      />
      <Table
        key="risk_assessments_list"
        id="risk_assessments_list"
        title={formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.assessment.idx.dashboardTitleOne",
        })}
        columns={getAssessmentReportsColumns(countries, onClickOpenModal)}
        fetchFunction={getAssessmentReports}
      />
      <AssessmentDetailsModal assessment={selectedRow} isOpen={isModalVisible} onClose={onClickCloseModal} />
    </>
  );
};

Assessment.propTypes = {};

const mapStateToProps = (state) => ({
  agencies: state?.agenciesList?.data?.data?.results ?? [],
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAssessmentReports: (params) => dispatch(getAssessmentReportsList({ ...params, partner: ownProps.partnerId })),
  getCountries: () => dispatch(getCountriesList()),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Assessment);

import Chip from "@mui/material/Chip";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import SanctionStatus from "@local/components/sanction-status";
import VerificationIcon from "@local/components/verification-icon";
import i18n from "@local/i18next/i18n";
import CircleIcon from "@mui/icons-material/Circle";
import style from "./style.module.scss";
import { csipMap, ratingMap, selfAssessmentMap } from "@local/utils/constants";

const getRatingColor = (rating) => {
  switch (rating) {
    case "low":
      return "#FFA500";
    case "medium":
      return "#90EE90";
    case "full":
      return "#057b52";
    case "low_risk":
      return "#0000CC";
    case "not_eligible":
      return "#cc0000";
    case "pending_agency_confirm":
      return "#f7cb73";
    default:
      return "#777777";
  }
};

const getCsipColor = (csip) => {
  switch (csip) {
    case "available":
      return "#90EE90";
    case "overdue":
      return "#057b52";
    default:
      return "#777777";
  }
};

const getSelfAssessmentColor = (selfAssessment) => {
  switch (selfAssessment) {
    case "in_progress":
      return "#90EE90";
    case "submitted":
      return "#057b52";
    default:
      return "#777777";
  }
};

export const getPartnerInformationColumns = () => [
  {
    id: "id",
    title: formatMessage({ id: "pages.reports.list.utils.columns.id" }),
  },
  {
    id: "legal_name",
    title: formatMessage({ id: "pages.reports.list.utils.columns.legalName" }),
  },
  {
    id: "organization_type",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.organizationType",
    }),
  },
  {
    id: "country",
    title: formatMessage({ id: "pages.reports.list.utils.columns.country" }),
  },
  {
    id: "no_of_offices",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.noOfOffices",
    }),
    type: "custom",
    render: (row) => <span>{row.offices.length}</span>,
  },
  {
    id: "agency_experiences",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.agencyExperiences",
    }),
  },
];

export const getCfeiManagementColumns = () => [
  {
    id: "displayID",
    title: formatMessage({ id: "pages.reports.list.utils.columns.displayID" }),
  },
  {
    id: "title",
    title: formatMessage({ id: "pages.reports.list.utils.columns.title" }),
  },
  {
    id: "locations",
    title: formatMessage({ id: "pages.reports.list.utils.columns.locations" }),
    type: "custom",
    render: (row) => <span>{row?.locations.map((location) => location?.admin_level_1?.country_name).join(", ")}</span>,
  },
  {
    id: "locations_project",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.locationsProject",
    }),
    type: "custom",
    render: (row) => <span>{row?.locations.map((location) => location?.admin_level_1?.name).join(", ")}</span>,
  },
  {
    id: "type_display",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.typeDisplay",
    }),
  },
];

export const getPseaColumns = () => [
  {
    id: "id",
    title: "Psea Id",
  },
  {
    id: "legal_name",
    title: i18n.t("Partner Name"),
  },
  {
    id: "self_assessment",
    title: i18n.t("Self-assessment Completed by Partner"),
    type: "custom",
    render: (row) => (
      <>
        {row?.self_assessment ? (
          <Chip
            size="small"
            icon={
              <CircleIcon
                style={{
                  fill: getSelfAssessmentColor(row?.self_assessment),
                  fontSize: ".75rem",
                }}
              />
            }
            label={<span className={style.ratingLabel}>{selfAssessmentMap[row?.self_assessment]}</span>}
          />
        ) : (
          "--"
        )}
      </>
    ),
  },
  {
    id: "ppcr_rating",
    title: i18n.t("Rating"),
    type: "custom",
    render: (row) => (
      <>
        {" "}
        {row?.ppcr_rating ? (
          <Chip
            size="small"
            icon={
              <CircleIcon
                style={{
                  fill: getRatingColor(row?.ppcr_rating),
                  fontSize: ".75rem",
                }}
              />
            }
            label={<span className={style.ratingLabel}>{ratingMap[row?.ppcr_rating]}</span>}
          />
        ) : (
          "--"
        )}
      </>
    ),
  },
  {
    id: "ppcr_completed_by",
    title: i18n.t("Completed by"),
  },
  {
    id: "ppcr_date",
    title: i18n.t("Date"),
    type: "date",
  },
  {
    id: "csip_status",
    title: i18n.t("Capacity Strengthening Implemetation Plan (CSIP)"),
    type: "custom",
    render: (row) => (
      <>
        {row?.csip_status ? (
          <Chip
            size="small"
            icon={
              <CircleIcon
                style={{
                  fill: getCsipColor(row?.csip_status),
                  fontSize: ".75rem",
                }}
              />
            }
            label={<span className={style.ratingLabel}>{csipMap[row?.csip_status]}</span>}
          />
        ) : (
          "--"
        )}
      </>
    ),
  },
  {
    id: "pfd_rating",
    title: i18n.t("Rating"),
    type: "custom",
    render: (row) => (
      <>
        {row?.pfd_rating ? (
          <Chip
            size="small"
            icon={
              <CircleIcon
                style={{
                  fill: getRatingColor(row?.pfd_rating),
                  fontSize: ".75rem",
                }}
              />
            }
            label={<span className={style.ratingLabel}>{ratingMap[row?.pfd_rating]}</span>}
          />
        ) : (
          "--"
        )}
      </>
    ),
  },
  {
    id: "pfd_completed_by",
    title: i18n.t("Completed by"),
  },
  {
    id: "pfd_date",
    title: i18n.t("Date"),
    type: "date",
  },
];

export const getPseaUpperColumns = () => [
  {
    id: "id",
    title: "",
    span: 1,
  },
  {
    id: "legal_name",
    title: "",
    span: 1,
  },
  {
    id: "psea_self_assessment_completed_by_partner",
    title: "",
    span: 1,
  },
  {
    id: "ppcr",
    title: i18n.t("Preliminary PSEA Capacity Rating"),
    span: 3,
  },
  {
    id: "csip",
    title: "",
    span: 1,
  },
  {
    id: "pfd",
    title: i18n.t("PSEA Final Determination"),
    span: 3,
  },
];

export const getVerificationColumns = () => [
  {
    id: "id",
    title: formatMessage({ id: "pages.reports.list.utils.columns.idOne" }),
  },
  {
    id: "legal_name",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.legalNameOne",
    }),
  },
  {
    id: "icons",
    title: "",
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: ".25rem" }}>
            <VerificationIcon profileStatus={row?.profile_status} />
          </div>
          <SanctionStatus sanction={row?.sanctions_status} />
          {row?.is_locked ? (
            <div>
              <Chip
                size="small"
                style={{ backgroundColor: "#e84033", color: "#FFFFFF" }}
                label={formatMessage({
                  id: "pages.reports.list.utils.columns.deleted",
                })}
              />
            </div>
          ) : undefined}
        </div>
      );
    },
  },
  {
    id: "other_names",
    title: formatMessage({ id: "pages.reports.list.utils.columns.otherNames" }),
    type: "custom",
    render: (row) => <span>{row?.other_names?.join(", ") || "--"}</span>,
  },
  {
    id: "organization_type",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.organizationTypeOne",
    }),
  },
  {
    id: "country",
    title: formatMessage({ id: "pages.reports.list.utils.columns.countryOne" }),
  },
  {
    id: "verification_year",
    title: formatMessage({
      id: "pages.reports.list.utils.columns.verificationYear",
    }),
  },
];

import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getBooleanValue, getColorByHighlightType } from "@local/utils";

const FieldCheckbox = ({ id, label, small, readOnly, input: { value = null, onChange }, onChangeExtra, meta, highlightType }) => {
  const onChangeOption = (event, value) => {
    onChange(value);
    if (onChangeExtra) onChangeExtra(value);
  };

  const color = getColorByHighlightType(highlightType);

  return (
    <div
      style={{
        border: color ? `solid 1px ${meta?.error ? "#d32f2f" : "#b7b7b7"}` : undefined,
        backgroundColor: color ? `${color}29` : undefined,
        padding: color ? ".5rem .75rem" : undefined,
        borderRadius: "4px",
      }}
    >
      <FormControlLabel sx={{ marginRight: "0" }} id={id} size={small ? "small" : undefined} error={(meta?.error ?? "") !== ""} disabled={readOnly} control={<Checkbox checked={getBooleanValue(value)} onChange={onChangeOption} />} label={label} />
    </div>
  );
};

FieldCheckbox.propTypes = {};

FieldCheckbox.defaultProps = {
  small: false,
};

export default FieldCheckbox;

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import React from "react";
import formatDistance from "date-fns/formatDistance";
import differenceInDays from "date-fns/differenceInDays";
import add from "date-fns/add";
import Button from "@local/components/button";

export const getShellProfilesColumns = (countries) => [
  {
    id: "legal_name",
    title: formatMessage({
      id: "pages.shell.profiles.list.utils.columns.legalName",
    }),
    type: "text",
  },
  {
    id: "email",
    title: formatMessage({
      id: "pages.shell.profiles.list.utils.columns.email",
    }),
  },
  {
    id: "country_code",
    title: formatMessage({
      id: "pages.shell.profiles.list.utils.columns.countryCode",
    }),
    type: "custom",
    render: (row) => <span>{countries.find((item) => item.value === row.country_code)?.label ?? "--"}</span>,
  },
  {
    id: "display_type",
    title: formatMessage({
      id: "pages.shell.profiles.list.utils.columns.displayType",
    }),
  },
  {
    id: "hq",
    title: formatMessage({ id: "pages.shell.profiles.list.utils.columns.hq" }),
    type: "custom",
    render: (row) => <span>{row?.hq?.legal_name ?? "--"}</span>,
  },
  {
    id: "vendor_number",
    title: formatMessage({
      id: "pages.shell.profiles.list.utils.columns.vendorNumber",
    }),
  },
  {
    id: "age",
    title: formatMessage({ id: "pages.shell.profiles.list.utils.columns.age" }),
    type: "custom",
    render: (row) => {
      const { created } = row;
      const todayDate = new Date();
      const createdDateUtc = new Date(created);
      const todayDateUtc = add(todayDate, {
        minutes: todayDate.getTimezoneOffset(),
      });
      const distance = formatDistance(todayDateUtc, createdDateUtc);
      const days = differenceInDays(todayDateUtc, createdDateUtc);
      let backgroundColor = "#000000";
      if (days < 180) {
        backgroundColor = "#87c834";
      } else if (days < 360) {
        backgroundColor = "#f47006";
      } else {
        backgroundColor = "#fe0800";
      }
      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                width: "1rem",
                height: "1rem",
                display: "inline-block",
                borderRadius: "1rem",
                marginRight: ".5rem",
                backgroundColor,
              }}
            />
            <span style={{ textTransform: "capitalize" }}>{distance}</span>
          </div>
        </>
      );
    },
  },
];

export const getShellProfilesExpandSectionItems = (onClickSendNotification, onClickDeleteInvitation) => [
  {
    id: "send_notification",
    title: formatMessage({
      id: "pages.shell.profiles.list.utils.columns.sendNotificationButton",
    }),
    type: "custom",
    render: (row) => (
      <Button
        variant="text"
        onClick={() => onClickSendNotification(row)}
        text={formatMessage({
          id: "pages.shell.profiles.list.utils.columns.sendNotificationButton",
        })}
      />
    ),
  },
  {
    id: "delete_invitation",
    title: formatMessage({
      id: "pages.shell.profiles.list.utils.columns.deleteInvitationButton",
    }),
    type: "custom",
    render: (row) => (
      <Button
        variant="text"
        onClick={() => onClickDeleteInvitation(row)}
        text={formatMessage({
          id: "pages.shell.profiles.list.utils.columns.deleteInvitationButton",
        })}
      />
    ),
  },
];

import { applicationStatusColor } from "@local/styles/muiTheme";

export const getApplicationStatusColor = (type) => applicationStatusColor[type];

export const getReviewModalInitialValues = (assessment, isSingleReviewer) => {
  let scoresValues = {};
  assessment?.scores?.forEach((item) => {
    scoresValues[`score_${item.selection_criteria}`] = item.score;
  });
  return {
    note: assessment?.note ?? "",
    ...(isSingleReviewer ? { is_a_committee_score: assessment?.is_a_committee_score ?? undefined } : {}),
    ...scoresValues,
  };
};

import React from "react";
import MUIPagination from "@mui/material/Pagination";
import style from "./style.module.scss";

const Pagination = ({ id, count, page, onPageChange }) => {
  return (
    <div className={style.paginationWrapper} key={id}>
      <MUIPagination count={count} page={page} color="primary" onChange={onPageChange} />
    </div>
  );
};

Pagination.propTypes = {};

export default Pagination;

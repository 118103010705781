import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@local/styles/muiTheme";
import Card from "@mui/material/Card";
import { CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import logo from "@local/assets/images/logo.png";
import { grey } from "@mui/material/colors";

const styleSheet = () => ({
  card: {
    minWidth: 275,
    marginTop: "100px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 25,
    padding: "10px",
  },
  pos: {
    marginBottom: 12,
  },
  cardMedia: {
    backgroundColor: grey,
  },
});

function Page404(props) {
  const { classes } = props;

  return (
    <Grid container>
      <Grid item xs={4}></Grid>
      <Grid item xs={4} style={{ textAlign: "center" }}>
        <Card className={classes.card}>
          <Link to={"/"}>
            <CardMedia className={classes.cardMedia}>
              <img alt="" src={logo} />
            </CardMedia>
          </Link>
          <Typography className={classes.title} color="textPrimary" gutterBottom>
            <h1>404</h1>
            <b>Page Not Found</b>
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

Page404.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet)(Page404);

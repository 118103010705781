import React from "react";
import FieldGroup from "@local/components/field-group";
import FieldFile from "@local/components/field-file";
import Card from "@local/components/card";
import Steps from "@local/components/steps";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import MainContentWrapper from "@local/components/main-content-wrapper";
import HeaderNavigation from "@local/components/header-navigation";
import style from "./style.module.scss";

const Main = ({ onClickSubmit }) => {
  return (
    <>
      <HeaderNavigation title="Demo" />
      <MainContentWrapper>
        <Card
          title="Demo"
          footer={
            <div className={style.cardActionsWrapper}>
              <Button text="Submit" onClick={onClickSubmit} />
            </div>
          }
        >
          <FieldGroup>
            <Field id="single_file_upload" name="single_file_upload" label="Single file upload" placeholder="Single file upload" component={FieldFile} />
            <Field id="multiple_file_upload" name="multiple_file_upload" label="Multiple file upload" placeholder="Multiple file upload" component={FieldFile} multiple />
          </FieldGroup>
          <br />
          <br />
          <br />
          <Steps
            options={[
              {
                id: "foo",
                label: "Foo",
              },
              {
                id: "bar",
                label: "Bar",
              },
            ]}
          />
        </Card>
      </MainContentWrapper>
    </>
  );
};

export default reduxForm({ form: "demoForm" })(Main);

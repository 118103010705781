import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptSpecializations } from "@local/utils/adapters";

// POST_REFACTORING_TODO: These functions are too heavy, check if they can be memoized

export const getApplicationsFilters = (countries, sectors, agencies) => {
  let result = [];
  result.push({
    id: "project_title",
    name: "project_title",
    label: formatMessage({ id: "pages.partner.details.utils.filters.search" }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.search",
    }),
    type: "text",
  });
  result.push({
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.partner.details.utils.filters.country" }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: countries,
  });
  result.push({
    id: "specializations",
    name: "specializations",
    label: formatMessage({ id: "pages.partner.details.utils.filters.sector" }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  });
  result.push({
    id: "agency",
    name: "agency",
    label: formatMessage({ id: "pages.partner.details.utils.filters.agency" }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.agency",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: agencies,
  });
  result.push({
    id: "did_win",
    name: "did_win",
    label: formatMessage({ id: "pages.partner.details.utils.filters.show" }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.show",
    }),
    type: "checkbox",
  });
  return result;
};

export const getObservationsFilters = (flagTypes, riskCategories) => {
  let result = [];
  result.push({
    id: "flag_type",
    name: "flag_type",
    label: formatMessage({
      id: "pages.partner.details.utils.filters.typeObservation",
    }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.typeObservation",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: flagTypes,
  });
  result.push({
    id: "category",
    name: "category",
    label: formatMessage({
      id: "pages.partner.details.utils.filters.category",
    }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.category",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: riskCategories,
  });
  result.push({
    id: "only_mine",
    name: "only_mine",
    label: formatMessage({ id: "pages.partner.details.utils.filters.showOne" }),
    placeholder: formatMessage({
      id: "pages.partner.details.utils.filters.showOne",
    }),
    type: "checkbox",
  });
  return result;
};

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import Button from "@local/components/button";
import Alert from "@local/components/alert";
import ConfirmModal from "@local/components/confirm-modal";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import style from "./style.module.scss";

const CfeiManagement = ({ fetchFunction, columns, filters, adapterFunction, hasCFEIReportPermission, exportProjectDetailsReports, showDownloadInfo, downloadInfo, isOpen, onClose, onCloseDownloadInfo, onChangeTableFilters, initialFilters }) => {
  return (
    <>
      <Collapse in={showDownloadInfo}>
        <Alert
          type="success"
          content={
            <>
              <span className={style.alertTitle}>
                {formatMessage({
                  id: "pages.reports.list.cmp.cfei.management.idx.report",
                })}
              </span>
              <span>{downloadInfo}</span>
            </>
          }
          actionLabel={formatMessage({
            id: "pages.reports.list.cmp.cfei.management.idx.ok",
          })}
          onClickAction={onCloseDownloadInfo}
          withBottomMargin
        />
      </Collapse>
      <Table
        key="cfei_management_reports_list"
        id="cfei_management_reports_list"
        title={formatMessage({
          id: "pages.reports.list.cmp.cfei.management.idx.management",
        })}
        columns={columns}
        fetchFunction={fetchFunction}
        filters={filters}
        filterInfo={formatMessage({
          id: "pages.reports.list.cmp.cfei.management.idx.select",
        })}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        initialFilters={initialFilters}
        withUrlSynchronization
        actions={
          hasCFEIReportPermission && (
            <Button
              variant="text"
              text={formatMessage({
                id: "pages.reports.list.cmp.cfei.management.idx.exportReport",
              })}
              onClick={exportProjectDetailsReports}
            />
          )
        }
      />
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={exportProjectDetailsReports}
        title={formatMessage({
          id: "pages.reports.list.cmp.cfei.management.idx.reportWarning",
        })}
        type="warning"
        message={
          <div className={style.infoWrapper}>
            <Typography variant="subtitle1" component="div">
              <Alert
                type="info"
                content={formatMessage({
                  id: "pages.reports.list.cmp.cfei.management.idx.infoTitle",
                })}
                withBottomMargin
              />
            </Typography>
            <Typography variant="body1">
              <div className={style.infoText}>
                {formatMessage({
                  id: "pages.reports.list.cmp.cfei.management.idx.info",
                })}
              </div>
            </Typography>
          </div>
        }
      />
    </>
  );
};

CfeiManagement.propTypes = {};

export default CfeiManagement;

import React, { useState } from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import imageLogo from "../../assets/images/login-page-logo.png";
import imageBackground from "../../assets/images/login-page-background.jpg";
import imageUn from "@local/assets/images/login-page-un.png";
import imageUnfpa from "@local/assets/images/login-page-unfpa.png";
import imageUnhcr from "@local/assets/images/login-page-unhcr.png";
import imageUnicef from "@local/assets/images/login-page-unicef.png";
import imageUnwomen from "@local/assets/images/login-page-unwomen.png";
import imageFao from "@local/assets/images/login-page-fao.png";
import imageWho from "@local/assets/images/login-page-who.png";
import imageIom from "@local/assets/images/login-page-iom.png";
import imageUnops from "@local/assets/images/login-page-unops.png";
import imageWfp from "@local/assets/images/login-page-wfp.png";
import RegistrationAgencyModal from "./components/registration-agency-modal";
import RegistrationModal from "./components/registration-modal";
import style from "./style.module.scss";
import { getBaseUrl } from "@local/utils/network";

const getEmailErrorMessage = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(email.toLowerCase())) return formatMessage({ id: "pages.login.register.emailError" });
  return "";
};

const Register = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [modalVisible, setModalVisible] = useState(undefined);

  const onClickCloseModal = () => setModalVisible(undefined);

  const onChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
    setError(getEmailErrorMessage(value));
  };

  const onClickSubmit = () => {
    const emailDomain = email.split("@")[1].toLowerCase();
    const agenciesDomain = ["un.org", "unwomen.org", "fao.org", "undp.org", "unfpa.org", "unhcr.org", "unicc.org", "unicef.org", "wfp.org", "who.int", "wipo.int", "iom.int", "unops.org"];

    if (agenciesDomain.includes(emailDomain)) setModalVisible("registration-agency-modal");
    else if (!agenciesDomain.includes(emailDomain) && emailDomain !== "gmail.com") setModalVisible("registration-modal");
    else window.location.assign(`${getBaseUrl()}/api/auth/authorize/login/csopartner_authn/?email=${encodeURIComponent(email)}`);
  };

  const onClickBack = () => window.location.assign("/login");

  const onClickSubmitRegister = () => window.location.assign(`${getBaseUrl()}/api/auth/authorize/login/csopartner_authn/?email=${encodeURIComponent(email)}`);

  return (
    <>
      <div
        className={style.mainWrapper}
        style={{
          backgroundImage: `linear-gradient(0deg, #2296F3BB, #2296F3BB), url("${imageBackground}")`,
        }}
      >
        <div className={style.headerWrapper}>
          <IconButton onClick={onClickBack}>
            <span className={style.backButtonWrapper}>
              <KeyboardArrowLeft fontSize="large" />
            </span>
          </IconButton>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.columnsWrapper}>
            <div>
              <div className={style.loginWrapper}>
                <span className={style.title}>{formatMessage({ id: "pages.login.register.title" })}</span>
                <span className={style.subtitle}>{formatMessage({ id: "pages.login.register.subtitle" })}</span>
                <div className={style.formWrapper}>
                  <label className={style.formLabel} htmlFor="registerEmail">
                    {formatMessage({ id: "pages.login.register.emailField" })}
                  </label>
                  <input className={error === "" ? style.formField : style.formFieldWithError} type="email" name="email" id="registerEmail" value={email} onChange={onChangeEmail} />
                  <small className={style.errorMessage}>{error}</small>
                  <button disabled={error !== "" || email === ""} className={style.formButton} type="submit" onClick={onClickSubmit}>
                    <span>{formatMessage({ id: "pages.login.register.next" })}</span>
                    <NavigateNextIcon />
                  </button>
                  <small className={style.formButtonHelpText}>
                    {formatMessage({
                      id: "pages.login.register.passwordHelperText",
                    })}
                  </small>
                </div>
              </div>
            </div>
            <div>
              <img alt={formatMessage({ id: "pages.login.register.logoAltText" })} src={imageLogo} />
              <p className={style.description}>{formatMessage({ id: "pages.login.register.description" })}</p>
              <a
                className={style.learnMoreLink}
                alt={formatMessage({
                  id: "pages.login.register.learnMoreAltText",
                })}
                href={`${getBaseUrl()}/landing/about/`}
              >
                {formatMessage({ id: "pages.login.register.learnMore" })}
              </a>
              <div className={style.agenciesLogosWrapper}>
                <img alt="IOM" src={imageIom} />
                <img alt="UN" src={imageUn} />
                <img alt="UN WOMEN" src={imageUnwomen} />
                <img alt="FAO" src={imageFao} />
                <img alt="UNFPA" src={imageUnfpa} />
                <img alt="UNHCR" src={imageUnhcr} />
                <img alt="UNICEF" src={imageUnicef} />
                <img alt="UNOPS" src={imageUnops} />
                <img alt="WFP" src={imageWfp} />
                <img alt="WHO" src={imageWho} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <RegistrationAgencyModal isOpen={modalVisible === "registration-agency-modal"} onClose={onClickCloseModal} onSubmit={onClickBack} />
      <RegistrationModal isOpen={modalVisible === "registration-modal"} onClose={onClickCloseModal} onSubmit={onClickSubmitRegister} />
    </>
  );
};

Register.propTypes = {};

export default Register;

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";

export const getUserColumns = () => [
  {
    id: "fullname",
    title: formatMessage({
      id: "pages.users.management.utils.columns.fullname",
    }),
  },
  {
    id: "email",
    title: formatMessage({ id: "pages.users.management.utils.columns.email" }),
  },
  {
    id: "roles",
    title: formatMessage({ id: "pages.users.management.utils.columns.roles" }),
    type: "custom",
    render: (row) => <span>{row?.office_memberships?.map((item) => `${item.role_display} (${item?.office?.name ?? "--"})`)?.join(", ") ?? "--"}</span>,
  },
];

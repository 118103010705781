import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import Button from "@local/components/button";
import { getFlagsList, getAgenciesList, createFlag, updateFlag, createComment } from "@local/actions";
import { getFlagsColumns } from "../../utils/columns";
import ManageFlagModal from "./components/manage-flag-modal";
import FlagDetailsModal from "./components/flag-details-modal";
import ToggleActivationFlagModal from "./components/toggle-activate-flag-modal";
import Breadcrumb from "@local/components/breadcrumb";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "@local/legacy-utils/restrictions";
import { useParams } from "react-router-dom";

const Flag = ({ getFlags, getAgencies, createFlag, updateFlag, createComment, addCommentFormValues, manageFlagFormValues, deactivateFlagFormValues, resetManageFlagForm, resetAddCommentForm, resetCloseFlagForm, partnerId, agencyId, session, flags }) => {
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [manageFlagModalVisible, setManageFlagModalVisible] = useState(false);
  const [isDeactivateFlagModalVisible, setIsDeactivateFlagModalVisible] = useState(false);
  const [isActivateFlagModalVisible, setIsActivateFlagModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [flagStatus, setFlagStatus] = useState(undefined);
  const [flagComments, setFlagComments] = useState(undefined);
  const [commentsNumber, setCommentsNumber] = useState(-1);
  const { flagId } = useParams();

  const adaptedValues = {
    ...selectedRow,
    comment: selectedRow?.comment?.message,
  };

  useEffect(() => {
    getAgencies();
  }, [getAgencies]);

  useEffect(() => {
    if (flagId && flags.length) {
      const currentFlag = flags.find((item) => item.id === flagId);
      setSelectedRow(currentFlag);
      setFlagStatus(currentFlag?.status);
      setFlagComments(currentFlag?.comment);
      setCommentsNumber(-1);
      setDetailsModalVisible(true);
    }
  }, [flagId, flags]);

  const onClickOpenDetailsModal = (row) => {
    setSelectedRow(row);
    setFlagStatus(row?.status);
    setFlagComments(row?.comment);
    setCommentsNumber(-1);
    setDetailsModalVisible(true);
  };

  const onClickCloseDetailsModal = () => {
    setDetailsModalVisible(false);
    setSelectedRow(null);
  };

  const onClickOpenManageFlagModal = () => {
    setManageFlagModalVisible(true);
  };

  const onClickOpenDeactivateFlagModal = (row) => {
    resetCloseFlagForm();
    setSelectedRow(row);
    setIsDeactivateFlagModalVisible(true);
  };

  const onClickCloseDeactivateFlagModal = () => {
    setIsDeactivateFlagModalVisible(false);
    setSelectedRow(null);
    resetCloseFlagForm();
  };

  const onClickOpenActivateFlagModal = (row) => {
    setSelectedRow(row);
    setIsActivateFlagModalVisible(true);
  };

  const onClickCloseActivateFlagModal = () => {
    setIsActivateFlagModalVisible(false);
    setSelectedRow(null);
  };

  const onClickCloseManageFlagModal = () => {
    setManageFlagModalVisible(false);
    setIsEditable(false);
    resetManageFlagForm();
  };

  const handleOnClickEditFlag = (row) => {
    setSelectedRow(row);
    setIsEditable(true);
    setManageFlagModalVisible(true);
  };

  const afterRefresh = () => {
    setShouldRefresh(false);
  };

  const onSubmitRaiseFlagModal = async () => {
    try {
      await createFlag({
        ...manageFlagFormValues,
        comment: {
          message: manageFlagFormValues?.comment,
        },
        agency: agencyId,
        partner: partnerId,
        data: {
          additional_details: [
            {
              name: "New Additional Data",
              type: "text",
              value: "add something on first additional data",
            },
          ],
        },
      });
      setManageFlagModalVisible(false);
      setShouldRefresh(true);
      resetManageFlagForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.flag.idx.raiseError",
        }),
      );
    }
  };

  const onClickEditFlagModal = async () => {
    try {
      await updateFlag(adaptedValues?.id, {
        reason: manageFlagFormValues?.reason,
        comment: {
          message: manageFlagFormValues?.comment,
        },
        attachments: manageFlagFormValues?.attachments,
      });
      setManageFlagModalVisible(false);
      setIsEditable(false);
      setShouldRefresh(true);
      resetManageFlagForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.flag.idx.editError",
        }),
      );
    }
  };

  const onClickCloseFlag = async (flag) => {
    try {
      await updateFlag(flag?.id, {
        status: "close",
        deactivation_comment: deactivateFlagFormValues?.deactivation_comment,
      });
      setFlagStatus("close");
      resetCloseFlagForm();
      setShouldRefresh(true);
      setIsDeactivateFlagModalVisible(false);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.flag.idx.closeError",
        }),
      );
    }
  };

  const onClickOpenFlag = async (flag) => {
    try {
      await updateFlag(flag?.id, { status: "open" });
      setFlagStatus("open");
      setShouldRefresh(true);
      setIsActivateFlagModalVisible(false);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.flag.idx.openError",
        }),
      );
    }
  };

  const onClickCreateComment = async () => {
    try {
      const newFlagComment = await createComment({
        ...addCommentFormValues,
        replies: selectedRow?.comment?.id,
      });
      setFlagComments({
        comment_replies: [...(flagComments?.comment_replies ?? []), newFlagComment?.data],
      });
      resetAddCommentForm();
      setShouldRefresh(true);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.flag.idx.createCommentError",
        }),
      );
    }
  };

  const onClickViewAllComments = () => {
    const updatedCommentsNumber = commentsNumber - 4;
    setCommentsNumber(updatedCommentsNumber / -1 > flagComments.length ? flagComments.length : updatedCommentsNumber);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            id: 1,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.idx.dashboardTitle",
            }),
            url: `/partner/${partnerId}/risk/dashboard`,
          },
          {
            id: 2,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.idx.dashboardTitleOne",
            }),
            url: `/partner/${partnerId}/risk/flag`,
          },
        ]}
      />
      <Table
        key="risk_flags_list"
        id="risk_flags_list"
        title={formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.flag.idx.dashboardTitleOne",
        })}
        columns={getFlagsColumns(onClickOpenDetailsModal, handleOnClickEditFlag, onClickOpenDeactivateFlagModal, onClickOpenActivateFlagModal, session)}
        fetchFunction={getFlags}
        actions={
          isSectionVisible(AGENCY_RESTRICTIONS.partners.risk.flags["raiseFlag"], session) && (
            <Button
              text={formatMessage({
                id: "pages.partner.details.cmp.risk.cmp.flag.idx.button",
              })}
              onClick={onClickOpenManageFlagModal}
            />
          )
        }
        shouldRefresh={shouldRefresh}
        afterRefresh={afterRefresh}
      />
      <FlagDetailsModal
        flag={selectedRow}
        isOpen={detailsModalVisible}
        onClose={onClickCloseDetailsModal}
        onCreateComment={onClickCreateComment}
        session={session}
        flagStatus={flagStatus}
        flagComments={flagComments}
        onClickViewAllComments={onClickViewAllComments}
        commentsNumber={commentsNumber}
      />
      <ManageFlagModal isOpen={manageFlagModalVisible} onClose={onClickCloseManageFlagModal} initialValues={isEditable ? adaptedValues : undefined} isEditable={isEditable} onSubmit={onSubmitRaiseFlagModal} onEditFlag={onClickEditFlagModal} />
      <ToggleActivationFlagModal
        flag={selectedRow}
        isDeactivateFlagModalVisible={isDeactivateFlagModalVisible}
        isActivateFlagModalVisible={isActivateFlagModalVisible}
        onClickCloseDeactivateFlagModal={onClickCloseDeactivateFlagModal}
        onClickCloseActivateFlagModal={onClickCloseActivateFlagModal}
        onClickCloseFlag={onClickCloseFlag}
        onClickOpenFlag={onClickOpenFlag}
        isReadOnly={selectedRow?.status === "close"}
        initialValues={
          selectedRow?.status === "close"
            ? {
                deactivation_comment: selectedRow?.deactivation_comment?.message,
              }
            : undefined
        }
      />
    </>
  );
};

Flag.propTypes = {};

const mapStateToProps = (state) => ({
  agencies: state?.agenciesList?.data?.data?.results ?? [],
  agencyId: state?.session?.agencyId,
  session: state?.session,
  flags: state?.flagsList?.data?.data?.results ?? [],
  manageFlagFormValues: state?.form?.manageFlagForm?.values,
  addCommentFormValues: state?.form?.addCommentForm?.values,
  deactivateFlagFormValues: state?.form?.deactivateFlagForm?.values,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFlags: (params) => dispatch(getFlagsList({ ...params, partner: ownProps.partnerId })),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
  createFlag: (body) => dispatch(createFlag(body)),
  resetManageFlagForm: () => dispatch(reset("manageFlagForm")),
  updateFlag: (id, body) => dispatch(updateFlag(body, { id })),
  createComment: (body) => dispatch(createComment(body)),
  resetAddCommentForm: () => dispatch(reset("addCommentForm")),
  resetCloseFlagForm: () => dispatch(reset("deactivateFlagForm")),
});

export default connect(mapStateToProps, mapDispatchToProps)(Flag);

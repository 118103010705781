import React from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import { getOpenCfeisList } from "@local/actions";

const columns = [
  {
    id: "title",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfOpenCfeis.title",
    }),
  },
  {
    id: "displayID",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfOpenCfeis.displayID",
    }),
    type: "link",
    to: (row) => `/cfei/open/${row.id}`,
  },
  {
    id: "applications_count",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfOpenCfeis.applicationsCount",
    }),
  },
  {
    id: "deadline_date",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfOpenCfeis.deadlineDate",
    }),
    type: "date",
  },
];

const ListOfOpenCfeis = ({ getOpenCfeis }) => {
  return (
    <Table
      id="list_of_open_cfeis"
      title={formatMessage({
        id: "pages.dashboard.cmp.agency.listOfOpenCfeis.titleOne",
      })}
      columns={columns}
      fetchFunction={getOpenCfeis}
    />
  );
};

ListOfOpenCfeis.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  getOpenCfeis: (params) => dispatch(getOpenCfeisList(params)),
});

export default connect(undefined, mapDispatchToProps)(ListOfOpenCfeis);

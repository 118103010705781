import React from "react";
import { reduxForm, Field } from "redux-form";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldText from "@local/components/field-text";
import { ROLES } from "@local/legacy-utils/constants";
import ShowHide from "@local/components/show-hide";
import { getPartnerSelfAssessmentStatus, getPartnerSelfAssessmentStatusMessage, getAgencySelfAssessmentStatusMessage } from "../../../../../../utils/adapters";
import Alert from "@local/components/alert";
import { isAgencyInitialSectionComplete } from "@local/pages/partner-details/components/psea/utils";

const getAgencyInitialAssessmentAlertText = (role, pseaInitialAssessment, pseaSelfAssessment) => {
  if (role === ROLES.AGENCY) {
    if (getPartnerSelfAssessmentStatus(role, pseaSelfAssessment) === "in_progress") {
      return formatMessage({
        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.agencyInitialAssessmentNotDonePartnerStarted",
      });
    } else if (!isAgencyInitialSectionComplete(role, pseaInitialAssessment)) {
      return formatMessage({
        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.agencyInitialAssessmentNotDone",
      });
    }
  }
  return undefined;
};

const getPartnerOptionalAssessmentAlertText = (role, pseaInitialAssessment, pseaSelfAssessment, isPartnerLocked) => {
  if (
    role === ROLES.PARTNER &&
    !isPartnerLocked &&
    (pseaInitialAssessment?.agency_beneficiaries === false || ((pseaInitialAssessment?.agency_beneficiaries ?? undefined) === undefined && pseaInitialAssessment?.partner_beneficiaries === false)) &&
    pseaInitialAssessment?.proceed_with_self_assessment === false &&
    pseaSelfAssessment?.is_finalized_by_partner !== true
  ) {
    return formatMessage({
      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.proceedWithSelfAssessment",
    });
  }
  return undefined;
};

const getPartnerPreviousPseaAlertText = (role, pseaInitialAssessment, pseaSelfAssessment) => {
  if (
    role === ROLES.PARTNER &&
    (pseaInitialAssessment?.proceed_with_self_assessment ?? undefined) === undefined &&
    ((pseaInitialAssessment?.agency_is_assessed === true && pseaInitialAssessment?.agency_rating !== "not_valid") || ((pseaInitialAssessment?.agency_is_assessed ?? undefined) === undefined && pseaInitialAssessment?.partner_is_assessed === true)) &&
    (pseaSelfAssessment?.partner_finalized_on ?? undefined) === undefined &&
    (pseaSelfAssessment?.unlock_assessment_justification ?? undefined) === undefined
  ) {
    return formatMessage({
      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.partnerInitialAssessmentWarning",
    });
  }
  return undefined;
};

const Questions = ({ role, partnerErrorMessages, agencyErrorMessages, pseaSelfAssessment, isPartnerLocked, isAgencyLocked, pseaInitialAssessment }) => {
  const agencyInitialAssessmentAlertText = getAgencyInitialAssessmentAlertText(role, pseaInitialAssessment, pseaSelfAssessment);
  const partnerOptionalAssessmentAlertText = getPartnerOptionalAssessmentAlertText(role, pseaInitialAssessment, pseaSelfAssessment, isPartnerLocked);
  const partnerPreviousPseaAlertText = getPartnerPreviousPseaAlertText(role, pseaInitialAssessment, pseaSelfAssessment);

  return (
    <div className={style.questionsTableMainWrapper}>
      {agencyInitialAssessmentAlertText ? (
        <div className={style.warningMessageWrapper}>
          <Alert type="warning" content={agencyInitialAssessmentAlertText} />
        </div>
      ) : (
        <></>
      )}
      {partnerOptionalAssessmentAlertText ? (
        <div className={style.warningMessageWrapper}>
          <Alert type="warning" content={partnerOptionalAssessmentAlertText} />
        </div>
      ) : (
        <></>
      )}
      {partnerPreviousPseaAlertText ? (
        <div className={style.warningMessageWrapper}>
          <Alert type="warning" content={partnerPreviousPseaAlertText} />
        </div>
      ) : (
        <></>
      )}
      <div className={style.questionsTableHeaderWrapper}>
        <div className={style.questionsTableHeader}>
          <span>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableHeaderOneTitle",
            })}
          </span>
        </div>
        <div className={style.questionsTableHeader}>
          <span>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableHeaderTwoTitle",
            })}
          </span>
          <small>{getPartnerSelfAssessmentStatusMessage(role, pseaSelfAssessment)}</small>
        </div>
        <div className={style.questionsTableHeader}>
          <span>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableHeaderThreeTitle",
            })}
          </span>
          <small>{getAgencySelfAssessmentStatusMessage(pseaSelfAssessment)}</small>
        </div>
      </div>
      <div className={style.questionsTableRowsWrapper}>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowOneCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={
                    <>
                      <span>
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionstablerowonecellinfo1",
                        })}
                      </span>{" "}
                      <a
                        className={style.tooltipLink}
                        target="_blank"
                        alt={formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionstablerowonecellinfo2",
                        })}
                        href="https://undocs.org/ST/SGB/2003/13"
                        rel="noreferrer"
                      >
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionstablerowonecellinfo2",
                        })}
                      </a>
                      <span>{"."}</span>
                    </>
                  }
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS1")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS1")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS1"
                name="partner_CS1"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS1_comment"
                name="partner_CS1_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS1"
                name="agency_CS1"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS1_comment"
                name="agency_CS1_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowTwoCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowTwoCellInfo",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS2")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS2")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS2"
                name="partner_CS2"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                  {
                    value: "n/a",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNa",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS2_comment"
                name="partner_CS2_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS2"
                name="agency_CS2"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                  {
                    value: "n/a",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNa",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS2_comment"
                name="agency_CS2_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowThreeCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowThreeCellInfo",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS3")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS3")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS3"
                name="partner_CS3"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS3_comment"
                name="partner_CS3_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS3"
                name="agency_CS3"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS3_comment"
                name="agency_CS3_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowFourCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowFourCellInfo",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS4")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS4")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS4"
                name="partner_CS4"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS4_comment"
                name="partner_CS4_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS4"
                name="agency_CS4"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS4_comment"
                name="agency_CS4_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowFiveCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowFiveCellInfo",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS5")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS5")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS5"
                name="partner_CS5"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS5_comment"
                name="partner_CS5_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS5"
                name="agency_CS5"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS5_comment"
                name="agency_CS5_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowSixCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowSixCellInfo",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS6")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS6")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS6"
                name="partner_CS6"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS6_comment"
                name="partner_CS6_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS6"
                name="agency_CS6"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS6_comment"
                name="agency_CS6_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowSevenCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowSevenCellInfo",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS7")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS7")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS7"
                name="partner_CS7"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS7_comment"
                name="partner_CS7_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS7"
                name="agency_CS7"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS7_comment"
                name="agency_CS7_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
        <div className={style.questionsTableRow}>
          <div className={style.questionsTableCell}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowEightSevenCellTitle",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.questionsTableRowEightSevenCellInfo",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
              {role === ROLES.AGENCY ? (
                <ShowHide isVisible={pseaSelfAssessment?.is_finalized_by_partner}>
                  <span className={style.waringMessage}>
                    {agencyErrorMessages
                      ?.filter((item) => item?.coreStandard === "CS8")
                      ?.map((item) => item.message)
                      ?.join(", ")}
                  </span>
                </ShowHide>
              ) : (
                <span className={style.waringMessage}>
                  {partnerErrorMessages
                    ?.filter((item) => item?.coreStandard === "CS8")
                    ?.map((item) => item.message)
                    ?.join(", ")}
                </span>
              )}
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS8"
                name="partner_CS8"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                  {
                    value: "n/a",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNoAllegations",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={role === ROLES.AGENCY || isPartnerLocked}
                id="partner_CS8_comment"
                name="partner_CS8_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
          <div className={style.questionsTableCell}>
            <div className={style.questionFieldsWrapper}>
              <Field
                component={FieldRadioGroup}
                small
                readOnly={isAgencyLocked}
                id="agency_CS8"
                name="agency_CS8"
                options={[
                  {
                    value: "yes",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionYes",
                    }),
                  },
                  {
                    value: "no",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNo",
                    }),
                  },
                  {
                    value: "n/a",
                    label: formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerOptionNoAllegations",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                multiline
                small
                readOnly={isAgencyLocked}
                id="agency_CS8_comment"
                name="agency_CS8_comment"
                label={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.questions.idx.answerComment",
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Questions.propTypes = {};

export default reduxForm({
  form: "pseaSelfAssessmentForm",
  enableReinitialize: true,
})(Questions);

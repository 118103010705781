import React from "react";
import Card from "@local/components/card";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@local/components/button";

const Offices = ({ specialPartnerSubNationals, onClickGoToOffice, onClickAddNewSubnationalOffice, isAddSubnationalOfficeAllowed }) => {
  return (
    <>
      {specialPartnerSubNationals.hq ? (
        <Card
          title={formatMessage({
            id: "pages.partner.details.cmp.offices.idx.nationalOffice",
          })}
        >
          <div className={style.itemsMainWrapper} onClick={() => onClickGoToOffice(specialPartnerSubNationals.hq.id)}>
            <div className={style.itemWrapper}>
              <div>
                <span>{specialPartnerSubNationals.hq.legal_name}</span>
              </div>
              <div>
                <KeyboardArrowRight />
              </div>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          title={formatMessage({
            id: "pages.partner.details.cmp.offices.idx.title",
          })}
          action={
            isAddSubnationalOfficeAllowed ? (
              <Button
                text={formatMessage({
                  id: "pages.partner.details.cmp.offices.idx.newGovUnPartner",
                })}
                onClick={onClickAddNewSubnationalOffice}
              />
            ) : (
              <></>
            )
          }
        >
          <div className={style.itemsMainWrapper}>
            {specialPartnerSubNationals?.subnational_offices?.length === 0 ? (
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.offices.idx.emptyOffices",
                })}
              </span>
            ) : (
              <></>
            )}
            {specialPartnerSubNationals?.subnational_offices?.map((item) => (
              <div key={`subnational_office_item_${item.id}`} className={style.itemWrapper} onClick={() => onClickGoToOffice(item.id)}>
                <div>
                  <span>{item.legal_name}</span>
                </div>
                <div>
                  <KeyboardArrowRight />
                </div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </>
  );
};

Offices.propTypes = {};

export default Offices;

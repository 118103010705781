import React from "react";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getMainColor } from "@local/styles/muiTheme";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import Box from "@local/components/box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { WrapperComponent } from "../../utils";
import { isRequired } from "@local/utils/validators";
import FieldRadioGroup from "@local/components/field-radio-group";

// POST_REFACTORING_TODO: Errors and alert icon for each section

const Funding = ({ partner, onSubmitAndContinue, onSubmitAndExit, budgetTypes, donorTypes, isLoading, inEditMode, isBudgetRequired }) => {
  const onClickSaveAndContinue = () => {
    onSubmitAndContinue();
  };

  const onClickSaveAndExit = () => {
    onSubmitAndExit();
  };

  if (isLoading) return <CircularProgress size={50} />;

  return (
    <WrapperComponent
      inEditMode={inEditMode}
      title={formatMessage({
        id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.funding",
      })}
      footer={
        <div className={style.cardActionsWrapper}>
          <Button
            variant="text"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.saveAndExit",
            })}
            onClick={onClickSaveAndExit}
          />
          <Button
            variant="contained"
            text={formatMessage({
              id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.saveAndContinue",
            })}
            onClick={onClickSaveAndContinue}
          />
        </div>
      }
    >
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.budget",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Box
                title={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.annualBudget",
                })}
                informationText={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.annualBudgetOne",
                })}
                sections={partner?.budgets?.map((item) => (
                  <React.Fragment key={`budgets_wrapper_${item.id}`}>
                    <div className={style.itemWrapper}>
                      <div>
                        <Grid container spacing={2}>
                          <Grid item xs={2}>
                            <Field
                              component={FieldText}
                              validate={[isRequired]}
                              highlightType="basic"
                              readOnly
                              small={!inEditMode}
                              id={`budgets_year_${item.id}`}
                              name={`budgets_year_${item.id}`}
                              label={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.year",
                              })}
                              placeholder={formatMessage({
                                id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.year",
                              })}
                            />
                          </Grid>
                          <Grid item xs={10}>
                            {isBudgetRequired ? (
                              <Field
                                component={FieldSelect}
                                validate={[isRequired]}
                                highlightType="basic"
                                readOnly={!inEditMode}
                                small={!inEditMode}
                                id={`budgets_budget_${item.id}`}
                                name={`budgets_budget_${item.id}`}
                                label={formatMessage({
                                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.budgetOne",
                                })}
                                placeholder={formatMessage({
                                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.budgetOne",
                                })}
                                valueField="value"
                                labelField="label"
                                options={budgetTypes}
                              />
                            ) : (
                              <Field
                                component={FieldSelect}
                                highlightType="basic"
                                readOnly={!inEditMode}
                                small={!inEditMode}
                                id={`budgets_budget_${item.id}`}
                                name={`budgets_budget_${item.id}`}
                                label={formatMessage({
                                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.budgetOne",
                                })}
                                placeholder={formatMessage({
                                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.budgetOne",
                                })}
                                valueField="value"
                                labelField="label"
                                options={budgetTypes}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              />
            </FieldGroup>
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div className={style.sectionTitleWrapper}>
            <CheckCircleIcon style={{ fontSize: "1.5rem", color: getMainColor() }} />
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.majorDonors",
              })}
            </span>
          </div>
          <div className={style.sectionContentWrapper}>
            <FieldGroup>
              <Field
                component={FieldSelect}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="major_donors"
                name="major_donors"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.typeOfDonor",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.typeOfDonor",
                })}
                valueField="value"
                labelField="label"
                options={donorTypes}
                multiple
              />
              <Field
                component={FieldRadioGroup}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="have_core_funding"
                name="have_core_funding"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.coreFundingQuestion",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.coreFundingQuestion",
                })}
                informationText={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.coreFunding",
                })}
                options={[
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.yes",
                    }),
                  },
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.no",
                    }),
                  },
                ]}
              />
              <Field
                component={FieldText}
                highlightType="advanced"
                readOnly={!inEditMode}
                small={!inEditMode}
                id="main_donors_list"
                name="main_donors_list"
                label={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.donorsList",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.profile.details.cmp.funding.idx.donorsList",
                })}
                type="text"
                maxLength={5000}
                withCharactersCount
                multiline
              />
            </FieldGroup>
          </div>
        </div>
      </div>
    </WrapperComponent>
  );
};

Funding.propTypes = {};

export default reduxForm({
  form: "editPartnerProfileFundingForm",
  enableReinitialize: true,
})(Funding);

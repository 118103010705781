import React from "react";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { formatDate } from "@local/utils";
import { getVerificationContent } from "@local/utils/adapters";

const VerificationIcon = ({ profileStatus, date, submitter, isWithTexts }) => {
  return (
    <div className={style.mainWrapper}>
      {getVerificationContent(profileStatus)?.icon}
      {isWithTexts ? (
        <div className={style.textsWrapper}>
          <span>
            {getVerificationContent(profileStatus)?.stepText}
            {date ? <small>{formatDate(date) || ""}</small> : undefined}
          </span>
          {submitter?.name ? (
            <small className={style.submitter}>{`${formatMessage({ id: "cmp.verification.icon.idx.by" })} ${submitter.name} ${submitter.agency_name}`}</small>
          ) : (
            <small className={style.submitter}>{formatMessage({ id: "cmp.verification.icon.idx.neverVerified" })}</small>
          )}
        </div>
      ) : undefined}
    </div>
  );
};

VerificationIcon.propTypes = {};

export default VerificationIcon;

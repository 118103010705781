/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import MainContent from "@local/components/main-content-wrapper";
import Button from "@local/components/button";
import Tooltip from "@mui/material/Tooltip";

const getHederButton = (id, pathname, onClickHeaderButton, hasUcnPermission, isHq, isProfileComplete) => {
  if (!id && pathname.match("^/applications/unsolicited")) {
    if (hasUcnPermission) {
      return !isHq && isProfileComplete ? (
        <Button
          text={formatMessage({
            id: "pages.applications.list.cmp.container.idx.unsolicited",
          })}
          onClick={onClickHeaderButton}
        />
      ) : (
        <Tooltip
          title={formatMessage({
            id: "pages.applications.list.cmp.container.idx.tooltipInfo",
          })}
        >
          <span>
            <Button
              text={formatMessage({
                id: "pages.applications.list.cmp.container.idx.unsolicited",
              })}
              onClick={onClickHeaderButton}
              isDisabled
            />
          </span>
        </Tooltip>
      );
    }
  }
  return undefined;
};

const Container = ({ isRestrictedPath, tabs, children, type, id, onClickHeaderButton, hasUcnPermission, isHq, isProfileComplete }) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (isRestrictedPath) history.push("/");
  }, []);

  const handleChange = (event, index) => {
    history.push({ pathname: `/applications/${tabs[index].path}` });
  };

  const index = tabs.findIndex((tab) => tab.path === type);
  if (index === -1) history.push("/");

  return (
    <HeaderNavigation
      index={index}
      title={formatMessage({
        id: "pages.applications.list.cmp.container.idx.applications",
      })}
      tabs={tabs}
      header={getHederButton(id, location.pathname, onClickHeaderButton, hasUcnPermission, isHq, isProfileComplete)}
      handleChange={handleChange}
    >
      <MainContent>{index !== -1 ? children : undefined}</MainContent>
    </HeaderNavigation>
  );
};

Container.propTypes = {};

export default Container;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, Rectangle } from "react-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import style from "./style.module.scss";
import { getCountryBoundingBox, getRandomColorByIndex } from "@local/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import FieldAutosuggest from "../field-autosuggest/";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { toast } from "react-toastify";

const getListBoundingBox = (boundingBoxes) => {
  const coordinates = [];
  boundingBoxes.forEach((item) => {
    if (item.boundingBox) {
      coordinates.push(item.boundingBox[0]);
      coordinates.push(item.boundingBox[1]);
    }
  });
  if (coordinates.length > 0) {
    const latitudes = coordinates.map((item) => item[0]);
    const longitudes = coordinates.map((item) => item[1]);
    return [
      [Math.max.apply(null, latitudes), Math.max.apply(null, longitudes)],
      [Math.min.apply(null, latitudes), Math.min.apply(null, longitudes)],
    ];
  }
  return undefined;
};

const BoundingBoxHandler = ({ boundingBoxes }) => {
  const map = useMap();
  useEffect(() => {
    if (map && boundingBoxes) {
      const listBoundingBox = getListBoundingBox(boundingBoxes);
      if (listBoundingBox) map.fitBounds(getListBoundingBox(boundingBoxes), { padding: [20, 20] });
    }
  }, [map, boundingBoxes]);
  return null;
};

const ClickHandler = ({ onClick, readOnly }) => {
  useMapEvents({
    click(event) {
      if (!readOnly)
        onClick({
          location: { latitude: event.latlng.lat, longitude: event.latlng.lng },
        });
    },
  });
  return null;
};

const Map = ({ height = "200px", markers = [], onClickMap, onClickDeleteMarker, readOnly, countryCodes = [], isError = false }) => {
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [search, setSearch] = useState("");
  const provider = useRef(undefined);

  useEffect(() => {
    provider.current = new OpenStreetMapProvider();
  }, []);

  useEffect(() => {
    if (!boundingBoxes.find((item) => countryCodes.includes(item))) {
      const updatedBoundingBoxes = countryCodes.map((country) => ({
        country,
        boundingBox: getCountryBoundingBox(country),
      }));
      setBoundingBoxes(updatedBoundingBoxes);
    }
  }, [countryCodes]);

  const onSearch = (item) => {
    if (item) {
      setSearch(item);
      onClickMap(item.data);
    }
  };

  const fetchLocations = async (query) => {
    try {
      const results = await provider.current.search({ query });
      return {
        data: {
          results: results.map((item, index) => ({
            value: `map_search_result_${index}`,
            label: item.label,
            data: {
              location: {
                latitude: parseFloat(item.y),
                longitude: parseFloat(item.x),
              },
            },
          })),
        },
      };
    } catch (error) {
      toast.error(formatMessage({ id: "cmp.map.idx.error" }));
    }
  };

  return (
    <div className={style.mainWrapper}>
      {!readOnly ? (
        <div className={style.searchWrapper}>
          <FieldAutosuggest
            id="map_search"
            label={formatMessage({ id: "cmp.map.idx.search" })}
            fetchFunction={fetchLocations}
            valueField="value"
            labelField="label"
            small
            input={{
              value: search,
              onChange: onSearch,
            }}
          />
        </div>
      ) : undefined}
      <div className={style.mapWrapper} style={{ borderColor: isError ? "#d32f2f" : "#cccccc" }}>
        <MapContainer style={{ height }} center={[1, 1]} zoom={5} scrollWheelZoom={false}>
          <ClickHandler onClick={onClickMap} readOnly={readOnly} />
          <BoundingBoxHandler boundingBoxes={boundingBoxes} />
          {boundingBoxes.map((item, index) => {
            if (item.boundingBox) {
              return (
                <Rectangle
                  key={`map_bounding_box_${item.country}_${index}`}
                  bounds={item.boundingBox}
                  pathOptions={{
                    color: getRandomColorByIndex(index, 90),
                    weight: 2,
                    fill: true,
                    lineCap: "round",
                    lineJoin: "round",
                  }}
                />
              );
            }
            return <React.Fragment key={`map_bounding_not_found_${index}`} />;
          })}
          <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {markers.map((item, index) => (
            <Marker key={`map_marker_${item.id}_${index}`} position={{ lat: item.latitude, lon: item.longitude }}>
              <Popup>
                <div className={style.popupWrapper}>
                  <div>
                    <span className={style.deleteLabel}>{item?.admin_level_1?.name}</span>
                  </div>
                  <div>
                    {!readOnly ? (
                      <DeleteIcon
                        style={{
                          cursor: "pointer",
                          color: "#cc0000",
                          fontSize: "1.25rem",
                        }}
                        onClick={() => onClickDeleteMarker(item)}
                      />
                    ) : undefined}
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
      {isError && <div className={style.errorText}>{formatMessage({ id: "cmp.map.idx.required" })}</div>}
    </div>
  );
};

export default Map;

import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldText from "@local/components/field-text";
import FieldRadioGroup from "@local/components/field-radio-group";
import Button from "@local/components/button";
import { Grid } from "@mui/material";
import { isRequired } from "@local/utils/validators";
import Alert from "@local/components/alert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatDate } from "@local/utils";
import style from "./style.module.scss";

const VerifyMatchModal = ({ isOpen, onClose, pristine, invalid, onSubmit, formValues, sanctionMatchDetails, readOnly }) => {
  const getUnppItems = (sanctionMatchDetails) => {
    switch (sanctionMatchDetails?.match_type) {
      case "Usr":
        return (
          <ul className={style.listWrapper}>
            <li key={"unpp_data_full_name"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.fullName",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.fullname}</span>
            </li>
            <li key={"unpp_data_email"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.email",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.email}</span>
            </li>
            <li key={"unpp_data_title"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.title",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.title}</span>
            </li>
            <li key={"unpp_data_role"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.role",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.role}</span>
            </li>
          </ul>
        );
      case "Brd":
        return (
          <ul className={style.listWrapper}>
            <li key={"unpp_data_full_name"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.fullName",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.fullname}</span>
            </li>
            <li key={"unpp_data_job_title"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.jobTitle",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.job_title}</span>
            </li>
            <li key={"unpp_data_telephone"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.telephone",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.telephone}</span>
            </li>
            <li key={"unpp_data_mobile"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.mobile",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.mobile}</span>
            </li>
            <li key={"unpp_data_email"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.email",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.email}</span>
            </li>
          </ul>
        );
      case "Org":
        return (
          <ul className={style.listWrapper}>
            <li key={"unpp_data_legal_name"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.legalName",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.legal_name}</span>
            </li>
            <li key={"unpp_data_country"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.country",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.country}</span>
            </li>
            <li key={"unpp_data_address"} className={style.listItemWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.address",
                })}
              </span>
              <span>{sanctionMatchDetails?.extra?.unpp_data?.address}</span>
            </li>
          </ul>
        );
      default:
        return undefined;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.verifyMatch",
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Alert
            type="warning"
            content={
              sanctionMatchDetails?.match_text !== ""
                ? sanctionMatchDetails?.match_text
                : `${formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.header",
                  })} ${sanctionMatchDetails?.name?.name}`
            }
            withBottomMargin
          />
        </Grid>
        <Grid item xs={6}>
          <div className={style.sectionWrapper}>
            <span className={style.sectionTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.sanctionList",
              })}
            </span>
            <ul className={style.listWrapper}>
              <li className={style.listItemWrapper}>
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.entityName",
                  })}
                </span>
                <span>{sanctionMatchDetails?.extra?.sanctions_list?.entity_name}</span>
              </li>
              <li className={style.listItemWrapper}>
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.listedOn",
                  })}
                </span>
                <span>{sanctionMatchDetails?.extra?.sanctions_list?.listed_on ? formatDate(sanctionMatchDetails?.extra?.sanctions_list?.listed_on) : ""}</span>
              </li>
              <li className={style.listItemWrapper}>
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.lastUpdated",
                  })}
                </span>
                <span>{sanctionMatchDetails?.extra?.sanctions_list?.last_updated ? formatDate(sanctionMatchDetails?.extra?.sanctions_list?.last_updated) : ""}</span>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={style.sectionWrapper}>
            <span className={style.sectionTitle}>
              {formatMessage({
                id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.partnerPortal",
              })}
            </span>
            {getUnppItems(sanctionMatchDetails)}
          </div>
        </Grid>
        {!readOnly ? (
          <>
            <Grid item xs={12}>
              <div className={style.guidanceLinkWrapper}>
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.guidanceHelpText",
                  })}
                </span>
                <a
                  target="_blank"
                  alt={formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.guidanceLink",
                  })}
                  href="https://www.un.org/securitycouncil/content/un-sc-consolidated-list"
                  rel="noreferrer"
                >
                  {formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.guidanceLink",
                  })}
                </a>
                <OpenInNewIcon style={{ fontSize: ".85rem", color: "#777777" }} />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={FieldRadioGroup}
                validate={[isRequired]}
                id="validation"
                name="validation"
                label={formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.flagUpdate",
                })}
                placeholder={formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.flagUpdate",
                })}
                fullWidthLabel
                options={[
                  {
                    value: false,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.notTrueMatch",
                    }),
                  },
                  {
                    value: true,
                    label: formatMessage({
                      id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.confirmedMatch",
                    }),
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              {formValues?.validation === "false" ? (
                <Field
                  component={FieldText}
                  id="can_ignore_text"
                  name="can_ignore_text"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.reason",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.enterDetails",
                  })}
                />
              ) : (
                <Field
                  component={FieldText}
                  id="validation_text"
                  name="validation_text"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.reason",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.enterDetails",
                  })}
                />
              )}
            </Grid>
          </>
        ) : undefined}
      </Grid>
      <div className={style.submitButtonWrapper}>
        {!readOnly ? (
          <Button
            variant="contained"
            onClick={onSubmit}
            isDisabled={pristine || invalid}
            text={formatMessage({
              id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.button",
            })}
          />
        ) : (
          <Button
            variant="contained"
            onClick={onClose}
            text={formatMessage({
              id: "pages.partner.details.cmp.overview.cmp.verify.match.modal.idx.okButton",
            })}
          />
        )}
      </div>
    </Modal>
  );
};

export default reduxForm({ form: "verifyMatchForm", enableReinitialize: true })(VerifyMatchModal);

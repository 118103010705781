import React from "react";
import { useHistory } from "react-router-dom";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";

const BackButton = ({ path }) => {
  const history = useHistory();

  const onClick = () => {
    if (path === -1) {
      history.goBack();
    } else {
      history.push(path);
    }
  };

  return (
    <IconButton onClick={onClick}>
      <KeyboardArrowLeft />
    </IconButton>
  );
};

BackButton.propTypes = {};

export default BackButton;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import { getProgressReportsList, getAgenciesList } from "@local/actions";
import { getProgressReportColumns } from "../../utils/columns";
import Breadcrumb from "@local/components/breadcrumb";

const ProgressReport = ({ getProgressReports, getAgencies, partnerId }) => {
  useEffect(() => {
    getAgencies();
  }, []);

  return (
    <>
      <Breadcrumb
        items={[
          {
            id: 1,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.progress.reports.idx.dashboardTitle",
            }),
            url: `/partner/${partnerId}/risk/dashboard`,
          },
          {
            id: 2,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.progress.reports.idx.dashboardTitleOne",
            }),
            url: `/partner/${partnerId}/risk/progress-report`,
          },
        ]}
      />
      <Table
        key="risk_progress_reports_list"
        id="risk_progress_reports_list"
        title={formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.progress.reports.idx.dashboardTitleOne",
        })}
        columns={getProgressReportColumns()}
        fetchFunction={getProgressReports}
      />
    </>
  );
};

ProgressReport.propTypes = {};

const mapStateToProps = (state) => ({
  agencies: state?.agenciesList?.data?.data?.results ?? [],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getProgressReports: (params) => dispatch(getProgressReportsList({ ...params, partner: ownProps.partnerId })),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressReport);

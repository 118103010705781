import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import style from "./style.module.scss";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { getColorByHighlightType } from "@local/utils";
import Button from "../button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Chip from "@mui/material/Chip";

const FieldListInput = ({
  id,
  label,
  placeholder,
  helperText,
  type,
  maxLength,
  multiline,
  maxRows,
  rows,
  defaultValue,
  readOnly,
  input: { value, onChange },
  meta,
  informationText,
  highlightType,
  emptyPlaceholder = formatMessage({
    id: "cmp.field.list.input.idx.fieldListInputNoData",
  }),
}) => {
  const [list, setList] = useState(value ?? []);
  const [newItemValue, setNewItemValue] = useState("");

  const onChangeNewItemValue = (event) => setNewItemValue(event.target.value);

  const onClickAddItem = () => {
    const updatedList = [...list, newItemValue];
    setList(updatedList);
    onChange(updatedList);
    setNewItemValue("");
  };

  const onClickRemoveItem = (toRemoveIndex) => {
    const updatedList = list.filter((item, index) => index !== toRemoveIndex);
    setList(updatedList);
    onChange(updatedList);
  };

  const color = getColorByHighlightType(highlightType);

  return (
    <div>
      <div
        className={style.mainWrapper}
        style={{
          backgroundColor: `${color}29`,
          borderColor: (meta?.error ?? "") !== "" ? "#d32f2f" : "#b7b7b7",
        }}
      >
        {readOnly ? <span className={style.emptyLabel}>{label}</span> : <></>}
        {list.length ? (
          <>
            {list.map((item, index) => (
              <div className={style.itemWrapper} key={`field_list_input_${item}_${index}`}>
                <Chip className={style?.attachmentName} onClick={() => undefined} label={item} onDelete={readOnly ? undefined : () => onClickRemoveItem(index)} />
              </div>
            ))}
          </>
        ) : (
          <span className={style.noData}>{emptyPlaceholder}</span>
        )}
        {readOnly ? (
          <></>
        ) : (
          <div className={style.inputWrapper}>
            <FormControl fullWidth variant="outlined">
              <TextField
                error={(meta?.error ?? "") !== ""}
                id={id}
                value={newItemValue ?? ""}
                onChange={onChangeNewItemValue}
                label={label}
                placeholder={placeholder}
                size="small"
                multiline={multiline}
                maxRows={maxRows}
                rows={rows}
                defaultValue={defaultValue}
                disabled={readOnly}
                inputProps={{
                  type,
                  maxLength,
                }}
                InputProps={{
                  startAdornment: informationText ? (
                    <div className={style.tooltipWrapper}>
                      <Tooltip title={informationText}>
                        <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                      </Tooltip>
                    </div>
                  ) : undefined,
                }}
              />
            </FormControl>
            <div className={style.buttonWrapper}>
              <Button
                isDisabled={newItemValue.trim() === ""}
                onClick={onClickAddItem}
                text={formatMessage({
                  id: "cmp.field.list.input.idx.fieldListInputAddLabel",
                })}
              />
            </div>
          </div>
        )}
      </div>
      <div className={style.footerWrapper}>
        <div>
          {helperText && !meta?.error ? <FormHelperText id={`field-text-helper-text-${id}`}>{helperText}</FormHelperText> : <></>}
          {meta?.error ? (
            <FormHelperText error id={`field-text-helper-text-${id}`}>
              {meta?.error}
            </FormHelperText>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

FieldListInput.defaultProps = {};

export default FieldListInput;

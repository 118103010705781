import React from "react";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";

const InformedPartners = ({ project }) => (
  <Card
    title={formatMessage({
      id: "pages.project.details.cmp.overview.cmp.informed.partners.idx.title",
    })}
  >
    <div>
      {project?.invited_partners.length > 0 ? (
        project?.invited_partners.map((item) => (
          <div key={item?.id}>
            <span className={style.text}>{item?.legal_name}</span>
          </div>
        ))
      ) : (
        <span>
          {formatMessage({
            id: "pages.project.details.cmp.overview.cmp.informed.partners.idx.noContent",
          })}
        </span>
      )}
    </div>
  </Card>
);

InformedPartners.propTypes = {};

export default InformedPartners;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory, useLocation } from "react-router";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import MainContent from "@local/components/main-content-wrapper";

const Container = ({ children, tabs, type }) => {
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event, index) => {
    history.push({
      pathname: `/idp/${tabs[index].path}`,
      query: location.query,
    });
  };

  const index = tabs.findIndex((tab) => tab.path === type);
  if (index === -1) history.push("/");

  const filteredTabs = tabs.filter((tab) => tab.isVisible);

  return (
    <HeaderNavigation
      index={index}
      title={formatMessage({
        id: "pages.users.management.cmp.container.idx.header",
      })}
      tabs={filteredTabs}
      handleChange={handleChange}
    >
      <MainContent>{children}</MainContent>
    </HeaderNavigation>
  );
};

Container.propTypes = {};

export default Container;

import * as R from "ramda";
import { combineReducers } from "redux";
import { getNewRequestToken } from "../legacy-utils/apiHelper";
import apiMeta, { success, loadStarted, loadEnded, loadSuccess, loadFailure } from "./apiMeta";
import { getAgencyMembers, getAgencyOffice, getAgencyAdmin } from "../legacy-utils/api";
import { extractIds, flattenToId, toObject, flattenToObjectKey } from "./normalizationHelpers";
import { AGENCY_MEMBERS_POSITIONS, DELIMITER } from "../legacy-utils/constants";

const { ADMINISTRATOR, EDITOR } = AGENCY_MEMBERS_POSITIONS;
const tag = "agencyMembers";
const AGENCY_MEMBERS = "AGENCY_MEMBERS";
const AGENCY_OFFICES = "AGENCY_OFFICES";

const groupByMembers = (results) => R.groupBy((member) => member.role, results);
const groupIdsByRole = R.compose(R.map(extractIds), groupByMembers);

const initialState = {};

const getMembers = (pick, sortedMembers, allMembers) => R.pick(R.flatten(R.props(pick, sortedMembers)), allMembers);
const getPossibleFocalPointsReviewers = R.curry(getMembers)([ADMINISTRATOR, EDITOR]);

export const loadAgencyMembers = (agencyId, params) => (dispatch) =>
  getAgencyMembers(agencyId, params).then((response) => {
    dispatch(loadSuccess(AGENCY_MEMBERS, { members: response.results }));
    return response.results;
  });

const saveMembers = (members) => ({
  sortedMembers: groupIdsByRole(members),
  allMembers: toObject(flattenToId, members),
  allMembersShort: toObject(flattenToObjectKey("name"), members),
});

export const selectPossibleFocalPointsReviewers = (state) => {
  const { sortedMembers = {}, allMembersShort = {} } = state;
  return getPossibleFocalPointsReviewers(sortedMembers, allMembersShort);
};

export const loadAgencyFocalPointsForAutoComplete = (params) => (dispatch, getState) => {
  const newCancelToken = getNewRequestToken(getState, tag);
  const officeId = getState().session.officeId;
  dispatch(loadStarted(AGENCY_MEMBERS, newCancelToken));
  return getAgencyMembers(officeId, { focal: true, ...params }, { cancelToken: newCancelToken.token })
    .then((response) => {
      dispatch(loadEnded(AGENCY_MEMBERS));
      const responseItems = response.results.map((item) => {
        item["name"] = `${item.name}  (${item.email})`;
        return item;
      });
      return toObject(flattenToObjectKey("name"), responseItems);
    })
    .catch((error) => {
      dispatch(loadEnded(AGENCY_MEMBERS));
      dispatch(loadFailure(AGENCY_MEMBERS, error));
    });
};

export const loadAgencyOfficeForAutoComplete = (params) => {
  const id = params.extra;
  const country = params.value;
  return (dispatch, getState) => {
    const newCancelToken = getNewRequestToken(getState, tag);
    dispatch(loadStarted(AGENCY_OFFICES, newCancelToken));
    return getAgencyOffice(id, { country }, { cancelToken: newCancelToken.token })
      .then((response) => {
        dispatch(loadEnded(AGENCY_OFFICES));

        R.map((response) => (response.country = getState().countries[response.country]), response.results);
        return toObject(flattenToObjectKey("country"), response.results);
      })
      .catch((error) => {
        dispatch(loadEnded(AGENCY_OFFICES));
        dispatch(loadFailure(AGENCY_OFFICES, error));
      });
  };
};

export const loadAgencyAdminForAutoComplete = (params) => {
  const id = params.extra;
  return (dispatch, getState) => {
    const newCancelToken = getNewRequestToken(getState, tag);
    dispatch(loadStarted(AGENCY_OFFICES, newCancelToken));
    return getAgencyAdmin(id, { params }, { cancelToken: newCancelToken.token })
      .then((response) => {
        dispatch(loadEnded(AGENCY_OFFICES));
        R.map((response) => (response.id = response.email + DELIMITER + response.fullname), response.results);
        return toObject(flattenToObjectKey("fullname"), response.results);
      })
      .catch((error) => {
        dispatch(loadEnded(AGENCY_OFFICES));
        dispatch(loadFailure(AGENCY_OFFICES, error));
      });
  };
};

export const loadAgencyReviewersForAutoComplete = (params) => (dispatch, getState) => {
  const newCancelToken = getNewRequestToken(getState, tag);
  const officeId = getState().session.officeId;
  dispatch(loadStarted(AGENCY_MEMBERS, newCancelToken));
  return getAgencyMembers(officeId, { reviewer: true, ...params }, { cancelToken: newCancelToken.token })
    .then((response) => {
      dispatch(loadEnded(AGENCY_MEMBERS));
      return toObject(flattenToObjectKey("name"), response.results);
    })
    .catch((error) => {
      dispatch(loadEnded(AGENCY_MEMBERS));
      dispatch(loadFailure(AGENCY_MEMBERS, error));
    });
};

function agencyMembersReducer(state = initialState, action) {
  switch (action.type) {
    case success`${AGENCY_MEMBERS}`: {
      return saveMembers(action.members);
    }
    default:
      return state;
  }
}

export default combineReducers({
  data: agencyMembersReducer,
  status: apiMeta(AGENCY_MEMBERS),
});

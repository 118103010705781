/* eslint-disable no-unused-vars */

import React from "react";
import { withRouter, useParams } from "react-router";
import Dashboard from "./components/dashboard";
import Assessment from "./components/assessment";
import Audit from "./components/audit";
import ProgressReport from "./components/progress-reports"; // UNCRA-160: Hide functionality of Progress Report on Improvement Plan Details
import Flag from "./components/flag";
import FinancialDetails from "./components/financial-details";
import PseaDetails from "./components/psea-details";
import Observations from "./components/observations";

const Risk = () => {
  const { type, id } = useParams();

  let content = undefined;

  switch (type) {
    case "dashboard":
      content = <Dashboard partnerId={id} />;
      break;
    case "assessment":
      content = <Assessment partnerId={id} />;
      break;
    case "audit":
      content = <Audit partnerId={id} />;
      break;
    case "flag":
      content = <Flag partnerId={id} />;
      break;
    case "financial":
      content = <FinancialDetails partnerId={id} />;
      break;
    case "psea":
      content = <PseaDetails partnerId={id} />;
      break;
    case "observations":
      content = <Observations partnerId={id} />;
      break;
    default:
      content = undefined;
      break;
  }

  return <>{content}</>;
};

Risk.propTypes = {};

export default withRouter(Risk);

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import Button from "@local/components/button";
import { ROLES } from "@local/legacy-utils/constants";

const UsersList = ({ onClickAdd, onClickDownload, fetchFunction, columns, shouldRefresh, afterRefresh, filters, onChangeTableFilters, officeId, userRole }) => {
  return (
    <Table
      id="users_list"
      title={formatMessage({
        id: "pages.users.management.cmp.users.list.idx.users",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      shouldRefresh={shouldRefresh}
      afterRefresh={afterRefresh}
      filters={filters}
      initialFilters={{ office: officeId }}
      onChangeFilters={onChangeTableFilters}
      withUrlSynchronization
      filtersNumberOfColumns={4}
      actions={
        <>
          <Button
            text={formatMessage({
              id: "pages.users.management.cmp.users.list.idx.user",
            })}
            onClick={onClickAdd}
          />
          {userRole === ROLES.AGENCY ? (
            <Button
              text={formatMessage({
                id: "pages.users.management.cmp.users.list.idx.download",
              })}
              onClick={onClickDownload}
            />
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};

UsersList.propTypes = {};

export default UsersList;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Card from "@local/components/card";
import Grid from "@mui/material/Grid";
import VerificationSteps from "@local/components/verification-steps";
import FlagsList from "@local/components/flags-list";
import ProfileSummary from "./components/profile-summary";
import PseaObservations from "@local/components/psea-observations";
import { useParams } from "react-router";
import VerifyMatchModal from "./components/verify-match-modal";
import { toast } from "react-toastify";

const Overview = ({ partnerSummary, partner, verifications, hasPermissionViewFlagCount, hasViewPseaObservationsPermission, countries, updateSanctionMatch, verifyMatchFormValues, getPartnerProfileData, sanctionMatchDetails }) => {
  useEffect(() => {
    getPartnerProfileData();
  }, []);

  const { id } = useParams();
  const [isVerifyMatchVisible, setIsVerifyMatchVisible] = useState(false);

  const verification = verifications[0];

  const onClickOpenVerifyMatchModal = () => setIsVerifyMatchVisible(true);

  const onClickCloseVerifyMatchModal = () => setIsVerifyMatchVisible(false);

  const onSubmitVerifyMatch = async () => {
    try {
      await updateSanctionMatch({
        validation: verifyMatchFormValues?.validation,
        can_ignore_text: verifyMatchFormValues?.validation === "false" ? verifyMatchFormValues?.can_ignore_text : undefined,
        validation_text: verifyMatchFormValues?.validation === "true" ? verifyMatchFormValues?.validation_text : undefined,
        can_ignore: verifyMatchFormValues?.validation === "false" ? true : false,
      });
      setIsVerifyMatchVisible(false);
      getPartnerProfileData();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.overview.idx.unableToSaveData",
        }),
      );
    }
  };

  const sanctions_status = partner?.partner_additional?.sanctions_status ?? undefined;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <ProfileSummary partner={partnerSummary} countries={countries} />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                title={formatMessage({
                  id: "pages.partner.details.cmp.overview.idx.overviewProfileStatus",
                })}
              >
                <VerificationSteps hq={partner?.partner_additional?.hq} verification={verification} partnerId={id} profileStatus={partner?.partner_additional?.profile_status} />
              </Card>
            </Grid>
            {hasPermissionViewFlagCount ? (
              <Grid item xs={12}>
                <Card
                  title={formatMessage({
                    id: "pages.partner.details.cmp.overview.idx.riskAndProfileOverview",
                  })}
                >
                  <FlagsList partner={partner} hasPermissionViewFlagCount={hasPermissionViewFlagCount} partnerId={id} onClickOpenVerifyMatchModal={onClickOpenVerifyMatchModal} />
                </Card>
              </Grid>
            ) : undefined}
            {hasViewPseaObservationsPermission ? (
              <Grid item xs={12}>
                <Card
                  title={formatMessage({
                    id: "pages.partner.details.cmp.overview.idx.flagStatus",
                  })}
                >
                  <PseaObservations partnerId={id} />
                </Card>
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
      <VerifyMatchModal
        isOpen={isVerifyMatchVisible}
        onClose={onClickCloseVerifyMatchModal}
        initialValues={undefined}
        onSubmit={onSubmitVerifyMatch}
        formValues={verifyMatchFormValues}
        sanctionMatchDetails={sanctionMatchDetails}
        readOnly={sanctions_status === "confirmed_match"}
      />
    </>
  );
};

Overview.propTypes = {};

export default Overview;

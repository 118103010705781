import React from "react";
import { reduxForm, Field } from "redux-form";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Button from "@local/components/button";
import FieldDateRangePicker from "@local/components/field-date-range-picker";
import { UN_VENDOR_NUMBER_DOCUMENT_TYPE_CHOICES } from "@local/legacy-utils/constants";
import FieldSelect from "@local/components/field-select";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldFile from "@local/components/field-file";
import { isRequired } from "@local/utils/validators";
import style from "../style.module.scss";

const ManageUnDocumentForm = ({ pristine, invalid, onClickCloseManageUnDocument, manageUnDocument }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            component={FieldDateRangePicker}
            id="date"
            name="date"
            label={formatMessage({
              id: "pages.partner.details.cmp.un.data.cmp.ManageUnDocumentForm.date",
            })}
            fullWidth
            validate={[isRequired]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={FieldSelect}
            id="document_type"
            name="document_type"
            label={formatMessage({
              id: "pages.partner.details.cmp.un.data.cmp.ManageUnDocumentForm.documentTypeLabel",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.un.data.cmp.ManageUnDocumentForm.documentTypePlaceHolder",
            })}
            valueField="value"
            labelField="label"
            options={UN_VENDOR_NUMBER_DOCUMENT_TYPE_CHOICES}
            validate={[isRequired]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={FieldFile}
            id="file_field"
            name="file_field"
            label={formatMessage({
              id: "pages.partner.details.cmp.un.data.cmp.ManageUnDocumentForm.fileField",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.un.data.cmp.ManageUnDocumentForm.fileField",
            })}
            validate={[isRequired]}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={style.modalActionsWrapper}>
            <div>
              <Button
                onClick={onClickCloseManageUnDocument}
                variant="text"
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.cmp.ManageUnDocumentForm.cancel",
                })}
              />
            </div>
            <div>
              <Button
                onClick={manageUnDocument}
                isDisabled={pristine || invalid}
                text={formatMessage({
                  id: "pages.partner.details.cmp.un.data.cmp.ManageUnDocumentForm.submit",
                })}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </FormControl>
  );
};

ManageUnDocumentForm.propTypes = {};

export default reduxForm({
  form: "manageUnDocumentForm",
  enableReinitialize: true,
})(ManageUnDocumentForm);

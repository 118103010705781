/* eslint-disable max-len */

import { formatDateToBeSent } from "@local/utils/index";
import { getPartnerDeclarationQuestions } from "@local/utils/adapters";

export const adaptPartner = (formValues) => {
  const result = {
    declaration: getPartnerDeclarationQuestions(formValues)?.map((question) => ({ answer: "Yes", question: question?.text })),
    governing_document:
      formValues?.have_governing_document?.toString() === "true"
        ? {
            document: {
              content: formValues?.governing_document?.content,
              filename: formValues?.governing_document?.filename,
            },
          }
        : undefined,
    registration_document:
      formValues?.registered_to_operate_in_country?.toString() === "true"
        ? {
            issue_date: formatDateToBeSent(formValues?.issue_date),
            issuing_authority: formValues?.issuing_authority,
            registration_number: formValues?.registration_number,
            document: {
              content: formValues?.registration_document?.content,
              filename: formValues?.registration_document?.filename,
            },
          }
        : undefined,
    partner: {
      country_code: formValues?.country_code,
      display_type: formValues?.display_type,
      legal_name: formValues?.legal_name,
    },
    director: [
      {
        email: formValues?.head_organization_email,
        fullname: formValues?.head_organization_fullname,
        job_title: ["head_of_organization"],
      },
    ],
    partner_profile: {
      other_names: formValues?.other_names,
      have_governing_document: formValues?.have_governing_document?.toString() === "true",
      missing_governing_document_comment: formValues?.missing_governing_document_comment,
      missing_registration_document_comment: formValues?.missing_registration_document_comment,
      registered_to_operate_in_country: formValues?.registered_to_operate_in_country?.toString() === "true",
      year_establishment: parseInt(formValues?.year_establishment),
      term_of_use: true,
    },
  };
  return result;
};

export const getFirstAccessInitialValues = (shellProfile) => {
  return {
    display_type: shellProfile?.display_type,
    legal_name: shellProfile?.legal_name,
    country_code: shellProfile?.country_code,
  };
};

export const adaptPartnersOptions = (partners) =>
  partners.map((partner) => ({
    ...partner,
    label: `${partner.legal_name} (${partner.country_name})`,
  }));

import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toPairs, isEmpty, map } from "ramda";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import { Link } from "react-router-dom";
import moment from "moment";
import { formatDateForChart } from "../../../../legacy-utils/dates";
import { formatMessage } from "../../../../legacy-utils/i18nHelper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import style from "./newPartners.module.scss";
import Loader from "@local/components/loader";

const NewPartners = ({ isLoading, number, dayBreakdown = {} }) => {
  let data;
  if (!isEmpty(dayBreakdown)) {
    data = map(
      ([date, count]) => ({ date: formatDateForChart(date), count }),
      toPairs(dayBreakdown).sort(([dateA], [dateB]) => moment(dateA).isAfter(dateB)),
    );
  }
  return (
    <Card>
      <CardContent>
        <div className={style.titleWrapper}>
          <div>
            <Typography variant="subtitle2" component="div">
              {formatMessage({
                id: "pages.dashboard.cmp.agency.newPartners.title",
              })}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {formatMessage({
                id: "pages.dashboard.cmp.agency.newPartners.caption",
              })}
            </Typography>
          </div>
          <div>
            {number !== undefined ? (
              <Typography variant="h6" component="div">
                {number}
              </Typography>
            ) : undefined}
          </div>
        </div>
        {isLoading ? (
          <Loader isVisible />
        ) : (
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={data} margin={{ left: 0, right: 16, top: 16 }}>
              <XAxis dataKey="date" />
              <YAxis allowDecimals={false} />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Line type="monotone" dataKey="count" stroke="#2196f3" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </CardContent>
      <CardActions>
        <Button component={Link} to="/partner/" color="primary">
          {formatMessage({
            id: "pages.dashboard.cmp.agency.newPartners.button",
          })}
        </Button>
      </CardActions>
    </Card>
  );
};

NewPartners.propTypes = {};

export default NewPartners;

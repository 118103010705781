import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import { adaptTimePeriodsValues, adaptDirectSelectionCompletedReasons } from "@local/pages/project-details/utils/adapters";
import { isRequired } from "@local/utils/validators";
import FieldSelect from "@local/components/field-select";

const FinalizeDirectSelectionProjectForm = ({ completedReasonsOptions, formValues, timePeriods }) => {
  return (
    <FieldGroup>
      <Field
        component={FieldText}
        validate={[isRequired]}
        id="justification"
        name="justification"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.finalize.direct.selection.project.form.idx.justificationLabel",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.container.cmp.finalize.direct.selection.project.form.idx.justificationPlaceholder",
        })}
      />
      <Field
        component={FieldRadioGroup}
        validate={[isRequired]}
        id="completed_reason"
        name="completed_reason"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.finalize.direct.selection.project.form.idx.completedReasonLabel",
        })}
        options={completedReasonsOptions}
        fullWidthLabel
        fullWidthRadioElement
      />
      {completedReasonsOptions.find((item) => item.value === "accepted_retention") ? (
        <Field
          component={FieldSelect}
          validate={[isRequired]}
          id="completed_retention"
          name="completed_retention"
          label={formatMessage({
            id: "pages.project.details.cmp.container.cmp.finalize.direct.selection.project.form.idx.retentionLabel",
          })}
          placeholder={formatMessage({
            id: "pages.project.details.cmp.container.cmp.finalize.direct.selection.project.form.idx.retentionPlaceholder",
          })}
          valueField="value"
          labelField="label"
          options={adaptTimePeriodsValues(timePeriods)}
        />
      ) : (
        <></>
      )}
      <Field
        component={FieldText}
        id="cancellation_explanation"
        name="cancellation_explanation"
        label={formatMessage({
          id: "pages.project.details.cmp.container.cmp.finalize.direct.selection.project.form.idx.cancellationExplanationLabel",
        })}
        placeholder={formatMessage({
          id: "pages.project.details.cmp.container.cmp.finalize.direct.selection.project.form.idx.cancellationExplanationPlaceholder",
        })}
        validate={formValues?.completed_reason === "cancelled" ? [isRequired] : []}
        readOnly={formValues?.completed_reason !== "cancelled"}
      />
    </FieldGroup>
  );
};

const selector = formValueSelector("finalizeDirectSelectionProjectForm");

const mapStateToProps = (state, ownProps) => ({
  completedReasonsOptions: adaptDirectSelectionCompletedReasons(state?.partnerProfileConfig["direct-selection-completed-reason"], ownProps, state?.projectDetails?.data?.data?.contains_partner_accepted),
  timePeriods: state?.partnerProfileConfig["direct-selection-retention"],
  completedReason: selector(state, "completed_reason"),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "finalizeDirectSelectionProjectForm",
    enableReinitialize: true,
  })(FinalizeDirectSelectionProjectForm),
);

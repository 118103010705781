import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import style from "./style.module.scss";
import DownloadIcon from "@local/components/download-icon";
import { ROLES } from "@local/legacy-utils/constants";

const canDocumentBeDeleted = (item, isPartnerLocked, isAgencyLocked, role, hasEditPseaSelfAssessmentDocumentsPermission, hasSpecialPseaPermission) => {
  if (role === ROLES.PARTNER && item?.is_agency) {
    return false;
  }
  if (role === ROLES.PARTNER && isPartnerLocked) {
    return false;
  }
  if (role === ROLES.PARTNER && !hasEditPseaSelfAssessmentDocumentsPermission) {
    return false;
  }
  if (role === ROLES.AGENCY && !item?.is_agency) {
    return false;
  }
  if (role === ROLES.AGENCY && isAgencyLocked) {
    return false;
  }
  if (role === ROLES.AGENCY && !hasEditPseaSelfAssessmentDocumentsPermission && !hasSpecialPseaPermission) {
    return false;
  }
  return true;
};

const Documents = ({ onClickAdd, documents, documentTypes, role, onClickDeleteDocument, hasEditPseaSelfAssessmentDocumentsPermission, hasSpecialPseaPermission, isPartnerLocked, isAgencyLocked, isDocumentRequired }) => {
  return (
    <>
      <span className={isDocumentRequired ? style.requiredLabel : style.label}>
        {formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.documents.idx.selfAssessmentDocumentLabel",
        })}
      </span>
      <div className={style.firstQuestionFilesListWrapper}>
        {documents?.length ? (
          <>
            {documents.map((item) => (
              <div key={`psea_initial_document_item_${item.id}`}>
                <DownloadIcon
                  onClickDelete={canDocumentBeDeleted(item, isPartnerLocked, isAgencyLocked, role, hasEditPseaSelfAssessmentDocumentsPermission, hasSpecialPseaPermission) ? () => onClickDeleteDocument(item) : undefined}
                  fileUrl={item.file.file_field}
                  fileName={documentTypes
                    ?.filter((type) => item.core_standards?.includes(type.value))
                    ?.map((item) => item.value)
                    ?.join(", ")}
                />
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={style.mainActionWrapper}>
        {role === ROLES.PARTNER || hasSpecialPseaPermission || hasEditPseaSelfAssessmentDocumentsPermission ? (
          <Button
            isDisabled={(isPartnerLocked && role === ROLES.PARTNER) || (isAgencyLocked && role === ROLES.AGENCY) || (!hasEditPseaSelfAssessmentDocumentsPermission && !hasSpecialPseaPermission)}
            text={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.documents.idx.uploadDocumentButton",
            })}
            onClick={onClickAdd}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

Documents.propTypes = {};

export default Documents;

import React, { useEffect } from "react";
import MainContentWrapper from "@local/components/main-content-wrapper";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "../../legacy-utils/i18nHelper";
import Main from "./components/main";
import { getIpAgreementData } from "@local/actions";
import { connect } from "react-redux";

const IPAgreement = ({ ipAgreement, getIpAgreement }) => {
  useEffect(() => {
    getIpAgreement();
  }, [getIpAgreement]);

  return (
    <HeaderNavigation title={formatMessage({ id: "pages.ip.agreement.idx.ipAgreement" })}>
      <MainContentWrapper>
        <Main ipAgreement={ipAgreement} />
      </MainContentWrapper>
    </HeaderNavigation>
  );
};

const mapStateToProps = (state) => ({
  ipAgreement: state.ipAgreementData?.data?.data ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  getIpAgreement: () => dispatch(getIpAgreementData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IPAgreement);

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { adminNav, getDuplicateFilterStatuses, getDuplicateResultsFilterStatuses, getPartnerTypes, getProjectStatusFilterValues, getSpecialPartnerTypes, mapAgenciesIds } from "@local/utils/constants";
import { getCountriesList, getLocationsList, getAgenciesList, getSectorsList, getPartnersList, getFocalPointsList, getAdminProjectsList, adminPartnerDuplicatesList } from "@local/actions";
import { getPartnerDuplicatesColumns, getPartnersColumns, getProjectAdminColumns } from "./utils/columns";
import { getPartnerDuplicateFilters, getPartnersFilters, getPendingAssessmentReviewFilters } from "./utils/filters";

import Container from "./components/container";
import { checkPermission, COMMON_PERMISSIONS } from "@local/legacy-utils/permissions";
import { checkRestrictedPath } from "@local/legacy-utils/restrictions";
import { adaptCountries, adaptFocalPoints } from "@local/utils/adapters";
import { getSortedList } from "@local/utils";
import AssessmentFinalizations from "./components/assessment-finilizations";
import AdminPartners from "./components/partners";
import PartnerDuplicates from "./components/partner-duplicates";

// POST_REFACTORING_TODO: check the selection on the tables

const AdminPanel = ({
  getPartners,
  specialPartnerTypes,
  partnerTypes,
  getProjects,
  sectors,
  statuses,
  getLocations,
  countries,
  getCountries,
  agencies,
  getAgencies,
  getSectors,
  isRestrictedPath,
  tabs,
  hasPermission,
  getFocalPoints,
  focalPoints,
  officeId,
  agencyId,
  getPartnersDuplicates,
  duplicateStatuses,
  duplicateResultStatuses,
}) => {
  const { type } = useParams();
  const [selectedProject, setSelectedProject] = useState(null);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [isPartnerDuplicateModalOpen, setIsPartnerDuplicateModalOpen] = useState(false);
  const [partnerDuplicate, setPartnerDuplicate] = useState(null);
  const [, setIsTableFiltered] = useState(false);
  const [, setFilterParams] = useState(null);
  const [locations, setLocations] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  useEffect(() => {
    getAgencies();
    getSectors();
    getCountries();
    getFocalPoints(officeId);
  }, []);

  const onClickCloseProjectModal = () => {
    setIsProjectModalOpen(false);
    setSelectedProject(null);
  };

  const onChangeTableFilters = (filterValues) => {
    setIsTableFiltered(Object.keys(filterValues).filter((filter) => filter).length > 0 && Object.values(filterValues).filter((filter) => filter).length > 0); // fix this issue
    setFilterParams(filterValues);
  };

  const onClickProjectRow = useCallback((row) => {
    setSelectedProject(row);
    setIsProjectModalOpen(true);
  });

  const onClickPartnerDuplicateRow = useCallback((row) => {
    setPartnerDuplicate(row);
    setIsPartnerDuplicateModalOpen(true);
  });

  const onClickClosePartnerDuplicateModal = () => {
    setIsPartnerDuplicateModalOpen(false);
    setPartnerDuplicate(null);
  };

  const onClickClosePartnerModal = () => {
    setIsPartnerModalOpen(false);
    setSelectedPartner(null);
  };

  const onClickPartnerRow = useCallback((row) => {
    setSelectedPartner(row);
    setIsPartnerModalOpen(true);
  });

  let content = undefined;

  switch (type) {
    case "projects":
      content = (
        <AssessmentFinalizations
          columns={getProjectAdminColumns(countries, sectors, onClickProjectRow)}
          filters={getPendingAssessmentReviewFilters(countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses)}
          fetchFunction={getProjects}
          isOpen={isProjectModalOpen}
          onClose={onClickCloseProjectModal}
          onChangeTableFilters={onChangeTableFilters}
          selectedProject={selectedProject}
          initialFilters={{ agency: agencyId }}
          shouldRefresh={shouldRefresh}
          setShouldRefresh={setShouldRefresh}
        />
      );
      break;
    case "partner-duplicates":
      content = (
        <PartnerDuplicates
          columns={getPartnerDuplicatesColumns(onClickPartnerDuplicateRow)}
          filters={getPartnerDuplicateFilters(duplicateStatuses, duplicateResultStatuses)}
          fetchFunction={getPartnersDuplicates}
          isOpen={isPartnerDuplicateModalOpen}
          onClose={onClickClosePartnerDuplicateModal}
          onChangeTableFilters={onChangeTableFilters}
          selectedProject={selectedProject}
          initialFilters={{ agency: agencyId }}
          shouldRefresh={shouldRefresh}
          setShouldRefresh={setShouldRefresh}
          selectedPartnerDuplicate={partnerDuplicate}
          setPartnerDuplicate={setPartnerDuplicate}
          setIsPartnerDuplicateModalOpen={setIsPartnerDuplicateModalOpen}
        />
      );
      break;
    case "partners":
      content = (
        <AdminPartners
          columns={getPartnersColumns(partnerTypes, specialPartnerTypes, countries, onClickPartnerRow)}
          filters={getPartnersFilters(countries, sectors, partnerTypes)}
          fetchFunction={getPartners}
          onChangeTableFilters={onChangeTableFilters}
          shouldRefresh={shouldRefresh}
          setShouldRefresh={setShouldRefresh}
          onClose={onClickClosePartnerModal}
          selectedPartner={selectedPartner}
          isOpen={isPartnerModalOpen}
        />
      );
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Container type={type} tabs={tabs} hasPermission={hasPermission} isRestrictedPath={isRestrictedPath}>
        {content}
      </Container>
    </>
  );
};

AdminPanel.propTypes = {};

const mapStateToProps = (state) => ({
  sectors: getSortedList(state?.sectorsList?.data?.data, "name"),
  agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
  focalPoints: adaptFocalPoints(state.focalPointsList?.data?.data?.results ?? []),
  statuses: getProjectStatusFilterValues(),
  duplicateResultStatuses: getDuplicateResultsFilterStatuses(),
  duplicateStatuses: getDuplicateFilterStatuses(),
  officeId: state.session.officeId,
  tabs: adminNav(state),
  role: state.session.role,
  agencyId: state.session.agencyId,
  specialPartnerTypes: getSpecialPartnerTypes(),
  hasPermission: checkPermission(COMMON_PERMISSIONS.ADMIN_PANEL, state),
  isRestrictedPath: checkRestrictedPath(state),
  partnerTypes: getPartnerTypes(),
  agencyName: state?.session?.agencyName,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getCountries: () => dispatch(getCountriesList()),
  getLocations: (params) => dispatch(getLocationsList(params)),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
  getSectors: (params) => dispatch(getSectorsList(params)),
  getFocalPoints: (officeId) => dispatch(getFocalPointsList({ focal: "true" }, { officeId })),
  getPartners: (params) => dispatch(getPartnersList({ ...params, is_locked: false })),
  getProjects: (params) => dispatch(getAdminProjectsList(params)),
  getPartnersDuplicates: (params) => dispatch(adminPartnerDuplicatesList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);

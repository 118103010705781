import React from "react";
import Card from "@local/components/card";
import VerificationIcon from "@local/components/verification-icon";
import CircleIcon from "@mui/icons-material/Circle";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { selectedPartnerDirectProjectStatusColor } from "@local/styles/muiTheme";
import style from "./style.module.scss";

const getStatusColor = (status) => selectedPartnerDirectProjectStatusColor[status];

const SelectedPartnersDirect = ({ project, currentStatus, projectStatus, isProjectPublished }) => (
  <Card
    title={formatMessage({
      id: "pages.project.details.cmp.overview.cmp.selected.partners.direct.idx.title",
    })}
  >
    <div>
      {project?.direct_selected_partners?.map((partner) => (
        <div key={partner?.id}>
          <div className={style.infoWrapper}>
            <span className={style.text}>{partner?.partner_name}</span>
            <VerificationIcon profileStatus={partner?.partner_additional?.profile_status} />
          </div>
          {projectStatus === "Dra" || projectStatus === "Sen" ? (
            <span className={style.info}>
              {formatMessage({
                id: "pages.project.details.cmp.overview.cmp.selected.partners.direct.idx.isDraftText",
              })}
            </span>
          ) : (
            <></>
          )}
          {isProjectPublished ? (
            currentStatus.includes("Suc") ? (
              <span className={style.info}>
                <CircleIcon style={{ fill: getStatusColor(currentStatus) }} fontSize="small" />
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.selected.partners.direct.idx.isPublishedText",
                })}
              </span>
            ) : currentStatus === "Acc" ? (
              <span className={style.info}>
                <CircleIcon style={{ fill: getStatusColor(currentStatus) }} fontSize="small" />
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.selected.partners.direct.idx.accepted",
                })}
              </span>
            ) : (
              <span className={style.info}>
                <CircleIcon style={{ fill: getStatusColor(currentStatus) }} fontSize="small" />
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.selected.partners.direct.idx.declined",
                })}
              </span>
            )
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  </Card>
);

SelectedPartnersDirect.propTypes = {};

export default SelectedPartnersDirect;

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptSpecializations } from "@local/utils/adapters";

// POST_REFACTORING_TODO: These functions are too heavy, check if they can be memoized

export const getOpenApplicationsFilters = (countries, getLocations, locations, setLocations, sectors, agencies, statuses) => {
  let result = [];
  result.push({
    id: "project_title",
    name: "project_title",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.search",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.search",
    }),
    type: "text",
  });
  result.push({
    id: "country_code",
    name: "country_code",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.country",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  });
  result.push({
    id: "locations",
    name: "locations",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.location",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
  });
  result.push({
    id: "specializations",
    name: "specializations",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.sector",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  });
  result.push({
    id: "applications_status",
    name: "applications_status",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.cnStatus",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: statuses,
  });
  result.push({
    id: "agency",
    name: "agency",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.agency",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.agency",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: agencies,
  });
  return result;
};

export const getDirectApplicationsFilters = (countries, getLocations, locations, setLocations, sectors, agencies) => {
  let result = [];
  result.push({
    id: "project_title",
    name: "project_title",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.search",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.search",
    }),
    type: "text",
  });
  result.push({
    id: "country_code",
    name: "country_code",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.country",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  });
  result.push({
    id: "locations",
    name: "locations",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.location",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
  });
  result.push({
    id: "specializations",
    name: "specializations",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.sector",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  });
  result.push({
    id: "agency",
    name: "agency",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.agency",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.agency",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: agencies,
  });
  return result;
};

export const getUnsolicitedApplicationsFilters = (countries, getLocations, locations, setLocations, sectors, agencies) => {
  let result = [];
  result.push({
    id: "project_title",
    name: "project_title",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.search",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.search",
    }),
    type: "text",
  });
  result.push({
    id: "country_code",
    name: "country_code",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.country",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  });
  result.push({
    id: "locations",
    name: "locations",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.location",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
  });
  result.push({
    id: "specializations",
    name: "specializations",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.sector",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  });
  result.push({
    id: "agency",
    name: "agency",
    label: formatMessage({
      id: "pages.applications.list.utils.filters.agency",
    }),
    placeholder: formatMessage({
      id: "pages.applications.list.utils.filters.agency",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: agencies,
  });
  return result;
};

import React from "react";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Chip from "@mui/material/Chip";

const SanctionStatus = ({ sanction }) => {
  return (
    <div className={style.mainWrapper}>
      {sanction === "confirmed_match" ? (
        <div className={style.confirmedMatchLabelWrapper}>
          <Chip
            size="small"
            style={{ backgroundColor: "#e84033", color: "#FFFFFF" }}
            label={formatMessage({
              id: "cmp.sanction.status.idx.confirmedMatch",
            })}
          />
        </div>
      ) : sanction === "potential_match" ? (
        <div className={style.potentialMatchLabelWrapper}>
          <Chip
            size="small"
            style={{ backgroundColor: "#f29000", color: "#FFFFFF" }}
            label={formatMessage({
              id: "cmp.sanction.status.idx.potentialMatch",
            })}
          />
        </div>
      ) : undefined}
    </div>
  );
};

SanctionStatus.propTypes = {};

export default SanctionStatus;

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { withRouter } from "react-router";
import Breadcrumb from "@local/components/breadcrumb";
import Psea from "@local/pages/partner-details/components/psea";

const PseaDetails = ({ partnerId }) => {
  return (
    <>
      <Breadcrumb
        items={[
          {
            id: 1,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.psea.details.idx.dashboardTitle",
            }),
            url: `/partner/${partnerId}/risk/dashboard`,
          },
          {
            id: 2,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.psea.details.idx.dashboardTitleOne",
            }),
            url: `/partner/${partnerId}/risk/psea`,
          },
        ]}
      />
      <Psea partnerId={partnerId} />
    </>
  );
};

export default withRouter(PseaDetails);

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Card from "@local/components/card";
import style from "./style.module.scss";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";

const items = (partner, history) => [
  {
    id: "legal_name",
    label: formatMessage({
      id: "pages.partner.details.cmp.spacial.partner.overview.idx.partnerName",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "id",
    label: formatMessage({
      id: "pages.partner.details.cmp.spacial.partner.overview.idx.partnerId",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "display_type_display",
    label: formatMessage({
      id: "pages.partner.details.cmp.spacial.partner.overview.idx.type",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "national_office",
    label: formatMessage({
      id: "pages.partner.details.cmp.spacial.partner.overview.idx.nationalOffice",
    }),
    isVisible: !partner.is_hq,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span className={style.link} onClick={() => history.push(`/special-partner/${partner.hq.id}/overview`)}>
          {partner?.hq?.legal_name}
        </span>
      </div>
    ),
  },
  {
    id: "country_display",
    isVisible: true,
    label: formatMessage({
      id: "pages.partner.details.cmp.spacial.partner.overview.idx.country",
    }),
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "address",
    label: formatMessage({
      id: "pages.partner.details.cmp.spacial.partner.overview.idx.address",
    }),
    isVisible: true,
    render: (item) => (
      <div className={style.itemWrapper}>
        <div className={style.subitemWrapper}>
          <span className={style.subitemLabel}>{item.label}</span>
          {partner?.mailing_address?.mailing_type === "Str" ? (
            <div className={style.itemWrapperChild}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.spacial.partner.overview.idx.street",
                })}
              </span>
              <span>{partner.mailing_address?.street}</span>
            </div>
          ) : (
            <div className={style.itemWrapperChild}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.spacial.partner.overview.idx.POBox",
                })}
              </span>
              <span>{partner.mailing_address?.po_box}</span>
            </div>
          )}
          <div className={style.itemWrapperChild}>
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.spacial.partner.overview.idx.city",
              })}
            </span>
            <span>{partner.mailing_address?.city}</span>
          </div>
          <div className={style.itemWrapperChild}>
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.spacial.partner.overview.idx.zipCode",
              })}
            </span>
            <span>{partner.mailing_address?.zip_code}</span>
          </div>
        </div>
      </div>
    ),
  },
  {
    id: "contact",
    label: formatMessage({
      id: "pages.partner.details.cmp.spacial.partner.overview.idx.contact",
    }),
    isVisible: true,
    render: (item) => (
      <div className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner.mailing_address?.org_email}</span>
      </div>
    ),
  },
];

const SpacialPartnerOverview = ({ partner }) => {
  const history = useHistory();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card
          title={
            <div className={style.headerWrapper}>
              <span className={style.headerTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.spacial.partner.overview.idx.profileSummary",
                })}
              </span>
              <div className={style.lastUpdateWrapper}>
                <span>
                  {formatMessage({
                    id: "pages.partner.details.cmp.spacial.partner.overview.idx.addedBy",
                  })}
                </span>
                <span>{partner?.added_by_agency}</span>
              </div>
            </div>
          }
        >
          <div className={style.itemsWrapper}>{items(partner, history).map((item) => (item?.isVisible ?? true ? item.render(item) : undefined))}</div>
        </Card>
      </Grid>
    </Grid>
  );
};

SpacialPartnerOverview.propTypes = {};

export default SpacialPartnerOverview;

import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import Alert from "@local/components/alert";
import FieldText from "@local/components/field-text";

const WithdrawApplicationModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.application.details.cmp.withdraw.application.modal.idx.title",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onClose}
              variant="text"
              text={formatMessage({
                id: "pages.application.details.cmp.withdraw.application.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.application.details.cmp.withdraw.application.modal.idx.withdraw",
              })}
            />
          </div>
        </>
      }
    >
      <Alert
        type="info"
        content={formatMessage({
          id: "pages.application.details.cmp.withdraw.application.modal.idx.header",
        })}
        withBottomMargin
      />
      <FieldGroup>
        <Field
          component={FieldText}
          id="withdraw_reason"
          name="withdraw_reason"
          label={formatMessage({
            id: "pages.application.details.cmp.withdraw.application.modal.idx.label",
          })}
          placeholder={formatMessage({
            id: "pages.application.details.cmp.withdraw.application.modal.idx.label",
          })}
          maxLength={5000}
          withCharactersCount
          multiline
        />
      </FieldGroup>
    </Modal>
  );
};

WithdrawApplicationModal.propTypes = {};

export default reduxForm({ form: "withdrawApplicationForm" })(WithdrawApplicationModal);

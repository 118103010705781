import React from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import { getApplicationsToScoreList } from "@local/actions";

// POST_REFACTORING_TODO: Check if this is working correctly

const columns = [
  {
    id: "eoi.title",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfConceptNotes.title",
    }),
    type: "link",
    to: (row) => `/cfei/open/${row?.eoi?.id}`,
    width: 250,
  },
  {
    id: "eoi.displayID",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfConceptNotes.displayID",
    }),
  },
  {
    id: "eoi_applications_count",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfConceptNotes.eoiApplicationsCount",
    }),
  },
  {
    id: "eoi.deadline_date",
    title: formatMessage({
      id: "pages.dashboard.cmp.agency.listOfConceptNotes.deadlineDate",
    }),
    type: "date",
  },
];

const ListOfConceptNotes = ({ getApplicationsToScore }) => {
  return (
    <Table
      id="list_of_concept_notes"
      title={formatMessage({
        id: "pages.dashboard.cmp.agency.listOfConceptNotes.titleOne",
      })}
      columns={columns}
      fetchFunction={getApplicationsToScore}
    />
  );
};

ListOfConceptNotes.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  getApplicationsToScore: (params) => dispatch(getApplicationsToScoreList(params)),
});

export default connect(undefined, mapDispatchToProps)(ListOfConceptNotes);

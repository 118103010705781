import React, { useEffect, useState } from "react";
import SectionContainer from "../section-container";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Main from "./components/main";
import { connect } from "react-redux";
import { getPseaDetails, getPseaSelfAssessmentJustificationsList, getPseaRatingCommentsList, createPseaRatingComment } from "@local/actions";
import { toast } from "react-toastify";
import { reset, isValid } from "redux-form";
import { ROLES } from "@local/legacy-utils/constants";

const FinalDetermination = ({
  psea,
  getPseaCsipDetails,
  getPseaSelfAssessmentJustifications,
  getPseaDetails,
  pseaSelfAssessmentJustifications,
  role,
  getPseaRatingComments,
  createPseaRatingComment,
  pseaRatingCommentFormValues,
  isRatingCommentFormValid,
  pseaRatingComments,
  resetPseaRatingCommentsForm,
}) => {
  const [isCreateCommentModalOpen, setIsCreateCommentModalOpen] = useState(false);

  useEffect(() => {
    getPseaDetails();
    getPseaSelfAssessmentJustifications();
    getPseaRatingComments();
  }, [getPseaCsipDetails, getPseaDetails, getPseaSelfAssessmentJustifications, getPseaRatingComments]);

  const onClickCreateComment = () => setIsCreateCommentModalOpen(true);

  const onCancelCreateCommentModal = () => {
    setIsCreateCommentModalOpen(false);
    resetPseaRatingCommentsForm();
  };

  const onSubmitCreateCommentModal = async () => {
    try {
      await createPseaRatingComment({
        ...pseaRatingCommentFormValues,
        is_agency: role === ROLES.AGENCY,
        is_final: false,
      });
      await getPseaRatingComments();
      setIsCreateCommentModalOpen(false);
      resetPseaRatingCommentsForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.idx.unableToSaveData",
        }),
      );
    }
  };

  return (
    <>
      <SectionContainer
        isCollapsable={false}
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.final.determination.idx.reassessment",
        })}
      >
        <Main
          psea={psea}
          role={role}
          justificationTypes={pseaSelfAssessmentJustifications}
          onClickCreateComment={onClickCreateComment}
          onCancelCreateCommentModal={onCancelCreateCommentModal}
          onSubmitCreateCommentModal={onSubmitCreateCommentModal}
          isCreateCommentModalOpen={isCreateCommentModalOpen}
          isRatingCommentFormValid={isRatingCommentFormValid}
          pseaRatingComments={pseaRatingComments}
        />
      </SectionContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state.session.role,
  psea: state.pseaDetails.data?.data ?? {},
  pseaSelfAssessmentJustifications: state?.pseaSelfAssessmentJustificationsList?.data?.data?.justifications ?? [],
  pseaRatingComments: state?.pseaRatingCommentsList?.data?.data ?? [],
  pseaRatingCommentFormValues: state?.form?.pseaRatingCommentFormValues?.values ?? {},
  isRatingCommentFormValid: isValid("pseaRatingCommentFormValues")(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPseaDetails: () => dispatch(getPseaDetails(undefined, { partnerId: ownProps.partnerId })),
  getPseaSelfAssessmentJustifications: () => dispatch(getPseaSelfAssessmentJustificationsList()),
  getPseaRatingComments: () => dispatch(getPseaRatingCommentsList(undefined, { partnerId: ownProps.partnerId })),
  createPseaRatingComment: (data) => dispatch(createPseaRatingComment(data, { partnerId: ownProps.partnerId })),
  resetPseaRatingCommentsForm: () => dispatch(reset("pseaRatingCommentFormValues")),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinalDetermination);

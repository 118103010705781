import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { formatMessage } from "../../../../legacy-utils/i18nHelper";
import Card from "@local/components/card";
import style from "./numberOfCfeisBySector.module.scss";
import Loader from "@local/components/loader";

const sectorsReverseTranslationItems = {
  "Camp Coordination & Camp Management": "backend.common.fixtures.initial.common.sector.Camp_Coordination_&_Camp_Management",
  Education: "backend.common.fixtures.initial.common.sector.Education",
  "Food Security": "backend.common.fixtures.initial.common.sector.Food_Security",
  Health: "backend.common.fixtures.initial.common.sector.Health",
  Livelihoods: "backend.common.fixtures.initial.common.sector.Livelihoods",
  Logistics: "backend.common.fixtures.initial.common.sector.Logistics",
  Nutrition: "backend.common.fixtures.initial.common.sector.Nutrition",
  Protection: "backend.common.fixtures.initial.common.sector.Protection",
  Shelter: "backend.common.fixtures.initial.common.sector.Shelter",
  "WASH and Environment": "backend.common.fixtures.initial.common.sector.WASH_and_Environment",
  "Social Policy and  Advocacy": "backend.common.fixtures.initial.common.sector.Social_Policy_and__Advocacy",
  "Durable Solutions": "backend.common.fixtures.initial.common.sector.Durable_Solutions",
  "Cross Sectoral Areas": "backend.common.fixtures.initial.common.sector.Cross_Sectoral_Areas",
};

const adaptItems = (items) => {
  if (!items) return [];
  const result = Object.keys(items).map((key) => ({
    id: key,
    label: formatMessage({ id: sectorsReverseTranslationItems[key] }),
    value: items[key],
  }));
  const total = result.reduce((total, current) => total + current.value, 0);
  return result.map((item) => ({
    ...item,
    percentage: Math.round((item.value * 100) / total),
  }));
};

const NumberOfCfeisBySector = ({ isLoading, newCfeiBySectors }) => {
  const items = adaptItems(newCfeiBySectors);

  return (
    <Card
      title={
        <div>
          <Typography variant="subtitle2" component="div">
            {formatMessage({
              id: "pages.dashboard.cmp.partner.numberOfCfeisBySector.title",
            })}
          </Typography>
          <Typography variant="subtitle1" component="div">
            {formatMessage({
              id: "pages.dashboard.cmp.partner.numberOfCfeisBySector.caption",
            })}
          </Typography>
        </div>
      }
      footer={
        <>
          <Button component={Link} to="/cfei/open/" color="primary">
            {formatMessage({
              id: "pages.dashboard.cmp.partner.numberOfCfeisBySector.button",
            })}
          </Button>
        </>
      }
    >
      {isLoading ? (
        <Loader isVisible={false} />
      ) : (
        <>
          {items.map((item) => (
            <div key={`dashboard_number_of_cfeis_by_sector_${item.id}`} className={style.totalItemWrapper}>
              <div className={style.progressWrapper}>
                <div className={style.progressPercentage} style={{ width: `${item.percentage}%` }} />
                <div className={style.percentageLabel}>
                  <span>{item.value}</span>
                  <span>{item.label}</span>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </Card>
  );
};

NumberOfCfeisBySector.propTypes = {};

export default NumberOfCfeisBySector;

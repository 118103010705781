import React from "react";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import MainContent from "@local/components/main-content-wrapper";
import HeaderNavigation from "@local/components/header-navigation";
import { formatDate } from "@local/utils";
import WarningIcon from "@mui/icons-material/Warning";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import Button from "@local/components/button";
import AddIcon from "@mui/icons-material/Add";
import ManageCountryOfficeModal from "../manage-country-office-modal";

const ProfileItem = ({ title, usersCount, lastUpdateDate, isCompleted, onClick }) => (
  <div className={style.itemColumnsWrapper}>
    {title ? (
      <div className={style.title}>
        <span>{title}</span>
      </div>
    ) : (
      <></>
    )}
    <div>
      {usersCount ? (
        <span>
          {usersCount} {formatMessage({ id: "pages.profiles.list.cmp.mn.idx.users" })}
        </span>
      ) : (
        <></>
      )}
    </div>
    <div>
      <span>{formatDate(lastUpdateDate)}</span>
    </div>
    <div>
      <div className={style.warningIconWrapper}>
        {!isCompleted ? (
          <div className={style.warningIcon}>
            <WarningIcon style={{ fontSize: ".75rem", color: "#dd0000" }} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
    <div>
      <div>
        <IconButton size="large" edge="end" onClick={onClick} color="inherit">
          <ArrowForwardIosIcon />
        </IconButton>
      </div>
    </div>
  </div>
);

const Main = ({ partnerProfiles, onClickProfile, hasAddCountryOfficePermission, onSubmitAddNewCountry, countries, onClickOpenAddCountryOfficeModal, onClickCloseAddCountryOfficeModal, isCountryModalVisible }) => {
  const partnerHqProfile = partnerProfiles ?? { users: 0 };
  const partnerCountryProfiles = partnerProfiles?.country_profiles ?? [];
  const partnerCountryPresence = partnerProfiles?.country_presence ?? [];

  return (
    <>
      <HeaderNavigation title={formatMessage({ id: "pages.profiles.list.cmp.mn.idx.header" })} />
      <MainContent>
        <div className={style.itemWrapper}>
          <Card>
            <ProfileItem
              title={formatMessage({
                id: "pages.profiles.list.cmp.mn.idx.profile",
              })}
              usersCount={partnerHqProfile.users}
              lastUpdateDate={partnerHqProfile.modified}
              isCompleted
              onClick={() => onClickProfile(partnerHqProfile.id)}
            />
          </Card>
        </div>
        <Card
          title={formatMessage({
            id: "pages.profiles.list.cmp.mn.idx.countryProfile",
          })}
          action={
            hasAddCountryOfficePermission ? (
              <Button
                text={formatMessage({
                  id: "pages.profiles.list.cmp.mn.idx.new",
                })}
                startIcon={<AddIcon />}
                onClick={onClickOpenAddCountryOfficeModal}
                variant="text"
              />
            ) : undefined
          }
        >
          {partnerCountryProfiles.map((item, index) => (
            <div key={`partner_profile_${index}`} className={style.itemWrapper}>
              <ProfileItem title={item.country_display} usersCount={undefined} lastUpdateDate={item.last_profile_update} isCompleted={item?.partner_additional?.profile_is_complete} onClick={() => onClickProfile(item.id)} />
            </div>
          ))}
        </Card>
      </MainContent>
      <ManageCountryOfficeModal isOpen={isCountryModalVisible} onSubmit={onSubmitAddNewCountry} onClose={onClickCloseAddCountryOfficeModal} countries={countries} countryPresence={partnerCountryPresence} partnerCountryProfiles={partnerCountryProfiles} />
    </>
  );
};

Main.propTypes = {};

export default Main;

import { formatMessage } from "@local/legacy-utils/i18nHelper";

const initialState = {
  tabs: [
    {
      id: "overview",
      path: "overview",
      label: formatMessage({
        id: "legacy.reducers.organizationProfileNav.overview",
      }),
    },
    {
      id: "undata",
      path: "undata",
      label: formatMessage({
        id: "legacy.reducers.organizationProfileNav.undata",
      }),
    },
  ],
};

export default function organizationProfileNavReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

import React from "react";
import Table from "@local/components/table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getVerificationsColumns, getVerificationsExpandSectionItems } from "../../utils/columns";

const Verification = ({ fetchFunction, shouldRefresh, afterRefresh }) => {
  return (
    <Table
      key="partner_verifications"
      id="partner_verifications"
      title={formatMessage({
        id: "pages.partner.details.cmp.verification.idx.verification",
      })}
      columns={getVerificationsColumns()}
      fetchFunction={fetchFunction}
      shouldRefresh={shouldRefresh}
      afterRefresh={afterRefresh}
      expandSectionItems={getVerificationsExpandSectionItems()}
      expandSectionNumberOfColumns={1}
    />
  );
};

Verification.propTypes = {};

export default Verification;

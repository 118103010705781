import { getKeyName } from "@local/utils";

export const adaptFormInitialValues = (user, toAddUser, regionalOffice) => {
  if (!user) {
    return {
      id: undefined,
      formValues: toAddUser
        ? {
            email: toAddUser.split(",").at(0),
            fullname: toAddUser.split(",").at(1),
          }
        : { is_regional_office: "false" },
      stateValues: {
        officeRoles: [
          {
            id: 0,
          },
        ],
      },
    };
  }

  let officeRolesFormValues = {};
  let officeRoles = user?.office_memberships ?? [];
  if (officeRoles.length === 0) officeRoles = [{ originalId: undefined }];
  officeRoles = officeRoles.map((item, index) => ({
    id: index,
    originalId: item?.id ?? undefined,
    role: item?.role ?? undefined,
    office: item?.office?.id ?? undefined,
  }));

  officeRoles.forEach((item, index) => {
    officeRolesFormValues[getKeyName("regional_office_role_office", index)] = item.office;
    officeRolesFormValues[getKeyName("regional_office_role_role", index)] = item.role;
  });
  officeRoles.forEach((item, index) => {
    officeRolesFormValues[getKeyName("office_role_office", index)] = item.office;
    officeRolesFormValues[getKeyName("office_role_role", index)] = item.role;
  });

  return {
    id: user?.id,
    formValues: {
      fullname: user?.fullname,
      email: user?.email,
      is_regional_office: regionalOffice ? "true" : "false",
      office_role_office_main: regionalOffice ? regionalOffice?.id : undefined,
      office_role_role_main: regionalOffice ? officeRolesFormValues?.regional_office_role_role_0 : undefined,
      ...officeRolesFormValues,
    },
    stateValues: {
      officeRoles,
      officesForRegion: regionalOffice ? officeRoles : undefined,
    },
  };
};

export const adaptFormValuesToBeSent = (formValues, additionalParams) => {
  return {
    fullname: formValues?.fullname,
    email: formValues?.email,
    office_memberships:
      formValues?.is_regional_office?.toString() === "true"
        ? additionalParams.officeRoles.map((item) => ({
            id: item.originalId ?? undefined,
            role: formValues[getKeyName("regional_office_role_role", item.id)],
            office_id: formValues[getKeyName("regional_office_role_office", item.id)],
          }))
        : additionalParams.officeRoles.map((item) => ({
            id: item.originalId ?? undefined,
            role: formValues[getKeyName("office_role_role", item.id)],
            office_id: formValues[getKeyName("office_role_office", item.id)],
          })),
  };
};

export const adaptOfficeFormInitialValues = (regionalOffice, toAddRegional, countries) => {
  if (!regionalOffice) {
    return {
      id: undefined,
      formValues: toAddRegional
        ? {
            code: toAddRegional.split(",").at(0),
            name: toAddRegional.split(",").at(1),
          }
        : {},
      stateValues: {},
    };
  }

  return {
    id: regionalOffice?.id,
    formValues: {
      name: regionalOffice?.name,
      code: regionalOffice?.code,
      countries: regionalOffice?.countries.map((country) => countries[country]),
    },
    stateValues: {},
  };
};

export const adaptOfficeFormValuesToBeSent = (formValues, countries) => {
  return {
    name: formValues?.name,
    code: formValues?.code,
    countries: formValues?.countries.map((country) => Object.keys(countries).find((key) => countries[key] === country)),
  };
};

export const adaptOfficesOfRegion = (offices, countries, countryCode) =>
  offices
    ?.map((office) => (office?.name === countries[countryCode] ? office?.id : undefined))
    ?.filter((item) => item)
    ?.at(0);

export const adaptCountriesToOffices = (offices, countries, countriesForRegion) => countriesForRegion?.map((item) => offices.find((office) => office?.name === countries[item]));

import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldSelect from "@local/components/field-select";
import FieldText from "@local/components/field-text";
import { isRequired, isValidEmail } from "@local/utils/validators";
import FieldAutosuggest from "@local/components/field-autosuggest";

const NewShellProfileModal = ({ isOpen, onClose, countries, partnerTypes, onSubmit, isValid, isInternational, getPartnerHqs }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.newShellProfileModalButton",
      })}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.cancel",
            })}
          />
          <Button
            isDisabled={!isValid}
            onClick={onSubmit}
            text={formatMessage({
              id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.save",
            })}
          />
        </>
      }
    >
      <FieldGroup>
        <Field
          component={FieldSelect}
          validate={[isRequired]}
          id="display_type"
          name="display_type"
          label={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.type",
          })}
          placeholder={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.type",
          })}
          valueField="value"
          labelField="label"
          options={partnerTypes}
        />
        {isInternational ? (
          <Field
            component={FieldAutosuggest}
            validate={[isRequired]}
            id="hq"
            name="hq"
            label={formatMessage({
              id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.hq",
            })}
            placeholder={formatMessage({
              id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.hq",
            })}
            valueField="value"
            labelField="label"
            fetchFunction={getPartnerHqs}
            adapterFunction={(partners) =>
              partners.map((partner) => ({
                value: partner.id,
                label: partner.legal_name,
              }))
            }
          />
        ) : undefined}
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="legal_name"
          name="legal_name"
          label={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.label",
          })}
          placeholder={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.label",
          })}
        />
        <Field
          component={FieldText}
          validate={[isRequired, isValidEmail]}
          id="email"
          name="email"
          label={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.email",
          })}
          placeholder={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.email",
          })}
          type="email"
        />
        <Field
          component={FieldSelect}
          validate={[isRequired]}
          id="country_code"
          name="country_code"
          label={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.country",
          })}
          placeholder={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.country",
          })}
          valueField="value"
          labelField="label"
          options={countries}
        />
        <Field
          component={FieldText}
          id="vendor_number"
          name="vendor_number"
          label={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.partnerCode",
          })}
          placeholder={formatMessage({
            id: "pages.shell.profiles.list.cmp.new.shell.profile.modal.idx.partnerCode",
          })}
        />
      </FieldGroup>
    </Modal>
  );
};

NewShellProfileModal.propTypes = {};

export default reduxForm({ form: "newShellProfileModalForm" })(NewShellProfileModal);

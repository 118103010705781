import React from "react";
import style from "./style.module.scss";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const Box = ({ title, content, sections, actions, informationText, error, withBackground, withBorder = true, withPadding = true }) => {
  return (
    <div
      className={withBackground ? style.boxWrapperWithBackground : style.boxWrapper}
      style={{
        borderColor: withBorder ? (error ? "#d32f2f" : "#dddddd") : "transparent",
      }}
    >
      {title ? (
        <div className={style.boxTitleWrapper} style={{ borderColor: error ? "#d32f2f" : "#dddddd" }}>
          <div>
            <span className={style.boxTitle} style={{ color: error ? "#d32f2f" : "#333333" }}>
              {title}
            </span>
            {error ? <span className={style.errorMessage}>{error}</span> : undefined}
          </div>
          {informationText ? (
            <div className={style.tooltipWrapper}>
              <Tooltip title={informationText}>
                <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
              </Tooltip>
            </div>
          ) : undefined}
        </div>
      ) : undefined}
      {content ? <div style={withPadding ? { padding: "1rem" } : undefined}>{content}</div> : undefined}
      {sections ? (
        <div className={style.boxSections}>
          {sections.map((item, index) => (
            <div className={style.boxSection} key={`box_section_${title}_${index}`}>
              {item}
            </div>
          ))}
        </div>
      ) : undefined}
      {actions ? <div className={style.boxActions}>{actions}</div> : undefined}
    </div>
  );
};

Box.propTypes = {};

export default Box;

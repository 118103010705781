import * as R from "ramda";
import { getPartnerFlags } from "../legacy-utils/api";
import { clearError, startLoading, stopLoading, saveErrorMsg } from "./apiStatus";
import React from "react";
import FormattedMessage from "../legacy-utils/translator";

export const OBSERVATIONS_LOAD_STARTED = "OBSERVATIONS_LOAD_STARTED";
export const OBSERVATIONS_LOAD_SUCCESS = "OBSERVATIONS_LOAD_SUCCESS";
export const OBSERVATIONS_LOAD_FAILURE = "OBSERVATIONS_LOAD_FAILURE";
export const OBSERVATIONS_LOAD_ENDED = "APA_LOAD_ENDED";

export const agencyPartnerObsLoadStarted = () => ({
  type: OBSERVATIONS_LOAD_STARTED,
});
export const agencyPartnerObsLoadSuccess = (response) => ({
  type: OBSERVATIONS_LOAD_SUCCESS,
  response,
});
export const agencyPartnerObsLoadFailure = (error) => ({
  type: OBSERVATIONS_LOAD_FAILURE,
  error,
});
export const agencyPartnerObsLoadEnded = () => ({
  type: OBSERVATIONS_LOAD_ENDED,
});

const saveFlags = (state, action) => {
  const flags = R.map(
    (item) => ({
      id: item.id,
      flag_type: item.flag_type,
      category_display: item.category_display || "-",
      modified: item.modified,
      created: item.created,
      submitter: item.submitter,
      contactPerson: item.contact_person,
      contactEmail: item.contact_email,
      contactPhone: item.contact_phone,
      attachment: item.attachment,
      category: item.category,
      comment: item.comment,
      isValid: item.is_valid,
      isEscalated: item.can_be_escalated,
      validationComment: item.validation_comment,
      escalationComment: item.escalation_comment,
    }),
    action.response.results,
  );

  return R.assoc("items", flags, R.assoc("totalCount", action.response.count, state));
};

const messages = {
  loadFailed: "Load partner observations failed.",
};

const initialState = {
  columns: [
    {
      name: "flag_type",
      title: <FormattedMessage id="reducers.agencyPartnerObservationsList.flagType" />,
      width: 350,
    },
    {
      name: "category_display",
      title: <FormattedMessage id="reducers.agencyPartnerObservationsList.categoryDisplay" />,
    },
    {
      name: "modified",
      title: <FormattedMessage id="reducers.agencyPartnerObservationsList.modified" />,
    },
    {
      name: "submitter",
      title: <FormattedMessage id="reducers.agencyPartnerObservationsList.submitter" />,
    },
  ],
  loading: false,
  totalCount: 0,
  items: [],
};

export const loadPartnerFlags = (id, params) => (dispatch) => {
  dispatch(agencyPartnerObsLoadStarted());
  return getPartnerFlags(id, params)
    .then((flags) => {
      dispatch(agencyPartnerObsLoadEnded());
      dispatch(agencyPartnerObsLoadSuccess(flags));
    })
    .catch((error) => {
      dispatch(agencyPartnerObsLoadEnded());
      dispatch(agencyPartnerObsLoadFailure(error));
    });
};

export default function agencyPartnerObservationsListReducer(state = initialState, action) {
  switch (action.type) {
    case OBSERVATIONS_LOAD_FAILURE: {
      return saveErrorMsg(state, action, messages.loadFailed);
    }
    case OBSERVATIONS_LOAD_ENDED: {
      return stopLoading(state);
    }
    case OBSERVATIONS_LOAD_STARTED: {
      return startLoading(clearError(state));
    }
    case OBSERVATIONS_LOAD_SUCCESS: {
      return saveFlags(state, action);
    }
    default:
      return state;
  }
}

import { Grid } from "@mui/material";

import { Field, reduxForm } from "redux-form";

import { isRequired } from "@local/utils/validators";
import { connect } from "react-redux";
import Button from "@local/components/button";
import style from "./style.module.scss";
import Box from "@local/components/box";
import { getFocalPointsList, updateProjectFocalPoints } from "@local/actions";

import ConfirmModal from "@local/components/confirm-modal";
import { useState } from "react";
import { toast } from "react-toastify";
import i18n from "@local/i18next/i18n";
import FieldSelect from "@local/components/field-select";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptFocalPoints } from "@local/utils/adapters";

const ProjectFocalPointForm = ({ focalPoints, updateProjectFocalPoints, formValues }) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const isValidForm = (formValues) => {
    if (formValues?.["focal_points"].length > 0) {
      return true;
    }
    return false;
  };
  return (
    <>
      <div className={style.modalContent}>
        <Box
          title={i18n.t("Focal points")}
          content={
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    component={FieldSelect}
                    validate={[isRequired]}
                    id="focal_points"
                    name="focal_points"
                    label={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.projectProgrammeFocalPoints",
                    })}
                    placeholder={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.selectTheNameOfTheFocalPoint",
                    })}
                    valueField="id"
                    labelField="label"
                    options={adaptFocalPoints(focalPoints)}
                    multiple
                  />
                </Grid>
              </Grid>
            </>
          }
        />
        <div>
          <Button isDisabled={!isValidForm(formValues)} className={style.updateButton} text={i18n.t("Update")} onClick={() => setIsConfirmModalOpen(true)} />
        </div>
      </div>
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onSubmit={() => {
          updateProjectFocalPoints(formValues, focalPoints);
          setIsConfirmModalOpen(false);
        }}
        onClose={() => setIsConfirmModalOpen(false)}
        message={i18n.t("Are you sure you want to update/replace the project's focal points?")}
        type="info"
      />
    </>
  );
};

const mapStateToProps = (state, initialValues) => {
  const existingFocals = new Set((state.focalPointsList?.data?.data?.results || []).map((focal) => focal.id));

  const newFocals = (initialValues?.selectedProject?.focal_points || []).filter((focal) => !existingFocals.has(focal.id)).map((focal) => ({ id: focal.id, name: focal.name, email: focal.email }));

  const focals = [...(state.focalPointsList?.data?.data?.results || []), ...newFocals];
  return {
    formValues: state?.form?.projectAdminFocalPointModalForm?.values,
    focalPoints: focals,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getFocalPoints: (officeId) => dispatch(getFocalPointsList({ focal: "true" }, { officeId })),
  updateProjectFocalPoints: async (body, focalPoints) => {
    const updatedFocalPoints = body.focal_points
      .map((id) => {
        const focal = focalPoints.find((focal) => focal.id.toString() === id);
        if (focal) {
          // Return only id, name, and email properties
          const { id, name, email } = focal;
          return { id, name, email };
        }
        return null; // Return null if no match is found
      })
      .filter(Boolean); // Remove null or undefined entries

    try {
      await dispatch(
        updateProjectFocalPoints(
          {
            focal_points: updatedFocalPoints,
          },
          { id: body?.id },
        ),
      );
      toast.success(i18n.t("Update success"));
      ownProps.setShouldRefresh(true);
    } catch (error) {
      toast.error(i18n.t("Update failed"));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "projectAdminFocalPointModalForm", enableReinitialize: true })(ProjectFocalPointForm));

import React, { useRef, useState } from "react";
import Button from "@local/components/button";
import { requestUpload } from "@local/utils/network";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import { getColorByHighlightType } from "@local/utils";

const getFileNameFromUrl = (url) => url?.split("/").at(-1);

const FieldFile = ({ id, multiple, readOnly, label, input: { value, onChange, name }, meta, informationText, isLocalFile = false, highlightType }) => {
  const fileField = useRef(undefined);
  const [loading, setLoading] = useState(false);

  const onClickUpload = () => {
    fileField.current.click();
  };

  const onSelectFiles = async () => {
    setLoading(true);
    try {
      if (isLocalFile) {
        if (fileField.current.files[0].name.length <= 100) {
          const reader = new FileReader();
          reader.onload = (event) => {
            if (multiple) {
              onChange([
                ...value,
                {
                  content: btoa(event.target.result),
                  filename: fileField.current.files[0].name,
                },
              ]);
            } else {
              onChange({
                content: btoa(event.target.result),
                filename: fileField.current.files[0].name,
              });
            }
            fileField.current.value = "";
          };
          reader.readAsBinaryString(fileField.current.files[0]);
        } else {
          toast.error(
            `${formatMessage({
              id: "cmp.field.file.idx.localFileFieldErrorMessageA",
            })} (${formatMessage({
              id: "cmp.field.file.idx.localFileFieldErrorMessageB",
            })} ${fileField.current.files[0].name.length}).`,
          );
        }
      } else {
        const formData = new FormData();
        formData.append("file_field", fileField.current.files[0]);
        const result = await requestUpload(formData);
        fileField.current.value = "";
        if (multiple) {
          onChange([...value, result?.data]);
        } else {
          onChange(result?.data);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.file_field?.join(", ") ?? formatMessage({ id: "cmp.field.file.idx.error" }), { autoClose: 10000 });
    }
    setLoading(false);
  };

  const onClickFile = (file) => window.open(file.file_field);

  const onClickRemove = (toRemove) => {
    if (multiple) {
      const updatedValue = value.filter((item) => (!isLocalFile && item.id !== toRemove.id) || (isLocalFile && item.filename === toRemove.filename));
      onChange(updatedValue);
    } else {
      onChange(null);
    }
  };

  let displayValue = [];
  if (multiple) {
    if (value.length) displayValue = value;
  } else {
    if (value) displayValue = [value];
  }

  const color = getColorByHighlightType(highlightType);

  return (
    <div>
      <div
        className={style.mainWrapper}
        style={{
          backgroundColor: `${color}29`,
          borderColor: (meta?.error ?? "") !== "" ? "#d32f2f" : "#b7b7b7",
        }}
      >
        <div className={style.filesAndLabelWrapper}>
          <span className={style.label}>{label}</span>
          {informationText ? (
            <div className={style.tooltipWrapper}>
              <Tooltip title={informationText}>
                <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
              </Tooltip>
            </div>
          ) : undefined}
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              {displayValue.length ? (
                <div className={style.filesListWrapper}>
                  {displayValue.map((item, index) => (
                    <div key={`file_field_item_${id}_${name}_${index}`} className={style.fileWrapper}>
                      <Chip
                        className={style?.attachmentName}
                        avatar={
                          <Avatar sx={{ backgroundColor: "#C5C5C5" }}>
                            <AttachFileIcon style={{ fontSize: ".75rem", fill: "#404040" }} />
                          </Avatar>
                        }
                        onClick={() => onClickFile(item)}
                        label={isLocalFile ? item.filename : getFileNameFromUrl(item.file_field)}
                        size="small"
                        onDelete={readOnly !== true ? () => onClickRemove(item) : undefined}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <span
                  className={style.placeholder}
                  style={{
                    color: (meta?.error ?? "") !== "" ? "#d32f2f" : "#888888",
                  }}
                >
                  {meta?.error ? meta?.error : formatMessage({ id: "cmp.field.file.idx.missingFile" })}
                </span>
              )}
            </>
          )}
        </div>
        <div className={style.buttonWrapper}>{!readOnly ? <Button variant="text" onClick={onClickUpload} text={formatMessage({ id: "cmp.field.file.idx.buttonLabel" })} /> : undefined}</div>
      </div>
      <input id={`file_field_${id}_${name}`} name={`file_field_${id}_${name}`} type="file" ref={fileField} className={style.fileInput} onChange={onSelectFiles} />
    </div>
  );
};

FieldFile.propTypes = {};

export default FieldFile;

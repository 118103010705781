import React from "react";

import Table from "@local/components/table";
import i18n from "@local/i18next/i18n";

const DraftProjectsList = ({ fetchFunction, columns, filters, adapterFunction, initialFilters, shouldRefresh, setShouldRefresh }) => {
  return (
    <Table
      shouldRefresh={shouldRefresh}
      afterRefresh={() => setShouldRefresh(false)}
      key="projects_list_open_draft"
      id="projects_list_open_draft"
      title={i18n.t("Draft projects")}
      columns={columns}
      fetchFunction={fetchFunction}
      filters={filters}
      adapterFunction={adapterFunction}
      withUrlSynchronization
      initialFilters={initialFilters}
    />
  );
};

DraftProjectsList.propTypes = {};

export default DraftProjectsList;

import { Tooltip } from "@mui/material";
import style from "./style.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import i18n from "@local/i18next/i18n";
import { CALCULATION_STATUSES } from "@local/utils/constants";
const SimilarityInfoArea = ({ selectedPartnerDuplicate }) => {
  return (
    <>
      <span style={{ padding: "1rem", fontWeight: 600 }}>Similarity Scores</span>
      <div className={style.similaritiesArea}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
          <div className={style.singleSimilarity}>
            <div className={style.similarityHeadingsWrapper}>
              <span>Name</span>
              <Tooltip title="Indicates the similarity score between the legal names of the partners" arrow>
                <InfoIcon fontSize="12px" htmlColor="#11111180" />
              </Tooltip>
            </div>
            <span>{selectedPartnerDuplicate?.name_similarity}%</span>
          </div>

          <div className={style.singleSimilarity}>
            <div className={style.similarityHeadingsWrapper}>
              <span>Address</span>
              <Tooltip title="Indicates the similarity score between the address mailing data points (Street, City, Country, Zip code, Website, Organization email) of the partners" arrow>
                <InfoIcon fontSize="12px" htmlColor="#11111180" />
              </Tooltip>
            </div>
            {selectedPartnerDuplicate?.address_similarity}%
          </div>

          <div className={style.singleSimilarity}>
            <div className={style.similarityHeadingsWrapper}>
              <span>Document</span>
              <Tooltip title="Indicates the similarity score between the document data points (Registration number, Issue date, Issuing authority) of partners" arrow>
                <InfoIcon fontSize="12px" htmlColor="#11111180" />
              </Tooltip>
            </div>
            {selectedPartnerDuplicate?.document_similarity}%
          </div>

          <div className={style.singleSimilarity}>
            <div className={style.similarityHeadingsWrapper}>
              <span>Weighted</span>
              <Tooltip title="Indicates the average of all similarity scores (Name score, Address score, Document score)" arrow>
                <InfoIcon fontSize="12px" htmlColor="#11111180" />
              </Tooltip>
            </div>
            {selectedPartnerDuplicate?.weighted_similarity}%
          </div>
        </div>
        <div className={style.similarityResultWrapper}>
          <div className={style.similarityResultInnerWrapper}>
            <span style={{ fontWeight: 600 }}>{i18n.t("Calculation Result")}</span>
            <span>{selectedPartnerDuplicate?.duplicate_result ? CALCULATION_STATUSES[selectedPartnerDuplicate?.duplicate_result] : "--"}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimilarityInfoArea;

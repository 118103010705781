import React, { useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import style from "./style.module.scss";
import ShowHide from "@local/components/show-hide";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";

const SectionContainer = ({ title, children, isEmpty, warningMessages, isCollapsable = true, isExpanded = false, emptyLabel = undefined, hasWarningIcon = false }) => {
  const [isVisible, setIsVisible] = useState(isExpanded);

  const onClickToggle = () => setIsVisible((state) => !state);

  return (
    <>
      <div className={style.mainWrapper}>
        <div className={style.titleWrapper}>
          <div className={style.titleContent}>
            {hasWarningIcon ? (
              <div className={style.warningIcon}>
                <WarningIcon style={{ fontSize: ".85rem", color: "#dd0000" }} />
              </div>
            ) : undefined}
            <span className={hasWarningIcon ? style.title : undefined}>{title}</span>
          </div>
          {warningMessages?.length ? (
            warningMessages?.map((warningMessage) => (
              <div>
                <Tooltip title={warningMessage?.tooltip}>
                  <div className={style.warningWrapper}>
                    <span>{warningMessage?.title}</span>
                    <InfoIcon style={{ fontSize: ".8rem", marginLeft: ".25rem" }} />
                  </div>
                </Tooltip>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
        {isCollapsable ? <IconButton onClick={onClickToggle}>{isVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton> : <></>}
      </div>
      <ShowHide isVisible={!isCollapsable || isVisible}>
        <div className={style.contentWrapper}>
          {isEmpty ? (
            <span className={style.emptyLabel}>
              {emptyLabel ??
                formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.section.container.idx.notAvailable",
                })}
            </span>
          ) : (
            children
          )}
        </div>
      </ShowHide>
    </>
  );
};

export default SectionContainer;

import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";

const ConfirmModal = ({ isOpen, message, onClose, onSubmit, type = "confirmation", isConfirmButtonDisabled = false, title = formatMessage({ id: "cmp.confirm.modal.idx.confirm" }) }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      small
      type={type}
      footer={
        <>
          <Button variant="text" onClick={onClose} text={formatMessage({ id: "cmp.confirm.modal.idx.cancel" })} />
          <Button isDisabled={isConfirmButtonDisabled} onClick={onSubmit} text={formatMessage({ id: "cmp.confirm.modal.idx.confirmOne" })} />
        </>
      }
    >
      {message}
    </Modal>
  );
};

ConfirmModal.propTypes = {};

export default ConfirmModal;

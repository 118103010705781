import React from "react";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BackButton from "../back-button";
import style from "./style.module.scss";

const HeaderNavigation = ({ title, backButtonPath, tabs, children, header, handleChange, index }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={style.mainWrapper}>
            <div className={style.titleWrapper}>
              <div className={style.titleBackButtonWrapper}>
                <div>
                  {backButtonPath ? (
                    <div className={style.backButtonWrapper}>
                      <BackButton path={backButtonPath} />
                    </div>
                  ) : undefined}
                </div>
                <div>
                  <div className={style.title}>{title}</div>
                </div>
              </div>
              <div>{header}</div>
            </div>
            {tabs ? (
              <div className={style.tabsWrapper}>
                <Tabs value={index} onChange={handleChange} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile>
                  {tabs.map((tab, index) => (
                    <Tab key={`header_navigation_tab_${index}`} label={tab.label} disabled={tab.isDisabled} />
                  ))}
                </Tabs>
              </div>
            ) : undefined}
          </div>
        </Grid>
      </Grid>
      <>{children}</>
    </>
  );
};

HeaderNavigation.propTypes = {};

export default HeaderNavigation;

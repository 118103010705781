import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";

const DownloadButton = ({ fileUrl }) => (
  <div className={style.contentWrapper}>
    {fileUrl ? (
      <a target="_blank" href={fileUrl} rel="noreferrer" className={style.downloadLabel}>
        <span className={style.downloadIcon}>
          <FileDownloadIcon />
        </span>
        {formatMessage({ id: "cmp.download.button.idx.download" })}
      </a>
    ) : (
      <span className={style.captionText}>{formatMessage({ id: "cmp.download.button.idx.noFile" })}</span>
    )}
  </div>
);

DownloadButton.propTypes = {};

export default DownloadButton;

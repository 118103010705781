import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldText from "@local/components/field-text";
import FieldFile from "@local/components/field-file";
import Button from "@local/components/button";
import { Grid } from "@mui/material";
import { isRequired } from "@local/utils/validators";
import style from "./style.module.scss";

const ManageObservationModal = ({ isOpen, onClose, pristine, invalid, isEditable, onSubmit, onEditObservation }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.observations.cmp.manage.observation.modal.idx.addObservation",
      })}
      small
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            component={FieldText}
            id="comment"
            name="comment"
            type="text"
            multiline
            rows={4}
            maxRows={6}
            maxLength={5000}
            withCharactersCount
            label={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.observations.cmp.manage.observation.modal.idx.label",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.observations.cmp.manage.observation.modal.idx.placeholder",
            })}
            validate={[isRequired]}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={FieldFile}
            id="attachments"
            name="attachments"
            label={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.observations.cmp.manage.observation.modal.idx.button",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.observations.cmp.manage.observation.modal.idx.button",
            })}
            multiple
          />
        </Grid>
      </Grid>
      <div className={style.submitButtonWrapper}>
        <Button
          variant="contained"
          onClick={isEditable ? onEditObservation : onSubmit}
          isDisabled={pristine || invalid}
          text={formatMessage({
            id: "pages.partner.details.cmp.risk.cmp.observations.cmp.manage.observation.modal.idx.buttonOne",
          })}
        />
      </div>
    </Modal>
  );
};

ManageObservationModal.propTypes = {};

export default reduxForm({
  form: "manageObservationForm",
  enableReinitialize: true,
})(ManageObservationModal);

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldSelect from "@local/components/field-select";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group/index";
import { isRequired, isValidEmail } from "@local/utils/validators";
import Alert from "@local/components/alert";
import Box from "@local/components/box";
import style from "./style.module.scss";
import FieldText from "@local/components/field-text";
import i18n from "@local/i18next/i18n";
import ConfirmModal from "@local/components/confirm-modal";

const AdminPanelUserManageModal = ({ isOpen, onSubmit, onClose, isValidForm, roles, stateValues, inEditMode, formValues }) => {
  const [officeRoles, setOfficeRoles] = useState(undefined);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  useEffect(() => {
    setOfficeRoles(undefined);
    if (stateValues?.officeRoles && !officeRoles) setOfficeRoles(stateValues.officeRoles);
  }, [stateValues?.officeRoles]);

  const onClickSubmit = async () => {
    onSubmit();
    setConfirmModalHidden();
    setOfficeRoles(undefined);
  };

  const onClickClose = () => {
    onClose();
  };

  const setConfirmModalVisible = () => {
    setIsConfirmModalVisible(true);
  };

  const setConfirmModalHidden = () => {
    setIsConfirmModalVisible(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClickClose}
        title={formatMessage({
          id: "pages.users.management.cmp.user.manage.modal.idx.title",
        })}
        footer={
          <>
            <Button
              variant="text"
              onClick={onClickClose}
              text={formatMessage({
                id: "pages.users.management.cmp.user.manage.modal.idx.cancel",
              })}
            />
            <Button
              isDisabled={!isValidForm}
              onClick={setConfirmModalVisible}
              text={formatMessage({
                id: "pages.users.management.cmp.user.manage.modal.idx.save",
              })}
            />
          </>
        }
      >
        <Alert
          type="info"
          content={formatMessage({
            id: "pages.users.management.cmp.user.manage.modal.idx.header",
          })}
          withBottomMargin
        />
        <FieldGroup>
          <Field
            component={FieldText}
            validate={[isRequired]}
            readOnly={inEditMode}
            id="fullname"
            name="fullname"
            label={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.firstName",
            })}
            placeholder={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.firstName",
            })}
            type="text"
          />
          <Field
            component={FieldText}
            validate={[isRequired, isValidEmail]}
            readOnly={inEditMode}
            id="email"
            name="email"
            label={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.email",
            })}
            placeholder={formatMessage({
              id: "pages.users.management.cmp.user.manage.modal.idx.email",
            })}
            type="email"
          />
          <>
            <Box
              title={i18n.t("Role")}
              content={
                <div className={style.itemWrapper}>
                  <div>
                    <FieldGroup>
                      <Field
                        component={FieldSelect}
                        validate={[isRequired]}
                        id={"role"}
                        name={"role"}
                        label={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.role",
                        })}
                        placeholder={formatMessage({
                          id: "pages.users.management.cmp.user.manage.modal.idx.role",
                        })}
                        valueField="value"
                        labelField="label"
                        options={roles}
                      />
                    </FieldGroup>
                  </div>
                </div>
              }
            />
          </>
        </FieldGroup>
      </Modal>
      <ConfirmModal onClose={setConfirmModalHidden} isOpen={isConfirmModalVisible} onSubmit={onClickSubmit} title={!inEditMode ? i18n.t("Do you confirm creating a new user for the current partner?") : i18n.t("Do you confirm updating the current user?")} />
    </>
  );
};

AdminPanelUserManageModal.propTypes = {};

export default reduxForm({ form: "userModalForm", enableReinitialize: true })(AdminPanelUserManageModal);

import parse from "date-fns/parse";
import { formatDateToBeSent } from "@local/utils";
import { UN_VENDOR_NUMBER_DOCUMENT_TYPE_CHOICES, OBSERVATION_DECISION, ESCALATION_DECISION, FLAGS, SANCTION_DECISION } from "@local/legacy-utils/constants";

export const adaptApplications = (applications, countries) =>
  applications.map((application) => ({
    ...application,
    country: application?.eoi?.country_code?.map((item) => countries[item]).join(", "),
    specializations: application?.eoi?.specializations?.map((item) => item.name)?.join(", "),
  }));

export const adaptDocumentsType = (documentType) => Object.values(UN_VENDOR_NUMBER_DOCUMENT_TYPE_CHOICES).find((item) => item.value === documentType);

export const adaptFormInitialValues = (data) => ({
  document_type: data?.document_type,
  file_field: data?.file_field,
  date: [parse(data?.start_date, "yyyy-MM-dd", new Date()), parse(data?.end_date, "yyyy-MM-dd", new Date())],
});

export const adaptFormValuesToBeSent = (data) => ({
  document_type: data?.document_type,
  file_field: data?.file_field?.id,
  start_date: formatDateToBeSent(data?.date[0]),
  end_date: formatDateToBeSent(data?.date[1]),
});

export const adaptObservationInitialValues = (data) => ({
  contact_email: data?.contact_email,
  contact_person: data?.contact_person,
  contact_phone: data?.contact_phone,
  comment: data?.comment,
  attachment: data?.attachment,
});

export const adaptObservationToBeSent = (type, formValues) => {
  const result = {
    category: formValues?.category,
    comment: formValues?.comment,
    contact_email: formValues?.contact_email,
    contact_person: formValues?.contact_person,
    contact_phone: formValues?.contact_phone,
    reason_radio: formValues?.reason_radio,
    flag_decision: [{}],
  };
  switch (type) {
    case "updateObservation":
      return {
        ...result,
        is_valid: formValues?.reason_radio === OBSERVATION_DECISION.NO_VALID ? false : undefined,
        flag_type: formValues?.reason_radio !== OBSERVATION_DECISION.NO_VALID ? FLAGS.ESCALATED : formValues?.flag_type,
        validation_comment: formValues?.validation_comment,
      };
    case "updateEscalatedObservation":
      return {
        ...result,
        is_valid: formValues?.reason_radio === ESCALATION_DECISION.DEFFERED ? false : true,
        escalation_comment: formValues?.escalation_comment,
      };
    case "updateSanctionObservation":
      return {
        ...result,
        is_valid: formValues?.reason_radio === SANCTION_DECISION.NOTMATCH ? false : true,
        category: undefined,
      };
    default:
      return result;
  }
};

export const getRatingScoreLabel = (revisionScore, ratingOptions, uploadedDocumentRating) => {
  let rating = undefined;
  let result = "";
  if (revisionScore !== null) {
    if (revisionScore === 8) rating = "full";
    else if (revisionScore >= 6 && revisionScore <= 7) rating = "medium";
    else if (revisionScore <= 5) rating = "low";
  }
  if (rating) result = ratingOptions?.find((item) => item.value === rating)?.extendedLabel;
  return result || ratingOptions?.find((item) => item.value === uploadedDocumentRating)?.extendedLabel;
};

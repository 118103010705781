import React from "react";
import Table from "@local/components/table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getOpenProjectApplicationsColumns } from "../../utils/columns";
import { getOpenProjectApplicationFilters } from "../../utils/filters";

const OpenProjectApplications = ({
  fetchFunction,
  countries,
  getLocations,
  locations,
  setLocations,
  sectors,
  concernTypes,
  partnerTypes,
  updateProjectApplication,
  project,
  shouldRefresh,
  afterRefresh,
  isCompleted,
  isFocalPoint,
  isCreator,
  isApplicationDeadlinePassed,
  isReviewer,
}) => {
  return (
    <Table
      shouldRefresh={shouldRefresh}
      afterRefresh={afterRefresh}
      key="open_project_applications"
      id="open_project_applications"
      title={formatMessage({
        id: "pages.project.details.cmp.open.project.applications.idx.applications",
      })}
      columns={getOpenProjectApplicationsColumns(project, updateProjectApplication, isCompleted, isFocalPoint, isCreator, isApplicationDeadlinePassed, isReviewer)}
      fetchFunction={fetchFunction}
      filters={getOpenProjectApplicationFilters(countries, getLocations, locations, setLocations, sectors, concernTypes, partnerTypes)}
    />
  );
};

OpenProjectApplications.propTypes = {};

export default OpenProjectApplications;

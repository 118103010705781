import { formatMessage } from "@local/legacy-utils/i18nHelper";

const initialState = {
  tabs: [
    {
      id: "overview",
      path: "overview",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.overview",
      }),
      isAvailableForSpecialPartner: true,
      isAvailableForRegularPartner: true,
    },
    {
      id: "details",
      path: "details",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.details",
      }),
      isAvailableForSpecialPartner: false,
      isAvailableForRegularPartner: true,
    },
    {
      id: "undata",
      path: "undata",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.undata",
      }),
      isAvailableForSpecialPartner: true,
      isAvailableForRegularPartner: true,
    },
    {
      id: "verification",
      path: "verification",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.verification",
      }),
      isAvailableForSpecialPartner: false,
      isAvailableForRegularPartner: true,
    },
    {
      id: "risk",
      path: "risk/dashboard",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.risk",
      }),
      isDisabled: false,
      isAvailableForSpecialPartner: false,
      isAvailableForRegularPartner: true,
    },
    {
      id: "observations",
      path: "observations",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.observations",
      }),
      isAvailableForSpecialPartner: false,
      isAvailableForRegularPartner: false,
    },
    {
      id: "applications",
      path: "applications",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.applications",
      }),
      isAvailableForSpecialPartner: false,
      isAvailableForRegularPartner: true,
    },
    {
      id: "users",
      path: "users",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.users",
      }),
      isAvailableForSpecialPartner: false,
      isAvailableForRegularPartner: true,
    },
    {
      id: "offices",
      path: "offices",
      label: formatMessage({
        id: "legacy.reducers.agencyPartnerProfileNav.countryOffice",
      }),
      isAvailableForSpecialPartner: true,
      isAvailableForRegularPartner: false,
    },
  ],
};

export default function agencyPartnerProfileNavReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

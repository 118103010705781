/* eslint-disable no-prototype-builtins */

import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "ramda";
import Typography from "@mui/material/Typography";
import { formatMessage } from "../../../../legacy-utils/i18nHelper";
import NumberOfPartnerCompletedProfile from "./numberOfPartnerCompletedProfile";
import Card from "@local/components/card";
import style from "./numberOfPartners.module.scss";
import Loader from "@local/components/loader";

const backendTextData = {
  "Academic Institution": "backend.dashboard.agencyDashboard.orgTypes.Aca",
  "Community Based Organization (CBO)": "backend.dashboard.agencyDashboard.orgTypes.CBO",
  "International Civil Society Organization Country Office": "backend.dashboard.agencyDashboard.orgTypes.INGO_CO",
  "International Civil Society Organization HQ": "backend.dashboard.agencyDashboard.orgTypes.INGO_HQ",
  "National NGO": "backend.dashboard.agencyDashboard.orgTypes.NGO",
  "Red Cross/Red Crescent National Society": "backend.dashboard.agencyDashboard.orgTypes.RCC",
};

const chartColors = ["rgba(135, 86, 146, 1)", "rgba(94, 44, 242, 1)", "rgba(50, 101, 219, 1)", "rgba(44, 211, 242, 1)", "rgba(42, 232, 153, 1)", "rgba(190, 0, 50, 1)"];

const NumberOfPartners = ({ isLoading, partnerBreakdown = {}, orgTypes }) => {
  let data = [];
  let dataForList = [];
  let total = 0;
  let totalCompleted = 0;
  if (!isEmpty(partnerBreakdown)) {
    for (var key in partnerBreakdown) {
      if (partnerBreakdown.hasOwnProperty(key)) {
        let complete = {
          name: formatMessage({ id: backendTextData[orgTypes?.[key]] }),
          partnerCompletedName: formatMessage({ id: backendTextData[orgTypes?.[key]] }) + " Completed",
          partnerType: partnerBreakdown[key][0],
          completion: "Completed",
          partnerProfile: partnerBreakdown[key][1],
        };
        let uncomplete = {
          partnerCompletedName: formatMessage({ id: backendTextData[orgTypes?.[key]] }) + " Uncompleted",
          completion: "Uncomplete",
          partnerProfile: partnerBreakdown[key][0] - partnerBreakdown[key][1],
        };
        total = total + partnerBreakdown[key][0];
        totalCompleted = totalCompleted + partnerBreakdown[key][1];
        dataForList.push(complete);
        data.push(complete);
        data.push(uncomplete);
      }
    }
  }
  return (
    <Card
      title={
        <div>
          <div>
            <Typography variant="subtitle2" component="div">
              {formatMessage({
                id: "pages.dashboard.cmp.agency.numberOfPartners.title",
              })}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {formatMessage({
                id: "pages.dashboard.cmp.agency.numberOfPartners.caption",
              })}
            </Typography>
          </div>
        </div>
      }
    >
      {isLoading ? (
        <Loader isVisible />
      ) : (
        <>
          <div>{data && data.length > 0 ? <NumberOfPartnerCompletedProfile total={total} totalCompleted={totalCompleted} chartData={data} /> : undefined}</div>
          <div className={style.mainWrapper}>
            {dataForList.map(({ name, partnerType, partnerProfile }, index) => (
              <div key={`number_of_partners_${index}`} className={style.rowWrapper}>
                <div>
                  <div style={{ background: chartColors[index] }} />
                </div>
                <div>
                  <span>{name}</span>
                </div>
                <div>
                  <span>
                    {partnerType}/{partnerProfile}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  );
};

NumberOfPartners.propTypes = {};

NumberOfPartners.contextTypes = {};

const mapStateToProps = (state) => ({
  orgTypes: state.partnerProfileConfig["dashboard-partner-type"],
});

export default connect(mapStateToProps, undefined)(NumberOfPartners);

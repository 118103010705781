/* eslint-disable no-case-declarations */
import { formatDateToBeSent } from "@local/utils";
import { PROJECT_TYPES } from "@local/utils/constants";
import { getKeyName, getLocationObject } from "@local/utils";
export const adaptProjectToBeSent = (type, formValues, selectedLocations, attachments, criteria, agencyId, agencyOfficeId, project, onEditId) => {
  const locations = selectedLocations.map((location) => ({
    admin_level_1: {
      name: location.adminLevelOne.name,
      country_code: location.adminLevelOne.countryCode,
    },
    lat: location.latitude,
    lon: location.longitude,
  }));
  const result = {
    agency: agencyId,
    agency_office: agencyOfficeId,
    title: formValues.title,
    country_code: formValues.countries, // POST_REFACTORING_TODO: This was in the original request, not sure if it is needed
    locations: locations, // POST_REFACTORING_TODO: This was in the original request, not sure if it is needed
    countries: formValues.countries.map((country) => ({
      country,
      locations: locations.filter((location) => location.admin_level_1.country_code === country),
    })),
    focal_points: formValues?.focal_points ?? undefined,
    specializations: formValues.areas,
    population: formValues?.population ?? undefined,
    description: formValues.description,
    goal: formValues.goal,
    indicative_budget: formValues?.indicative_budget ?? undefined,
    indicative_budget_currency: formValues?.indicative_budget_currency ?? undefined,
    other_information: formValues?.other_information ?? undefined,
    start_date: formatDateToBeSent(formValues.start_date),
    end_date: formatDateToBeSent(formValues.end_date),
    attachments: attachments
      .filter((item) => formValues[`attachment_${item.uniqueId}`]?.id)
      .map((item) => ({
        id: item?.originalId ?? undefined,
        file: formValues[`attachment_${item.uniqueId}`].id,
        description: formValues[`attachment_description_${item.uniqueId}`],
      })),
    cn_template_id: formValues?.cn_template?.id,
    is_cn_substitute: formValues?.is_cn_substitute?.toString() === "true",
  };
  switch (type) {
    case PROJECT_TYPES.OPEN:
      return {
        ...result,
        clarification_request_deadline_date: formatDateToBeSent(formValues.clarification_request_deadline_date),
        deadline_date: formatDateToBeSent(formValues.deadline_date),
        notif_results_date: formatDateToBeSent(formValues.notif_results_date),
        has_weighting: formValues.has_weighting?.toString() === "true",
        assessments_criteria: criteria.map((item) => ({
          id: item?.originalId ?? undefined,
          selection_criteria: formValues[`selection_criteria_${item.uniqueId}`],
          description: formValues[`selection_criteria_description_${item.uniqueId}`],
          weight: formValues.has_weighting?.toString() === "true" ? parseFloat(formValues[`selection_criteria_weight_${item.uniqueId}`]) : undefined, // POST_REFACTORING_TODO Check this
        })),
      };
    case PROJECT_TYPES.UNSOLICITED:
      return {
        ...result,
        cn: project?.cn?.id !== formValues?.cn?.id ? formValues?.cn?.id : undefined,
        agency: formValues?.agency,
      };
    case PROJECT_TYPES.DIRECT:
      if (!onEditId) {
        return {
          applications: [
            {
              ds_attachment: formValues?.ds_attachment?.id,
              ds_justification_select: formValues?.ds_justification_select,
              is_cn_required: formValues?.is_cn_required === "true",
              justification_reason: formValues?.justification_reason,
              partner: formValues?.partner?.id,
            },
          ],
          eoi: result,
        };
      } else {
        return {
          ...result,
          applications: [
            {
              ds_attachment: formValues?.ds_attachment?.id,
              ds_justification_select: formValues?.ds_justification_select,
              is_cn_required: formValues?.is_cn_required === "true",
              justification_reason: formValues?.justification_reason,
              partner: formValues?.partner?.id,
            },
          ],
        };
      }
    default:
      return result;
  }
};

export const adaptDraftProjectToBeSent = (type, formValues, selectedLocations, attachments, criteria, agencyId, agencyOfficeId, project, onEditId) => {
  const locations = selectedLocations?.map((location) => ({
    admin_level_1: {
      name: location.adminLevelOne.name,
      country_code: location.adminLevelOne.countryCode,
    },
    lat: location.latitude,
    lon: location.longitude,
  }));
  const result = {
    agency: agencyId,
    agency_office: agencyOfficeId,
    title: formValues.title,
    country_code: formValues?.countries, // POST_REFACTORING_TODO: This was in the original request, not sure if it is needed
    locations: locations, // POST_REFACTORING_TODO: This was in the original request, not sure if it is needed
    countries: formValues?.countries?.map((country) => ({
      country,
      locations: locations?.filter((location) => location.admin_level_1.country_code === country),
    })),
    focal_points: formValues?.focal_points ?? undefined,
    specializations: formValues?.areas,
    population: formValues?.population ?? undefined,
    description: formValues.description,
    goal: formValues?.goal,
    indicative_budget: formValues?.indicative_budget ?? undefined,
    indicative_budget_currency: formValues?.indicative_budget_currency ?? undefined,
    other_information: formValues?.other_information ?? undefined,
    start_date: formValues?.start_date ? formatDateToBeSent(formValues.start_date) : undefined,
    end_date: formValues?.end_date ? formatDateToBeSent(formValues.end_date) : undefined,
    attachments: attachments
      ?.filter((item) => formValues[`attachment_${item.uniqueId}`]?.id)
      ?.map((item) => ({
        id: item?.originalId ?? undefined,
        file: formValues[`attachment_${item.uniqueId}`].id,
        description: formValues[`attachment_description_${item.uniqueId}`],
      })),
    cn_template_id: formValues?.cn_template?.id,
    is_cn_substitute: formValues?.is_cn_substitute?.toString() === "true",
  };
  switch (type) {
    case PROJECT_TYPES.OPEN:
      return {
        ...result,
        clarification_request_deadline_date: formValues?.clarification_request_deadline_date ? formatDateToBeSent(formValues.clarification_request_deadline_date) : undefined,
        deadline_date: formValues?.deadline_date ? formatDateToBeSent(formValues.deadline_date) : undefined,
        notif_results_date: formValues?.notif_results_date ? formatDateToBeSent(formValues.notif_results_date) : undefined,
        has_weighting: formValues?.has_weighting?.toString() === "true",
        assessments_criteria: criteria?.map((item) => ({
          id: item?.originalId ?? undefined,
          selection_criteria: formValues[`selection_criteria_${item.uniqueId}`],
          description: formValues[`selection_criteria_description_${item.uniqueId}`],
          weight: formValues?.has_weighting?.toString() === "true" ? parseFloat(formValues[`selection_criteria_weight_${item.uniqueId}`]) : undefined, // POST_REFACTORING_TODO Check this
        })),
      };
    case PROJECT_TYPES.UNSOLICITED:
      return {
        ...result,
        cn: project?.cn?.id !== formValues?.cn?.id ? formValues?.cn?.id : undefined,
        agency: formValues?.agency,
      };
    case PROJECT_TYPES.DIRECT:
      if (!onEditId) {
        return {
          applications: [
            {
              ds_attachment: formValues?.ds_attachment?.id,
              ds_justification_select: formValues?.ds_justification_select,
              is_cn_required: formValues?.is_cn_required === "true",
              justification_reason: formValues?.justification_reason,
              partner: formValues?.partner?.id,
            },
          ],
          eoi: result,
        };
      } else {
        return {
          ...result,
          applications: [
            {
              ds_attachment: formValues?.ds_attachment?.id,
              ds_justification_select: formValues?.ds_justification_select,
              is_cn_required: formValues?.is_cn_required === "true",
              justification_reason: formValues?.justification_reason,
              partner: formValues?.partner?.id,
            },
          ],
        };
      }
    default:
      return result;
  }
};

const getBooleanString = (value) => {
  if (value === true) return "true";
  return "false";
};
export const adaptEditProjectInitialFormValues = (project, type, sectors, allCountries) => {
  if (!project) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }
  let attachmentsFormValues = {};
  let attachments = project?.attachments ?? [];
  if (attachments?.length === 0) attachments = [{ uniqueId: 0 }];
  attachments = attachments.map((item, index) => ({
    uniqueId: item?.id ?? index,
    originalId: item?.id ?? undefined,
    file: item.file,
    description: item.description,
  }));
  attachments.forEach((item) => {
    attachmentsFormValues[getKeyName("attachment", item.uniqueId)] = item.originalId
      ? {
          file_field: item.file.file_field,
          id: item.file.id,
        }
      : undefined;
    attachmentsFormValues[getKeyName("attachment_description", item.uniqueId)] = item.description;
  });
  let selectedLocations = [];
  let countries = new Set();
  if (type === PROJECT_TYPES.UNSOLICITED) {
    project?.locations_proposal_of_eoi?.forEach(async (item) => {
      countries.add(item.admin_level_1.country_code);
      const toAdd = await getLocationObject({ latitude: parseFloat(item.lat), longitude: parseFloat(item.lon) }, allCountries, [item?.country_code]);
      selectedLocations.push(toAdd);
    });
  } else {
    project?.locations?.forEach(async (item) => {
      countries.add(item.admin_level_1.country_code);
      const toAdd = await getLocationObject({ latitude: parseFloat(item.lat), longitude: parseFloat(item.lon) }, allCountries, [item?.admin_level_1?.country_code]);
      selectedLocations.push(toAdd);
    });
  }

  let formValues = {
    id: project?.id ?? undefined,
    countries: Array.from(countries),
    focal_points: project?.focal_points ?? [],
    population: project?.population ?? undefined,
    description: project?.description ?? "",
    goal: project?.goal ?? "",
    indicative_budget: project?.indicative_budget,
    indicative_budget_currency: project?.indicative_budget_currency,
    other_information: project?.other_information,
    start_date: new Date(project?.start_date),
    end_date: new Date(project?.end_date),
    ...attachmentsFormValues,
  };

  let stateValues = {
    attachments,
    selectedLocations,
  };

  switch (type) {
    case PROJECT_TYPES.OPEN:
      let criteriaFormValues = {};
      let criteria = project?.assessments_criteria ?? [];
      if (criteria.length === 0) criteria = [{ uniqueId: 0 }];
      criteria = criteria.map((item, index) => ({
        uniqueId: item?.id ?? index,
        originalId: item?.selection_criteria ?? undefined,
        selection_criteria: item?.selection_criteria ?? undefined,
        description: item?.description ?? undefined,
        weight: item?.weight ?? undefined,
      }));
      criteria.forEach((item) => {
        criteriaFormValues[getKeyName("selection_criteria", item.uniqueId)] = item.selection_criteria;
        criteriaFormValues[getKeyName("selection_criteria_description", item.uniqueId)] = item.description;
        criteriaFormValues[getKeyName("selection_criteria_weight", item.uniqueId)] = item.weight;
      });

      formValues = {
        ...formValues,
        title: project?.title ?? "",
        clarification_request_deadline_date: new Date(project?.clarification_request_deadline_date),
        deadline_date: new Date(project?.deadline_date),
        notif_results_date: new Date(project?.notif_results_date),
        has_weighting: getBooleanString(project?.has_weighting),
        is_cn_substitute: project?.is_cn_substitute,
        cn_template: { id: undefined, file_field: project?.cn_template },
        sectors: sectors.filter((sector) => project?.specializations?.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.specializations?.map((item) => item.id),
        ...criteriaFormValues,
      };

      stateValues = {
        ...stateValues,
        criteria,
      };
      break;
    case PROJECT_TYPES.DIRECT:
      formValues = {
        ...formValues,
        title: project?.title ?? "",
        ds_attachment: project?.direct_selected_partners?.at(0)?.ds_attachment,
        ds_justification_select: project?.direct_selected_partners?.at(0)?.ds_justification_select,
        is_cn_required: project?.direct_selected_partners?.at(0)?.is_cn_required,
        justification_reason: project?.direct_selected_partners?.at(0)?.justification_reason,
        partner: {
          id: project?.direct_selected_partners?.at(0)?.partner_id,
          legal_name: project?.direct_selected_partners?.at(0)?.partner_name,
        },
        sectors: sectors.filter((sector) => project?.specializations?.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.specializations?.map((item) => item.id),
      };
      break;
    case PROJECT_TYPES.UNSOLICITED:
      formValues = {
        ...formValues,
        agency: project?.agency?.id,
        cn: project?.cn,
        title: project?.proposal_of_eoi_details?.title ?? "",
        ds_attachment: project?.direct_selected_partners?.at(0)?.ds_attachment,
        ds_justification_select: project?.direct_selected_partners?.at(0)?.ds_justification_select,
        is_cn_required: project?.is_cn_required,
        justification_reason: project?.direct_selected_partners?.at(0)?.justification_reason,
        partner: {
          id: project?.direct_selected_partners?.at(0)?.partner_id,
          legal_name: project?.direct_selected_partners?.at(0)?.partner_name,
        },
        sectors: sectors.filter((sector) => project?.proposal_of_eoi_details?.specializations.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.proposal_of_eoi_details?.specializations?.map((item) => item.id),
      };
      break;
    default:
      break;
  }
  return {
    formValues,
    stateValues,
  };
};

export const adaptEditDraftProjectInitialFormValues = (project, type, sectors, allCountries) => {
  if (!project) {
    return {
      formValues: undefined,
      stateValues: undefined,
    };
  }
  let attachmentsFormValues = {};
  let attachments = project?.attachments ?? [];
  if (attachments?.length === 0) attachments = [{ uniqueId: 0 }];
  attachments = attachments.map((item, index) => ({
    uniqueId: item?.id ?? index,
    originalId: item?.id ?? undefined,
    file: item.file,
    description: item.description,
  }));
  attachments.forEach((item) => {
    attachmentsFormValues[getKeyName("attachment", item.uniqueId)] = item.originalId
      ? {
          file_field: item.file.file_field,
          id: item.file.id,
        }
      : undefined;
    attachmentsFormValues[getKeyName("attachment_description", item.uniqueId)] = item.description;
  });
  let selectedLocations = [];
  let countries = new Set();
  if (type === PROJECT_TYPES.UNSOLICITED) {
    project?.locations_proposal_of_eoi?.forEach(async (item) => {
      countries.add(item.admin_level_1.country_code);
      const toAdd = await getLocationObject({ latitude: parseFloat(item.lat), longitude: parseFloat(item.lon) }, allCountries, [item?.country_code]);
      selectedLocations.push(toAdd);
    });
  } else {
    project?.locations?.forEach(async (item) => {
      countries.add(item.admin_level_1.country_code);
      const toAdd = await getLocationObject({ latitude: parseFloat(item.lat), longitude: parseFloat(item.lon) }, allCountries, [item?.admin_level_1?.country_code]);
      selectedLocations.push(toAdd);
    });
  }

  let formValues = {
    id: project?.id ?? undefined,
    countries: Array.from(countries),
    focal_points: project?.focal_points ?? [],
    population: project?.population ?? undefined,
    description: project?.description ?? "",
    goal: project?.goal ?? "",
    indicative_budget: project?.indicative_budget,
    indicative_budget_currency: project?.indicative_budget_currency,
    other_information: project?.other_information,
    start_date: new Date(project?.start_date),
    end_date: new Date(project?.end_date),
    ...attachmentsFormValues,
  };

  let stateValues = {
    attachments,
    selectedLocations,
  };

  switch (type) {
    case PROJECT_TYPES.OPEN:
      let criteriaFormValues = {};
      let criteria = project?.assessments_criteria ?? [];
      if (criteria.length === 0) criteria = [{ uniqueId: 0 }];
      criteria = criteria.map((item, index) => ({
        uniqueId: item?.id ?? index,
        originalId: item?.selection_criteria ?? undefined,
        selection_criteria: item?.selection_criteria ?? undefined,
        description: item?.description ?? undefined,
        weight: item?.weight ?? undefined,
      }));
      criteria.forEach((item) => {
        criteriaFormValues[getKeyName("selection_criteria", item.uniqueId)] = item.selection_criteria;
        criteriaFormValues[getKeyName("selection_criteria_description", item.uniqueId)] = item.description;
        criteriaFormValues[getKeyName("selection_criteria_weight", item.uniqueId)] = item.weight;
      });

      formValues = {
        ...formValues,
        title: project?.title ?? "",
        clarification_request_deadline_date: new Date(project?.clarification_request_deadline_date),
        deadline_date: new Date(project?.deadline_date),
        notif_results_date: new Date(project?.notif_results_date),
        has_weighting: getBooleanString(project?.has_weighting),
        is_cn_substitute: project?.is_cn_substitute,
        cn_template: { id: undefined, file_field: project?.cn_template },
        sectors: sectors.filter((sector) => project?.specializations?.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.specializations?.map((item) => item.id),
        ...criteriaFormValues,
      };

      stateValues = {
        ...stateValues,
        criteria,
      };
      break;
    case PROJECT_TYPES.DIRECT:
      formValues = {
        ...formValues,
        title: project?.title ?? "",
        ds_attachment: project?.direct_selected_partners?.at(0)?.ds_attachment,
        ds_justification_select: project?.direct_selected_partners?.at(0)?.ds_justification_select,
        is_cn_required: project?.direct_selected_partners?.at(0)?.is_cn_required,
        justification_reason: project?.direct_selected_partners?.at(0)?.justification_reason,
        partner: {
          id: project?.direct_selected_partners?.at(0)?.partner_id,
          legal_name: project?.direct_selected_partners?.at(0)?.partner_name,
        },
        sectors: sectors.filter((sector) => project?.specializations?.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.specializations?.map((item) => item.id),
      };
      break;
    case PROJECT_TYPES.UNSOLICITED:
      formValues = {
        ...formValues,
        agency: project?.agency?.id,
        cn: project?.cn,
        title: project?.proposal_of_eoi_details?.title ?? "",
        ds_attachment: project?.direct_selected_partners?.at(0)?.ds_attachment,
        ds_justification_select: project?.direct_selected_partners?.at(0)?.ds_justification_select,
        is_cn_required: project?.is_cn_required,
        justification_reason: project?.direct_selected_partners?.at(0)?.justification_reason,
        partner: {
          id: project?.direct_selected_partners?.at(0)?.partner_id,
          legal_name: project?.direct_selected_partners?.at(0)?.partner_name,
        },
        sectors: sectors.filter((sector) => project?.proposal_of_eoi_details?.specializations.find((specialization) => specialization.category === sector.id)).map((item) => item.id),
        areas: project?.proposal_of_eoi_details?.specializations?.map((item) => item.id),
      };
      break;
    default:
      break;
  }
  return {
    formValues,
    stateValues,
  };
};

export const getFileName = (fileField) => fileField?.split("/").pop();

export const adaptFocalPointsOptions = (focalPoints) =>
  focalPoints.map((focalPoint) => ({
    ...focalPoint,
    label: `${focalPoint.name} (${focalPoint.email})`,
  }));

import React from "react";
import Table from "@local/components/table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getUsersColumns } from "../../utils/columns";

// POST_REFACTORING_TODO: Permissions and action buttons if needed

const Users = ({ fetchFunction }) => {
  return <Table key="partner_users" id="partner_users" title={formatMessage({ id: "pages.partner.details.cmp.users.idx.users" })} columns={getUsersColumns()} fetchFunction={fetchFunction} />;
};

Users.propTypes = {};

export default Users;

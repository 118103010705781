import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";

const RegistrationAgencyModal = ({ isOpen, onClose, onSubmit }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.login.cmp.registration.agency.modal.idx.title",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.login.cmp.registration.agency.modal.idx.register",
              })}
            />
          </div>
        </>
      }
      small
    >
      <span>
        {formatMessage({
          id: "pages.login.cmp.registration.agency.modal.idx.description",
        })}
      </span>
    </Modal>
  );
};

RegistrationAgencyModal.propTypes = {};

export default RegistrationAgencyModal;

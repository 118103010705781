import React from "react";
import FeedbackItem from "@local/components/feedback-item";

const DirectProjectFeedback = ({ isDirect, allowedToAddDirectFeedback, applications, feedback, isCompleted }) => {
  return (
    <div>
      {applications?.map((application) => {
        return (
          <FeedbackItem
            key={`direct_application_feedback_${application?.id}`}
            applicationId={`${application?.id}`}
            extraTitle={application?.legal_name}
            cn={isDirect ? application?.cn?.file_field : null}
            allowedToAddFeedback={allowedToAddDirectFeedback}
            feedback={feedback}
            isCompleted={isCompleted}
          />
        );
      })}
    </div>
  );
};

DirectProjectFeedback.propTypes = {};

export default DirectProjectFeedback;

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import Badge from "@mui/material/Badge";
import { Field } from "redux-form";
import { isRequired } from "@local/utils/validators";
import FieldRadioGroup from "@local/components/field-radio-group/index";

const PartnerDeclarationQuestion = ({ text, nameField, id, isPartnerDeclarationRequired, formValues }) => {
  return (
    <div className={style.questionContainer}>
      <Badge anchorOrigin={{ vertical: "top", horizontal: "left" }} badgeContent={id} color="primary" max={12} className={style.badge}>
        <div className={style.questionWrapper}>
          <div className={style.questionOptionWrapper}>
            <Field
              component={FieldRadioGroup}
              validate={[isRequired]}
              small
              fullWidthLabel
              id={nameField}
              name={nameField}
              label={text}
              placeholder={text}
              options={[
                {
                  value: true,
                  label: formatMessage({
                    id: "cmp.partner.declaration.question.idx.yes",
                  }),
                },
                {
                  value: false,
                  label: formatMessage({
                    id: "cmp.partner.declaration.question.idx.no",
                  }),
                },
              ]}
            />
          </div>
          {formValues[nameField] === "false" ? <span className={style.errorMessage}>{isPartnerDeclarationRequired}</span> : undefined}
        </div>
      </Badge>
    </div>
  );
};

export default PartnerDeclarationQuestion;

import React, { useState } from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Grid from "@mui/material/Grid";
import step1 from "@local/assets/images/step1.png";
import step2 from "@local/assets/images/step2.png";
import step3 from "@local/assets/images/step3.png";
import style from "./style.module.scss";

const steps = [
  { id: 1, label: "" },
  { id: 2, label: "" },
  { id: 3, label: "" },
];

const LoginIdentityModal = ({ isOpen, onClose, onSubmit }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <p>
                  {formatMessage({
                    id: "pages.login.cmp.login.identity.modal.idx.modalContentStep1Part1",
                  })}{" "}
                  <b>
                    {formatMessage({
                      id: "pages.login.cmp.login.identity.modal.idx.migration",
                    })}
                  </b>
                  ,
                </p>
                <p>
                  {formatMessage({
                    id: "pages.login.cmp.login.identity.modal.idx.modalContentStep1Part2",
                  })}{" "}
                  <b>
                    {formatMessage({
                      id: "pages.login.cmp.login.identity.modal.idx.followingSteps",
                    })}{" "}
                  </b>
                </p>
                <p>
                  {formatMessage({
                    id: "pages.login.cmp.login.identity.modal.idx.modalContentStep1Part3",
                  })}{" "}
                  <b>
                    "
                    {formatMessage({
                      id: "pages.login.cmp.login.identity.modal.idx.verifyEmail",
                    })}{" "}
                    "
                  </b>
                </p>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={style.identityImage}>
                <img alt="login" src={step1} />
              </div>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <p>
                  {formatMessage({
                    id: "pages.login.cmp.login.identity.modal.idx.modalContentStep2",
                  })}
                  <b>
                    "
                    {formatMessage({
                      id: "pages.login.cmp.login.identity.modal.idx.continue",
                    })}
                    "
                  </b>
                </p>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={style.identityImage}>
                <img alt="login" src={step2} />
              </div>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div>
                <p>
                  {formatMessage({
                    id: "pages.login.cmp.login.identity.modal.idx.newPassword",
                  })}{" "}
                  <b>
                    "
                    {formatMessage({
                      id: "pages.login.cmp.login.identity.modal.idx.continue",
                    })}
                    "
                  </b>
                </p>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className={style.identityImage}>
                <img alt="login" src={step3} />
              </div>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.login.cmp.login.identity.modal.idx.title",
      })}
    >
      <Box>
        <Stepper activeStep={activeStep}>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={step.id} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <></>
        ) : (
          <>
            <div className={style.contentWrapper}>{getStepContent(activeStep)}</div>
            <div className={style.buttonsWrapper}>
              <Button
                text={formatMessage({
                  id: "pages.login.cmp.login.identity.modal.idx.back",
                })}
                color="inherit"
                isDisabled={activeStep === 0}
                onClick={handleBack}
              />
              <Button
                text={
                  activeStep === steps.length - 1
                    ? formatMessage({
                        id: "pages.login.cmp.login.identity.modal.idx.confirm",
                      })
                    : formatMessage({
                        id: "pages.login.cmp.login.identity.modal.idx.next",
                      })
                }
                onClick={activeStep === steps.length - 1 ? onSubmit : handleNext}
              />
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

LoginIdentityModal.propTypes = {};

export default LoginIdentityModal;

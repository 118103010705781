import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import { isRequired } from "@local/utils/validators";
import FieldText from "@local/components/field-text";

const EditPersonResponsibleModal = ({ onCancel, onSubmit, isOpen, isFormValid, selectedActivity }) => {
  const onClickSubmit = () => {
    onSubmit(selectedActivity);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={formatMessage({
        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.person.responsible.modal.idx.EditActivityTitle",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onCancel}
              variant="text"
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.person.responsible.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={!isFormValid}
              onClick={onClickSubmit}
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.person.responsible.modal.idx.button",
              })}
            />
          </div>
        </>
      }
      small
    >
      <FieldGroup>
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="person_responsible"
          name="person_responsible"
          label={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.person.responsible.modal.idx.pseaCsipActivityPersonResponsible",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.core.standard.cmp.edit.person.responsible.modal.idx.pseaCsipActivityPersonResponsible",
          })}
          type="text"
        />
      </FieldGroup>
    </Modal>
  );
};

EditPersonResponsibleModal.propTypes = {};

export default reduxForm({
  form: "editPersonResponsibleForm",
  enableReinitialize: true,
})(EditPersonResponsibleModal);

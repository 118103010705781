import React from "react";
import { connect } from "react-redux";
import Modal from "@local/components/modal";
import Chip from "@mui/material/Chip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid, Typography } from "@mui/material";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CheckboxesList from "@local/components/checkboxes-list";
import { adaptCountries } from "@local/utils/adapters";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import { getStatusColor } from "@local/pages/partner-details/components/risk/utils/index";
import { adaptPartnerCountries } from "@local/pages/partner-details/components/risk/utils/adapters";
import style from "./style.module.scss";

const AssessmentDetailsModal = ({ assessment, countries, isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.assessmentDetailsModalTitle",
      })}
    >
      <div>
        <div className={style.headerWrapper}>
          <div className={style.headerContent}>
            <Typography variant="subtitle2">{assessment?.report_name}</Typography>
            <Typography variant="subtitle1">{assessment?.id}</Typography>
          </div>
          <div>
            <Typography variant="subtitle2">
              {formatMessage({
                id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.evaluatedRisk",
              })}
              <Chip className={style.chipContent} icon={<CircleIcon style={{ fill: getStatusColor(assessment?.risk_level) }} fontSize="small" />} label={assessment?.risk_level} />
            </Typography>
          </div>
        </div>
        <div>
          <div className={style.contentWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.country",
                    })}
                  </Typography>
                  <Typography variant="body2">{assessment && assessment?.operating_country && adaptPartnerCountries(assessment?.operating_country, countries)}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.agencyName",
                    })}
                  </Typography>
                  <Typography variant="body2">{assessment?.partner_vendor?.agency?.name}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.assessmentType",
                    })}
                  </Typography>
                  <Typography variant="body2">{assessment?.assessment_type}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.assessmentDate",
                    })}
                  </Typography>
                  <Typography variant="body2">{formatDateForPrint(assessment?.created)}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.attachments",
                    })}
                  </Typography>
                  <Typography variant="body2">
                    <Chip icon={<AttachFileIcon style={{ fill: "#808080" }} fontSize="small" />} label={assessment?.attachments?.length} />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {assessment?.attachments?.length > 0 && (
          <div xs={12}>
            <Typography variant="subtitle2">
              {formatMessage({
                id: "pages.partner.details.cmp.risk.cmp.assessment.cmp.modal.idx.downloadDocument",
              })}
            </Typography>
            <div>
              <CheckboxesList items={assessment?.attachments} />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

AssessmentDetailsModal.propTypes = {};

const mapStateToProps = (state) => ({
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
});

export default connect(mapStateToProps, null)(AssessmentDetailsModal);

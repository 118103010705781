import React from "react";
import Main from "./components/main";
import Container from "./components/container";
import { connect } from "react-redux";

const Faq = ({ role }) => {
  return (
    <Container>
      <Main role={role} />
    </Container>
  );
};

Faq.propTypes = {};

const mapStateToProps = (state) => ({
  role: state.session.role,
});

export default connect(mapStateToProps, undefined)(Faq);

import React from "react";
import PropTypes from "prop-types";
import style from "./style.module.scss";

const FieldGroup = ({ children }) => {
  return <div className={style.mainWrapper}>{children}</div>;
};

FieldGroup.propTypes = {
  children: PropTypes.any.isRequired,
};

export default FieldGroup;

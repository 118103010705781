import React from "react";
import { connect } from "react-redux";
import { Grid, Typography, Chip } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Modal from "@local/components/modal";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import CheckboxesList from "@local/components/checkboxes-list";
import { adaptCountries } from "@local/utils/adapters";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import { adaptPartnerCountries } from "@local/pages/partner-details/components/risk/utils/adapters";
import style from "./style.module.scss";

const tabsContent = (audit) => [
  {
    id: 0,
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditDetailsTabDocuments",
    }),
    name: "documents",
    content: audit?.attachments?.length > 0 && (
      <div xs={12}>
        <Typography variant="subtitle2">
          {formatMessage({
            id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.downloadDocument",
          })}
        </Typography>
        <div>
          <CheckboxesList items={audit?.attachments} />
        </div>
      </div>
    ),
  },
  {
    id: 1,
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditDetailsTabKeyFindings",
    }),
    name: "keyFindings",
    content: (
      <div xs={12}>
        <span>{audit?.key_findings}</span>
      </div>
    ),
  },
  {
    id: 2,
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditDetailsTabIneligibleExpenses",
    }),
    name: "ineligibleExpenses",
    content: (
      <div xs={12}>
        <span>{audit?.ineligible_expenses}</span>
      </div>
    ),
  },
  {
    id: 3,
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditDetailsTabCompliance",
    }),
    name: "compliance",
    content: (
      <div xs={12}>
        <span>{audit?.compliance}</span>
      </div>
    ),
  },
  {
    id: 4,
    path: "recommendations",
    label: formatMessage({
      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditDetailsTabRecommendations",
    }),
    name: "recommendations",
    content: (
      <div xs={12}>
        <span>{audit?.recommendations}</span>
      </div>
    ),
  },
];

const AuditDetailsModal = ({ audit, countries, isOpen, onClose, tabs, index, handleChange }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditDetailsModalTitle",
      })}
    >
      <div>
        <div className={style.headerWrapper}>
          <div className={style.headerContent}>
            <Typography variant="subtitle2">{audit?.report_name}</Typography>
            <Typography variant="subtitle1">{audit?.id}</Typography>
          </div>
        </div>
        <div>
          <div className={style.contentWrapper}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.reportType",
                    })}
                  </Typography>
                  <Typography variant="body2">{audit?.report_type}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.country",
                    })}
                  </Typography>
                  <Typography variant="body2">{audit?.operating_country && adaptPartnerCountries(audit?.operating_country, countries)}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.agencyName",
                    })}
                  </Typography>
                  <Typography variant="body2">{audit?.partner_vendor?.agency?.name}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditedBy",
                    })}
                  </Typography>
                  <Typography variant="body2">{audit?.partner_vendor?.partner?.legal_name}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditDate",
                    })}
                  </Typography>
                  <Typography variant="body2">{formatDateForPrint(audit?.audit_date)}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.opinion",
                    })}
                  </Typography>
                  <Typography variant="body2">
                    <span className={style.ellipsisLabel}>{audit?.opinion}</span>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.type",
                    })}
                  </Typography>
                  <Typography variant="body2">
                    <span>
                      {audit?.is_special
                        ? formatMessage({
                            id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.typeSpecial",
                          })
                        : formatMessage({
                            id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.typeRegular",
                          })}
                    </span>
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.auditPeriod",
                    })}
                  </Typography>
                  <Typography variant="body2">{audit?.audit_period}</Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div>
                  <Typography variant="subtitle2" component="body2">
                    {formatMessage({
                      id: "pages.partner.details.cmp.risk.cmp.audit.cmp.modal.idx.attachments",
                    })}
                  </Typography>
                  <Typography variant="body2">
                    <Chip icon={<AttachFileIcon style={{ fill: "#808080" }} fontSize="small" />} label={audit?.attachments?.length} />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <TabContext value={index}>
          {tabs ? (
            <div>
              <Tabs value={index} onChange={handleChange} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile>
                {tabs.map((tab, index) => (
                  <Tab key={`audit_navigation_tab_${index}`} label={tab.label} disabled={tab.isDisabled} />
                ))}
              </Tabs>
            </div>
          ) : undefined}
          {tabsContent(audit).map((tab, index) => (
            <TabPanel value={tab?.id}>{tab?.content}</TabPanel>
          ))}
        </TabContext>
      </div>
    </Modal>
  );
};

AuditDetailsModal.propTypes = {};

const mapStateToProps = (state) => ({
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
});

export default connect(mapStateToProps, null)(AuditDetailsModal);

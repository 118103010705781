import React from "react";
import Button from "@mui/material/Button";
import style from "./style.module.scss";
import { getMainColor } from "@local/styles/muiTheme";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

const getColorByType = (type) => {
  switch (type) {
    case "info":
      return getMainColor();
    case "success":
      return "#099854";
    case "warning":
      return "#FF9900";
    default:
      return getMainColor();
  }
};

const getIconByType = (type) => {
  switch (type) {
    case "info":
      return <InfoOutlinedIcon style={{ fill: getMainColor(), fontSize: "1.5rem" }} />;
    case "success":
      return <CheckCircleOutlineOutlinedIcon style={{ fill: "#099854", fontSize: "1.5rem" }} />;
    case "warning":
      return <WarningIcon style={{ fill: "#FF9900", fontSize: "1.5rem" }} />;
    default:
      return <InfoOutlinedIcon style={{ fill: getMainColor(), fontSize: "1.5rem" }} />;
  }
};

const Alert = ({ type, content, actionLabel, onClickAction, withBottomMargin }) => {
  const color = getColorByType(type);
  return (
    <div style={{ marginBottom: withBottomMargin ? "1rem" : "0" }}>
      <div
        className={style.mainWrapper}
        style={{
          borderColor: `${color}44`,
          backgroundColor: `${color}11`,
        }}
      >
        <div className={style.iconWrapper}>{getIconByType(type)}</div>
        <div className={style.textWrapper}>
          <span style={{ color }}>{content}</span>
        </div>
        <div style={{ color }}>
          {onClickAction ? (
            <Button color="inherit" size="small" onClick={onClickAction}>
              {actionLabel}
            </Button>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

Alert.propTypes = {};

export default Alert;

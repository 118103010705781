import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { loadCountries } from "@local/legacy-reducers/countries";
import { loadPartnerConfig } from "@local/legacy-reducers/partnerProfileConfig";
import { loadSectors } from "@local/legacy-reducers/sectors";
import getTheme from "@local/styles/muiTheme";
import Snackbar from "@mui/material/Snackbar";
import { errorToBeCleared } from "../../legacy-reducers/errorReducer";
import { selectAllErrorsMapped } from "../../store";

const SnackbarContainer = (props) => {
  const { errors, children, clearError } = props;
  return (
    <div>
      {children}
      {errors.map((error, index) => (
        <Snackbar
          key={`snackbar_${error.id}_${index}`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open
          message={error.userMessage}
          autoHideDuration={4e3 * (errors.length - index)}
          onClose={() => {
            clearError(error.id);
          }}
        />
      ))}
    </div>
  );
};

SnackbarContainer.propTypes = {};

const ConnectedSnackbarContainer = connect(
  (state) => ({
    errors: selectAllErrorsMapped(state),
  }),
  (dispatch) => ({
    clearError: (id) => dispatch(errorToBeCleared(id)),
  }),
)(SnackbarContainer);

class Main extends Component {
  constructor() {
    super();
    this.state = {
      configLoaded: false,
      error: null,
    };
  }

  componentDidMount() {
    const { getCountries, getPartnerConfig, getSectors } = this.props;
    const configPromises = [getCountries(), getPartnerConfig(), getSectors()];
    Promise.all(configPromises)
      .then(() => {
        this.setState({ configLoaded: true });
      })
      .catch((error) => {
        this.setState({ error });
      });
  }

  render() {
    const { children } = this.props;
    return (
      <ThemeProvider theme={getTheme()}>
        <ConnectedSnackbarContainer>{this.state.configLoaded && children}</ConnectedSnackbarContainer>
      </ThemeProvider>
    );
  }
}

Main.propTypes = {
  children: PropTypes.node,
  getCountries: PropTypes.func,
  getSectors: PropTypes.func,
  getPartnerConfig: PropTypes.func,
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: () => dispatch(loadCountries()),
  getSectors: () => dispatch(loadSectors()),
  getPartnerConfig: () => dispatch(loadPartnerConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);

import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import Alert from "@local/components/alert";
import FieldText from "@local/components/field-text";
import FieldCheckbox from "@local/components/field-checkbox";
import style from "./style.module.scss";
import { isNumeric, isRequired, isRequiredTrue } from "@local/utils/validators";

const ManageReviewModal = ({ title, isOpen, onClose, onSubmit, isSingleReviewer, partnerName, criteria, selectionCriteria, isFormValid }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      footer={
        <>
          <div>
            <Button
              onClick={onClose}
              variant="text"
              text={formatMessage({
                id: "pages.application.details.cmp.manage.review.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.application.details.cmp.manage.review.modal.idx.save",
              })}
              isDisabled={!isFormValid}
            />
          </div>
        </>
      }
    >
      <Alert
        type="info"
        content={`${formatMessage({
          id: "pages.application.details.cmp.manage.review.modal.idx.header",
        })}: ${partnerName}`}
        withBottomMargin
      />
      <div>
        <div className={style.caption}>
          <span>
            {formatMessage({
              id: "pages.application.details.cmp.manage.review.modal.idx.criteria",
            })}
          </span>
          <span>
            {formatMessage({
              id: "pages.application.details.cmp.manage.review.modal.idx.score",
            })}
          </span>
        </div>
        <FieldGroup>
          <div className={style.criteriaWrapper}>
            {criteria?.map((item) => (
              <div key={`criteria_item_${item.selection_criteria}`} className={style.criteriaItemWrapper}>
                <div>
                  <span>
                    {selectionCriteria.find((element) => element.value === item.selection_criteria.slice(0, 3))?.label} - {item.description}
                  </span>
                </div>
                <div>
                  <div>
                    <Field
                      small
                      component={FieldText}
                      validate={[isNumeric, isRequired]}
                      id={`score_${item.selection_criteria}`}
                      name={`score_${item.selection_criteria}`}
                      label={formatMessage({
                        id: "pages.application.details.cmp.manage.review.modal.idx.scoreOne",
                      })}
                      placeholder={formatMessage({
                        id: "pages.application.details.cmp.manage.review.modal.idx.scoreOne",
                      })}
                    />
                  </div>
                  {item.weight ? (
                    <div className={style.criteriaItemWeightWrapper}>
                      <span>/ {item.weight}</span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </div>
          <Field
            component={FieldText}
            id="note"
            name="note"
            label={formatMessage({
              id: "pages.application.details.cmp.manage.review.modal.idx.note",
            })}
            placeholder={formatMessage({
              id: "pages.application.details.cmp.manage.review.modal.idx.notePlaceholder",
            })}
          />
          {isSingleReviewer ? (
            <Field
              component={FieldCheckbox}
              validate={[isRequiredTrue]}
              id="is_a_committee_score"
              name="is_a_committee_score"
              label={formatMessage({
                id: "pages.application.details.cmp.manage.review.modal.idx.confirmReview",
              })}
            />
          ) : (
            <></>
          )}
        </FieldGroup>
      </div>
    </Modal>
  );
};

ManageReviewModal.propTypes = {};

export default reduxForm({
  form: "manageReviewForm",
  enableReinitialize: true,
})(ManageReviewModal);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import SectionContainer from "../section-container";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Container from "./components/container";
import CoreStandard from "./components/core-standard";
import Actions from "./components/actions";
import UploadDocumentModal from "./components/upload-document-modal";
import ExtendDeadlineModal from "./components/extend-deadline-modal";
import ActivityModal from "./components/activity-modal";
import { reset, isValid } from "redux-form";
import { connect } from "react-redux";
import {
  startPseaCsip,
  getPseaCsipDetails,
  createPseaCsipCoreStandardDocument,
  deletePseaCsipCoreStandardDocument,
  createPseaCsipCoreStandardActivity,
  updatePseaCsipCoreStandardActivity,
  updatePseaCsipCoreStandardComment,
  extendPseaCsipDeadline,
  completePseaCsip,
  getPseaCsipActivitiesList,
  getPseaDetails,
} from "@local/actions";
import Button from "@local/components/button";
import { getPseaCoreStandards, getPseaCoreStandardMonitoringStatuses } from "@local/utils/constants";
import { formatDateToBeSent } from "@local/utils";
import { toast } from "react-toastify";
import Loader from "@local/components/loader";
import { checkPermission, AGENCY_PERMISSIONS, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import { ROLES } from "@local/legacy-utils/constants";
import add from "date-fns/add";
import { formatDate } from "@local/pages/partner-details/components/psea/utils";
import { useTranslation } from "react-i18next";

const Csip = ({
  resetDocumentForm,
  resetActivityForm,
  resetExtendDeadlineForm,
  isDocumentFormValid,
  isActivityFormValid,
  isExtendFormValid,
  isEditTargetDateFormValid,
  isEditPersonResponsibleFormValid,
  isEditCommentFormValid,
  startPseaCsip,
  getPseaCsipDetails,
  getPseaCsipCoreStandardDocuments,
  createPseaCsipCoreStandardDocument,
  deletePseaCsipCoreStandardDocument,
  createPseaCsipCoreStandardActivity,
  updatePseaCsipCoreStandardActivity,
  updatePseaCsipCoreStandardComment,
  pseaCsipDetails,
  role,
  coreStandards,
  activityModalFormValues,
  activityMonitoringStatuses,
  documentModalFormValues,
  extendDeadlineModalFormValues,
  editTargetDateFormValues,
  editPersonResponsibleFormValues,
  editCommentFormValues,
  isLoading,
  extendPseaCsipDeadline,
  completePseaCsip,
  hasExtendPseaCsipDeadlinePermission,
  hasEditPseaCsipActivitiesPermission,
  hasViewPseaCsipActivitiesMonitoringStatusPermission,
  hasEditPseaCsipActivitiesMonitoringStatusPermission,
  hasViewPseaCsipDocumentsPermission,
  hasEditPseaCsipDocumentsPermission,
  hasCompletePseaCsipPermission,
  pseaCsipActivities,
  getPseaCsipActivitiesList,
  pseaCsipCoreStandardActivityFormValues,
  getPseaDetails,
  revisionToJustifySixMonths,
  revisionToJustifyNineMonths,
  partnerId,
  resetCommentForm,
}) => {
  const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const [isStatusEnabled, setStatusEnabled] = useState(true);
  const [isExtendDeadlineModalVisible, setIsExtendDeadlineModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [warningMessage, setWarningMessage] = useState(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    getPseaCsipDetails();
    getPseaCsipActivitiesList();
  }, [getPseaCsipActivitiesList, getPseaCsipDetails]);

  useEffect(() => {
    if (
      role === ROLES.AGENCY &&
      ((revisionToJustifySixMonths &&
        !revisionToJustifySixMonths.justification_to_proceed &&
        !revisionToJustifyNineMonths &&
        (revisionToJustifySixMonths?.revision_rating === "low" || revisionToJustifySixMonths?.revision_rating === "medium" || revisionToJustifySixMonths?.revision_rating === "not_eligible")) ||
        (revisionToJustifyNineMonths &&
          !revisionToJustifyNineMonths.justification_to_proceed &&
          (revisionToJustifyNineMonths?.revision_rating === "low" || revisionToJustifyNineMonths?.revision_rating === "medium" || revisionToJustifyNineMonths?.revision_rating === "not_eligible")))
    ) {
      setWarningMessage({
        title: formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.initialAssessmentWarningBannerTitle",
        }),
        tooltip: formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.initialAssessmentWarningBannerTooltip",
        }),
      });
    } else {
      setWarningMessage(undefined);
    }
  }, [revisionToJustifySixMonths?.justification_to_proceed, revisionToJustifyNineMonths?.justification_to_proceed]);

  const onClickStartPseaCsip = async () => {
    await startPseaCsip();
    getPseaCsipDetails();
  };

  const onClickUploadDocument = () => {
    setIsDocumentModalVisible(true);
  };

  const onClickCancelUploadDocument = () => {
    setIsDocumentModalVisible(false);
    resetDocumentForm();
  };

  const onClickCancelExtendDeadline = () => {
    setIsExtendDeadlineModalVisible(false);
    resetExtendDeadlineForm();
  };

  const onClickSubmitUploadDocument = async () => {
    try {
      await createPseaCsipCoreStandardDocument(pseaCsipDetails?.core_standards[selectedTab]?.id, {
        file: documentModalFormValues?.file?.id,
      });
      getPseaCsipDetails();
      setIsDocumentModalVisible(false);
      resetDocumentForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickAddActivity = () => {
    setIsActivityModalVisible(true);
  };

  const onClickCancelAddActivity = () => {
    setIsActivityModalVisible(false);
    resetActivityForm();
  };

  const onClickDeleteDocument = async (item) => {
    try {
      await deletePseaCsipCoreStandardDocument(pseaCsipDetails?.core_standards[selectedTab]?.id, item.id);
      getPseaCsipDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickSubmitAddActivity = async () => {
    try {
      await createPseaCsipCoreStandardActivity(pseaCsipDetails?.core_standards[selectedTab]?.id, {
        activity: activityModalFormValues.activity,
        title: activityModalFormValues.title,
        person_responsible: activityModalFormValues.person_responsible,
        target_date: formatDateToBeSent(activityModalFormValues.target_date),
        status: "not_started",
        is_required: activityModalFormValues?.is_required ?? false,
      });
      setIsActivityModalVisible(false);
      resetActivityForm();
      getPseaCsipDetails();
    } catch (error) {
      toast.error(error?.response?.data?.failed);
    }
  };

  const onClickExtendDeadline = () => {
    setIsExtendDeadlineModalVisible(true);
  };

  const onSubmitEditTargetDate = async (selectedActivity) => {
    try {
      await updatePseaCsipCoreStandardActivity(pseaCsipDetails?.core_standards[selectedTab]?.id, selectedActivity?.id, {
        target_date: formatDateToBeSent(editTargetDateFormValues?.target_date),
      });
      getPseaCsipDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };
  const onSubmitEditPersonResponsible = async (selectedActivity) => {
    try {
      await updatePseaCsipCoreStandardActivity(pseaCsipDetails?.core_standards[selectedTab]?.id, selectedActivity?.id, {
        person_responsible: editPersonResponsibleFormValues?.person_responsible,
      });
      getPseaCsipDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };

  const onChangeStatus = async (activity, status) => {
    try {
      setStatusEnabled(false);
      await updatePseaCsipCoreStandardActivity(pseaCsipDetails?.core_standards[selectedTab]?.id, activity?.id, {
        status,
      });
      setStatusEnabled(true);
      getPseaCsipDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };

  const onSubmitEditComment = async () => {
    try {
      await updatePseaCsipCoreStandardComment(pseaCsipDetails?.core_standards[selectedTab]?.id, { comment: editCommentFormValues?.comment });
      getPseaCsipDetails();
      resetCommentForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickSubmitExtendDeadline = async () => {
    try {
      await extendPseaCsipDeadline(extendDeadlineModalFormValues?.extension_justification);
      getPseaCsipDetails();
      setIsExtendDeadlineModalVisible(false);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickCompletePseaCsip = async () => {
    try {
      await completePseaCsip();
      getPseaCsipDetails();
      getPseaDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.unableToSaveData",
        }),
      );
    }
  };

  const onChangeTab = (event, index) => {
    setSelectedTab(index);
    getPseaCsipCoreStandardDocuments(pseaCsipDetails.core_standards[index]?.id);
  };

  const getPseaCoreStandardActivities = () =>
    pseaCsipActivities
      ?.filter((item) => item.core_standard === pseaCsipDetails?.core_standards?.[selectedTab]?.core_standard || item.core_standard === "ALL")
      .map((item) => (item.required === true ? { ...item, description: `${t(item.description)} *` } : { ...item, description: `${t(item.description)}` }));

  let content = <></>;

  if (isLoading || !pseaCsipDetails) content = <Loader isVisible />;

  if (!pseaCsipDetails.start_date) {
    if (role === ROLES.AGENCY) {
      content = (
        <>
          <Button
            isDisabled={!hasEditPseaCsipActivitiesPermission}
            onClick={onClickStartPseaCsip}
            text={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.startCsip",
            })}
          />
          <small
            style={{
              display: "block",
              marginTop: ".5rem",
              width: "80%",
              color: "#555555",
            }}
          >
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.startCsipInformationText",
            })}
          </small>
        </>
      );
    } else {
      content = (
        <>
          <small style={{ width: "80%" }}>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.csipNotStartedMessage",
            })}
          </small>
        </>
      );
    }
  } else {
    content = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {role === ROLES.AGENCY && pseaCsipDetails?.extension_justification ? (
            <div style={{ marginBottom: "1.5rem" }}>
              <b style={{ marginRight: ".5rem" }}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.extendCsipJustificationLabel",
                })}
              </b>
              <span>{pseaCsipDetails?.extension_justification}</span>
            </div>
          ) : (
            <></>
          )}
          {role === ROLES.AGENCY ? (
            <div style={{ marginBottom: "1.5rem", marginLeft: "1.5rem" }}>
              {pseaCsipDetails?.is_extended ? (
                <div>
                  <div style={{ textAlign: "center" }}>
                    <span
                      style={{
                        marginBottom: ".25rem",
                        display: "inline-block",
                        fontWeight: "500",
                        color: "#333333",
                        borderRadius: "5px",
                        backgroundColor: "#eeeeee",
                        padding: ".25rem .5rem",
                        fontSize: ".85rem",
                      }}
                    >
                      {formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.extendedCsip",
                      })}
                    </span>
                  </div>
                  <div style={{ fontSize: ".75rem", textAlign: "center" }}>
                    <div>
                      <span
                        style={{
                          display: "inline-block",
                          marginRight: ".25rem",
                          color: "#555555",
                        }}
                      >
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.activitiesCompletedBy",
                        })}
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          marginRight: ".25rem",
                          fontWeight: "500",
                        }}
                      >
                        {pseaCsipDetails?.extended_by_agency}
                      </span>
                      <span
                        style={{
                          display: "inline-block",
                          marginRight: ".25rem",
                          color: "#555555",
                        }}
                      >
                        {formatMessage({
                          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.activitiesCompletedOn",
                        })}
                      </span>
                      <span style={{ display: "inline-block", fontWeight: "500" }}>{formatDate(pseaCsipDetails?.extended_on)}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <Button
                  onClick={onClickExtendDeadline}
                  text={formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.extendCsip",
                  })}
                  isDisabled={!hasExtendPseaCsipDeadlinePermission || add(new Date(pseaCsipDetails?.start_date), { months: 6 }) < new Date()}
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <Container
          onChangeTab={onChangeTab}
          selectedTab={selectedTab}
          tabs={pseaCsipDetails?.core_standards
            ?.sort((a, b) => a?.core_standard > b?.core_standard)
            ?.map((item) => ({
              id: item.id,
              label: coreStandards.find((coreStandard) => coreStandard.value === item?.core_standard)?.label,
              isCompleted: item.rating === "yes" || item.is_optional,
              isOptional: item.rating === "no" && item.is_optional,
            }))}
        >
          <CoreStandard
            isDraftCsip={pseaCsipDetails?.is_draft}
            onClickUploadDocument={onClickUploadDocument}
            onClickAddActivity={onClickAddActivity}
            coreStandard={pseaCsipDetails?.core_standards[selectedTab]}
            coreStandards={coreStandards}
            activityMonitoringStatuses={activityMonitoringStatuses}
            onClickDeleteDocument={onClickDeleteDocument}
            onSubmitEditTargetDate={onSubmitEditTargetDate}
            isEditTargetDateFormValid={isEditTargetDateFormValid}
            onSubmitEditPersonResponsible={onSubmitEditPersonResponsible}
            isEditPersonResponsibleFormValid={isEditPersonResponsibleFormValid}
            onSubmitEditComment={onSubmitEditComment}
            isEditCommentFormValid={isEditCommentFormValid}
            role={role}
            hasEditPseaCsipActivitiesPermission={hasEditPseaCsipActivitiesPermission}
            hasViewPseaCsipActivitiesMonitoringStatusPermission={hasViewPseaCsipActivitiesMonitoringStatusPermission}
            hasEditPseaCsipActivitiesMonitoringStatusPermission={hasEditPseaCsipActivitiesMonitoringStatusPermission}
            hasViewPseaCsipDocumentsPermission={hasViewPseaCsipDocumentsPermission}
            hasEditPseaCsipDocumentsPermission={hasEditPseaCsipDocumentsPermission}
            pseaCsipActivities={pseaCsipActivities}
            onChangeStatus={onChangeStatus}
            isStatusEnabled={isStatusEnabled}
            resetCommentForm={resetCommentForm}
          />
        </Container>
        <Actions
          role={role}
          onClickCompletePseaCsip={onClickCompletePseaCsip}
          pseaCsipDetails={pseaCsipDetails}
          hasCompletePseaCsipPermission={hasCompletePseaCsipPermission}
          revisionToJustifySixMonths={revisionToJustifySixMonths}
          revisionToJustifyNineMonths={revisionToJustifyNineMonths}
          partnerId={partnerId}
        />
      </>
    );
  }

  const warningMessages = [];

  if (pseaCsipDetails?.is_draft) {
    warningMessages.push({
      title: formatMessage({
        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.capacityStrengthDraftBannerTitle",
      }),
      tooltip:
        role === ROLES.PARTNER
          ? formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.capacityStrengthDraftBannerPartnerTooltip",
            })
          : formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.capacityStrengthDraftBannerTooltip",
            }),
    });
  }

  if (warningMessage) {
    warningMessages.push(warningMessage);
  }

  return (
    <>
      <SectionContainer
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.idx.capacityStrength",
        })}
        hasWarningIcon={pseaCsipDetails?.start_date && pseaCsipDetails?.core_standards?.find((cs) => cs.rating === "no")}
        warningMessages={warningMessages}
      >
        {content}
      </SectionContainer>
      <ActivityModal
        onCancel={onClickCancelAddActivity}
        onSubmit={onClickSubmitAddActivity}
        isOpen={isActivityModalVisible}
        isFormValid={isActivityFormValid}
        pseaCsipActivities={getPseaCoreStandardActivities() ?? []}
        formValues={pseaCsipCoreStandardActivityFormValues}
        coreStandard={pseaCsipDetails?.core_standards?.[selectedTab]}
      />
      <UploadDocumentModal onCancel={onClickCancelUploadDocument} onSubmit={onClickSubmitUploadDocument} isOpen={isDocumentModalVisible} isFormValid={isDocumentFormValid} />
      <ExtendDeadlineModal onCancel={onClickCancelExtendDeadline} onSubmit={onClickSubmitExtendDeadline} isOpen={isExtendDeadlineModalVisible} isFormValid={isExtendFormValid} />
    </>
  );
};

const mapStateToProps = (state) => {
  const role = state?.session?.role;

  return {
    isDocumentFormValid: isValid("pseaCsipCoreStandardDocumentForm")(state),
    isActivityFormValid: isValid("pseaCsipCoreStandardActivityForm")(state),
    isExtendFormValid: isValid("pseaCsipCoreStandardExtendDeadlineForm")(state),
    isEditTargetDateFormValid: isValid("editTargetDateForm")(state),
    isEditPersonResponsibleFormValid: isValid("editPersonResponsibleForm")(state),
    isEditCommentFormValid: isValid("editCommentForm")(state),
    pseaCsipCoreStandardActivityFormValues: state?.form?.pseaCsipCoreStandardActivityForm?.values ?? {},
    pseaCsipDetails: state.pseaCsipDetails.data?.data ?? {},
    isLoading: state.pseaCsipDetails.loading ?? false,
    pseaCsipActivities: state?.pseaCsipActivitiesList?.data?.data?.activities ?? [],
    role,
    coreStandards: getPseaCoreStandards(),
    activityModalFormValues: state?.form?.pseaCsipCoreStandardActivityForm?.values,
    documentModalFormValues: state?.form?.pseaCsipCoreStandardDocumentForm?.values,
    extendDeadlineModalFormValues: state?.form?.pseaCsipCoreStandardExtendDeadlineForm?.values,
    editTargetDateFormValues: state?.form?.editTargetDateForm?.values,
    editPersonResponsibleFormValues: state?.form?.editPersonResponsibleForm?.values,
    editCommentFormValues: state?.form?.editCommentForm?.values,
    activityMonitoringStatuses: getPseaCoreStandardMonitoringStatuses(),
    hasExtendPseaCsipDeadlinePermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.EXTEND_PSEA_CSIP_DEADLINE, state) : false,
    hasEditPseaCsipActivitiesPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.EDIT_PSEA_CSIP_ACTIVITIES, state) : false,
    hasViewPseaCsipActivitiesMonitoringStatusPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.VIEW_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS, state) : checkPermission(PARTNER_PERMISSIONS.VIEW_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS, state),
    hasEditPseaCsipActivitiesMonitoringStatusPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.EDIT_PSEA_CSIP_ACTIVITIES_MONITORING_STATUS, state) : false,
    hasViewPseaCsipDocumentsPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.VIEW_PSEA_CSIP_DOCUMENTS, state) : checkPermission(PARTNER_PERMISSIONS.VIEW_PSEA_CSIP_DOCUMENTS, state),
    hasEditPseaCsipDocumentsPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.EDIT_PSEA_CSIP_DOCUMENTS, state) : false,
    hasCompletePseaCsipPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.COMPLETE_PSEA_CSIP, state) : false,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetDocumentForm: () => dispatch(reset("pseaCsipCoreStandardDocumentForm")),
  resetActivityForm: () => dispatch(reset("pseaCsipCoreStandardActivityForm")),
  resetExtendDeadlineForm: () => dispatch(reset("pseaCsipCoreStandardExtendDeadlineForm")),
  resetCommentForm: () => dispatch(reset("editCommentForm")),
  startPseaCsip: () => dispatch(startPseaCsip(undefined, { partnerId: ownProps.partnerId })),
  getPseaCsipDetails: () => dispatch(getPseaCsipDetails(undefined, { partnerId: ownProps.partnerId })),
  createPseaCsipCoreStandardDocument: (coreStandardId, data) =>
    dispatch(
      createPseaCsipCoreStandardDocument(data, {
        partnerId: ownProps.partnerId,
        coreStandardId,
      }),
    ),
  deletePseaCsipCoreStandardDocument: (coreStandardId, id) =>
    dispatch(
      deletePseaCsipCoreStandardDocument({
        partnerId: ownProps.partnerId,
        coreStandardId,
        id,
      }),
    ),
  createPseaCsipCoreStandardActivity: (coreStandardId, data) =>
    dispatch(
      createPseaCsipCoreStandardActivity(data, {
        partnerId: ownProps.partnerId,
        coreStandardId,
      }),
    ),
  extendPseaCsipDeadline: (extension_justification) => dispatch(extendPseaCsipDeadline({ is_extended: true, extension_justification }, { partnerId: ownProps.partnerId })),
  updatePseaCsipCoreStandardActivity: (coreStandardId, activityId, data) =>
    dispatch(
      updatePseaCsipCoreStandardActivity(data, {
        partnerId: ownProps.partnerId,
        coreStandardId,
        activityId,
      }),
    ),
  updatePseaCsipCoreStandardComment: (coreStandardId, data) =>
    dispatch(
      updatePseaCsipCoreStandardComment(data, {
        partnerId: ownProps.partnerId,
        coreStandardId,
      }),
    ),
  completePseaCsip: () => dispatch(completePseaCsip({ submit: true }, { partnerId: ownProps.partnerId })),
  getPseaCsipActivitiesList: () => dispatch(getPseaCsipActivitiesList()),
  getPseaDetails: () => dispatch(getPseaDetails(undefined, { partnerId: ownProps.partnerId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Csip);

import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import style from "./style.module.scss";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { getColorByHighlightType } from "@local/utils";

const FieldRadioGroup = ({ id, label, options, small, readOnly, informationText, input: { value = null, onChange }, onChangeExtra, fullWidthLabel = false, fullWidthRadioElement = false, meta, highlightType }) => {
  const onChangeOption = (event, value) => {
    onChange(value);
    if (onChangeExtra) onChangeExtra(value);
  };

  const color = getColorByHighlightType(highlightType);

  return (
    <div
      style={{
        border: color ? `solid 1px ${meta?.error ? "#d32f2f" : "#b7b7b7"}` : undefined,
        backgroundColor: color ? `${color}29` : undefined,
        padding: color ? ".5rem .75rem" : undefined,
        borderRadius: "4px",
      }}
    >
      <FormControl error={(meta?.error ?? "") !== ""}>
        <RadioGroup row name={id} value={value} onChange={onChangeOption}>
          <div className={fullWidthLabel ? style.contentWrapperBlock : style.contentWrapper}>
            {label ? (
              <div className={style.labelWrapper}>
                <FormLabel size={small ? "small" : "normal"} component="legend">
                  {label}
                </FormLabel>
              </div>
            ) : undefined}
            {informationText ? (
              <div className={style.labelTooltipWrapper}>
                <Tooltip title={informationText}>
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
            ) : undefined}
            <div className={fullWidthRadioElement ? style.blockOptionsWrapper : style.optionsWrapper}>
              {options.map((item, index) =>
                item.informationText ? (
                  <div className={style.optionWrapper} key={`field_radio_group_${id}_${index}`}>
                    <div>
                      <Radio size={small ? "small" : "normal"} value={item.value} disabled={readOnly || item.disabled} />
                    </div>
                    <div>
                      <span className={style.optionLabel}>{item.label}</span>
                    </div>
                    <div className={style.tooltipWrapper}>
                      <Tooltip title={item.informationText}>
                        <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                      </Tooltip>
                    </div>
                  </div>
                ) : (
                  <div className={fullWidthRadioElement ? style.blockOptionWrapper : style.optionWrapper} key={`field_radio_group_${id}_${index}`}>
                    <div>
                      <Radio size={small ? "small" : "normal"} value={item.value} disabled={readOnly || item.disabled} />
                    </div>
                    <div>
                      <span className={style.optionLabel}>{item.label}</span>
                      {item?.extraLabel ? (
                        <div className={style.extraLabelWrapper}>
                          <span className={style.extraLabelText}>{item.extraLabel}</span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

FieldRadioGroup.propTypes = {};

FieldRadioGroup.defaultProps = {
  small: false,
};

export default FieldRadioGroup;

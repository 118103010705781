import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldText from "@local/components/field-text";
import FieldSelect from "@local/components/field-select";
import { reduxForm, Field } from "redux-form";
import { isRequired, isValidEmail } from "@local/utils/validators";
import FieldGroup from "@local/components/field-group/index";
import Grid from "@mui/material/Grid";
import FieldListInput from "@local/components/field-list-input";

const ManagePartnerModal = ({ isOpen, onClose, onSubmit, isValidSpecialPartnerForm, partnerTypes, formValues, countries, isTypeEditable = true }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.partners.list.cmp.manage.partner.modal.idx.title",
      })}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.partners.list.cmp.manage.partner.modal.idx.cancel",
            })}
          />
          <Button
            isDisabled={!isValidSpecialPartnerForm}
            onClick={onSubmit}
            text={formatMessage({
              id: "pages.partners.list.cmp.manage.partner.modal.idx.save",
            })}
          />
        </>
      }
    >
      <FieldGroup>
        <Field
          component={FieldSelect}
          validate={[isRequired]}
          id="display_type"
          name="display_type"
          label={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.projectDetailsType",
          })}
          placeholder={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.projectDetailsType",
          })}
          valueField="value"
          labelField="label"
          options={partnerTypes}
          readOnly={!isTypeEditable}
        />
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="legal_name"
          name="legal_name"
          label={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.legalName",
          })}
          placeholder={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.legalName",
          })}
          type="text"
        />
        <Field
          component={FieldListInput}
          highlightType="advanced"
          id="other_names"
          name="other_names"
          label={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.otherNames",
          })}
          placeholder={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.otherNames",
          })}
          emptyPlaceholder={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.otherNamesEmpty",
          })}
        />
        <Field
          component={FieldSelect}
          validate={[isRequired]}
          id="country_code"
          name="country_code"
          label={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.country",
          })}
          placeholder={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.country",
          })}
          valueField="value"
          labelField="label"
          options={countries}
        />
        <Field
          component={FieldText}
          validate={[isValidEmail]}
          id="org_email"
          name="org_email"
          label={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.organizationEmail",
          })}
          placeholder={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.organizationEmail",
          })}
          type="email"
        />
        <Field
          component={FieldRadioGroup}
          validate={[isRequired]}
          id="mailing_type"
          name="mailing_type"
          label={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.mailingType",
          })}
          placeholder={formatMessage({
            id: "pages.partners.list.cmp.manage.partner.modal.idx.mailingType",
          })}
          options={[
            {
              value: "Str",
              label: formatMessage({
                id: "pages.partners.list.cmp.manage.partner.modal.idx.streetAddress",
              }),
            },
            {
              value: "POB",
              label: formatMessage({
                id: "pages.partners.list.cmp.manage.partner.modal.idx.poBox",
              }),
            },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {formValues?.mailing_type === "POB" ? (
              <Field
                component={FieldText}
                validate={[isRequired]}
                id="po_box"
                name="po_box"
                label={formatMessage({
                  id: "pages.partners.list.cmp.manage.partner.modal.idx.poBoxNumber",
                })}
                type="text"
              />
            ) : (
              <Field
                component={FieldText}
                validate={[isRequired]}
                id="street"
                name="street"
                label={formatMessage({
                  id: "pages.partners.list.cmp.manage.partner.modal.idx.streetAddress",
                })}
                type="text"
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <Field
              component={FieldText}
              validate={[isRequired]}
              id="city"
              name="city"
              label={formatMessage({
                id: "pages.partners.list.cmp.manage.partner.modal.idx.city",
              })}
              placeholder={formatMessage({
                id: "pages.partners.list.cmp.manage.partner.modal.idx.city",
              })}
              type="text"
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={FieldText}
              id="zip_code"
              name="zip_code"
              label={formatMessage({
                id: "pages.partners.list.cmp.manage.partner.modal.idx.zipCode",
              })}
              placeholder={formatMessage({
                id: "pages.partners.list.cmp.manage.partner.modal.idx.zipCode",
              })}
              type="text"
            />
          </Grid>
        </Grid>
      </FieldGroup>
    </Modal>
  );
};

ManagePartnerModal.propTypes = {};

export default reduxForm({
  form: "specialPartnerModalForm",
  enableReinitialize: true,
})(ManagePartnerModal);

import React, { useState, useRef, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FormHelperText from "@mui/material/FormHelperText";

const FieldAutosuggest = ({ id, label, fetchFunction, adapterFunction, valueField, labelField, small, multiple, disabled, onChangeExtra, input: { value, onChange }, meta, helperText, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const timeout = useRef(undefined);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const isOptionEqualToValue = (option, value) => option[valueField] === value[valueField];
  const getOptionLabel = (option) => (option ? option[labelField] : "");

  useEffect(() => () => clearTimeout(timeout.current), []);

  const onChangeFilter = async (event) => {
    if (event) {
      const eventValue = event?.target?.value;
      setFilter(eventValue);
      clearTimeout(timeout.current);
      if (eventValue) {
        const id = setTimeout(async () => {
          try {
            setIsLoading(true);
            const result = await fetchFunction(eventValue);
            setOptions(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data?.results ?? []);
            setIsLoading(false);
          } catch (error) {
            toast.error(formatMessage({ id: "cmp.field.autosuggest.idx.error" }));
            setIsLoading(false);
          }
        }, 500);
        timeout.current = id;
      } else {
        setOptions([]);
      }
    }
  };

  const onChangeOption = (event, value) => {
    onChange(value);
    if (multiple) {
      setFilter("");
    }
    setOptions([]);
    if (onChangeExtra) onChangeExtra(value);
  };

  let computedValue = value;
  let properties = {};
  if (multiple) {
    properties = { inputValue: filter ?? "" };
    if (!value) {
      computedValue = [];
    }
  } else {
    if (!value) {
      computedValue = null;
    }
  }

  return (
    <div>
      <Autocomplete
        id={id}
        open={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={isLoading}
        onChange={onChangeOption}
        onInputChange={onChangeFilter}
        value={computedValue}
        multiple={multiple}
        disabled={disabled}
        noOptionsText={formatMessage({
          id: "cmp.field.autosuggest.idx.noOptions",
        })}
        renderInput={(params) => (
          <TextField
            error={(meta?.error ?? "") !== ""}
            {...params}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            size={small ? "small" : "normal"}
          />
        )}
        {...properties}
      />
      {helperText && !meta?.error ? (
        <FormHelperText sx={{ mt: "3px", mr: "14px", mb: "0", ml: "14px" }} id={`select_helper_text_${id}`}>
          {helperText}
        </FormHelperText>
      ) : (
        <></>
      )}
      {meta?.error ? (
        <FormHelperText sx={{ mt: "3px", mr: "14px", mb: "0", ml: "14px" }} error id={`field-text-helper-text-${id}`}>
          {meta?.error}
        </FormHelperText>
      ) : (
        <></>
      )}
    </div>
  );
};

FieldAutosuggest.propTypes = {};

export default FieldAutosuggest;

import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Alert from "@local/components/alert";
import FieldRadioGroup from "@local/components/field-radio-group";
import FieldText from "@local/components/field-text";
import { reduxForm, Field } from "redux-form";
import FlagIcon from "@local/components/flag-icon";
import { isRequired, isValidEmail } from "@local/utils/validators";
import FieldGroup from "@local/components/field-group";
import FieldSelect from "@local/components/field-select";
import FieldFile from "@local/components/field-file";
import Box from "@local/components/box";
import ShowHide from "@local/components/show-hide";
import { OBSERVATION_DECISION, ESCALATION_DECISION, SANCTION_DECISION } from "@local/legacy-utils/constants";
import style from "./style.module.scss";

const handleObservationUpdateContent = (observationUpdateType) => {
  switch (observationUpdateType) {
    case "updateObservation":
      return (
        <div className={style.contentWrapper}>
          <FlagIcon flagStyle={{ color: "#ffcc00" }} />
          {formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.info",
          })}
        </div>
      );
    case "updateEscalatedObservation":
      return (
        <div className={style.contentWrapper}>
          <FlagIcon flagStyle={{ color: "#ffcc00" }} />
          {formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.infoOne",
          })}
        </div>
      );
    case "updateSanctionObservation":
      return (
        <div className={style.contentWrapper}>
          <FlagIcon flagStyle={{ color: "#ffcc00" }} />
          {formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.infoTwo",
          })}
        </div>
      );
    default:
      return undefined;
  }
};

const ObservationModal = ({ isOpen, onClose, onSubmit, isValid, isRiskFlagSelected, categoryRisks, isEditable = false, observationUpdateType = undefined, selectedFlag, flagTypeOptions }) => {
  // const flagTypeOptions = [
  //   {
  //     value: FLAG_TYPES.OBSERVATION,
  //     label: (
  //       <FlagIcon flagType={FLAG_TYPES.OBSERVATION} />
  //     ),
  //   },
  //   {
  //     value: FLAG_TYPES.YELLOW,
  //     label: (
  //       <FlagIcon flagType={FLAG_TYPES.YELLOW} />
  //     ),
  //   },
  //   {
  //     value: FLAG_TYPES.RED,
  //     label: (
  //       <FlagIcon flagType={FLAG_TYPES.RED} />
  //     ),
  //   },
  //   {
  //     value: FLAG_TYPES.ESCALATED,
  //     label: (
  //       <FlagIcon flagType={FLAG_TYPES.ESCALATED} />
  //     ),
  //   },
  // ];

  const updateObservationOptions = [
    {
      value: OBSERVATION_DECISION.NO_VALID,
      label: formatMessage({
        id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.noValid",
      }),
    },
    {
      value: OBSERVATION_DECISION.ESCALATE,
      label: formatMessage({
        id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.escalate",
      }),
    },
  ];

  const updateEscalatedObservationOptions = [
    {
      value: ESCALATION_DECISION.DEFFERED,
      label: (
        <div>
          <FlagIcon flagStyle={{ color: "#ffcc00" }} />
          {formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.defferedInfo",
          })}
        </div>
      ),
    },
    {
      value: ESCALATION_DECISION.CONFIRMED,
      label: (
        <div>
          <FlagIcon flagStyle={{ color: "#cc0000" }} />
          {formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.confirmedInfo",
          })}
        </div>
      ),
    },
  ];

  const updateSanctionObservationOptions = [
    {
      value: SANCTION_DECISION.NOTMATCH,
      label: formatMessage({
        id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.notTrueMatch",
      }),
    },
    {
      value: SANCTION_DECISION.CONFIRMED,
      label: formatMessage({
        id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.confirmedMatch",
      }),
    },
  ];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        isEditable
          ? formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.title",
            })
          : formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.titleOne",
            })
      }
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.cancel",
            })}
          />
          <Button
            isDisabled={!isValid}
            onClick={onSubmit}
            text={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.save",
            })}
          />
        </>
      }
    >
      {!isEditable ? (
        <Alert
          type="info"
          content={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.infoThree",
          })}
          withBottomMargin
        />
      ) : undefined}
      {observationUpdateType ? <div>{handleObservationUpdateContent(observationUpdateType)}</div> : undefined}
      <FieldGroup>
        <ShowHide isVisible={observationUpdateType !== "updateEscalatedObservation"}>
          <Field
            component={FieldRadioGroup}
            validate={[isRequired]}
            fullWidthLabel
            id="flag_type"
            name="flag_type"
            label={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.flagType",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.flagType",
            })}
            readOnly={isEditable}
            options={flagTypeOptions}
          />
        </ShowHide>
        {isRiskFlagSelected || observationUpdateType === "updateEscalatedObservation" ? (
          <Field
            component={FieldSelect}
            validate={[isRequired]}
            id="category"
            name="category"
            label={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.categoryOfRisk",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.categoryOfRisk",
            })}
            valueField="value"
            labelField="label"
            options={categoryRisks}
            readOnly={isEditable}
          />
        ) : undefined}
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="comment"
          name="comment"
          label={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.comments",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.comments",
          })}
          readOnly={isEditable}
        />
        <ShowHide isVisible={observationUpdateType !== "updateSanctionObservation"}>
          <FieldGroup>
            <Field
              component={FieldText}
              id="contact_person"
              name="contact_person"
              label={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.contact",
              })}
              placeholder={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.contact",
              })}
              readOnly={isEditable}
            />
            <Field
              component={FieldText}
              id="contact_phone"
              name="contact_phone"
              label={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.telephone",
              })}
              placeholder={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.telephone",
              })}
              readOnly={isEditable}
            />
            <Field
              component={FieldText}
              validate={[isRequired, isValidEmail]}
              id="contact_email"
              name="contact_email"
              label={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.email",
              })}
              placeholder={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.email",
              })}
              type="email"
              readOnly={isEditable}
            />
            <Field
              component={FieldFile}
              id="attachment"
              name="attachment"
              label={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.attachment",
              })}
              placeholder={formatMessage({
                id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.attachment",
              })}
              readOnly={isEditable}
            />
          </FieldGroup>
        </ShowHide>
        <ShowHide isVisible={observationUpdateType === "updateObservation" || observationUpdateType === "updateSanctionObservation"}>
          <Box
            content={
              <FieldGroup>
                <Field
                  component={FieldRadioGroup}
                  id="reason_radio"
                  name="reason_radio"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.flagUpdate",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.flagUpdate",
                  })}
                  fullWidthLabel
                  options={
                    observationUpdateType === "updateObservation" ? (selectedFlag?.can_be_escalated === false ? updateObservationOptions.filter((item) => item?.value !== OBSERVATION_DECISION.ESCALATE) : updateObservationOptions) : updateSanctionObservationOptions
                  }
                />
                <Field
                  component={FieldText}
                  id="validation_comment"
                  name="validation_comment"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.reason",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.enterDetails",
                  })}
                />
              </FieldGroup>
            }
          />
        </ShowHide>
        <ShowHide isVisible={observationUpdateType === "updateEscalatedObservation"}>
          <Box
            content={
              <FieldGroup>
                <Field
                  component={FieldRadioGroup}
                  id="reason_radio"
                  name="reason_radio"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.defer",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.defer",
                  })}
                  fullWidthLabel
                  fullWidthRadioElement
                  options={updateEscalatedObservationOptions}
                />
                <Field
                  component={FieldText}
                  id="escalation_comment"
                  name="escalation_comment"
                  label={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.comment",
                  })}
                  placeholder={formatMessage({
                    id: "pages.partner.details.cmp.container.cmp.observation.modal.idx.comment",
                  })}
                />
              </FieldGroup>
            }
          />
        </ShowHide>
      </FieldGroup>
    </Modal>
  );
};

ObservationModal.propTypes = {};

export default reduxForm({
  form: "observationModalForm",
  enableReinitialize: true,
})(ObservationModal);

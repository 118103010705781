import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptSpecializations } from "@local/utils/adapters";

export const getPartnersFilters = (countries, partnerTypes, sectors, verificationStatuses, concernTypes, organizationDescriptions, partnerStatusTypes) => [
  {
    id: "legal_name",
    name: "legal_name",
    label: formatMessage({ id: "pages.partners.list.utils.filters.name" }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.name",
    }),
    type: "text",
  },
  {
    id: "display_type",
    name: "display_type",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.typeOfOrganization",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.typeOfOrganization",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerTypes,
  },
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.partners.list.utils.filters.country" }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: countries,
  },
  {
    id: "specializations",
    name: "specializations",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.sectorArea",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.sectorArea",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "concern",
    name: "concern",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.populations",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.populations",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: concernTypes,
  },
  {
    id: "vendor_num",
    name: "vendor_num",
    label: formatMessage({ id: "pages.partners.list.utils.filters.vendorNum" }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.vendorNum",
    }),
    type: "text",
  },
  {
    id: "is_verified",
    name: "is_verified",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.verificationStatus",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.verificationStatus",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: verificationStatuses,
    clearOnChange: ["complete_date", "passed_date", "failed_date"],
  },
  {
    id: "complete_date",
    fromName: "complete_date_from",
    toName: "complete_date_to",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.completedDate",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.completedDate",
    }),
    getVisibility: (filtersValues) => (filtersValues.is_verified ?? false) && filtersValues.is_verified === "pending",
    type: "dateRange",
  },
  {
    id: "passed_date",
    fromName: "passed_date_from",
    toName: "passed_date_to",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.passedDate",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.passedDate",
    }),
    getVisibility: (filtersValues) => (filtersValues.is_verified ?? false) && filtersValues.is_verified === "verified",
    type: "dateRange",
  },
  {
    id: "failed_date",
    fromName: "failed_date_from",
    toName: "failed_date_to",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.failedDate",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.failedDate",
    }),
    getVisibility: (filtersValues) => (filtersValues.is_verified ?? false) && filtersValues.is_verified === "unverified",
    type: "dateRange",
  },
  {
    id: "org_description",
    name: "org_description",
    label: formatMessage({
      id: "pages.partners.list.utils.filters.orgDescription",
    }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.orgDescription",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: organizationDescriptions,
    multiple: true,
  },
  {
    id: "partner_status",
    name: "partner_status",
    label: "Partner Status",
    placeholder: "Partner Status",
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerStatusTypes,
    multiple: false,
  },
  {
    id: "is_hq",
    name: "is_hq",
    label: formatMessage({ id: "pages.partners.list.utils.filters.show" }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.show",
    }),
    type: "checkbox",
  },
  {
    id: "is_sanctioned",
    name: "is_sanctioned",
    label: formatMessage({ id: "pages.partners.list.utils.filters.sanction" }),
    placeholder: formatMessage({
      id: "pages.partners.list.utils.filters.sanction",
    }),
    type: "checkbox",
  },
];

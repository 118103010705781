import { formatMessage } from "@local/legacy-utils/i18nHelper";

export const getUserFilters = (offices, roles, statuses) => [
  {
    id: "name",
    name: "name",
    label: formatMessage({
      id: "pages.users.management.utils.filters.fullname",
    }),
    placeholder: formatMessage({
      id: "pages.users.management.utils.filters.fullname",
    }),
    type: "text",
  },
  {
    id: "office",
    name: "office",
    label: formatMessage({ id: "pages.users.management.utils.filters.office" }),
    placeholder: formatMessage({
      id: "pages.users.management.utils.filters.office",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: offices,
  },
  {
    id: "role",
    name: "role",
    label: formatMessage({ id: "pages.users.management.utils.filters.roles" }),
    placeholder: formatMessage({
      id: "pages.users.management.utils.filters.roles",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: roles,
  },
  {
    id: "status",
    name: "status",
    label: formatMessage({ id: "pages.users.management.utils.filters.status" }),
    placeholder: formatMessage({
      id: "pages.users.management.utils.filters.status",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: statuses,
  },
];

export const getOfficeFilters = (offices) => [
  {
    id: "name",
    name: "name",
    label: formatMessage({ id: "pages.users.management.utils.filters.name" }),
    placeholder: formatMessage({
      id: "pages.users.management.utils.filters.name",
    }),
    type: "text",
  },
  {
    id: "code",
    name: "code",
    label: formatMessage({ id: "pages.users.management.utils.filters.code" }),
    placeholder: formatMessage({
      id: "pages.users.management.utils.filters.code",
    }),
    type: "text",
  },
  {
    id: "office",
    name: "office",
    label: formatMessage({
      id: "pages.users.management.utils.filters.offices",
    }),
    placeholder: formatMessage({
      id: "pages.users.management.utils.filters.offices",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: offices,
  },
];

import React from "react";
import PropTypes from "prop-types";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import GppGoodIcon from "@mui/icons-material/GppGood";
import Button from "../button";
import { useHistory } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { getVerificationContent } from "@local/utils/adapters";
import Loader from "../loader";

const VerificationSteps = (props) => {
  const history = useHistory();

  const { verification = {}, partnerId, hq, profileStatus } = props;

  const isProfileIncompleteHighlighted = profileStatus === "Incomplete";
  const isVerificationPendingHighlighted = profileStatus === "Pending";
  const isVerificationHighlighted = profileStatus === "Passed" || profileStatus === "Failed" || profileStatus === "Expired" || profileStatus === "Invalidated";
  const isNotVerified = profileStatus === "Incomplete" || profileStatus === "Pending";

  const onClickViewAll = () => history.push(`/partner/${partnerId}/verification`);

  const onClickViewHq = () => window.location.assign(`/partner/${hq.id}/overview`);

  if (hq && !hq.is_verified) {
    return (
      <div className={style.verifyHqWrapper}>
        <div>
          <div>
            <div className={style.warningIcon}>
              <WarningIcon style={{ fontSize: ".85rem", color: "#dd0000" }} />
            </div>
          </div>
          <span>{formatMessage({ id: "cmp.verification.steps.idx.verification" })}</span>
        </div>
        <div>
          <Button text={formatMessage({ id: "cmp.verification.steps.idx.verifyHq" })} variant="text" onClick={onClickViewHq} />
        </div>
      </div>
    );
  }

  return profileStatus ? (
    <div className={style.mainWrapper}>
      <div className={style.stepsWrapper}>
        <div className={style.stepWrapper}>
          <div
            className={style.stepIconWrapper}
            style={{
              borderColor: isProfileIncompleteHighlighted ? "#5393f8" : "transparent",
            }}
          >
            <GppGoodIcon style={{ fill: "#9e9e9e" }} />
          </div>
          <span
            className={style.stepText}
            style={{
              fontWeight: isProfileIncompleteHighlighted ? "500" : "normal",
            }}
          >
            {formatMessage({
              id: "cmp.verification.steps.idx.profileIncompleteFull",
            })}
          </span>
        </div>
        <div className={style.stepDivider} />
        <div className={style.stepWrapper}>
          <div
            className={style.stepIconWrapper}
            style={{
              borderColor: isVerificationPendingHighlighted ? "#5393f8" : "transparent",
            }}
          >
            <GppGoodIcon style={{ fill: "#0000ff" }} />
          </div>
          <span
            className={style.stepText}
            style={{
              fontWeight: isVerificationPendingHighlighted ? "500" : "normal",
            }}
          >
            {formatMessage({
              id: "cmp.verification.steps.idx.profileComplete",
            })}
          </span>
        </div>
        <div className={style.stepDivider} />
        <div className={style.stepWrapper}>
          <div
            className={style.stepIconWrapper}
            style={{
              borderColor: isVerificationHighlighted ? "#5393f8" : "transparent",
            }}
          >
            {isNotVerified ? <GppGoodIcon style={{ fill: "#099854", fontSize: "1.25rem" }} /> : getVerificationContent(profileStatus, "#efefef")?.icon}
          </div>
          <span className={style.stepText} style={{ fontWeight: isVerificationHighlighted ? "500" : "normal" }}>
            {isNotVerified ? formatMessage({ id: "cmp.verification.steps.idx.verified" }) : getVerificationContent(profileStatus)?.stepText}
          </span>
        </div>
      </div>
      <div className={style.textWrapper}>
        <span>{getVerificationContent(profileStatus)?.text}</span>
      </div>
      <div className={style.footerWrapper}>
        {verification.submitter ? (
          <div>
            <span className={style.verifiedByLabel}>{formatMessage({ id: "cmp.verification.steps.idx.by" })}</span>
            <span className={style.verifiedByName}>{verification.submitter.agency_name}</span>
            <span className={style.verifiedByDate}>{formatDateForPrint(verification.created)}</span>
          </div>
        ) : (
          <div>
            <span className={style.notVerified}>
              {formatMessage({
                id: "cmp.verification.steps.idx.neverVerified",
              })}
            </span>
          </div>
        )}
        <div>
          <Button text={formatMessage({ id: "cmp.verification.steps.idx.button" })} variant="text" onClick={onClickViewAll} />
        </div>
      </div>
    </div>
  ) : (
    <Loader isVisible />
  );
};

VerificationSteps.propTypes = {
  verification: PropTypes.object,
};

VerificationSteps.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default VerificationSteps;

import React from "react";
import style from "./style.module.scss";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";

const DownloadIcon = ({ fileUrl, fileName, withMarginTop, onClickDelete }) => (
  <div className={style.wrapper} style={withMarginTop ? { marginTop: ".25rem" } : undefined}>
    <div className={style.mainWrapper}>
      <div>
        <a href={fileUrl} alt={fileName} target="_blank" rel="noreferrer">
          <AttachFileIcon style={{ fontSize: ".85rem" }} />
        </a>
      </div>
      <div>
        <a href={fileUrl} alt={fileName} target="_blank" rel="noreferrer">
          <span className={style.label}>{fileName}</span>
        </a>
      </div>
      {onClickDelete ? (
        <div className={style.deleteIconWrapper} onClick={onClickDelete}>
          <CloseIcon style={{ fontSize: ".95rem" }} />
        </div>
      ) : (
        <></>
      )}
    </div>
  </div>
);

DownloadIcon.propTypes = {};

export default DownloadIcon;

import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import style from "./style.module.scss";
import { getBaseUrl } from "@local/utils/network";

const CheckboxesList = ({ items }) => {
  const [checkedState, setCheckedState] = useState(items.slice().fill(false));
  const [checkedFiles, setCheckedFiles] = useState(items.slice().fill(null));

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));
    setCheckedState(updatedCheckedState);

    const updatedCheckedFiles = updatedCheckedState.map((item, index) => item && items[index]).filter((item) => item);
    setCheckedFiles(updatedCheckedFiles);
  };

  const hasFiles = checkedFiles.filter((item) => item);

  const handleDownload = () => {
    const fileIds = checkedFiles.map((file) => file?.id).join(",");
    window.open(`${getBaseUrl()}/api/risk-profile/export-file-zip/?file_ids=${fileIds}`);
    setCheckedState(items.slice().fill(false));
    setCheckedFiles(items.slice().fill(null));
  };

  return (
    <div className={style.wrapper}>
      <FormGroup row>
        {items.map((item, index) => (
          <div className={style.contentWrapper}>
            <FormControlLabel
              control={<Checkbox key={item.id} checked={checkedState[index]} onClick={() => handleOnChange(index)} />}
              label={
                <div className={style.label}>
                  <span>{item.name}</span>
                </div>
              }
            />
          </div>
        ))}
      </FormGroup>
      <div>
        <Button text={formatMessage({ id: "cmp.checkboxes.list.idx.button" })} variant="contained" isDisabled={hasFiles.length === 0} onClick={handleDownload} />
      </div>
    </div>
  );
};

CheckboxesList.propTypes = {};

export default CheckboxesList;

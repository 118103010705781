import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import style from "./style.module.scss";

const Loader = ({ isVisible, size = 40, isFullScreen }) => {
  if (isVisible) {
    if (isFullScreen)
      return (
        <div className={style.fullScreenWrapper}>
          <CircularProgress size={size} />
        </div>
      );
    return (
      <div className={style.wrapper}>
        <CircularProgress size={size} />
      </div>
    );
  } else {
    return <></>;
  }
};

Loader.propTypes = {};

export default Loader;

import React from "react";
import Card from "@local/components/card";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import intervalToDuration from "date-fns/intervalToDuration";
import style from "./style.module.scss";
import { differenceInMinutes, formatDuration, startOfToday, endOfDay } from "date-fns";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import { enUS, fr, es } from "date-fns/esm/locale";

const getLocale = () => {
  switch (localStorage.getItem("language")) {
    case "en":
      return enUS;
    case "fr":
      return fr;
    case "es":
      return es;
    default:
      return enUS;
  }
};

const Timeline = ({ project }) => {
  let isDeadlinePassed = false;
  if (project?.deadline_date) {
    const deadline = endOfDay(new Date(project.deadline_date));
    const today = startOfToday();
    isDeadlinePassed = deadline < today;
  }

  const timeAvailableUntilDeadline =
    formatDuration(
      intervalToDuration({
        start: new Date(),
        end: new Date(`${project?.deadline_date} 23:59:59`),
      }),
      {
        locale: getLocale(),
        format: ["years", "months", "weeks", "days", "hours", "minutes"],
      },
    ) +
    (isDeadlinePassed
      ? ` ${formatMessage({
          id: "pages.project.details.cmp.overview.cmp.timeline.idx.ago",
        })}`
      : "");

  const postedDate = new Date(project?.published_timestamp ?? project?.created);
  const clarificationRequestDeadlineDate = new Date(project?.clarification_request_deadline_date);
  const deadlineDate = new Date(project?.deadline_date);
  const notificationResultsDate = new Date(project?.notif_results_date);
  const startDate = new Date(project?.start_date);

  const clarificationRequestDeadlineDifference = differenceInMinutes(clarificationRequestDeadlineDate, postedDate);
  const deadlineDifference = differenceInMinutes(deadlineDate, postedDate);
  const notificationResultsDifference = differenceInMinutes(notificationResultsDate, postedDate);
  const startDifference = differenceInMinutes(startDate, postedDate);

  let clarificationRequestDeadlinePercentage = parseInt((clarificationRequestDeadlineDifference * 100) / startDifference);
  let deadlinePercentage = parseInt((deadlineDifference * 100) / startDifference);
  let notificationResultsPercentage = parseInt((notificationResultsDifference * 100) / startDifference);
  let progressPercentage = parseInt((differenceInMinutes(new Date(), postedDate) * 100) / startDifference);

  if (clarificationRequestDeadlinePercentage < 0) clarificationRequestDeadlinePercentage = 0;
  if (deadlinePercentage < 0) deadlinePercentage = 0;
  if (notificationResultsPercentage < 0) notificationResultsPercentage = 0;
  if (progressPercentage < 0) progressPercentage = 0;

  if (clarificationRequestDeadlinePercentage > 100) clarificationRequestDeadlinePercentage = 100;
  if (deadlinePercentage > 100) deadlinePercentage = 100;
  if (notificationResultsPercentage > 100) notificationResultsPercentage = 100;
  if (progressPercentage > 100) progressPercentage = 100;

  return (
    <Card
      title={formatMessage({
        id: "pages.project.details.cmp.overview.cmp.timeline.idx.title",
      })}
      action={
        isDeadlinePassed ? (
          <div className={style.headerActionWrapper}>
            {formatMessage({
              id: "pages.project.details.cmp.overview.cmp.timeline.idx.deadline",
            })}{" "}
            {timeAvailableUntilDeadline}
          </div>
        ) : (
          <div className={style.headerActionWrapper}>
            {formatMessage({
              id: "pages.project.details.cmp.overview.cmp.timeline.idx.application",
            })}{" "}
            {timeAvailableUntilDeadline}
          </div>
        )
      }
    >
      <div className={style.mainWrapper}>
        <div className={style.timelineWrapper}>
          <div className={style.timelineRow} />
          <div className={style.timelineProgressRow} style={{ width: `${progressPercentage}%` }} />
          <div className={style.timelineItemWrapperOne} style={{ left: "0%" }}>
            <div className={style.bullet} style={{ backgroundColor: "#00aa88" }} />
            <div className={style.paper} style={{ borderColor: "#00aa88" }}>
              {formatDateForPrint(postedDate)}
            </div>
          </div>
          <div className={style.timelineItemWrapperTwo} style={{ left: `${clarificationRequestDeadlinePercentage}%` }}>
            <div className={style.bullet} style={{ backgroundColor: "#ee8800" }} />
            <div className={style.paper} style={{ borderColor: "#ee8800" }}>
              {formatDateForPrint(clarificationRequestDeadlineDate)}
            </div>
          </div>
          <div className={style.timelineItemWrapperThree} style={{ left: `${deadlinePercentage}%` }}>
            <div className={style.bullet} style={{ backgroundColor: "#cc0000" }} />
            <div className={style.paper} style={{ borderColor: "#cc0000" }}>
              {formatDateForPrint(deadlineDate)}
            </div>
          </div>
          <div className={style.timelineItemWrapperFour} style={{ left: `${notificationResultsPercentage}%` }}>
            <div className={style.bullet} style={{ backgroundColor: "#0000ee" }} />
            <div className={style.paper} style={{ borderColor: "#0000ee" }}>
              {formatDateForPrint(notificationResultsDate)}
            </div>
          </div>
          <div className={style.timelineItemWrapperFive} style={{ left: "calc(100% - 5rem)" }}>
            <div className={style.bullet} style={{ backgroundColor: "#222222" }} />
            <div className={style.paper} style={{ borderColor: "#222222" }}>
              {formatDateForPrint(startDate)}
            </div>
          </div>
        </div>
        <div className={style.legendWrapper}>
          <ul className={style.legend}>
            <li>
              <div style={{ backgroundColor: "#00aa88" }} />
              <span>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.timeline.idx.posted",
                })}
              </span>
            </li>
            <li>
              <div style={{ backgroundColor: "#ee8800" }} />
              <span>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.timeline.idx.clarification",
                })}
              </span>
            </li>
            <li>
              <div style={{ backgroundColor: "#cc0000" }} />
              <span>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.timeline.idx.deadlineOne",
                })}
              </span>
            </li>
            <li>
              <div style={{ backgroundColor: "#0000ee" }} />
              <span>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.timeline.idx.notification",
                })}
              </span>
            </li>
            <li>
              <div style={{ backgroundColor: "#222222" }} />
              <span>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.timeline.idx.estimated",
                })}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
};

Timeline.propTypes = {};

export default Timeline;

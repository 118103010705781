/* eslint-disable no-case-declarations */

import React, { useEffect } from "react";
import MainContentWrapper from "@local/components/main-content-wrapper";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "../../legacy-utils/i18nHelper";
import Main from "./components/main";
import { getNotificationsList, updateNotifications, getNotificationsNotReadList } from "@local/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { PROJECT_TYPES_BACKEND } from "@local/utils/constants";

const getPageFromUrl = (url) => (url ? new URLSearchParams(url) : undefined);

const getLink = (notification) => {
  if (notification?.project?.id) {
    switch (notification?.source) {
      case "cfei_review_required":
        return `${window.location.origin}/cfei/open/${notification.project.id}/overview`;
      case "agency_application_decision_make":
        return `${window.location.origin}/cfei/open/${notification.project.id}/applications/${notification.object_id}`;
      case "direct_select_un_int":
        return `${window.location.origin}/cfei/direct/${notification.project.id}/overview`;
      case "direct_select_ucn":
        return `${window.location.origin}/cfei/direct/${notification.project.id}/overview`;
      case "added_as_cfei_local_point":
      case "cfei_cancel":
      case "cfei_application_lost":
      case "cfei_application_withdraw":
      case "cfei_application_selected":
      case "cfei_application_submitted":
      case "cfei_invitation":
      case "cfei_update_prev":
      case "agency_cfei_reviewers_selected":
      case "cfei_draft_sent_for_review":
      case "dsr_draft_sent_for_review":
      case "cfei_sent_for_decision_making":
      case "cfei_clarification_deadline_passed":
      case "cfei_update":
        return `${window.location.origin}/cfei/${notification?.project?.display_type === PROJECT_TYPES_BACKEND.DIRECT ? "direct" : "open"}/${notification.project.id}/overview`;
      default:
        return "/";
    }
  } else {
    if (notification?.source?.startsWith("psea_")) {
      return `${window.location.origin}/profile/${notification.object_id}/psea`;
    }
    switch (notification.source) {
      case "first_access_partner_request":
        const regexInput = new RegExp("(?:This is to inform you that )(.*)(?: requested access as a Partner Member)", "ig");
        const regexResult = regexInput.exec(notification?.description ?? "");
        if (regexResult && regexResult.length) {
          return `${window.location.origin}/idp/users/add/${encodeURI(`${regexResult.at(1).split(" ").at(1)},${regexResult.at(1).split(" ").at(0)}`)}`;
        }
        return `/idp/${window.location.origin}/users`;
      default:
        return "/";
    }
  }
};

const adaptNotifications = (data) =>
  data?.map((item, index) => ({
    date: new Date(item.notification.created).toLocaleString(),
    id: item.notification.id ?? index,
    title: item.notification.name,
    description: item.notification.description,
    link: getLink(item.notification),
    project: item.notification?.project,
    read: item.did_read,
  }));

const NotificationsList = ({ previousPage, nextPage, notifications, getNotifications, updateNotifications, getNotificationsNotRead }) => {
  useEffect(() => {
    getNotifications(1);
  }, [getNotifications]);

  const onClickPrevious = () => {
    getNotifications(previousPage);
  };

  const onClickNext = () => {
    getNotifications(nextPage);
  };

  const onClickMarkAllAsRead = async () => {
    try {
      await updateNotifications({ mark_all_as_read: true });
      await getNotifications(nextPage ? nextPage - 1 : 1);
      await getNotificationsNotRead();
    } catch (error) {
      toast.error(formatMessage({ id: "pages.notifications.list.idx.error" }));
    }
  };

  return (
    <HeaderNavigation title={formatMessage({ id: "pages.notifications.list.idx.title" })}>
      <MainContentWrapper>
        <Main havePreviousPage={previousPage !== undefined} haveNextPage={nextPage !== undefined} notifications={notifications} onClickNext={onClickNext} onClickPrevious={onClickPrevious} onClickMarkAllAsRead={onClickMarkAllAsRead} />
      </MainContentWrapper>
    </HeaderNavigation>
  );
};

const mapStateToProps = (state) => ({
  notifications: adaptNotifications(state.notificationsList?.data?.data?.results ?? []),
  previousPage: getPageFromUrl(state.notificationsList?.data?.data?.previous ?? undefined)?.get("page") ?? undefined,
  nextPage: getPageFromUrl(state.notificationsList?.data?.data?.next ?? undefined)?.get("page") ?? undefined,
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (page) => dispatch(getNotificationsList({ page })),
  getNotificationsNotRead: () => dispatch(getNotificationsNotReadList({ not_read: true })),
  updateNotifications: (params) => dispatch(updateNotifications(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import Button from "@local/components/button";

const OfficesList = ({ onClickAdd, fetchFunction, columns, shouldRefresh, afterRefresh, filters, onChangeTableFilters }) => {
  return (
    <Table
      id="users_list"
      title={formatMessage({
        id: "pages.users.management.cmp.offices.list.idx.regionalOffices",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      shouldRefresh={shouldRefresh}
      afterRefresh={afterRefresh}
      filters={filters}
      onChangeFilters={onChangeTableFilters}
      withUrlSynchronization
      filtersNumberOfColumns={3}
      actions={
        <>
          <Button
            text={formatMessage({
              id: "pages.users.management.cmp.offices.list.idx.office",
            })}
            onClick={onClickAdd}
          />
        </>
      }
    />
  );
};

OfficesList.propTypes = {};

export default OfficesList;

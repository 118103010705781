import React from "react";
import Typography from "@mui/material/Typography";
import { formatMessage } from "../../../../legacy-utils/i18nHelper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import style from "./numberOfConceptNotes.module.scss";
import Loader from "@local/components/loader";

const NumberOfConceptNotes = ({ isLoading, number }) => {
  return (
    <Card>
      <CardContent>
        <div className={style.titleWrapper}>
          <div>
            <Typography variant="subtitle2" component="div">
              {formatMessage({
                id: "pages.dashboard.cmp.agency.numberOfConceptNotes.title",
              })}
            </Typography>
          </div>
          {isLoading ? (
            <Loader isVisible />
          ) : (
            <div>
              {number !== undefined ? (
                <Typography variant="h6" component="div">
                  {number}
                </Typography>
              ) : undefined}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

NumberOfConceptNotes.propTypes = {};

export default NumberOfConceptNotes;

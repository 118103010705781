/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useMemo } from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import MUITable from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import Typography from "@mui/material/Typography";
import style from "./style.module.scss";
import Grid from "@mui/material/Grid";
import FieldText from "../field-text";
import FieldAutosuggest from "../field-autosuggest";
import FieldSelect from "../field-select";
import FieldCheckbox from "../field-checkbox";
import FieldDateRangePicker from "../field-date-range-picker";
import FieldRadioGroup from "../field-radio-group";
import { formatDateForFiltering, formatNumber } from "@local/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import Alert from "@local/components/alert";
import ClearIcon from "@mui/icons-material/Clear";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";

// POST_REFACTORING_TODO: Filters in URL does not work properly after refreshing

const getValue = (column, row) => {
  const splitted = column.id.split(".");
  if (splitted.length === 1) {
    if (!row) return "--";
    return row[column.id];
  }
  const updated = row[splitted[0]];
  splitted.shift();
  return getValue({ id: splitted.join(".") }, updated);
};

const getCell = (column, row) => {
  switch (column.type) {
    case "date":
      return <span>{formatDateForPrint(getValue(column, row))}</span>;
    case "number":
      return <span style={{ textAlign: "right" }}>{formatNumber(getValue(column, row))}</span>;
    case "link":
      return (
        <Link alt={column.title} to={column.to(row)} style={{ textAlign: "right" }}>
          {getValue(column, row)}
        </Link>
      );
    case "custom":
      return column.render(row);
    case "clickable":
      return (
        <span onClick={() => column.onClick(row)} style={{ textAlign: "right" }}>
          {getValue(column, row)}
        </span>
      );
    case "text":
      return <span>{getValue(column, row) || "--"}</span>;
    default:
      return <span>{getValue(column, row) || "--"}</span>;
  }
};

const getFilter = (item, id, filterValues, onChangeFilterValues) => {
  if ((item.getVisibility?.(filterValues) ?? true) === false) return <></>;
  switch (item.type) {
    case "text":
      return (
        <FieldText
          id={`table_filter_text_${id}_${item.id}`}
          label={item.label}
          placeholder={item.placeholder}
          type="text"
          small
          input={{
            value: filterValues[item.id] ?? "",
            onChange: (event) => onChangeFilterValues(item, event.target.value),
          }}
        />
      );
    case "autosuggest":
      return (
        <FieldAutosuggest
          id={`table_filter_autosuggest_${id}_${item.id}`}
          label={item.label}
          fetchFunction={item.fetchFunction}
          valueField={item.valueField}
          labelField={item.labelField}
          small
          input={{
            value: filterValues[item.id],
            onChange: (value) => onChangeFilterValues(item, value),
          }}
          multiple={item.multiple}
        />
      );
    case "select":
      return (
        <FieldSelect
          id={`table_filter_select_${id}_${item.id}`}
          label={item.label}
          placeholder={item.placeholder}
          small
          valueField={item.valueField}
          labelField={item.labelField}
          options={item.options}
          onChangeExtra={item.onChangeExtra}
          multiple={item.multiple}
          input={{
            value: filterValues[item.id],
            onChange: (event) => onChangeFilterValues(item, event.target.value),
          }}
        />
      );
    case "dateRange":
      return (
        <FieldDateRangePicker
          id={`table_filter_date_range_picker_${id}_${item.id}`}
          label={item.label}
          small
          options={item.options}
          input={{
            value: filterValues[item.id],
            onChange: (range) => onChangeFilterValues(item, range),
          }}
        />
      );
    case "radio":
      return (
        <FieldRadioGroup
          id={`table_filter_radio_group_${id}_${item.id}`}
          label={item.label}
          small
          options={item.options}
          input={{
            value: filterValues[item.id],
            onChange: (value) => onChangeFilterValues(item, value),
          }}
        />
      );
    case "checkbox":
      return (
        <FieldCheckbox
          id={`table_filter_checkbox_${id}_${item.id}`}
          label={item.label}
          small
          input={{
            value: filterValues[item.id],
            onChange: (value) => onChangeFilterValues(item, value),
          }}
        />
      );
    default:
      return <></>;
  }
};

const getExpandSectionItem = (row, item, key) => {
  switch (item.type) {
    case "date":
      return (
        <div key={`expand_section_item_${key}`}>
          <span className={style.expandSectionItemTitle}>{item.title}</span>
          <span className={style.expandSectionItemValue}>{formatDateForPrint(getValue(item, row))}</span>
        </div>
      );
    case "custom":
      return (
        <div key={`expand_section_item_${key}`}>
          <span className={style.expandSectionItemTitle}>{item.title}</span>
          <span className={style.expandSectionItemValue}>{item.render(row)}</span>
        </div>
      );
    case "text":
      return (
        <div key={`expand_section_item_${key}`}>
          <span className={style.expandSectionItemTitle}>{item.title}</span>
          <span className={style.expandSectionItemValue}>{getValue(item, row) || "--"}</span>
        </div>
      );
    default:
      return (
        <div key={`expand_section_item_${key}`}>
          <span className={style.expandSectionItemTitle}>{item.title}</span>
          <span className={style.expandSectionItemValue}>{getValue(item, row) || "--"}</span>
        </div>
      );
  }
};

const buildFilterValuesObject = (item, value, filterValues) => {
  if ((item.getVisibility?.(filterValues) ?? true) === false) return {};
  if (!value || (item.multiple && value.length === 0)) return { [item.id]: undefined };
  switch (item.type) {
    case "dateRange":
      return {
        [item.fromName]: value[0] ? new Date(value[0]) : null,
        [item.toName]: value[1] ? new Date(value[1]) : null,
      };
    case "autosuggest":
      return { [item.id]: item.multiple ? value.split(",") : value };
    case "select":
      return { [item.id]: item.multiple ? value.split(",") : value };
    default:
      return { [item.id]: value };
  }
};

const buildFilterParamsObject = (item, value) => {
  if (!value || (item.multiple && value.length === 0)) return { [item.id]: undefined };
  switch (item.type) {
    case "dateRange":
      return {
        [item.fromName]: formatDateForFiltering(value[0]) ?? null,
        [item.toName]: formatDateForFiltering(value[1]) ?? null,
      };
    case "autosuggest":
      return {
        [item.id]: item.multiple ? value.map((item) => item.id).join(",") : value,
      };
    case "select":
      return { [item.id]: item.multiple ? value.join(",") : value };
    default:
      return { [item.id]: value };
  }
};

const getWrapperComponent = (isBordered) => {
  if (isBordered) {
    return ({ children }) => <div className={style.borderedTable}>{children}</div>;
  }
  return ({ children }) => <Paper sx={{ width: "100%", mb: 0 }}>{children}</Paper>;
};

const Row = ({ key, data, visibleColumns, expandSectionItems, expandSectionNumberOfColumns }) => {
  const [open, setOpen] = useState(false);

  const filteredExpandSectionItems = expandSectionItems.filter((item) => item.getVisibility?.(data) ?? true);

  if (filteredExpandSectionItems?.length) {
    return (
      <React.Fragment key={key}>
        <TableRow>
          <TableCell sx={{ width: "1rem" }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {visibleColumns.map((column, columnIndex) => (
            <TableCell width={column?.width ?? undefined} key={`table_body_row_cell_${key}_${columnIndex}`} align={column?.type === "number" ? "right" : "left"}>
              {getCell(column, data)}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={visibleColumns.length + 1}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className={style.expandSectionWrapper}>
                {
                  <Grid container spacing={2}>
                    {filteredExpandSectionItems.map((item, index) => (
                      <Grid item xs={12 / expandSectionNumberOfColumns} key={`table_expand_${key}_${index}`}>
                        {getExpandSectionItem(data, item, key)}
                      </Grid>
                    ))}
                  </Grid>
                }
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <TableRow key={key}>
        {visibleColumns.map((column, columnIndex) => (
          <TableCell width={column?.width ?? undefined} key={`table_body_row_cell_${key}_${columnIndex}`} align={column?.type === "number" ? "right" : "left"}>
            {getCell(column, data)}
          </TableCell>
        ))}
      </TableRow>
    </React.Fragment>
  );
};

const Table = ({
  id,
  columns,
  fetchFunction,
  adapterFunction = undefined,
  actions = undefined,
  title = undefined,
  filters = [],
  filterInfo = undefined,
  onChangeFilters = undefined,
  shouldRefresh = false,
  afterRefresh = undefined,
  isBordered = false,
  expandSectionItems = [],
  withUrlSynchronization = false,
  expandSectionNumberOfColumns = 4,
  filtersNumberOfColumns = 3,
  initialFilters,
  secondaryColumns = [],
  isPaginated = true,
  isSelectable = false,
  keyColumn,
  selected = [],
  setSelected = undefined,
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({});
  const [filterParams, setFilterParams] = useState({});
  const timeout = useRef(undefined);
  const [isFiltersSectionExpanded, setIsFiltersSectionExpanded] = useState(true);

  const getFilterActiveStatus = (filterValues) => Object.keys(filterValues).find((key) => (filterValues[key] && !Array.isArray(filterValues[key])) || (Array.isArray(filterValues[key]) && filterValues[key].find((item) => item)));

  useEffect(() => {
    parseUrlQuery();
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    if (shouldRefresh && afterRefresh) {
      parseUrlQuery();
      afterRefresh();
    }
  }, [shouldRefresh, afterRefresh]);

  const Wrapper = useMemo(() => getWrapperComponent(isBordered), [isBordered]);

  const setInitialFilters = async () => {
    let updatedFilterValues = {};
    let updatedFilterParams = {};
    Object.keys(initialFilters ?? {})?.forEach((key) => {
      const item = filters?.find((filter) => filter.id === key);
      updatedFilterValues = { ...filterValues, [item.id]: initialFilters[key] };
      updatedFilterParams = {
        ...filterParams,
        ...buildFilterParamsObject(item, initialFilters[key]),
      };
    });
    setFilterValues(updatedFilterValues);
    setFilterParams(updatedFilterParams);
    try {
      setIsLoading(true);
      const result = await fetchFunction({
        page: 1,
        page_size: rowsPerPage,
        ...updatedFilterParams,
      });
      setPage(1);
      setCount(result?.data?.count ?? 0);
      setRows(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data?.results ?? []);
      setIsLoading(false);
      updateUrlQuery(page, rowsPerPage, updatedFilterParams);
    } catch (error) {
      toast.error(formatMessage({ id: "cmp.table.idx.error" }));
      setIsLoading(false);
    }
  };

  const parseUrlQuery = async () => {
    if (withUrlSynchronization) {
      setPage(1);
      let searchParams = Object.fromEntries(new URLSearchParams(window.location.search));
      let updatedFilterValues = {};
      let updatedFilterParams = {};
      let updatedPage = 1;
      let updatedRowsPerPage = 10;
      Object.keys(initialFilters ?? {}).forEach((key) => {
        if (searchParams[key] === undefined) searchParams = { ...searchParams, [key]: initialFilters[key] };
      });
      Object.keys(searchParams).forEach((key) => {
        switch (key) {
          case "page":
            setPage(searchParams[key]);
            updatedPage = searchParams[key];
            return;
          case "page_size":
            setRowsPerPage(searchParams[key]);
            updatedRowsPerPage = searchParams[key];
            return;
          default:
            break;
        }
        const item = filters?.find((filter) => filter.id === key);
        if (item) {
          const createdFilterValue = buildFilterValuesObject(item, searchParams[key], updatedFilterValues);
          updatedFilterValues = {
            ...updatedFilterValues,
            ...createdFilterValue,
          };
          updatedFilterParams = {
            ...updatedFilterParams,
            ...buildFilterParamsObject(item, createdFilterValue[item.id]),
          };
          item.onChangeExtra?.({ target: { value: searchParams[key] } });
        }
      });
      setFilterValues(updatedFilterValues);
      setFilterParams(updatedFilterParams);
      updateUrlQuery(updatedPage, updatedRowsPerPage, updatedFilterParams);
      try {
        setIsLoading(true);
        const result = await fetchFunction({
          page: updatedPage,
          page_size: updatedRowsPerPage,
          ...updatedFilterParams,
        });
        setCount(result?.data?.count ?? 0);
        setRows(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data?.results ?? []);
        setIsLoading(false);
      } catch (error) {
        toast.error(formatMessage({ id: "cmp.table.idx.error" }));
        setIsLoading(false);
      }
    } else {
      setInitialFilters();
    }
  };

  const updateUrlQuery = (page, page_size, filters) => {
    if (withUrlSynchronization) {
      const url = new URL(`${window.location.origin}${window.location.pathname}`);
      url.searchParams.set("page", page);
      url.searchParams.set("page_size", page_size);
      if (filters)
        Object.keys(filters).forEach((key) => {
          if (filters[key]) url.searchParams.set(key, filters[key]);
        });
      window.history.pushState({}, "", url);
    }
  };

  const onClickToggleFiltersSection = () => setIsFiltersSectionExpanded(!isFiltersSectionExpanded);

  const onPageChange = async (event, newPage) => {
    try {
      setIsLoading(true);
      const result = await fetchFunction({
        page: newPage + 1,
        page_size: rowsPerPage,
        ...filterParams,
      });
      setPage(newPage + 1);
      setCount(result?.data?.count ?? 0);
      setRows(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data?.results ?? []);
      setIsLoading(false);
      updateUrlQuery(newPage + 1, rowsPerPage, filterParams);
    } catch (error) {
      toast.error(formatMessage({ id: "cmp.table.idx.error" }));
      setIsLoading(false);
    }
  };

  const onRowsPerPageChange = async (event) => {
    try {
      setIsLoading(true);
      const result = await fetchFunction({
        page: 1,
        page_size: event.target.value,
        ...filterParams,
      });
      setPage(1);
      setRowsPerPage(event.target.value);
      setCount(result?.data?.count ?? 0);
      setRows(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data?.results ?? []);
      setIsLoading(false);
      updateUrlQuery(1, event.target.value, filterParams);
    } catch (error) {
      toast.error(formatMessage({ id: "cmp.table.idx.error" }));
      setIsLoading(false);
    }
  };

  const onChangeFilterValues = (item, value) => {
    clearTimeout(timeout.current);
    let updatedFilterValues = { ...filterValues, [item.id]: value };
    let updatedFilterParams = {
      ...filterParams,
      ...buildFilterParamsObject(item, value),
    };
    if (item?.clearOnChange?.length) {
      item.clearOnChange.forEach((toClearFilter) => {
        const updatedValue = filters.find((filter) => filter.id === toClearFilter).type === "dateRange" ? [null, null] : null;
        updatedFilterValues = {
          ...updatedFilterValues,
          [toClearFilter]: updatedValue,
        };
        updatedFilterParams = {
          ...updatedFilterParams,
          ...buildFilterParamsObject(
            filters.find((filter) => filter.id === toClearFilter),
            updatedValue,
          ),
        };
      });
    }
    setFilterValues(updatedFilterValues);
    setFilterParams(updatedFilterParams);
    onChangeFilters && onChangeFilters(updatedFilterValues);
    const id = setTimeout(async () => {
      try {
        setIsLoading(true);
        const result = await fetchFunction({
          page: 1,
          page_size: rowsPerPage,
          ...updatedFilterParams,
        });
        setPage(1);
        setCount(result?.data?.count ?? 0);
        setRows(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data?.results ?? []);
        setIsLoading(false);
        updateUrlQuery(1, rowsPerPage, updatedFilterParams);
      } catch (error) {
        toast.error(formatMessage({ id: "cmp.table.idx.error" }));
        setIsLoading(false);
      }
    }, 700);
    timeout.current = id;
  };

  const onClickClearFilterValues = async () => {
    clearTimeout(timeout.current);
    const updatedFilterValues = {};
    const updatedFilterParams = {};
    setFilterValues(updatedFilterValues);
    setFilterParams(updatedFilterParams);
    onChangeFilters && onChangeFilters(updatedFilterValues);
    try {
      setIsLoading(true);
      const result = await fetchFunction({
        page: 1,
        page_size: rowsPerPage,
        ...updatedFilterParams,
      });
      setPage(1);
      setCount(result?.data?.count);
      setRows(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data?.results ?? []);
      setIsLoading(false);
      updateUrlQuery(page, rowsPerPage, updatedFilterParams);
    } catch (error) {
      toast.error(formatMessage({ id: "cmp.table.idx.error" }));
      setIsLoading(false);
    }
  };

  const visibleColumns = columns.filter((item) => item.isVisible !== false);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Wrapper>
        {title ? (
          <div className={style.headerWrapper}>
            <div className={style.titleWrapper}>
              <Typography variant="subtitle2" component="div">
                {title}
              </Typography>
            </div>
            {actions ? <div className={style.actionsWrapper}>{actions}</div> : undefined}
            <div>
              {filters.length ? (
                <>
                  <div className={style.filterIconWrapper} onClick={onClickToggleFiltersSection}>
                    <FilterListIcon style={{ fontSize: "1.25rem" }} />
                    {isFiltersSectionExpanded ? <KeyboardArrowUpIcon style={{ fontSize: "1.25rem" }} /> : <KeyboardArrowDownIcon style={{ fontSize: "1.25rem" }} />}
                  </div>
                </>
              ) : undefined}
            </div>
            <div>
              {getFilterActiveStatus(filterValues) ? (
                <>
                  <div className={style.filterClearIconWrapper} onClick={onClickClearFilterValues}>
                    <ClearIcon style={{ fontSize: "1.25rem" }} />
                    <div className={style.filterActiveBullet} />
                  </div>
                </>
              ) : undefined}
            </div>
          </div>
        ) : undefined}
        {filters.length ? (
          <div className={isFiltersSectionExpanded ? style.filtersWrapperExpanded : style.filtersWrapperNotExpanded}>
            <Grid container spacing={2}>
              {filterInfo ? (
                <Grid item xs={12}>
                  <Alert type="info" content={filterInfo} />
                </Grid>
              ) : (
                <></>
              )}
              {filters.map((item, key) =>
                (item.getVisibility?.(filterValues) ?? true) !== false ? (
                  <Grid item xs={12 / filtersNumberOfColumns} key={`table_filter_${id}_${key}`}>
                    {getFilter(item, id, filterValues, onChangeFilterValues)}
                  </Grid>
                ) : undefined,
              )}
            </Grid>
          </div>
        ) : undefined}
        <>
          {isLoading ? (
            <div className={style.progressWrapper}>
              <CircularProgress color="primary" size={50} />
            </div>
          ) : (
            <>
              <TableContainer>
                <MUITable>
                  <TableHead>
                    {secondaryColumns.length ? (
                      <TableRow>
                        {secondaryColumns.map((column, index) =>
                          column.title !== undefined ? (
                            <TableCell className={column.title !== "" ? style.secondaryColumns : ""} colSpan={column.span} key={`table_head_row_cell_${id}_${index}`} align="center">
                              {column.title}
                            </TableCell>
                          ) : (
                            <></>
                          ),
                        )}
                      </TableRow>
                    ) : undefined}
                    <TableRow>
                      {isSelectable && <TableCell padding="checkbox"></TableCell>}
                      {expandSectionItems.length ? <TableCell sx={{ width: "1rem" }} /> : undefined}
                      {visibleColumns.map((column, index) =>
                        column.title !== undefined ? (
                          <TableCell key={`table_head_row_cell_${id}_${index}`} align={column?.type === "number" ? "right" : "left"}>
                            {column.title}
                          </TableCell>
                        ) : (
                          <></>
                        ),
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.length ? (
                      <>
                        {isSelectable
                          ? rows.map((row, rowIndex) => {
                              const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${rowIndex}`;
                              return (
                                <TableRow key={`table_body_row_${id}_${rowIndex}`}>
                                  <TableCell padding="checkbox" onClick={(event) => handleSelect(event, row[keyColumn])}>
                                    <Checkbox
                                      color="primary"
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell>
                                  {visibleColumns.map((column, columnIndex) => (
                                    <TableCell width={column?.width ?? undefined} key={`table_body_row_cell_${id}_${columnIndex}_${rowIndex}`} align={column?.type === "number" ? "right" : "left"}>
                                      {getCell(column, row)}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              );
                            })
                          : rows.map((row, rowIndex) => <Row data={row} visibleColumns={visibleColumns} expandSectionItems={expandSectionItems} expandSectionNumberOfColumns={expandSectionNumberOfColumns} key={`table_body_row_${id}_${rowIndex}`} />)}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={visibleColumns.length + (expandSectionItems?.length ? 1 : 0)}>{formatMessage({ id: "cmp.table.idx.noData" })}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </MUITable>
              </TableContainer>
              {isPaginated ? <TablePagination rowsPerPageOptions={[10, 15, 20, 50, 100]} component="div" count={count} rowsPerPage={parseInt(rowsPerPage)} page={page - 1} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} /> : undefined}
            </>
          )}
        </>
      </Wrapper>
    </Box>
  );
};

export default Table;

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import { getAuditReportsList, getCountriesList, getAgenciesList } from "@local/actions";
import { getAuditReportsColumns } from "../../utils/columns";
import { adaptCountries } from "@local/utils/adapters";
import AuditDetailsModal from "./components/modal";
import Breadcrumb from "@local/components/breadcrumb";
import { auditDetailsNav } from "../../utils/constants";

const Audit = ({ getAuditReports, countries, getCountries, getAgencies, partnerId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleChange = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  const tabs = auditDetailsNav;

  useEffect(() => {
    getAgencies();
    getCountries();
  }, []);

  const onClickOpenModal = (row) => {
    setSelectedRow(row);
    setIsModalVisible(true);
  };
  const onClickCloseModal = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            id: 1,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.audit.idx.dashboardTitle",
            }),
            url: `/partner/${partnerId}/risk/dashboard`,
          },
          {
            id: 2,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.audit.idx.dashboardTitleOne",
            }),
            url: `/partner/${partnerId}/risk/audit`,
          },
        ]}
      />
      <Table
        key="risk_audits_list"
        id="risk_audits_list"
        title={formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.audit.idx.dashboardTitleOne",
        })}
        columns={getAuditReportsColumns(countries, onClickOpenModal)}
        fetchFunction={getAuditReports}
        onClickOpenModal={onClickOpenModal}
      />
      <AuditDetailsModal audit={selectedRow} isOpen={isModalVisible} onClose={onClickCloseModal} handleChange={handleChange} tabs={tabs} index={selectedTabIndex} />
    </>
  );
};

Audit.propTypes = {};

const mapStateToProps = (state) => ({
  agencies: state?.agenciesList?.data?.data?.results ?? [],
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAuditReports: (params) => dispatch(getAuditReportsList({ ...params, partner: ownProps.partnerId })),
  getCountries: () => dispatch(getCountriesList()),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Audit);

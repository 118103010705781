import React from "react";
import Table from "@local/components/table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getApplicationsColumns } from "../../utils/columns";
import { getApplicationsFilters } from "../../utils/filters";

const Applications = ({ fetchFunction, adapterFunction, countries, sectors, agencies, initialFilters }) => {
  return (
    <Table
      filters={getApplicationsFilters(countries, sectors, agencies)}
      key="partner_applications"
      id="partner_applications"
      title={formatMessage({
        id: "pages.partner.details.cmp.applications.idx.applications",
      })}
      columns={getApplicationsColumns()}
      fetchFunction={fetchFunction}
      adapterFunction={adapterFunction}
      initialFilters={initialFilters}
      withUrlSynchronization
    />
  );
};

Applications.propTypes = {};

export default Applications;

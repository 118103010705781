import axios from "axios";
import moment from "moment-timezone";
import store from "@local/store";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";

export const getBaseUrl = () => (process.env.REACT_APP_BACKEND_HOST ? process.env.REACT_APP_BACKEND_HOST : "");

const getHeaders = () => {
  const { token, partnerId, officeId } = store.getState().session;
  let headers = {
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    "Client-Timezone-Name": moment.tz.guess(),
  };
  if (token) headers = { ...headers, Authorization: `token ${token}` };
  if (partnerId) headers = { ...headers, "Partner-ID": partnerId };
  if (officeId) headers = { ...headers, "Agency-Office-ID": officeId };
  return headers;
};

const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const requestGet = ({ url, params }) =>
  axios.get(url, {
    baseURL: `${getBaseUrl()}/api`,
    headers: getHeaders(),
    params,
  });

export const requestPost = ({ url, data }) =>
  axios.post(url, data, {
    baseURL: `${getBaseUrl()}/api`,
    headers: { ...getHeaders(), "X-CSRFToken": getCookie("csrftoken") },
  });

export const requestDelete = ({ url, params }) =>
  axios.delete(url, {
    baseURL: `${getBaseUrl()}/api`,
    headers: { ...getHeaders(), "X-CSRFToken": getCookie("csrftoken") },
    params,
  });

export const requestPatch = ({ url, data }) =>
  axios.patch(url, data, {
    baseURL: `${getBaseUrl()}/api`,
    headers: { ...getHeaders(), "X-CSRFToken": getCookie("csrftoken") },
  });

export const requestPut = ({ url, data }) =>
  axios.put(url, {
    baseURL: `${getBaseUrl()}/api`,
    headers: { ...getHeaders(), "X-CSRFToken": getCookie("csrftoken") },
    data,
  });

export const requestUpload = (data) =>
  axios.post("/common/file/", data, {
    baseURL: `${getBaseUrl()}/api`,
    headers: { ...getHeaders(), "X-CSRFToken": getCookie("csrftoken") },
  });

export const getReverseGeocode = (params) =>
  axios.get("https://api.bigdatacloud.net/data/reverse-geocode-client", {
    params,
  });

export const downloadFile = async (url, fileType) => {
  try {
    const result = await axios.get(url, {
      baseURL: `${getBaseUrl()}/api`,
      headers: getHeaders(),
      responseType: "blob",
    });
    const urlObject = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = urlObject;
    const filename_match = (result?.headers["content-disposition"] ?? result?.headers["Content-Disposition"])?.match(/filename="(.+)"/);
    if (filename_match) {
      link.setAttribute("download", filename_match[1]);
    } else {
      link.setAttribute("download", `export.${fileType}`);
    }
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(urlObject);
    document.body.removeChild(link);
  } catch (error) {
    toast.error(formatMessage({ id: "utils.network.error" }));
  }
};

import React from "react";
import FormattedMessage from "../legacy-utils/translator";

const initialState = {
  columns: [
    {
      name: "legal_name",
      title: <FormattedMessage id="reducers.partnersPreselectionList.legalName" />,
      width: 200,
    },
    {
      name: "type_org",
      title: <FormattedMessage id="reducers.partnersPreselectionList.typeOrg" />,
    },
    {
      name: "id",
      title: <FormattedMessage id="reducers.partnersPreselectionList.id" />,
    },
    {
      name: "your_score",
      title: <FormattedMessage id="reducers.partnersPreselectionList.yourScore" />,
    },
    {
      name: "average_total_score",
      title: <FormattedMessage id="reducers.partnersPreselectionList.averageTotalScore" />,
    },
    {
      name: "recommended_partner",
      title: <FormattedMessage id="reducers.partnersPreselectionList.recommendedPartner" />,
      width: 330,
    },
  ],
};

export default function partnersPreselectedListReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import { userStatusColor } from "@local/styles/muiTheme";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import style from "../style.module.scss";

export const getUserColumns = (onClickEditUser, onClickToggleUser, statuses, userId) => [
  {
    id: "fullname",
    title: formatMessage({
      id: "pages.users.management.utils.columns.fullname",
    }),
  },
  {
    id: "email",
    title: formatMessage({ id: "pages.users.management.utils.columns.email" }),
  },
  {
    id: "roles",
    title: formatMessage({ id: "pages.users.management.utils.columns.roles" }),
    type: "custom",
    render: (row) => <span>{row?.office_memberships?.map((item) => `${item.role_display} (${item?.office?.name ?? "--"})`)?.join(", ") ?? "--"}</span>,
  },
  {
    id: "status",
    title: formatMessage({ id: "pages.users.management.utils.columns.status" }),
    type: "custom",
    render: (row) => {
      const status = statuses?.find((item) => item.value === row.status);
      if (status) {
        return <Chip icon={<CircleIcon style={{ fill: userStatusColor[status.value] }} fontSize="small" />} label={status.label} />;
      }
      return "--";
    },
  },
  {
    id: "actions",
    title: formatMessage({
      id: "pages.users.management.utils.columns.actions",
    }),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {row.is_active ? (
            <div style={{ marginRight: ".5rem" }}>
              <IconButton size="small" onClick={() => onClickEditUser(row)}>
                <EditIcon />
              </IconButton>
            </div>
          ) : (
            <></>
          )}
          <div>
            <IconButton size="small" onClick={() => onClickToggleUser(row, !row.is_active)} disabled={row.id === userId}>
              {row.is_active ? (
                <Tooltip
                  title={formatMessage({
                    id: "pages.users.management.utils.columns.deactivate",
                  })}
                >
                  <DoDisturbIcon />
                </Tooltip>
              ) : (
                <Tooltip
                  title={formatMessage({
                    id: "pages.users.management.utils.columns.activate",
                  })}
                >
                  <CheckCircleOutlineIcon />
                </Tooltip>
              )}
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

export const getOfficesColumns = (countries, onClickEditRegionalOffice, onClickOpenDeleteRegionalOfficeModal) => [
  {
    id: "name",
    title: formatMessage({ id: "pages.users.management.utils.columns.name" }),
  },
  {
    id: "code",
    title: formatMessage({ id: "pages.users.management.utils.columns.code" }),
  },
  {
    id: "countries",
    title: formatMessage({
      id: "pages.users.management.utils.columns.offices",
    }),
    type: "custom",
    render: (row) => row.countries.map((country) => <Chip className={style.countryChip} label={countries[country]} />),
  },
  {
    id: "actions",
    title: formatMessage({
      id: "pages.users.management.utils.columns.actionsOne",
    }),
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginRight: ".5rem" }}>
            <IconButton size="small" onClick={() => onClickEditRegionalOffice(row)}>
              <EditIcon />
            </IconButton>
          </div>
          <div>
            <IconButton size="small" onClick={() => onClickOpenDeleteRegionalOfficeModal(row)}>
              <Tooltip
                title={formatMessage({
                  id: "pages.users.management.utils.columns.delete",
                })}
              >
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      );
    },
  },
];

import React from "react";
import HeaderNavigation from "@local/components/header-navigation";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import MainContent from "@local/components/main-content-wrapper";

const Container = ({ children }) => {
  return (
    <HeaderNavigation title={formatMessage({ id: "pages.faq.cmp.container.idx.header" })}>
      <MainContent>{children}</MainContent>
    </HeaderNavigation>
  );
};

Container.propTypes = {};

export default Container;

import React, { useState } from "react";
import LastProfileUpdate from "./lastProfileUpdate";
import ListOfSubmittedConceptNotes from "./listOfSubmittedConceptNotes";
import ListOfPendingOffers from "./listOfPendingOffers";
import NumberOfAwards from "./numberOfAwards";
import NumberOfCfeisBySector from "./numberOfCfeisBySector";
import NumberOfPinnedCfei from "./numberOfPinnedCfeis";
import NumberOfSubmittedConceptNotes from "./numberOfSubmittedConceptNotes";
import Grid from "@mui/material/Grid";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Button from "@local/components/button";
import { useHistory } from "react-router-dom";

const PartnerDashboard = ({
  isLoading,
  dashboard: { new_cfei_by_sectors_last_days_ago: newCfeiBySectors, num_of_submitted_cn: { count: numSubmittedCN, details: numSubmittedCNByAgency } = {}, num_of_pinned_cfei: numPinnedCfei, num_of_awards: numAwards, last_profile_update: lastUpdate },
}) => {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(localStorage.getItem("newlyRegistered") === "true");
  const history = useHistory();

  const onClickCloseWelcomeModal = () => {
    localStorage.setItem("newlyRegistered", "false");
    setIsWelcomeModalOpen(false);
  };

  const onClickViewProfile = () => {
    setIsWelcomeModalOpen(false);
    localStorage.setItem("newlyRegistered", "false");
    history.push("/profile");
  };

  const onClickViewProjects = () => {
    setIsWelcomeModalOpen(false);
    localStorage.setItem("newlyRegistered", "false");
    history.push("/cfei/open");
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NumberOfCfeisBySector isLoading={isLoading} newCfeiBySectors={newCfeiBySectors} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NumberOfSubmittedConceptNotes isLoading={isLoading} numSubmittedCN={numSubmittedCN} numSubmittedCNByAgency={numSubmittedCNByAgency} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NumberOfPinnedCfei isLoading={isLoading} number={numPinnedCfei} />
            </Grid>
            <Grid item xs={12}>
              <NumberOfAwards isLoading={isLoading} number={numAwards} />
            </Grid>
            <Grid item xs={12}>
              <LastProfileUpdate isLoading={isLoading} date={lastUpdate} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ListOfSubmittedConceptNotes />
        </Grid>
        <Grid item xs={12}>
          <ListOfPendingOffers />
        </Grid>
      </Grid>
      <Modal
        isOpen={isWelcomeModalOpen}
        onClose={onClickCloseWelcomeModal}
        title={formatMessage({
          id: "pages.dashboard.cmp.partner.partnerDashboard.title",
        })}
        small
        footer={
          <>
            <Button
              onClick={onClickViewProjects}
              variant="text"
              text={formatMessage({
                id: "pages.dashboard.cmp.partner.partnerDashboard.cfeiButton",
              })}
            />
            <Button
              onClick={onClickViewProfile}
              text={formatMessage({
                id: "pages.dashboard.cmp.partner.partnerDashboard.profileButton",
              })}
            />
          </>
        }
      >
        {formatMessage({
          id: "pages.dashboard.cmp.partner.partnerDashboard.text",
        })}
      </Modal>
    </>
  );
};

PartnerDashboard.propTypes = {};

export default PartnerDashboard;

import { RISK_FLAGS_REASONS, UN_AGENCIES } from "@local/legacy-utils/constants";
import { allowedAgencies } from "@local/utils/constants";

export const adaptPartnerCountries = (countryKey, countries) =>
  countries
    .filter((country) => countryKey.includes(country.value))
    .map((item) => item.label)
    .join(", ");

export const adaptFlagReasons = (reason) => Object.values(RISK_FLAGS_REASONS).find((item) => item.value === reason);

export const getVendorNumberFromState = (state, agencyId) => {
  let vendorNumbers = [];
  if (state?.session?.role === "partner") vendorNumbers = state?.partnerProfileDataDetails?.data?.data?.vendor_numbers ?? [];
  else vendorNumbers = (state?.partnerSummaryDetails?.data?.data?.vendor_numbers || state?.specialPartnerDetails?.data?.vendor_numbers) ?? [];
  return vendorNumbers.find((vendorNumber) => vendorNumber?.agency_id === agencyId);
};

export const getVendorNumbersListFromState = (state) => {
  let vendorNumbers = [];
  if (state?.session?.role === "partner") vendorNumbers = state?.partnerProfileDataDetails?.data?.data?.vendor_numbers ?? [];
  else vendorNumbers = (state?.partnerSummaryDetails?.data?.data?.vendor_numbers || state?.specialPartnerDetails?.data?.vendor_numbers) ?? [];
  return vendorNumbers;
};

export const getCountryTotal = (countryItem) => countryItem.data.reduce((total, item) => total + item.amount, 0);

export const adaptUNAgencies = (agencies) => agencies.filter((agency) => allowedAgencies.includes(agency.name)).map((item) => item.name);

export const adapterFinancialDetails = (data) => {
  const currentYear = new Date().getFullYear();
  const agencies = data?.map((item) => item.agency);
  const financialDetails = data?.map((item) => ({
    agency: item?.agency,
    budget: item?.data["financial-data"]?.[currentYear]?.budget,
    cash_at_hand: item?.data["financial-data"]?.[currentYear]?.["cash-at-hand"],
    expenditure_current_year: item?.data["financial-data"]?.[currentYear]?.expenditure,
    expenditure_previous_year: item?.data["financial-data"]?.[currentYear - 1]?.expenditure,
    expenditure_previous_two_year: item?.data["financial-data"]?.[currentYear - 2]?.expenditure,
    expenditure_previous_three_year: item?.data["financial-data"]?.[currentYear - 3]?.expenditure,
  }));

  const otherAgencies = UN_AGENCIES.filter((agency) => (!agencies.includes(agency.value) ? agency.label : undefined));
  return [...financialDetails, otherAgencies.map((item) => ({ agency: item?.label }))].flat(1).sort((a, b) => (a.agency < b.agency ? -1 : 1));
};

export const adapterFinancialDetailsForAgency = (data, agency) => {
  const currentYear = new Date().getFullYear();
  const financialDetails = data?.map((item) => ({
    agency: item?.agency,
    budget: item?.data["financial-data"]?.[currentYear]?.budget,
    cash_at_hand: item?.data["financial-data"]?.[currentYear]?.["cash-at-hand"],
    expenditure_current_year: item?.data["financial-data"]?.[currentYear]?.expenditure,
    expenditure_previous_year: item?.data["financial-data"]?.[currentYear - 1]?.expenditure,
    expenditure_previous_two_year: item?.data["financial-data"]?.[currentYear - 2]?.expenditure,
    expenditure_previous_three_year: item?.data["financial-data"]?.[currentYear - 3]?.expenditure,
  }));
  return financialDetails?.filter((item) => item?.agency === agency?.name);
};

import { ROLES } from "@local/legacy-utils/constants";
import { getPseaCoreStandards } from "@local/utils/constants";
import { formatDateToBeSent, getBooleanValue } from "@local/utils";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { parseISO } from "date-fns";
import { formatDate } from "@local/pages/partner-details/components/psea/utils";

export const adaptInitialAssessmentFormValuesToBeSent = (formValues, role) => {
  let result = {};
  if (role === ROLES.AGENCY) {
    const agency_beneficiaries = getBooleanValue(formValues?.agency_beneficiaries);
    const agency_is_assessed = getBooleanValue(formValues?.agency_is_assessed);
    const agency_un_agency_submitter = formValues?.agency_un_agency_submitter;
    const agency_submission_date = formatDateToBeSent(formValues?.agency_submission_date);
    const agency_rating = formValues?.agency_rating;
    const different_rating_comment = formValues?.different_rating_comment;

    let assessmentValues = {};

    if (agency_is_assessed) {
      assessmentValues = {
        agency_un_agency_submitter,
        agency_submission_date,
        agency_rating,
        different_rating_comment,
      };
    }

    result = {
      agency_is_assessed,
      agency_beneficiaries,
      agency_rating: formValues?.rating ?? null,
      ...assessmentValues,
    };
  } else {
    const partner_beneficiaries = getBooleanValue(formValues?.partner_beneficiaries);
    const partner_is_assessed = getBooleanValue(formValues?.partner_is_assessed);
    const proceed_with_self_assessment = getBooleanValue(formValues?.proceed_with_self_assessment);
    const partner_un_agency_submitter = formValues?.partner_un_agency_submitter;
    const partner_submission_date = formatDateToBeSent(formValues?.partner_submission_date);
    const partner_rating = formValues?.partner_rating;

    let partnerBeneficiariesValues = {};
    let assessmentValues = {};

    if (partner_beneficiaries === false) {
      partnerBeneficiariesValues = {
        proceed_with_self_assessment,
      };
    } else {
      partnerBeneficiariesValues = {
        proceed_with_self_assessment: null,
      };
    }
    if (partner_is_assessed) {
      assessmentValues = {
        partner_un_agency_submitter,
        partner_submission_date,
        partner_rating,
      };
    } else {
      assessmentValues = {
        un_agency_submitter: null,
        submission_date: null,
        partner_rating: null,
      };
    }

    result = {
      partner_is_assessed,
      partner_beneficiaries,
      ...partnerBeneficiariesValues,
      ...assessmentValues,
      ...partnerBeneficiariesValues,
    };
  }
  return result;
};

export const adaptSelfAssessmentFormValuesToBeSent = (formValues, role) => {
  const coreStandards = getPseaCoreStandards();
  if (role === ROLES.PARTNER) {
    const partner_response = [];
    coreStandards.forEach((item) => {
      if (formValues[`partner_${item.value}`]) {
        partner_response.push({
          core_standards: item.value,
          partner_answer: formValues[`partner_${item.value}`],
          partner_comment: formValues[`partner_${item.value}_comment`],
        });
      }
    });
    return {
      partner_response,
    };
  } else {
    const agency_response = [];
    coreStandards.forEach((item) => {
      if (formValues[`agency_${item.value}`]) {
        agency_response.push({
          core_standards: item.value,
          agency_answer: formValues[`agency_${item.value}`],
          agency_comment: formValues[`agency_${item.value}_comment`],
        });
      }
    });
    return {
      agency_response,
    };
  }
};

export const adaptSelfAssessmentFormValuesInitialValues = (pseaSelfAssessment) => {
  if (!pseaSelfAssessment?.questions) return undefined;
  const coreStandards = getPseaCoreStandards();
  const result = {};
  coreStandards.forEach((item) => {
    const question = pseaSelfAssessment?.questions?.find((question) => question.core_standards === item.value);
    result[`partner_${item.value}`] = question?.partner_answer;
    result[`partner_${item.value}_comment`] = question?.partner_comment;
    result[`agency_${item.value}`] = question?.agency_answer;
    result[`agency_${item.value}_comment`] = question?.agency_comment;
  });
  return result;
};

export const adaptAgencyInitialValues = (pseaInitialAssessment) => {
  return {
    partner_is_assessed: (pseaInitialAssessment?.partner_is_assessed ?? undefined) !== undefined ? (pseaInitialAssessment?.partner_is_assessed ? "true" : "false") : undefined,
    agency_is_assessed: (pseaInitialAssessment?.agency_is_assessed ?? undefined) !== undefined ? (pseaInitialAssessment?.agency_is_assessed ? "true" : "false") : undefined,
    partner_beneficiaries: (pseaInitialAssessment?.partner_beneficiaries ?? undefined) !== undefined ? (pseaInitialAssessment?.partner_beneficiaries ? "true" : "false") : undefined,
    agency_beneficiaries: (pseaInitialAssessment?.agency_beneficiaries ?? undefined) !== undefined ? (pseaInitialAssessment?.agency_beneficiaries ? "true" : "false") : undefined,
    proceed_with_self_assessment: (pseaInitialAssessment?.proceed_with_self_assessment ?? undefined) !== undefined ? (pseaInitialAssessment.proceed_with_self_assessment ? "true" : "false") : undefined,
    agency_submission_date: pseaInitialAssessment?.agency_submission_date ? parseISO(pseaInitialAssessment?.agency_submission_date) : undefined,
    agency_un_agency_submitter: pseaInitialAssessment?.agency_un_agency_submitter,
    agency_rating: pseaInitialAssessment?.agency_rating,
    partner_submission_date: pseaInitialAssessment?.partner_submission_date ? parseISO(pseaInitialAssessment?.partner_submission_date) : undefined,
    partner_un_agency_submitter: pseaInitialAssessment?.partner_un_agency_submitter,
    partner_rating: pseaInitialAssessment?.partner_rating,
    different_rating_comment: pseaInitialAssessment?.different_rating_comment,
  };
};

export const adaptPartnerInitialValues = (pseaInitialAssessment) => {
  return {
    partner_is_assessed: (pseaInitialAssessment?.partner_is_assessed ?? undefined) !== undefined ? (pseaInitialAssessment?.partner_is_assessed ? "true" : "false") : undefined,
    partner_beneficiaries: (pseaInitialAssessment?.partner_beneficiaries ?? undefined) !== undefined ? (pseaInitialAssessment?.partner_beneficiaries ? "true" : "false") : undefined,
    proceed_with_self_assessment: (pseaInitialAssessment?.proceed_with_self_assessment ?? undefined) !== undefined ? (pseaInitialAssessment.proceed_with_self_assessment ? "true" : "false") : undefined,
    partner_submission_date: pseaInitialAssessment?.partner_submission_date ? parseISO(pseaInitialAssessment?.partner_submission_date) : undefined,
    partner_un_agency_submitter: pseaInitialAssessment?.partner_un_agency_submitter,
    partner_rating: pseaInitialAssessment?.partner_rating,
  };
};

export const subtractYears = (date, years) => {
  date.setFullYear(date.getFullYear() - years);
  return date;
};

export const getAgencySelfAssessmentStatus = (pseaSelfAssessment) => {
  if (pseaSelfAssessment?.unlock_assessment_justification && pseaSelfAssessment?.is_finalized_by_partner === false) {
    return "unlocked";
  } else if (pseaSelfAssessment?.questions?.find((item) => item?.agency_answer) && pseaSelfAssessment?.is_finalized_by_agency === false) {
    return "in_progress";
  } else if (pseaSelfAssessment?.is_finalized_by_agency) {
    return "completed";
  } else return "not_available";
};

export const getAgencySelfAssessmentStatusMessage = (pseaSelfAssessment) => {
  const status = getAgencySelfAssessmentStatus(pseaSelfAssessment);
  switch (status) {
    case "unlocked":
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.questionsTableHeaderTwoSubtitle",
      });
    case "in_progress":
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.questionsTableHeaderTwoSubtitleInProgress",
      });
    case "completed":
      return `${formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.completedBy",
      })} ${pseaSelfAssessment?.finalized_agency} ${formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.completedOn",
      })} ${formatDate(pseaSelfAssessment?.agency_finalized_on)}`;
    case "not_available":
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.questionsTableHeaderTwoSubtitle",
      });
    default:
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.questionsTableHeaderTwoSubtitle",
      });
  }
};

export const getPartnerSelfAssessmentStatus = (role, pseaSelfAssessment) => {
  if (role === ROLES.AGENCY && pseaSelfAssessment?.unlock_assessment_justification && pseaSelfAssessment?.is_finalized_by_partner === false) {
    return "unlocked";
  } else if (pseaSelfAssessment?.psea && pseaSelfAssessment?.questions?.find((item) => item?.partner_answer) && (pseaSelfAssessment?.is_finalized_by_partner ?? false) === false && !pseaSelfAssessment?.is_finalized_by_agency) {
    return "in_progress";
  } else if (pseaSelfAssessment?.is_finalized_by_partner) {
    return "completed";
  } else return "not_available";
};

export const getPartnerSelfAssessmentStatusMessage = (role, pseaSelfAssessment) => {
  const status = getPartnerSelfAssessmentStatus(role, pseaSelfAssessment);
  switch (status) {
    case "unlocked":
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.pendingPartnerRevision",
      });
    case "in_progress":
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.questionsTableHeaderTwoSubtitleInProgress",
      });
    case "completed":
      return `${formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.completedBy",
      })} ${pseaSelfAssessment?.finalized_partner?.name} ${formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.completedOn",
      })} ${formatDate(pseaSelfAssessment?.partner_finalized_on)}`;
    case "not_available":
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.questionsTableHeaderTwoSubtitle",
      });
    default:
      return formatMessage({
        id: "pages.partner.details.cmp.psea.utils.adapters.questionsTableHeaderTwoSubtitle",
      });
  }
};

import React, { useState } from "react";
import { connect } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import FieldFile from "@local/components/field-file";
import { updateProjectApplication } from "@local/actions";
import style from "./style.module.scss";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";
import { PROJECT_STATUSES } from "@local/legacy-utils/constants";
import Checkbox from "@mui/material/Checkbox";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import { useHistory } from "react-router-dom";

// POST_REFACTORING_TODO: translations

const getForm = (application, isCnRequired, onSubmit, formValues, project, isProfileUpToDate, setIsProfileUpToDate, onClickViewProfile, setIsChangeEnabled, isChangeEnabled) => {
  if (!application) {
    return <></>;
  }
  if (application?.did_accept === false && application?.did_decline === true && !isChangeEnabled) {
    return (
      <div className={style.resultFormDeclinedMainWrapper}>
        <div className={style.resultFormTitleWrapper}>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.info",
            })}
          </span>
        </div>
        <div className={style.resultFormDeclinedWrapper}>
          <RemoveCircleIcon fontSize="small" style={{ color: "#DB5163" }} />
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.declined",
            })}
          </span>
        </div>
        {project?.status === PROJECT_STATUSES.OPE ? (
          <div className={style.changeWrapper}>
            <Button type="text" variant="text" text="Change" onClick={setIsChangeEnabled} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
  if (application?.did_accept === true && application?.did_decline === false && !isChangeEnabled) {
    return (
      <div className={style.resultFormConfirmedMainWrapper}>
        <div className={style.resultFormTitleWrapper}>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.info",
            })}
          </span>
        </div>
        <div className={style.resultFormConfirmedWrapper}>
          <CheckCircleIcon fontSize="small" style={{ color: "#64BA19" }} />
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.confirmed",
            })}
          </span>
        </div>
        {project?.status === PROJECT_STATUSES.OPE ? (
          <div className={style.changeWrapper}>
            <Button type="text" variant="text" text="Change" onClick={setIsChangeEnabled} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
  if ((application?.did_accept === false && application?.did_decline === false) || isChangeEnabled) {
    return (
      <div className={style.resultFormInputMainWrapper}>
        <div className={style.resultFormTitleWrapper}>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.info",
            })}
          </span>
          <span className={style.acceptLabel}>Would you like to accept and move forward?</span>
        </div>
        <div className={style.resultFormInputWrapper}>
          <FieldGroup>
            <Field
              component={FieldRadioGroup}
              id="confirmation"
              name="confirmation"
              label={formatMessage({
                id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.infoQuestion",
              })}
              placeholder={formatMessage({
                id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.infoQuestion",
              })}
              fullWidthLabel
              options={[
                {
                  value: true,
                  label: formatMessage({
                    id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.yes",
                  }),
                },
                {
                  value: false,
                  label: formatMessage({
                    id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.no",
                  }),
                },
              ]}
            />
            {isCnRequired && formValues?.confirmation?.toString() === "true" ? (
              <Field
                component={FieldFile}
                id="cn"
                name="cn"
                label={formatMessage({
                  id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.cn",
                })}
                placeholder={formatMessage({
                  id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.cn",
                })}
              />
            ) : (
              <></>
            )}
          </FieldGroup>
          <div className={style.checkWrapper}>
            <div className={style.checkFieldWrapper}>
              <Checkbox checked={isProfileUpToDate} onChange={setIsProfileUpToDate} disabled={!formValues?.confirmation} />
            </div>
            <span className={style.checkInfoWrapper}>
              <span>
                {formatMessage({
                  id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.confirmLabel",
                })}
              </span>
              <span className={style.checkInfo}>
                <span>
                  {formatMessage({
                    id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.lastUpdate",
                  })}{" "}
                  {formatDateForPrint(new Date())}
                </span>
                <span>
                  {formatMessage({
                    id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.notSure",
                  })}
                </span>
                <div className={style.checkActionWrapper}>
                  <Button
                    type="text"
                    variant="text"
                    text={formatMessage({
                      id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.viewProfile",
                    })}
                    onClick={onClickViewProfile}
                  />
                </div>
              </span>
            </span>
          </div>
          <div className={style.resultFormActionWrapper}>
            <Button
              text={formatMessage({
                id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.button",
              })}
              onClick={onSubmit}
              endIcon={<SendIcon />}
              isDisabled={(isCnRequired && formValues?.confirmation?.toString() === "true" && !formValues?.cn?.id) || (formValues?.confirmation?.toString() === "true" && !isProfileUpToDate) || (formValues?.confirmation ?? undefined) === undefined}
            />
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

const PartnerResponseForm = ({ application, formValues, submitConfirmation, isCnRequired, refreshFunction, project, partnerId }) => {
  const [isProfileUpToDate, setIsProfileUpToDate] = useState(false);
  const [isChangeEnabled, setIsChangeEnabled] = useState(false);
  const history = useHistory();

  const onSubmit = async () => {
    try {
      setIsChangeEnabled(false);
      await submitConfirmation({
        did_accept: formValues?.confirmation?.toString() === "true",
        did_decline: formValues?.confirmation === "false",
        cn: isCnRequired && formValues?.confirmation?.toString() === "true" ? formValues?.cn?.id : undefined,
      });
      refreshFunction();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.project.details.cmp.direct.project.response.cmp.partner.response.form.idx.reminderError",
        }),
      );
    }
  };

  const onChangeIsProfileUpToDate = () => {
    const updatedValue = !isProfileUpToDate;
    setIsProfileUpToDate(updatedValue);
  };

  const onClickViewProfile = () => {
    history.push(`/profile/${partnerId}/overview`);
  };

  const onClickChange = () => {
    setIsChangeEnabled(true);
  };

  return <>{getForm(application, isCnRequired, onSubmit, formValues, project, isProfileUpToDate, onChangeIsProfileUpToDate, onClickViewProfile, onClickChange, isChangeEnabled)}</>;
};

PartnerResponseForm.propTypes = {};

const mapStateToProps = (state) => ({
  formValues: state?.form?.partnerResponseForm?.values,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitConfirmation: (body) => dispatch(updateProjectApplication(body, { id: ownProps?.application?.id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "partnerResponseForm", enableReinitialize: true })(PartnerResponseForm));

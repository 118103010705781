export const formFieldArray = [
  "displayType",
  "isHq",
  "legalName",
  "otherNames",
  "countryCode",
  "headOrganizationFullname",
  "headOrganizationEmail",
  "yearEstablishment",
  "registeredToOperateInCountry",
  "registrationDocument",
  "issuingAuthority",
  "issueDate",
  "registrationNumber",
  "missingRegistrationDocumentComment",
  "haveGoverningDocument",
  "governingDocument",
  "missingGoverningDocumentComment",
  "ad1",
  "ad2",
  "ad3",
  "ad4",
  "ad5",
  "ad6",
  "ad7",
  "ad8",
  "ad9",
  "ad10",
  "ad11",
  "ad12",
];

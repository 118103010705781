import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import Button from "@local/components/button";
import ConfirmModal from "@local/components/confirm-modal";
import Alert from "@local/components/alert";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import style from "./style.module.scss";

const Verification = ({ fetchFunction, columns, filters, adapterFunction, hasVerificationReportPermission, exportVerificationDetailsReports, showDownloadInfo, downloadInfo, isOpen, onClose, onCloseDownloadInfo, onChangeTableFilters }) => {
  return (
    <>
      <Collapse in={showDownloadInfo}>
        <Alert
          type="success"
          content={
            <>
              <span className={style.alertTitle}>
                {formatMessage({
                  id: "pages.reports.list.cmp.verification.idx.report",
                })}
              </span>
              <span>{downloadInfo}</span>
            </>
          }
          actionLabel={formatMessage({
            id: "pages.reports.list.cmp.verification.idx.ok",
          })}
          onClickAction={onCloseDownloadInfo}
          withBottomMargin
        />
      </Collapse>
      <Table
        key="verification_reports_list"
        id="verification_reports_list"
        title={formatMessage({
          id: "pages.reports.list.cmp.verification.idx.verification",
        })}
        columns={columns}
        fetchFunction={fetchFunction}
        filters={filters}
        filterInfo={formatMessage({
          id: "pages.reports.list.cmp.verification.idx.select",
        })}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        withUrlSynchronization
        actions={
          hasVerificationReportPermission && (
            <Button
              variant="text"
              text={formatMessage({
                id: "pages.reports.list.cmp.verification.idx.exportReport",
              })}
              onClick={exportVerificationDetailsReports}
            />
          )
        }
      />
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={exportVerificationDetailsReports}
        title={formatMessage({
          id: "pages.reports.list.cmp.verification.idx.reportWarning",
        })}
        type="warning"
        message={
          <div className={style.infoWrapper}>
            <Typography variant="subtitle1" component="div">
              <Alert
                type="info"
                content={formatMessage({
                  id: "pages.reports.list.cmp.verification.idx.infoTitle",
                })}
                withBottomMargin
              />
            </Typography>
            <Typography variant="body1">
              <div className={style.infoText}>
                {formatMessage({
                  id: "pages.reports.list.cmp.verification.idx.info",
                })}
              </div>
            </Typography>
          </div>
        }
      />
    </>
  );
};

Verification.propTypes = {};

export default Verification;

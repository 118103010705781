/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MUITable from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import CircularProgress from "@mui/material/CircularProgress";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import style from "./style.module.scss";
import { Link } from "react-router-dom";

const getValue = (column, row) => {
  const splitted = column.id.split(".");
  if (splitted.length === 1) {
    return row[column.id];
  }
  const updated = row[splitted[0]];
  splitted.shift();
  return getValue({ id: splitted.join(".") }, updated);
};

const getCell = (column, row) => {
  switch (column.type) {
    case "date":
      return <span>{formatDateForPrint(getValue(column, row))}</span>;
    case "number":
      return <span style={{ textAlign: "right" }}>{formatDateForPrint(getValue(column, row))}</span>;
    case "link":
      return (
        <Link alt={column.title} to={column.to(row)} style={{ textAlign: "right" }}>
          {getValue(column, row)}
        </Link>
      );
    case "custom":
      return column.render(row);
    case "clickable":
      return (
        <span onClick={() => column.onClick(row)} style={{ textAlign: "right" }}>
          {getValue(column, row)}
        </span>
      );
    default:
      return <span>{getValue(column, row)}</span>;
  }
};

const getWrapperComponent = (isBordered) => {
  if (isBordered) {
    return ({ children }) => <div className={style.borderedTable}>{children}</div>;
  }
  return ({ children }) => <Paper sx={{ width: "100%", mb: 0 }}>{children}</Paper>;
};

const StaticTable = ({ id, columns, fetchFunction, adapterFunction = undefined, title = undefined, isSelectable = false, keyColumn, actions = undefined, shouldRefresh = false, afterRefresh = undefined, secondaryColumns = [], isBordered = false }) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    onPageChange();
  }, []);

  useEffect(() => {
    if (shouldRefresh && afterRefresh) {
      onPageChange();
      afterRefresh();
    }
  }, [shouldRefresh, afterRefresh]);

  const onPageChange = async () => {
    try {
      setIsLoading(true);
      const result = await fetchFunction();
      setRows(adapterFunction ? adapterFunction(result?.data?.results ?? []) : result?.data ?? []);
      setIsLoading(false);
    } catch (error) {
      toast.error(formatMessage({ id: "cmp.static.table.idx.error" }));
      setIsLoading(false);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const isAllSelected = selected.length === rows.length;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = rows.map((row) => row.id);
      setSelected(allSelected);
      return;
    } else setSelected([]);
  };

  const handleSelect = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const Wrapper = useMemo(() => getWrapperComponent(isBordered), [isBordered]);

  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        {title ? (
          <div className={style.titleWrapper}>
            <div>
              <Typography variant="subtitle2" component="div">
                {title}
              </Typography>
            </div>
            {actions ? <div className={style.actionsWrapper}>{actions}</div> : undefined}
          </div>
        ) : undefined}

        <>
          {isLoading ? (
            <div className={style.progressWrapper}>
              <CircularProgress color="primary" size={50} />
            </div>
          ) : (
            <>
              <TableContainer>
                <MUITable>
                  <TableHead>
                    {secondaryColumns.length ? (
                      <TableRow>
                        {secondaryColumns.map((column, index) =>
                          column.title !== undefined ? (
                            <TableCell colSpan={column.span} key={`table_head_row_cell_${id}_${index}`} align="center">
                              {column.title}
                            </TableCell>
                          ) : (
                            <></>
                          ),
                        )}
                      </TableRow>
                    ) : undefined}
                    <TableRow>
                      {isSelectable && rows.length > 0 && (
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isAllSelected} onChange={(event) => handleSelectAllClick(event)} />
                        </TableCell>
                      )}
                      {columns.map((column, index) => (
                        <TableCell width={column?.width ?? undefined} key={`table_head_row_cell_${id}_${index}`} align={column?.type === "number" ? "right" : "left"}>
                          {column.title}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows?.length ? (
                      <>
                        {rows.map((row, rowIndex) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${rowIndex}`;
                          return (
                            <TableRow key={`table_body_row_${id}_${rowIndex}`}>
                              {isSelectable && (
                                <TableCell padding="checkbox" onClick={(event) => handleSelect(event, row[keyColumn])}>
                                  <Checkbox
                                    color="primary"
                                    checked={isItemSelected}
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </TableCell>
                              )}
                              {columns.map((column, columnIndex) => (
                                <TableCell width={column?.width ?? undefined} key={`table_body_row_cell_${id}_${columnIndex}_${rowIndex}`} align={column?.type === "number" ? "right" : "left"}>
                                  {getCell(column, row)}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={columns.length}>{formatMessage({ id: "cmp.static.table.idx.noData" })}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </MUITable>
              </TableContainer>
            </>
          )}
        </>
      </Box>
    </Wrapper>
  );
};

StaticTable.propTypes = {};

export default StaticTable;

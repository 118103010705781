/* eslint-disable react-hooks/exhaustive-deps */
import Button from "@local/components/button";
import style from "./style.module.scss";
import { connect } from "react-redux";
import { getPartnerDuplicatesProfileDataDetails, updatePartnerDuplicatesProfileDataDetails } from "@local/actions";
import { useEffect, useState } from "react";
import SimilarityInfoArea from "./SimilarityInfoArea";
import PartnersInfoArea from "./PartnersInfoArea";
import DuplicateForm from "./DuplicateForm";
import ConfirmModal from "@local/components/confirm-modal";
import { toast } from "react-toastify";
import i18n from "@local/i18next/i18n";
import { Divider } from "@mui/material";
const PartnerDuplicateArea = ({ selectedPartnerDuplicate, partnerMatchingUpdate, formValues, getPartnersDuplicateProfileDetails, partnersDuplicate, onClose, setShouldRefresh }) => {
  //   Fetch partner data on mount and when selectedPartnerDuplicate changes
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isRevertConfirmModalOpen, setIsRevertConfirmModalOpen] = useState(false);
  useEffect(() => {
    if (selectedPartnerDuplicate) {
      getPartnersDuplicateProfileDetails();
    }
  }, []);

  const isButtonDisabled = (formValues) => {
    if (formValues?.is_duplicate === undefined) {
      return true;
    }
    if (formValues?.is_duplicate === "false") {
      return false;
    }
    if (formValues?.is_duplicate === "true" && formValues?.duplicate_object_id === undefined) {
      return true;
    }
  };

  const onSubmit = async () => {
    try {
      await partnerMatchingUpdate(formValues);
      toast.success(i18n.t("Update success"));
      onClose();
      setShouldRefresh(true);
    } catch (e) {
      toast.error(i18n.t("Update failed"));
    }
    setIsConfirmModalOpen(false);
  };

  const onSubmitRevert = async () => {
    try {
      await partnerMatchingUpdate({ revert: true });
      toast.success(i18n.t("Update success"));
      onClose();
      setShouldRefresh(true);
    } catch (e) {
      toast.error(i18n.t("Update failed"));
    }
    setIsConfirmModalOpen(false);
  };

  const confirmMessage = (formValues) => {
    return formValues?.is_duplicate === "true" ? i18n.t("Are you sure you want to delete this duplicate?") : i18n.t("Are you sure partners are not duplicates?");
  };

  return (
    <>
      <div className={style.mainWrapper}>
        <SimilarityInfoArea selectedPartnerDuplicate={partnersDuplicate} />
        <PartnersInfoArea partnerOne={partnersDuplicate?.partner} partnerTwo={partnersDuplicate?.duplicate_partner} />

        {partnersDuplicate?.is_duplicate === null && (
          <>
            <Divider />
            <DuplicateForm selectedPartnerDuplicate={partnersDuplicate} />
          </>
        )}
      </div>
      {partnersDuplicate?.is_duplicate === null && (
        <div style={{ float: "inline-end" }}>
          <Button isDisabled={isButtonDisabled(formValues)} text="Save" onClick={() => setIsConfirmModalOpen(true)} />
        </div>
      )}
      {(partnersDuplicate?.is_duplicate === true || partnersDuplicate?.is_duplicate === false) && (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <div style={{ fontWeight: "bold" }}>{i18n.t("Confirmed by: ")}</div>
            <div>
              {partnersDuplicate?.latest_history?.created_by?.fullname}
              {`(${partnersDuplicate?.latest_history?.created_by?.email}) of ${partnersDuplicate?.latest_history?.created_by?.agency}`}
            </div>
          </div>

          <Button text="Revert" onClick={() => setIsRevertConfirmModalOpen(true)} />
        </div>
      )}
      <ConfirmModal isOpen={isRevertConfirmModalOpen} onSubmit={onSubmitRevert} onClose={() => setIsRevertConfirmModalOpen(false)} message={i18n.t("Are you sure you want to revert this duplicate?")} type="info" />
      <ConfirmModal isOpen={isConfirmModalOpen} onSubmit={onSubmit} onClose={() => setIsConfirmModalOpen(false)} message={confirmMessage(formValues)} type="info" />
    </>
  );
};

PartnerDuplicateArea.propTypes = {};

const mapStateToProps = (state) => {
  return {
    partnersDuplicate: state?.partnersDuplicate?.data?.data,
    formValues: state?.form?.duplicatePartnersForm?.values,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPartnersDuplicateProfileDetails: (params) => dispatch(getPartnerDuplicatesProfileDataDetails(params, { id: ownProps?.selectedPartnerDuplicate?.id })),
  partnerMatchingUpdate: (body) => {
    let bodyToSend = null;
    if (body?.revert) {
      bodyToSend = { revert: true };
    } else {
      const is_duplicate = body?.is_duplicate === "true" ? true : false;
      const duplicate_object_id = body?.is_duplicate === "true" ? parseInt(body?.duplicate_object_id) : null;
      bodyToSend = is_duplicate ? { is_duplicate, duplicate_object_id, notify: body.notify } : { is_duplicate };
    }

    return dispatch(updatePartnerDuplicatesProfileDataDetails({ ...bodyToSend }, { id: ownProps?.selectedPartnerDuplicate?.id }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDuplicateArea);

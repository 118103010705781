import { Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import EventIcon from "@mui/icons-material/Event";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { ROLES } from "@local/legacy-utils/constants";
import Button from "@local/components/button";
import DownloadIcon from "@local/components/download-icon";
import Map from "@local/components/map";
import style from "../style.module.scss";
import { getFileName } from "./adapters";
import { getBaseUrl } from "@local/utils/network";

export const getOpenProjectItems = (project, sectors, agencies, displayGoal, concernTypes) => [
  {
    id: "title",
    label: formatMessage({
      id: "pages.project.details.utils.items.projectTitle",
    }),
    value: project?.title,
    isVisible: true,
  },
  {
    id: "focal_points",
    label: formatMessage({
      id: "pages.project.details.utils.items.projectProgrammeFocalPoints",
    }),
    value: project?.focal_points_detail?.map((item) => item.name).join(", "),
    isVisible: true,
  },
  {
    id: "locations",
    label: formatMessage({ id: "pages.project.details.utils.items.locations" }),
    type: "custom",
    value: () => {
      let countryCodes = new Set(project?.locations.map((location) => location?.admin_level_1?.country_code));
      const locations = project?.locations.map((location) => ({
        ...location,
        latitude: location?.lat,
        longitude: location?.lon,
      }));
      const countries = new Set(project?.locations.map((location) => location?.admin_level_1?.country_name));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.country",
              })}
            </span>
            {Array.from(countries).map((item) => (
              <Chip key={`country_${item}`} label={item} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.locationMapWrapper}>
            <Map height="400px" markers={locations ? locations : []} countryCodes={countryCodes ? Array.from(countryCodes) : []} readOnly />
          </div>
        </div>
      );
    },
    isVisible: project?.locations?.length > 0,
  },
  {
    id: "specializations",
    label: formatMessage({ id: "pages.project.details.utils.items.label" }),
    type: "custom",
    value: () => {
      const projectSpecializationsIds = project?.specializations.map((specialization) => specialization?.id);
      const getSectorSpecializationsIds = (sector) => sector?.specializations?.map((specialization) => specialization?.id);
      const projectSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => projectSpecializationsIds?.includes(sectorSpecializationId)));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.sector",
              })}
            </span>
            {projectSectors.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.areaOfSpecialization",
              })}
            </span>
            {project?.specializations.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
        </div>
      );
    },
    isVisible: project?.specializations?.length > 0,
  },
  {
    id: "population",
    label: formatMessage({ id: "pages.project.details.utils.items.populationOfConcern" }),
    type: "custom",
    value: () => {
      const projectPopulations = project?.population?.map((specialization) => {
        return concernTypes.find((item) => item.value === specialization)?.label || specialization;
      });
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionPopulationWrapper}>{projectPopulations?.length > 0 ? projectPopulations?.map((item) => <Chip label={item} size="small" className={style.itemPopulationValueWrapper} />) : "--"}</div>
        </div>
      );
    },
    isVisible: agencies.find((item) => (item?.id === project?.agency ? item?.name : null))?.name === "UNHCR",
  },
  {
    id: "agency",
    label: formatMessage({ id: "pages.project.details.utils.items.issued" }),
    value: agencies.map((item) => (item.id === project?.agency ? item.name : null)),
    isVisible: true,
  },
  {
    id: "description",
    label: formatMessage({
      id: "pages.project.details.utils.items.backgroundDescription",
    }),
    value: project?.description,
    isVisible: true,
  },
  {
    id: "goal",
    label: formatMessage({
      id: "pages.project.details.utils.items.expectedResults",
    }),
    value: project?.goal,
    isVisible: displayGoal,
  },
  {
    id: "indicative_budget",
    label: formatMessage({
      id: "pages.project.details.utils.items.indicativeBudget",
    }),
    value: project?.indicative_budget ? `${project?.indicative_budget_currency ?? ""} ${parseFloat(project?.indicative_budget)?.toFixed(2)}` : "--",
    isVisible: true,
  },
  {
    id: "other_information",
    label: formatMessage({
      id: "pages.project.details.utils.items.otherInformationOptional",
    }),
    value: project?.other_information,
    isVisible: true,
  },
  {
    id: "dates",
    label: undefined,
    type: "custom",
    value: () => (
      <div>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.requestForClarificationDeadline",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.clarification_request_deadline_date}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.applicationDeadline",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.deadline_date}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.notificationOfResults",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.notif_results_date}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    ),
    isVisible: true,
  },
  {
    id: "date_range",
    label: undefined,
    type: "custom",
    value: () => (
      <div>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.estimatedStartDate",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.start_date}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.estimatedEndDate",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.end_date}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    ),
    isVisible: true,
  },
  {
    id: "attachments",
    label: formatMessage({
      id: "pages.project.details.utils.items.attachmentsOptional",
    }),
    type: "custom",
    value: () => (
      <div className={style.attachmentsWrapper}>
        {project?.attachments?.length > 0 ? (
          project?.attachments?.map((item) => (
            <div className={style.sectionWrapper}>
              <div className={style.subSectionWrapper}>
                <div className={style.attachmentItemWrapper} key={`attachment_${item?.file?.name}`}>
                  <span className={style.itemLabel}>
                    {formatMessage({
                      id: "pages.project.details.utils.items.attachment",
                    })}
                  </span>
                  <DownloadIcon fileUrl={item?.file?.file_field} fileName={item?.file?.name} />
                </div>
              </div>
              <div className={style.subSectionWrapper}>
                <span className={style.itemLabel}>
                  {formatMessage({
                    id: "pages.project.details.utils.items.attachmentDescription",
                  })}
                </span>
                <span className={style.itemValue}>{item?.description}</span>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    ),
    isVisible: project?.attachments?.length > 0,
  },
];

export const getDirectProjectItems = (project, sectors, agencies, displayGoal, justifications, concernTypes) => [
  {
    id: "title",
    label: formatMessage({
      id: "pages.project.details.utils.items.projectTitle",
    }),
    value: project?.title,
    isVisible: true,
  },
  {
    id: "focal_points",
    label: formatMessage({
      id: "pages.project.details.utils.items.projectProgrammeFocalPoints",
    }),
    value: project?.focal_points_detail?.map((item) => item.name).join(", "),
    isVisible: true,
  },
  {
    id: "locations",
    label: formatMessage({ id: "pages.project.details.utils.items.locations" }),
    type: "custom",
    value: () => {
      let countryCodes = new Set(project?.locations.map((location) => location?.admin_level_1?.country_code));
      const locations = project?.locations.map((location) => ({
        ...location,
        latitude: location?.lat,
        longitude: location?.lon,
      }));
      const countries = new Set(project?.locations.map((location) => location?.admin_level_1?.country_name));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.country",
              })}
            </span>
            {Array.from(countries).map((item) => (
              <Chip key={`country_${item}`} label={item} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.locationMapWrapper}>
            <Map height="400px" markers={locations ? locations : []} countryCodes={countryCodes ? Array.from(countryCodes) : []} readOnly />
          </div>
        </div>
      );
    },
    isVisible: project?.locations?.length > 0,
  },
  {
    id: "specializations",
    label: formatMessage({ id: "pages.project.details.utils.items.label" }),
    type: "custom",
    value: () => {
      const projectSpecializationsIds = project?.specializations.map((specialization) => specialization?.id);
      const getSectorSpecializationsIds = (sector) => sector?.specializations?.map((specialization) => specialization?.id);
      const projectSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => projectSpecializationsIds?.includes(sectorSpecializationId)));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.sector",
              })}
            </span>
            {projectSectors.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.areaOfSpecialization",
              })}
            </span>
            {project?.specializations.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
        </div>
      );
    },
    isVisible: project?.specializations?.length > 0,
  },
  {
    id: "population",
    label: formatMessage({ id: "pages.project.details.utils.items.populationOfConcern" }),
    type: "custom",
    value: () => {
      const projectPopulations = project?.population?.map((specialization) => {
        return concernTypes.find((item) => item.value === specialization)?.label || specialization;
      });
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionPopulationWrapper}>{projectPopulations?.length > 0 ? projectPopulations?.map((item) => <Chip label={item} size="small" className={style.itemPopulationValueWrapper} />) : "--"}</div>
        </div>
      );
    },
    isVisible: agencies.find((item) => (item?.id === project?.agency ? item?.name : null))?.name === "UNHCR",
  },
  {
    id: "agency",
    label: formatMessage({ id: "pages.project.details.utils.items.issued" }),
    value: agencies.find((item) => item.id === project?.agency)?.name,
    isVisible: true,
  },
  {
    id: "description",
    label: formatMessage({
      id: "pages.project.details.utils.items.backgroundDescription",
    }),
    value: project?.description,
    isVisible: true,
  },
  {
    id: "goal",
    label: formatMessage({
      id: "pages.project.details.utils.items.expectedResults",
    }),
    value: project?.goal,
    isVisible: displayGoal,
  },
  {
    id: "indicative_budget",
    label: formatMessage({
      id: "pages.project.details.utils.items.indicativeBudget",
    }),
    value: project?.indicative_budget ? `${project?.indicative_budget_currency ?? ""} ${parseFloat(project?.indicative_budget)?.toFixed(2)}` : "--",
    isVisible: true,
  },
  {
    id: "other_information",
    label: formatMessage({
      id: "pages.project.details.utils.items.otherInformationOptional",
    }),
    value: project?.other_information,
    isVisible: true,
  },
  {
    id: "date_range",
    label: undefined,
    type: "custom",
    value: () => (
      <div>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.estimatedStartDate",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.start_date}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.estimatedEndDate",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.end_date}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    ),
    isVisible: true,
  },
  {
    id: "direct_selection",
    label: "",
    type: "custom",
    value: () => {
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.dsJustification",
              })}
            </span>
            {project?.direct_selected_partners?.at(0)?.ds_justification_select?.map((item) => (
              <Chip label={justifications.find((element) => element.value === item).label} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.justificationReason",
              })}
            </span>
            <span className={style.itemValue}>{project?.direct_selected_partners?.at(0)?.justification_reason}</span>
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.dsAttachment",
              })}
            </span>
            <div className={style.attachmentsWrapper}>
              {project?.direct_selected_partners?.at(0)?.ds_attachment ? (
                <div key={`attachment_${project?.direct_selected_partners?.at(0)?.id}`}>
                  <DownloadIcon
                    fileUrl={`${getBaseUrl()}${project?.direct_selected_partners?.at(0)?.ds_attachment?.file_field ?? project?.direct_selected_partners?.at(0)?.ds_attachment}`}
                    fileName={project?.direct_selected_partners?.at(0)?.ds_attachment?.name ?? getFileName(project?.direct_selected_partners?.at(0)?.ds_attachment ?? "")}
                  />
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.isCnRequired",
              })}
            </span>
            <span className={style.itemValue}>
              {project?.direct_selected_partners?.at(0)?.is_cn_required === true
                ? formatMessage({
                    id: "pages.project.details.utils.items.isCnRequiredYes",
                  })
                : project?.direct_selected_partners?.at(0)?.is_cn_required === false
                  ? formatMessage({
                      id: "pages.project.details.utils.items.isCnRequiredNo",
                    })
                  : "-"}
            </span>
          </div>
        </div>
      );
    },
    isVisible: true,
  },
];

export const getUnsolicitedProjectItems = (project, sectors, role, history, partnerId, organizationTypes) => [
  {
    id: "partner_name_and_display_type",
    label: undefined,
    type: "custom",
    value: () => (
      <div>
        <Grid container direction="row">
          <Grid item xs={12} sm={6}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.projectDetailsPartner",
                })}
              </span>
              <span className={style.value}>{project?.partner?.legal_name}</span>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.projectDetailsType",
                })}
              </span>
              <span className={style.value}>{organizationTypes[project?.partner?.display_type]}</span>
            </div>
          </Grid>
        </Grid>
      </div>
    ),
    isVisible: role === ROLES.AGENCY,
  },
  {
    id: "title",
    label: formatMessage({
      id: "pages.project.details.utils.items.projectTitle",
    }),
    value: project?.proposal_of_eoi_details?.title,
    isVisible: true,
  },
  {
    id: "agency",
    label: formatMessage({ id: "pages.project.details.utils.items.labelOne" }),
    value: project?.agency?.name,
    isVisible: role === ROLES.PARTNER,
  },
  {
    id: "locations",
    label: formatMessage({ id: "pages.project.details.utils.items.locations" }),
    type: "custom",
    value: () => {
      let countryCodes = new Set(project?.locations_proposal_of_eoi.map((location) => location?.admin_level_1?.country_code));
      const locations = project?.locations_proposal_of_eoi.map((location) => ({
        ...location,
        latitude: location?.lat,
        longitude: location?.lon,
      }));
      const countries = new Set(project?.locations_proposal_of_eoi.map((location) => location?.admin_level_1?.country_name));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.country",
              })}
            </span>
            {Array.from(countries).map((item) => (
              <Chip key={`country_${item}`} label={item} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.locationMapWrapper}>
            <Map height="400px" markers={locations ? locations : []} countryCodes={countryCodes ? Array.from(countryCodes) : []} readOnly />
          </div>
        </div>
      );
    },
    isVisible: project?.locations_proposal_of_eoi.length > 0,
  },
  {
    id: "specializations",
    label: formatMessage({ id: "pages.project.details.utils.items.label" }),
    type: "custom",
    value: () => {
      const projectSpecializationsIds = project?.proposal_of_eoi_details?.specializations.map((specialization) => specialization?.id);
      const getSectorSpecializationsIds = (sector) => sector?.specializations?.map((specialization) => specialization?.id);
      const projectSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => projectSpecializationsIds?.includes(sectorSpecializationId)));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.sector",
              })}
            </span>
            {projectSectors.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.areaOfSpecialization",
              })}
            </span>
            {project?.proposal_of_eoi_details?.specializations.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
        </div>
      );
    },
    isVisible: project?.proposal_of_eoi_details?.specializations?.length > 0,
  },
  {
    id: "viewProfile",
    label: undefined,
    type: "custom",
    value: () => (
      <div className={style.viewButton}>
        <Button
          type="text"
          text={formatMessage({
            id: "pages.project.details.utils.items.viewProfile",
          })}
          variant="text"
          onClick={() => history.push(`/partner/${partnerId}/details`)}
        />
      </div>
    ),
    isVisible: true,
  },
];

export const getPartnerProjectItems = (project, sectors, displayGoal, justifications) => [
  {
    id: "title",
    label: formatMessage({
      id: "pages.project.details.utils.items.projectTitle",
    }),
    value: project?.title,
    isVisible: true,
  },
  {
    id: "locations",
    label: formatMessage({ id: "pages.project.details.utils.items.locations" }),
    type: "custom",
    value: () => {
      let countryCodes = new Set(project?.locations.map((location) => location?.admin_level_1?.country_code));
      const locations = project?.locations.map((location) => ({
        ...location,
        latitude: location?.lat,
        longitude: location?.lon,
      }));
      const countries = new Set(project?.locations.map((location) => location?.admin_level_1?.country_name));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.country",
              })}
            </span>
            {Array.from(countries).map((item) => (
              <Chip key={`country_${item}`} label={item} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.locationMapWrapper}>
            <Map height="400px" markers={locations ? locations : []} countryCodes={countryCodes ? Array.from(countryCodes) : []} readOnly />
          </div>
        </div>
      );
    },
    isVisible: project?.locations?.length > 0,
  },
  {
    id: "specializations",
    label: formatMessage({ id: "pages.project.details.utils.items.label" }),
    type: "custom",
    value: () => {
      const projectSpecializationsIds = project?.specializations.map((specialization) => specialization?.id);
      const getSectorSpecializationsIds = (sector) => sector?.specializations?.map((specialization) => specialization?.id);
      const projectSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => projectSpecializationsIds?.includes(sectorSpecializationId)));
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.sector",
              })}
            </span>
            {projectSectors.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.areaOfSpecialization",
              })}
            </span>
            {project?.specializations.map((item) => (
              <Chip label={item.name} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
        </div>
      );
    },
    isVisible: project?.specializations?.length > 0,
  },
  {
    id: "agency",
    label: formatMessage({ id: "pages.project.details.utils.items.issued" }),
    value: project?.agency,
    isVisible: true,
  },
  {
    id: "description",
    label: formatMessage({
      id: "pages.project.details.utils.items.backgroundDescription",
    }),
    value: project?.description,
    isVisible: true,
  },
  {
    id: "goal",
    label: formatMessage({
      id: "pages.project.details.utils.items.expectedResults",
    }),
    value: project?.goal,
    isVisible: displayGoal,
  },
  {
    id: "indicative_budget",
    label: formatMessage({
      id: "pages.project.details.utils.items.indicativeBudget",
    }),
    value: project?.indicative_budget ? `${project?.indicative_budget_currency ?? ""} ${parseFloat(project?.indicative_budget)?.toFixed(2)}` : "--",
    isVisible: true,
  },
  {
    id: "other_information",
    label: formatMessage({
      id: "pages.project.details.utils.items.otherInformationOptional",
    }),
    value: project?.other_information,
    isVisible: true,
  },
  {
    id: "dates",
    label: undefined,
    type: "custom",
    value: () => (
      <div>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.requestForClarificationDeadline",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.clarification_request_deadline_date}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.applicationDeadline",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.deadline_date}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.notificationOfResults",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.notif_results_date}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    ),
    isVisible: project?.display_type !== "Dir",
  },
  {
    id: "date_range",
    label: undefined,
    type: "custom",
    value: () => (
      <div>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.estimatedStartDate",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.start_date}
              </span>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={style.flexColumn}>
              <span className={style.itemLabel}>
                {formatMessage({
                  id: "pages.project.details.utils.items.estimatedEndDate",
                })}
              </span>
              <span className={style.value}>
                <span className={style.icon}>
                  <EventIcon fontSize="small" />
                </span>
                {project?.end_date}
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
    ),
    isVisible: true,
  },
  {
    id: "attachments",
    label: formatMessage({
      id: "pages.project.details.utils.items.attachmentsOptional",
    }),
    type: "custom",
    value: () => (
      <div className={style.attachmentsWrapper}>
        {project?.attachments?.length > 0 ? (
          project?.attachments?.map((item) => (
            <div className={style.sectionWrapper}>
              <div className={style.subSectionWrapper}>
                <div className={style.attachmentItemWrapper} key={`attachment_${item?.file?.name}`}>
                  <span className={style.itemLabel}>
                    {formatMessage({
                      id: "pages.project.details.utils.items.attachment",
                    })}
                  </span>
                  <DownloadIcon fileUrl={item?.file?.file_field} fileName={item?.file?.name} />
                </div>
              </div>
              <div className={style.subSectionWrapper}>
                <span className={style.itemLabel}>
                  {formatMessage({
                    id: "pages.project.details.utils.items.attachmentDescription",
                  })}
                </span>
                <span className={style.itemValue}>{item?.description}</span>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    ),
    isVisible: project?.attachments?.length > 0,
  },
  {
    id: "direct_selection",
    label: "",
    type: "custom",
    value: () => {
      return (
        <div className={style.sectionWrapper}>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.dsJustification",
              })}
            </span>
            {project?.direct_selected_partners?.at(0)?.ds_justification_select?.map((item) => (
              <Chip label={justifications.find((element) => element.value === item).label} size="small" className={style.itemValueWrapper} />
            ))}
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.justificationReason",
              })}
            </span>
            <span className={style.itemValue}>{project?.direct_selected_partners?.at(0)?.justification_reason}</span>
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.dsAttachment",
              })}
            </span>
            <div className={style.attachmentsWrapper}>
              {project?.direct_selected_partners?.at(0)?.ds_attachment ? (
                <div key={`attachment_${project?.direct_selected_partners?.at(0)?.id}`}>
                  <DownloadIcon
                    fileUrl={`${getBaseUrl()}${project?.direct_selected_partners?.at(0)?.ds_attachment?.file_field ?? project?.direct_selected_partners?.at(0)?.ds_attachment}`}
                    fileName={project?.direct_selected_partners?.at(0)?.ds_attachment?.name ?? getFileName(project?.direct_selected_partners?.at(0)?.ds_attachment ?? "")}
                  />
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className={style.subSectionWrapper}>
            <span className={style.itemLabel}>
              {formatMessage({
                id: "pages.project.details.utils.items.isCnRequired",
              })}
            </span>
            <span className={style.itemValue}>
              {project?.direct_selected_partners?.at(0)?.is_cn_required === true
                ? formatMessage({
                    id: "pages.project.details.utils.items.isCnRequiredYes",
                  })
                : project?.direct_selected_partners?.at(0)?.is_cn_required === false
                  ? formatMessage({
                      id: "pages.project.details.utils.items.isCnRequiredNo",
                    })
                  : "-"}
            </span>
          </div>
        </div>
      );
    },
    isVisible: project?.display_type === "Dir",
  },
];

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPartnerProfileDataDetails, getCountriesList } from "@local/actions";
import Checkbox from "@mui/material/Checkbox";
import Button from "@local/components/button";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Modal from "@local/components/modal";
import ProfileDetails from "@local/pages/partner-details/components/profile-details";
import { adaptCountries } from "@local/utils/adapters";
import style from "./style.module.scss";
import Box from "@local/components/box";

const ProfileConfirmation = ({ lastUpdatedDate, disabled, checked, onChange, partnerId, partnerProfile, countries, getPartnerProfileData, getCountries, isProfileConfirmationChecked }) => {
  const history = useHistory();

  useEffect(() => {
    getCountries();
  }, []);

  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  const onClickOpenProfileModal = () => setIsProfileModalVisible(true);

  const onClickCloseProfileModal = () => setIsProfileModalVisible(false);

  const onClickViewProfile = async () => {
    await getPartnerProfileData(partnerId);
    onClickOpenProfileModal();
  };

  return (
    <>
      <div className={style.checkWrapper}>
        <Checkbox checked={checked ? checked : isProfileConfirmationChecked} onChange={onChange} disabled={disabled} />
        <span className={style.checkInfoWrapper}>
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.project.submission.cmp.profile.confirmation.idx.confirmLabel",
            })}
          </span>
          <span className={style.checkInfo}>
            <span>
              {formatMessage({
                id: "pages.project.details.cmp.project.submission.cmp.profile.confirmation.idx.lastUpdate",
              })}{" "}
              {formatDateForPrint(lastUpdatedDate)}
            </span>
            <span>
              {formatMessage({
                id: "pages.project.details.cmp.project.submission.cmp.profile.confirmation.idx.notSure",
              })}
            </span>
            <Button
              type="text"
              variant="text"
              text={formatMessage({
                id: "pages.project.details.cmp.project.submission.cmp.profile.confirmation.idx.viewProfile",
              })}
              onClick={onClickViewProfile}
            />
          </span>
        </span>
      </div>
      <Modal
        isOpen={isProfileModalVisible}
        onClose={onClickCloseProfileModal}
        title={formatMessage({
          id: "pages.project.details.cmp.project.submission.cmp.profile.confirmation.idx.countryProfile",
        })}
        footer={
          <>
            <div>
              <Button
                onClick={onClickCloseProfileModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.project.submission.cmp.profile.confirmation.idx.close",
                })}
              />
            </div>
            <div>
              <Button
                onClick={() => history.push(`/profile/${partnerId}/edit`)}
                text={formatMessage({
                  id: "pages.project.details.cmp.project.submission.cmp.profile.confirmation.idx.editProfile",
                })}
              />
            </div>
          </>
        }
      >
        <Box withBackground content={<ProfileDetails partner={partnerProfile} countries={countries} partnerId={partnerId} />} />
      </Modal>
    </>
  );
};

ProfileConfirmation.propTypes = {};

const mapStateToProps = (state) => ({
  partnerId: state?.session?.partnerId,
  lastUpdatedDate: state?.session?.lastUpdate,
  partnerProfile: state?.partnerProfileDataDetails?.data?.data ?? {},
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
});

const mapDispatchToProps = (dispatch) => ({
  getPartnerProfileData: (id) => dispatch(getPartnerProfileDataDetails(undefined, { id })),
  getCountries: () => dispatch(getCountriesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileConfirmation);

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PartnerDashboard from "./components/partner/partnerDashboard";
import AgencyDashboard from "./components/agency/agencyDashboard";
import { ROLES } from "../../legacy-utils/constants";
import MainContentWrapper from "@local/components/main-content-wrapper";
import HeaderNavigation from "@local/components/header-navigation";
import { loadDashboard } from "../../legacy-reducers/dashboard";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { checkRestrictedPath } from "../../legacy-utils/restrictions";

const Dashboard = ({ role, dashboard, isLoading, isRestrictedPath, history, loadDashboardData }) => {
  useEffect(() => {
    loadDashboardData();
    if (isRestrictedPath) history.push("/partner");
  }, []);
  return (
    <>
      <HeaderNavigation title={formatMessage({ id: "pages.dashboard.idx.header" })} />
      <MainContentWrapper>{role === ROLES.PARTNER ? <PartnerDashboard isLoading={isLoading} dashboard={dashboard} /> : <AgencyDashboard isLoading={isLoading} dashboard={dashboard} />}</MainContentWrapper>
    </>
  );
};

Dashboard.propTypes = {};

const mapStateToProps = (state) => ({
  role: state.session.role,
  isLoading: state.dashboard.status.loading,
  dashboard: state.dashboard.data,
  isRestrictedPath: checkRestrictedPath(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadDashboardData: () => dispatch(loadDashboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));

import FlagIcon from "@local/components/flag-icon";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import currenciesItems from "@local/assets/json/currencies.json";
import i18n from "@local/i18next/i18n";

export const adaptCountries = (countries) => Object.keys(countries).map((key) => ({ value: key, label: countries[key] }));

export const adaptApplicationStatuses = (applicationStatuses) =>
  Object.keys(applicationStatuses).map((key) => ({
    value: key,
    label: applicationStatuses[key],
  }));

export const adaptCategoryRisks = (categoryRisks) =>
  Object.keys(categoryRisks ?? {}).map((key) => ({
    value: key,
    label: categoryRisks[key],
  }));

export const adaptRoles = (roles) => Object.keys(roles).map((key) => ({ value: key, label: roles[key] }));

export const adaptSpecializations = (sectors) => {
  let result = [];
  sectors.forEach((item) => {
    result.push({ isTitle: true, ...item });
    item.specializations.forEach((item) => {
      result.push(item);
    });
  });
  return result;
};

export const adaptOrganizationTypes = (organizations) =>
  Object.keys(organizations).map((key) => ({
    value: key,
    label: organizations[key],
  }));

export const adaptFocalPoints = (focalPoints) =>
  focalPoints.map((focalPoint) => ({
    ...focalPoint,
    label: `${focalPoint.name} (${focalPoint.email})`,
  }));

export const adaptCurrencies = () => {
  let result = [];
  currenciesItems.forEach((currency) => {
    currency.label = `${currency.name} (${currency.code})`;
    result.push({ ...currency });
  });
  return result;
};

export const adaptJustifications = (justifications) =>
  Object.keys(justifications).map((key) => ({
    value: key,
    label: justifications[key],
  }));

export const adaptDirectSelectionSources = (directSelectionSources) =>
  Object.keys(directSelectionSources).map((key) => ({
    value: key,
    label: directSelectionSources[key],
  }));

export const adaptBudgetTypes = (budgetTypes) =>
  Object.keys(budgetTypes).map((key) => ({
    value: key,
    label: budgetTypes[key],
  }));

export const adaptDonorTypes = (donorTypes) =>
  Object.keys(donorTypes).map((key) => ({
    value: key,
    label: donorTypes[key],
  }));

export const adaptFinancialControlSystems = (financialControlSystems) =>
  Object.keys(financialControlSystems).map((key) => ({
    value: key,
    label: financialControlSystems[key],
  }));

export const adaptAccountingMethods = (accountingMethods) =>
  Object.keys(accountingMethods).map((key) => ({
    value: key,
    label: accountingMethods[key],
  }));

export const adaptAreasOfResponsibility = (areasOfResponsibility) =>
  Object.keys(areasOfResponsibility).map((key) => ({
    value: key,
    label: areasOfResponsibility[key],
  }));

export const adaptAreasOfPolicy = (areasOfPolicy) =>
  Object.keys(areasOfPolicy).map((key) => ({
    value: key,
    label: areasOfPolicy[key],
  }));

export const adaptAuditTypes = (auditTypes) =>
  Object.keys(auditTypes).map((key) => ({
    value: key,
    label: auditTypes[key],
  }));

export const adaptCapacityAssessments = (capacityAssessments) =>
  Object.keys(capacityAssessments).map((key) => ({
    value: key,
    label: capacityAssessments[key],
  }));

export const adaptPartnersWithExtendedLabel = (partners, countries) =>
  partners.map((item) => ({
    ...item,
    legal_name: `${item?.legal_name ?? "--"} (${countries.find((country) => country.value === item?.country_code)?.label ?? "--"})`,
  }));

export const adaptReviewersWithExtendedEmailLabel = (reviewers) =>
  reviewers.map((item) => ({
    ...item,
    legal_name: `${item?.name ?? "--"} (${item?.email})`,
  }));

export const getFileName = (fileField) => fileField?.split("/").pop();

export const adaptFlagTypes = (flags) =>
  Object.keys(flags ?? {}).map((key) => ({
    value: key,
    label: <FlagIcon flagType={key} />,
  }));

export const getVerificationContent = (profileStatus, backgroundColor = "#FFFFFF") => {
  switch (profileStatus) {
    case "Incomplete":
      return {
        icon: <GppGoodIcon style={{ fill: "#9e9e9e", fontSize: "1.25rem" }} />,
        stepText: formatMessage({ id: "utils.adapters.profileIncompleteFull" }),
        text: formatMessage({ id: "utils.adapters.InformationIncomplete" }),
      };
    case "Pending":
      return {
        icon: <GppGoodIcon style={{ fill: "#0000cc", fontSize: "1.25rem" }} />,
        stepText: formatMessage({ id: "utils.adapters.profileComplete" }),
        text: formatMessage({ id: "utils.adapters.InformationCanBeVerified" }),
      };
    case "Passed":
      return {
        icon: <GppGoodIcon style={{ fill: "#099854", fontSize: "1.25rem" }} />,
        stepText: formatMessage({ id: "utils.adapters.verified" }),
        text: formatMessage({ id: "utils.adapters.InformationVerified" }),
      };
    case "Failed":
      return {
        icon: <GppGoodIcon style={{ fill: "#f44336", fontSize: "1.25rem" }} />,
        stepText: formatMessage({ id: "utils.adapters.unverified" }),
        text: formatMessage({ id: "utils.adapters.InformationUnverified" }),
      };
    case "Expired":
      return {
        icon: (
          <span style={{ display: "flex", position: "relative" }}>
            <AddModeratorIcon style={{ fill: "#f44336", fontSize: "1.25rem" }} />
            <HourglassDisabledIcon
              style={{
                fill: "#f44336",
                position: "absolute",
                background: backgroundColor,
                borderRadius: "50%",
                bottom: "0",
                right: "0.03rem",
                width: "0.75rem",
                height: "0.75rem",
              }}
            />
          </span>
        ),
        stepText: formatMessage({ id: "utils.adapters.expired" }),
        text: formatMessage({ id: "utils.adapters.InformationExpired" }),
      };
    case "Invalidated":
      return {
        icon: <RemoveModeratorIcon style={{ fill: "#1E90FF", fontSize: "1.25rem" }} />,
        stepText: i18n.t("Re-verification Required"),
        text: formatMessage({ id: "utils.adapters.InformationInvalidated" }),
      };

    default:
      return undefined;
  }
};

export const getPartnerDeclarationQuestions = (formValues) => [
  {
    id: "1",
    nameField: "ad_1",
    text: formatMessage({ id: "utils.adapters.text" }),
    content: formatMessage({ id: "utils.adapters.declarationAd1" }),
    errorMessage: (
      <>
        {formatMessage({ id: "utils.adapters.errorMessage" })}
        <a target="_blank" href="https://www.ungm.org" alt={formatMessage({ id: "utils.adapters.declarationLink1" })} rel="noreferrer">
          ({formatMessage({ id: "utils.adapters.declarationLink1" })})
        </a>
      </>
    ),
    isVisible: true,
  },
  {
    id: "2",
    nameField: "ad_2",
    text: formatMessage({ id: "utils.adapters.textOne" }),
    content: formatMessage({ id: "utils.adapters.declarationAd2" }),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true",
  },
  {
    id: "3",
    nameField: "ad_3",
    text: formatMessage({ id: "utils.adapters.textTwo" }),
    content: (
      <>
        {formatMessage({ id: "utils.adapters.declarationAd3" })}{" "}
        <ol type="a">
          <li>{formatMessage({ id: "utils.adapters.part1" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part2" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part3" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part4" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part5" })}</li>
        </ol>
      </>
    ),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true" && formValues?.ad_2 === "true",
  },
  {
    id: "4",
    nameField: "ad_4",
    text: formatMessage({ id: "utils.adapters.textThree" }),
    content: formatMessage({ id: "utils.adapters.declarationAd4" }),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true" && formValues?.ad_2 === "true" && formValues?.ad_3 === "true",
  },
  {
    id: "5",
    nameField: "ad_5",
    text: formatMessage({ id: "utils.adapters.textFour" }),
    content: (
      <>
        {formatMessage({ id: "utils.adapters.declarationAd5" })}{" "}
        <a target="_blank" href="https://undocs.org/ST/SGB/2003/13" alt={formatMessage({ id: "utils.adapters.declarationLink2" })} rel="noreferrer">
          ({formatMessage({ id: "utils.adapters.declarationLink2" })})
        </a>
      </>
    ),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true" && formValues?.ad_2 === "true" && formValues?.ad_3 === "true" && formValues?.ad_4 === "true",
  },
  {
    id: "6",
    nameField: "ad_6",
    text: formatMessage({ id: "utils.adapters.textFive" }),
    content: (
      <>
        {formatMessage({ id: "utils.adapters.part1One" })}{" "}
        <a target="_blank" href="https://www.un.org/securitycouncil/content/un-sc-consolidated-list" alt={formatMessage({ id: "utils.adapters.declarationLink3" })} rel="noreferrer">
          ({formatMessage({ id: "utils.adapters.declarationLink3" })})
        </a>{" "}
        {formatMessage({ id: "utils.adapters.part2One" })}
      </>
    ),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true" && formValues?.ad_2 === "true" && formValues?.ad_3 === "true" && formValues?.ad_4 === "true" && formValues?.ad_5 === "true",
  },
  {
    id: "7",
    nameField: "ad_7",
    text: formatMessage({ id: "utils.adapters.textSix" }),
    content: (
      <>
        {formatMessage({ id: "utils.adapters.declarationAd7" })}{" "}
        <ol type="a">
          <li>{formatMessage({ id: "utils.adapters.part1Two" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part2Two" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part3One" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part4One" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part5One" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part6" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part7" })}</li>
          <li>{formatMessage({ id: "utils.adapters.part8" })}</li>
        </ol>
      </>
    ),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true" && formValues?.ad_2 === "true" && formValues?.ad_3 === "true" && formValues?.ad_4 === "true" && formValues?.ad_5 === "true" && formValues?.ad_6 === "true",
  },
  {
    id: "8",
    nameField: "ad_8",
    text: formatMessage({ id: "utils.adapters.textSeven" }),
    content: formatMessage({ id: "utils.adapters.declarationAd8" }),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true" && formValues?.ad_2 === "true" && formValues?.ad_3 === "true" && formValues?.ad_4 === "true" && formValues?.ad_5 === "true" && formValues?.ad_6 === "true" && formValues?.ad_7 === "true",
  },
  {
    id: "9",
    nameField: "ad_9",
    text: formatMessage({ id: "utils.adapters.textEight" }),
    content: formatMessage({ id: "utils.adapters.declarationAd9" }),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible: formValues?.ad_1 === "true" && formValues?.ad_2 === "true" && formValues?.ad_3 === "true" && formValues?.ad_4 === "true" && formValues?.ad_5 === "true" && formValues?.ad_6 === "true" && formValues?.ad_7 === "true" && formValues?.ad_8 === "true",
  },
  {
    id: "10",
    nameField: "ad_10",
    text: formatMessage({ id: "utils.adapters.textNine" }),
    content: formatMessage({ id: "utils.adapters.declarationAd10" }),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible:
      formValues?.ad_1 === "true" &&
      formValues?.ad_2 === "true" &&
      formValues?.ad_3 === "true" &&
      formValues?.ad_4 === "true" &&
      formValues?.ad_5 === "true" &&
      formValues?.ad_6 === "true" &&
      formValues?.ad_7 === "true" &&
      formValues?.ad_8 === "true" &&
      formValues?.ad_9 === "true",
  },
  {
    id: "11",
    nameField: "ad_11",
    text: formatMessage({ id: "utils.adapters.textTen" }),
    content: formatMessage({ id: "utils.adapters.declarationAd11" }),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible:
      formValues?.ad_1 === "true" &&
      formValues?.ad_2 === "true" &&
      formValues?.ad_3 === "true" &&
      formValues?.ad_4 === "true" &&
      formValues?.ad_5 === "true" &&
      formValues?.ad_6 === "true" &&
      formValues?.ad_7 === "true" &&
      formValues?.ad_8 === "true" &&
      formValues?.ad_9 === "true" &&
      formValues?.ad_10 === "true",
  },
  {
    id: "12",
    nameField: "ad_12",
    text: formatMessage({ id: "utils.adapters.textEleven" }),
    content: formatMessage({ id: "utils.adapters.declarationAd12" }),
    errorMessage: formatMessage({ id: "utils.adapters.errorMessageOne" }),
    isVisible:
      formValues?.ad_1 === "true" &&
      formValues?.ad_2 === "true" &&
      formValues?.ad_3 === "true" &&
      formValues?.ad_4 === "true" &&
      formValues?.ad_5 === "true" &&
      formValues?.ad_6 === "true" &&
      formValues?.ad_7 === "true" &&
      formValues?.ad_8 === "true" &&
      formValues?.ad_9 === "true" &&
      formValues?.ad_10 === "true" &&
      formValues?.ad_11 === "true",
  },
];

export const formatNumberDecimals = (value) => {
  // Check if the number is an integer
  if (Number.isInteger(value)) {
    return value?.toString();
  } else {
    return value?.toFixed(3).toString();
  }
};

import React from "react";
import Card from "@local/components/card";
import style from "./style.module.scss";
import { formatDate } from "@local/utils";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getJobTitleTypes } from "@local/utils/constants";

const items = (partner, countries) => [
  {
    id: "id",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.partnerId",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "legal_name",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.partnerName",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "display_type_display",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.type",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "orgHq",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.orgHq",
    }),
    isVisible: partner.hq?.id ?? false,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <a alt="HQ" className={style.link} href={`/partner/${partner.hq?.id}/overview`}>
          {partner.hq?.legal_name}
        </a>
      </div>
    ),
  },
  {
    id: "orgHqLocation",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.orgHqLocation",
    }),
    isVisible: partner.hq?.id ?? false,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner.hq?.country_display}</span>
      </div>
    ),
  },
  {
    id: "country_display",
    isVisible: true,
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.country",
    }),
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "location",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.location",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner.location_of_offices || partner.country_presence_display}</span>
      </div>
    ),
  },
  {
    id: "headOfOrganization",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.headOfOrganization",
    }),
    isVisible: true,
    render: (item) => (
      <>
        <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
          <span>{item.label}</span>
          <span>{partner[item.id]}</span>
        </div>
        <div key={"partner_details_item_fullname"} className={style.itemWrapperChild}>
          <span>Full Name</span>
          <span>{partner.org_head?.fullname}</span>
        </div>
        <div key={"partner_details_item_jobTitle"} className={style.itemWrapperChild}>
          <span>Job Title</span>
          <span>{partner?.org_head?.job_title?.map((item) => getJobTitleTypes().find((element) => element.value === item)?.label)?.join(", ") ?? "--"}</span>
        </div>
        <div key={"partner_details_item_telephone"} className={style.itemWrapperChild}>
          <span>Telephone</span>
          <span>{partner.org_head?.telephone}</span>
        </div>
        <div key={"partner_details_item_email"} className={style.itemWrapperChild}>
          <span>Email</span>
          <span>{partner.org_head?.email}</span>
        </div>
      </>
    ),
  },
  {
    id: "contact",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.contact",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner.mailing_address?.po_box || partner.mailing_address?.street}</span>
      </div>
    ),
  },
  {
    id: "sectors",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.sectors",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <div className={style.subItemWrapper}>
          {partner.experiences?.map((experience) => (
            <div>
              <b>{experience.specialization?.category?.name} </b> {experience.specialization?.name}
            </div>
          )) ?? "--"}
        </div>
      </div>
    ),
  },
  {
    id: "populations",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.populations",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <div className={style.subItemWrapper}>
          {partner.population_of_concern?.map((itemPopulation) => (
            <div>
              {formatMessage({
                id: `backend.common.consts.CONCERN_.${itemPopulation}`,
              })}
            </div>
          )) ?? "--"}
        </div>
      </div>
    ),
  },
  {
    id: "year_establishment",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.year",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "unExperience",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.unExperience",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner?.collaborations_partnership?.map((item) => item.agency_name)?.join(", ") ?? "--"}</span>
      </div>
    ),
  },
  {
    id: "annual_budget",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.budget",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>
          {partner[item.id]
            ? formatMessage({
                id: `backend.common.consts.BUDGET_.${partner[item.id]}`,
              })
            : "-"}
        </span>
      </div>
    ),
  },
  {
    id: "key_result",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.results",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "mandate_and_mission",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.mandate",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <span>{partner[item.id]}</span>
      </div>
    ),
  },
  {
    id: "subOffices",
    label: formatMessage({
      id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.subOffices",
    }),
    isVisible: true,
    render: (item) => (
      <div key={`partner_details_item_${item.id}`} className={style.itemWrapper}>
        <span>{item.label}</span>
        <div className={style.subItemWrapper}>
          {partner.sub_offices?.map((office) => (
            <div>
              <a className={style.link} href={`/partner/${office.id}/overview`}>
                {countries[office.country_code]} - {office.id}
              </a>
            </div>
          )) ?? "-"}
        </div>
      </div>
    ),
  },
];

const ProfileSummary = ({ partner, bottomButton, countries }) => {
  return (
    <Card
      title={
        <div className={style.headerWrapper}>
          <span className={style.headerTitle}>
            {formatMessage({
              id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.profileSummary",
            })}
          </span>
          {partner?.last_profile_update ? (
            <div className={style.lastUpdateWrapper}>
              <span>
                {formatMessage({
                  id: "pages.partner.details.cmp.overview.cmp.profile.summary.idx.updated",
                })}
              </span>
              <span>{formatDate(partner?.last_profile_update)}</span>
            </div>
          ) : undefined}
        </div>
      }
    >
      <div className={style.itemsWrapper}>{items(partner, countries).map((item) => (item?.isVisible ?? true ? item.render(item) : undefined))}</div>
      <div className={style.buttonWrapper}>{bottomButton}</div>
    </Card>
  );
};

ProfileSummary.propTypes = {};

export default ProfileSummary;

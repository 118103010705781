import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";

const UnsolicitedProjectsList = ({ fetchFunction, columns, filters, adapterFunction }) => {
  return (
    <Table
      key="projects_list_unsolicited"
      id="projects_list_unsolicited"
      title={formatMessage({
        id: "pages.projects.list.cmp.unsolicited.projects.list.idx.unsolicitedTitle",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      filters={filters}
      adapterFunction={adapterFunction}
      withUrlSynchronization
    />
  );
};

UnsolicitedProjectsList.propTypes = {};

export default UnsolicitedProjectsList;

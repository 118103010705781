import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";

const PinnedProjectsList = ({ fetchFunction, columns, filters, adapterFunction }) => {
  return (
    <Table
      key="projects_list_pinned"
      id="projects_list_pinned"
      title={formatMessage({
        id: "pages.projects.list.cmp.pinned.projects.list.idx.pinnedTitle",
      })}
      columns={columns}
      fetchFunction={fetchFunction}
      filters={filters}
      adapterFunction={adapterFunction}
      withUrlSynchronization
    />
  );
};

PinnedProjectsList.propTypes = {};

export default PinnedProjectsList;

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptSpecializations } from "@local/utils/adapters";
import { YEARS_VAL, adaptValues } from "./adapters";
import { RISK_FLAGS_REASONS } from "@local/legacy-utils/constants";
import i18n from "@local/i18next/i18n";
import i18next from "i18next";

export const getPartnerInformationFilters = (countries, getLocations, locations, setLocations, sectors, status, partnerTypes) => [
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.reports.list.utils.filters.country" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  },
  {
    id: "locations",
    name: "locations",
    label: formatMessage({ id: "pages.reports.list.utils.filters.location" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
  },
  {
    id: "registered",
    name: "registered",
    label: formatMessage({
      id: "pages.reports.list.utils.filters.registration",
    }),
    type: "radio",
    options: status,
  },
  {
    id: "display_types",
    name: "display_types",
    label: formatMessage({
      id: "pages.reports.list.utils.filters.typeOfOrganization",
    }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.typeOfOrganization",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerTypes,
  },
  {
    id: "specializations",
    name: "specializations",
    label: formatMessage({ id: "pages.reports.list.utils.filters.sector" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "has_experience",
    name: "has_experience",
    label: formatMessage({ id: "pages.reports.list.utils.filters.experience" }),
    type: "radio",
    options: status,
  },
  {
    id: "is_hq",
    name: "is_hq",
    type: "checkbox",
    label: formatMessage({ id: "pages.reports.list.utils.filters.show" }),
  },
];

export const getCfeiManagementFilters = (countries, getLocations, locations, setLocations, sectors, cfeiTypes, applicationStatuses, agencies) => [
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.reports.list.utils.filters.countryOne" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.countryOne",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  },
  {
    id: "locations",
    name: "locations",
    label: formatMessage({
      id: "pages.reports.list.utils.filters.locationOne",
    }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.locationOne",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
  },
  {
    id: "agency",
    name: "agency",
    label: formatMessage({ id: "pages.reports.list.utils.filters.agency" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.chooseOne",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: agencies,
  },
  {
    id: "specializations",
    name: "specializations",
    label: formatMessage({ id: "pages.reports.list.utils.filters.sector" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "posted_year",
    name: "posted_year",
    label: formatMessage({ id: "pages.reports.list.utils.filters.year" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.yearPlaceholder",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: YEARS_VAL(),
  },
  {
    id: "display_type",
    name: "display_type",
    label: formatMessage({ id: "pages.reports.list.utils.filters.cfeiType" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.chooseTwo",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: adaptValues(cfeiTypes),
  },
  {
    id: "status",
    name: "status",
    label: formatMessage({ id: "pages.reports.list.utils.filters.status" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.chooseTwo",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: adaptValues(applicationStatuses),
  },
];

export const getVerificationFilters = (countries, sectors, partnerTypes, VerificationStatuses, populationConcern) => [
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.reports.list.utils.filters.countryTwo" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.countryTwo",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: countries,
  },
  {
    id: "display_type",
    name: "display_type",
    label: formatMessage({ id: "pages.reports.list.utils.filters.typeLabel" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.typeLabel",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerTypes,
  },
  {
    id: "verification_year",
    name: "verification_year",
    label: formatMessage({
      id: "pages.reports.list.utils.filters.verificationYear",
    }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.verificationYear",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: YEARS_VAL(),
  },
  {
    id: "flag",
    name: "flag",
    label: formatMessage({ id: "pages.reports.list.utils.filters.flagType" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.flagType",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: RISK_FLAGS_REASONS,
  },
  {
    id: "is_verified",
    name: "is_verified",
    label: formatMessage({
      id: "pages.reports.list.utils.filters.verificationStatus",
    }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.verificationStatus",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: VerificationStatuses,
    clearOnChange: ["complete_date", "passed_date", "failed_date"],
  },
  {
    id: "specializations",
    name: "specializations",
    label: formatMessage({ id: "pages.reports.list.utils.filters.sectorOne" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.chooseOne",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "concern",
    name: "concern",
    label: formatMessage({
      id: "pages.reports.list.utils.filters.populations",
    }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.populations",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: adaptValues(populationConcern),
    multiple: true,
  },
  {
    id: "complete_date",
    fromName: "complete_date_from",
    toName: "complete_date_to",
    label: formatMessage({
      id: "pages.reports.list.utils.filters.completedDate",
    }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.completedDate",
    }),
    getVisibility: (filtersValues) => (filtersValues.is_verified ?? false) && filtersValues.is_verified === "pending",
    type: "dateRange",
  },
  {
    id: "passed_date",
    fromName: "passed_date_from",
    toName: "passed_date_to",
    label: formatMessage({ id: "pages.reports.list.utils.filters.passedDate" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.passedDate",
    }),
    getVisibility: (filtersValues) => (filtersValues.is_verified ?? false) && filtersValues.is_verified === "verified",
    type: "dateRange",
  },
  {
    id: "failed_date",
    fromName: "failed_date_from",
    toName: "failed_date_to",
    label: formatMessage({ id: "pages.reports.list.utils.filters.failedDate" }),
    placeholder: formatMessage({
      id: "pages.reports.list.utils.filters.failedDate",
    }),
    getVisibility: (filtersValues) => (filtersValues.is_verified ?? false) && filtersValues.is_verified === "unverified",
    type: "dateRange",
  },
  {
    id: "is_hq",
    name: "is_hq",
    type: "checkbox",
    label: formatMessage({ id: "pages.reports.list.utils.filters.showOne" }),
  },
];

export const getPseaFilters = (selfAssessmentStatuses, csipStatuses, partnerTypes, countries, sectors) => [
  {
    id: "legal_name",
    name: "legal_name",
    label: i18n.t("Partner Name"),
    placeholder: i18n.t("Partner Name"),
    type: "text",
  },
  {
    id: "vendor_num",
    name: "vendor_num",
    label: i18n.t("Partner Code"),
    placeholder: i18n.t("Partner Code"),
    type: "text",
  },
  {
    id: "display_type",
    name: "display_type",
    label: i18next.t("Type of Organization"),
    placeholder: i18next.t("Type of Organization"),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: partnerTypes,
  },
  {
    id: "country_code",
    name: "country_code",
    label: i18next.t("Country"),
    placeholder: i18next.t("Country"),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: countries,
  },
  {
    id: "specializations",
    name: "specializations",
    label: i18next.t("Sector & Area of Specialization"),
    placeholder: i18next.t("Sector & Area of Specialization"),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "self_assessment",
    name: "self_assessment",
    label: i18next.t("Self-Assessment (Partner)"),
    placeholder: i18next.t("Self-Assessment (Partner)"),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: selfAssessmentStatuses,
  },
  {
    id: "csip_status",
    name: "csip_status",
    label: "CSIP",
    placeholder: "CSIP",
    type: "select",
    valueField: "value",
    labelField: "label",
    options: csipStatuses,
  },
];

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { connect } from "react-redux";
import ManageModal from "./components/manage-modal";
import { getSubscriptionsList, createSubscription, updateSubscription, deleteSubscription, getTagsList, getRssChannelList } from "@local/actions";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { isValid, reset, formValueSelector } from "redux-form";
import ConfirmModal from "@local/components/confirm-modal";
import Container from "./components/container";
import Button from "@local/components/button";
import style from "./style.module.scss";

const SubscriptionsList = ({ isValidForm, formValues, channels, getSubscriptions, createSubscription, updateSubscription, deleteSubscription, resetSubscriptionForm, getRssChannelList, getTags, user, selectedChannel, subscriptionsList }) => {
  const [isManageModalVisible, setIsManageModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [current, setCurrent] = useState(undefined);
  const [subscriptions, setSubscriptions] = useState(subscriptionsList);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRssChannelList();
    fetchFunction();
  }, []);

  const onClickAddSubscription = () => {
    setIsManageModalVisible(true);
  };

  const onSubmitManageSubscriptionModal = async () => {
    try {
      setLoading(true);
      if (current) {
        await updateSubscription(current.id, {
          channel: channels[0]?.id?.toString(),
          tags: formValues?.tags?.map((item) => item.id) ?? [],
        });
        setLoading(false);
      } else {
        await createSubscription({
          user,
          channel: channels[0]?.id?.toString(),
          tags: formValues?.tags?.map((item) => item.id) ?? [],
        });
      }
      fetchFunction();
      setIsManageModalVisible(false);
      resetSubscriptionForm();
      setCurrent(undefined);
      setLoading(false);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.subscriptions.list.idx.unableToSaveData" }));
    }
  };

  const onClickEditSubscription = (item) => {
    setIsManageModalVisible(true);
    setCurrent(item);
  };

  const onCloseManageSubscriptionModal = () => {
    setIsManageModalVisible(false);
    setCurrent(undefined);
    resetSubscriptionForm();
  };

  const onClickDeleteSubscription = (item) => {
    setIsDeleteModalVisible(true);
    setCurrent(item);
  };

  const onCloseDeleteSubscriptionModal = () => {
    setIsDeleteModalVisible(false);
    setCurrent(undefined);
  };

  const onSubmitDeleteSubscriptionModal = async () => {
    try {
      await deleteSubscription(current.id);
      setIsDeleteModalVisible(false);
      fetchFunction();
      setCurrent(undefined);
    } catch (error) {
      toast.error(formatMessage({ id: "pages.subscriptions.list.idx.unableToSaveData" }));
    }
  };

  const fetchFunction = async () => {
    const subscriptionsResults = await getSubscriptions(user);
    if (subscriptionsResults?.data?.results) {
      setSubscriptions(subscriptionsResults?.data?.results);
    }
    setLoading(false);
    return subscriptionsResults;
  };

  return (
    <>
      <Container>
        {subscriptions?.length ? (
          <div className={style.subscribeContainer}>
            <h2>
              {formatMessage({
                id: "pages.subscriptions.list.idx.subscribedChannel",
              })}
            </h2>
            <p>{formatMessage({ id: "pages.subscriptions.list.idx.subtitle" })}</p>
            <div className={style.tagsContainer}>
              {subscriptions[0]?.tags?.length ? subscriptions[0].tags.map((tag) => <span className={style.tag}>{tag}</span>) : undefined}
              <Button
                text={formatMessage({
                  id: "pages.subscriptions.list.idx.addTags",
                })}
                onClick={() => onClickEditSubscription(subscriptions[0])}
                className={style.addTagsBtn}
              />
            </div>
            <Button
              text={formatMessage({
                id: "pages.subscriptions.list.idx.unsubscribeChannel",
              })}
              onClick={() => onClickDeleteSubscription(subscriptions[0])}
              className={style.unsubscribeBtn}
            />
          </div>
        ) : loading ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          <div className={style.subscribeContainer}>
            <h2>
              {formatMessage({
                id: "pages.subscriptions.list.idx.headingTitle",
              })}
            </h2>
            <p>
              {formatMessage({
                id: "pages.subscriptions.list.idx.subtitleOne",
              })}
            </p>
            <Button
              className={style.newSubscriptionBtn}
              text={formatMessage({
                id: "pages.subscriptions.list.idx.subscribeChannel",
              })}
              onClick={onClickAddSubscription}
            />
          </div>
        )}
      </Container>
      <ConfirmModal
        isOpen={isDeleteModalVisible}
        onSubmit={onSubmitDeleteSubscriptionModal}
        onClose={onCloseDeleteSubscriptionModal}
        message={formatMessage({
          id: "pages.subscriptions.list.idx.deleteModalMessage",
        })}
      />
      <ManageModal
        isOpen={isManageModalVisible}
        onSubmit={onSubmitManageSubscriptionModal}
        onClose={onCloseManageSubscriptionModal}
        isValidForm={isValidForm}
        initialValues={{
          channel: current?.channel ? 1 : null,
          tags: current?.tags?.map((item) => ({ id: item, name: item })) ?? null,
        }}
        channels={channels}
        fetchTagsFunction={getTags}
        selectedChannel={selectedChannel}
      />
    </>
  );
};

SubscriptionsList.propTypes = {};

const selector = formValueSelector("subscriptionModalForm");

const mapStateToProps = (state) => ({
  user: state.session.userId,
  isValidForm: isValid("subscriptionModalForm")(state),
  formValues: state.form?.subscriptionModalForm?.values ?? {},
  channels: state?.rssChannelList?.data?.data?.results ?? [],
  selectedChannel: selector(state, "channel"),
  subscriptionsList: state?.subscriptions?.data?.data ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  getSubscriptions: (id) => dispatch(getSubscriptionsList(undefined, { id })),
  createSubscription: (params) => dispatch(createSubscription(params)),
  updateSubscription: (id, params) => dispatch(updateSubscription(params, { id })),
  deleteSubscription: (id) => dispatch(deleteSubscription({ id })),
  getTags: (name) => dispatch(getTagsList({ name, page_size: 10 })),
  getRssChannelList: () => dispatch(getRssChannelList()),
  resetSubscriptionForm: () => dispatch(reset("subscriptionModalForm")),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsList);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import { getSubmittedConceptNotesList, getCountriesList } from "@local/actions";
import { getApplicationStatusColor } from "@local/pages/application-details/utils/adapters.js";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";

// POST_REFACTORING_TODO: Check if this is working correctly

const getTypeCode = (type) => {
  switch (type) {
    case "Open Selection":
      return "open";
    case "Unsolicited Concept Note":
      return "unsolicited";
    case "Direct Selection / Retention":
      return "direct";
    default:
      return "open";
  }
};

const getColumns = (countries) => [
  {
    id: "cn_id",
    title: formatMessage({
      id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.cnId",
    }),
    type: "link",
    to: (row) => `/cfei/${getTypeCode(row.cfei_type)}/${row.eoi_id}`,
  },
  {
    id: "project_title",
    title: formatMessage({
      id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.projectTitle",
    }),
  },
  {
    id: "cfei_type",
    title: formatMessage({
      id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.cfeiType",
    }),
  },
  {
    id: "agency_name",
    title: formatMessage({
      id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.agencyName",
    }),
  },
  {
    id: "countries",
    title: formatMessage({
      id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.countries",
    }),
    type: "custom",
    render: (row) => <>{row.countries.map((item) => countries[item]).join(", ")}</>,
  },
  {
    id: "specializations",
    title: formatMessage({
      id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.specializations",
    }),
    type: "custom",
    render: (row) => <>{row.specializations.map((item) => item.name).join(", ")}</>,
  },
  {
    id: "application_status_display",
    title: formatMessage({
      id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.applicationStatus",
    }),
    type: "custom",
    render: (row) => <Chip icon={<CircleIcon style={{ fill: getApplicationStatusColor(row.application_status) }} fontSize="small" />} label={row.application_status_display} />,
  },
];

const ListOfSubmittedConceptNotes = ({ getSubmittedConceptNotes, countries, getCountries }) => {
  useEffect(() => {
    getCountries();
  }, [getCountries]);

  if (Object.keys(countries).length === 0) return <></>;

  return (
    <Table
      id="list_of_submitted_concept_notes"
      title={formatMessage({
        id: "pages.dashboard.cmp.partner.listOfSubmittedConceptNotes.title",
      })}
      columns={getColumns(countries)}
      fetchFunction={getSubmittedConceptNotes}
    />
  );
};

ListOfSubmittedConceptNotes.propTypes = {};

const mapStateToProps = (state) => ({
  countries: state?.countriesList?.data?.data ?? {},
});

const mapDispatchToProps = (dispatch) => ({
  getSubmittedConceptNotes: (params) => dispatch(getSubmittedConceptNotesList(params)),
  getCountries: () => dispatch(getCountriesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListOfSubmittedConceptNotes);

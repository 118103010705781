import React from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";

const AlertModal = ({ isOpen, message, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({ id: "cmp.alert.modal.idx.warning" })}
      small
      type="warning"
      footer={
        <>
          <Button onClick={onClose} text={formatMessage({ id: "cmp.alert.modal.idx.ok" })} />
        </>
      }
    >
      {message}
    </Modal>
  );
};

AlertModal.propTypes = {};

export default AlertModal;

import { requestGet } from "@local/utils/network";

const actionStart = (resource) => ({
  type: `${resource}_START`,
});

const actionEnd = (resource, data) => ({
  type: `${resource}_END`,
  data,
});

const actionError = (resource, errorData) => ({
  type: `${resource}_ERROR`,
  errorData,
});

const list =
  (resource, url, raiseException = true, cacheKey = undefined, mockResult = undefined) =>
  (params = {}, urlParams = {}) =>
  (dispatch, getState) => {
    const performRequest = () => {
      let withParamsUrl = url;
      Object.keys(urlParams).forEach((key) => {
        withParamsUrl = withParamsUrl.replaceAll(`:${key}`, urlParams[key]);
      });
      dispatch(actionStart(resource));
      return requestGet({ url: withParamsUrl, params })
        .then((response) => {
          dispatch(actionEnd(resource, response));
          return response;
        })
        .catch((error) => {
          dispatch(actionError(resource, error));
          if (raiseException) throw error;
        });
    };
    if (mockResult) {
      dispatch(actionStart(resource));
      return Promise.resolve(mockResult)
        .then((response) => {
          dispatch(actionEnd(resource, response));
          return response;
        })
        .catch((error) => {
          dispatch(actionError(resource, error));
          if (raiseException) throw error;
        });
    }
    if (!Object.keys(urlParams).length && cacheKey) {
      if (Object.keys(getState()[cacheKey].data).length) {
        const cache = getState()[cacheKey].data;
        dispatch(actionStart(resource));
        return Promise.resolve(cache)
          .then((response) => {
            dispatch(actionEnd(resource, response));
            return response;
          })
          .catch((error) => {
            dispatch(actionError(resource, error));
            if (raiseException) throw error;
          });
      } else {
        return performRequest();
      }
    } else {
      return performRequest();
    }
  };

export default list;

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldFile from "@local/components/field-file";
import Button from "@local/components/button";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import style from "./style.module.scss";
import ProfileConfirmation from "../profile-confirmation/index";

const ConceptNoteSubmissionForm = ({ deadlineDate, isConceptNoteUploaded, submitDate, hasUploadCnPermission, conceptNote, onClickUploadConceptNote, onClickCheckProfileConfirmation, isProfileConfirmationChecked }) => {
  return (
    <>
      <FieldGroup>
        {hasUploadCnPermission || isConceptNoteUploaded ? (
          <Field
            component={FieldFile}
            id="cn"
            name="cn"
            label={formatMessage({
              id: "pages.project.details.cmp.project.submission.cmp.concept.note.submission.form.idx.uploadFile",
            })}
            placeholder={formatMessage({
              id: "pages.project.details.cmp.project.submission.cmp.concept.note.submission.form.idx.uploadFile",
            })}
            readOnly={isConceptNoteUploaded}
          />
        ) : (
          <></>
        )}
        {!conceptNote ? (
          <div className={style.boxSection}>
            <span>
              {formatMessage({
                id: "pages.project.details.cmp.project.submission.cmp.concept.note.submission.form.idx.label",
              })}
            </span>
          </div>
        ) : (
          <></>
        )}
        <div className={style.noteWrapper}>
          <span>{`${formatMessage({
            id: "pages.project.details.cmp.project.submission.cmp.concept.note.submission.form.idx.deadline",
          })} ${formatDateForPrint(deadlineDate)}`}</span>
        </div>
        {hasUploadCnPermission || isConceptNoteUploaded ? <ProfileConfirmation onChange={onClickCheckProfileConfirmation} disabled={isConceptNoteUploaded} checked={isConceptNoteUploaded} isProfileConfirmationChecked={isProfileConfirmationChecked} /> : <></>}
        <div className={style.resultWrapper}>
          {isConceptNoteUploaded ? (
            <span>{`${formatMessage({
              id: "pages.project.details.cmp.project.submission.cmp.concept.note.submission.form.idx.submitted",
            })} ${formatDateForPrint(submitDate)}`}</span>
          ) : (
            hasUploadCnPermission && (
              <Button
                text={formatMessage({
                  id: "pages.project.details.cmp.project.submission.cmp.concept.note.submission.form.idx.submit",
                })}
                onClick={onClickUploadConceptNote}
                isDisabled={!(isProfileConfirmationChecked && conceptNote)}
              />
            )
          )}
        </div>
      </FieldGroup>
    </>
  );
};

ConceptNoteSubmissionForm.propTypes = {};

export default reduxForm({
  form: "conceptNoteSubmissionForm",
  enableReinitialize: true,
})(ConceptNoteSubmissionForm);

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Tooltip from "@mui/material/Tooltip";
import { adaptProjectCountries } from "../utils/adapters";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import { getStatusColor } from "./";

// POST_REFACTORING_TODO: These functions are too heavy, check if they can be memoized

export const getOpenApplicationsColumns = (sectors, countries) => {
  let result = [];
  result.push({
    id: "project_title",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.projectTitle",
    }),
  });
  result.push({
    id: "project_displayID",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.projectDisplayid",
    }),
    type: "link",
    to: (row) => `/cfei/open/${row?.eoi_id}/overview/`,
  });
  result.push({
    id: "agency_name",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.agencyName",
    }),
  });
  result.push({
    id: "country",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.country",
    }),
    type: "custom",
    render: (row) => <span>{adaptProjectCountries(row.country, countries)}</span>,
  });
  result.push({
    id: "specializations",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.specializations",
    }),
    type: "custom",
    render: (row) => {
      const rowSpecializationsIds = row.specializations.map((specialization) => specialization.id);
      const getSectorSpecializationsIds = (sector) => sector.specializations.map((specialization) => specialization.id);
      const rowSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => rowSpecializationsIds.includes(sectorSpecializationId)));
      return (
        <Tooltip title={row.specializations.map((item) => item.name).join(", ")} arrow>
          <span>{rowSectors.map((item) => item.name).join(", ")}</span>
        </Tooltip>
      );
    },
  });
  result.push({
    id: "application_date",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.applicationDate",
    }),
    type: "date",
  });
  result.push({
    id: "application_status",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.applicationStatus",
    }),
    type: "custom",
    render: (row) => {
      return (
        <Chip
          icon={<CircleIcon style={{ fill: getStatusColor(row.application_status) }} fontSize="small" />}
          label={formatMessage({
            id: `backend.common.consts.EXTENDED_APPLICATION_STATUSES.${row.application_status}`,
          })}
        />
      );
    },
  });
  return result;
};

export const getDirectApplicationsColumns = (sectors, countries) => {
  let result = [];
  result.push({
    id: "id",
    title: formatMessage({ id: "pages.applications.list.utils.columns.id" }),
    type: "link",
    to: (row) => `/cfei/direct/${row?.eoi_id}/overview/`,
  });
  result.push({
    id: "project_title",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.projectTitleOne",
    }),
  });
  result.push({
    id: "agency_name",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.agencyName",
    }),
  });
  result.push({
    id: "country",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.country",
    }),
    type: "custom",
    render: (row) => <span>{adaptProjectCountries(row.country, countries)}</span>,
  });
  result.push({
    id: "specializations",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.specializationsOne",
    }),
    type: "custom",
    render: (row) => {
      const rowSpecializationsIds = row.specializations.map((specialization) => specialization.id);
      const getSectorSpecializationsIds = (sector) => sector.specializations.map((specialization) => specialization.id);
      const rowSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => rowSpecializationsIds.includes(sectorSpecializationId)));
      return (
        <Tooltip title={row.specializations.map((item) => item.name).join(", ")} arrow>
          <span>{rowSectors.map((item) => item.name).join(", ")}</span>
        </Tooltip>
      );
    },
  });
  result.push({
    id: "submission_date",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.datePosted",
    }),
    type: "date",
  });
  result.push({
    id: "selected_source",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.selectedSource",
    }),
    type: "text",
  });
  result.push({
    id: "application_status",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.applicationStatusOne",
    }),
    type: "custom",
    render: (row) => {
      return (
        <Chip
          icon={<CircleIcon style={{ fill: getStatusColor(row.status) }} fontSize="small" />}
          label={formatMessage({
            id: `backend.common.consts.EXTENDED_APPLICATION_STATUSES.${row.application_status}`,
          })}
        />
      );
    },
  });
  return result;
};

export const getUnsolicitedApplicationsColumns = (sectors, countries) => {
  let result = [];
  result.push({
    id: "id",
    title: formatMessage({ id: "pages.applications.list.utils.columns.id" }),
    type: "link",
    to: (row) => `/cfei/unsolicited/${row?.id}/overview/`,
  });
  result.push({
    id: "project_title",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.projectTitleOne",
    }),
  });
  result.push({
    id: "agency_name",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.agencyName",
    }),
  });
  result.push({
    id: "country",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.country",
    }),
    type: "custom",
    render: (row) => <span>{adaptProjectCountries(row.country, countries)}</span>,
  });
  result.push({
    id: "specializations",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.specializationsOne",
    }),
    type: "custom",
    render: (row) => {
      const rowSpecializationsIds = row.specializations.map((specialization) => specialization.id);
      const getSectorSpecializationsIds = (sector) => sector.specializations.map((specialization) => specialization.id);
      const rowSectors = sectors.filter((sector) => getSectorSpecializationsIds(sector).some((sectorSpecializationId) => rowSpecializationsIds.includes(sectorSpecializationId)));
      return (
        <Tooltip title={row.specializations.map((item) => item.name).join(", ")} arrow>
          <span>{rowSectors.map((item) => item.name).join(", ")}</span>
        </Tooltip>
      );
    },
  });
  result.push({
    id: "submission_date",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.submissionDate",
    }),
    type: "date",
  });
  result.push({
    id: "selected_source",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.isDirect",
    }),
    type: "text",
  });
  result.push({
    id: "application_status",
    title: formatMessage({
      id: "pages.applications.list.utils.columns.applicationStatusOne",
    }),
    type: "custom",
    render: (row) => {
      return (
        <Chip
          icon={<CircleIcon style={{ fill: getStatusColor(row.status) }} fontSize="small" />}
          label={formatMessage({
            id: `backend.common.consts.EXTENDED_APPLICATION_STATUSES.${row.application_status}`,
          })}
        />
      );
    },
  });
  return result;
};

import React from "react";
import FormattedMessage from "../legacy-utils/translator";

const initialState = {
  tabs: [
    {
      path: "identification",
      label: <FormattedMessage id="reducers.partnerProfileDetailsNav.identification" />,
      name: "identification",
    },
    {
      path: "contact",
      label: <FormattedMessage id="reducers.partnerProfileDetailsNav.contact" />,
      name: "mailing",
    },
    {
      path: "mandate",
      label: <FormattedMessage id="reducers.partnerProfileDetailsNav.mandate" />,
      name: "mandate_mission",
    },
    {
      path: "funding",
      label: <FormattedMessage id="reducers.partnerProfileDetailsNav.funding" />,
      name: "fund",
    },
    {
      path: "collaboration",
      label: <FormattedMessage id="reducers.partnerProfileDetailsNav.collaboration" />,
      name: "collaboration",
    },
    {
      path: "project",
      label: <FormattedMessage id="reducers.partnerProfileDetailsNav.project" />,
      name: "project_impl",
    },
    {
      path: "other",
      label: <FormattedMessage id="reducers.partnerProfileDetailsNav.other" />,
      name: "otherInformation",
    },
  ],
};

export default function partneProfileDetailsNavReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

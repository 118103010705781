import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import enFlag from "@local/assets/images/en.png";
import esFlag from "@local/assets/images/es.png";
import frFlag from "@local/assets/images/fr.png";
import EditIcon from "@mui/icons-material/Edit";
import { editLocale } from "@local/legacy-reducers/localeReducer";
import { refreshNavItems } from "@local/legacy-reducers/nav";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";

const LanguageTrigger = ({ language, updateLanguage, refreshNavItems }) => {
  const { i18n } = useTranslation();
  const [isLanguageSelectorExpanded, setIsLanguageSelectorExpanded] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(language);
  const onClickOpenLanguageSelector = () => setIsLanguageSelectorExpanded(true);

  useEffect(() => {
    i18n.changeLanguage(language); // sets the current selected language
  }, []);

  const onSelectLanguage = async (language) => {
    updateLanguage(language);
    refreshNavItems();
    setSelectedLanguage(language);
    // here we set the language for i18next
    await i18n.changeLanguage(language);
    setIsLanguageSelectorExpanded(false);
  };

  return (
    <div>
      <div onClick={!isLanguageSelectorExpanded ? onClickOpenLanguageSelector : undefined} className={isLanguageSelectorExpanded ? style.languageSelectorWrapperExpanded : style.languageSelectorWrapper}>
        <span>
          <EditIcon style={{ fontSize: ".9rem", color: "#fefefe" }} />
        </span>
        <div onClick={() => onSelectLanguage("en")} className={isLanguageSelectorExpanded || selectedLanguage === "en" ? style.languageVisible : style.languageHidden}>
          <img src={enFlag} alt="EN" />
        </div>
        <div onClick={() => onSelectLanguage("es")} className={isLanguageSelectorExpanded || selectedLanguage === "es" ? style.languageVisible : style.languageHidden}>
          <img src={esFlag} alt="ES" />
        </div>
        <div onClick={() => onSelectLanguage("fr")} className={isLanguageSelectorExpanded || selectedLanguage === "fr" ? style.languageVisible : style.languageHidden}>
          <img src={frFlag} alt="FR" />
        </div>
      </div>
    </div>
  );
};

LanguageTrigger.propTypes = {};

const mapStateToProps = (state) => ({
  language: state.locale.lang,
});

const mapDispatchToProps = (dispatch) => ({
  updateLanguage: (language) => dispatch(editLocale(language)),
  refreshNavItems: () => dispatch(refreshNavItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageTrigger);

import React from "react";
import Typography from "@mui/material/Typography";
import { chartColors } from "@local/styles/muiTheme";
import Card from "@local/components/card";
import style from "./numberOfSubmittedConceptNotes.module.scss";
import { formatMessage } from "../../../../legacy-utils/i18nHelper";
import Loader from "@local/components/loader";
import { PieChart, Pie, Cell, Label, ResponsiveContainer } from "recharts";

const renderLabel = ({ value, offset, position, viewBox, ...params }) => (
  <text className="recharts-text recharts-label" textAnchor="middle" y={+viewBox.cy + 12} x={+viewBox.cx}>
    <Typography type="display2" component="tspan">
      {value}
    </Typography>
  </text>
);

const DonutChart = (props) => {
  const { label, colors, data, containerProps, defaultPieProps, pieProps } = props;

  return (
    <ResponsiveContainer {...containerProps}>
      <PieChart>
        <Pie data={data} {...defaultPieProps} {...pieProps}>
          <Label value={label} content={renderLabel} position="center" />
          {data.map(({ name }, index) => (
            <Cell key={name} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

DonutChart.propTypes = {};

DonutChart.defaultProps = {
  containerProps: {
    width: "100%",
    height: 200,
  },
  defaultPieProps: {
    outerRadius: 100,
    innerRadius: "80%",
  },
};

const NumberOfSubmittedConceptNotes = ({ isLoading, numSubmittedCN: total, numSubmittedCNByAgency: data = [], classes }) => {
  const newData = data.map(({ name, count }) => ({ name, value: count }));
  return (
    <Card
      title={
        <div>
          <Typography variant="subtitle2" component="div">
            {formatMessage({
              id: "pages.dashboard.cmp.partner.numberOfSubmittedConceptNotes.title",
            })}
          </Typography>
        </div>
      }
    >
      {isLoading ? (
        <Loader isVisible />
      ) : (
        <>
          <DonutChart
            colors={chartColors}
            label={`${total}`}
            data={newData}
            containerProps={{
              width: "100%",
              height: 200,
            }}
            pieProps={{
              dataKey: "value",
            }}
          />
          <div className={style.labelsWrapper}>
            {newData.map(({ name, value }, index) => (
              <div key={`dashboard_applications_${index}`} className={style.labelWrapper}>
                <div className={style.color} style={{ backgroundColor: chartColors[index] }} />
                <span className={style.label}>{name}</span>
                <span className={style.value}>{value}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  );
};

NumberOfSubmittedConceptNotes.propTypes = {};

export default NumberOfSubmittedConceptNotes;

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Table from "@local/components/table";
import Button from "@local/components/button";
import ConfirmModal from "@local/components/confirm-modal";
import Alert from "@local/components/alert";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import style from "./style.module.scss";
import { useTranslation } from "react-i18next";

const PseaReport = ({ fetchFunction, columns, secondaryColumns, filters, adapterFunction, hasPseaReportPermission, exportPseaDetailsReports, showDownloadInfo, downloadInfo, isOpen, onClose, onCloseDownloadInfo, onChangeTableFilters }) => {
  const { t } = useTranslation();
  return (
    <>
      <Collapse in={showDownloadInfo}>
        <Alert
          type="success"
          content={
            <>
              <span className={style.alertTitle}>
                {formatMessage({
                  id: "pages.reports.list.cmp.verification.idx.report",
                })}
              </span>
              <span>{downloadInfo}</span>
            </>
          }
          actionLabel={formatMessage({
            id: "pages.reports.list.cmp.verification.idx.ok",
          })}
          onClickAction={onCloseDownloadInfo}
          withBottomMargin
        />
      </Collapse>
      <Table
        key="psea_reports_list"
        id="psea_reports_list"
        title={t("PSEA")}
        columns={columns}
        secondaryColumns={secondaryColumns}
        fetchFunction={fetchFunction}
        filters={filters}
        filterInfo={t("Select filters to generate a PSEA report")}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        withUrlSynchronization
        actions={hasPseaReportPermission && <Button variant="text" text={t("Export report")} onClick={exportPseaDetailsReports} />}
      />
      <ConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={exportPseaDetailsReports}
        title={formatMessage({
          id: "pages.reports.list.cmp.verification.idx.reportWarning",
        })}
        type="warning"
        message={
          <div className={style.infoWrapper}>
            <Typography variant="subtitle1" component="div">
              <Alert
                type="info"
                content={formatMessage({
                  id: "pages.reports.list.cmp.verification.idx.infoTitle",
                })}
                withBottomMargin
              />
            </Typography>
            <Typography variant="body1">
              <div className={style.infoText}>
                {formatMessage({
                  id: "pages.reports.list.cmp.verification.idx.info",
                })}
              </div>
            </Typography>
          </div>
        }
      />
    </>
  );
};

PseaReport.propTypes = {};

export default PseaReport;

import React, { useState } from "react";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ShowHide from "@local/components/show-hide";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";

const getColor = (total, partial) => {
  if (total * 0.75 > partial) return "#ff5d34";
  return "#4aad58";
};

const getPartialByRegionalOffice = (countries) => {
  let result = 0;
  countries.forEach((country) => {
    country.partners.forEach((partner) => {
      if (partner.valid_ip_agreement) result++;
    });
  });
  return result;
};

const getTotalByRegionalOffice = (countries) => {
  let result = 0;
  countries.forEach((country) => {
    result += country.partners.length;
  });
  return result;
};

const getPartialByCountry = (partners) => {
  let result = 0;
  partners.forEach((partner) => {
    if (partner.valid_ip_agreement) result++;
  });
  return result;
};

const getTotalByCountry = (partners) => {
  return partners.length;
};

const getColorByRegionalOffice = (countries) => {
  return getColor(getTotalByRegionalOffice(countries), getPartialByRegionalOffice(countries));
};

const getColorByCountry = (partners) => {
  return getColor(getTotalByCountry(partners), getPartialByCountry(partners));
};

const Main = ({ ipAgreement }) => {
  const [expandedItems, setExpandedItems] = useState([]);

  const onClickExpandItem = (clickedItem) => {
    let updatedExpandedItems = [...expandedItems];
    if (expandedItems.find((item) => item === clickedItem)) {
      updatedExpandedItems = updatedExpandedItems.filter((item) => item !== clickedItem);
    } else {
      updatedExpandedItems.push(clickedItem);
    }
    setExpandedItems(updatedExpandedItems);
  };

  if (!ipAgreement.length) return <></>;

  return (
    <>
      <Paper sx={{ p: "1rem" }}>
        <div>
          <div>
            <Typography variant="subtitle2" component="div">
              {formatMessage({
                id: "pages.ip.agreement.cmp.mn.idx.headerTitle",
              })}
            </Typography>
          </div>
          <div className={style.helpTextWrapper}>
            <small className={style.informationText}>
              {formatMessage({
                id: "pages.ip.agreement.cmp.mn.idx.headerHelpText",
              })}
            </small>
          </div>
        </div>
        <div className={style.tableWrapper}>
          <div className={style.columnsHeaderWrapper}>
            <div>
              <span>
                {formatMessage({
                  id: "pages.ip.agreement.cmp.mn.idx.columnDepartment",
                })}
              </span>
            </div>
            <div>
              <span>
                {formatMessage({
                  id: "pages.ip.agreement.cmp.mn.idx.columnUploaded",
                })}
              </span>
              <div className={style.tooltipWrapper}>
                <Tooltip
                  title={formatMessage({
                    id: "pages.ip.agreement.cmp.mn.idx.columnUploadedHelpText",
                  })}
                >
                  <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
                </Tooltip>
              </div>
            </div>
          </div>
          <div>
            {ipAgreement.map((regionalOfficeItem) => (
              <div key={`ip_agreement_regional_office_${regionalOfficeItem.department_label}`} className={style.regionalOfficeWrapper}>
                <div className={style.regionalOfficeHeaderWrapper}>
                  <div className={style.regionalOfficeHeaderArrowWrapper}>
                    <IconButton onClick={() => onClickExpandItem(`ip_agreement_regional_office_${regionalOfficeItem.department_label}`)}>
                      {expandedItems.includes(`ip_agreement_regional_office_${regionalOfficeItem.department_label}`) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </div>
                  <div className={style.regionalOfficeHeaderNameWrapper}>
                    <span>{regionalOfficeItem.department_label}</span>
                  </div>
                  <div
                    className={style.regionalOfficeHeaderCountWrapper}
                    style={{
                      color: getColorByRegionalOffice(regionalOfficeItem.sub_departments),
                    }}
                  >
                    <span>{getPartialByRegionalOffice(regionalOfficeItem.sub_departments)}</span>
                    <span>/</span>
                    <span>{getTotalByRegionalOffice(regionalOfficeItem.sub_departments)}</span>
                  </div>
                </div>
                <ShowHide isVisible={expandedItems.includes(`ip_agreement_regional_office_${regionalOfficeItem.department_label}`)}>
                  <div className={style.regionalOfficeBodyWrapper}>
                    {regionalOfficeItem.sub_departments.map((countryItem) => (
                      <div key={`ip_agreement_country_${regionalOfficeItem.department_label}`} className={style.countryWrapper}>
                        <div className={style.countryHeaderWrapper}>
                          <div className={style.countryHeaderArrowWrapper}>
                            <IconButton onClick={() => onClickExpandItem(`ip_agreement_country_${countryItem.id}`)}>{expandedItems.includes(`ip_agreement_country_${countryItem.id}`) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                          </div>
                          <div className={style.countryHeaderNameWrapper}>
                            <span>{countryItem.label}</span>
                          </div>
                          <div
                            className={style.countryHeaderCountWrapper}
                            style={{
                              color: getColorByCountry(countryItem.partners),
                            }}
                          >
                            <span>{getPartialByCountry(countryItem.partners)}</span>
                            <span>/</span>
                            <span>{getTotalByCountry(countryItem.partners)}</span>
                          </div>
                        </div>
                        <ShowHide isVisible={expandedItems.includes(`ip_agreement_country_${countryItem.id}`)}>
                          <div className={style.countryBodyWrapper}>
                            <div className={style.partnerItemWrapper}>
                              <div className={style.partnerItemCodeWrapper}>
                                <b>
                                  {formatMessage({
                                    id: "pages.ip.agreement.cmp.mn.idx.columnPartnerCode",
                                  })}
                                </b>
                              </div>
                              <div className={style.partnerItemNameWrapper}>
                                <b>
                                  {formatMessage({
                                    id: "pages.ip.agreement.cmp.mn.idx.columnLegalName",
                                  })}
                                </b>
                              </div>
                              <div className={style.partnerItemExpenditureWrapper}>
                                <b>
                                  {formatMessage({
                                    id: "pages.ip.agreement.cmp.mn.idx.columnExpenditure",
                                  })}
                                </b>
                              </div>
                            </div>
                            {countryItem.partners.map((partnerItem) => (
                              <div key={`ip_agreement_partner_${regionalOfficeItem.department_label}`}>
                                <div className={style.partnerItemWrapper}>
                                  <div className={style.partnerItemCodeWrapper}>
                                    <span
                                      style={{
                                        color: partnerItem.valid_ip_agreement ? "#4aad58" : "#ff5d34",
                                      }}
                                    >
                                      {partnerItem.vendor_number}
                                    </span>
                                  </div>
                                  <div className={style.partnerItemNameWrapper}>
                                    <span>{partnerItem.legal_name}</span>
                                  </div>
                                  <div className={style.partnerItemExpenditureWrapper}>
                                    <span>{partnerItem.expenditure}</span>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </ShowHide>
                      </div>
                    ))}
                  </div>
                </ShowHide>
              </div>
            ))}
          </div>
        </div>
      </Paper>
    </>
  );
};

export default Main;

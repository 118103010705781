/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import Modal from "@local/components/modal";
import Button from "@local/components/button";
import FieldSelect from "@local/components/field-select";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group/index";
import { isRequired } from "@local/utils/validators";
import FieldText from "@local/components/field-text";

const OfficeManageModal = ({ isOpen, onSubmit, onClose, isValidForm, stateValues, inEditMode, offices }) => {
  const [officeRoles, setOfficeRoles] = useState(undefined);

  useEffect(() => {
    setOfficeRoles(undefined);
    if (stateValues?.officeRoles && !officeRoles) setOfficeRoles(stateValues.officeRoles);
  }, [stateValues?.officeRoles]);

  const onClickSubmit = () => {
    onSubmit({ officeRoles });
    setOfficeRoles(undefined);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({
        id: "pages.users.management.cmp.office.manage.modal.idx.title",
      })}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.users.management.cmp.office.manage.modal.idx.cancel",
            })}
          />
          <Button
            isDisabled={!isValidForm}
            onClick={onClickSubmit}
            text={formatMessage({
              id: "pages.users.management.cmp.office.manage.modal.idx.save",
            })}
          />
        </>
      }
    >
      <FieldGroup>
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="name"
          name="name"
          label={formatMessage({
            id: "pages.users.management.cmp.office.manage.modal.idx.name",
          })}
          placeholder={formatMessage({
            id: "pages.users.management.cmp.office.manage.modal.idx.name",
          })}
          type="text"
        />
        <Field
          component={FieldText}
          validate={[isRequired]}
          id="code"
          name="code"
          label={formatMessage({
            id: "pages.users.management.cmp.office.manage.modal.idx.code",
          })}
          placeholder={formatMessage({
            id: "pages.users.management.cmp.office.manage.modal.idx.code",
          })}
          type="text"
        />
        <Field
          component={FieldSelect}
          validate={[isRequired]}
          id="countries"
          name="countries"
          label={formatMessage({
            id: "pages.users.management.cmp.office.manage.modal.idx.offices",
          })}
          placeholder={formatMessage({
            id: "pages.users.management.cmp.office.manage.modal.idx.offices",
          })}
          valueField="name"
          labelField="name"
          options={offices}
          multiple
        />
      </FieldGroup>
    </Modal>
  );
};

OfficeManageModal.propTypes = {};

export default reduxForm({ form: "officeModalForm", enableReinitialize: true })(OfficeManageModal);

import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field } from "redux-form";
import FieldText from "@local/components/field-text";
import Button from "@local/components/button";
import { Grid } from "@mui/material";
import { isRequired } from "@local/utils/validators";
import { normalizeDateFormat } from "@local/legacy-utils/dates";
import style from "./style.module.scss";

const ToggleActivationFlagModal = ({ flag, isDeactivateFlagModalVisible, isActivateFlagModalVisible, onClickCloseDeactivateFlagModal, onClickCloseActivateFlagModal, onClickCloseFlag, onClickOpenFlag, pristine, invalid, isReadOnly }) => {
  return (
    <Modal
      isOpen={isReadOnly ? isActivateFlagModalVisible : isDeactivateFlagModalVisible}
      onClose={isReadOnly ? onClickCloseActivateFlagModal : onClickCloseDeactivateFlagModal}
      title={
        isReadOnly
          ? formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.toggle.activate.flag.modal.idx.openFlagModalTitle",
            })
          : formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.toggle.activate.flag.modal.idx.closeFlagModalTitle",
            })
      }
      small
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field
            component={FieldText}
            id="deactivation_comment"
            name="deactivation_comment"
            type="text"
            label={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.toggle.activate.flag.modal.idx.closeFlagModalComment",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.flag.cmp.toggle.activate.flag.modal.idx.closeFlagModalCommentPlaceholder",
            })}
            validate={[isRequired]}
            readOnly={isReadOnly}
            small={isReadOnly}
          />
        </Grid>
        {isReadOnly ? (
          <Grid item xs={12}>
            <div className={style.infoWrapper}>
              <span className={style.infoLabel}>
                {formatMessage({
                  id: "pages.partner.details.cmp.risk.cmp.flag.cmp.toggle.activate.flag.modal.idx.closeFlagInfo",
                })}
              </span>
              <span className={style.infoValue}>
                {flag?.deactivation_comment?.created_by?.name} [{flag?.deactivation_comment?.created_by?.email}]
              </span>
              <span className={style.dateValue}>{normalizeDateFormat(flag?.deactivation_comment?.created)}</span>
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <div className={style.submitButtonWrapper}>
        <Button
          variant="contained"
          onClick={() => (isReadOnly ? onClickOpenFlag(flag) : onClickCloseFlag(flag))}
          isDisabled={!isReadOnly ? pristine || invalid : undefined}
          text={
            isReadOnly
              ? formatMessage({
                  id: "pages.partner.details.cmp.risk.cmp.flag.cmp.toggle.activate.flag.modal.idx.openFlagButton",
                })
              : formatMessage({
                  id: "pages.partner.details.cmp.risk.cmp.flag.cmp.toggle.activate.flag.modal.idx.closeFlagModalSubmit",
                })
          }
        />
      </div>
    </Modal>
  );
};

ToggleActivationFlagModal.propTypes = {};

export default reduxForm({
  form: "deactivateFlagForm",
  enableReinitialize: true,
})(ToggleActivationFlagModal);

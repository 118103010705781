/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { reduxForm, Field } from "redux-form";
import { useParams } from "react-router";
import { connect } from "react-redux";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldFile from "@local/components/field-file";
import Modal from "@local/components/modal";
import Alert from "@local/components/alert";
import Card from "@local/components/card";
import Button from "@local/components/button";
import Tooltip from "@mui/material/Tooltip";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { ROLES } from "@local/legacy-utils/constants";
import { checkPermission, AGENCY_PERMISSIONS, isRoleOffice, AGENCY_ROLES } from "@local/legacy-utils/permissions";
import Loader from "@local/pages/common/loader";
import { getClarificationAnswersList } from "@local/actions";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { formatDateForPrint } from "@local/legacy-utils/dates";
import style from "./style.module.scss";
import { getUrlFromFileField } from "@local/utils";

const AgencyClarificationAnswers = ({
  getClarificationAnswers,
  isAdvEd,
  isPAM,
  isBasEd,
  isMFT,
  isCreator,
  isFocalPoint,
  answers,
  hasPermissionToAdd,
  role,
  isClarificationDeadlinePassed,
  isApplicationDeadlinePassed,
  count,
  loadingAnswers,
  onClickOpenUploadFileModal,
  onClickCloseUploadFileModal,
  onClickOpenRemoveFileModal,
  onClickCloseRemoveFileModal,
  onClickAddClarificationAnswer,
  onClickDeleteClarificationAnswer,
  isUploadFileModalVisible,
  isRemoveFileModalVisible,
  isAdvEdPlus,
  isAdvEdSecretariat,
}) => {
  const { id } = useParams();

  useEffect(() => {
    getClarificationAnswers(id);
  }, []);

  const tooltipText =
    (!isClarificationDeadlinePassed &&
      formatMessage({
        id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.isCfeiDeadlinePassed",
      })) ||
    (isApplicationDeadlinePassed &&
      formatMessage({
        id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.isCfeiDeadlinePassed",
      })) ||
    (count === 3 &&
      formatMessage({
        id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.maxFile",
      }));

  const hasPermission = (hasActionPermission) =>
    (hasActionPermission && (isAdvEd || isAdvEdPlus || isAdvEdSecretariat) && (isCreator || isFocalPoint)) || (hasActionPermission && isBasEd && isCreator) || (hasActionPermission && isMFT && (isFocalPoint || isCreator)) || (hasActionPermission && isPAM && isCreator);

  const fileItems = () =>
    answers.length > 0
      ? answers.map((item) => (
          <div key={`clarification_answer_${item.id}`} className={style.clarificationAnswerItemWrapper}>
            <AttachFileIcon />
            <span
              onClick={() => {
                window.open(getUrlFromFileField(item.file.file_field));
              }}
            >
              {item.title}
            </span>
            {hasPermission(hasPermissionToAdd) && role === ROLES.AGENCY ? (
              <IconButton size="small" onClick={() => onClickOpenRemoveFileModal(item.id)}>
                <CloseIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </div>
        ))
      : role === ROLES.PARTNER && (
          <span className={style.caption}>
            {formatMessage({
              id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.noResponse",
            })}
          </span>
        );

  return (
    <>
      <div className={style.contentWrapper}>
        <Card
          title={
            role === ROLES.AGENCY ? (
              <span className={style.title}>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.title",
                })}
              </span>
            ) : (
              <span className={style.title}>
                {formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.partnerTitle",
                })}
              </span>
            )
          }
        >
          <Loader fullscreen loading={loadingAnswers} />
          {role === ROLES.AGENCY ? (
            !loadingAnswers && answers && fileItems()
          ) : isClarificationDeadlinePassed ? (
            fileItems()
          ) : (
            <span className={style.caption}>
              {formatMessage({
                id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.publishedUntil",
              })}{" "}
            </span>
          )}
          {!loadingAnswers && role === ROLES.AGENCY ? (
            !isClarificationDeadlinePassed || isApplicationDeadlinePassed || count === 3 ? (
              <div className={style.buttonWrapper}>
                <Tooltip title={tooltipText}>
                  <span>
                    <Button
                      text={formatMessage({
                        id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.upload",
                      })}
                      onClick={onClickOpenUploadFileModal}
                      startIcon={<UploadIcon />}
                      isDisabled
                    />
                  </span>
                </Tooltip>
              </div>
            ) : hasPermission(hasPermissionToAdd) ? (
              <div className={style.buttonWrapper}>
                <Button
                  text={formatMessage({
                    id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.upload",
                  })}
                  onClick={onClickOpenUploadFileModal}
                  startIcon={<UploadIcon />}
                />
              </div>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Card>
      </div>
      {isUploadFileModalVisible ? (
        <Modal
          isOpen={isUploadFileModalVisible}
          onClose={onClickCloseUploadFileModal}
          title={formatMessage({
            id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.titleOne",
          })}
          footer={
            <>
              <Button
                onClick={onClickCloseUploadFileModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.cancel",
                })}
              />
              <Button
                onClick={onClickAddClarificationAnswer}
                text={formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.save",
                })}
              />
            </>
          }
          small
        >
          <Alert type="info" content={formatDateForPrint(new Date())} withBottomMargin />
          <FieldGroup>
            <Field
              component={FieldText}
              id="title"
              name="title"
              label={formatMessage({
                id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.comment",
              })}
              placeholder={formatMessage({
                id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.commentPlaceholder",
              })}
              type="text"
              maxLength={5000}
              withCharactersCount
              multiline
            />
            <Field
              component={FieldFile}
              id="file"
              name="file"
              label={formatMessage({
                id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.upload",
              })}
              placeholder={formatMessage({
                id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.upload",
              })}
            />
          </FieldGroup>
        </Modal>
      ) : (
        <></>
      )}
      {isRemoveFileModalVisible ? (
        <Modal
          isOpen={isRemoveFileModalVisible}
          onClose={onClickCloseRemoveFileModal}
          title={formatMessage({
            id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.titleTwo",
          })}
          footer={
            <>
              <Button
                onClick={onClickCloseRemoveFileModal}
                variant="text"
                text={formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.cancel",
                })}
              />
              <Button
                onClick={onClickDeleteClarificationAnswer}
                text={formatMessage({
                  id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.saveOne",
                })}
              />
            </>
          }
          small
        >
          <span>
            {formatMessage({
              id: "pages.project.details.cmp.overview.cmp.agency.clarification.answers.idx.info",
            })}
          </span>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  id: ownProps?.id,
  loadingAnswers: state?.clarificationAnswersList?.loading,
  hasPermissionToAdd: checkPermission(AGENCY_PERMISSIONS.CFEI_PUBLISHED_VIEW_AND_ANSWER_CLARIFICATION_QUESTIONS, state),
  count: state?.clarificationAnswersList?.data?.data?.count ?? 0,
  answers: state?.clarificationAnswersList?.data?.data?.results ?? [],
  isAdvEd: isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED, state),
  isMFT: isRoleOffice(AGENCY_ROLES.MFT_USER, state),
  isPAM: isRoleOffice(AGENCY_ROLES.PAM_USER, state),
  isBasEd: isRoleOffice(AGENCY_ROLES.EDITOR_BASIC, state),
  isAdvEdPlus: isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED_PLUS, state),
  isAdvEdSecretariat: isRoleOffice(AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT, state),
  role: state?.session?.role,
});

const mapDispatchToProps = (dispatch) => ({
  getClarificationAnswers: (id) => dispatch(getClarificationAnswersList(undefined, { projectId: id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "addClarificationAnswerForm", enableReinitialize: true })(AgencyClarificationAnswers));

/* eslint-disable max-len */

import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import MUIAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "@local/assets/images/logo.png";
import enLogo from "@local/assets/images/unpp_en.svg";
import esLogo from "@local/assets/images/unpp_sp.svg";
import frLogo from "@local/assets/images/unpp_fr.svg";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getMainColor } from "@local/styles/muiTheme";
import IconButton from "@mui/material/IconButton";
import Notifications from "@mui/icons-material/Notifications";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Logout from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "@local/legacy-reducers/session";
import { getNotificationsNotReadList } from "@local/actions";
import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import Banner from "../banner";
import { checkPermission, COMMON_PERMISSIONS } from "@local/legacy-utils/permissions";
import LanguageTrigger from "../language-trigger";

const bannerMessages = [];

const getLogoBanner = () => {
  const domain = window.location.hostname;
  switch (domain) {
    case "unpartnerportal.org":
      return "";
    case "www.unpartnerportal.org":
      return "";
    case "uat.unpartnerportal.org":
      return "UAT";
    case "dev.unpartnerportal.org":
      return "DEV";
    case "uncra.unpartnerportal.org":
      return "UNCRA";
    case "localhost":
      return "LOC";
    default:
      return "";
  }
};

const AppBar = ({ logout, language, getNotificationsNotRead, notificationsCount, hasPermission, username, role, email }) => {
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [nameAbbreviation, setNameAbbreviation] = useState("");
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    const documentClientWidth = document.documentElement.clientWidth > 1200;
    const isLongName = documentClientWidth ? username?.length > 20 : username?.length > 10;
    const numOfUsernameChars = documentClientWidth ? 20 : 10;
    if (username) {
      if (isLongName) setFullName(username?.substring(0, numOfUsernameChars)?.concat("..."));
      else setFullName(username);
    } else setFullName(email?.substring(0, numOfUsernameChars)?.concat("..."));
    setNameAbbreviation(stringAvatar(username));
  }, [username, email]);

  useEffect(() => {
    getNotificationsNotRead();
  }, [getNotificationsNotRead]);

  const onClickOpenDialog = () => setIsDialogOpen(true);

  const onClickCloseDialog = () => setIsDialogOpen(false);

  const onClickGoToProductionEnvironment = () => window.location.assign("https://www.unpartnerportal.org/");

  const onClickLogout = () => logout();

  const onClickUserProfile = () => history.push("/user");

  const onClickNotifications = () => history.push("/notifications-list");

  const stringAvatar = (name) => {
    let result = name?.split(" ");
    if (result?.length > 1) return `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`;
    else return "";
  };

  const logoBanner = getLogoBanner();

  return (
    <>
      <Box color={getMainColor()}>
        <MUIAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <div className={style.logoWrapper}>
              <div className={style.logoContentWrapper}>
                {language === "en" ? (
                  <img alt={formatMessage({ id: "cmp.app.bar.idx.logo" })} src={enLogo} className={style.logo} />
                ) : language === "es" ? (
                  <img alt={formatMessage({ id: "cmp.app.bar.idx.logo" })} src={esLogo} className={style.logo} />
                ) : language === "fr" ? (
                  <img alt={formatMessage({ id: "cmp.app.bar.idx.logo" })} src={frLogo} className={style.logo} />
                ) : undefined}
                {logo ? (
                  <span onClick={onClickOpenDialog} className={style.logoBanner}>
                    {logoBanner}
                  </span>
                ) : undefined}
              </div>
            </div>
            <div className={style.bannerWrapper}>
              <Banner messages={bannerMessages} />
            </div>
            <div className={style.stackWrapper}>
              <div>
                <LanguageTrigger />
              </div>
              <div>
                <IconButton size="large" edge="end" onClick={onClickNotifications} color="inherit">
                  <Badge anchorOrigin={{ vertical: "bottom", horizontal: "right" }} badgeContent={hasPermission ? notificationsCount : undefined} color="error" max={99}>
                    <Notifications />
                  </Badge>
                </IconButton>
              </div>
              <Chip
                className={style.nameAndRole}
                onClick={onClickUserProfile}
                icon={
                  username && nameAbbreviation ? (
                    <span style={{ color: getMainColor() }} className={style.nameAbbreviation}>
                      {nameAbbreviation}
                    </span>
                  ) : (
                    <IconButton size="large" edge="end" style={{ color: "#FFFFFF" }} disabled>
                      <AccountCircle />
                    </IconButton>
                  )
                }
                label={
                  <>
                    <span className={style.username}>{fullName}</span>
                    <span className={style.role}>{role}</span>
                  </>
                }
              />
              <div>
                <IconButton size="large" edge="end" onClick={onClickLogout} color="inherit">
                  <Logout />
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </MUIAppBar>
      </Box>
      <Dialog open={isDialogOpen}>
        <DialogTitle>About this environment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are in <b>{logoBanner}</b> environment, it is intended to be used for <b>demonstrational proposes</b> only, if you are here by mistake, please, go to <b>production environment</b> instead.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickGoToProductionEnvironment}>Go to production environment</Button>
          <Button onClick={onClickCloseDialog}>Continue here</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AppBar.propTypes = {};

const mapStateToProps = (state) => ({
  language: state.locale.lang,
  notificationsCount: state.notificationsNotReadList?.data?.data?.count ?? 0,
  hasPermission: checkPermission(COMMON_PERMISSIONS.RECEIVE_NOTIFICATIONS, state),
  username: state?.session?.name ?? undefined,
  email: state?.session?.email ?? undefined,
  role: (state?.session?.position || state?.session?.officeRole) ?? undefined,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutUser()),
  getNotificationsNotRead: () => dispatch(getNotificationsNotReadList({ not_read: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);

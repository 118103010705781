import React, { useState } from "react";
import { connect } from "react-redux";
import { isUserMigrated, isUserInvited } from "@local/actions";
import { IconButton } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import imageLogo from "@local/assets/images/login-page-logo.png";
import imageBackground from "@local/assets/images/login-page-background.jpg";
import imageUn from "@local/assets/images/login-page-un.png";
import imageUnfpa from "@local/assets/images/login-page-unfpa.png";
import imageUnhcr from "@local/assets/images/login-page-unhcr.png";
import imageUnicef from "@local/assets/images/login-page-unicef.png";
import imageUnwomen from "@local/assets/images/login-page-unwomen.png";
import imageFao from "@local/assets/images/login-page-fao.png";
import imageWho from "@local/assets/images/login-page-who.png";
import imageIom from "@local/assets/images/login-page-iom.png";
import imageUnops from "@local/assets/images/login-page-unops.png";
import imageWfp from "@local/assets/images/login-page-wfp.png";
import RegisterIdentityModal from "./components/register-identity-modal";
import GmailLoginIdentityModal from "./components/gmail-login-identity-modal";
import LoginIdentityModal from "./components/login-identity-modal";
import InvitedModal from "./components/invited-modal";
import style from "./style.module.scss";
import { getBaseUrl } from "@local/utils/network";

const getEmailErrorMessage = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regex.test(email.toLowerCase())) return formatMessage({ id: "pages.login.loginRedirect.emailError" });
  return "";
};

const LoginRedirect = ({ isUserMigrated, isUserInvited }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const [modalVisible, setModalVisible] = useState(undefined);

  const onClickCloseModal = () => setModalVisible(undefined);

  const onChangeEmail = (event) => {
    const { value } = event.target;
    setEmail(value);
    setError(getEmailErrorMessage(value));
  };

  const onClickRegister = () => window.location.assign("/registration-email");

  const onClickBack = () => window.location.assign(`${getBaseUrl()}/landing/`);

  const onClickSubmitIdentity = () => window.location.assign(`${getBaseUrl()}/api/auth/authorize/login/csopartner_authn/?email=${encodeURIComponent(email)}`);

  const onClickSubmit = async () => {
    const agenciesDomain = ["un.org", "unwomen.org", "fao.org", "undp.org", "unfpa.org", "unhcr.org", "unicc.org", "unicef.org", "wfp.org", "who.int", "wipo.int", "iom.int", "unops.org"];

    const emailDomain = email?.split("@")?.at(1).toLowerCase();
    let isAgency = undefined;
    let isUn = undefined;
    let isRegistered = undefined;
    let isMigrated = undefined;
    let isGmail = undefined;
    let isInvited = undefined;
    try {
      const response = await isUserMigrated(email);
      isMigrated = response?.data?.is_migrated;
      isRegistered = true;
    } catch (error) {
      if (error?.response?.status === 404) {
        isMigrated = false;
        isRegistered = false;
      }
    }

    try {
      const isInvitedResponse = await isUserInvited(email);
      isInvited = isInvitedResponse?.data?.at(0);
    } catch (error) {
      if (error?.response?.status === 404) {
        isInvited = false;
      }
    }

    if (!agenciesDomain.includes(emailDomain) && isInvited === true) {
      setModalVisible("invited-modal");
    }

    if (agenciesDomain.includes(emailDomain)) {
      isGmail = false;
      isAgency = true;
      isUn = emailDomain === "un.org";
    } else {
      isGmail = emailDomain === "gmail.com";
      isAgency = false;
      isUn = false;
    }
    if (isAgency) {
      setModalVisible(undefined);
      if (isUn) {
        window.location.assign(`${getBaseUrl()}/api/auth/authorize/login/unsc/?email=${encodeURIComponent(email)}`);
      } else {
        window.location.assign(`${getBaseUrl()}/api/auth/authorize/login/agency_authn/?email=${encodeURIComponent(email)}`);
      }
    } else {
      if (isRegistered) {
        if (isMigrated) {
          window.location.assign(`${getBaseUrl()}/api/auth/authorize/login/csopartner_authn/?email=${encodeURIComponent(email)}`);
        } else {
          if (isInvited !== true) {
            if (isGmail) {
              setModalVisible("gmail-login-identity-modal");
            } else {
              setModalVisible("login-identity-modal");
            }
          }
        }
      } else {
        setModalVisible("register-identity-modal");
      }
    }
  };

  return (
    <>
      <div
        className={style.mainWrapper}
        style={{
          backgroundImage: `linear-gradient(0deg, #2296F3BB, #2296F3BB), url("${imageBackground}")`,
        }}
      >
        <div className={style.headerWrapper}>
          <IconButton onClick={onClickBack}>
            <span className={style.backButtonWrapper}>
              <KeyboardArrowLeft fontSize="large" />
            </span>
          </IconButton>
        </div>
        <div className={style.contentWrapper}>
          <div className={style.columnsWrapper}>
            <div>
              <div className={style.loginWrapper}>
                <span className={style.title}>{formatMessage({ id: "pages.login.loginRedirect.title" })}</span>
                <span className={style.subtitle}>{formatMessage({ id: "pages.login.loginRedirect.subtitle" })}</span>
                <div className={style.formWrapper}>
                  <label className={style.formLabel} htmlFor="email">
                    {formatMessage({
                      id: "pages.login.loginRedirect.emailField",
                    })}
                  </label>
                  <input className={error === "" ? style.formField : style.formFieldWithError} type="email" name="email" id="email" value={email} onChange={onChangeEmail} />
                  <small className={style.errorMessage}>{error}</small>
                  <button disabled={error !== "" || email === ""} className={style.formButton} type="submit" onClick={onClickSubmit}>
                    <span>{formatMessage({ id: "pages.login.loginRedirect.next" })}</span>
                    <NavigateNextIcon />
                  </button>
                  <small className={style.formButtonHelpText}>
                    {formatMessage({
                      id: "pages.login.loginRedirect.passwordHelperText",
                    })}
                  </small>
                </div>
                <span className={style.register} onClick={onClickRegister}>
                  {formatMessage({
                    id: "pages.login.loginRedirect.registerLink",
                  })}
                </span>
              </div>
            </div>
            <div>
              <img
                alt={formatMessage({
                  id: "pages.login.loginRedirect.logoAltText",
                })}
                src={imageLogo}
              />
              <p className={style.description}>{formatMessage({ id: "pages.login.loginRedirect.description" })}</p>
              <a
                className={style.learnMoreLink}
                alt={formatMessage({
                  id: "pages.login.loginRedirect.learnMoreAltText",
                })}
                href={`${getBaseUrl()}/landing/about/`}
              >
                {formatMessage({ id: "pages.login.loginRedirect.learnMore" })}
              </a>
              <div className={style.agenciesLogosWrapper}>
                <img alt="IOM" src={imageIom} />
                <img alt="UN" src={imageUn} />
                <img alt="UN WOMEN" src={imageUnwomen} />
                <img alt="FAO" src={imageFao} />
                <img alt="UNFPA" src={imageUnfpa} />
                <img alt="UNHCR" src={imageUnhcr} />
                <img alt="UNICEF" src={imageUnicef} />
                <img alt="UNOPS" src={imageUnops} />
                <img alt="WFP" src={imageWfp} />
                <img alt="WHO" src={imageWho} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <RegisterIdentityModal isOpen={modalVisible === "register-identity-modal"} onClose={onClickCloseModal} onSubmit={onClickRegister} />
      <GmailLoginIdentityModal isOpen={modalVisible === "gmail-login-identity-modal"} onClose={onClickCloseModal} onSubmit={onClickSubmitIdentity} />
      <LoginIdentityModal isOpen={modalVisible === "login-identity-modal"} onClose={onClickCloseModal} onSubmit={onClickSubmitIdentity} />
      <InvitedModal isOpen={modalVisible === "invited-modal"} onClose={onClickCloseModal} onSubmit={onClickSubmitIdentity} />
    </>
  );
};

LoginRedirect.propTypes = {};

const mapDispatchToProps = (dispatch) => ({
  isUserMigrated: (email) => dispatch(isUserMigrated({ email })),
  isUserInvited: (email) => dispatch(isUserInvited({ email })),
});

export default connect(null, mapDispatchToProps)(LoginRedirect);

import { Grid } from "@mui/material";
import { Field, change, reduxForm } from "redux-form";
import { isRequired, isRequiredIfValue } from "@local/utils/validators";
import { connect } from "react-redux";
import { useEffect, useMemo } from "react";
import FieldRadioGroup from "@local/components/field-radio-group";
import FieldCheckbox from "@local/components/field-checkbox";
import i18n from "@local/i18next/i18n";

const DuplicateForm = ({ formValues, selectedPartnerDuplicate, dispatch }) => {
  const isPartnerPickRequired = useMemo(() => isRequiredIfValue("is_duplicate", "true"), []);
  const isQuestion2Locked = (formValues) => {
    return formValues?.is_duplicate !== "true";
  };
  useEffect(() => {
    if (formValues?.is_duplicate === "false") {
      dispatch(change("duplicatePartnersForm", "duplicate_object_id", undefined));
    }
  }, [formValues?.is_duplicate, dispatch]);
  return (
    <div style={{ padding: "1rem" }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                label={i18n.t("Are partners real duplicates?")}
                validate={[isRequired]}
                component={FieldRadioGroup}
                id="is_duplicate"
                name="is_duplicate"
                options={[
                  {
                    value: true,
                    label: i18n.t("Yes"),
                  },
                  {
                    value: false,
                    label: i18n.t("No"),
                  },
                ]}
              />
            </Grid>
            {formValues?.is_duplicate === "true" && (
              <>
                <Grid item xs={12}>
                  <Field
                    label={i18n.t("Which partner should be deleted?")}
                    validate={[isPartnerPickRequired]}
                    component={FieldRadioGroup}
                    id="duplicate_object_id"
                    name="duplicate_object_id"
                    readOnly={isQuestion2Locked(formValues)}
                    options={[
                      {
                        value: selectedPartnerDuplicate.partner.id,
                        label: i18n.t("Partner 1"),
                      },
                      {
                        value: selectedPartnerDuplicate.duplicate_partner.id,
                        label: i18n.t("Partner 2"),
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field label={i18n.t("Notify involved partners?")} validate={[isPartnerPickRequired]} component={FieldCheckbox} id="notify-duplicate-partners" name="notify" />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: state?.form?.duplicatePartnersForm?.values,
});

export default connect(mapStateToProps, null)(reduxForm({ form: "duplicatePartnersForm", enableReinitialize: true })(DuplicateForm));

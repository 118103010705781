import React from "react";
import PropTypes from "prop-types";
import MUIButton from "@mui/material/Button";

const Button = ({ text, type, variant, onClick, isDisabled, startIcon, endIcon, color, className }) => {
  return (
    <MUIButton type={type} variant={variant} onClick={onClick} disabled={isDisabled} startIcon={startIcon} endIcon={endIcon} color={color} className={className}>
      {text}
    </MUIButton>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  variant: "contained",
  isDisabled: false,
};

export default Button;

import { AGENCY_ROLES } from "./permissions";
import { ROLES } from "./constants";

export const RESTRICTION_TYPES = {
  DISABLED: "disabled",
  HIDE: "hide",
  NONE: "none",
};

export const AGENCY_RESTRICTIONS = {
  dashboard: {},
  partnershipOpportunities: {
    agency: {
      FAO: {
        type: RESTRICTION_TYPES.HIDE,
        roles: [
          AGENCY_ROLES.GLOBAL_EDITOR,
          AGENCY_ROLES.ADMINISTRATOR,
          AGENCY_ROLES.HQ_EDITOR,
          AGENCY_ROLES.READER,
          AGENCY_ROLES.EDITOR_BASIC,
          AGENCY_ROLES.EDITOR_ADVANCED,
          AGENCY_ROLES.EDITOR_ADVANCED,
          AGENCY_ROLES.EDITOR_ADVANCED_PLUS,
          AGENCY_ROLES.PAM_USER,
          AGENCY_ROLES.MFT_USER,
          AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT,
        ],
      },
    },
  },
  partners: {
    applications: {
      agency: {
        UN_SECRETARIAT: {
          type: RESTRICTION_TYPES.DISABLED,
          roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT, AGENCY_ROLES.HQ_EDITOR, AGENCY_ROLES.ADMINISTRATOR],
        },
      },
    },
    unData: {
      partnerCodeManually: {
        agency: {
          UNFPA: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.ADMINISTRATOR],
          },
        },
      },
      partnerCodeDelete: {
        agency: {
          UNFPA: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.ADMINISTRATOR],
          },
        },
      },
      documentDelete: {
        agency: {
          UNFPA: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.ADMINISTRATOR],
          },
        },
      },
    },
    risk: {
      flags: {
        raiseFlag: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          },
        },
        editFlag: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          },
        },
        closeFlag: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          },
        },
        addCommentToFlag: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          },
        },
        reOpenFlag: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.PAM_USER, AGENCY_ROLES.MFT_USER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            WFP: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            FAO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            WHO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            IOM: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
            UNOPS: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED],
            },
          },
        },
      },
      observations: {
        addObservation: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          },
        },
        editObservation: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          },
        },
        deleteObservation: {
          agency: {
            UNICEF: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNHCR: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UNFPA: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UN_SECRETARIAT: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            UN_WOMEN: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            WIPO: {
              type: RESTRICTION_TYPES.HIDE,
              roles: [AGENCY_ROLES.READER],
            },
            IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
            UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          },
        },
      },
    },
  },
  reports: {
    reportManagement: {
      agency: {
        UN_SECRETARIAT: {
          type: RESTRICTION_TYPES.DISABLED,
          roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT, AGENCY_ROLES.HQ_EDITOR, AGENCY_ROLES.ADMINISTRATOR],
        },
      },
    },
    verificationAndObservations: {
      sectorOfSpecialization: {
        agency: {
          UNICEF: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER],
          },
          UNHCR: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UNFPA: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UN_WOMEN: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER],
          },
          FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
        },
      },
      populationOfConcent: {
        agency: {
          UNICEF: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER],
          },
          UNHCR: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UNFPA: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UN_WOMEN: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER],
          },
          FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
        },
      },
      UNExperience: {
        agency: {
          UNICEF: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER],
          },
          UNHCR: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UNFPA: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          WFP: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UN_WOMEN: {
            type: RESTRICTION_TYPES.HIDE,
            roles: [AGENCY_ROLES.READER],
          },
          FAO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          WHO: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          IOM: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
          UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: [AGENCY_ROLES.READER] },
        },
      },
    },
  },
  invitedNGOPartners: {
    agency: {
      UN_SECRETARIAT: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      UNICEF: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      UNHCR: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      UNFPA: {
        type: RESTRICTION_TYPES.HIDE,
        roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.EDITOR_ADVANCED_PLUS, AGENCY_ROLES.PAM_USER, AGENCY_ROLES.MFT_USER, AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT],
      },
      WFP: { type: RESTRICTION_TYPES.HIDE, roles: Object.values(AGENCY_ROLES) },
      UN_WOMEN: {
        type: RESTRICTION_TYPES.HIDE,
        roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.EDITOR_ADVANCED_PLUS, AGENCY_ROLES.PAM_USER, AGENCY_ROLES.MFT_USER, AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT],
      },
      FAO: {
        type: RESTRICTION_TYPES.HIDE,
        roles: [AGENCY_ROLES.READER, AGENCY_ROLES.PAM_USER, AGENCY_ROLES.MFT_USER, AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT],
      },
      WHO: {
        type: RESTRICTION_TYPES.HIDE,
        roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED_PLUS, AGENCY_ROLES.PAM_USER, AGENCY_ROLES.MFT_USER, AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT],
      },
      WIPO: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      IOM: { type: RESTRICTION_TYPES.HIDE, roles: Object.values(AGENCY_ROLES) },
      UNOPS: {
        type: RESTRICTION_TYPES.HIDE,
        roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.EDITOR_ADVANCED_PLUS, AGENCY_ROLES.PAM_USER, AGENCY_ROLES.MFT_USER, AGENCY_ROLES.GLOBAL_EDITOR],
      },
    },
  },
  subscriptions: {
    agency: {
      UN_SECRETARIAT: {
        type: RESTRICTION_TYPES.DISABLED,
        roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED_SECRETARIAT, AGENCY_ROLES.HQ_EDITOR, AGENCY_ROLES.ADMINISTRATOR],
      },
    },
  },
  userManagement: {
    regionalOffices: {
      agency: {
        UN_SECRETARIAT: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        UNICEF: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        UNHCR: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        WFP: {
          type: RESTRICTION_TYPES.HIDE,
          roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.ADMINISTRATOR],
        },
        UNFPA: {
          type: RESTRICTION_TYPES.HIDE,
          roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.ADMINISTRATOR],
        },
        UN_WOMEN: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        FAO: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        WHO: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        WIPO: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        IOM: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
        UNOPS: {
          type: RESTRICTION_TYPES.HIDE,
          roles: Object.values(AGENCY_ROLES),
        },
      },
    },
  },
  ipAgreement: {
    agency: {
      UN_SECRETARIAT: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      UNICEF: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      UNHCR: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      WFP: { type: RESTRICTION_TYPES.HIDE, roles: Object.values(AGENCY_ROLES) },
      UNFPA: {
        type: RESTRICTION_TYPES.HIDE,
        roles: [AGENCY_ROLES.READER, AGENCY_ROLES.EDITOR_BASIC, AGENCY_ROLES.EDITOR_ADVANCED, AGENCY_ROLES.ADMINISTRATOR],
      },
      UN_WOMEN: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      FAO: { type: RESTRICTION_TYPES.HIDE, roles: Object.values(AGENCY_ROLES) },
      WHO: { type: RESTRICTION_TYPES.HIDE, roles: Object.values(AGENCY_ROLES) },
      WIPO: {
        type: RESTRICTION_TYPES.HIDE,
        roles: Object.values(AGENCY_ROLES),
      },
      IOM: { type: RESTRICTION_TYPES.HIDE, roles: Object.values(AGENCY_ROLES) },
      UNOPS: { type: RESTRICTION_TYPES.HIDE, roles: Object.values(AGENCY_ROLES) },
    },
  },
};

export const checkAgencyRestrictions = (restriction, session) => {
  const agencyName = session?.agencyName;
  const role = session?.officeRole;
  if (restriction?.agency && Object.keys(restriction?.agency).includes(agencyName) && restriction?.agency[agencyName].roles.includes(role)) {
    return restriction?.agency[agencyName].type;
  }
  return RESTRICTION_TYPES.NONE;
};

export const checkFlagRestrictions = (restriction, session, flagAgencyId = undefined, flagCreatedBy = undefined) => {
  const havePermissionsRoles = [AGENCY_ROLES.HQ_EDITOR, AGENCY_ROLES.ADMINISTRATOR];
  const agencyName = session?.agencyName;
  const role = session?.officeRole;
  const isSameAgency = flagAgencyId === session?.agencyId;
  const isCreator = flagCreatedBy === session?.userId;

  let result = true;

  if (restriction?.agency && Object.keys(restriction?.agency).includes(agencyName) && restriction?.agency[agencyName].roles.includes(role)) result = false;
  if (havePermissionsRoles.includes(role) && !isSameAgency) result = false;
  if (!restriction?.agency[agencyName].roles.includes(role) && !isCreator) result = false;
  if ((!restriction?.agency[agencyName].roles.includes(role) && isCreator) || (havePermissionsRoles.includes(role) && isSameAgency)) result = true;
  return result;
};

export const isSectionVisible = (restriction, session) => checkAgencyRestrictions(restriction, session) === RESTRICTION_TYPES.NONE;

export const filterRestrictions = (items, restrictions, session) => {
  return items.reduce((final, item) => {
    let itemRestricted = RESTRICTION_TYPES.NONE;
    if (session.role === "agency") {
      restrictions[item.name] && (itemRestricted = checkAgencyRestrictions(restrictions[item.name], session));
    }
    switch (itemRestricted) {
      case RESTRICTION_TYPES.HIDE:
        return final;
      case RESTRICTION_TYPES.DISABLED: {
        final.push({ ...item, disabled: true });
        return final;
      }
      default: {
        final.push(item);
        return final;
      }
    }
  }, []);
};

export const checkRestrictedPath = (state) => state.session.role === ROLES.AGENCY && checkAgencyRestrictions(AGENCY_RESTRICTIONS.dashboard, state.session) !== RESTRICTION_TYPES.NONE;

//Restricted agencies and their respective restricted paths
export const pathRestrictions = [
  {
    agency: "FAO",
    paths: ["/cfei"],
  },
  // Add more agencies and their restricted paths as needed
];

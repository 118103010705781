import React, { useEffect, useState } from "react";
import UsersList from "./components/users-list";
import { usersNav } from "@local/utils/constants";
import { adaptRoles } from "@local/utils/adapters";
import { connect } from "react-redux";
import { getOfficesList, getPartnerUsersList } from "@local/actions";

import Container from "./components/container";
import { getUserColumns } from "./utils/columns";
import { getUserFilters } from "./utils/filters";
import { useParams } from "react-router-dom";

// POST_REFACTORING_TODO: We should avoid duplicated office/role pairs, not mandatory, they are handled by backend

// TODO: fix the edit of regional office

const OrganizationUsers = ({ getUsers, officesChoices, getOffices, rolesFilter, userId, tabs, officeId }) => {
  const [filterParams, setFilterParams] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { type } = useParams();

  useEffect(() => {
    getOffices();
  }, [getOffices]);

  const onChangeTableFilters = (filterValues) => {
    setFilterParams(filterValues);
  };

  const afterRefresh = () => {
    setShouldRefresh(false);
  };

  let content = undefined;

  switch (type) {
    case "users":
      content = (
        <>
          <UsersList userId={userId} fetchFunction={getUsers} columns={getUserColumns()} filters={getUserFilters(officesChoices, rolesFilter)} shouldRefresh={shouldRefresh} afterRefresh={afterRefresh} onChangeTableFilters={onChangeTableFilters} officeId={officeId} />
        </>
      );
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Container tabs={tabs} type={type}>
        {content}
      </Container>
    </>
  );
};

OrganizationUsers.propTypes = {};

const mapStateToProps = (state) => ({
  officesChoices: state?.officesList?.data?.data ? state?.officesList?.data?.data["user-offices-choices"] : [],
  rolesFilter: adaptRoles(state.partnerProfileConfig["user-role-filter"] ?? {}),
  userId: state?.session?.userId,
  tabs: usersNav(state)?.filter((item) => item.isVisible),
  countries: state.countries,
  session: state?.session,
  officeId: state?.session?.officeId,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (params) => dispatch(getPartnerUsersList(params)),
  getOffices: (params) => dispatch(getOfficesList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUsers);

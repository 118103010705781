/* eslint-disable no-undef */

import React, { useState, useEffect, useRef } from "react";
import style from "./style.module.scss";
import banner from "@local/assets/images/bannerV2.png";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";

const Banner = ({ children, role }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [isFraudBannerVisible, setIsFraudBannerVisible] = useState(false);
  const timeout = useRef(undefined);

  useEffect(() => {
    const closedBannerId = localStorage.getItem("closedBannerId");
    const closedFraudBannerId = localStorage.getItem("closedFraudBannerId");

    // Check if the current date is before December 9, 2024
    const currentDate = new Date();
    const fraudBannerEndDate = new Date("2024-12-09T23:59:59");

    if (currentDate <= fraudBannerEndDate && closedFraudBannerId !== "fraud-awareness-notification") {
      const id = setTimeout(() => {
        setIsFraudBannerVisible(true);
      }, 2000);
      timeout.current = id;
    }

    if (closedBannerId !== "psea-webinar-notification") {
      const id = setTimeout(() => {
        setIsBannerVisible(true);
      }, 2000);
      timeout.current = id;
    }

    return () => clearTimeout(timeout.current);
  }, []);

  const onClickBanner = () => {
    window.open("https://supportcso.unpartnerportal.org/hc/en-us/sections/14883240182807-PSEA-Module-User-Guides-and-Resource-Materials", "_blank", "noopener");
    setIsBannerVisible(false);
    localStorage.setItem("closedBannerId", "psea-webinar-notification");
  };

  const onClickClose = () => {
    setIsBannerVisible(false);
    localStorage.setItem("closedBannerId", "psea-webinar-notification");
  };

  const onClickFraudBanner = () => {
    window.open("https://learning.unpartnerportal.org/course/view.php?id=2", "_blank", "noopener");
    setIsFraudBannerVisible(false);
    localStorage.setItem("closedFraudBannerId", "fraud-awareness-notification");
  };

  const onClickCloseFraud = () => {
    setIsFraudBannerVisible(false);
    localStorage.setItem("closedFraudBannerId", "fraud-awareness-notification");
  };

  return (
    <div>
      <div className={style.mainWrapper}>
        <div style={{ backgroundColor: "#483D8B" }} className={isFraudBannerVisible ? `${style.fraudBannerWrapper} ${style.bannerWrapperVisible}` : `${style.bannerWrapper} ${style.bannerWrapperHidden}`}>
          <div className={style.bannerTopWrapper}>
            <div>
              <span className={style.text}>
                <span className={style.textBold}>International Fraud Awareness Week! </span>
                The UN upholds a zero-tolerance policy toward fraud and corruption, and we expect the same from our partners. Fraud and corruption threaten our reputation and reduce our ability to reach those we serve. Together, let’s prevent and address these
                challenges, ensuring trust and integrity in all we do. We are counting on you!
              </span>
            </div>
            <div>
              <div className={style.closeButtonWrapper}>
                <CloseIcon
                  onClick={onClickCloseFraud}
                  style={{
                    cursor: "pointer",
                    color: "#fefefe",
                    fontSize: "1.25rem",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.bannerFraudBottomWrapper}>
            {isFraudBannerVisible ? (
              <span onClick={onClickFraudBanner} className={style.button}>
                We encourage you to make full use of the Fraud Awareness Basic Course
              </span>
            ) : undefined}
          </div>
        </div>
        <div style={{ backgroundImage: `url(${banner})` }} className={isBannerVisible ? `${style.bannerWrapper} ${style.bannerWrapperVisible}` : `${style.bannerWrapper} ${style.bannerWrapperHidden}`}>
          <div className={style.bannerTopWrapper}>
            <div>
              <span className={style.text}>For more information on the Protection from Sexual Exploitation and Abuse (PSEA) module, and to learn how to use it, we encourage you to review the user guides and recordings from previous webinars.</span>
            </div>
            <div>
              <div className={style.closeButtonWrapper}>
                <CloseIcon
                  onClick={onClickClose}
                  style={{
                    cursor: "pointer",
                    color: "#fefefe",
                    fontSize: "1.25rem",
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.bannerBottomWrapper}>
            {isBannerVisible ? (
              <span onClick={onClickBanner} className={style.button}>
                View Resource
              </span>
            ) : undefined}
          </div>
        </div>
      </div>
      <>{children}</>
    </div>
  );
};

Banner.propTypes = {};

const mapStateToProps = (state) => ({
  role: state?.session?.role ?? {},
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);

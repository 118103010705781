/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory, useLocation } from "react-router";
import HeaderNavigation from "@local/components/header-navigation";
import MainContent from "@local/components/main-content-wrapper";
import i18n from "@local/i18next/i18n";

const Container = ({ children, tabs, type }) => {
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event, index) => {
    history.push({
      pathname: `/org/${tabs[index].path}`,
      query: location.query,
    });
  };

  const index = tabs.findIndex((tab) => tab.path === type);
  if (index === -1) history.push("/");

  const filteredTabs = tabs.filter((tab) => tab.isVisible);

  return (
    <HeaderNavigation index={index} title={i18n.t("Organization users")} tabs={filteredTabs} handleChange={handleChange}>
      <MainContent>{children}</MainContent>
    </HeaderNavigation>
  );
};

Container.propTypes = {};

export default Container;

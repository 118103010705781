import React from "react";
import Table from "@local/components/table";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getObservationsColumns, getObservationsExpandSectionItems } from "../../utils/columns";
import { getObservationsFilters } from "../../utils/filters";

const Observations = ({
  fetchFunction,
  hasResolveEscalatePermission,
  hasReviewSanctionMatchPermission,
  flagTypes,
  riskCategories,
  shouldRefresh,
  afterRefresh,
  userId,
  onClickOpenUpdateObservationModal,
  onClickOpenUpdateEscalatedObservationModal,
  onClickOpenUpdateSanctionObservationModal,
}) => {
  return (
    <Table
      key="partner_observations"
      id="partner_observations"
      title={formatMessage({
        id: "pages.partner.details.cmp.observations.idx.observations",
      })}
      columns={getObservationsColumns(hasResolveEscalatePermission, hasReviewSanctionMatchPermission, userId, onClickOpenUpdateObservationModal, onClickOpenUpdateEscalatedObservationModal, onClickOpenUpdateSanctionObservationModal)}
      fetchFunction={fetchFunction}
      filters={getObservationsFilters(flagTypes, riskCategories)}
      expandSectionItems={getObservationsExpandSectionItems()}
      shouldRefresh={shouldRefresh}
      afterRefresh={afterRefresh}
    />
  );
};

Observations.propTypes = {};

export default Observations;

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import VerificationIcon from "@local/components/verification-icon";
import { SPECIAL_PARTNER_TYPES, getJobTitleTypes } from "@local/utils/constants";
import Chip from "@mui/material/Chip";
import SanctionStatus from "@local/components/sanction-status";

const adaptExperiences = (experiences) => {
  const result = [];
  experiences.forEach((item) => {
    const foundItemIndex = result.findIndex((toFindItem) => toFindItem.sector === item.specialization?.category?.id);
    if (foundItemIndex !== -1) {
      result[foundItemIndex].areas.push(item.specialization?.id);
    } else {
      result.push({
        id: item?.specialization?.category?.id,
        name: item?.specialization?.category?.name,
        sector: item?.specialization?.category?.id,
        years: item.years,
        areas: [item.specialization?.name],
      });
    }
  });
  return result;
};

export const getPartnersColumns = (specialPartnerTypes, countries, partnerTypes) => [
  {
    id: "legal_name",
    title: formatMessage({ id: "pages.partners.list.utils.columns.name" }),
    type: "link",
    to: (row) => (specialPartnerTypes.find((item) => item.value === row.display_type) ? `/special-partner/${row.id}/overview` : `/partner/${row.id}/overview`),
  },
  {
    id: "icons",
    title: "",
    type: "custom",
    render: (row) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.is_hq ? (
            <div style={{ marginRight: ".25rem" }}>
              <Chip size="small" color="primary" label="HQ" />
            </div>
          ) : (
            <></>
          )}
          {!SPECIAL_PARTNER_TYPES.includes(row.display_type) ? (
            <div style={{ marginRight: ".25rem" }}>
              <VerificationIcon profileStatus={row?.partner_additional?.profile_status} />
            </div>
          ) : (
            <></>
          )}
          <SanctionStatus sanction={row?.partner_additional?.sanctions_status} />
          {row?.is_locked ? (
            <div>
              <Chip
                size="small"
                style={{ backgroundColor: "#e84033", color: "#FFFFFF" }}
                label={formatMessage({
                  id: "pages.partners.list.utils.columns.deleted",
                })}
              />
            </div>
          ) : undefined}
        </div>
      );
    },
  },
  {
    id: "other_names",
    title: formatMessage({
      id: "pages.partners.list.utils.columns.otherNames",
    }),
    type: "custom",
    render: (row) => <span>{row?.other_names?.join(", ") || "--"}</span>,
  },
  {
    id: "display_type",
    title: formatMessage({
      id: "pages.partners.list.utils.columns.displayType",
    }),
    type: "custom",
    render: (row) => <span>{partnerTypes?.find((item) => row.display_type === item.value)?.label || "--"}</span>,
  },
  {
    id: "country_code",
    title: formatMessage({
      id: "pages.partners.list.utils.columns.countryCode",
    }),
    type: "custom",
    render: (row) => <span>{countries?.find((item) => row.country_code === item.value)?.label || "--"}</span>,
  },
  {
    id: "experience_working",
    title: formatMessage({
      id: "pages.partners.list.utils.columns.experienceWorking",
    }),
  },
];

export const getPartnersExpandSectionItems = () => [
  {
    id: "mailing_address.org_email",
    title: formatMessage({ id: "pages.partners.list.utils.columns.mailing" }),
  },
  {
    id: "mailing_address.mailing_telephone",
    title: formatMessage({ id: "pages.partners.list.utils.columns.phone" }),
  },
  {
    id: "mailing_address.website",
    title: formatMessage({ id: "pages.partners.list.utils.columns.website" }),
  },
  {
    id: "org_head.fullname",
    title: formatMessage({ id: "pages.partners.list.utils.columns.headName" }),
  },
  {
    id: "org_head.job_title",
    title: formatMessage({ id: "pages.partners.list.utils.columns.headTitle" }),
    type: "custom",
    render: (row) => <span>{row?.org_head?.job_title?.map((item) => getJobTitleTypes().find((element) => element.value === item)?.label)?.join(", ") ?? "--"}</span>,
  },
  {
    id: "org_head.email",
    title: formatMessage({ id: "pages.partners.list.utils.columns.headEmail" }),
  },
  {
    id: "working_languages",
    title: formatMessage({ id: "pages.partners.list.utils.columns.languages" }),
    type: "custom",
    render: (row) => <span>{row.working_languages.join(", ")}</span>,
  },
  {
    id: "specializations",
    title: formatMessage({
      id: "pages.partners.list.utils.columns.specialization",
    }),
    type: "custom",
    render: (row) => {
      const experiences = adaptExperiences(row.experiences);
      return (
        <>
          {experiences.map((item, index) => (
            <div style={{ fontSize: ".8rem" }} key={`experience_${row.id}_${index}`}>
              <b style={{ paddingRight: ".5rem" }}>{item.name}</b>
              <span>{item.areas.join(", ")}</span>
            </div>
          ))}
        </>
      );
    },
  },
];

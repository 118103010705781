import { getSelectedLanguage, setSelectedLanguage } from "@local/legacy-utils/i18nHelper";
import * as R from "ramda";
import { clearError, startLoading, stopLoading } from "./apiStatus";

export const LOCALE_LOAD_STARTED = "LOCALE_LOAD_STARTED";
export const LOCALE_LOAD_SUCCESS = "LOCALE_LOAD_SUCCESS";
export const LOCALE_LOAD_ENDED = "LOCALE_LOAD_ENDED";
export const LOCALE_DEFAULT = "LOCALE_DEFAULT";

export const localeLoadStarted = () => ({ type: LOCALE_LOAD_STARTED });
export const localeLoadSuccess = (response) => ({
  type: LOCALE_LOAD_SUCCESS,
  response,
});
export const localeLoadEnded = () => ({ type: LOCALE_LOAD_ENDED });
export const localeDefault = () => ({ type: LOCALE_DEFAULT });

const saveLocale = (state, action) => {
  return R.assoc("lang", action.response, state);
};

export const EN = "en";
export const ES = "es";
export const FR = "fr";

const initialState = {
  loading: false,
  lang: getSelectedLanguage(),
};

const patchLocale = (params) => {
  switch (params) {
    case EN: {
      return EN;
    }
    case ES: {
      return ES;
    }
    case FR: {
      return FR;
    }
    default: {
      return EN;
    }
  }
};

export const loadLocale = () => (dispatch) => {
  dispatch(localeLoadStarted());
  dispatch(localeLoadEnded());
  return dispatch(localeDefault());
};

export const editLocale = (params) => (dispatch) => {
  dispatch(localeLoadStarted());
  dispatch(localeLoadEnded());
  setSelectedLanguage(params);
  return dispatch(localeLoadSuccess(patchLocale(params)));
};

export default function localeReducer(state = initialState, action) {
  switch (action.type) {
    case LOCALE_LOAD_ENDED: {
      return stopLoading(state);
    }
    case LOCALE_LOAD_STARTED: {
      return startLoading(clearError(state));
    }
    case LOCALE_LOAD_SUCCESS: {
      return saveLocale(state, action);
    }
    default:
      return state;
  }
}

import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import { adaptOpenProjectCompletedReasons } from "../../../../utils/adapters";

const CompleteCfeiForm = ({ status, type, hasWinners, completedReasonsOptions }) => (
  <FieldGroup>
    <Field
      component={FieldRadioGroup}
      id="completed_reason"
      name="completed_reason"
      label={formatMessage({
        id: "pages.project.details.cmp.container.cmp.complete.cfei.form.idx.reason",
      })}
      options={adaptOpenProjectCompletedReasons(completedReasonsOptions, status, type, hasWinners)}
      fullWidthLabel
      fullWidthRadioElement
    />
    <Field
      component={FieldText}
      id="justification"
      name="justification"
      label={formatMessage({
        id: "pages.project.details.cmp.container.cmp.complete.cfei.form.idx.justification",
      })}
    />
  </FieldGroup>
);

const mapStateToProps = (state) => ({
  completedReasonsOptions: state?.partnerProfileConfig["completed-reason"],
});

export default connect(mapStateToProps)(reduxForm({ form: "completeCfeiForm", enableReinitialize: true })(CompleteCfeiForm));

import Loader from "@local/components/loader";
import style from "./style.module.scss";
import { connect } from "react-redux";
import { adaptCountries } from "@local/utils/adapters";
import { evalPropertyValue } from "@local/pages/admin/utils/adapters";
import { similarityProperties } from "@local/pages/admin/utils/columns";
import i18n from "@local/i18next/i18n";
const PartnersInfoArea = ({ partnerOne, partnerTwo, countries }) => {
  return (
    <>
      {partnerOne && partnerTwo ? (
        <table className={style.detailsTable}>
          <thead>
            <tr>
              <th style={{ backgroundColor: "transparent" }}></th>
              <th>{i18n.t("Partner")} 1</th>
              <th>{i18n.t("Partner")} 2</th>
            </tr>
          </thead>
          <tbody>
            {similarityProperties().map((property, index) => (
              <tr key={index}>
                <td style={{ fontWeight: property?.isSection ? "600" : "400", backgroundColor: property?.isSection ? "#f0f0f0" : "transparent" }}>{property?.label}</td>
                <td style={{ backgroundColor: property?.isSection ? "#f0f0f0" : "transparent" }}>{!property?.isSection ? evalPropertyValue(partnerOne, property?.key, countries) : null}</td>
                <td style={{ backgroundColor: property?.isSection ? "#f0f0f0" : "transparent" }}>{!property?.isSection ? evalPropertyValue(partnerTwo, property?.key, countries) : null}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Loader isVisible={true} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  countries: adaptCountries(state?.countriesList?.data?.data ?? {}),
});

export default connect(mapStateToProps, null)(PartnersInfoArea);

import React from "react";
import { reduxForm, Field } from "redux-form";
import FieldGroup from "@local/components/field-group";
import FieldText from "@local/components/field-text";
import Button from "@local/components/button";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import Modal from "@local/components/modal";

const getMessagesForAction = (action) => {
  switch (action) {
    case "did_win":
      return {
        title: formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.selectApplicationModalTitle",
        }),
        description: formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.selectApplicationModalHeader",
        }),
        submit: formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.selectApplicationModalSubmit",
        }),
      };
    case "did_withdraw":
      return {
        title: formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.retractApplicationModalTitle",
        }),
        description: formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.retractApplicationModalHeader",
        }),
        submit: formatMessage({
          id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.retractApplicationModalSubmit",
        }),
      };
    default:
      return {
        title: "",
        description: "",
        submit: "",
      };
  }
};

const UpdateApplicationModal = ({ isOpen, onClose, onSubmit, action }) => {
  const messages = getMessagesForAction(action);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={messages.title}
      footer={
        <>
          <Button
            variant="text"
            onClick={onClose}
            text={formatMessage({
              id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.cancel",
            })}
          />
          <Button onClick={onSubmit} text={messages.submit} />
        </>
      }
    >
      <FieldGroup>
        <span>{messages.description}</span>
        <Field
          component={FieldText}
          id="justification_reason"
          name="justification_reason"
          label={formatMessage({
            id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.label",
          })}
          placeholder={formatMessage({
            id: "pages.project.details.cmp.open.project.results.cmp.update.application.modal.idx.label",
          })}
          withCharactersCount
          maxLength={5000}
          multiline
        />
      </FieldGroup>
    </Modal>
  );
};

UpdateApplicationModal.propTypes = {};

export default reduxForm({
  form: "updateApplicationForm",
  enableReinitialize: true,
})(UpdateApplicationModal);

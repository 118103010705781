/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import style from "./style.module.scss";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { connect } from "react-redux";
import { getPseaDetails } from "@local/actions";
import { getPseaRatingOptions } from "@local/utils/constants";
import Chip from "@mui/material/Chip";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "@local/components/button";
import { useHistory } from "react-router-dom";
import differenceInDays from "date-fns/differenceInDays";
import { getLabelFromRevision, getPreliminaryDetermination, formatDate } from "@local/pages/partner-details/components/psea/utils";

const getPseaRatingColor = (rating) => {
  switch (rating) {
    case "low":
      return "#FFA500";
    case "medium":
      return "#90EE90";
    case "full":
      return "#057b52";
    case "low_risk":
      return "#0000CC";
    case "not_eligible":
      return "#cc0000";
    case "pending_agency_confirm":
      return "#f7cb73";
    default:
      return "#777777";
  }
};

const getCsipColor = (preliminaryDeterminationCompletionDate, csipStartDate, csipCompletionDate, psea) => {
  if (csipCompletionDate) {
    return "#00cc00";
  } else if (!csipStartDate) {
    if (["full", "low_risk"].includes(psea?.final_determination)) {
      return "#777777";
    }
    if (["medium", "low", "not_eligible", undefined, null].includes(psea?.final_determination) && preliminaryDeterminationCompletionDate && differenceInDays(new Date(), new Date(preliminaryDeterminationCompletionDate)) >= 30) {
      return "#cc0000";
    }
  } else if (csipStartDate) {
    return "#0044ff";
  }
  return "#777777";
};

const getCsipLabel = (preliminaryDeterminationCompletionDate, csipStartDate, csipCompletionDate, psea) => {
  if (csipCompletionDate || csipStartDate) {
    return formatMessage({ id: "cmp.psea.observations.idx.available" });
  } else if (!csipStartDate) {
    if (["full", "low_risk"].includes(psea?.final_determination)) {
      return formatMessage({ id: "cmp.psea.observations.idx.csipNotRequired" });
    }
    if (["medium", "low", "not_eligible", undefined, null].includes(psea?.final_determination) && preliminaryDeterminationCompletionDate && differenceInDays(new Date(), new Date(preliminaryDeterminationCompletionDate)) >= 30) {
      return formatMessage({ id: "cmp.psea.observations.idx.pastDue" });
    }
  }
  return formatMessage({ id: "cmp.psea.observations.idx.notAvailable" });
};

const getPreliminaryLabel = (psea, preliminaryDetermination) => {
  if (preliminaryDetermination?.revision_rating) {
    return getLabelFromRevision(preliminaryDetermination?.revision_rating, preliminaryDetermination?.revision_score, psea?.final_determination_uploaded_document_rating);
  } else if (!psea?.self_assessment_is_finalized_by_partner && !psea?.self_assessment_is_finalized_by_agency) {
    return formatMessage({
      id: "cmp.psea.observations.idx.sectionNotAvailable",
    });
  } else if (psea?.self_assessment_is_finalized_by_partner && !psea?.self_assessment_is_finalized_by_agency) {
    return formatMessage({ id: "cmp.psea.observations.idx.pending" });
  } else if (psea?.self_assessment_is_finalized_by_agency) {
    return getPseaRatingOptions().find((item) => item.value === psea?.self_assessment_rating)?.extendedLabel;
  }
  return formatMessage({ id: "cmp.psea.observations.idx.sectionNotAvailable" });
};

const getPreliminaryColor = (psea, preliminaryDetermination) => {
  if (preliminaryDetermination?.revision_rating) {
    return getPseaRatingColor(getPseaRatingOptions().find((item) => item.value === preliminaryDetermination?.revision_rating)?.value);
  } else if (!psea && psea?.self_assessment_is_finalized_by_partner !== true && psea?.self_assessment_is_finalized_by_agency !== true) {
    return getPseaRatingColor();
  } else if (psea?.self_assessment_is_finalized_by_partner && !psea?.self_assessment_is_finalized_by_agency) {
    return getPseaRatingColor("pending_agency_confirm");
  } else if (!psea?.self_assessment_is_finalized_by_partner && psea?.self_assessment_is_finalized_by_agency) {
    return getPseaRatingColor(psea?.self_assessment_rating);
  } else if (psea?.self_assessment_is_finalized_by_partner && psea?.self_assessment_is_finalized_by_agency) {
    return getPseaRatingColor(psea?.self_assessment_rating);
  }
  return getPseaRatingColor();
};

const PseaObservations = ({ psea, getPseaDetails, partnerId }) => {
  const history = useHistory();

  useEffect(() => {
    getPseaDetails();
  }, []);

  const onClickView = () => history.push(`/partner/${partnerId}/psea`);

  const revisions = psea?.revisions ?? [];
  let sortedRevisions = [];
  let initialAssessment = undefined;
  let selfAssessment = undefined;
  sortedRevisions = [...revisions];
  sortedRevisions.sort((a, b) => a.id - b.id);
  initialAssessment = sortedRevisions.find((item) => item.revision_type === "initial_assessment") ?? {};
  selfAssessment = sortedRevisions.filter((item) => item.revision_type === "self_assessment").at(-1) ?? {};
  const preliminaryDetermination = getPreliminaryDetermination(initialAssessment, selfAssessment);

  return (
    <>
      <div className={style.sectionsWrapper}>
        <div className={style.sectionWrapper}>
          <div>
            <span className={style.title}>{formatMessage({ id: "cmp.psea.observations.idx.rating" })}</span>
          </div>
          <div>
            <Chip
              size="small"
              icon={
                <CircleIcon
                  style={{
                    fill: getPreliminaryColor(psea, preliminaryDetermination),
                    fontSize: ".75rem",
                  }}
                />
              }
              label={<span className={style.ratingLabel}>{getPreliminaryLabel(psea, preliminaryDetermination)}</span>}
            />
            {preliminaryDetermination?.revision_date ? (
              <span className={style.dateLabel}>
                {formatMessage({
                  id: "cmp.psea.observations.idx.completedBy",
                })}
                {`
                  ${preliminaryDetermination?.agency_name}
                  ${formatDate(preliminaryDetermination?.revision_date)}
                `}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div>
            <span className={style.title}>{formatMessage({ id: "cmp.psea.observations.idx.plan" })}</span>
          </div>
          <div>
            <div>
              <Chip
                size="small"
                icon={
                  <CircleIcon
                    style={{
                      fill: getCsipColor(preliminaryDetermination?.revision_date, psea?.csip_start_date, psea?.csip_completion_date, psea),
                      fontSize: ".75rem",
                    }}
                  />
                }
                label={<span className={style.ratingLabel}>{getCsipLabel(preliminaryDetermination?.revision_date, psea?.csip_start_date, psea?.csip_completion_date, psea)}</span>}
              />
            </div>
            {psea?.csip_completion_date ? (
              <span className={style.dateLabel}>
                {formatMessage({ id: "cmp.psea.observations.idx.completion" })} {formatDate(psea?.csip_completion_date)}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={style.sectionWrapper}>
          <div>
            <span className={style.title}>{formatMessage({ id: "cmp.psea.observations.idx.determination" })}</span>
          </div>
          <div>
            <Chip
              size="small"
              icon={
                <CircleIcon
                  style={{
                    fill: getPseaRatingColor(psea?.final_determination),
                    fontSize: ".75rem",
                    filter: psea?.is_expired ? "grayscale(1)" : undefined,
                  }}
                />
              }
              label={
                <>
                  <span
                    style={
                      psea?.is_expired
                        ? {
                            textDecoration: "line-through",
                            filter: psea?.is_expired ? "grayscale(1)" : undefined,
                          }
                        : undefined
                    }
                    className={style.ratingLabel}
                  >
                    {getLabelFromRevision(psea?.final_determination, psea?.final_determination_score, psea?.final_determination_uploaded_document_rating)}
                  </span>
                </>
              }
            />
            {psea?.is_expired ? (
              <span className={style.expiredLabel}>
                {formatMessage({
                  id: "cmp.psea.observations.idx.finalDeterminationExpiredLabel",
                })}
              </span>
            ) : (
              <></>
            )}
          </div>
          <small>
            {psea?.final_determination ? (
              <>
                {psea?.final_determination_completed_by ? (
                  <span className={style.dateLabel}>
                    {`${formatMessage({
                      id: "cmp.psea.observations.idx.completedBy",
                    })} ${psea?.final_determination_completed_by} ${formatDate(psea?.date_of_completion)}`}
                  </span>
                ) : (
                  <span className={style.dateLabel}>
                    {`${
                      psea.csip_is_extended
                        ? formatMessage({
                            id: "cmp.psea.observations.idx.nineMothTitle",
                          })
                        : formatMessage({
                            id: "cmp.psea.observations.idx.sixMothTitle",
                          })
                    } ${formatDate(psea?.date_of_completion)}`}
                  </span>
                )}
              </>
            ) : (
              <></>
            )}
          </small>
        </div>
      </div>
      <div className={style.actionsWrapper}>
        <Button text={formatMessage({ id: "cmp.psea.observations.idx.viewButton" })} variant="text" onClick={onClickView} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    psea: state.pseaDetails.data?.data ?? {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  getPseaDetails: () => dispatch(getPseaDetails(undefined, { partnerId: ownProps.partnerId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PseaObservations);

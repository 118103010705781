/* eslint-disable no-unused-vars */

import { formatMessage } from "@local/legacy-utils/i18nHelper";
import isValid from "date-fns/isValid";
import { endOfDay, startOfDay } from "date-fns";

const errorMessages = {
  validEmail: "common.messages.errorMessages.validEmail",
  validPhoneNumber: "common.messages.errorMessages.validPhoneNumber",
  required: "common.messages.errorMessages.required",
  validDate: "common.messages.errorMessages.validDate",
  validUrl: "common.messages.errorMessages.validUrl",
  validBetween: "common.messages.errorMessages.validBetween",
  numeric: "common.messages.errorMessages.numeric",
  greaterOrEqualTo: "common.messages.errorMessages.greaterOrEqualTo",
  validBetweenDate: "common.messages.errorMessages.validBetweenDate",
  validFutureDate: "common.messages.errorMessages.validFutureDate",
};

const isEmpty = (value) => value === null || value === undefined || value === "" || value?.length === 0 || (value?.find ? value?.findIndex((item) => item === null || item === undefined || item === "") !== -1 : false);

const getErrorMessage = (key) => formatMessage({ id: errorMessages[key] });

export const isValidEmail = (value) => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? getErrorMessage("validEmail") : "");

export const isValidPhoneNumber = (value) => (value && !/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g.test(value) ? getErrorMessage("validPhoneNumber") : "");

export const isRequired = (value) => (isEmpty(value) ? getErrorMessage("required") : "");

export const isRequiredAndNotEmptyString = (value) => (isEmpty(value) || value.trim() === "" ? getErrorMessage("required") : "");

export const isRequiredTrue = (value) => (value !== true && value !== "true" && value !== 1 ? getErrorMessage("required") : "");

export const isRequiredIfValue = (fieldName, fieldValue) => (value, allValues) => (isEmpty(value) && allValues[fieldName] === fieldValue ? getErrorMessage("required") : "");

export const isBetween = (from, to) => (value) => (value && (isNaN(value) || value < from || value > to) ? getErrorMessage("validBetween") : "");

export const isBetweenDate = (from, to) => (value) => (value && (endOfDay(new Date(value)) < startOfDay(new Date(from)) || startOfDay(new Date(value)) > endOfDay(new Date(to))) ? getErrorMessage("validBetweenDate") : "");

export const isFutureDate = () => (value) => (value && endOfDay(new Date(value)) < startOfDay(new Date()) ? getErrorMessage("validFutureDate") : "");

export const isGreaterOrEqualTo = (max) => (value) => (value && (isNaN(value) || value > max) ? getErrorMessage("greaterOrEqualTo") : "");

export const isNumeric = (value) => (value && isNaN(value) ? getErrorMessage("numeric") : "");

export const isDecimalNumeric = (value) => {
  const isDecimalNumber = /^\d+(\.\d+)?$/.test(value);
  return !isDecimalNumber ? getErrorMessage("numeric") : "";
};

export const isValidDate = (value) => (value && !isValid(value) ? getErrorMessage("validDate") : "");

export const isValidUrl = (value) => {
  if (value === null || value === undefined || value === "") {
    return "";
  }
  try {
    const url = new URL(value);
    return "";
  } catch (error) {
    return getErrorMessage("validUrl");
  }
};

export const isDateAfter = (fieldName, message) => (value, allValues) => (value && value <= allValues[fieldName] ? message : "");

export const isValidDateEditMode = (value) => {
  return value && !isValid(new Date(value)) ? getErrorMessage("validDate") : "";
};

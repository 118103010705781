import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldGroup from "@local/components/field-group";
import FieldSelect from "@local/components/field-select";
import Button from "@local/components/button";
import { reduxForm, Field } from "redux-form";
import { isRequired } from "@local/utils/validators";
import FieldText from "@local/components/field-text";
import Alert from "@local/components/alert";
import FieldCheckbox from "@local/components/field-checkbox";
import style from "./style.module.scss";

const UnlockModal = ({ onCancel, onSubmit, isOpen, justificationTypes, isFormValid, title, isCsipWarningVisible, isCsipWarningConfirmed, onChangeCsipWarningConfirmation, isCommentRequired, unlockType }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={title}
      footer={
        <>
          <div>
            <Button
              onClick={onCancel}
              variant="text"
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={!isFormValid || (isCsipWarningVisible && !isCsipWarningConfirmed)}
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.button",
              })}
            />
          </div>
        </>
      }
    >
      <>
        <FieldGroup>
          <Field
            component={FieldSelect}
            validate={[isRequired]}
            id={unlockType === "self_assessment" ? "unlock_assessment_justification" : "unlock_rating_justification"}
            name={unlockType === "self_assessment" ? "unlock_assessment_justification" : "unlock_rating_justification"}
            label={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.justification",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.justification",
            })}
            valueField="justification"
            labelField="description"
            options={justificationTypes}
          />
          <Field
            component={FieldText}
            validate={isCommentRequired ? [isRequired] : []}
            id={unlockType === "self_assessment" ? "unlock_assessment_comment" : "unlock_rating_comment"}
            name={unlockType === "self_assessment" ? "unlock_assessment_comment" : "unlock_rating_comment"}
            label={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.justificationDescription",
            })}
            placeholder={formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.justificationDescription",
            })}
            type="text"
            withCharactersCount
            maxLength={500}
          />
          {isCsipWarningVisible ? (
            <Alert
              type="warning"
              content={
                <>
                  <span className={style.confirmLabel}>
                    {formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.unlockConfirmationMessage",
                    })}
                  </span>
                  <FieldCheckbox
                    label={formatMessage({
                      id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.unlock.modal.idx.unlockConfirmationMessageCheckbox",
                    })}
                    small
                    input={{
                      value: isCsipWarningConfirmed,
                      onChange: onChangeCsipWarningConfirmation,
                    }}
                  />
                </>
              }
            />
          ) : (
            <></>
          )}
        </FieldGroup>
      </>
    </Modal>
  );
};

UnlockModal.propTypes = {};

export default reduxForm({
  form: "pseaSelfAssessmentUnlockForm",
  enableReinitialize: true,
})(UnlockModal);

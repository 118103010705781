import React, { useState } from "react";
import Table from "@local/components/table";
import i18n from "@local/i18next/i18n";
import Modal from "@local/components/modal";
import { connect } from "react-redux";
import { Chip, Tab, Tabs } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import ProjectForm from "./components/ProjectForm";
import { getProjectAdminFocalPointHistoryColumns, getProjectAdminHistoryColumns, getProjectAdminReviewersColumns } from "../../utils/columns";
import { completeProjectReviewerAssessments, getFocalPointsHistoryListAdmin, getProjectStatusHistoryListAdmin, revertProjectStatusAdmin, sendReminder } from "@local/actions";
import { toast } from "react-toastify";
import { projectModalNav } from "@local/utils/constants";
import Loader from "@local/components/loader";
import style from "./style.module.scss";
import ConfirmModal from "@local/components/confirm-modal";
import Button from "@local/components/button";
import ProjectFocalPointForm from "./components/ProjectFocalPointForm";

const AssessmentFinalizations = ({
  tabs,
  initialFilters,
  fetchFunction,
  columns,
  filters,
  adapterFunction,
  isOpen,
  onClose,
  onChangeTableFilters,
  selectedProject,
  completeReviewerAssessments,
  shouldRefresh,
  setShouldRefresh,
  sendReminderToReviewer,
  revertProjectStatus,
  projectStatusHistory,
  projectFocalPointsHistory,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [isRevertNotifyModalOpen, setIsRevertNotifyModalOpen] = useState(false);
  const [reviewerId, setReviewerId] = useState(null);

  const getStatusHistory = async () => {
    try {
      const res = await projectStatusHistory(selectedProject.id);
      const filteredHistoryList = res.filter((obj) => obj?.data && obj?.data?.completed_reason === "revert").sort((a, b) => b?.id - a?.id);

      return {
        data: {
          count: filteredHistoryList ? filteredHistoryList.length : 0,
          results: filteredHistoryList ? [...filteredHistoryList] : [],
        },
      };
    } catch (err) {
      console.log(err);
    }
  };

  const getFocalPointsHistory = async () => {
    try {
      const res = await projectFocalPointsHistory(selectedProject.id);

      return {
        data: {
          count: res ? res.length : 0,
          results: res ? [...res] : [],
        },
      };
    } catch (err) {
      console.log(err);
    }
  };

  // Handle change of tab
  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNotifyIconClick = (reviwerId) => {
    setIsNotifyModalOpen(true);
    setReviewerId(reviwerId);
  };

  const handleConfirmationClick = () => {
    sendReminderToReviewer(reviewerId);
    setIsNotifyModalOpen(false);
    setReviewerId(null);
  };

  const handleRevertConfirmationClick = async () => {
    try {
      await revertProjectStatus(selectedProject.id);
      setIsRevertNotifyModalOpen(false);
    } catch (error) {
      console.log("Failed to revert project status", error);
    }
  };

  let content;

  switch (activeTab) {
    case 0:
      content = <ProjectForm setShouldRefresh={setShouldRefresh} initialValues={selectedProject} />;
      break;
    case 1:
      content = (
        <div className={style.tableWrapper}>
          <Table
            key={`project_reviewers_list${selectedProject?.id}`}
            id="project_reviewers_list"
            title={i18n.t("Reviewers")}
            isPaginated={false}
            columns={getProjectAdminReviewersColumns(completeReviewerAssessments, handleNotifyIconClick)}
            fetchFunction={() => ({
              data: {
                count: selectedProject?.reviewers ? selectedProject?.reviewers?.length : 0,
                results: selectedProject?.reviewers ? selectedProject?.reviewers : [],
              },
            })}
            adapterFunction={adapterFunction}
            onChangeFilters={onChangeTableFilters}
            keyColumn="id"
          />
        </div>
      );
      break;
    case 2:
      content = (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", paddingTop: "1rem" }}>
          <div>
            {i18n.t("Revert project status from")} <Chip icon={<CircleIcon style={{ fill: "#5B92E5" }} fontSize="small" />} label={i18n.t("Finalized")} /> {i18n.t("to")}{" "}
            <Chip icon={<CircleIcon style={{ fill: "#233944" }} fontSize="small" />} label={i18n.t("Closed/Under Review")} />
          </div>
          <div>
            <Button isDisabled={selectedProject?.status !== "Com"} onClick={() => setIsRevertNotifyModalOpen(true)} text={i18n.t("Revert Status")} />
          </div>
          <div className={style.tableWrapper}>
            <Table key={`project_status_history_list${selectedProject?.id}`} id="project_status_history_list" title={i18n.t("Status History")} isPaginated={false} columns={getProjectAdminHistoryColumns()} fetchFunction={getStatusHistory} keyColumn="id" />
          </div>
        </div>
      );
      break;
    case 3:
      content = (
        <div>
          <ProjectFocalPointForm
            setShouldRefresh={setShouldRefresh}
            selectedProject={selectedProject}
            initialValues={{
              ...selectedProject,
              focal_points: selectedProject?.focal_points?.map((fp) => fp.id.toString()) ?? [],
            }}
          />
          <div className={style.tableWrapper}>
            <Table
              key={`project_focal_points_history_list${selectedProject?.id}`}
              id="project_focal_points_history_list"
              title={i18n.t("Focal Points History")}
              isPaginated={false}
              columns={getProjectAdminFocalPointHistoryColumns()}
              fetchFunction={getFocalPointsHistory}
              keyColumn="id"
            />
          </div>
        </div>
      );
      break;
    default:
      content = undefined;
  }

  return (
    <>
      <Table
        key="projects_list"
        id="projects_list"
        title={i18n.t("Projects")}
        columns={columns}
        fetchFunction={fetchFunction}
        filters={filters}
        shouldRefresh={shouldRefresh}
        afterRefresh={() => setShouldRefresh(false)}
        adapterFunction={adapterFunction}
        onChangeFilters={onChangeTableFilters}
        withUrlSynchronization
        keyColumn="id"
        initialFilters={initialFilters}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setActiveTab(0);
          onClose();
        }}
        title={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{i18n.t("Project Details")}</span>
            <span className={style.projectIdLabel}>CFEI ID: {selectedProject?.displayID}</span>
          </div>
        }
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className={style.projectIdLabel}>Title</span>
          <span className={style.itemValue}> {selectedProject?.title}</span>
        </div>
        <Tabs value={activeTab} onChange={handleChangeTab} variant="scrollable" scrollButtons={false} allowScrollButtonsMobile>
          {tabs.map((tab, index) => (
            <Tab key={tab.id} value={index} label={tab.label} disabled={tab.isDisabled} />
          ))}
        </Tabs>
        <div className={style.modal}>
          {selectedProject ? (
            content
          ) : (
            <div className={style.loaderWrapper}>
              <Loader isVisible />
            </div>
          )}
        </div>
      </Modal>
      <ConfirmModal isOpen={isNotifyModalOpen} title={i18n.t("Do you confirm sending notification to the reviewer?")} onSubmit={handleConfirmationClick} onClose={() => setIsNotifyModalOpen(false)} />
      <ConfirmModal isOpen={isRevertNotifyModalOpen} title={i18n.t("Do you confirm reverting the project status to Closed/Under Review?")} onSubmit={async () => await handleRevertConfirmationClick()} onClose={() => setIsRevertNotifyModalOpen(false)} />
    </>
  );
};

AssessmentFinalizations.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    tabs: projectModalNav(),
    selectedProject: state?.getAdminProjectsList?.data?.data?.results?.find((result) => result?.id === ownProps?.selectedProject?.id),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  completeReviewerAssessments: async (id) => {
    try {
      await dispatch(completeProjectReviewerAssessments(null, { id: ownProps?.selectedProject?.id, reviewer_id: id }));
      toast.success(i18n.t("Assessments completed successfully"));
      ownProps?.setShouldRefresh(true);
    } catch (error) {
      toast.error(i18n.t("Action failed"));
    }
  },
  revertProjectStatus: async () => {
    try {
      await dispatch(revertProjectStatusAdmin({ completed_reason: "revert" }, { id: ownProps?.selectedProject?.id }));
      toast.success(i18n.t("Project status reverted successfully"));
      ownProps?.setShouldRefresh(true);
    } catch (error) {
      toast.error(i18n.t("Action failed"));
    }
  },
  projectStatusHistory: async () => {
    try {
      const res = await dispatch(getProjectStatusHistoryListAdmin(null, { id: ownProps?.selectedProject?.id }));
      return res.data;
    } catch (error) {
      toast.error(i18n.t("Failed to fetch project status history"));
    }
  },
  projectFocalPointsHistory: async () => {
    try {
      const res = await dispatch(getFocalPointsHistoryListAdmin(null, { id: ownProps?.selectedProject?.id }));
      return res.data;
    } catch (error) {
      toast.error(i18n.t("Failed to fetch project status history"));
    }
  },
  sendReminderToReviewer: async (id) => {
    try {
      const res = await dispatch(sendReminder(null, { id: ownProps?.selectedProject?.id, reviewerId: id }));
      if (res.data.success) {
        toast.success(i18n.t(res.data.success));
      }
    } catch (error) {
      toast.error(i18n.t("Notification message failed to sent"));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentFinalizations);

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { reset } from "redux-form";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getAgenciesList, getObservationsList, createObservation, updateObservation, deleteObservation } from "@local/actions";
import Table from "@local/components/table";
import Button from "@local/components/button";
import { getObservationsColumns } from "../../utils/columns";
import ManageObservationModal from "./components/manage-observation-modal";
import Breadcrumb from "@local/components/breadcrumb";
import { AGENCY_RESTRICTIONS, isSectionVisible } from "@local/legacy-utils/restrictions";
import ConfirmModal from "@local/components/confirm-modal";
import Alert from "@local/components/alert";

const Observations = ({ getObservations, getAgencies, partnerId, session, resetManageObservationForm, manageObservationFormValues, addObservation, updateObservation, deleteObservation }) => {
  const [manageObservationModalVisible, setManageObservationModalVisible] = useState(false);
  const [isDeleteObservationModalVisible, setIsDeleteObservationModalVisible] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [currentObservation, setCurrentObservation] = useState(undefined);

  const adaptedValues = {
    ...selectedRow,
    comment: selectedRow?.comment?.message,
  };

  useEffect(() => {
    getAgencies();
  }, [getAgencies]);

  const onClickOpenManageObservationModal = () => {
    setManageObservationModalVisible(true);
  };

  const onClickOpenDeleteObservationModal = (item) => {
    setIsDeleteObservationModalVisible(true);
    setCurrentObservation(item);
  };

  const onClickCloseManageObservationModal = () => {
    setManageObservationModalVisible(false);
    setIsEditable(false);
    resetManageObservationForm();
  };

  const onClickCloseDeleteObservation = () => {
    setIsDeleteObservationModalVisible(false);
  };

  const handleOnClickEditFlag = (row) => {
    setSelectedRow(row);
    setIsEditable(true);
    setManageObservationModalVisible(true);
  };

  const afterRefresh = () => {
    setShouldRefresh(false);
  };

  const onSubmitAddObservationModal = async () => {
    try {
      await addObservation({
        comment: {
          message: manageObservationFormValues?.comment,
        },
        attachments: manageObservationFormValues?.attachments,
        partner: partnerId,
        agency: session?.agencyId,
      });
      setManageObservationModalVisible(false);
      setShouldRefresh(true);
      resetManageObservationForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.observations.idx.raiseError",
        }),
      );
    }
  };

  const onClickEditObservation = async () => {
    try {
      await updateObservation(adaptedValues?.id, {
        comment: {
          message: manageObservationFormValues?.comment,
        },
        attachments: manageObservationFormValues?.attachments,
      });
      setManageObservationModalVisible(false);
      setIsEditable(false);
      setShouldRefresh(true);
      resetManageObservationForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.observations.idx.editError",
        }),
      );
    }
  };

  const handleOnClickDeleteObservation = async () => {
    try {
      await deleteObservation(currentObservation?.id);
      setShouldRefresh(true);
      setIsDeleteObservationModalVisible(false);
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.observations.idx.unableToSaveData",
        }),
      );
    }
  };

  return (
    <>
      <Breadcrumb
        items={[
          {
            id: 1,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.observations.idx.dashboardTitle",
            }),
            url: `/partner/${partnerId}/risk/dashboard`,
          },
          {
            id: 2,
            label: formatMessage({
              id: "pages.partner.details.cmp.risk.cmp.observations.idx.observations",
            }),
            url: `/partner/${partnerId}/risk/observations`,
          },
        ]}
      />
      <Table
        key="risk_observations_list"
        id="risk_observations_list"
        title={formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.observations.idx.observations",
        })}
        columns={getObservationsColumns(handleOnClickEditFlag, onClickOpenDeleteObservationModal, session)}
        fetchFunction={getObservations}
        actions={
          isSectionVisible(AGENCY_RESTRICTIONS.partners.risk.observations["addObservation"], session) && (
            <Button
              text={formatMessage({
                id: "pages.partner.details.cmp.risk.cmp.observations.idx.addObservation",
              })}
              onClick={onClickOpenManageObservationModal}
            />
          )
        }
        shouldRefresh={shouldRefresh}
        afterRefresh={afterRefresh}
      />
      <ManageObservationModal
        isOpen={manageObservationModalVisible}
        onClose={onClickCloseManageObservationModal}
        initialValues={isEditable ? adaptedValues : undefined}
        isEditable={isEditable}
        onSubmit={onSubmitAddObservationModal}
        onEditObservation={onClickEditObservation}
      />
      <ConfirmModal
        isOpen={isDeleteObservationModalVisible}
        onClose={onClickCloseDeleteObservation}
        onSubmit={handleOnClickDeleteObservation}
        title={formatMessage({
          id: "pages.partner.details.cmp.risk.cmp.observations.idx.deleteObservation",
        })}
        type="warning"
        message={
          <div>
            <Alert
              type="info"
              content={formatMessage({
                id: "pages.partner.details.cmp.risk.cmp.observations.idx.info",
              })}
              withBottomMargin
            />
          </div>
        }
      />
    </>
  );
};

Observations.propTypes = {};

const mapStateToProps = (state) => ({
  agencies: state?.agenciesList?.data?.data?.results ?? [],
  session: state?.session,
  observations: state?.observationsList?.data?.data?.results ?? [],
  manageObservationFormValues: state?.form?.manageObservationForm?.values,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getObservations: (params) => dispatch(getObservationsList({ ...params, partner: ownProps.partnerId })),
  getAgencies: (params) => dispatch(getAgenciesList(params)),
  resetManageObservationForm: () => dispatch(reset("manageObservationForm")),
  addObservation: (body) => dispatch(createObservation(body)),
  updateObservation: (id, body) => dispatch(updateObservation(body, { id })),
  deleteObservation: (id) => dispatch(deleteObservation({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Observations);

import { formatMessage } from "@local/legacy-utils/i18nHelper";

const initialState = {
  tabs: [
    {
      path: "notes",
      label: formatMessage({
        id: "legacy.reducers.partnerApplicationsNav.notes",
      }),
    },
    {
      path: "unsolicited",
      label: formatMessage({
        id: "legacy.reducers.partnerApplicationsNav.unsolicited",
      }),
    },
    {
      path: "direct",
      label: formatMessage({
        id: "legacy.reducers.partnerApplicationsNav.direct",
      }),
    },
  ],
};

export default function partnerApplicationsNavReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
